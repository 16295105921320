import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { fileDownloadLink } from '../../../constants/defaults';
import { SecureDownload } from '../../../containers/dialogs';
import downloadFile from '../../../helpers/functions/fileDownload';
import AttachedFiles from '../../ui/attachedFiles';
import DotLoader from '../dotLoader';

const useStyles = makeStyles((theme) => ({
  baseStyle: {
    padding: '10px',
    borderColor: theme.palette.text.primary,
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }
}));

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

// const DEFAULT_TYPES = 'image/*, video/*, audio/*, .txt, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .odt, .rtf, .csv, .key';
const DEFAULT_TYPES2 = {
  'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
  'text/plain': ['.txt', '.csv'],
  'video/*': ['.avi', '.mp4', '.mpeg'],
  'audio/*': ['.mp3'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
  'application/pdf': ['.pdf'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/rtf': ['.rtf']
};

const Dropzone = ({ attachments, setAttachments, onUpload, disabled, loading, multiple, fileTypes = DEFAULT_TYPES2, subText }) => {
  const classes = useStyles();

  const [fileToDownload, setFileToDownload] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen((open) => !open);
  };

  const onDrop = (files) => {
    onUpload(files);
  };

  const handleDownload = (file = {}) => {
    setFileToDownload(file);
    toggleModal();
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    multiple: multiple,
    noKeyboard: true,
    disabled,
    accept: fileTypes
  });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Fragment>
      <Card className={classes.baseStyle} {...getRootProps({ style })}>
        <CardContent>
          {loading ? (
            <Box sx={{ position: 'relative' }}>
              <DotLoader />
            </Box>
          ) : (
            <Fragment>
              <input {...getInputProps()} />
              <Typography variant='subtitle1' align='center'>
                Drag and drop some files here, or click to select files
              </Typography>
              {subText && <Typography varaint='subtitle2'>{subText}</Typography>}
            </Fragment>
          )}
        </CardContent>
      </Card>

      <Grid container>
        <AttachedFiles attachments={attachments} setAttachments={setAttachments} onDownloadClick={handleDownload} />
      </Grid>
      <SecureDownload
        onClick={() => {
          downloadFile(fileDownloadLink(fileToDownload?.hash));
        }}
        open={modalOpen}
        toggle={toggleModal}
        file={fileToDownload || {}}
      />
    </Fragment>
  );
};
Dropzone.propTypes = {
  attachments: PropTypes.array,
  setAttachments: PropTypes.func,
  onUpload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  fileTypes: PropTypes.string,
  subText: PropTypes.string
};

export default Dropzone;
