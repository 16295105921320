import { useTheme } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import defaultOptions from './defaultOptions';

const StackedBarChart = ({ name, series, xAxis, yAxis, legend, backgroundColor, onEvents }) => {
  const theme = useTheme();

  const [chartOptions, setChartOptions] = useState(defaultOptions);

  useEffect(() => {
    if (!series) {
      return;
    }

    const { palette } = theme || {};
    const { background, text } = palette || {};
    const newOptions = _.cloneDeep(defaultOptions);
    newOptions.legend.textStyle = { color: text.primary };

    const { xAxis: defaultXAxis, tooltip, yAxis: defaultYAxis, legend: defaultLegend } = newOptions || {};

    defaultYAxis.axisLabel.color = palette.text.primary;
    defaultXAxis.axisLabel.color = palette.text.primary;

    newOptions.name = name || '';
    newOptions.series = series;
    newOptions.xAxis = { ...defaultXAxis, ...xAxis };
    newOptions.yAxis = { ...defaultYAxis, ...yAxis };
    newOptions.legend = { ...defaultLegend, ...legend };
    newOptions.backgroundColor = backgroundColor;
    tooltip.backgroundColor = background?.default;
    tooltip.textStyle = { color: text?.primary };

    setChartOptions(newOptions);
  }, [backgroundColor, legend, name, series, theme, xAxis, yAxis]);

  return (
    <ReactEcharts
      option={chartOptions}
      onEvents={onEvents}
      style={{
        height: '100%'
      }}
    />
  );
};

StackedBarChart.propTypes = {
  name: PropTypes.string,
  series: PropTypes.array,
  tooltip: PropTypes.object,
  xAxis: PropTypes.object,
  yAxis: PropTypes.object,
  legend: PropTypes.object,
  backgroundColor: PropTypes.string,
  onEvents: PropTypes.object
};

export default StackedBarChart;
