import { gql } from '@apollo/client';

export const GET_INDUSTRIES = gql`
  query industries {
    industries(sort: "name:ASC") {
      id
      name
    }
  }
`;
