import { LOG_SUB_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { formatDateTime, formatUserName, stripHtmlTags } from '@anirudhm9/base-lib/lib/utils';
import { EditRounded } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { fileDownloadLink } from '../../../../../constants/defaults';
import { SecureDownload } from '../../../../../containers/dialogs';
import { useUserContext } from '../../../../../contexts';
import downloadFile from '../../../../../helpers/functions/fileDownload';
import { DotLoader, IconButtonEnhanced, TooltipEnhanced } from '../../../../global';
import AttachedFiles from '../../../attachedFiles';
import LetterAvatar from '../../../letterAvatar';
import ShowMoreText from '../../../showMoreText';
import CommentInput from '../../commentInput.js';

const CommentContent = ({ log, onSave }) => {
  const { userId } = useUserContext();

  const { id, attachments, comment, user, created_at, subType, edited } = log || {};

  const isCommentEmpty = useMemo(() => stripHtmlTags(comment) === '', [comment]);

  const [fileToDownload, setFileToDownload] = useState({});
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const editable = useMemo(() => {
    return String(user?.id) === String(userId) && subType === LOG_SUB_TYPES.COMMENT;
  }, [subType, user?.id, userId]);

  const handleSave = useCallback(
    async (data, recipientIds) => {
      try {
        setLoading(true);
        await onSave({ ...(data || {}), id }, recipientIds);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setEditMode(false);
      }
    },
    [id, onSave]
  );

  const toggle = () => {
    setDownloadModalOpen((open) => !open);
  };

  const handleDownload = (file = {}) => {
    setFileToDownload(file);
    toggle();
  };

  const toggleEdit = () => {
    setEditMode((mode) => !mode);
  };

  const Content = useCallback(() => {
    if (!editMode) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <ShowMoreText>{isCommentEmpty ? '' : comment}</ShowMoreText>
          </Grid>
          {edited && (
            <Grid item>
              <Typography variant='caption'>(edited)</Typography>
            </Grid>
          )}
        </Grid>
      );
    }

    return (
      <Grid container spacing={2}>
        <CommentInput
          onSave={handleSave}
          hideAvatar
          defaultValue={comment || ''}
          showAvatar={false}
          showCancel
          onCancel={toggleEdit}
          defaultAttachments={attachments}
          componentId='THIS ONE'
        />
      </Grid>
    );
  }, [attachments, comment, editMode, edited, handleSave, isCommentEmpty]);

  if (loading) {
    return <DotLoader />;
  }

  return (
    <Grid container item spacing={2} alignItems='stretch'>
      <Grid item>
        <LetterAvatar user={user} />
      </Grid>
      <Grid container item xs spacing={2} justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Typography>{formatUserName(user)}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='caption'>{formatDateTime(created_at)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Content />
        </Grid>
        {!editMode && (
          <Grid container item alignItems='center'>
            <Grid item xs>
              <AttachedFiles attachments={attachments} onDownloadClick={handleDownload} />
            </Grid>
            {editable && (
              <Grid container item xs justifyContent='flex-end'>
                <IconButtonEnhanced color='primary' onClick={toggleEdit}>
                  <TooltipEnhanced title={'Edit'}>
                    <EditRounded />
                  </TooltipEnhanced>
                </IconButtonEnhanced>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <SecureDownload
        onClick={() => {
          downloadFile(fileDownloadLink(fileToDownload?.hash));
        }}
        open={downloadModalOpen}
        toggle={toggle}
        file={fileToDownload || {}}
        href={fileToDownload?.href}
      />
    </Grid>
  );
};

CommentContent.propTypes = {
  log: PropTypes.object,
  onSave: PropTypes.func.isRequired
};
export default CommentContent;
