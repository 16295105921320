import { gql } from '@apollo/client';

export const COUNT_MODULES = gql`
  query countModules($where: JSON!) {
    countModules(where: $where)
  }
`;

export const GET_MODULES = gql`
  query modules($where: JSON!, $start: Int, $limit: Int, $sort: String) {
    modules(where: $where, start: $start, limit: $limit, sort: $sort) {
      id
      name
      version
      wanted
      latest
      type
      issues {
        id
        name
        relation
        status {
          id
          name
          value
        }
        criticality {
          id
          name
          value
        }
        assignees {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
