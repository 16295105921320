/* eslint-disable quotes */

// views.app.assessments.singleCategory

export const SINGLE_CATEGORY = {
  points: 'points',
  buttons: {
    next: 'Next',
    back: 'Back',
    cyberAssessment: 'Cyber Assessment'
  }
};
