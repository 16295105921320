import { CheckCircleOutlineRounded, ContentCopyRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useCopyToClipboard } from '../../../hooks';
import IconButtonEnhanced from '../iconButton';

const ClipboardCopy = ({ text, ...props }) => {
  const [isCopied, setIsCopied] = useState(false);

  const { copy } = useCopyToClipboard();

  const handleCopy = () => {
    copy(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  return (
    <IconButtonEnhanced color='primary' size='small' onClick={handleCopy} {...props}>
      {!isCopied ? <ContentCopyRounded sx={{ fontSize: 18 }} /> : <CheckCircleOutlineRounded color='success' sx={{ fontSize: 18 }} />}
    </IconButtonEnhanced>
  );
};

ClipboardCopy.propTypes = {
  text: PropTypes.string.isRequired
};
export default ClipboardCopy;
