import { isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material';
import { Autocomplete, Checkbox, Chip, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { THEME } from '../../../../styles';
import TextFieldEnhanced from '../textField';

const UnCheckIcon = <CheckBoxOutlineBlankRounded fontSize='small' />;
const CheckedIcon = <CheckBoxRounded fontSize='small' />;

const CheckboxOption = (props, option, { selected }) => (
  <li {...props}>
    <Checkbox icon={UnCheckIcon} checkedIcon={CheckedIcon} style={{ marginRight: 8 }} checked={selected} />
    {option.name}
  </li>
);

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: alpha(theme.palette.background.paper, 0.05)
  },
  listbox: {
    backgroundColor: theme.palette.mode === 'light' ? `${theme.palette.background.default} !important` : `${THEME.TOOLBAR_COLOR} !important`
  }
}));
/**
 * @typedef {import('@mui/material').AutocompleteProps} AutocompleteProps
 *
 * @param {String} label
 * @param {Array} options
 * @param {String | Array} value
 * @param {Function} onChange
 * @param {Boolean} multiple
 * @param {Boolean} checkbox
 * @param {'outlined' | 'contained' } variant
 * @param {Boolean} hasError
 * @param {{ errorText: String } & AutocompleteProps} props
 * @returns {JSX}
 */
const AutoCompleteEnhanced = ({
  id,
  label,
  options,
  value,
  fixedOptions,
  onChange,
  multiple,
  freeSolo,
  checkbox,
  variant = 'outlined',
  hasError = false,
  errorText = ' ',
  disabled,
  loading = false,
  limitTags = 2,
  required = false,
  ...props
}) => {
  const classes = useStyles();
  const [localOptions, setLocalOptions] = useState([]);

  //Ensures that fixedOption array of IDs is always converted to array of String
  const localFixedOptions = useMemo(() => {
    if (!(fixedOptions || []).length) {
      return [];
    }
    return fixedOptions.map((id) => String(id));
  }, [fixedOptions]);

  useEffect(() => {
    if (!options || !options.length) {
      return;
    }
    setLocalOptions(options);
  }, [options]);

  const handleChange = (_event, data) => {
    // single type autocomplete
    if (!multiple) {
      const { id, inputValue } = data || {};
      return onChange(id || inputValue || '', data);
    }

    // multiple type autocomplete
    const values = (data || []).map((datum) => datum.id || datum.value) || [];
    return onChange(values, data);
  };

  const renderDefaultValue = useCallback(() => {
    if (!value) {
      return multiple ? [] : '';
    }

    if (!multiple) {
      return localOptions?.find((option) => isIDEqual(option.id, value)) || '';
    }

    return localOptions?.filter((option) => {
      const { id } = option || {};

      return (value || []).includes(id);
    });
  }, [multiple, localOptions, value]);

  return (
    <Autocomplete
      id={String(id)}
      classes={{
        listbox: classes.listbox
      }}
      freeSolo={freeSolo}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      getOptionLabel={(option) => option.name || option.label || option.id || ''}
      getOptionDisabled={(option) => option.disabled || (localFixedOptions || []).includes(String(option?.id))}
      disablePortal
      options={localOptions}
      value={renderDefaultValue() || null}
      renderInput={(params) => (
        <TextFieldEnhanced {...params} label={label || ''} variant={variant} error={hasError} helperText={errorText} disabled={disabled} required={required} />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { id, name, disabled } = option || {};
          return <Chip key={id || name} label={name || ''} {...getTagProps({ index })} disabled={disabled || (localFixedOptions || []).includes(String(id))} />;
        })
      }
      {...(checkbox && {
        renderOption: CheckboxOption
      })}
      disabled={disabled}
      onChange={handleChange}
      loading={loading}
      limitTags={limitTags}
      {...props}
    />
  );
};

AutoCompleteEnhanced.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fixedOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  checkbox: PropTypes.bool,
  variant: PropTypes.bool,
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  creatable: PropTypes.bool,
  freeSolo: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  limitTags: PropTypes.number,
  required: PropTypes.bool
};

export default AutoCompleteEnhanced;
