import { useReactiveVar } from '@apollo/client';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { themeVar, userVar } from './api';
import { Toast } from './components/global';
import IdleTimerHandler from './components/global/idleTimer';
import { appName } from './constants/defaults';
import ContextProvider from './contexts';
import PageViewProvider from './contexts/logPageViewContext';
import { ScrollToTop } from './helpers/routing';
import { initialiseAmplitude } from './library';
import { GlobalStyles } from './styles';
import { SYSTEM_THEME } from './styles/theme';
import Main from './views';
import Application from './views/app';
import Error from './views/error';
import User from './views/user';

const AuthRoute = ({ children, userId }) => {
  if (!userId) {
    return <Navigate to='/user/login' />;
  }
  return (
    <ContextProvider>
      <IdleTimerHandler />
      {children}
    </ContextProvider>
  );
};

AuthRoute.propTypes = {
  children: PropTypes.element.isRequired,
  userId: PropTypes.any
};

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const userId = useReactiveVar(userVar);
  const localTheme = useReactiveVar(themeVar);

  useEffect(() => {
    document.title = appName;
  }, []);

  useEffect(() => {
    if (localTheme) {
      return;
    }
    const customTheme = SYSTEM_THEME(prefersDarkMode);
    themeVar(customTheme);
    localStorage.setItem('theme', JSON.stringify(customTheme));
  }, [prefersDarkMode, localTheme]);

  const theme = useMemo(() => createTheme(localTheme || SYSTEM_THEME(prefersDarkMode)), [localTheme, prefersDarkMode]);

  //Initial amplitude init so that login page etc events collected
  useEffect(() => {
    initialiseAmplitude();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Toast />
        <Fragment>
          <Router>
            <ScrollToTop>
              <Routes>
                <Route path='/' element={<Main />} />
                <Route
                  path='app/*'
                  element={
                    <AuthRoute userId={userId}>
                      <Application />
                    </AuthRoute>
                  }
                />
                <Route
                  path='user/*'
                  element={
                    <PageViewProvider>
                      <User />
                    </PageViewProvider>
                  }
                />
                <Route path='error' element={<Error />} />
                <Route path='*' element={<Navigate to='/app' />} />
              </Routes>
            </ScrollToTop>
          </Router>
        </Fragment>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
