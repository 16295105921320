import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import sideMenu from '../../../constants/sideMenu';
import { useUserContext } from '../../../contexts';
import { formatDate } from '../../../helpers/formatUtils';
import { ButtonEnhanced, TypographyEnhanced } from '../../global';

const Heading = ({
  id,
  value = {},
  description = '',
  descriptionValue = {},
  children,
  hideBreadcrumb = false,
  date,
  hideDivider = false,
  containerProps = {}
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { defaultUser } = useUserContext();

  const breadcrumbs = useMemo(() => {
    const result = [];
    const getParentTree = (menu) => {
      return menu
        ?.map((item) => {
          if (pathname.includes(item?.to)) {
            result.push(item);
            return getParentTree(item?.subs);
          }
          return null;
        })
        .filter((item) => item);
    };

    getParentTree(sideMenu);

    if (result?.[result?.length - 1]?.to !== pathname) {
      result.push({ id, label: id, to: pathname });
    }

    return result;
  }, [id, pathname]);

  return (
    <Grid container spacing={1.5} marginBottom={'20px'} {...containerProps}>
      {!hideBreadcrumb && !!breadcrumbs?.length && !defaultUser && (
        <Grid item xs={12}>
          <Breadcrumbs separator={<NavigateNext fontSize='default' />}>
            {(breadcrumbs || []).map((breadcrumb, index) => {
              const { id, label, to } = breadcrumb || {};
              return (
                <ButtonEnhanced variant='text' size='small' disabled={!index || index === breadcrumbs.length - 1} onClick={() => navigate(to)} key={id}>
                  <TypographyEnhanced id={label} value={value} />
                </ButtonEnhanced>
              );
            })}
          </Breadcrumbs>
        </Grid>
      )}

      <Grid item xs={12}>
        <TypographyEnhanced id={id} variant='h4' style={{ fontWeight: 'bold' }} display='inline' value={value} />
        {date && <TypographyEnhanced id={formatDate(date)} variant='h4' display='inline' />}
      </Grid>
      {description && (
        <Grid item xs={12}>
          <TypographyEnhanced id={description} value={descriptionValue} variant='caption' />
        </Grid>
      )}

      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
      {!hideDivider && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
    </Grid>
  );
};

Heading.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object,
  description: PropTypes.string,
  descriptionValue: PropTypes.object,
  children: PropTypes.any,
  hideBreadcrumb: PropTypes.bool,
  hideDivider: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  containerProps: PropTypes.object
};

export default Heading;
