import { gql } from '@apollo/client';

export const GET_SINGLE_CYBER_ASSESSMENT_CATEGORY_WITH_CUSTOM_VALUES = gql`
  query getCustomCategory($id: ID!) {
    getCustomCategory(id: $id)
  }
`;

export const GET_SINGLE_CYBER_ASSESSMENT_CATEGORY = gql`
  query category($id: ID!) {
    category(id: $id) {
      id
      index
      level
      name
      description
      parent {
        id
      }
      questions(sort: "index:ASC", where: { _or: [{ archived: false }, { archived_null: true }] }) {
        id
        index
        mappingNumber
        name
        description
        type
        scale
        datapoint {
          id
          answer
          other
          attachments
          notApplicable
          value
          selected {
            id
            name
            disabled
          }
        }
        options(sort: "score:ASC") {
          id
          name
          type
          value
          score
          customScore
        }
        parent {
          id
        }
        controlMappings(sort: "increase:DESC") {
          id
          increase
          destinationControl {
            id
            name
            mappingNumber
            framework {
              name
            }
            datapoint {
              id
              value
              selected {
                id
                value
              }
            }
          }
        }
        controlMappingsFrom(sort: "increase:DESC") {
          id
          increase
          sourceControl {
            id
            name
            mappingNumber
            framework {
              name
            }
            datapoint {
              id
              value
              selected {
                id
                value
              }
            }
          }
        }
      }
    }
  }
`;
