const DEFAULT_OPTIONS = {
  legend: {},
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    show: false,
    showTitle: false,
    feature: {
      dataView: { show: false, readOnly: false },
      magicType: { show: true, type: ['bar', 'line'] },
      restore: { show: false },
      saveAsImage: { show: false }
    }
  },
  xAxis: [
    {
      type: 'category',
      data: [],
      axisPointer: {
        type: 'shadow'
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: 'Series 1',
      interval: 1,
      axisLabel: {
        formatter: '{value}'
      }
    }
  ],
  series: [
    {
      name: 'Series 1',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value;
        }
      },
      data: []
    }
  ]
};

export { DEFAULT_OPTIONS };
