import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';
import { useCriticalities, useStatuses } from '../../hooks';

const CriticalityAndStatusContext = createContext();

/**
 *
 * @returns {{
 * criticalities: [JSON],
 * statuses: [JSON],
 * refetch: (variables?: Partial<{user: *}>) => Promise<ApolloQueryResult<any>>,
 * loading: boolean,
 * error: ApolloError,
 * }}
 */
export const useCriticalityAndStatusContext = () => {
  return useContext(CriticalityAndStatusContext);
};

const CriticalityAndStatusProvider = ({ children }) => {
  const { statuses, loading: statusesLoading, error: statusError, refetch: statusesRefetch } = useStatuses();
  const { criticalities, loading: criticalitiesLoading, error: criticalitiesError, refetch: criticalitiesRefetch } = useCriticalities();

  const refetch = () => {
    statusesRefetch();
    criticalitiesRefetch();
  };

  const value = {
    statuses,
    criticalities,
    loading: statusesLoading || criticalitiesLoading,
    refetch,
    error: statusError || criticalitiesError
  };

  return <CriticalityAndStatusContext.Provider value={value}>{children}</CriticalityAndStatusContext.Provider>;
};

CriticalityAndStatusProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired
};

export default CriticalityAndStatusProvider;
