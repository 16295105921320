import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { Collapse, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ButtonEnhanced, TypographyEnhanced } from '../../../../../../../../../../global';
import Mapping from '../../mapping';

const FrameworkControls = ({ name, mappings, satisfactory, refetch }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((open) => !open);
  };

  return (
    <Grid container item spacing={2} key={name}>
      <Grid item xs={12}>
        <ButtonEnhanced variant='text' color='primary' onClick={toggle}>
          <TypographyEnhanced id={name || ''} />
          {open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
        </ButtonEnhanced>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={open}>
          <Grid container item spacing={1}>
            {(mappings || []).map((mapping) => {
              const { id, destinationControl, increase } = mapping || {};
              return (
                <Grid item key={id}>
                  <Mapping control={destinationControl} refetch={refetch} increase={increase} satisfactory={satisfactory} />
                </Grid>
              );
            })}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

FrameworkControls.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mappings: PropTypes.arrayOf(PropTypes.object),
  refetch: PropTypes.func,
  satisfactory: PropTypes.bool
};

export default FrameworkControls;
