const defaultOptions = {
  series: [
    {
      name: 'PROGRESS CHART',
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {}
      },
      axisLine: {
        lineStyle: {
          width: 40,
          opacity: 0.2,
          color: [[1, '#F3F5FC']] // empty track color
        }
      },
      splitLine: {
        show: false,
        distance: 0,
        length: 10
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      data: [{ value: 10, name: 'DATAVALUE' }],
      title: {
        show: false
      },
      detail: {
        fontSize: 12,
        color: '#000',
        formatter: '{value}%'
      }
    }
  ]
};

export default defaultOptions;
