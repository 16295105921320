import { CRITICALITIES, STATUSES } from '@anirudhm9/base-lib/lib/constants';
import { formatUserName } from '@anirudhm9/base-lib/lib/utils';
import { PersonRounded } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ChipEnhanced, TooltipEnhanced, TypographyEnhanced } from '../../../../global';
import IssueChip from '../issueChip';
import IssueLogo from '../logo';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.default
  },
  accordionBackground: {
    backgroundColor: theme.palette.background.paper
  },
  card: {
    width: '100%',
    marginLeft: '0 !important'
  },
  usernameLine: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px'
  }
}));

const SingleIssueBar = ({ issue, onClick, cardProps }) => {
  const classes = useStyles();

  const { name, criticality, status, assignees = [], relation } = issue || {};

  const handleClick = () => {
    try {
      onClick();
    } catch (error) {
      console.error(error);
    }
  };

  const CardButton = (props) => {
    return <Button {...props} fullWidth />;
  };

  return (
    <Card className={classes.background} onClick={handleClick} component={CardButton} {...(cardProps || {})}>
      <CardContent className={classes.card}>
        <Grid container justifyContent='space-between' alignItems='center' spacing={2}>
          <Grid container item xs={12} spacing={2} justifyContent={'space-between'}>
            <Grid container item sm alignItems='center'>
              <IssueChip item={CRITICALITIES[criticality?.value]} icon={<IssueLogo relation={relation} />} title='Criticality' tooltip />
              <TypographyEnhanced id={name || ''} display='inline' ml={1} />
            </Grid>

            <Grid container item sm spacing={2} justifyContent='flex-end' alignItems='center'>
              {!assignees?.length ? null : (
                <Grid item>
                  <TooltipEnhanced
                    title={
                      <Box>
                        {assignees?.map((assignee) => (
                          <TypographyEnhanced key={assignee.id} id={formatUserName(assignee)} variant='caption' display='block' />
                        ))}
                      </Box>
                    }
                  >
                    <ChipEnhanced id={String(assignees?.length || 0)} icon={<PersonRounded />} size='small' />
                  </TooltipEnhanced>
                </Grid>
              )}
              <Grid item justifyContent='flex-end'>
                <IssueChip item={STATUSES[status?.value]} title='Status' tooltip />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SingleIssueBar.propTypes = {
  issue: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string
  }),
  onClick: PropTypes.func,
  cardProps: PropTypes.object
};
export default SingleIssueBar;
