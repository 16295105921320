import PropTypes from 'prop-types';
import { useState } from 'react';
import { animated, useSpring } from 'react-spring';

const Fade = ({ config, children }) => {
  const [flip, setFlip] = useState(false);

  const defaultConfig = {
    to: { opacity: 1 },
    from: { opacity: 0 },
    reset: true,
    reverse: flip,
    delay: 400,
    onRest: () => setFlip(!flip)
  };

  const fade = useSpring(config || defaultConfig);

  return <animated.div style={fade}>{children || <div></div>}</animated.div>;
};

Fade.propTypes = {
  config: PropTypes.object,
  children: PropTypes.element
};

export default Fade;
