import { EVENTS } from '../../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.app.reports.news.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.APP.REPORTS.NEWS[event];
};

// export const { NEWS: REPORTS_NEWS_EVENTS } = EVENTS.VIEWS.APP.REPORTS || {};
