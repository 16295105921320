import { logEvent } from '../../../library';
import { NOTIFICATIONS as NOTIFICATIONS_EVENTS } from '../../../library/amplitude/events/common';

/**
 * Logs event when user opens up notifications from the app toolbar
 *
 * @param {String | Number} id
 * @param {String} link
 * @param {VoidFunction} navigate
 * @param {Function} handleClose
 */
const logViewNotifications = (id, link, navigate, handleClose) => {
  logEvent(NOTIFICATIONS_EVENTS.VIEW_TASK, { notification_id: id });
  navigate(link);
  handleClose();
};

/**
 * Logs event when user changes tabs in the notifications modal
 *
 * @param {String | Number} value
 */
const logNotificationCurrentTab = (value) => {
  value === 1 ? logEvent(NOTIFICATIONS_EVENTS.VIEW_READ) : logEvent(NOTIFICATIONS_EVENTS.VIEW_UNREAD);
};

/**
 * Logs event when user marks a single issue or all issues as read
 *
 * @param {String | Number} id
 */
const logMarkAsRead = (id) => {
  id ? logEvent(NOTIFICATIONS_EVENTS.MARK_AS_READ, { notification_id: id }) : logEvent(NOTIFICATIONS_EVENTS.MARK_ALL_AS_READ);
};

/**
 * Logs event when user changes page in the notifications modal
 *
 * @param {String | Number} value
 * @param {String | Number} page
 */
const logPageChange = (value, page) => {
  logEvent(NOTIFICATIONS_EVENTS.NAVIGATION, { page_prev: page, page_current: value });
};

/**
 * Logs event when user changes row number in notifications modal
 *
 * @param {String | Number} value
 * @param {String | Number} prevValue
 */
const logRowChange = (value, prevValue) => {
  logEvent(NOTIFICATIONS_EVENTS.ROWS_PER_PAGE, { old: prevValue, new: value });
};

export { logViewNotifications, logNotificationCurrentTab, logMarkAsRead, logPageChange, logRowChange };
