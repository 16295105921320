import { useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import LogRocket from 'logrocket';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { orgVar, setOrgId } from '../../api';
import { DotLoader } from '../../components/global';
import { logRocketId } from '../../constants/defaults';
import { useUserContext } from '../../contexts';
import IntegrationProvider from '../../contexts/integrationContext';
import ShadowAssessmentProvider from '../../contexts/shadowAssessmentContext';
import UsersProvider from '../../contexts/usersContext';
import { RoleProtectedRoute, getInitialUrlForRole, handleLogout } from '../../helpers/routing';
import { useOrgData } from '../../hooks';
import { AppLayout } from '../../layout';
import { initialUserProperties, initialiseAmplitude } from '../../library';
import Assessments from './assessments';
import Dashboard from './dashboard';
import Integrations from './integrations';
import Manage from './manage';
import Onboarding from './onboarding';
import Reports from './reports';
import Rewards from './rewards';
import Settings from './settings';

const Application = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation() || {};

  const localOrgId = useReactiveVar(orgVar);

  const { userId, user, error: userError, loading: userLoading } = useUserContext();

  const { orgId, org, error: orgError, loading: orgLoading } = useOrgData(localOrgId || user?.org?.id);

  const [logRocketInitialized, setLogRocketInitialized] = useState(false);

  if (logRocketId) {
    LogRocket.init(logRocketId);
  }

  useEffect(() => {
    if (!org?.id) {
      return;
    }
    setOrgId(org?.id, org?.name);
  }, [org?.id, org?.name, orgId]);

  //Initial amplitude setup: intialise session, record login, set initial user properties
  useEffect(() => {
    if (_.isEmpty(user) || userLoading) {
      return;
    }

    const { id, email, firstName, lastName } = user || {};
    //Sets unique user ID within amplitude
    initialiseAmplitude(`${id} ${firstName} ${lastName}`);
    //Sets or updates initial user properties (ie if user has changed their email)
    const userProperties = {
      email,
      firstName,
      id,
      lastName
    };

    initialUserProperties(userProperties);
  }, [user, userLoading]);

  if (userError || orgError) {
    console.error('error', userError || orgError);
    handleLogout(navigate);
  }

  if (logRocketId && user && !logRocketInitialized) {
    const userName = `${user?.firstName} ${user?.lastName}`;
    LogRocket.identify(`[${window.location.hostname}][${userId}] ${userId} ${userName}`, {
      name: userName,
      userId
    });
    setLogRocketInitialized(true);
  }

  // user role values for org
  // const roles = user?.orgRoles?.map((role) => role.value) || [];
  const roles = useMemo(() => {
    if (userLoading || userError) {
      return [];
    }
    return user?.orgRoles?.map((role) => role.value);
  }, [user?.orgRoles, userError, userLoading]);

  // Gets the inital landing page for the user according to the role
  // const initialUrl = getInitialUrlForRole(roles, org?.onboarded, userId);
  const initialUrl = useMemo(() => {
    if (!userId || userLoading || userError) {
      return '';
    }
    return getInitialUrlForRole(roles, org?.onboarded, userId);
  }, [org?.onboarded, roles, userError, userId, userLoading]);

  if (userLoading || orgLoading || !user || !user?.email || (orgId && !org && !orgLoading) || !initialUrl) {
    return <DotLoader />;
  }

  // dont forget to add to allowed pages if adding new routes
  return (
    <AppLayout {...props} roles={roles}>
      <Routes>
        <Route from='/' element={<Navigate to={initialUrl} />} />
        <Route path='settings/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={Settings} />} />
        <Route path='app/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={Dashboard} />} />
        <Route
          path='onboarding/*'
          element={
            <IntegrationProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={Onboarding} />
            </IntegrationProvider>
          }
        />
        <Route
          path='dashboard/*'
          element={
            <UsersProvider>
              <IntegrationProvider>
                <RoleProtectedRoute roles={roles} path={pathname} element={Dashboard} />
              </IntegrationProvider>
            </UsersProvider>
          }
        />
        <Route path='rewards/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={Rewards} />} />
        <Route path='integrations/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={Integrations} />} />
        <Route path='reports/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={Reports} />} />
        <Route path='manage/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={Manage} />} />
        <Route
          path='assessment/*'
          element={
            <ShadowAssessmentProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={Assessments} />
            </ShadowAssessmentProvider>
          }
        />
        <Route path='*' element={<Navigate to={initialUrl} />} />
      </Routes>
    </AppLayout>
  );
};

Application.propTypes = {};

export default Application;
