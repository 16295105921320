import { Card, CardMedia, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { logEvent } from '../../../../../library';
import { Watermark } from '../../../../ui';
import PointsChip from '../../pointsChip';
import AchievementStatus from './achievementStatus';
import ClaimPointsModal from './claimPointsModal';
import { LOG_EVENT_PATH } from './constants/defaults';
import MoreInfoModal from './moreInfoModal';

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    // minHeight: '270px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cardContent: {
    height: '100%',
    padding: '32px'
  },
  image: {
    height: '64px',
    margin: 'auto'
  },
  icon: {
    height: '100px',
    width: '100%',
    margin: '30px auto'
  }
}));

const AchievementCard = ({ data }) => {
  const classes = useStyles();
  const [status, setStatus] = useState('NOT_STARTED');
  const [open, setMoreInfoOpen] = useState(false);
  const [claimModalOpen, setClaimModalOpen] = useState(false);

  const { label, image, pointValue, percentComplete, locked, claimed } = data || {};

  useEffect(() => {
    let currentStatus = 'NOT_STARTED';

    if (percentComplete === 100) {
      currentStatus = 'COMPLETE';
    } else if (percentComplete < 100 && percentComplete > 0) {
      currentStatus = 'IN_PROGRESS';
    }

    if (locked) {
      currentStatus = 'LOCKED';
    }

    setStatus(currentStatus);
  }, [locked, percentComplete]);

  const toggleMoreModal = () => {
    // log when opening modal
    !open && logEvent(LOG_EVENT_PATH('OPEN_MORE_INFO_MODAL'), { achievement: label });

    setMoreInfoOpen(!open);
  };

  const toggleClaimModal = () => {
    // log when opening modal
    !claimModalOpen && logEvent(LOG_EVENT_PATH('OPEN_REDEEM_MODAL'), { achievement: label });

    setMoreInfoOpen(false);
    setClaimModalOpen(!claimModalOpen);
  };

  const pointsChipType = () => {
    if (claimed) {
      return 'CLAIMED';
    }

    return 'DEFAULT';
  };

  return (
    <Fragment>
      <MoreInfoModal data={data} status={status} open={open} toggleModal={toggleMoreModal} toggleRedeem={toggleClaimModal} />
      <ClaimPointsModal data={{ pointValue, label }} open={claimModalOpen} toggleModal={toggleClaimModal} />
      <Watermark>
        <Card className={classes.card} onClick={() => toggleMoreModal()}>
          <CardMedia component='img' height='170' image={image} alt='background-image' />
          <Grid container spacing={1} direction='column' className={classes.cardContent}>
            <Grid item container justifyContent='center'>
              <PointsChip type={pointsChipType()} pointValue={pointValue} />
            </Grid>

            <Grid item xs container spacing={3}>
              <Grid item xs={12}>
                <Typography variant='body1' paragraph align='center' style={{ fontWeight: 'bold' }}>
                  {label}
                </Typography>
                <Grid item xs={12}>
                  <AchievementStatus data={data} onClaim={() => toggleClaimModal()} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Watermark>
    </Fragment>
  );
};

AchievementCard.propTypes = {
  data: PropTypes.any
};

export default AchievementCard;
