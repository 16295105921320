import { gql } from '@apollo/client';

export const COUNT_CONTROL_MAPPINGS = gql`
  query countControlMappings($where: JSON!) {
    countControlMappings(where: $where)
  }
`;

export const GET_OBJECTIVE_CONTROL_MAPPINGS_FOR_SUBJECTIVE_FRAMEWORK = gql`
  query controlmappings($where: JSON, $start: Int, $limit: Int) {
    controlmappings(where: $where, sort: "increase:DESC", start: $start, limit: $limit) {
      id
      increase
      sourceControl {
        id
        name
        mappingNumber
        datapoint {
          id
          answer
          value
          notApplicable
          selected {
            id
            name
            value
            score
          }
        }
        options(sort: "score:ASC") {
          id
          name
          value
        }
      }
    }
  }
`;

export const GET_SUBJECTIVE_CONTROL_MAPPINGS_FOR_OBJECTIVE_FRAMEWORK = gql`
  query controlmappings($where: JSON, $start: Int, $limit: Int) {
    controlmappings(where: $where, sort: "increase:DESC", start: $start, limit: $limit) {
      id
      increase
      destinationControl {
        id
        name
        mappingNumber
        datapoint {
          answer
          value
          notApplicable
        }
        framework {
          id
          name
        }
      }
    }
  }
`;

export const GET_CONTROL_MAPPING_SCORE = gql`
  query getMappingScore($where: JSON) {
    getMappingScore(where: $where)
  }
`;
