import { FRAMEWORKS } from '@anirudhm9/base-lib/lib/constants';
import { getScoreTotals } from '@anirudhm9/base-lib/lib/utils';
import { useTheme } from '@emotion/react';
import { alpha } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useIsDesktop } from '../../../../../hooks';
import { ColumnChart } from '../../../../ui';
import { CLOUD_ANSWER_OPTIONS } from '../constants';
import { logLegendChange, logMagicTypeChange } from '../utils';

const Chart = ({ category }) => {
  const isDesktop = useIsDesktop();
  const theme = useTheme();
  const { children } = category || {};

  const [options, setOptions] = useState({});

  useEffect(() => {
    if (!(children || []).length) {
      return;
    }

    const { text, success, error } = theme.palette || {};

    const ANSWER_OPTIONS = {
      YES: {
        label: CLOUD_ANSWER_OPTIONS.YES.label,
        value: FRAMEWORKS.AWS_SECURITY_HUB_FRAMEWORK.answers.YES.value,
        itemStyle: {
          color: success.main
        },
        areaStyle: {
          color: alpha(success.main, 0.5)
        }
      },
      NO: {
        label: CLOUD_ANSWER_OPTIONS.NO.label,
        value: FRAMEWORKS.AWS_SECURITY_HUB_FRAMEWORK.answers.NO.value,
        itemStyle: {
          color: error.main
        },
        areaStyle: {
          color: alpha(error.main, 0.5)
        }
      }
    };

    const indicator = [],
      data = [
        {
          ...ANSWER_OPTIONS.YES,
          name: ANSWER_OPTIONS.YES.label,
          data: []
        },
        {
          ...ANSWER_OPTIONS.NO,
          name: ANSWER_OPTIONS.NO.label,
          data: []
        }
      ];

    for (const child of children) {
      const categoryWithScores = getScoreTotals(child || {});
      const { total, points } = categoryWithScores || {};

      indicator.push({
        name: child?.name?.length > 20 ? `${child?.name?.slice(0, 8)}...` : child?.name,
        max: total,
        color: text?.primary
      });

      const yesData = data.find((datum) => datum.name === ANSWER_OPTIONS.YES.label);
      const noData = data.find((datum) => datum.name === ANSWER_OPTIONS.NO.label);

      yesData.data.push(points);
      noData.data.push(total - points);
    }

    const options = {
      toolbox: {
        show: false
      },
      xAxis: [
        {
          data: children.map((child) => child.name) || [],
          axisLabel: {
            interval: 0
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Score',
          interval: 3,
          itemStyle: {
            color: text?.primary
          }
        }
      ],
      // name: 'Device OS',
      series: Object.values(ANSWER_OPTIONS).map((option) => {
        const { label, type = isDesktop ? 'bar' : 'line', itemStyle } = option || {};
        const seriesData = data.find((datum) => datum.name === label);

        return {
          name: label,
          type,
          itemStyle,
          tooltip: {
            valueFormatter: function (value) {
              return value;
            }
          },
          data: seriesData.data
        };
      })
    };
    setOptions(options);
  }, [children, isDesktop, theme.palette]);

  return (
    <ColumnChart
      options={options}
      onEvents={{
        legendSelectChanged: logLegendChange,
        magicTypeChanged: logMagicTypeChange
      }}
    />
  );

  // return (
  //   <StackedDonutChart
  //     options={{
  //       radiusAxis: {
  //         data: indicator.map((item) => item.name)
  //       },
  //       series: data,
  //       legend: {
  //         data: Object.values(ANSWER_OPTIONS).map((option) => option.label)
  //       }
  //     }}
  //   />
  // );
};

Chart.propTypes = {
  category: PropTypes.object
};

export default Chart;
