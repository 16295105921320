import { EVENTS } from '../../../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.app.rewards.redeem.defaultRedeem.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.APP.REWARDS.REDEEM.DEFAULT_REDEEM[event];
};

// filters options to display
export const filterOptions = ['KEYWORD', 'CATEGORY', 'STATUS'];
