import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { TypographyEnhanced } from '../../../../global';
import NewsItem from './newsItem';

const CyberNews = ({ application, onClick }) => {
  const { id, thirdparty = {} } = application || {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TypographyEnhanced id={'Cyber News'} variant='overline' />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {(thirdparty?.newsItems || [])?.map((newsItem) => (
          <Grid key={newsItem.id} item xs={12} sm={6} md={4}>
            <NewsItem applicationId={id} newsItem={newsItem} src={thirdparty?.logoUrl || ''} alt={thirdparty?.name || ''} limit={10000} onClick={onClick} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

CyberNews.propTypes = {
  application: PropTypes.object,
  onClick: PropTypes.func
};

export default CyberNews;
