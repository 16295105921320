import { Grid, LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { TypographyEnhanced } from '../../../../../../../global';
import PointsChip from '../../../../../pointsChip';
import { TRANSLATION_PATH } from '../constants/defaults';

const Locked = ({ type = 'basic' }) => {
  switch (type) {
    case 'basic':
      return (
        <Grid item xs={12}>
          <LinearProgress variant='determinate' value={0} color='primary' />
          <TypographyEnhanced id={TRANSLATION_PATH('locked.locked')} variant='caption' paragraph align='center' style={{ margin: '0' }} />
        </Grid>
      );
    default: //detailed
      return (
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <PointsChip type='LOCKED' />
          </Grid>
          <Grid item xs={12}>
            <TypographyEnhanced id={TRANSLATION_PATH('locked.description')} variant='caption' paragraph />
          </Grid>
        </Grid>
      );
  }
};

Locked.propTypes = {
  type: PropTypes.string
};

export default Locked;
