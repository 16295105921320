import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { FIREBASE_CONFIG } from './constants';

const getFirebaseAuth = () => {
  try {
    const firebaseApp = initializeApp(FIREBASE_CONFIG);
    return getAuth(firebaseApp);
  } catch (error) {
    console.error(error);
  }
};
const FIREBASE_AUTH = getFirebaseAuth();

const GOOGLE_PROVIDER = new GoogleAuthProvider();
const MICROSOFT_PROVIDER = new OAuthProvider('microsoft.com');

export * from './helpers';
export { FIREBASE_AUTH, GOOGLE_PROVIDER, MICROSOFT_PROVIDER };
