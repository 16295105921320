// EVENTS.VIEWS.APP.MANAGE.SINGLE_USER

export const SINGLE_USER = {
  ARCHIVE_USER: 'individual_user-archive_user',
  UNARCHIVE_USER: 'individual_user-unarchive_user',
  UPDATE_USER: 'individual_user-edit_user',
  VIEW_DETAILS: 'individual_user-view_details',
  CLICK_ADD_APPLICATION: 'individual_user-click_add_application',
  VIEW_APPLICATION: 'individual_user-view_application'
};
