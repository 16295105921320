const _ = require('lodash');
const { symmetricDifference, stripHtmlTags } = require('../../common');

const formatOptions = (options, other) => {
  if (!options || !options.length) {
    return [];
  }
  return (
    options
      .map((option) => {
        if (other && option.name === 'Other') {
          return `Other: ${other}`;
        }
        return option.name;
      })
      .join(', ') || []
  );
};

const FORMAT_KEYS = {
  FROM: '<b>from</b>',
  FOR: '<b>for</b>',
  TO: '<b>to</b>',
  UPDATED: '<b>updated</b>',
  SET: '<b>set</b>',
  UPLOADED: '<b>uploaded</b>',
  CLEARED: '<b>cleared</b>'
};

const formatLog = (question, newDatapoint) => {
  const { index: questionIndex, datapoint: oldDatapoint, type } = question || {};
  const { selected: oldSelectedOptions = [], answer: oldAnswer = '', other: oldOther = '' } = oldDatapoint || {};
  const { attachments: newAttachments = [], selected: newSelectedOptions = [], answer: newAnswer = '', other: newOther = '' } = newDatapoint || {};

  const EMPTY_ANSWER_COMMENT = `${FORMAT_KEYS.CLEARED} the answer for Q.${questionIndex}`;

  const formatSelectLog = () => {
    if (!newSelectedOptions || !newSelectedOptions.length) {
      return EMPTY_ANSWER_COMMENT;
    }

    // Difference between new selected options and pre-selected options
    const difference = symmetricDifference(
      (newSelectedOptions || []).map((option) => option.value),
      (oldSelectedOptions || []).map((option) => option.value)
    ).length;

    // if there is no difference between the ids of the options selected and other option has not been updated
    if (!difference && oldOther === newOther) {
      return false;
    }

    let comment = '';

    const oldSelectedOptionNames = formatOptions(oldSelectedOptions, oldOther);
    const newSelectedOptionNames = formatOptions(newSelectedOptions, newOther);

    if (oldDatapoint && oldSelectedOptionNames.length) {
      comment = `${FORMAT_KEYS.UPDATED} Q.${questionIndex} ${FORMAT_KEYS.FROM} ${oldSelectedOptionNames} ${FORMAT_KEYS.TO}  ${newSelectedOptionNames}`;
    } else {
      comment = `${FORMAT_KEYS.SET} Q.${questionIndex} ${FORMAT_KEYS.TO} ${newSelectedOptionNames}`;
    }
    return comment;
  };

  const formatTextLog = () => {
    const newAnswerText = stripHtmlTags(newAnswer);
    const oldAnswerText = stripHtmlTags(oldAnswer);

    if (newAnswerText === oldAnswerText) {
      return null;
    }

    if (!newAnswerText) {
      return EMPTY_ANSWER_COMMENT;
    }

    let comment = '';
    if (oldDatapoint && oldAnswerText) {
      comment = `${FORMAT_KEYS.UPDATED} Q.${questionIndex} ${FORMAT_KEYS.FROM} ${oldAnswer} ${FORMAT_KEYS.TO} ${newAnswer}`;
    } else {
      comment = `${FORMAT_KEYS.SET} Q.${questionIndex} ${FORMAT_KEYS.TO} ${newAnswer}`;
    }
    return comment;
  };

  const formatUploadLog = () => {
    const attachmentLength = (newAttachments || []).length;
    if (!attachmentLength) {
      return EMPTY_ANSWER_COMMENT;
    }

    return `${FORMAT_KEYS.UPLOADED} ${attachmentLength} attachment/s ${FORMAT_KEYS.TO} Q.${questionIndex}`;
  };

  // Default comment
  let comment = '';
  switch (type) {
    // creatable, multiselect, and select uses the same function
    case 'CREATABLE':
    case 'MULTI_SELECT':
    case 'SELECT':
      comment = formatSelectLog();
      break;

    case 'UPLOAD':
      comment = formatUploadLog();
      break;

    // default for textbox and textarea
    default:
      comment = formatTextLog();
  }

  return comment;
};

const formatData = (datapoint = {}) => {
  if (!datapoint) {
    return {};
  }

  const keysToSave = ['id', 'selected', 'answer', 'other', 'attachments'];
  return _.pick(datapoint, keysToSave);
};

module.exports = {
  FORMAT_KEYS,
  formatLog,
  formatData
};
