import { gql } from '@apollo/client';

export const CREATE_ANSWER_OPTION = gql`
  mutation createAnsweroption($data: AnsweroptionInput!) {
    createAnsweroption(input: { data: $data }) {
      answeroption {
        id
        name
      }
    }
  }
`;

export const GET_ANSWER_OPTIONS = gql`
  query answeroptions($where: JSON) {
    answeroptions(where: $where) {
      id
      name
      value
    }
  }
`;
