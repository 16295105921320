import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../../contexts';
import UsersProvider from '../../../../contexts/usersContext';
import { RoleProtectedRoute } from '../../../../helpers/routing';

import ManageUsers from './default';
import ViewUser from './viewUser';

const Users = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <UsersProvider>
        <Routes>
          <Route path='/' element={<RoleProtectedRoute roles={roles} path={pathname} element={ManageUsers} />} />
          <Route path='/:id' element={<RoleProtectedRoute roles={roles} path={pathname} element={ViewUser} />} />
          <Route path='*' element={<RoleProtectedRoute roles={roles} path={pathname} element={ManageUsers} />} />
        </Routes>
      </UsersProvider>
    </div>
  );
};

Users.propTypes = {};

export default Users;
