import { createUserWithEmailAndPassword, getRedirectResult, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import { FIREBASE_AUTH } from '..';
import { subscribeUserToWS, wsContext } from '../../../api';

/**
 * To login firebase user with email and password
 *
 * @param {String} email
 * @param {String} password
 * @returns {JSON}
 */
const login = async (email, password) => {
  try {
    const response = await signInWithEmailAndPassword(FIREBASE_AUTH, email, password);
    return response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

/**
 * Void function to refresh firebase token
 */
const refreshLogin = async () => {
  try {
    // refreshes access token
    const newToken = await FIREBASE_AUTH.currentUser.getIdToken(true);

    // removes old socket connection
    if (wsContext?.userSocket) {
      wsContext.userSocket.disconnect();
    }

    localStorage.setItem('user_token', newToken);

    // sets up new socket connection
    subscribeUserToWS();

    // // 55 minutes in milliseconds
    // setTimeout(refreshLogin, REFRESH_INTERVAL.MILLISECONDS);
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

/**
 * To register firebase user with email and password
 *
 * @param {String} email
 * @param {String} password
 * @returns {JSON}
 */
const register = async (email, password) => {
  try {
    const response = await createUserWithEmailAndPassword(FIREBASE_AUTH, email, password);
    return response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

/**
 * Executed after performing signInWithRedirect or when signin in with popup
 *
 * @param {Boolean} isPopup sign in with popup
 * @param {ConstructorParameters} provider firebase providers
 *
 * @returns {{user: JSON, _tokenResponse: JSON}} provider data
 */
const getSSOResult = async (isPopup, provider) => {
  try {
    let result;
    if (isPopup) {
      result = await signInWithPopup(FIREBASE_AUTH, provider);
    } else {
      result = await getRedirectResult(FIREBASE_AUTH);
    }

    // localStorage.setItem('token_expiry', moment().add(REFRESH_INTERVAL.MINUTES, 'minutes'));
    // // 55 minutes in milliseconds
    // setTimeout(refreshLogin, REFRESH_INTERVAL.MILLISECONDS);
    return result;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

/**
 * Void function to logout firebase user
 */
const logout = async () => {
  try {
    await signOut(FIREBASE_AUTH);
  } catch (error) {
    console.error(error);
    // throw new Error(error);
  }
};

export { getSSOResult, login, logout, refreshLogin, register };
