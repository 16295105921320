import { Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../../contexts';
import DevicesProvider from '../../../../contexts/devicesContext';
import UserProvider from '../../../../contexts/userContext';
import UsersProvider from '../../../../contexts/usersContext';
import { RoleProtectedRoute } from '../../../../helpers/routing';
import AddDevice from './addDevice';
import DefaultDevices from './default';
import EditDevice from './editDevice';

const Devices = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <UsersProvider>
        <UserProvider>
          <DevicesProvider>
            <Routes>
              <Route path='/' element={<RoleProtectedRoute roles={roles} path={pathname} element={DefaultDevices} />} />
              <Route path='/:id' element={<RoleProtectedRoute roles={roles} path={pathname} element={EditDevice} />} />
              <Route path='/add' element={<RoleProtectedRoute roles={roles} path={pathname} element={AddDevice} />} />
              <Route path='*' element={<RoleProtectedRoute roles={roles} path={pathname} element={DefaultDevices} />} />
            </Routes>
          </DevicesProvider>
        </UserProvider>
      </UsersProvider>
    </div>
  );
};

export default Devices;
