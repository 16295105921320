import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getTotalFilters } from '../../../../../helpers/formatUtils';
import { EVENTS } from '../../../../../library';
import Filter from '../../../../ui/filter';
import FilterBody from '../../../../ui/filter/body';
import { FILTERS, SORT_OPTIONS } from './constants';
import { formatFilters } from './utils';

const NewsFilter = ({ applications = {}, defaultFilter = {}, sort, setSort, setWhere }) => {
  const [selectedFilters, setSelectedFilters] = useState(defaultFilter);
  const totalFilters = useMemo(() => getTotalFilters(selectedFilters || []), [selectedFilters]);
  const [searchQuery, setSearchQuery] = useState();
  const [filterQuery, setFilterQuery] = useState();

  const handleFilter = useCallback(() => {
    const query = formatFilters(selectedFilters, applications);
    setFilterQuery(query);
  }, [applications, selectedFilters]);

  const onSearch = (term = '') => {
    setSearchQuery({ _or: [{ title_contains: term }, { description_contains: term }, { 'thirdparty.name_contains': term }] });
  };

  const handleSort = useCallback((value) => setSort(value), [setSort]);

  const onFilterReset = () => {
    setSelectedFilters({});
    setFilterQuery();
  };

  useEffect(() => {
    const _and = [];
    if (searchQuery) {
      _and.push(searchQuery);
    }

    if (filterQuery) {
      _and.push(filterQuery);
    }

    setWhere({ _and });
  }, [filterQuery, searchQuery, setWhere]);

  return (
    <Filter
      onFilter={handleFilter}
      onFilterReset={onFilterReset}
      onSearch={onSearch}
      onSearchReset={() => setSearchQuery()}
      totalFilters={totalFilters}
      sortValue={sort}
      handleSort={handleSort}
      sortOptions={SORT_OPTIONS}
      eventPath={EVENTS.VIEWS.APP.REPORTS.NEWS}
    >
      <Grid container item spacing={2}>
        <FilterBody filters={FILTERS} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
      </Grid>
    </Filter>
  );
};

NewsFilter.propTypes = {
  applications: PropTypes.array.isRequired,
  defaultFilter: PropTypes.object,
  sort: PropTypes.string,
  setSort: PropTypes.func.isRequired,
  setWhere: PropTypes.func.isRequired
};

export default NewsFilter;
