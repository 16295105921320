// EVENTS.VIEWS.APP.ASSESSMENTS.CYBER_ASSESSMENT

export const CYBER_ASSESSMENT = {
  PAGE_VISITED: 'cyber_assessment-page_visited',
  VIEW_ASSESSMENT_CATEGORY: 'cyber_assessment-single_category-page_visited',
  SEARCH: 'cyber_assessment-search', //eventProps searchterm
  FILTER: 'cyber_assessment-filter', //eventProps array of filters
  ANSWER_QUESTION: 'cyber_assessment-answer_question',
  USER_COMMENT: 'cyber_assessment-user_comment',
  USER_COMMENT_EDIT: 'cyber_assessment-user_comment_edit',
  USER_COMMENT_TAG: 'cyber_assessment-user_comment_tag',
  USER_ADD_ATTACHMENT: 'cyber_assessment-user_add_attachment',
  COMMENT_FORMATTING_QUESTION: 'cyber_assessment-comment_formatting',
  BACK_BUTTON: 'cyber_assessment-back_button',
  NEXT_BUTTON: 'cyber_assessment-next_button',
  COMMENT_DROPDOWN: 'cyber_assessment-comment_dropdown',
  CHANGE_ANSWER_QUESTION: 'cyber_assessment-change_answer',
  COOKIE_TRAIL: 'cyber_assessment-cookie_trail'
};
