import { FORGOT_PASSWORD as forgotPassword } from './forgotPassword';
import { LOGIN as login } from './login';
import { REGISTER as register } from './register';
import { RESET_PASSWORD as resetPassword } from './resetPassword';

export const USER = {
  register,
  login,
  forgotPassword,
  resetPassword
};
