/* eslint-disable quotes */

// components.application.dashboard.welcome_modal

export const WELCOME_MODAL = {
  steps: {
    one: {
      title: 'Welcome to CyberHQ Essentials',
      line_one: "We're excited to have you onboard.",
      line_two:
        'Our goal is to ensure you have all the essentials required to manage your cyber journey from the start, and to do it in a way that makes sense to you without having to be a cybersecurity expert.'
    },
    two: {
      title: 'Insights',
      line_one: "You are on the way to improving your organization's cyber performance.",
      line_two: 'Start with the insights on your home dashboard to understand how you are doing and ways to improve.'
    },
    three: {
      title: 'Perform',
      line_one: 'Get better by improving daily to optimize your cyber performance over time.',
      line_two: 'Essentials helps you get to a place that is right for you without the usual cost.'
    }
  }
};
