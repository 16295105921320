export const TABLE_HEADERS = ['PASSWORD STRENGTH', 'No. of Accounts'];

export const COMPLIANCE_OPTIONS = [
  {
    name: 'Compliant',
    type: 'bar',
    stack: 'COMPLIANCE',
    label: {
      show: true,
      position: 'right'
    },

    data: [10]
  },
  {
    name: 'Non Compliant',
    type: 'bar',
    stack: 'COMPLIANCE',
    label: {
      show: true,
      position: 'left'
    },

    data: [10]
  }
];
