import { gql } from '@apollo/client';

export const GET_VERSIONS = gql`
  query getVersions($where: JSON) {
    versions(where: $where) {
      id
      name
      number
    }
  }
`;
