import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CategoryAccordion, CyberAssessmentFilter } from '../../../../components/application/assessments';
import { filterAndSearchAssessment } from '../../../../components/application/assessments/cyber/filter/utils';
import { DotLoader } from '../../../../components/global';
import { Heading } from '../../../../components/ui';
import { useOrgContext } from '../../../../contexts';
import { useAssessmentContext } from '../../../../contexts/assessmentContext';
import { useInitialLoading } from '../../../../hooks';
import { TRANSLATION_PATH } from './constants';
import SingleCategory from './singleCategory';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const CyberAssessment = () => {
  const initialLoading = useInitialLoading();
  const classes = useStyles();

  const { state } = useLocation();
  const { filter: defaultFilter } = state || {};

  const { assessment, loading: assessmentLoading } = useAssessmentContext();
  const { org } = useOrgContext();

  const [category, setCategory] = useState({});
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    if (assessmentLoading || initialLoading) {
      return;
    }

    if (!defaultFilter) {
      setCategory(assessment?.category || {});
      return;
    }

    const filteredCategory = filterAndSearchAssessment(assessment?.category || {}, defaultFilter);
    setCategory(filteredCategory);
  }, [assessment?.category, assessmentLoading, defaultFilter, initialLoading]);

  if (assessmentLoading || initialLoading) {
    return <DotLoader />;
  }

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title')} description={TRANSLATION_PATH('description')} descriptionValue={{ org: org?.name }} />
      <CyberAssessmentFilter category={assessment?.category || {}} setCategory={setCategory} defaultFilter={defaultFilter} />
      <Grid container component='main' spacing={2} className={classes.root}>
        <Grid item xs={selectedCategory ? 6 : 12}>
          <CategoryAccordion level={0} category={category} setSelectedCategory={setSelectedCategory} />
        </Grid>
        {selectedCategory && (
          <Grid item xs={6}>
            <SingleCategory id={selectedCategory?.id || ''} fullscreen={false} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
export default CyberAssessment;
