import { COMMON as common } from './common';
import { COMPONENTS as components } from './components';
import { VIEWS as views } from './views';

const EN_US = {
  views,
  common,
  components
};
export default EN_US;
