import { IMAGES } from '@anirudhm9/base-lib/lib/constants';
import { useQuery } from '@apollo/client';
import { CloudDoneRounded, CloudOffRounded } from '@mui/icons-material';
import { Box, Button, Grid, Paper, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { CHECK_AUTHORISATION } from '../../../../api';
import { logEvent } from '../../../../library';
import { INTEGRATIONS as INTEGRATIONS_EVENTS } from '../../../../library/amplitude/events/views/app/integrations';
import { IconEnhanced, TooltipEnhanced, TypographyEnhanced } from '../../../global';
import { Image } from '../../../ui';
import ModalEnhanced from '../../../ui/modal';
import AddApplication from '../connectIntegrationModal/addApplication';
import DeleteIntegrationModal from '../deleteIntegrationModal';
import { TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  cardButton: {
    textAlign: 'left'
  },
  paper: {
    borderRadius: '50px',
    background: theme.palette.background.default
  }
}));

const ConnectedIntegration = ({ integration }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { name, type, id, connected } = integration || {};

  const [modalOpen, setModalOpen] = useState(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);

  const {
    data: authorisationData,
    loading: authorisationLoading,
    error: authorisationError,
    refetch: refetchAuthorisation
  } = useQuery(CHECK_AUTHORISATION, { variables: { id: integration?.id }, skip: !integration?.id || integration?.archived });

  const authorised = useMemo(() => {
    if (authorisationError) {
      return false;
    }

    const { checkAuthorisation } = authorisationData || {};
    return checkAuthorisation;
  }, [authorisationData, authorisationError]);

  const toggleAuthModal = () => {
    setAuthModalOpen((open) => {
      if (!open) {
        localStorage.setItem(`${(type || '').toLowerCase()}_auth`, '');
      }
      return !open;
    });
    refetchAuthorisation();
  };

  useEffect(() => {
    if (!authModalOpen) {
      return;
    }
    logEvent(INTEGRATIONS_EVENTS.VIEW_INTEGRATION_STATUS, {
      integration_id: id,
      integration_name: name,
      integration_type: type,
      integration_connected: connected
    });
  }, [authModalOpen, connected, id, name, type]);

  const isDarkModeActive = useMemo(() => theme?.palette?.mode === 'dark', [theme]);
  const src = useMemo(() => {
    return IMAGES.ICONS[isDarkModeActive ? `${type}_DARK` : `${type}_LIGHT`] || IMAGES.ICONS[type];
  }, [isDarkModeActive, type]);

  const CardButton = (props) => {
    return <Button {...props} fullWidth onClick={toggleAuthModal} />;
  };

  return (
    <Fragment>
      <Paper component={CardButton} className={classes.paper}>
        <Box className={classes.container}>
          <Grid container direction='row' alignItems='center' margin={1} className={classes.cardButton}>
            <Grid item>
              <Box sx={{ width: '35px', height: '35px' }}>
                <Image src={src || ''} alt={name} />
              </Box>
            </Grid>
            <Grid item xs container direction='column' marginLeft={1}>
              <Grid item>
                <TypographyEnhanced id={name || ''} />
              </Grid>
              {/* TODO this should be some kind of identifier for the integration. eg. email, domain, etc. will depend on the integration */}
              {/* <Grid item>
            <TypographyEnhanced id={data.id} variant='caption' />
          </Grid> */}
            </Grid>
            <Grid item>
              {!authorised ? (
                <TooltipEnhanced title={TRANSLATION_PATH('tooltips.notConnected')}>
                  <IconEnhanced loading={authorisationLoading}>
                    <CloudOffRounded color='error' />
                  </IconEnhanced>
                </TooltipEnhanced>
              ) : (
                <TooltipEnhanced title={TRANSLATION_PATH('tooltips.connected')}>
                  <IconEnhanced loading={authorisationLoading}>
                    <CloudDoneRounded color='success' />
                  </IconEnhanced>
                </TooltipEnhanced>
              )}
            </Grid>
            {/* Hidden for now until new integrations are added which can be deleted */}
            {/* <Grid item justifySelf='flex-end'>
          <TooltipEnhanced title={TRANSLATION_PATH('tooltips.delete')}>
            <IconButtonEnhanced onClick={() => setModalOpen(true)}>
            <DeleteRounded />
            </IconButtonEnhanced>
            </TooltipEnhanced>
          </Grid> */}
          </Grid>
        </Box>
      </Paper>
      <DeleteIntegrationModal modalOpen={modalOpen} setModalOpen={setModalOpen} integration={integration} />

      <ModalEnhanced modalOpen={authModalOpen} setModalOpen={toggleAuthModal}>
        <AddApplication type={integration?.type} application={integration} toggle={toggleAuthModal} />
      </ModalEnhanced>
    </Fragment>
  );
};

ConnectedIntegration.propTypes = {
  integration: PropTypes.object
};

export default ConnectedIntegration;
