import { formatErrorMessage } from '@anirudhm9/base-lib/lib/utils';
import { Done, ErrorOutline } from '@mui/icons-material';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { toast } from '../../global';

const SaveLoader = ({ loading, error, callback }) => {
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (!loading) {
      // Uncomment to hide save indicator after 1 second
      // setTimeout(() => setSaved(false), 1000);
      return;
    }
    setSaved(true);
  }, [loading]);

  const handleClick = useCallback(async () => {
    try {
      const { execute } = callback || {};
      await execute();
    } catch (error) {
      console.error(error);
    }
  }, [callback]);

  const LoadingState = useCallback(() => {
    if (loading) {
      return (
        <Grid container item spacing={2}>
          <Grid item>
            <CircularProgress size={12} color='primary' />
          </Grid>
          <Grid item>
            <Typography variant='caption'>Saving data</Typography>
          </Grid>
        </Grid>
      );
    }

    if (error) {
      toast.error(formatErrorMessage(error));
      return (
        <Grid container item spacing={2}>
          <Button size='small' variant='text' startIcon={<ErrorOutline color='error' />} onClick={handleClick}>
            Error saving. Retry?
          </Button>
        </Grid>
      );
    }
    if (saved) {
      return (
        <Grid container item spacing={2}>
          <Grid item>
            <Done fontSize='small' color='success' />
          </Grid>
          <Grid item>
            <Typography variant='caption'>Saved</Typography>
          </Grid>
        </Grid>
      );
    }

    return null;
  }, [error, handleClick, loading, saved]);

  return (
    <Grid container spacing={1} alignContent='center'>
      <LoadingState />
    </Grid>
  );
};

SaveLoader.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  callback: PropTypes.any
};

export default SaveLoader;
