// components.application.reports.applications.manualAddApp

export const MANUAL_ADD_APP = {
  divider: 'Manually Add App',
  fields: {
    name: 'Name',
    url: 'Homepage',
    description: 'Description',
    kbs: 'Is this a key business system?',
    notes: 'Notes'
  },
  buttons: {
    save: 'Save'
  }
};
