import { INTEGRATION_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { formatAccountText } from '@anirudhm9/base-lib/lib/utils';
import { useTheme } from '@emotion/react';
import { CheckCircleOutline } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntegrationContext } from '../../../../../contexts';
import { useUsersContext } from '../../../../../contexts/usersContext';
import { logEvent } from '../../../../../library';
import { DASHBOARD as DASHBOARD_EVENTS } from '../../../../../library/amplitude/events/views/app/dashboard';
import { ButtonEnhanced, DotLoader, TypographyEnhanced } from '../../../../global';
import { DonutChart, TableEnhanced, WidgetContainer } from '../../../../ui';
import NoDataState from '../noDataState';
import { PATH, TABLE_HEADERS, TRANSLATION_PATH } from './constants';

const MultiFactorAuth = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { loading: integrationLoading } = useIntegrationContext();
  const { users, loading: usersLoading, error: usersError } = useUsersContext();

  const [selectedProfileType] = useState(INTEGRATION_TYPES.GSUITE.value);

  const [rows, setRows] = useState([]);
  const [options, setOptions] = useState();
  const [competent, setCompetent] = useState(false);

  const noProfilesPresent = useMemo(() => !(users || [])?.some((user) => user?.profiles?.length), [users]);
  const activeUsers = useMemo(() => (users || []).filter((user) => !user.archived && !user.blocked), [users]);

  useEffect(() => {
    if (!activeUsers?.length || noProfilesPresent) {
      return;
    }

    const { palette } = theme || {};
    const { text } = palette || {};

    const usersWithMFA = (
      (activeUsers || []).filter((user) => {
        const { profiles = [] } = user || {};
        const { mfaEnabled } = (profiles || []).find((profile) => profile.type === (selectedProfileType || INTEGRATION_TYPES.GSUITE.value)) || {};
        return mfaEnabled;
      }) || []
    )?.length;

    const usersWithoutMFA = (
      (activeUsers || []).filter((user) => {
        const { profiles = [] } = user || {};
        const { mfaEnabled, blocked, archived } =
          (profiles || []).find((profile) => profile.type === (selectedProfileType || INTEGRATION_TYPES.GSUITE.value)) || {};
        return !mfaEnabled && !blocked && !archived;
      }) || []
    )?.length;

    const options = {
      series: [
        {
          name: 'Status',
          label: {
            show: false,
            position: 'center',
            backgroundColor: 'none',
            color: text?.primary
          },
          data: [
            {
              id: 'ENABLED',
              value: usersWithMFA,
              name: 'Enabled',
              itemStyle: {
                color: palette?.success?.main
              }
            },
            {
              id: 'NOT_ENABLED',
              value: usersWithoutMFA,
              name: 'Not Enabled',
              itemStyle: {
                color: palette?.error?.main
              }
            }
          ]
        }
      ]
    };

    const rows = [
      { label: 'Enabled', value: formatAccountText(usersWithMFA) },
      { label: 'Not Enabled', value: formatAccountText(usersWithoutMFA) }
    ];
    setCompetent(usersWithoutMFA === 0);
    setOptions(options);
    setRows(rows);
  }, [activeUsers, noProfilesPresent, selectedProfileType, theme]);

  const onChartClick = useCallback(
    (event) => {
      const { name = 'Not Enabled' } = event || {};
      const type = name === 'Enabled' ? 'GOOGLE_MFA_ENABLED' : 'GOOGLE_MFA_NOT_ENABLED';

      logEvent(DASHBOARD_EVENTS.MFA_STATUS_CHART_CLICK, { mfa_type: type });
      //navigate and set default filter
      navigate('/app/manage/users', { state: { filter: { USER_GOOGLE: { USER_GOOGLE_CHECKBOX: [type] } } } });
    },
    [navigate]
  );

  if (usersError) {
    return <NoDataState path={PATH} navigateTo='/app/integrations' />;
  }

  return (
    <WidgetContainer title={TRANSLATION_PATH('title')}>
      <Grid item xs={12}>
        <TypographyEnhanced id={TRANSLATION_PATH('description')} />
      </Grid>

      {usersLoading || integrationLoading ? (
        <Grid item xs={12} m={2}>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <TableEnhanced columns={TABLE_HEADERS} rows={rows} />
          </Grid>
          <Grid container justifyContent='center' item xs={12}>
            {competent ? (
              <CheckCircleOutline sx={{ fontSize: 250 }} htmlColor={theme.palette.success.main} />
            ) : (
              <Grid item xs={12}>
                {options && (
                  <DonutChart
                    options={options}
                    onEvents={{
                      click: onChartClick
                    }}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent='flex-end'>
        <ButtonEnhanced
          variant='outlined'
          size='small'
          onClick={() => {
            logEvent(DASHBOARD_EVENTS.MFA_STATUS_VIEW_ALL);
            navigate('/app/manage/users');
          }}
        >
          <TypographyEnhanced id={TRANSLATION_PATH('buttons.viewAll')} />
        </ButtonEnhanced>
      </Grid>
    </WidgetContainer>
  );
};

export default MultiFactorAuth;
