import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Heading } from '../../../components/ui';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const BlankPage = ({ id }) => {
  const classes = useStyles();
  return (
    <Container component={'main'} className={classes.root} maxWidth={false}>
      <Grid item xxs='12'>
        <Heading id={id || 'Page not available'} />
      </Grid>
    </Container>
  );
};

BlankPage.propTypes = {
  id: PropTypes.string
};

export default BlankPage;
