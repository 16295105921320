import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';
import useDevices from '../../hooks/useDevices';

const DevicesContext = createContext();
/**
 *
 * @returns {{devices: Object, loading: boolean, error: ApolloError, refetch: function}}
 */
export const useDevicesContext = () => {
  return useContext(DevicesContext);
};

const DevicesProvider = ({ children }) => {
  const { devices, loading, error, refetch } = useDevices();

  const value = {
    devices,
    loading,
    error,
    refetch
  };

  return <DevicesContext.Provider value={value}>{children}</DevicesContext.Provider>;
};

DevicesProvider.propTypes = {
  children: PropTypes.element
};

export default DevicesProvider;
