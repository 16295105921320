import { ROLE_OPTIONS } from '@anirudhm9/base-lib/lib/constants';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { TypographyEnhanced } from '../../../../../global';

export const FILTERS = {
  ALL_USERS: {
    id: 'ALL_USERS',
    label: 'User - Essentials',

    filters: [
      {
        id: 'ALL_USERS_CHECKBOX',
        label: 'User Status',
        type: 'checkbox',
        options: [
          { id: 'ACTIVE_USERS', name: 'Active', value: 'ACTIVE_USERS' },
          { id: 'ARCHIVED', name: 'Archived', value: 'ARCHIVED' },
          { id: 'REGISTERED', name: 'Registered', value: 'REGISTERED' }
        ]
      },
      {
        id: 'ALL_USERS_SELECT',
        label: 'User Roles',
        value: 'USER_ROLE',
        type: 'select',
        options: ROLE_OPTIONS
      }
    ]
  },
  USER_GOOGLE: {
    id: 'USER_GOOGLE',
    label: 'User - Google',

    filters: [
      {
        id: 'USER_GOOGLE_CHECKBOX',
        label: '',
        type: 'checkbox',
        options: [
          { id: 'GOOGLE_ADMIN', name: 'Admin', value: 'GOOGLE_ADMIN' },
          { id: 'GOOGLE_DELEGATE_ADMIN', name: 'Delegate Admin', value: 'GOOGLE_DELEGATE_ADMIN' },
          { id: 'GOOGLE_ARCHIVED', name: 'Archived', value: 'GOOGLE_ARCHIVED' },
          { id: 'GOOGLE_SUSPENDED', name: 'Suspended', value: 'GOOGLE_SUSPENDED' },
          { id: 'GOOGLE_MFA_ENABLED', name: 'MFA Enabled', value: 'GOOGLE_MFA_ENABLED' },
          { id: 'GOOGLE_MFA_ENFORCED', name: 'MFA Enforced', value: 'GOOGLE_MFA_ENFORCED' },
          { id: 'GOOGLE_MFA_NOT_ENABLED', name: 'MFA Not Enabled', value: 'GOOGLE_MFA_NOT_ENABLED' }
        ]
      }
    ]
  },
  USER_SLACK: {
    id: 'USER_SLACK',
    label: 'User - Slack',

    filters: [
      {
        id: 'USER_SLACK_CHECKBOX',
        label: '',
        type: 'checkbox',
        options: [
          { id: 'SLACK_ADMIN', name: 'Admin', value: 'SLACK_ADMIN' },
          { id: 'SLACK_DELEGATE_ADMIN', name: 'Delegate Admin', value: 'SLACK_DELEGATE_ADMIN' },
          { id: 'SLACK_ARCHIVED', name: 'Archived', value: 'SLACK_ARCHIVED' },
          { id: 'SLACK_SUSPENDED', name: 'Suspended', value: 'SLACK_SUSPENDED' }
        ]
      }
    ]
  }
};

export const SORT_VALUES = {
  ALPHABETICAL_ASC: {
    id: 'ALPHABETICAL_ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Alphabetical
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'ALPHABETICAL_ASC',
    label: ''
  },
  ALPHABETICAL_DESC: {
    id: 'ALPHABETICAL_DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Alphabetical
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'ALPHABETICAL_DESC',
    label: ''
  }
};

export const SORT_OPTIONS = Object.values(SORT_VALUES);
