// components.application.settings.user.subscriptionSettings

export const SUBSCRIPTION_SETTINGS = {
  title: 'Subscription',
  plans: {
    basic: 'You are currently on the Basic Plan.'
  },
  upsell: 'If you would like to upgrade to CyberHQ Essentials Premium, please check out our Pricing page to discover which plan is right for you.',
  buttons: {
    plans: 'VIEW CYBER HQ PLANS',
    unSub: 'UNSUBSCRIBE'
  }
};
