import { FEEDBACK_TYPES } from '@anirudhm9/base-lib/lib/constants/api/feedback';
import { BugReportRounded, ListRounded, NotificationsRounded, ScienceRounded } from '@mui/icons-material';
import { Grid, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Fragment, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import { IconButtonEnhanced, MenuList, StyledBadge, TypographyEnhanced } from '../../../components/global';
import { LetterAvatar, MenuContainer } from '../../../components/ui';
import { ProfileMenu } from '../../../constants';
import { useUserContext } from '../../../contexts';
import { useIsDesktop, useRajivSanity } from '../../../hooks';
import { logEvent } from '../../../library';
import { NOTIFICATIONS as NOTIFICATIONS_EVENTS, USER_ACTIONS as USER_ACTIONS_EVENTS } from '../../../library/amplitude/events/common';
import FeedbackModal from '../../feedback';
import NotificationList from '../../notifications';

const useStyles = makeStyles(() => ({
  toolbar: {
    paddingRight: 24,
    justifyContent: 'space-between',
    backgroundColor: 'inherit'
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  headerRight: {
    justifyContent: 'space-between',
    position: 'fixed',
    right: 15
  },
  buttonHover: {
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1) !important'
    }
  }
}));

const alphaNotice = `Alpha Version: This application is still in the early stages of development.

You are using an early-release Alpha version of the product. Thank you for helping us iterate on and improve our capabilities.

Please be aware that while we will endeavour to ensure the data we hold will carry forward into the subsequent versions, there is a small chance that we will be unable to do so due to changes in functionality.`;

const ToolBar = ({ toggleBottomDrawer }) => {
  const isDesktop = useIsDesktop();
  const classes = useStyles();
  const navigate = useNavigate();
  const profileMenu = (ProfileMenu && ProfileMenu()) || [];

  const { user } = useUserContext();

  // To show name on the top nav instead of the letter avatar
  // const userName = isDesktop ? `${firstName} ${lastName}` : firstName;

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [developmentTextOpen, setDevelopmentTextOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationCounter, setNotificationCounter] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const menuAnchorRef = useRef(null);
  const notificationAnchorRef = useRef(null);
  const developmentAnchorRef = useRef(null);

  const handleToggle = (type) => {
    switch (type) {
      case 'menu':
        setProfileMenuOpen((prevOpen) => !prevOpen);
        break;
      case 'palette':
        // setPaletteOpen((prevOpen) => !prevOpen);
        break;
      case 'development':
        setDevelopmentTextOpen((prevOpen) => !prevOpen);
        break;
      default:
        return null;
    }
  };

  const handleNotificationToggle = () => {
    setNotificationOpen((prevOpen) => {
      if (!prevOpen) {
        setTabIndex(0);
      }
      !prevOpen && logEvent(NOTIFICATIONS_EVENTS.VIEW_UNREAD);
      return !prevOpen;
    });
  };

  const toggleFeedbackModal = () => {
    setFeedbackOpen((open) => !open);
  };

  const handleLogoClick = () => {
    logEvent(USER_ACTIONS_EVENTS.CLICK_APPBAR_LOGO);
    navigate('/app');
  };

  // Just some fun for rajiv. delete this and the confetti element to remove
  const { pop, width, height, cursor } = useRajivSanity();

  return (
    <Toolbar className={classes.toolbar}>
      <Confetti
        width={width}
        height={height}
        confettiSource={{
          w: 10,
          h: 10,
          x: cursor.x,
          y: cursor.y
        }}
        gravity={0.2}
        initialVelocityX={13}
        initialVelocityY={13}
        numberOfPieces={pop ? 50 : 0}
        recycle={false}
        onConfettiComplete={(confetti) => {
          // setPop(false);
          confetti.reset();
        }}
      />
      <Fragment>
        {!isDesktop ? (
          <IconButtonEnhanced edge='start' color='inherit' onClick={toggleBottomDrawer} size='large'>
            <ListRounded />
          </IconButtonEnhanced>
        ) : null}
      </Fragment>
      <Grid
        className='tool-bar-logo'
        onClick={handleLogoClick}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
      />
      <Grid className={classes.headerRight}>
        <IconButtonEnhanced color='warning' size='large' onClick={() => handleToggle('development')} ref={developmentAnchorRef}>
          <ScienceRounded />
        </IconButtonEnhanced>
        <MenuContainer anchorRef={developmentAnchorRef} open={developmentTextOpen} handleClose={() => setDevelopmentTextOpen(false)}>
          <Grid container item p={2}>
            <TypographyEnhanced id={alphaNotice} variant='caption' />
          </Grid>
        </MenuContainer>

        <IconButtonEnhanced color='inherit' size='large' onClick={toggleFeedbackModal}>
          <BugReportRounded />
        </IconButtonEnhanced>
        <FeedbackModal open={feedbackOpen} toggle={toggleFeedbackModal} type={FEEDBACK_TYPES.BUG.value} />
        <IconButtonEnhanced color='inherit' ref={notificationAnchorRef} onClick={handleNotificationToggle} size='large'>
          {notificationCounter > 0 ? (
            <StyledBadge overlap='circular' anchorOrigin={{ vertical: 'top', horizontal: 'right' }} variant='dot'>
              <NotificationsRounded />
            </StyledBadge>
          ) : (
            <NotificationsRounded />
          )}
        </IconButtonEnhanced>
        <NotificationList
          open={notificationOpen}
          setOpen={setNotificationOpen}
          anchorRef={notificationAnchorRef}
          setNotificationCounter={setNotificationCounter}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
        />
        <IconButtonEnhanced
          color='inherit'
          ref={menuAnchorRef}
          aria-controls={profileMenuOpen ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={() => handleToggle('menu')}
          size='large'
          className={classes.buttonHover}
        >
          <LetterAvatar user={user} />
          {/* <TypographyEnhanced id={userName} color='inherit'>
            {userName}
          </TypographyEnhanced> */}
        </IconButtonEnhanced>
        <MenuList open={profileMenuOpen} setOpen={setProfileMenuOpen} anchorRef={menuAnchorRef} items={profileMenu} />
      </Grid>
    </Toolbar>
  );
};

ToolBar.propTypes = {
  open: PropTypes.bool,
  handleDrawerOpen: PropTypes.func,
  isDrawerFixed: PropTypes.bool,
  toggleBottomDrawer: PropTypes.func
};

export default ToolBar;
