import { useTheme } from '@mui/styles';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DEFAULT_OPTIONS from './constants';

/**
 *
 * @param {{radar: {indicator: [{name:String, max: Number}], series: [{name: String, type: String, data: [{name: String, value:[Number]}]}]}} options
 * @returns
 */
const RadarChart = ({ options }) => {
  const theme = useTheme();

  const [chartOptions, setChartOptions] = useState();

  useEffect(() => {
    if (!options) {
      return;
    }

    const { palette } = theme || {};
    const { background, text } = palette || {};
    const defaultOptions = _.cloneDeep(DEFAULT_OPTIONS);

    const { series: defaultSeries, radar: defaultRadar = {}, tooltip: defaultTooltip } = defaultOptions || {};

    const { tooltip, series = [], radar, ...rest } = options || {};

    defaultOptions.tooltip = {
      ...defaultTooltip,
      backgroundColor: background?.default,
      textStyle: { color: text?.primary },
      ...(tooltip || {})
    };

    defaultOptions.series = (defaultSeries || []).map((item, index) => {
      const newItem = series[index] || {};
      return {
        ...(item || {}),
        ...(newItem || {})
      };
    });

    defaultOptions.radar = {
      ...defaultRadar,
      ...(radar || {})
    };

    setChartOptions({
      ...(defaultOptions || {}),
      ...(rest || {})
    });
  }, [options, theme]);

  if (!chartOptions) {
    return null;
  }

  return <ReactEcharts option={chartOptions} />;
};

RadarChart.propTypes = {
  options: PropTypes.object
};

export default RadarChart;
