// components.ui.filter

export const FILTER = {
  title: 'Filter',
  search: {
    label: 'Search'
  },
  buttons: {
    clear: 'Clear',
    filter: 'Filter items'
  }
};
