import { Container, Grid, Skeleton, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationsFilter, SingleAppBar } from '../../../../../components/application/manage';
import { ButtonEnhanced, CardWithLoader, TypographyEnhanced } from '../../../../../components/global';
import { Heading, PaginationEnhanced } from '../../../../../components/ui';
import { useApplicationsFilterContext } from '../../../../../contexts';
import { TRANSLATION_PATH } from './constants';
import { logAddUserClick, logAppBarClick, logPageChange, logRowChange } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const DefaultApplications = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { applications, applicationsCount, loading: applicationsLoading, page, setPage, setStart, pageSize, setPageSize } = useApplicationsFilterContext();

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title')} description={TRANSLATION_PATH('description')} />
      <ApplicationsFilter />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardWithLoader
            cardHeaderProps={{
              title: (
                <Grid container spacing={2}>
                  <Grid item xs>
                    <Stack>
                      <TypographyEnhanced id={TRANSLATION_PATH('cardTitle')} variant='h6' />
                      <TypographyEnhanced id={TRANSLATION_PATH('cardSubtitle')} variant='caption' />
                    </Stack>
                  </Grid>
                  <Grid item justifySelf='flex-end'>
                    <ButtonEnhanced size='medium' onClick={() => logAddUserClick(navigate)}>
                      <TypographyEnhanced id={TRANSLATION_PATH('buttons.add')} />
                    </ButtonEnhanced>
                  </Grid>
                </Grid>
              )
            }}
          >
            <Grid container spacing={2}>
              {applicationsLoading ? (
                <Grid item xs={12}>
                  <Skeleton />
                </Grid>
              ) : (
                (applications || [])?.map((application) => {
                  return (
                    <Grid item xs={12} key={application.id}>
                      <SingleAppBar application={application} onClick={() => logAppBarClick(application, navigate)} />
                    </Grid>
                  );
                })
              )}
              <Grid item xs={12}>
                <PaginationEnhanced
                  total={applicationsCount || 0}
                  onChange={setStart}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  page={page}
                  onPageChange={setPage}
                  logPageChange={logPageChange}
                  logRowChange={logRowChange}
                />
              </Grid>
            </Grid>
          </CardWithLoader>
        </Grid>
      </Grid>
    </Container>
  );
};

DefaultApplications.propTypes = {};

export default DefaultApplications;
