import { ScienceRounded } from '@mui/icons-material';
import { Card, CardContent, CardMedia, Dialog, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ButtonEnhanced, SkeletonEnhanced, TypographyEnhanced } from '../../../global';
import AlphaNoticeModal from '../alphaNoticeModal';
import { content } from './constants';

const useStyles = makeStyles((theme) => ({
  headerBackground: {
    height: '200px'
  },
  titleText: {
    marginTop: '5rem',
    color: 'white',
    textShadow: '0 0 2px black' //Added solely to allow Insights heading to be read against background image
  },
  bodyContent: {
    paddingLeft: theme.spacing(5),
    minWidth: '100%', //Ensures third welcome card (Perform) takes up width of card despite shorter body text content
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  }
}));

const WelcomeModal = ({ modalOpen, setModalOpen }) => {
  const [currentStep, setCurrentStep] = useState(1);
  // State for showing alpha disclaimer modal - remove when no longer necessary
  const [alphaDisclaimerOpen, setAlphaDisclaimerOpen] = useState(false);

  const classes = useStyles();

  const handleNext = () => {
    setCurrentStep((prev) => {
      return prev + 1;
    });
  };
  const handleBack = () => {
    setCurrentStep((prev) => {
      return prev - 1;
    });
  };
  const handleNoticeClick = () => {
    setAlphaDisclaimerOpen((prev) => !prev);
  };

  const hasPrev = () => {
    const prevVal = currentStep - 1;

    if (content[prevVal]) {
      return true;
    }

    return false;
  };
  const hasNext = () => {
    const nextVal = currentStep + 1;

    if (content[nextVal]) {
      return true;
    }

    return false;
  };

  const LeftButton = () => {
    if (hasPrev()) {
      return (
        <ButtonEnhanced variant='outline' onClick={() => handleBack()}>
          Back
        </ButtonEnhanced>
      );
    }
    return (
      <ButtonEnhanced variant='text' onClick={() => setModalOpen()}>
        Skip intro
      </ButtonEnhanced>
    );
  };

  const RightButton = () => {
    if (hasNext()) {
      return (
        <ButtonEnhanced color='primary' onClick={() => handleNext()}>
          Next
        </ButtonEnhanced>
      );
    }
    return (
      <ButtonEnhanced color='primary' onClick={() => setModalOpen()}>
        Close
      </ButtonEnhanced>
    );
  };

  return (
    <Dialog open={modalOpen}>
      <Card>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {content[currentStep]?.image ? (
              <CardMedia
                height='200px'
                image={content[currentStep]?.image}
                alt='welcome'
                // style={{ overflow: 'hidden', height: '200px', display: 'flex', alignItems: 'center' }}
              >
                <Grid container spacing={1} className={classes.headerBackground}>
                  <Grid item xs={12} className={classes.titleText}>
                    {content[currentStep]?.title}
                  </Grid>
                </Grid>
              </CardMedia>
            ) : (
              <SkeletonEnhanced variant='rectangular' width='100%' height={200} />
            )}
          </Grid>
          <CardContent className={classes.bodyContent}>
            <Grid item xs={12}>
              {content[currentStep]?.bodyText}
            </Grid>

            {currentStep === 1 && (
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <ButtonEnhanced onClick={handleNoticeClick} variant='outlined' sx={{ gap: '5px' }}>
                  <ScienceRounded />
                  <TypographyEnhanced id={'Alpha Notice'} />
                </ButtonEnhanced>
              </Grid>
            )}

            <AlphaNoticeModal modalOpen={alphaDisclaimerOpen} setModalOpen={setAlphaDisclaimerOpen} />

            <Grid container justifyContent='space-between' item xs={12}>
              <LeftButton />
              <RightButton />
            </Grid>
          </CardContent>
        </Grid>
      </Card>
    </Dialog>
  );
};

WelcomeModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired
};

export default WelcomeModal;
