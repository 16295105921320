import { Skeleton } from '@mui/material';

/**
 *
 * Customised Skeleton component
 *
 * @typedef {import('@mui/material').SkeletonProps} SkeletonProps Props for `Skeleton` from '@mui/material` library
 *
 * @param {SkeletonProps} props
 * @returns {React.FC<SkeletonProps>}
 */
const SkeletonEnhanced = (props) => {
  return <Skeleton animation='wave' {...props} />;
};
export default SkeletonEnhanced;
