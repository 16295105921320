import { Flag } from '@mui/icons-material';
import { Grid, LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { logEvent } from '../../../../../../../../library';
import { ButtonEnhanced, TypographyEnhanced } from '../../../../../../../global';
import { LOG_EVENT_PATH } from '../../../constants/defaults';
import { TRANSLATION_PATH } from '../constants/defaults';

const NotStarted = ({ data, percentComplete, closeModal, type = 'basic' }) => {
  const navigate = useNavigate();

  const { label, route } = data || {};

  const handleAction = () => {
    logEvent(LOG_EVENT_PATH('ACTION_BUTTON'), { achievement: label });
    navigate(route || '');
  };
  switch (type) {
    case 'basic':
      return (
        <Grid item xs={12}>
          <LinearProgress variant='determinate' value={percentComplete} color='primary' />
          <TypographyEnhanced id={TRANSLATION_PATH('notStarted.notStarted')} variant='caption' paragraph align='center' style={{ margin: '0' }} />
        </Grid>
      );
    default: //detailed
      return (
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} container>
            <Grid item>
              <Flag fontSize='small' color='primary' />
            </Grid>
            <Grid item>
              <TypographyEnhanced id={TRANSLATION_PATH('notStarted.notStarted')} />
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={2} justifyContent='flex-end'>
            <Grid item>
              <ButtonEnhanced variant='text' onClick={() => closeModal()}>
                <TypographyEnhanced id={TRANSLATION_PATH('common.close')} />
              </ButtonEnhanced>
            </Grid>
            <Grid item>
              <ButtonEnhanced variant='contained' color='primary' onClick={() => handleAction()}>
                <TypographyEnhanced id={TRANSLATION_PATH('common.goToTask')} />
              </ButtonEnhanced>
            </Grid>
          </Grid>
        </Grid>
      );
  }
};

NotStarted.propTypes = {
  data: PropTypes.object,
  closeModal: PropTypes.func,
  percentComplete: PropTypes.number,
  type: PropTypes.string
};

export default NotStarted;
