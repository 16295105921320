const defaultOptions = {
  name: 'Stacked Chart',
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    show: true
  },
  grid: {
    left: '15%'
  },
  xAxis: {
    type: 'value',
    max: null, // auto sets the max to be close to the largest value
    min: null,
    axisLabel: {
      show: true,
      color: '#000'
    }
  },
  yAxis: {
    type: 'category',
    max: null, // auto sets the max to be close to the largest value
    min: null,
    data: [],
    axisTick: {
      show: false
    },
    axisLabel: {
      show: true,
      color: '#000'
    }
  },
  series: []
};

export default defaultOptions;
