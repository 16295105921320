import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../contexts';
import { RoleProtectedRoute } from '../../../helpers/routing';
import CompanySettings from './company';
import UserSettings from './user';

const Settings = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <Routes>
        <Route path='/' element={<Navigate to='company' />} />
        <Route path='user' element={<RoleProtectedRoute roles={roles} path={pathname} element={UserSettings} />} />
        <Route path='company' element={<RoleProtectedRoute roles={roles} path={pathname} element={CompanySettings} />} />
        <Route path='*' element={<Navigate to='company' />} />
      </Routes>
    </div>
  );
};

Settings.propTypes = {};

export default Settings;
