// views.app.manage.applications.default

export const DEFAULT_APPLICATIONS = {
  title: 'Applications',
  description:
    'This list includes applications that may be been manually added by your team or automatically synchronized by our automation engine. Note: our engine ignores anything that has not been logged into via your single sign-on provider (e.g. Google) in the past 180 days.',
  cardTitle: 'List of Applications',
  cardSubtitle: 'All detected and manually added Applications.',
  buttons: {
    add: 'Add application'
  }
};
