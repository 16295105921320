import { OpenInNewRounded } from '@mui/icons-material';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useIsDesktop } from '../../../../../../hooks';
import { IconButtonEnhanced, TypographyEnhanced } from '../../../../../global';
import { HEADERS } from '../constants';
const Compliances = ({ compliances, onGetInfo }) => {
  const isDesktop = useIsDesktop();

  const handleClick = (compliance, link) => {
    onGetInfo && onGetInfo(compliance);
    window.open(link || `https://google.com/search?q=${compliance?.name}`, '_blank', 'noopener,noreferrer');
  };

  const headers = useMemo(() => {
    if (!isDesktop) {
      return [...HEADERS].filter((header) => header.id !== 'name');
    }
    return HEADERS;
  }, [isDesktop]);

  if (!compliances?.length) {
    return (
      <Grid container item>
        <TypographyEnhanced id='No applicable compliances found' />{' '}
      </Grid>
    );
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => {
            const { id, label } = header || {};
            return (
              <TableCell key={id}>
                <TypographyEnhanced variant='body1' id={label} />
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {compliances.map((compliance, index) => {
          const { id, name, fullName, link } = compliance || {};
          return (
            <TableRow key={id}>
              <TableCell>
                <TypographyEnhanced variant='body1' id={String(index + 1)} />
              </TableCell>

              <TableCell>
                <TypographyEnhanced variant='body1' id={name} />
              </TableCell>
              {isDesktop && (
                <TableCell>
                  <TypographyEnhanced variant='body1' id={fullName || name} />
                </TableCell>
              )}
              <TableCell>
                <IconButtonEnhanced color='primary' size='small' onClick={() => handleClick(compliance, link)}>
                  <OpenInNewRounded />
                </IconButtonEnhanced>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

Compliances.propTypes = {
  compliances: PropTypes.array.isRequired,
  onGetInfo: PropTypes.func
};
export default Compliances;
