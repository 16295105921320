import PropTypes from 'prop-types';
import { useId } from 'react';
import { SelectEnhanced } from '../../../../../ui/form';
import { KBS_TYPES } from '../kbsChip/constants';

const KbsSelect = ({ value, onChange, ...props }) => {
  const id = useId();

  return <SelectEnhanced id={id} label='Key Business System' value={value} onChange={onChange} options={Object.values(KBS_TYPES)} {...props} />;
};

KbsSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default KbsSelect;
