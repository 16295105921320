import { formatDate } from '@anirudhm9/base-lib/lib/utils';
import {
  AccessTimeRounded,
  AlternateEmailRounded,
  CalendarMonthRounded,
  CheckCircleOutlineRounded,
  CloseRounded,
  RemoveCircleOutlineRounded
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import { ChipEnhanced, TooltipEnhanced } from '../../../../../global';

const booleanChip = (title, value) => {
  let id;
  let icon;
  if (value === null || value === undefined) {
    id = 'N/A';
    icon = <RemoveCircleOutlineRounded />;
  } else {
    id = value ? 'Yes' : 'No';
    icon = value ? <CheckCircleOutlineRounded /> : <CloseRounded />;
  }

  return (
    <Grid item key={value}>
      <ChipEnhanced
        key={value}
        id={title || ''}
        icon={
          <TooltipEnhanced title={id || ''} ml={0.5} mt={0.5}>
            {icon}
          </TooltipEnhanced>
        }
      />
    </Grid>
  );
};

const dateChip = (title, date) => {
  if (!date) {
    return null;
  }
  return (
    <Grid item key={date}>
      <ChipEnhanced
        id={formatDate(date)}
        icon={
          <TooltipEnhanced title={title || ''} ml={0.5} mt={0.5}>
            <CalendarMonthRounded />
          </TooltipEnhanced>
        }
      />
    </Grid>
  );
};

const HEADERS = Object.freeze({
  admin: {
    name: 'Admin',
    value: 'ADMIN',
    render: (title, value) => booleanChip(title, value)
  },
  delegateAdmin: {
    name: 'Delegate Admin',
    value: 'DELEGATE_ADMIN',
    render: (title, value) => booleanChip(title, value)
  },
  aliases: {
    name: 'Aliases',
    value: 'ALIASES',
    render: (title, values) => (
      <Grid container spacing={2}>
        {(values || ['None']).map((value) => (
          <Grid item key={value}>
            <ChipEnhanced
              key={value}
              id={value}
              icon={
                <TooltipEnhanced title={title || ''} ml={0.5} mt={0.5}>
                  <AlternateEmailRounded />
                </TooltipEnhanced>
              }
            />
          </Grid>
        ))}
      </Grid>
    )
  },
  mfaEnabled: {
    name: 'MFA Enabled',
    value: 'MFA_ENABLED',
    render: (title, value) => booleanChip(title, value)
  },
  mfaEnforced: {
    name: 'MFA Enforced',
    value: 'MFA_ENFORCED',
    render: (title, value) => booleanChip(title, value)
  },
  archived: {
    name: 'Archived',
    value: 'ARCHIVED',
    render: (title, value) => booleanChip(title, value)
  },
  blocked: {
    name: 'Blocked',
    value: 'BLOCKED',
    render: (title, value) => booleanChip(title, value)
  },
  createdAt: {
    name: 'Created On',
    value: 'CREATED_AT',
    render: (title, value) => dateChip(title, value)
  },
  lastLogin: {
    name: 'Last Login',
    value: 'CREATED_AT',
    render: (title, value) => dateChip(title, value)
  },
  tz: {
    name: 'Timezone',
    value: 'TIMEZONE',
    render: (title, value) => {
      if (!value) {
        return null;
      }
      return (
        <ChipEnhanced
          key={value}
          id={value}
          icon={
            <TooltipEnhanced title={title || ''} ml={0.5} mt={0.5}>
              <AccessTimeRounded />
            </TooltipEnhanced>
          }
        />
      );
    }
  }
});

export { HEADERS };
