import { useQuery } from '@apollo/client';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo, useState } from 'react';
import { GET_WEBSITES } from '../../../../api';
import { default as SecHeaders } from '../../../../components/application/dashboard/widgets/webSecurityHeaders';
import { MissingHeadersTable, RawHeadersTable } from '../../../../components/application/dashboard/widgets/webSecurityHeaders/table';
import { DotLoader } from '../../../../components/global';
import { Heading } from '../../../../components/ui';
import { useOrgContext } from '../../../../contexts';
import { TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const WebSecurityHeaders = () => {
  const classes = useStyles();

  const { org, loading: orgLoading } = useOrgContext();

  const orgWebsiteIds = useMemo(() => {
    if (orgLoading) {
      return;
    }
    const { websites = [] } = org || {};
    return (websites || []).map(({ id }) => id).filter((id) => id);
  }, [org, orgLoading]);

  const {
    data: websitesData,
    loading: websitesLoading,
    error: websitesError
  } = useQuery(GET_WEBSITES, { variables: { where: { id_in: orgWebsiteIds } }, skip: !orgWebsiteIds || !orgWebsiteIds.length });

  const websites = useMemo(() => {
    if (websitesError || websitesLoading) {
      return [];
    }
    return websitesData?.websites || [];
  }, [websitesData?.websites, websitesError, websitesLoading]);

  const [scan, setScan] = useState();

  const handleChange = (website) => {
    const { headerScans } = website || {};
    const scan = headerScans?.[0];
    setScan(scan);
  };

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title')} description={TRANSLATION_PATH('description') || ''} />
      {!classes ? (
        <DotLoader sx={{ position: 'relative' }} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SecHeaders websites={websites || []} loading={orgLoading || websitesLoading} hideActions hideTitle onChange={handleChange} />
          </Grid>
          {Object.keys(scan?.missingHeaders || {})?.length ? (
            <Grid item xs={12}>
              <MissingHeadersTable scan={scan} loading={orgLoading || websitesLoading} />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <RawHeadersTable scan={scan} loading={orgLoading || websitesLoading} />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

WebSecurityHeaders.propTypes = {};

export default WebSecurityHeaders;
