import { gql } from '@apollo/client';

export const GET_DEFAULT_TEMPLATES = gql`
  query getDefaultTemplates($frameworkValue: String!, $start: Int, $limit: Int, $sort: String) {
    getDefaultTemplates(frameworkValue: $frameworkValue, start: $start, limit: $limit, sort: $sort)
  }
`;

export const GET_CUSTOM_TEMPLATES = gql`
  query getCustomTemplates($frameworkValue: String!, $org: ID!, $start: Int, $limit: Int, $sort: String) {
    getCustomTemplates(frameworkValue: $frameworkValue, org: $org, start: $start, limit: $limit, sort: $sort)
  }
`;

export const CREATE_SCENARIO_TEMPLATE = gql`
  mutation createScenariotemplate($data: ScenariotemplateInput!) {
    createScenariotemplate(input: { data: $data }) {
      scenariotemplate {
        id
      }
    }
  }
`;

export const DELETE_SCENARIO_TEMPLATE = gql`
  mutation deleteScenariotemplate($id: ID!) {
    deleteScenariotemplate(input: { where: { id: $id } }) {
      scenariotemplate {
        id
      }
    }
  }
`;
