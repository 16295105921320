import 'quill-mention';

// Formats objects for setting up the Quill editor
const FORMATS = ['size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'mention'];

const SLASH_VALUES = {
  TASK: 'TASK'
};

const COMMAND_OPTIONS = [{ id: SLASH_VALUES.TASK, value: 'Task' }];

// eslint-disable-next-line quotes
const PLACEHOLDER = 'Write a comment and/or type @ to mention users within your organization';

const CONFIGURATION = {
  DESKTOP: {
    toolbar: [
      [{ size: ['small', false, 'large'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['clean']
    ]
  },
  MOBILE: {
    toolbar: [
      [
        {
          size: ['small', false, 'large']
        }
      ],
      ['bold', 'italic', 'underline'],
      [
        {
          list: 'ordered'
        }
      ],
      ['clean']
    ]
  }
};

export { COMMAND_OPTIONS, CONFIGURATION, FORMATS, PLACEHOLDER, SLASH_VALUES };
