import { isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { memo, useCallback, useRef } from 'react';
import { DotLoader } from '../../../../../../components/global';
import { COLUMN_WIDTH } from '../../constants';
import ScrollHeader from '../scrollHeader';
import ThreatModellingColumn from './column';

const useStyles = makeStyles(() => ({
  table: {
    overflowX: 'scroll'
  },
  column: {
    width: COLUMN_WIDTH,
    minWidth: COLUMN_WIDTH
    // maxWidth: 250
  }
}));

const ThreatModellingColumns = ({ loading, categories, mappings, setMappings }) => {
  const classes = useStyles();
  const ref = useRef();

  const handleSelect = useCallback(
    (tacticMappingNumber, question) => {
      const selectedMappingNumber = question?.mappingNumber || '';

      setMappings((mappings) => {
        const tacticMappingNumbers = mappings?.[tacticMappingNumber];

        if (!tacticMappingNumbers) {
          return { ...(mappings || {}), [tacticMappingNumber]: [selectedMappingNumber] };
        }

        if (tacticMappingNumbers?.includes(selectedMappingNumber)) {
          return {
            ...(mappings || {}),
            [tacticMappingNumber]: tacticMappingNumbers.filter((mappingNumber) => !isIDEqual(mappingNumber, selectedMappingNumber))
          };
        }

        return {
          ...(mappings || {}),
          [tacticMappingNumber]: [...tacticMappingNumbers, selectedMappingNumber]
        };
      });
    },
    [setMappings]
  );

  if (loading) {
    return <DotLoader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ScrollHeader ref={ref} />
      </Grid>
      <Grid container item spacing={2} xs={12} flexWrap='nowrap' className={classes.table} ref={ref}>
        {(categories || []).map((category) => {
          const { mappingNumber } = category || {};
          return (
            <Grid item key={mappingNumber} className={classes.column}>
              <ThreatModellingColumn
                item={category}
                onClick={(question) => handleSelect(mappingNumber, question)}
                selectedMappingNumbers={mappings?.[mappingNumber] || []}
                level={1}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

ThreatModellingColumns.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      mappingNumber: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(PropTypes.object),
      questions: PropTypes.arrayOf(PropTypes.object)
    })
  ).isRequired,
  mappings: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(PropTypes.string)
  }),
  setMappings: PropTypes.func,
  loading: PropTypes.bool
};

export default memo(ThreatModellingColumns);
