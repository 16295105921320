const { IMAGES } = require('../assets');

const DEVICE_OS_TYPES = {
  ANDROID: {
    id: 'ANDROID',
    name: 'Android Device',
    value: 'ANDROID',
    src: IMAGES.ICONS.ANDROID
  },
  CHROME_OS: {
    id: 'CHROME_OS',
    name: 'Chrome OS Device',
    value: 'CHROME_OS',
    src: IMAGES.ICONS.CHROME
  },
  IOS: {
    id: 'IOS',
    name: 'iOS Device',
    value: 'IOS',
    src: IMAGES.ICONS.APPLE
  },
  MAC_OS: {
    id: 'MAC_OS',
    name: 'Mac OS Device',
    value: 'MAC_OS',
    src: IMAGES.ICONS.APPLE
  },
  WINDOWS: {
    id: 'WINDOWS',
    name: 'Windows Device',
    value: 'WINDOWS',
    src: IMAGES.ICONS.WINDOWS
  },
  WINDOWS_MOBILE: {
    id: 'WINDOWS_MOBILE',
    name: 'Windows Mobile Device',
    value: 'WINDOWS_MOBILE',
    src: IMAGES.ICONS.WINDOWS
  },
  LINUX: {
    id: 'LINUX',
    name: 'Linux Device',
    value: 'LINUX',
    src: IMAGES.ICONS.LINUX
  },
  OTHER: {
    id: 'OTHER',
    name: 'Unknown Device Type',
    value: 'OTHER',
    src: IMAGES.EMPTY_STATE.UNKNOWN_DEVICE
  }
};

const DEVICE_OS_TYPES_KEYS = Object.keys(DEVICE_OS_TYPES);

const DEVICE_CATEGORY_TYPES = {
  ...DEVICE_OS_TYPES,
  SYNC_STATUS_CURRENT: {
    id: 'SYNC_STATUS_CURRENT',
    name: 'Sync Status Current',
    value: 'SYNC_STATUS_CURRENT',
    src: IMAGES.ICONS.SYNC_CURRENT
  },
  SYNC_STATUS_OVERDUE: {
    id: 'SYNC_STATUS_OVERDUE',
    name: 'Sync Status Overdue',
    value: 'SYNC_STATUS_OVERDUE',
    src: IMAGES.ICONS.SYNC_ERROR
  },
  VIEW_ALL: {
    id: 'VIEW_ALL',
    name: 'All Devices',
    value: 'VIEW_ALL',
    src: IMAGES.GENERAL.DEVICES
  }
};

const DEVICE_CATEGORY_TYPES_KEYS = Object.keys(DEVICE_CATEGORY_TYPES);

const DEVICE_TYPES = {
  DESKTOP: {
    id: 'DESKTOP',
    name: 'Desktop',
    value: 'DESKTOP'
  },
  MOBILE: {
    id: 'MOBILE',
    name: 'Mobile',
    value: 'MOBILE'
  },
  TABLET: {
    id: 'TABLET',
    name: 'Tablet',
    value: 'TABLET'
  }
};

module.exports = {
  DEVICE_OS_TYPES,
  DEVICE_OS_TYPES_KEYS,
  DEVICE_CATEGORY_TYPES,
  DEVICE_CATEGORY_TYPES_KEYS,
  DEVICE_TYPES
};
