import { getCategoriesFlat, isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { GET_FRAMEWORKS } from '../../api';
import { useAssessmentData } from '../../hooks';
import { useOrgContext } from '../orgContext';

const ShadowAssessmentContext = createContext();
/**
 *
 * @returns {{frameworkId: *, assessment: (JSON), updateAssessment: VoidFunction, categoriesFlat: {[CategoryID]: category}, refetch: (variables?: Partial<{org: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError, shadowFramework: JSON, setShadowFramework: VoidFunction}}
 */
export const useShadowAssessmentContext = () => {
  return useContext(ShadowAssessmentContext);
};

const ShadowAssessmentProvider = ({ children }) => {
  const { orgId, org } = useOrgContext();

  const [assessment, setAssessment] = useState({}); // holds assessment category/questions structure
  const [assessmentAnswers, setAssessmentAnswers] = useState({}); // hold current orgs answers.. not sure if should be in this context
  const [categoriesFlat, setCategoriesFlat] = useState();
  const [shadowFramework, setShadowFramework] = useState();

  const {
    data: frameworksData,
    loading: frameworksLoading,
    error: frameworksError
  } = useQuery(GET_FRAMEWORKS, {
    variables: {
      where: {
        org: orgId,
        _and: [
          {
            _or: [{ hidden: false }, { hidden_null: true }]
          },
          {
            _or: [{ archived: false }, { archived_null: true }]
          }
        ]
      }
    },
    skip: !orgId
  });

  const frameworks = useMemo(() => {
    if (frameworksLoading || frameworksError) {
      return [];
    }
    return frameworksData?.frameworks;
  }, [frameworksData?.frameworks, frameworksError, frameworksLoading]);

  useEffect(() => {
    setShadowFramework((selected) => {
      if (selected) {
        return selected;
      }
      return frameworks?.find((framework) => isIDEqual(framework.id, org?.framework?.id))?.id;
    });
  }, [frameworks, org?.framework?.id, setShadowFramework]);

  const { frameworkId, assessment: assessmentData, loading, error, refetch } = useAssessmentData(shadowFramework, !shadowFramework);

  const updateAssessment = (data) => {
    if (!data || _.isEmpty(data)) {
      return;
    }
    setAssessment((prev) => {
      return { ...prev, ...data };
    });
  };

  const updateAnswers = (data) => {
    if (!data || _.isEmpty(data)) {
      return;
    }

    setAssessmentAnswers((prev) => {
      return { ...prev, ...data };
    });
  };

  useEffect(() => {
    if (error || loading || !assessmentData) {
      return;
    }
    const categoriesFlat = getCategoriesFlat(assessmentData?.category, 'id');
    setCategoriesFlat(categoriesFlat);
    updateAssessment(assessmentData);
  }, [assessmentData, error, loading]);

  const value = {
    frameworkId,
    frameworks,
    frameworksLoading,
    assessment,
    updateAssessment,
    assessmentAnswers,
    updateAnswers,
    loading,
    refetch,
    error,
    categoriesFlat,
    shadowFramework,
    setShadowFramework
  };

  return <ShadowAssessmentContext.Provider value={value}>{children}</ShadowAssessmentContext.Provider>;
};

ShadowAssessmentProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired
};

export default ShadowAssessmentProvider;
