import { sortVersionArray } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { lazy, memo, useEffect, useMemo, useState } from 'react';

import { Search } from '../../../../../../../components/ui';
import { searchTechnique } from '../../../utils';
import ThreatModellingColumnHeader from './header';

const ThreatModellingCell = lazy(() => import('../../cell'));

const useStyles = makeStyles(() => ({
  hover: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const ThreatModellingColumn = ({ item, onClick, selectedMappingNumbers, level = 0 }) => {
  const classes = useStyles();

  const [category, setCategory] = useState();

  useEffect(() => {
    if (!item) {
      return;
    }
    setCategory(item);
  }, [item]);

  const children = useMemo(() => {
    if (!category) {
      return [];
    }

    return sortVersionArray(category?.children || [], 'index');
  }, [category]);

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((open) => !open);
  };

  const onSearch = (term) => {
    const filteredCategory = searchTechnique(item, term);
    setCategory(filteredCategory);
    setOpen(true);
  };

  const onReset = () => {
    setCategory(item);
    setOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} spacing={2}>
        <ThreatModellingColumnHeader title={item?.name || ''} subtitle={`${children?.length || 0} techniques`} open={open} toggle={toggle} />
        <Grid item xs={12}>
          <Search onSearch={onSearch} onReset={onReset} />
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        {(children || []).map((child) => {
          const { id } = child || {};
          return (
            <Grid item key={id} xs={12} className={classes.hover}>
              <ThreatModellingCell item={child} defaultOpen={open} onClick={onClick} selectedMappingNumbers={selectedMappingNumbers} level={level + 1} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

ThreatModellingColumn.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    mappingNumber: PropTypes.string.isRequired
  }).isRequired,
  selectedMappingNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired
};

// eslint-disable-next-line react/display-name
export default memo(ThreatModellingColumn, (prevProps, nextProps) => {
  const { selectedMappingNumbers: previousSelectedMappingNumbers } = prevProps || {};
  const { selectedMappingNumbers: nextSelectedMappingNumbers } = nextProps || {};

  // TODO: incomplete prop checking: only considers selectedMappingNumbers
  return _.isEqual(previousSelectedMappingNumbers, nextSelectedMappingNumbers);
});
ThreatModellingColumn.displayName = 'ThreatModellingColumn';
