import { EVENTS } from '../../../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.app.manage.issues.single.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.APP.MANAGE.ISSUES[event];
};

export const ISSUE_EVENT_TYPES = {
  ADD: 'ADD',
  EDIT: 'EDIT'
};
