// ENUM for roles
const ROLES = {
  ADMIN: {
    id: 'ADMIN',
    name: 'Admin',
    value: 'ADMIN'
  },
  DEFAULT: {
    id: 'DEFAULT',
    name: 'Member',
    value: 'DEFAULT'
  }
};

const TUTORIAL = {
  WELCOME: false
};

// valid roles array
const VALID_ROLES = Object.keys(ROLES);

const ROLE_OPTIONS = Object.values(ROLES);

// default pagination limit
const PAGE_THRESHOLD = 5;

const CRITICALITIES = {
  LOW: {
    name: 'Low',
    value: 'LOW',
    color: 'blue'
  },
  LOW_MEDIUM: {
    name: 'Low-Medium',
    value: 'LOW_MEDIUM',
    color: 'green'
  },
  MEDIUM: {
    name: 'Medium',
    value: 'MEDIUM',
    color: 'yellow'
  },
  MEDIUM_HIGH: {
    name: 'Medium-High',
    value: 'MEDIUM_HIGH',
    color: 'orange'
  },
  HIGH: {
    name: 'High',
    value: 'HIGH',
    color: 'red'
  }
};

const STATUSES = {
  OPEN: {
    name: 'Open',
    value: 'OPEN',
    color: 'error'
  },
  ACCEPTED: {
    name: 'Risk Accepted',
    value: 'ACCEPTED',
    color: 'warning'
  },
  RESOLVED: {
    name: 'Risk Resolved',
    value: 'RESOLVED',
    color: 'success'
  }
};

const ISSUE_TYPES = {
  CLOUD_INFRASTRUCTURE: {
    name: 'Cloud Infrastructure',
    value: 'CLOUD_INFRASTRUCTURE'
  },
  DEVICE_OUT_OF_SYNC: {
    name: 'Device out of sync',
    value: 'DEVICE_OUT_OF_SYNC'
  },
  MANUAL: {
    name: 'User created issue',
    value: 'MANUAL'
  }
};

const ISSUE_RELATIONS = {
  DEVICE: {
    id: 'device',
    name: 'Device',
    value: 'DEVICE'
  },
  APPLICATION: {
    id: 'application',
    name: 'Application',
    value: 'APPLICATION'
  },
  USER: {
    id: 'user',
    name: 'User',
    value: 'USER'
  },
  QUESTION: {
    id: 'question',
    name: 'Question',
    value: 'QUESTION'
  },
  NONE: {
    id: 'NONE',
    name: 'None',
    value: 'NONE'
  }
};

const OVERALL_TASKS = {
  INITITAL_ONBOARDING: {
    name: 'Company Profile',
    value: 'INITITAL_ONBOARDING',
    navigate: '/app/settings/company'
  },
  GSUITE_CONNECT: {
    name: 'Connect Google Workspace',
    value: 'GSUITE_CONNECT',
    navigate: '/app/integrations'
  },
  AWS_CONNECT: {
    name: 'Connect AWS',
    value: 'AWS_CONNECT',
    navigate: '/app/integrations'
  },
  SLACK_CONNECT: {
    name: 'Connect Slack',
    value: 'SLACK_CONNECT',
    navigate: '/app/integrations'
  },
  ASSESSMENT_COMPLETION: {
    name: 'Complete Assessment',
    value: 'ASSESSMENT_COMPLETION',
    navigate: '/app/assessment/cyber',
    state: { filter: { STATUS: { STATUS_CHECKBOX: ['NOT_ANSWERED'] } } }
  },
  DEVICE_INVENTORY: {
    name: 'Device Inventory',
    value: 'DEVICE_INVENTORY',
    navigate: '/app/manage/devices'
  },
  APPLICATION_INVENTORY: {
    name: 'Application Inventory',
    value: 'APPLICATION_INVENTORY',
    navigate: '/app/manage/applications'
  },
  USER_INVENTORY: {
    name: 'User Listing',
    value: 'USER_INVENTORY',
    navigate: '/app/manage/users'
  },
  CLOSED_ISSUES: {
    name: 'Address Open Issues',
    value: 'CLOSED_ISSUES',
    navigate: '/app/manage/issues'
  }
};

const DEFAULT_SCALE = {
  min: 0,
  max: 5
};

module.exports = {
  ROLES,
  TUTORIAL,
  VALID_ROLES,
  ROLE_OPTIONS,
  PAGE_THRESHOLD,
  CRITICALITIES,
  STATUSES,
  ISSUE_TYPES,
  ISSUE_RELATIONS,
  OVERALL_TASKS,
  DEFAULT_SCALE
};
