import { logEvent } from '../../../../../../library';
import { DASHBOARD as DASHBOARD_EVENTS } from '../../../../../../library/amplitude/events/views/app/dashboard';
import { DEVICES_ROUTE } from '../constants';

/**
 * Logs chart click and navigates user
 *
 * @param {Object} event
 * @param {VoidFunction} navigate
 */
const logChartClick = (event, navigate) => {
  const { data } = event || {};
  const { name: search } = data || {};

  logEvent(DASHBOARD_EVENTS.DEVICES_CHART_CLICK, { device_column_clicked: search });
  //navigate and set default filter
  navigate(DEVICES_ROUTE, { state: { search } });
};

/**
 * Logs view all button click and navigates user
 *
 * @param {VoidFunction} navigate
 */
const logViewAll = (navigate) => {
  logEvent(DASHBOARD_EVENTS.DEVICES_VIEW_ALL);
  navigate(DEVICES_ROUTE);
};

export { logChartClick, logViewAll };
