import { formatUserName } from '@anirudhm9/base-lib/lib/utils';
import { Button, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { TypographyEnhanced } from '../../../../global';
import UserRoleIcon from '../userRoleIcon';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.background.default
  },
  cardContent: {
    width: '100%'
  },
  usernameLine: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px'
  }
}));

const SingleUserBar = ({ user }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  if (!user) {
    return;
  }

  const { id, email, orgRoles = [], archived, blocked } = user || {};
  const userRoles = orgRoles.map((role) => role.value) || [];

  const CardButton = (props) => {
    return <Button {...props} fullWidth />;
  };

  return (
    <Card onClick={() => navigate(`/app/manage/users/${id}`)} component={CardButton} className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container alignItems='center' spacing={2}>
          <Grid container item xs direction='column' alignItems='flex-start'>
            <Grid item className={classes.usernameLine}>
              <UserRoleIcon userRoles={userRoles} deleted={archived || blocked} />
              <TypographyEnhanced id={formatUserName(user)} />
            </Grid>
            <Grid item>
              <TypographyEnhanced id={email} variant='caption' />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SingleUserBar.propTypes = {
  user: PropTypes.object
};

export default SingleUserBar;
