import { KeyOffRounded, KeyRounded, RemoveCircleRounded } from '@mui/icons-material';

const PATH = 'components.application.reports.applications.kbsChip';

export const KBS_TYPES = {
  MAJOR: {
    id: 'MAJOR',
    name: 'Yes',
    value: 'MAJOR',
    translation: `${PATH}.MAJOR`,
    color: 'success',
    icon: <KeyRounded />
  },
  NONE: {
    id: 'NONE',
    name: 'No',
    value: 'NONE',
    translation: `${PATH}.NONE`,
    icon: <KeyOffRounded />
  },
  MINOR: {
    id: 'MINOR',
    name: 'Not Important',
    value: 'MINOR',
    translation: `${PATH}.MINOR`,
    icon: <RemoveCircleRounded />
  }
};

export const TRANSLATION_PATH = (route) => {
  return `${PATH}.${route}`;
};
