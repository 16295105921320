const API_CONSTANTS = require('./api');
const COMMON_CONSTANTS = require('./common');
const INTEGRATION_CONSTANTS = require('./integration');
const DEVICE_CONSTANTS = require('./device');
const ASSETS = require('./assets');
const SECURITY_HEADERS = require('./securityHeaders');

module.exports = {
  ...API_CONSTANTS,
  ...COMMON_CONSTANTS,
  ...INTEGRATION_CONSTANTS,
  ...ASSETS,
  ...DEVICE_CONSTANTS,
  ...SECURITY_HEADERS
};
