import { useMutation } from '@apollo/client';
import { Badge, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { INVITE_USER } from '../../../../../api';
import { useOrgContext } from '../../../../../contexts';
import { useUsersContext } from '../../../../../contexts/usersContext';
import { toast } from '../../../../global';
import { LetterAvatar } from '../../../../ui';
import SingleUserBar from '../singleUserBar';
import StatusIcon from '../statusIcon';

/**
 *
 * @param {JSON} user
 * @returns {JSX}
 */

const UserAvatar = ({ user }) => {
  const { orgId } = useOrgContext();
  const { refetch: refetchUsers } = useUsersContext();

  const [inviteUser] = useMutation(INVITE_USER);

  const [loading, setLoading] = useState(false);

  const handleInvite = async () => {
    try {
      setLoading(true);
      await inviteUser({ variables: { user: user?.id, org: orgId } });
      refetchUsers();
      toast.success('Invitation sent');
    } catch (error) {
      console.error(error);
      toast.error('There was an error inviting the user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container item spacing={2} alignItems='center' key={user?.id}>
      <Grid item>
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<StatusIcon {...(user || {})} onClick={handleInvite} loading={loading} />}
        >
          <LetterAvatar user={user} sx={{ height: 50, width: 50 }} />
        </Badge>
      </Grid>
      <Grid item xs>
        <SingleUserBar user={user} />
      </Grid>
    </Grid>
  );
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    confirmed: PropTypes.bool,
    invited: PropTypes.bool
  })
};

export default UserAvatar;
