import PropTypes from 'prop-types';
import { useState } from 'react';
import { AutoCompleteEnhanced, CheckboxEnhanced } from '../../../../../../../ui/form';
import { AUTO_SAVE_DELAY, OPTION_THRESHOLD } from '../../constants';
import { filterNoneOfTheAbove } from './utils';

const MultiSelectInput = ({
  id,
  label,
  options,
  selectedOptions,
  fixedOptions,
  setSelectedOptions,
  onSave,
  other,
  customTimeout,
  setCustomTimeout,
  disabled
}) => {
  const [latestTimeout, setLatestTimeout] = useState();

  const handleChange = (values) => {
    //Modifies values array accordingly if 'None of the above' has been selected
    const saveValues = filterNoneOfTheAbove(values, options, selectedOptions);

    const otherOption = options?.find((option) => option?.name === 'Other' && values.includes(option?.id));

    const callback = () => {
      onSave({ selected: saveValues });
      setLatestTimeout();
      setCustomTimeout();
    };

    const { questionId: previousQuestionId, callback: previousCallback } = customTimeout || {};
    if (id !== previousQuestionId && previousCallback) {
      previousCallback();
    }

    if (latestTimeout) {
      clearTimeout(latestTimeout);
    }

    setSelectedOptions(saveValues);

    // Do not save if other option is selected
    if (otherOption && !other) {
      return;
    }
    const timeout = setTimeout(callback, AUTO_SAVE_DELAY);
    setCustomTimeout({ id: timeout, questionId: id, callback });
    setLatestTimeout(timeout);
  };

  // show dropdown if options > threshold
  if (options?.length > OPTION_THRESHOLD) {
    return (
      <AutoCompleteEnhanced
        id={id}
        label={label}
        options={options || []}
        value={selectedOptions}
        fixedOptions={fixedOptions}
        onChange={handleChange}
        multiple
        disabled={disabled}
      />
    );
  }
  // show radio if options < threshold
  return <CheckboxEnhanced label={label} value={selectedOptions} onChange={handleChange} options={options} disabled={disabled} />;
};

MultiSelectInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  fixedOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setSelectedOptions: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  other: PropTypes.string,
  customTimeout: PropTypes.object,
  setCustomTimeout: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default MultiSelectInput;
