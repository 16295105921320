// views.app.manage.manageUsers

export const MANAGE_USERS = {
  title: 'Users',
  description:
    'It is important for you to be aware of the users that have access to your systems, including ones that have since been disabled, archived, or deleted. Select each individual user for more information regarding the applications they have access to and their devices.',
  cardTitle: 'List of Users',
  cardSubtitle: 'All detected and manaually added users. ',
  buttons: {
    add: 'Add User'
  }
};
