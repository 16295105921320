import { CheckRounded, ClearRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { IconButtonEnhanced } from '../../../global';

const InputChange = ({ onSave, onCancel, disabled = false }) => {
  return (
    <Grid container justifyContent='flex-end'>
      <IconButtonEnhanced color='primary' size='small' onClick={onSave} disabled={disabled}>
        <CheckRounded />
      </IconButtonEnhanced>
      <IconButtonEnhanced color='warning' size='small' onClick={onCancel}>
        <ClearRounded />
      </IconButtonEnhanced>
    </Grid>
  );
};

InputChange.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
export default InputChange;
