import { gql } from '@apollo/client';

export const GET_INTEGRATION_BY_ID = gql`
  query integration($id: ID!) {
    integration(id: $id) {
      id
      name
      type
      subType
      connected
      settings
      archived
      org {
        id
      }
    }
  }
`;

export const GET_INTEGRATIONS = gql`
  query integrations($where: JSON!) {
    integrations(where: $where, sort: "name:ASC") {
      id
      name
      type
      subType
      connected
      settings
      archived
      org {
        id
      }
      framework {
        id
      }
    }
  }
`;

export const GET_INTEGRATION_DATA = gql`
  query getIntegrationData($id: ID!) {
    getIntegrationData(id: $id)
  }
`;

export const CHECK_AUTHORISATION = gql`
  query checkAuthorisation($id: ID!) {
    checkAuthorisation(id: $id)
  }
`;

export const GET_OAUTH_CALLBACK = gql`
  query getOAuthCallback($id: ID!, $code: String!) {
    getOAuthCallback(id: $id, code: $code)
  }
`;

export const INITIALISE_INTEGRATION = gql`
  mutation initialiseIntegration($type: String!, $org: ID!, $settings: JSON) {
    initialiseIntegration(type: $type, org: $org, settings: $settings) {
      id
      name
      type
      subType
      connected
      archived
      settings
    }
  }
`;

export const UPDATE_INTEGRATION = gql`
  mutation updateIntegration($id: ID!, $data: editIntegrationInput!) {
    updateIntegration(input: { where: { id: $id }, data: $data }) {
      integration {
        id
        connected
      }
    }
  }
`;

export const ARCHIVE_INTEGRATION = gql`
  mutation archiveIntegration($id: ID!) {
    archiveIntegration(id: $id) {
      id
      connected
    }
  }
`;
