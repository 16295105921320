import _ from 'lodash';
import { deviceDetect, mobileModel } from 'react-device-detect';

export const detectUserDevice = (updateLocalData, currentUser) => {
  const userDevice = deviceDetect();
  const data = _.omit(userDevice, ['engineVersion', 'engineName', 'osName', 'osVersion']);
  const { osName, osVersion } = userDevice || {};

  //Update localData with properties from detected device
  updateLocalData('version', osVersion);
  updateLocalData('type', osName.toUpperCase());
  updateLocalData('user', currentUser?.id);
  updateLocalData('model', mobileModel === 'none' ? '' : mobileModel);
  updateLocalData('data', data);
};
