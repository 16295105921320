import { Box } from '@mui/system';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DEFAULT_ROWS_PER_PAGE } from './utils';

/**
 * @description:
 * Material UI X-Grid/Table with customisation options
 * @typedef {import('@mui/x-data-grid').DataGridProps} DataGridProps Props for `DataGrid` from '@mui/x-data-grid` library
 *
 * @param {String | Number} height (optional) if not provided, height is auto adjusted
 * @param {String | Number} width width of the box
 * @param {Array} columns Headers for the table
 * @param {Array} rows rows to render
 * @param {Array} rowsPerPageOptions number of rows to render per page
 * @param {Boolean} selectable option to make the rows selectable
 * @param {String} density adjusts the padding and spacing between rows and header
 * @param {Boolean} loading show spinnder overlay
 * @param {Boolean} autoPageSize change page size automatically
 * @param {String} disableColumnMenu to hide menu dots on each header
 * @param {String} showToolbar to show toolbar on top for filter and table options
 * @param {{ disableColumnSelector: Boolean } & DataGridProps} props
 * @returns {React.FC<SelectProps>}
 */

const DataGridEnhanced = ({
  height,
  width = '100%',
  columns = [],
  rows = [],
  rowsPerPageOptions,
  selectable = false,
  density = 'standard',
  loading = false,
  autoPageSize = false,
  disableColumnMenu = true,
  showToolbar = true,
  disableColumnSelector = false,
  handleDataGridChange,
  logPageSizeChange,
  isExportDisabled = true,
  ...props
}) => {
  const [displayedRows, setDisplayedRows] = useState(10);

  const handleSizeChange = (size) => {
    setDisplayedRows(size);
  };

  useEffect(() => {
    if (!rowsPerPageOptions || !rowsPerPageOptions.length) {
      handleSizeChange(DEFAULT_ROWS_PER_PAGE[0]);
      return;
    }
    handleSizeChange(rowsPerPageOptions[0]);
  }, [rowsPerPageOptions]);

  return (
    <Box sx={{ height, width }}>
      <DataGrid
        rows={rows}
        columns={columns}
        onStateChange={handleDataGridChange}
        pageSize={displayedRows}
        onPageSizeChange={(size) => {
          handleSizeChange(size);
          logPageSizeChange(size);
        }}
        rowsPerPageOptions={rowsPerPageOptions || DEFAULT_ROWS_PER_PAGE}
        checkboxSelection={selectable}
        autoPageSize={autoPageSize}
        density={density}
        loading={loading}
        autoHeight={!height}
        disableColumnMenu={disableColumnMenu}
        {...(showToolbar && {
          components: { Toolbar: GridToolbar }
        })}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: isExportDisabled },
            printOptions: { disableToolbarButton: isExportDisabled }
          }
        }}
        disableColumnSelector={disableColumnSelector}
        {...props}
      />
    </Box>
  );
};

DataGridEnhanced.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      type: PropTypes.string,
      headerName: PropTypes.string,
      description: PropTypes.string,
      minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      flex: PropTypes.number,
      hideable: PropTypes.bool,
      hide: PropTypes.bool,
      resizable: PropTypes.bool,
      valueGetter: PropTypes.func,
      renderHeader: PropTypes.func,
      renderCell: PropTypes.func
    })
  ),
  rows: PropTypes.array,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowsPerPageOptions: PropTypes.array,
  pageSize: PropTypes.number,
  selectable: PropTypes.bool,
  density: PropTypes.oneOf(['standard', 'compact', 'comfortable']),
  loading: PropTypes.bool,
  autoPageSize: PropTypes.bool,
  showToolbar: PropTypes.bool,
  disableColumnMenu: PropTypes.bool,
  disableColumnSelector: PropTypes.bool,
  handleDataGridChange: PropTypes.func,
  logPageSizeChange: PropTypes.func,
  isExportDisabled: PropTypes.bool
};

export default DataGridEnhanced;
