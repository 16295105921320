import { FRAMEWORKS } from '@anirudhm9/base-lib/lib/constants';

const CLOUD_ANSWER_OPTIONS = {
  YES: {
    label: 'Implemented',
    value: FRAMEWORKS.AWS_SECURITY_HUB_FRAMEWORK.answers.YES.value
  },
  NO: {
    label: 'Needs Attention',
    value: FRAMEWORKS.AWS_SECURITY_HUB_FRAMEWORK.answers.NO.value
  }
};

export { CLOUD_ANSWER_OPTIONS };
