export const setDeviceDetails = (updateLocalData, device) => {
  const { type, version, user, deviceType, model } = device || {};

  //Prefill device fields for user to edit
  updateLocalData('type', type.toUpperCase());
  updateLocalData('version', version);
  updateLocalData('deviceType', deviceType.toUpperCase());
  updateLocalData('user', user?.id);
  updateLocalData('model', model);
};
