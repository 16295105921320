import { getQuestionsFlat } from '@anirudhm9/base-lib/lib/utils';
import { StarRounded } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold'
  }
}));

const Score = ({ item }) => {
  const classes = useStyles();

  const questionsStats = useMemo(() => {
    if (!item) {
      return { answered: 0, total: 0, completed: false };
    }

    const questionsFlatArray = Object.values(getQuestionsFlat(item || {}, 'id') || {}) || [];
    const questionsAnswered = questionsFlatArray.filter((question) => question.datapoint)?.length || 0;
    return {
      total: questionsFlatArray?.length || 0,
      answered: questionsAnswered,
      completed: questionsAnswered === questionsFlatArray?.length
    };
  }, [item]);

  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item>
        <StarRounded color={questionsStats.completed ? 'secondary' : 'action'} />
      </Grid>
      <Grid item>
        <Typography variant='body1' className={classes.bold}>
          {questionsStats?.answered}/{questionsStats?.total}
        </Typography>
      </Grid>
    </Grid>
  );
};
Score.propTypes = {
  item: PropTypes.object
};

export default Score;
