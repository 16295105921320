import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { CardWithLoader, TypographyEnhanced } from '../../global';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  bold: {
    fontWeight: 'bold'
  }
}));

const WidgetContainer = ({ children, title, titleValue = {}, subTitle, subTitleValue = {}, loading, props }) => {
  const classes = useStyles();

  const SubTitle = () => {
    if (!subTitle) {
      return null;
    }

    if (typeof subTitle === 'string') {
      return (
        <Grid item>
          <TypographyEnhanced id={subTitle} value={subTitleValue} variant='body1' />
        </Grid>
      );
    }

    return subTitle;
  };

  return (
    <Grid item xs={12} {...props}>
      <CardWithLoader loading={loading}>
        <Grid container spacing={2}>
          <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
            {title && (
              <Grid item>
                <TypographyEnhanced id={title || ''} value={titleValue} variant='body2' className={classes.bold} />
              </Grid>
            )}
            <SubTitle />
          </Grid>
          {children}
        </Grid>
      </CardWithLoader>
    </Grid>
  );
};

WidgetContainer.propTypes = {
  title: PropTypes.string,
  titleValue: PropTypes.object,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  subTitleValue: PropTypes.object,
  children: PropTypes.any,
  loading: PropTypes.bool,
  props: PropTypes.any
};

export default WidgetContainer;
