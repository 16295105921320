import { Card, CardContent, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IssueItem } from '../../../../../components/application';
import { Heading } from '../../../../../components/ui';
import { usePageViewContext } from '../../../../../contexts/logPageViewContext';
import { logEvent } from '../../../../../library';
import { ISSUES as MANAGE_ISSUES_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/issues';
import { ISSUE_EVENT_TYPES, TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const Issue = () => {
  const classes = useStyles();
  const { id: issueId } = useParams();

  const { pageViewLogged, setPageViewLogged } = usePageViewContext();

  useEffect(() => {
    if (pageViewLogged) {
      return;
    }
    //add page will have no id
    if (!issueId) {
      logEvent(MANAGE_ISSUES_EVENTS.VIEW_ADD_ISSUE, { type: ISSUE_EVENT_TYPES.ADD });
      setPageViewLogged(true);
      return;
    }
    //view page
    logEvent(MANAGE_ISSUES_EVENTS.VIEW_EDIT_ISSUE, { type: ISSUE_EVENT_TYPES.EDIT, issue_id: issueId });
    setPageViewLogged(true);
  }, [issueId, pageViewLogged, setPageViewLogged]);

  return (
    <Container component={'main'} className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title.view')} description={TRANSLATION_PATH('description')} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <IssueItem id={issueId || ''} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

Issue.propTypes = {};
export default Issue;
