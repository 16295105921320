import { STATUS_CODES } from '@anirudhm9/base-lib/lib/constants';
import { createUUID, formatErrorMessage, getDomain, isEmpty, isValidUrl } from '@anirudhm9/base-lib/lib/utils';
import { useMutation, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATE_ORG, CREATE_WEBSITES, GET_COMPANY_SIZES, GET_COUNTRIES, GET_INDUSTRIES, UPDATE_ORG, UPDATE_WEBSITE, setOrgId } from '../../../../api';
import { useOrgContext, useUserContext } from '../../../../contexts';
import { logEvent } from '../../../../library';
import { COMPANY as COMPANY_PROFILE_EVENTS } from '../../../../library/amplitude/events/views/app/settings/company';
import { ButtonEnhanced, TypographyEnhanced, toast } from '../../../global';
import { SelectEnhanced, TextFieldEnhanced } from '../../../ui/form';
import { DEFAULT_ORG_DATA, REQUIRED_FIELDS, WEBSITE_PLACEHOLDER } from './constants';
import CountrySelect from './countrySelect';

const TRANSLATION_PATH = (route) => {
  return `components.application.onboarding.business_info.${route}`;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    justifyContent: 'center'
  },
  title: {
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  }
}));

// This component used in Onboarding and Company Settings pages

const BusinessInfo = ({ setCurrentStep }) => {
  const classes = useStyles();
  const { user } = useUserContext();
  const { orgId, org, refetch: refetchOrg } = useOrgContext();
  const location = useLocation();
  const navigate = useNavigate();

  const isOnboarding = location.pathname.includes('onboarding');

  const { data: industryData, loading: industryLoading, error: industryError } = useQuery(GET_INDUSTRIES);
  const { data: countryData, loading: countryLoading, error: countryError } = useQuery(GET_COUNTRIES);
  const { data: companySizeData, loading: sizeLoading, error: sizeError } = useQuery(GET_COMPANY_SIZES);

  const [createOrganisation] = useMutation(CREATE_ORG);
  const [updateOrganisation] = useMutation(UPDATE_ORG);
  const [createWebsites] = useMutation(CREATE_WEBSITES);
  const [updateWebsite] = useMutation(UPDATE_WEBSITE);

  const [industryOptions, setIndustryOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [companySizeOptions, setCompanySizeOptions] = useState([]);
  const [localData, setLocalData] = useState(DEFAULT_ORG_DATA);
  const [errorFields, setErrorFields] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!org || (org && org?.onboarded) || !setCurrentStep) {
      return;
    }

    if (user?.provider === 'local') {
      navigate('/app/dashboard');
    }

    setCurrentStep(2);
  }, [navigate, org, setCurrentStep, user?.provider]);

  useEffect(() => {
    if (!org) {
      return;
    }

    const { size, industry = {}, country = {}, operations = [] } = org || {};
    setLocalData({
      ...org,
      size: size?.id,
      industry: industry?.id,
      country: country?.id,
      operations: (operations || []).map((operation) => operation.id)
    });
  }, [org]);

  /**
   * Takes property that is an array of objects and returns array of object ids
   * Allows for comparison between org and localData properties for changes
   *
   * @param {[{id: String}]} property
   * @returns [Array of IDs]
   */
  const getApplicationPropertyIDs = (property) => {
    const result = property.reduce((result, current) => {
      result.push(current?.id);
      return result;
    }, []);
    return result;
  };

  /**
   * Logs a single amplitude event after Company Profile update event in handleSave function
   * Logs event properties for old and new Company Profile values that have been edited
   */
  const handleLogUpdateCompanyDetails = () => {
    const localDataKeys = Object.keys(localData);

    // property: { old: oldValue, new: newValue}
    // includes org id to ensure it gets logged
    const updatedProperties = { org_id: orgId };

    for (const key of localDataKeys) {
      //if key doesn't exist in org, don't log it
      if (!org[key]) {
        continue;
      }

      //Case 1: Both org and localData properties are arrays
      //Convert array of objects from org to array of IDs for comparison with localData array of IDs
      if (Array.isArray(org[key]) && typeof org[key][0] !== typeof localData[key][0]) {
        const oldKeyArrayOfIDs = getApplicationPropertyIDs(org[key]);
        //check for equality, if not equal - log changes
        if (!_.isEqual(oldKeyArrayOfIDs, localData[key])) {
          updatedProperties[key] = { old: oldKeyArrayOfIDs, new: localData[key] };
        }
        continue;
      }

      //Case 2: org property is an object with an id, localData is an id string
      //Get id from org object and compare to localData
      if (typeof org[key] !== typeof localData[key] && !_.isEqual(org[key]?.id, localData[key])) {
        updatedProperties[key] = { old: org[key]?.id, new: localData[key] };
        continue;
      }

      //Case 3: Property is same type and has same shape
      //Check for equality, if not equal - log changes
      if (!_.isEqual(org[key], localData[key])) {
        //only need link from object for company websites field
        if (key === 'websites') {
          updatedProperties['company_website'] = { old: org[key][0]?.link, new: localData[key][0]?.link };
          continue;
        }
        updatedProperties[key] = { old: org[key], new: localData[key] };
      }
    }

    logEvent(COMPANY_PROFILE_EVENTS.EDIT_PROFILE, updatedProperties);
  };

  const createOrUpdateOrg = async () => {
    const emptyFields = isEmpty(REQUIRED_FIELDS, localData);

    if (Object.keys(emptyFields).length) {
      setErrorFields(emptyFields);
      return;
    }

    try {
      setLoading(true);
      const { name, size, operations, industry, country, websites: sites } = localData || {};
      const { email = '' } = user || {};
      const domain = getDomain(email);
      const data = {
        name,
        size,
        domain,
        industry,
        country,
        operations,
        uid: createUUID()
      };

      if (_.every(sites, (site) => !isValidUrl(site?.link || ''))) {
        toast.error('Invalid website URL');
        setErrorFields({ websites: 'Invalid website URL' });
        return;
      }

      if (!orgId) {
        const response = await createOrganisation({ variables: { data } });
        const { id: orgId, name: orgName } = response?.data?.createOrg?.org || {};
        await createWebsites({ variables: { org: orgId, sites } });
        setOrgId(orgId, orgName);
      } else {
        delete data.domain;
        delete data.uid;
        await updateOrganisation({ variables: { id: orgId, data } });
        // updates all the websites
        await Promise.all(sites.map((site) => updateWebsite({ variables: { id: site.id, data: { link: site.link } } })));

        handleLogUpdateCompanyDetails();
        refetchOrg();
      }
      toast.success(STATUS_CODES.SUCCESS.DEFAULT.message);
      if (user?.provider === 'local') {
        navigate('/app/dashboard');
      } else if (setCurrentStep) {
        setCurrentStep(2);
      }
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (industryLoading || industryError) {
      return;
    }
    const { industries } = industryData || {};
    const options = (industries || []).map((industry) => {
      const { id: value, name: label } = industry || {};
      return { ...industry, label, value };
    });
    setIndustryOptions(options);
  }, [industryData, industryError, industryLoading]);

  useEffect(() => {
    if (countryLoading || countryError) {
      return;
    }
    const { countries } = countryData || {};
    setCountryOptions(countries);
  }, [countryData, countryError, countryLoading]);

  useEffect(() => {
    if (sizeLoading || sizeError) {
      return;
    }
    const { companysizes } = companySizeData || {};
    const options = (companysizes || []).map((size) => {
      const { id: value, name: label } = size || {};
      return { ...size, label, value };
    });
    setCompanySizeOptions(options);
  }, [companySizeData, sizeError, sizeLoading]);

  const updateLocalData = (key, value) => {
    setLocalData((local) => {
      if (key === 'country' && !local?.operations?.length) {
        return { ...local, [key]: value, operations: [value] };
      }
      return { ...local, [key]: value };
    });
  };

  if (!industryOptions.length || !countryOptions.length || !companySizeOptions.length) {
    return null;
  }

  return (
    <form className={classes.form} noValidate>
      <Grid container spacing={1} className={classes.container} direction={isOnboarding ? 'row' : 'column'}>
        {isOnboarding && (
          <Grid item xs={12}>
            <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h6' className={classes.title} />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextFieldEnhanced
            id='companyName'
            label='Company name'
            value={localData.name || ''}
            onChange={(value) => updateLocalData('name', value)}
            hasError={!!errorFields?.name}
            errorText={errorFields?.name}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldEnhanced
            id='companyWebsite'
            label='Application/Login URL'
            value={localData?.websites?.[0]?.link || ''}
            onChange={(value) =>
              setLocalData((prev) => {
                const newSite = { ...prev?.websites?.[0] } || {};
                newSite.link = value;
                return { ...prev, websites: [newSite] };
              })
            }
            placeholder={WEBSITE_PLACEHOLDER}
            hasError={!!errorFields?.websites}
            errorText={errorFields?.websites}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <SelectEnhanced
            id='industry'
            label='Industry'
            value={localData?.industry || ''}
            options={industryOptions}
            onChange={(data) => updateLocalData('industry', data)}
            fullWidth
            hasError={!!errorFields?.industry}
            errorText={errorFields?.industry}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <SelectEnhanced
            id='size'
            label='Size'
            value={localData?.size || ''}
            options={companySizeOptions}
            onChange={(data) => updateLocalData('size', data)}
            fullWidth
            hasError={!!errorFields?.size}
            errorText={errorFields?.size}
            required
          />
        </Grid>
        {isOnboarding && (
          <Grid item>
            <TypographyEnhanced id={TRANSLATION_PATH('operations_location')} variant='h6' className={classes.title} />
          </Grid>
        )}
        <Grid item xs={12}>
          <CountrySelect
            id='locationOfHeadquarters'
            label='Location of headquarters'
            options={countryOptions}
            value={localData?.country || ''}
            onChange={(data) => updateLocalData('country', data)}
            hasError={!!errorFields?.country}
            errorText={errorFields?.country}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <CountrySelect
            id='operatingCountries'
            label='Countries operating in?'
            multiple
            options={countryOptions}
            value={localData?.operations || []}
            checkbox
            onChange={(data) => updateLocalData('operations', data)}
            hasError={!!errorFields?.operations}
            errorText={errorFields?.operations}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonEnhanced fullWidth variant='contained' onClick={createOrUpdateOrg} loading={loading}>
            <TypographyEnhanced id={TRANSLATION_PATH(isOnboarding ? 'button.next' : 'button.save')} />
          </ButtonEnhanced>
        </Grid>
      </Grid>
    </form>
  );
};

BusinessInfo.propTypes = {
  setCurrentStep: PropTypes.func
};

export default BusinessInfo;
