import { Card, CardContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ButtonEnhanced from '../button';
import TypographyEnhanced from '../typography';

const InsufficientData = ({ title = '', description = '', link, linkText = '' }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardContent>
        <Grid container direction='column' spacing={2} alignItems='center'>
          <Grid item>
            <TypographyEnhanced id={title} variant='h6' textAlign='center' />
            <TypographyEnhanced id={description} variant='caption' textAlign='center' />
          </Grid>
          {link && (
            <Grid item>
              <ButtonEnhanced
                fullWidth
                size='large'
                variant='text'
                onClick={() => {
                  navigate('/app/integrations');
                }}
              >
                <TypographyEnhanced id={linkText} />
              </ButtonEnhanced>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

InsufficientData.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string
};
export default InsufficientData;
