/* eslint-disable quotes */

// components.application.dashboard.widgets.userSummary

export const USER_SUMMARY = {
  title: 'User Summary',
  description:
    'These are people in your team that could potentially increase your cyber exposure level. Typically, the people listed here have administrative access to key systems.',
  disconnected: {
    description:
      'Information required to provide insights here relies on one or more connections to other systems. Either you have not connected the relevant applications or there is an issue with the connections at the moment.',
    button: 'LINK COMPANY ACCOUNTS'
  }
};
