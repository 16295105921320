const ALLOWED_PAGES = {
  DEFAULT: [
    // onboarding
    '/app/onboarding',

    // dashboard
    // '/app/dashboard',

    // assessments
    // '/app/assessment',
    // '/app/assessment/cyber',
    // '/app/assessment/cyber/:id',
    // '/app/assessment/cyber/category/:id',
    '/app/assessment/cyber/category/:id/question/:id',

    // reports
    '/app/reports',
    '/app/reports/news',
    // '/app/reports/devices',
    // '/app/reports/web-security-headers',
    // '/app/reports/cloud-infrastructure',
    // '/app/reports/sbom',

    // rewards
    // '/app/rewards',
    // '/app/rewards/earn',
    // '/app/rewards/redeem',

    // integrations
    // '/app/integrations',
    // '/app/integrations/callback',
    // '/app/integrations/callback/github',

    // manage
    '/app/manage',

    // '/app/manage/users',
    '/app/manage/users/:id',

    // '/app/manage/applications',
    '/app/manage/applications/:id',
    // '/app/manage/applications/add',

    // '/app/manage/devices',
    '/app/manage/devices/:id',
    '/app/manage/devices/add',

    // '/app/manage/issues',
    // '/app/manage/issues/add',
    '/app/manage/issues/:id',

    // settings
    '/app/settings',
    // '/app/settings/company',
    '/app/settings/user'
  ]
};

export default ALLOWED_PAGES;
