import { APPLICATIONS } from './applications';
import { DEVICES } from './devices';
import { ISSUES } from './issues';
import { SINGLE_APPLICATION } from './singleApplication';
import { SINGLE_USER } from './singleUser';
import { USERS } from './users';

export const MANAGE = {
  APPLICATIONS,
  DEVICES,
  ISSUES,
  USERS,
  SINGLE_USER,
  SINGLE_APPLICATION
};
