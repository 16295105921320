import { isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ButtonEnhanced } from '../../../../../../components/global';
import { TextFieldEnhanced } from '../../../../../../components/ui/form';
import { useThreatModellingContext } from '../../../../../../contexts';

// eslint-disable-next-line quotes
const PLACEHOLDER = `A threat actor sends a phishing email to an employee of a company, containing a malicious link. The employee clicks on the link, which downloads a malware dropper to their computer. The dropper then executes a backdoor on the computer, giving the attacker remote access.`;
const ScenarioPrompt = ({ defaultValue, value, onSave, onChange }) => {
  const { promptLoading } = useThreatModellingContext();

  const hasPromptChanged = useMemo(() => !isIDEqual(defaultValue || '', value, true), [defaultValue, value]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldEnhanced
            type='text'
            label='Prompt'
            id='prompt'
            placeholder={PLACEHOLDER}
            value={value || ''}
            onChange={onChange}
            multiline
            rows={3}
            disabled={promptLoading}
          />
        </Grid>
        <Grid container item justifyContent='flex-end'>
          <ButtonEnhanced onClick={onSave} disabled={!hasPromptChanged} size='small' loading={promptLoading}>
            Generate via prompt
          </ButtonEnhanced>
        </Grid>
      </Grid>
    </Grid>
  );
};

ScenarioPrompt.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onSave: PropTypes.func,
  onChange: PropTypes.func
};
export default ScenarioPrompt;
