import { IMAGES } from '@anirudhm9/base-lib/lib/constants';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
  responsiveImage: {
    height: 'auto',
    maxWidth: '100%'
  }
}));

/**
 * @typedef {import('react').ImgHTMLAttributes} imgProps HTML img tag props
 *
 * @param {{ src: String, alt: String} & imgProps}
 * @returns {React.FC <imgProps>}
 */
const Image = ({ src, alt, style, sx, ...props }) => {
  const classes = useStyles();

  const [url, setUrl] = useState(src || '');

  const handleError = () => {
    setUrl(IMAGES.EMPTY_STATE.CONNECTIVITY_CABLE);
  };

  return (
    <img
      referrerPolicy='no-referrer'
      className={classes.responsiveImage}
      onError={handleError}
      src={url || src}
      alt={alt || String(src)}
      style={style || sx}
      {...props}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.object,
  sx: PropTypes.object
};

export default Image;
