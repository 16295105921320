import { EVENTS } from '../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.user.resetPassword.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.USER.RESET_PASSWORD[event];
};

export const requiredFields = ['password', 'passwordConfirmation'];

export const defaultData = {
  password: '',
  passwordConfirmation: '',
  code: ''
};
