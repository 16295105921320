import { formatUserName } from '@anirudhm9/base-lib/lib/utils';
import { Avatar, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useUsersContext } from '../../../../../../contexts/usersContext';
import { ChipEnhanced, TooltipEnhanced, TypographyEnhanced } from '../../../../../global';
import { LetterAvatar } from '../../../../../ui';

const ViewUsersSelect = ({ label, value, size = 'small', onClickEvent }) => {
  const { users, loading } = useUsersContext();
  const navigate = useNavigate();

  if (loading) {
    return;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TypographyEnhanced id={label || ''} />
      </Grid>
      {(users || []).map((user) => {
        const { id, email } = user;

        if (!value?.includes(id)) {
          return null;
        }

        return (
          <Grid item key={email}>
            <TooltipEnhanced title={email || ''}>
              <ChipEnhanced
                id={formatUserName(user)}
                size={size}
                onClick={() => onClickEvent(user) || navigate(`/app/manage/users/${id}`)}
                avatar={user?.imageUrl ? <Avatar src={user?.imageUrl} alt={formatUserName(user)} /> : <LetterAvatar user={user} />}
              />
            </TooltipEnhanced>
          </Grid>
        );
      })}
    </Grid>
  );
};

ViewUsersSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  size: PropTypes.string,
  onClickEvent: PropTypes.func
};

export default ViewUsersSelect;
