import { isIDEqual, preventEnter } from '@anirudhm9/base-lib/lib/utils';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import PropTypes from 'prop-types';

const RadioEnhanced = ({ label, defaultValue, value, onChange, options, disabled, ...props }) => {
  return (
    <FormControl component='fieldset' onKeyDown={preventEnter}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup aria-label={label} name={`radio-buttons-group-${label}`} defaultValue={defaultValue} onChange={(e) => onChange(e.target.value)} {...props}>
        {(options || []).map((option) => {
          const { id, name } = option || {};
          return <FormControlLabel key={id} value={id} control={<Radio checked={isIDEqual(id, value)} color='primary' />} label={name} disabled={disabled} />;
        })}
      </RadioGroup>
    </FormControl>
  );
};

RadioEnhanced.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element, PropTypes.node, PropTypes.object]),
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool
};

export default RadioEnhanced;
