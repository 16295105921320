import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../contexts';
import { RoleProtectedRoute } from '../../../helpers/routing';
import Earn from './earn';
import Redeem from './redeem';

const Rewards = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <Routes>
        <Route path='/' element={<Navigate to='earn' />} />
        <Route path='/earn' element={<RoleProtectedRoute roles={roles} path={pathname} element={Earn} />} />
        <Route path='/redeem/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={Redeem} />} />
        <Route path='*' element={<Navigate to='earn' />} />
      </Routes>
    </div>
  );
};

Rewards.propTypes = {};

export default Rewards;
