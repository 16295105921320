/* eslint-disable quotes */
// components.application.rewards.redeem.rewardCard

export const REWARD_CARD = {
  owner: 'by {{name}}',
  points: '{{points}} points',
  redeemed: 'Redeemed',
  buttons: {
    redeem: 'REDEEM REWARD',
    locked: 'INSUFFICIENT POINTS',
    download: 'DOWNLOAD'
  }
};
