/* eslint-disable quotes */

// components.application.dashboard.widgets.multiFactorAuth

export const MULTI_FACTOR_AUTH = {
  title: 'Multi-Factor Authentication',
  description:
    'Multi-factor authentication (MFA) is important, as it makes stealing your information harder for the average criminal. Ensure all company accounts have MFA enabled.',
  disconnected: {
    description:
      'Information required to provide insights here relies on one or more connections to other systems. Either you have not connected the relevant applications or there is an issue with the connections at the moment.',
    button: 'LINK COMPANY ACCOUNTS'
  },
  buttons: {
    linkAccount: 'LINK COMPANY ACCOUNTS',
    viewAll: 'VIEW ALL'
  }
};
