import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getTotalFilters } from '../../helpers/formatUtils';
import { useApplications } from '../../hooks';
import useApplicationsCount from '../../hooks/useApplicationsCount';
import { DEFAULT_FILTER, DEFAULT_SORT, PAGE_THRESHOLD } from './constants';

const ApplicationsFilterContext = createContext();
/**
 * @returns {{
 * applications: Array,
 * applicationsCount: Number,
 * loading: Boolean,
 * error: ApolloError,
 * page: Number,
 * setPage: Void Function,
 * searchTerm: String,
 * setSearchTerm: Void Function,
 * searchQuery: String,
 * setSearchQuery: Void Function,
 * filterQuery: JSON,
 * setFilterQuery: Void Function,
 * setWhere: Void Function,
 * sort: String,
 * setSort: Void Function,
 * setStart: Number,
 * pageSize: Number,
 * setPageSize: Void Function,
 * selectedFilters: JSON,
 * setSelectedFilters: Void Function,
 * totalFilters: Number,
 * refetch: (variables?: Partial<{application: *}>) => Promise<ApolloQueryResult<any>>
 * refetchAppCount: Promise<ApolloQueryResult<any>>}}
 */
export const useApplicationsFilterContext = () => {
  return useContext(ApplicationsFilterContext);
};

const ApplicationsFilterProvider = ({ children }) => {
  const [where, setWhere] = useState({});
  const [selectedFilters, setSelectedFilters] = useState(DEFAULT_FILTER);
  const [sort, setSort] = useState(DEFAULT_SORT);

  const [start, setStart] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_THRESHOLD);
  const [page, setPage] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchQuery, setSearchQuery] = useState();
  const [filterQuery, setFilterQuery] = useState();

  const totalFilters = useMemo(() => getTotalFilters(selectedFilters || []), [selectedFilters]);

  //FILTERED APPLICATIONS
  const { applications, loading, error, refetch } = useApplications(where, sort, start, pageSize);

  //If user has used search or changed filters, reset page to 0
  useEffect(() => {
    if (!searchQuery && !filterQuery) {
      return;
    }
    setStart(0);
    setPage(0);
  }, [filterQuery, refetch, searchQuery]);

  //FILTERED APPLICATIONS COUNT
  const { applicationsCount, refetch: refetchAppCount } = useApplicationsCount(where);

  const value = {
    applications,
    applicationsCount,
    loading,
    error,
    page,
    setPage,
    searchTerm,
    setSearchTerm,
    searchQuery,
    setSearchQuery,
    filterQuery,
    setFilterQuery,
    setWhere,
    sort,
    setSort,
    setStart,
    pageSize,
    setPageSize,
    selectedFilters,
    setSelectedFilters,
    totalFilters,
    refetch,
    refetchAppCount
  };

  return <ApplicationsFilterContext.Provider value={value}>{children}</ApplicationsFilterContext.Provider>;
};

ApplicationsFilterProvider.propTypes = {
  children: PropTypes.element
};

export default ApplicationsFilterProvider;
