// components.user.register.userForm

export const USER_FORM = {
  firstName: { label: 'First Name', placeholder: 'Your first name' },
  lastName: { label: 'Last Name', placeholder: 'Your last name' },
  email: { label: 'Email', placeholder: 'Only business email addresses will be accepted' },
  oldPassword: { label: 'Old Password', placeholder: 'Enter old password' },
  newPassword: { label: 'New Password', placeholder: 'Enter new password' },
  password: { label: 'Password', placeholder: 'Choose password' },
  confirmPassword: { label: 'Confirm Password', placeholder: 'Confirm password' }
};
