import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { fileDownloadLink } from '../../../../../../../../constants/defaults';
import { SecureDownload } from '../../../../../../../../containers/dialogs';
import { downloadFile } from '../../../../../../../../library';
import { Dropzone } from '../../../../../../../global';
import AttachedFiles from '../../../../../../../ui/attachedFiles';
import { AUTO_SAVE_DELAY } from '../../constants';

const Upload = ({ id, label, attachments, setAttachments, onSave, onUpload, loading, customTimeout, setCustomTimeout }) => {
  const [latestTimeout, setLatestTimeout] = useState();
  const [fileToDownload, setFileToDownload] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen((open) => !open);
  };

  const handleChange = (values) => {
    const callback = () => {
      onSave({ attachments: values });
      setLatestTimeout();
      setCustomTimeout();
    };

    const { questionId: previousQuestionId, callback: previousCallback } = customTimeout || {};
    if (id !== previousQuestionId && previousCallback) {
      previousCallback();
    }

    if (latestTimeout) {
      clearTimeout(latestTimeout);
    }

    setAttachments(values);
    const timeout = setTimeout(callback, AUTO_SAVE_DELAY);
    setCustomTimeout({ id: timeout, questionId: id, callback });
    setLatestTimeout(timeout);
  };

  const handleDownload = (file = {}) => {
    setFileToDownload(file);
    toggleModal();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>{label || ''}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Dropzone onUpload={onUpload} multiple loading={loading} />
      </Grid>
      <Grid item xs={12}>
        <AttachedFiles defaultOpen={true} attachments={attachments} setAttachments={handleChange} onDownloadClick={handleDownload} />
      </Grid>
      <SecureDownload
        onClick={() => {
          downloadFile(fileDownloadLink(fileToDownload?.hash));
        }}
        open={modalOpen}
        toggle={toggleModal}
        file={fileToDownload || {}}
      />
    </Grid>
  );
};

Upload.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  attachments: PropTypes.array.isRequired,
  setAttachments: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  customTimeout: PropTypes.object,
  setCustomTimeout: PropTypes.func.isRequired
};
export default Upload;
