import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import DotLoader from '../dotLoader';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative'
  },
  buttonStyle: {
    borderRadius: '2em'
  }
}));

/**
 * @description:
 * Button with loading icon
 *
 * @typedef {import('@mui/material').ButtonProps} ButtonProps Props for `Button` from '@mui/material` library
 * @param {{ loading: Boolean } & ButtonProps} props loading state for Spinner
 * @returns {React.FC<ButtonProps>}
 */
const ButtonEnhanced = ({ loading, color, onClick, disabled, children, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button className={classes.buttonStyle} variant='contained' color={color || 'primary'} disabled={disabled || loading} onClick={onClick} {...props}>
        {children}
      </Button>
      {loading && <DotLoader />}
    </div>
  );
};

ButtonEnhanced.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string
};

export default ButtonEnhanced;
