import { gql } from '@apollo/client';

export const COUNT_LIBRARIES = gql`
  query countLibraries($where: JSON!) {
    countLibraries(where: $where)
  }
`;

export const GET_LIBRARIES = gql`
  query libraries($where: JSON!, $start: Int, $limit: Int, $sort: String) {
    libraries(where: $where, start: $start, limit: $limit, sort: $sort) {
      id
      name
      description
      url
      private
      developmentFramework
      auditSummary
    }
  }
`;
