const { FRAMEWORKS } = require('../../../constants');
const { getQuestionsFlat } = require('../../common');

const getScoreTotals = (category) => {
  const result = {
    points: 0,
    pointsPercentage: 0,
    answered: 0,
    total: 0
  };
  // gets questions flat with key value pairs, where key is mappingNumber
  const questionsFlat = getQuestionsFlat(category);
  const questionsArray = Object.values(questionsFlat || {});
  // filters all the questions that are not archived
  const applicableQuestions = (questionsArray || []).filter((question) => !question.archived);
  // number of questions answered out of the applicable questions
  const answeredQuestions = (applicableQuestions || []).filter((question) => question.datapoint);

  // number of questions answered as "Yes" for MCF
  const yesAnsweredQuestions = (answeredQuestions || []).filter((question) => {
    const { datapoint = {} } = question || {};
    const { selected = [] } = datapoint || {};
    return (selected || []).find((option) => option.value === FRAMEWORKS.MASTER_CONTROL_FRAMEWORK.answers.YES.value);
  });

  result.total = (applicableQuestions || []).length;
  result.answered = (answeredQuestions || []).length;
  result.answeredPercentage = (result.answered * 100) / result.total;
  result.points = (yesAnsweredQuestions || []).length;
  result.pointsPercentage = (result.points * 100) / result.total;

  return result;
};

module.exports = {
  getScoreTotals
};
