import { gql } from '@apollo/client';

export const GET_DEVICES = gql`
  query devices($where: JSON) {
    devices(where: $where, sort: "lastSync:ASC") {
      id
      deviceId
      type
      version
      model
      deviceType
      compromisedStatus
      passwordStatus
      encryptionStatus
      firstSync
      lastSync
      custom
      org {
        id
      }
      user {
        id
        firstName
        lastName
        email
      }
      integration {
        id
        name
        type
      }
    }
  }
`;

export const GET_DEVICE = gql`
  query device($id: ID!) {
    device(id: $id) {
      id
      deviceId
      type
      model
      deviceType
      compromisedStatus
      passwordStatus
      encryptionStatus
      firstSync
      lastSync
      custom
      org {
        id
      }
      user {
        id
        firstName
        lastName
        email
      }
      integration {
        id
        name
        type
      }
    }
  }
`;

export const CREATE_DEVICE = gql`
  mutation createDevice($data: DeviceInput!) {
    createDevice(input: { data: $data }) {
      device {
        id
      }
    }
  }
`;

export const UPDATE_DEVICE = gql`
  mutation updateDevice($id: ID!, $data: editDeviceInput!) {
    updateDevice(input: { where: { id: $id }, data: $data }) {
      device {
        id
      }
    }
  }
`;
