import { ANSWER_OPTION_SUB_TYPES, ANSWER_OPTION_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { formatErrorMessage, isEmpty, validateURL } from '@anirudhm9/base-lib/lib/utils';
import { useMutation, useQuery } from '@apollo/client';
import { GridViewRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CREATE_APPLICATION, GET_ANSWER_OPTIONS, GET_THIRDPARTIES } from '../../../../../api';
import { defaultMessages } from '../../../../../constants';
import { useApplicationsFilterContext, useOrgContext } from '../../../../../contexts';
import { useUsersContext } from '../../../../../contexts/usersContext';
import { ButtonEnhanced, ChipEnhanced, TypographyEnhanced, toast } from '../../../../global';
import { HTMLEditor, TextFieldEnhanced } from '../../../../ui/form';
import AppLogo from '../appLogo';
import HeldInfoSelect from '../heldInfo/heldInfoSelect';
import { KBS_TYPES } from '../kbs/kbsChip/constants';
import KbsSelect from '../kbs/kbsSelect';
import EditUsersSelect from '../viewEditUsers/editUsersSelect';
import ApplicationAutoComplete from './autoComplete';
import { DEFAULT_APPLICATION_DATA, MANUAL_OPTION, REQUIRED_FIELDS, TRANSLATION_PATH } from './constants';

const ManualAddApp = () => {
  const { orgId } = useOrgContext();
  const { users: usersOptions, loading: usersLoading } = useUsersContext();
  const { refetch: refetchApplications, refetchAppCount } = useApplicationsFilterContext();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [localData, setLocalData] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [selectedThirdpartyId, setSelectedThirdpartyId] = useState();
  const [selectedThirdparty, setSelectedThirdparty] = useState();
  const [thirdpartyOptions, setThirdpartyOptions] = useState([]);

  const [createApplication] = useMutation(CREATE_APPLICATION);

  const {
    data: thirdpartiesData,
    loading: thirdpartiesLoading,
    error: thirdpartiesError
  } = useQuery(GET_THIRDPARTIES, {
    variables: {}
  });

  const thirdparties = useMemo(() => {
    if (thirdpartiesError || thirdpartiesLoading) {
      return [];
    }
    return thirdpartiesData?.thirdparties || [];
  }, [thirdpartiesData?.thirdparties, thirdpartiesError, thirdpartiesLoading]);

  const {
    data: informationHeldData,
    loading: informationHeldLoading,
    error: informationHeldError
  } = useQuery(GET_ANSWER_OPTIONS, {
    variables: {
      where: {
        value_ne: null,
        type: ANSWER_OPTION_TYPES.THIRD_PARTY,
        subType: ANSWER_OPTION_SUB_TYPES.INFORMATION
      }
    }
  });

  const informationHeldOptions = useMemo(() => {
    if (informationHeldLoading || informationHeldError) {
      return [];
    }
    return informationHeldData?.answeroptions || [];
  }, [informationHeldData?.answeroptions, informationHeldError, informationHeldLoading]);

  useEffect(() => {
    if (!thirdparties.length) {
      return;
    }
    setThirdpartyOptions(thirdparties || []);
  }, [thirdparties]);

  useEffect(() => {
    const { id, name, kbs, informationHeld, website } = selectedThirdparty || {};

    setLocalData({
      ...DEFAULT_APPLICATION_DATA,
      name: name || '',
      thirdparty: id,
      kbs: kbs || KBS_TYPES.NONE.value,
      informationHeld: (informationHeld || []).map((information) => information.id),
      website: website?.link || '',
      org: orgId
    });

    return () => {
      setLocalData(DEFAULT_APPLICATION_DATA);
    };
  }, [orgId, selectedThirdparty]);

  const updateLocalData = (key, value) => {
    setLocalData((local) => {
      return { ...local, [key]: value };
    });
  };

  const handleSaveApp = async () => {
    const emptyFields = isEmpty(REQUIRED_FIELDS, localData);
    if (Object.keys(emptyFields || {}).length) {
      setErrorFields(emptyFields);
      toast.error(defaultMessages.requiredFields);
      return;
    }

    if (!validateURL(localData?.website)) {
      setErrorFields({
        website: defaultMessages.invalidInput
      });
      toast.error(defaultMessages.invalidFields);
      return;
    }

    try {
      setLoading(true);
      await createApplication({
        variables: {
          data: localData
        }
      });
      refetchApplications();
      refetchAppCount();
      toast.success('Application created successfully.');
      navigate(-1);
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value, data) => {
    const thirdpartiesCopy = _.cloneDeep(thirdparties);
    const { name: thirdpartyName, inputValue } = data || {};
    let name = thirdpartyName;
    if (inputValue) {
      name = inputValue;
      thirdpartiesCopy.push({
        ...MANUAL_OPTION,
        name
      });
      setThirdpartyOptions(thirdpartiesCopy);
    }

    const thirdparty = thirdparties.find((option) => option?.id === value);

    setSelectedThirdpartyId(inputValue ? MANUAL_OPTION.id : thirdparty?.id);
    setSelectedThirdparty(thirdparty);
    updateLocalData('name', name);
  };

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item>
          {selectedThirdparty?.logoUrl ? (
            <AppLogo url={selectedThirdparty?.logoUrl || ''} alt={selectedThirdparty?.name || localData?.name || ''} style={{ height: 100, width: 100 }} />
          ) : (
            <GridViewRounded sx={{ height: 100, width: 100 }} color='primary' />
          )}
        </Grid>
        <Grid container item xs spacing={1}>
          <Grid item xs={12}>
            <ApplicationAutoComplete
              options={thirdpartyOptions}
              onChange={handleChange}
              value={selectedThirdpartyId || ''}
              errorText={errorFields['name']}
              hasError={!!errorFields['name']}
            />
          </Grid>
          <Grid item xs={12}>
            <TypographyEnhanced id={selectedThirdparty?.description || ''} variant='caption' />
          </Grid>
          <Grid container item spacing={1}>
            {(selectedThirdparty?.categories || []).map((category) => {
              const { id, name } = category || {};
              return (
                <Grid item key={id}>
                  <ChipEnhanced id={name || ''} variant='outlined' />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldEnhanced
          id='customName'
          label='Custom Name'
          value={localData?.customName}
          onChange={(value) => updateLocalData('customName', value)}
          required
          errorText={errorFields['customName']}
          hasError={!!errorFields['customName']}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldEnhanced
          id='website'
          label='App/Login URL'
          value={localData?.website}
          onChange={(value) => updateLocalData('website', value.toLowerCase())}
          errorText={errorFields['website']}
          hasError={!!errorFields['website']}
          placeholder='https://domain.com'
          required
        />
      </Grid>

      <Grid item xs={12}>
        <KbsSelect
          value={localData?.kbs || ''}
          onChange={(value) => updateLocalData('kbs', value)}
          errorText={errorFields['kbs']}
          hasError={!!errorFields['kbs']}
        />
      </Grid>

      <Grid item xs={12}>
        <HeldInfoSelect
          options={informationHeldOptions || []}
          value={localData?.informationHeld}
          onChange={(values) => updateLocalData('informationHeld', values)}
          errorText={errorFields['informationHeld']}
          hasError={!!errorFields['informationHeld']}
        />
      </Grid>

      <Grid item xs={12}>
        <EditUsersSelect
          options={(usersOptions || []).filter((user) => !user.archived && !user.blocked)}
          label='Users using this application'
          value={localData?.users || []}
          onChange={(values) => updateLocalData('users', values)}
          loading={usersLoading}
          enable
          errorText={errorFields['users']}
          hasError={!!errorFields['users']}
        />
      </Grid>

      <Grid item xs={12} container>
        <EditUsersSelect
          options={(usersOptions || []).filter((user) => user.archived || user.blocked)}
          label='Archived Users'
          value={localData?.inactiveUsers || []}
          onChange={(values) => updateLocalData('inactiveUsers', values)}
          loading={usersLoading}
          enable
          errorText={errorFields['inactiveUsers']}
          hasError={!!errorFields['inactiveUsers']}
        />
      </Grid>

      <Grid item xs={12}>
        <HTMLEditor
          label='Notes'
          value={localData?.note || ''}
          onChange={(value) => updateLocalData('note', value)}
          errorText={errorFields['note']}
          hasError={!!errorFields['note']}
          placeholder=''
        />
      </Grid>

      <Grid container item xs={12} spacing={2} justifyContent='flex-end'>
        <Grid item>
          <ButtonEnhanced onClick={() => navigate(-1)} loading={loading || usersLoading} variant='outlined'>
            <TypographyEnhanced id={'common.button.cancel'} />
          </ButtonEnhanced>
        </Grid>
        <Grid item>
          <ButtonEnhanced onClick={handleSaveApp} loading={loading || usersLoading}>
            <TypographyEnhanced id={TRANSLATION_PATH('buttons.save')} />
          </ButtonEnhanced>
        </Grid>
      </Grid>
    </Grid>
  );
};

ManualAddApp.propTypes = {
  thirdparty: PropTypes.object
};

export default ManualAddApp;
