import { themeVar } from '../../../../api';

/**
 * Return a random color from the palette
 *
 * @returns {String} Color
 */
export const stringToColor = (value = '') => {
  const theme = themeVar();
  const palette = theme.palette;

  const colors = ['blue', 'green', 'orange', 'red', 'yellow'];
  const randomIndex = value.length % colors.length;

  return palette[colors[randomIndex]].main;
};
