import { INTEGRATION_TYPES, STATUS_CODES, TUTORIAL } from '@anirudhm9/base-lib/lib/constants';
import { createPassword, formatErrorMessage, getDomain, isEmpty, validateEmail } from '@anirudhm9/base-lib/lib/utils';
import { useMutation, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CHECK_AUTHORISATION, INVITE_ADMIN, UPDATE_ORG } from '../../../../api';
import { defaultMessages } from '../../../../constants';
import { useIntegrationContext, useUserContext } from '../../../../contexts';
import { useOrgContext } from '../../../../contexts/orgContext';
import { openLink } from '../../../../helpers/routing';
import { ButtonEnhanced, DividerWithText, TypographyEnhanced, toast } from '../../../global';
import AccordionEnhanced from '../../../ui/accordion';
import { TextFieldEnhanced } from '../../../ui/form';
import { INTEGRATION_SUBMISSION_TYPES } from '../../integrations/connectIntegrationModal/addApplication/constants';
import { DEFAULT_USER_DATA, GSUITE_STATES, PERMISSIONS, REQUIRED_FIELDS } from './constants';

const TRANSLATION_PATH = (route) => {
  return `components.application.onboarding.connect_gsuite.${route}`;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: 0,
    justifyContent: 'center'
  },
  title: {
    marginBottom: theme.spacing(3),
    texttextAlign: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  }
}));

const ConnectGsuite = ({ type, onSubmit }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { orgId, org, refetch: refetchOrg } = useOrgContext();
  const { integrationsByType, loading: integrationLoading, error: integrationError } = useIntegrationContext();

  const [updateOrganisation] = useMutation(UPDATE_ORG);
  const [createAndInviteAdmin] = useMutation(INVITE_ADMIN);

  const [gSuiteIntegration, setGsuiteIntegration] = useState({});
  const [localData, setLocalData] = useState(DEFAULT_USER_DATA);
  const [errorFields, setErrorFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [authorised, setAuthorised] = useState(false);
  const [authorisationFailed, setAuthorisationFailed] = useState(false);

  const authAttempted = localStorage.getItem('gsuite_auth') === GSUITE_STATES.ATTEMPTED;

  const authLink = useMemo(() => {
    if (org?.onboarded) {
      return gSuiteIntegration?.settings?.ONBOARDED_AUTH_LINK;
    }
    return gSuiteIntegration?.settings?.AUTH_LINK;
  }, [gSuiteIntegration?.settings?.AUTH_LINK, gSuiteIntegration?.settings?.ONBOARDED_AUTH_LINK, org?.onboarded]);

  const {
    data: authorisationData,
    loading: authorisationLoading,
    error: authorisationError
  } = useQuery(CHECK_AUTHORISATION, { variables: { id: gSuiteIntegration?.id }, skip: !gSuiteIntegration?.id });

  const completeOnboarding = useCallback(async () => {
    try {
      await updateOrganisation({ variables: { id: orgId, data: { onboarded: true } } });
      await refetchOrg();
      toast.success(defaultMessages.defaultSuccess);
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    }
  }, [orgId, refetchOrg, updateOrganisation]);

  useEffect(() => {
    const authFailed = localStorage.getItem('gsuite_auth') === GSUITE_STATES.FAILED;
    setAuthorisationFailed(authFailed);
  }, []);

  useEffect(() => {
    if (integrationLoading || integrationError) {
      return;
    }
    const gSuite = integrationsByType[INTEGRATION_TYPES.GSUITE.value];
    setGsuiteIntegration(gSuite);
  }, [integrationError, integrationLoading, integrationsByType]);

  useEffect(() => {
    if (authorisationLoading) {
      return;
    }

    if (authAttempted && authorisationError) {
      toast.error(STATUS_CODES.ERROR.PRIVILEGE_MISMATCH.message);
      localStorage.setItem('gsuite_auth', GSUITE_STATES.FAILED);
      setAuthorisationFailed(true);
      return;
    }
    const { checkAuthorisation } = authorisationData || {};
    setAuthorised(checkAuthorisation);
  }, [authAttempted, authorisationData, authorisationError, authorisationLoading]);

  useEffect(() => {
    if (org?.onboarded) {
      return;
    }

    if (authorised) {
      completeOnboarding();
      navigate('/app/dashboard');
    }
  }, [authorised, completeOnboarding, navigate, org?.onboarded]);

  const skipInvitation = async () => {
    try {
      if (!orgId) {
        toast.error(defaultMessages.defaultError);
        return;
      }

      setLoading(true);
      await completeOnboarding();
      toast.success('Onboarding complete.');
      navigate('/app/dashboard');
    } catch (error) {
      console.error({ error });
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const inviteAdmin = async () => {
    setErrorFields({}); // clear old errors
    const emptyFields = isEmpty(REQUIRED_FIELDS, localData);

    if (Object.keys(emptyFields).length) {
      setErrorFields(emptyFields);
      setLoading(false);
      toast.error(defaultMessages.requiredFields);
      return;
    }
    const { email = '' } = localData || {};

    if (!validateEmail(email)) {
      setErrorFields({ email: defaultMessages.invalidEmail });
      toast.error(defaultMessages.invalidEmail);
      return;
    } else if (getDomain(user?.email) !== getDomain(email)) {
      setErrorFields({ email: 'User domain mismatch' });
      toast.error('User domain mismatch');
      return;
    }

    try {
      if (!orgId) {
        toast.error(defaultMessages.defaultError);
        return;
      } else {
        const createUserData = {
          ...localData,
          username: email,
          org: orgId,
          tutorial: TUTORIAL,
          password: createPassword()
        };
        setLoading(true);
        await createAndInviteAdmin({ variables: { data: createUserData, org: orgId } });
        await completeOnboarding();
        if (onSubmit) {
          onSubmit(type, INTEGRATION_SUBMISSION_TYPES.INVITE_ADMIN.value);
        }
        toast.success('User invitation sent.');
        navigate('/app/dashboard');
      }
    } catch (error) {
      console.error({ error });
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className={classes.form} noValidate>
      <Grid container spacing={2} className={classes.container}>
        {authorisationFailed ? (
          <Grid item>
            <TypographyEnhanced id={TRANSLATION_PATH('unauthorised')} variant='h6' textAlign='center' />
          </Grid>
        ) : (
          <Grid container item xs spacing={2}>
            <Grid item xs>
              <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h6' textAlign='center' />
            </Grid>
            <Grid item>
              <TypographyEnhanced id={TRANSLATION_PATH('description')} variant='body1' textAlign='center' />
            </Grid>
            <Grid item>
              <TypographyEnhanced id={TRANSLATION_PATH('scope_description')} variant='body1' textAlign='center' />

              {PERMISSIONS.map((item, i) => {
                return (
                  <AccordionEnhanced key={i} header={item.label}>
                    {item.description}
                  </AccordionEnhanced>
                );
              })}
            </Grid>
            <Grid item xs={12}>
              <ButtonEnhanced
                fullWidth
                variant='contained'
                onClick={() => {
                  if (onSubmit) {
                    onSubmit(type, INTEGRATION_SUBMISSION_TYPES.AUTHORIZE_INTEGRATION.value);
                  }
                  localStorage.setItem('gsuite_auth', GSUITE_STATES.ATTEMPTED);
                  openLink(authLink, '_self');
                }}
                loading={loading || integrationLoading || authorisationLoading}
              >
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.authorise')} />
              </ButtonEnhanced>
            </Grid>
            <Grid container alignItems='center' justifyContent='center' item xs={12} className={classes.container}>
              <Grid item>
                <TypographyEnhanced id={TRANSLATION_PATH('privacy_policy.1')} variant='body1' textAlign='inline' />
              </Grid>
              <Grid item>
                <TypographyEnhanced
                  id={TRANSLATION_PATH('privacy_policy.2')}
                  variant='body1'
                  textAlign='inline'
                  onClick={() => openLink('https://www.avertro.com/privacy-policy')}
                  fontWeight='bold'
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <DividerWithText text='Not Admin?' />
        <Grid item xs={12}>
          <TypographyEnhanced id={TRANSLATION_PATH('invite_admin')} variant='body1' textAlign='center' />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextFieldEnhanced
            id='firstName'
            label='First name'
            value={localData?.firstName || ''}
            onChange={(value) =>
              setLocalData((prev) => {
                return { ...prev, firstName: value };
              })
            }
            hasError={!!errorFields?.firstName}
            errorText={errorFields?.firstName}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldEnhanced
            id='lastName'
            label='Last name'
            value={localData?.lastName || ''}
            onChange={(value) =>
              setLocalData((prev) => {
                return { ...prev, lastName: value };
              })
            }
            hasError={!!errorFields?.lastName}
            errorText={errorFields?.lastName}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldEnhanced
            id='email'
            label='Email'
            type='email'
            value={localData?.email || ''}
            onChange={(value) =>
              setLocalData((prev) => {
                return { ...prev, email: value };
              })
            }
            hasError={!!errorFields?.email}
            errorText={errorFields?.email}
            required
          />
        </Grid>
        <Grid container item spacing={2} justifyContent='flex-end'>
          {!org?.onboarded && (
            <Grid item xs={6}>
              <ButtonEnhanced fullWidth variant='outlined' onClick={skipInvitation} loading={loading || integrationLoading || authorisationLoading}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.skip')} />
              </ButtonEnhanced>
            </Grid>
          )}
          <Grid item xs={6}>
            <ButtonEnhanced fullWidth variant='contained' onClick={inviteAdmin} loading={loading || integrationLoading || authorisationLoading}>
              <TypographyEnhanced id={TRANSLATION_PATH('buttons.invite')} />
            </ButtonEnhanced>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

ConnectGsuite.propTypes = {
  type: PropTypes.oneOf(Object.keys(INTEGRATION_TYPES)),
  onSubmit: PropTypes.func
};

export default ConnectGsuite;
