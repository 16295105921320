import { PAGE_THRESHOLD } from '@anirudhm9/base-lib/lib/constants';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { COUNT_LOGS } from '../../api';

/**
 * Custom hook to encapsulate COUNT_LOGS
 * usage example:
 *
 * const { count, loading, error, refetch } = useLogsCount()
 *
 * @returns {{count: (Number), refetch: (variables?: Partial<{criticality: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError}}
 */
const useLogsCount = (variables = { where: {}, start: 0, limit: PAGE_THRESHOLD }, skip = false) => {
  const {
    data: logCountData,
    loading,
    error,
    refetch
  } = useQuery(COUNT_LOGS, {
    variables,
    skip
  });

  const count = useMemo(() => {
    if (loading || error) {
      return 0;
    }
    return logCountData?.countLogs || 0;
  }, [error, loading, logCountData?.countLogs]);

  return { count, loading, error, refetch };
};

export default useLogsCount;
