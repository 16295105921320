import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

/**
 * Taken from https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
 * @param data
 * @param filename
 * @param mime
 * @param bom
 */
function downloadFileFromData(data, filename, mime, bom) {
  var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL = window.URL && window.URL.createObjectURL ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 0);
  }
}

export function addCommentsAndAttachments(datapoint, withTimestamps) {
  const comments = { comments: '', attachments: '', attachmentLinks: '' };
  if (datapoint && datapoint.datapointactivities && datapoint.datapointactivities.length) {
    const datapoints = _.orderBy(datapoint.datapointactivities, ['created_at'], ['desc']);
    datapoints.forEach((item) => {
      if (item.attachment && item.attachment.length) {
        item.attachment.forEach((file) => {
          comments.attachments += `${file.name} \n`;
          comments.attachmentLinks += `${window.location.origin}/app/securefile?file=${file.hash} \n`;
        });
      }
      if (!item.type || item.type === 'CATEGORY_ADD_COMMENT' || item.type === 'ADD_COMMENT') {
        if (item.comment) {
          comments.comments += `${withTimestamps ? moment(item.created_at).format('DD-MM-YYYY  h:mm:ss a') + ': ' : ''}  ${item.comment} \n`;
        }
      }
    });
  }
  return comments;
}

// eslint-disable-next-line
export function downloadCsv(fields, data, fileName) {
  // todo figure this out later
  // const parser = new Parser({
  //   fields: fields
  // });
  // const csv = parser.parse(data);
  // const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  // saveAs(blob, fileName);
}

/**
 * Initiates a file download with axios (XHR) - adding default Authorization header and allowing to access secure file endpoints.
 * Then turning the response in to object URL
 * @param url
 * @returns {Promise<void>} - initiates browser file download dialogue.
 */
export default async function downloadFile(url) {
  const res = await axios.get(url, { responseType: 'blob' });
  const contentType = res.headers['content-type'];
  const cd = res.headers['content-disposition'];
  const fileName = cd ? cd.substring(cd.indexOf('filename=') + 9) : 'essentialsDownloadedFile';
  downloadFileFromData(res.data, fileName, contentType);
}
