import { FRAMEWORKS } from '@anirudhm9/base-lib/lib/constants';
import { getCategoriesFlat, getQuestionsFlat, isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COUNT_SCENARIOS, CREATE_OR_UPDATE_SCENARIO, CREATE_SCENARIO_TEMPLATE, DELETE_SCENARIO, GET_SCENARIOS, GET_SCENARIO_FROM_PROMPT } from '../../api';
import { toast } from '../../components/global';
import { DEFAULT_PAGE_SIZE } from '../../components/ui/pagination/constants';
import { useAssessmentData } from '../../hooks';
import { DEFAULT_SCENARIO } from '../../views/app/assessments/threatFx/constants';
import { useOrgContext } from '../orgContext';
import { THREAT_MODELLING_COLORS, getControlThreshold } from './utils';

const ThreatModellingContext = createContext();

/**
 * @typedef {{id: string|number, source: object,sourceTactic: object, target: object, targetTactic: object, links: Array}} Scenario
 *
 * @returns {{
 * loading: boolean,
 * setLoading: VoidFunction,
 * frameworkOptions: {label: string, value: string|number, data: object}[],
 * selectedFramework: object,
 * setSelectedFramework: VoidFunction,
 * isMitreFrameworkSelected: boolean,
 * category: object,
 * questionsFlat: object,
 * categoryLoading: boolean,
 * refetchCategory: VoidFunction,
 * rootScenario: object,
 * scenario: Scenario,
 * setScenario: VoidFunction,
 * updateLinksFlat: VoidFunction,
 * resetScenario: VoidFunction,
 * scenarios: Scenario[],
 * refetchScenarios: VoidFunction,
 * resetScenarios: VoidFunction ,
 * selectedQuestionId: object,
 * setSelectedQuestionId: VoidFunction,
 * selectedCategoryId: object,
 * setSelectedCategoryId: VoidFunction,
 * linksFlat: object,
 * setLinksFlat: VoidFunction,
 * saveScenario: VoidFunction,
 * deleteScenario: VoidFunction
 * showBarrier: boolean,
 * setShowBarrier: VoidFunction
 * promptLoading: boolean,
 * promptDescription: string,
 * generateScenarioFromPrompt: (prompt: string) => Promise<{name: string, description: string, prompt: string, mappings: JSON}>,
 * saveTemplate: VoidFunction
 * selectedTemplate: object
 * setSelectedTemplate: VoidFunction
 * }}
 */
export const useThreatModellingContext = (props) => {
  try {
    return useContext(ThreatModellingContext) || {};
  } catch (error) {
    console.error(error);
    return props || {};
  }
};

const ThreatModellingContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const { orgId, org } = useOrgContext();

  const [createScenarioTemplate] = useMutation(CREATE_SCENARIO_TEMPLATE);
  const [createOrUpdateScenario] = useMutation(CREATE_OR_UPDATE_SCENARIO);
  const [deleteScenarioMutation] = useMutation(DELETE_SCENARIO);
  const [getScenarioFromPrompt] = useMutation(GET_SCENARIO_FROM_PROMPT);

  const [selectedFramework, setSelectedFramework] = useState();
  const [thresholds, setThresholds] = useState(THREAT_MODELLING_COLORS);
  const [showBarrier, setShowBarrier] = useState(false);
  const [promptLoading, setPromptLoading] = useState(false);
  const [promptDescription, setPromptDescription] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [start, setStart] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [loading, setLoading] = useState(false);
  const [scenarios, setScenarios] = useState([]);
  const [scenario, setScenario] = useState({ links: [] });
  // question modal
  const [selectedQuestionId, setSelectedQuestionId] = useState();
  // category modal
  const [selectedCategoryId, setSelectedCategoryId] = useState();

  const mitreFramework = useMemo(() => {
    if (!org?.frameworks?.length) {
      return;
    }
    return org?.frameworks?.find((framework) => framework.value === FRAMEWORKS.MITRE_ATTACK.value);
  }, [org?.frameworks]);

  useEffect(() => {
    if (!mitreFramework) {
      return;
    }
    setSelectedFramework(mitreFramework);
  }, [mitreFramework]);

  // fetches category data for selected framework
  const { assessment, loading: assessmentLoading, refetch: assessmentRefetch } = useAssessmentData(selectedFramework?.id, !selectedFramework?.id);

  const isMitreFrameworkSelected = useMemo(() => isIDEqual(mitreFramework?.id, selectedFramework?.id), [mitreFramework?.id, selectedFramework?.id]);

  const categories = useMemo(() => {
    if (!assessment?.category || !assessment?.category?.children?.length || assessmentLoading) {
      return [];
    }
    return _.sortBy(assessment?.category?.children || [], (child) => Number(child?.index));
  }, [assessment?.category, assessmentLoading]);

  // selected framework category questions in the form of key value pairs where key is the mapping number of the question
  const questionsFlat = useMemo(() => {
    if (!assessment) {
      return {};
    }
    const flat = _.cloneDeep(getQuestionsFlat(assessment?.category, 'mappingNumber'));
    for (const question of Object.values(flat)) {
      const { datapoint, accessible, statistics, framework } = question || {};

      // database value (0-5)
      const { originalValue } = statistics || {};
      const color = getControlThreshold(thresholds, originalValue, datapoint?.notApplicable || (framework?.isCustomised && !accessible?.valid));
      question.color = color;
      question.blocked = color?.value === THREAT_MODELLING_COLORS.GREEN.value;
    }

    return flat;
  }, [assessment, thresholds]);

  // selected framework categories in the form of key value pairs where key is the mapping number of the question
  const categoriesFlat = useMemo(() => {
    if (!assessment) {
      return {};
    }

    return getCategoriesFlat(assessment?.category, 'mappingNumber');
  }, [assessment]);

  const {
    data: scenarioCountData,
    loading: scenarioCountLoading,
    error: scenarioCountError
    // refetch: refetchProjectCount
  } = useQuery(COUNT_SCENARIOS, {
    variables: { where: { org: orgId, framework: selectedFramework?.id, _or: [{ archived: false }, { archived_null: true }] } },
    skip: !orgId || !selectedFramework?.id
  });

  const scenarioCount = useMemo(() => {
    if (scenarioCountLoading || scenarioCountError) {
      return 0;
    }
    return scenarioCountData?.countScenarios || 0;
  }, [scenarioCountData?.countScenarios, scenarioCountError, scenarioCountLoading]);

  // gets all the unarchived scenarios for the selected framework in the org
  const {
    data: scenarioData,
    loading: scenarioLoading,
    error: scenarioError,
    refetch: refetchScenarios
  } = useQuery(GET_SCENARIOS, {
    variables: {
      where: {
        org: orgId,
        framework: selectedFramework?.id,
        _or: [{ archived: false }, { archived_null: true }]
      },
      start,
      limit: pageSize
    },
    skip: !orgId || !selectedFramework?.id
  });

  // saves all scenarios in a memo
  const initialScenarios = useMemo(() => {
    if (scenarioLoading || scenarioError || !selectedFramework?.id) {
      return [];
    }

    return scenarioData?.scenarios || [];
  }, [scenarioData?.scenarios, scenarioError, scenarioLoading, selectedFramework?.id]);

  // // consolidation of all the scenarios into one to show as one chart
  // const rootScenario = useMemo(() => {
  //   if (!(initialScenarios || [].length)) {
  //     return;
  //   }

  //   return {
  //     name: 'Combined',
  //     links: (initialScenarios || []).reduce((result, scenario) => {
  //       return [...(result || []), ...(scenario?.links || [])];
  //     }, [])
  //   };
  // }, [initialScenarios]);

  // update local scenarios with the change in framework category
  useEffect(() => {
    if (!initialScenarios?.length) {
      return;
    }
    setScenarios(initialScenarios);
  }, [initialScenarios]);

  const resetScenario = () => {
    setScenario({ links: [] });
  };

  const resetScenarios = () => {
    setScenarios([]);
    resetScenario();
  };

  const saveTemplate = useCallback(
    async (template, mappings) => {
      let entity;
      try {
        setLoading(true);
        const { data } = await createScenarioTemplate({
          variables: {
            data: {
              ...(template || {}),
              framework: selectedFramework?.value,
              mappings,
              org: orgId
            }
          }
        });
        entity = data?.createScenariotemplate;
        resetScenario();
        toast.success('Template successfully created');
      } catch (error) {
        console.error(error);
        toast.error('There was an error saving your template.');
      } finally {
        setLoading(false);
      }
      return entity;
    },
    [createScenarioTemplate, orgId, selectedFramework?.value]
  );

  const saveScenario = useCallback(
    async (scenario, mappings) => {
      let entity;
      try {
        setLoading(true);
        const mappingsWithTacticIds = {};
        for (const category of categories) {
          const { id, mappingNumber } = category || {};
          const tacticMappings = mappings?.[mappingNumber];
          if (tacticMappings) {
            mappingsWithTacticIds[id] = tacticMappings;
          }
        }

        const { data } = await createOrUpdateScenario({
          variables: {
            id: scenario?.id,
            org: orgId,
            data: {
              ...(scenario || {}),
              framework: selectedFramework?.id,
              mappings: mappingsWithTacticIds,
              template: selectedTemplate?.id
            }
          }
        });
        entity = data?.createOrUpdateScenario;
        resetScenario();
        toast.success(`Scenario successfully ${scenario?.id ? 'updated' : 'created'}.`);
        refetchScenarios();
      } catch (error) {
        console.error(error);
        toast.error('There was an error saving your scenario.');
      } finally {
        setLoading(false);
      }
      return entity;
    },
    [categories, createOrUpdateScenario, orgId, refetchScenarios, selectedFramework?.id, selectedTemplate?.id]
  );

  const deleteScenario = useCallback(
    async (id) => {
      try {
        if (!id) {
          return;
        }
        setLoading(true);
        await deleteScenarioMutation({
          variables: { id }
        });
        resetScenario();
        toast.success('Scenario successfully deleted.');
        refetchScenarios();
        navigate('/app/assessment/threat-modeling');
      } catch (error) {
        console.error(error);
        toast.error('There was an error deleting your scenario.');
      } finally {
        setLoading(false);
      }
    },
    [deleteScenarioMutation, navigate, refetchScenarios]
  );

  const generateScenarioFromPrompt = async (prompt) => {
    let scenario;
    try {
      setPromptLoading(true);
      toast.info('Unleashing the scenario-fetching robots... 🤖📝');
      const { data } = await getScenarioFromPrompt({ variables: { prompt, frameworkValue: selectedFramework?.value, org: orgId } });
      scenario = data?.getScenarioFromPrompt || DEFAULT_SCENARIO;

      if (!Object.keys(scenario?.mappings || {}).length) {
        toast.error('There was an error getting your mappings');
        return;
      }
      setPromptDescription(scenario?.description || '');
      toast.success('Data is here, and it brought its A-game... 🏆📄');
    } catch (error) {
      console.error(error);
      toast.error('There was an error with your request');
    } finally {
      setPromptLoading(false);
    }
    return scenario;
  };

  const value = {
    loading,
    setLoading,
    selectedFramework,
    setSelectedFramework,
    isMitreFrameworkSelected,
    thresholds,
    setThresholds,
    category: assessment?.category,
    categories,
    questionsFlat,
    categoriesFlat,
    categoryLoading: assessmentLoading,
    refetchCategory: assessmentRefetch,
    // rootScenario,
    scenario,
    setScenario,
    resetScenario,
    scenarios,
    refetchScenarios,
    resetScenarios,
    selectedQuestionId,
    setSelectedQuestionId,
    selectedCategoryId,
    setSelectedCategoryId,
    saveScenario,
    deleteScenario,
    generateScenarioFromPrompt,
    promptLoading,
    promptDescription,
    showBarrier,
    setShowBarrier,
    saveTemplate,
    selectedTemplate,
    setSelectedTemplate,
    scenarioCount,
    start,
    setStart,
    pageSize,
    setPageSize
  };

  return <ThreatModellingContext.Provider value={value}>{children}</ThreatModellingContext.Provider>;
};

ThreatModellingContextProvider.propTypes = {
  children: PropTypes.element
};

export default ThreatModellingContextProvider;
