import { getIntegrationAvatarURL } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { isDarkModeActive } from '../../../../constants/defaults';
import { TooltipEnhanced, TypographyEnhanced } from '../../../global';
import { Banner, Image } from '../../../ui';

/**
 * Info banner for integrations
 *
 * @param {String} model
 * @param {JSON} integration
 * @returns {JSX}
 */
const IntegrationBanner = ({ model = 'Issue', integration }) => {
  const { name, type } = integration || {};

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!integration) {
      return;
    }
    setShowAlert(true);
  }, [integration]);

  const src = useMemo(() => {
    return getIntegrationAvatarURL(type, isDarkModeActive);
  }, [type]);

  if (!showAlert) {
    return null;
  }

  return (
    <Banner onClose={() => setShowAlert(false)}>
      <Grid container spacing={1}>
        <Grid item>
          <TypographyEnhanced id={`This ${model} was automatically added by`} />
        </Grid>
        <Grid item>
          <TooltipEnhanced title={name || ''} sx={{ display: 'inline-block' }}>
            <Image src={src} style={{ height: 25, width: 25 }} />
          </TooltipEnhanced>
        </Grid>
      </Grid>
    </Banner>
  );
};

IntegrationBanner.propTypes = {
  model: PropTypes.string,
  integration: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string
  })
};
export default IntegrationBanner;
