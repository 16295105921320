import { IMAGES } from '@anirudhm9/base-lib/lib/constants';

export const dummyMarketplaceItems = [
  {
    id: '6285e59f117fd4bb6f65a277',
    index: 0,
    label: 'Information Security Policy',
    description: 'velit non sint magna magna elit dolor nulla adipisicing adipisicing incididunt nisi tempor sunt velit',
    imageMain: 'https://images.avertro.com/icons/document.svg',
    backgroundImage: IMAGES.REWARDS.IMAGE_PHONE_LOCK,
    images: [
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150'
    ],
    pointValue: 150,
    monetaryValue: 97,
    redeemed: true,
    canRedeem: true,
    currency: '<ReferenceError: EUR is not defined>',
    type: 'pdf',
    category: 'POLICY',
    creator: {
      name: 'Golden Gate Original',
      org: ''
    },
    createdAt: '2022-04-16T04:49:48 -10:00',
    archived: false
  },
  {
    id: '6285e59f33568b70601a3846',
    index: 1,
    label: 'Email Policy',
    description: 'occaecat officia fugiat ut consectetur fugiat esse consequat amet cupidatat laborum minim laboris sit cillum',
    imageMain: 'https://images.avertro.com/icons/document.svg',
    backgroundImage: IMAGES.REWARDS.IMAGE_LAPTOP_GMAIL,
    images: [
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150'
    ],
    pointValue: 200,
    monetaryValue: 56,
    redeemed: true,
    canRedeem: true,
    currency: '<ReferenceError: EUR is not defined>',
    type: 'doc',
    category: 'POLICY',
    creator: {
      name: 'Golden Gate Original',
      org: ''
    },
    createdAt: '2022-03-02T06:08:34 -11:00',
    archived: false
  },
  {
    id: '6285e59fdf76c6cc3cd70395',
    index: 2,
    label: 'Password Policy',
    description: 'magna consectetur veniam quis officia id velit incididunt nisi veniam elit laborum fugiat Lorem non',
    imageMain: 'https://images.avertro.com/icons/document.svg',
    backgroundImage: IMAGES.REWARDS.IMAGE_PHONE_VPN,
    images: [
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150'
    ],
    pointValue: 200,
    monetaryValue: 57,
    redeemed: false,
    canRedeem: false,
    currency: '<ReferenceError: EUR is not defined>',
    type: 'csv',
    category: 'POLICY',
    creator: {
      name: 'Golden Gate Original',
      org: ''
    },
    createdAt: '2021-02-06T10:42:20 -11:00',
    archived: false
  },
  {
    id: '6285e59f5edd861d60b31833',
    index: 3,
    label: 'Patch Management Policy',
    description: 'aute velit culpa ad ut laborum laboris duis elit in laborum consectetur non laborum minim',
    imageMain: 'https://images.avertro.com/icons/document.svg',
    backgroundImage: IMAGES.REWARDS.IMAGE_LAPTOP_CODE,
    images: [
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150'
    ],
    pointValue: 200,
    monetaryValue: 55,
    redeemed: false,
    canRedeem: true,
    currency: '<ReferenceError: EUR is not defined>',
    type: 'doc',
    category: 'POLICY',
    creator: {
      name: 'Golden Gate Original',
      org: ''
    },
    createdAt: '2021-07-06T02:44:22 -10:00',
    archived: false
  },
  {
    id: '6285e59fa10ef8c48de61e86',
    index: 4,
    label: 'veniam reprehenderit commodo',
    description: 'occaecat quis id dolor ipsum incididunt elit mollit nostrud do nisi aliquip consectetur anim sit',
    imageMain: 'https://images.avertro.com/icons/document.svg',
    backgroundImage: IMAGES.REWARDS.IMAGE_PHONE_APPS_2,
    images: [
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150'
    ],
    pointValue: 335,
    monetaryValue: 66,
    redeemed: false,
    canRedeem: true,
    currency: '<ReferenceError: EUR is not defined>',
    type: 'doc',
    category: 'PROCEDURE',
    creator: {
      name: 'Manuela Gentry',
      org: ''
    },
    createdAt: '2021-10-06T10:17:34 -11:00',
    archived: false
  },
  {
    id: '6285e59f904282e66a30f869',
    index: 5,
    label: 'consequat eiusmod est',
    description: 'incididunt nisi qui ex voluptate do cupidatat duis aliquip irure aliqua qui duis ipsum adipisicing',
    imageMain: 'https://images.avertro.com/icons/document.svg',
    backgroundImage: IMAGES.REWARDS.IMAGE_PC,
    images: [
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150'
    ],
    pointValue: 200,
    monetaryValue: 45,
    redeemed: true,
    canRedeem: true,
    currency: '<ReferenceError: EUR is not defined>',
    type: 'doc',
    category: 'PROCEDURE',
    creator: {
      name: 'Bettie Brewer',
      org: ''
    },
    createdAt: '2021-07-21T07:16:20 -10:00',
    archived: false
  },
  {
    id: '6285e59f0f8620bf484b2742',
    index: 6,
    label: 'et adipisicing esse',
    description: 'amet non fugiat labore elit magna sit ex ullamco ipsum et sint excepteur veniam deserunt',
    imageMain: 'https://images.avertro.com/icons/document.svg',
    backgroundImage: IMAGES.REWARDS.IMAGE_HDD,
    images: [
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150'
    ],
    pointValue: 350,
    monetaryValue: 16,
    redeemed: false,
    canRedeem: false,
    currency: '<ReferenceError: EUR is not defined>',
    type: 'csv',
    category: 'PLAYBOOK',
    creator: {
      name: 'Christie Blevins',
      org: ''
    },
    createdAt: '2022-03-08T09:21:17 -11:00',
    archived: true
  },
  {
    id: '6285e59fb330a24064357f31',
    index: 7,
    label: 'minim cupidatat aliqua',
    description: 'anim magna qui excepteur sit commodo officia labore duis in est irure veniam aute exercitation',
    imageMain: 'https://images.avertro.com/icons/document.svg',
    backgroundImage: IMAGES.REWARDS.IMAGE_HDD,
    images: [
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150',
      'https://via.placeholder.com/150'
    ],
    pointValue: 1000,
    monetaryValue: 86,
    redeemed: true,
    canRedeem: false,
    currency: '<ReferenceError: EUR is not defined>',
    type: 'doc',
    category: 'PLAYBOOK',
    creator: {
      name: 'Lucille Sargent',
      org: ''
    },
    createdAt: '2022-04-14T08:10:50 -10:00',
    archived: false
  }
];
