import { AttachFileRounded, CloseRounded, InsertDriveFileRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { Collapse, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ButtonEnhanced, IconButtonEnhanced } from '../../global';

const AttachedFiles = ({ defaultOpen, attachments, setAttachments, onDownloadClick }) => {
  const [open, setOpen] = useState(() => defaultOpen);

  if (!attachments || !attachments.length || !Array.isArray(attachments)) {
    return null;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <ButtonEnhanced variant='text' color='primary' onClick={() => setOpen((open) => !open)}>
          <AttachFileRounded fontSize='small' />
          <Typography>Attached Files </Typography>
          {open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
        </ButtonEnhanced>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={open} timeout='auto' unmountOnExit>
          {(attachments || []).map((attachment, index) => {
            const { name, hash } = attachment || {};
            return (
              <Grid container item xs={12} key={index} alignItems='center'>
                <InsertDriveFileRounded fontSize='small' color='secondary' />
                <ButtonEnhanced variant='text' size='small' onClick={() => onDownloadClick(attachment)} color='inherit'>
                  <Typography display='inline'>{name}</Typography>
                </ButtonEnhanced>
                {setAttachments && (
                  <IconButtonEnhanced color='warning' variant='outlined' onClick={() => setAttachments(_.reject(attachments, ['hash', hash]))}>
                    <CloseRounded />
                  </IconButtonEnhanced>
                )}
              </Grid>
            );
          })}
        </Collapse>
      </Grid>
    </Grid>
  );
};

AttachedFiles.propTypes = {
  defaultOpen: PropTypes.bool,
  attachments: PropTypes.array,
  setAttachments: PropTypes.func,
  onDownloadClick: PropTypes.func
};

export default AttachedFiles;
