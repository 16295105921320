import { useQuery } from '@apollo/client';
import { GET_FRAMEWORK_ASSESSMENT } from '../../api';
import { useOrgContext } from '../../contexts';

/**
 * usage example:
 *
 * const { assessment, loading, error, refetch } = userAssessmentData()
 *
 * @returns {{frameworkId: *, assessment: (JSON), refetch: (variables?: Partial<{assessment: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError}}
 */
const useAssessmentData = (frameworkId, skip) => {
  const { orgId, org, loading: orgLoading, error: orgError } = useOrgContext();

  const {
    data: assessmentData,
    loading,
    error,
    refetch
  } = useQuery(GET_FRAMEWORK_ASSESSMENT, {
    variables: { id: frameworkId || org?.framework?.id },
    skip: !orgId || (!org?.framework?.id && !frameworkId) || skip
  });

  if (error) {
    console.error(error);
  }

  return {
    frameworkId: org?.framework?.id,
    assessment: assessmentData?.framework,
    loading: loading || orgLoading,
    error: error || orgError,
    refetch
  };
};

export default useAssessmentData;
