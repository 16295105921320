import AMPLITUDE from 'amplitude-js';
import { orgNameVar, orgVar } from '../../api';

const { REACT_APP_AMPLITUDE_API_KEY } = process.env || {};
const HOST_NAME = window.location.hostname.replace('.avertro.com', '');

// To initialise amplitude for the instance (hostname)
const initialiseAmplitude = (user) => {
  try {
    if (!REACT_APP_AMPLITUDE_API_KEY) {
      return;
    }

    const session = AMPLITUDE.getInstance(HOST_NAME);
    const { _sessionId } = session || {};

    if (_sessionId) {
      return;
    }

    AMPLITUDE.getInstance(HOST_NAME).init(REACT_APP_AMPLITUDE_API_KEY, user, {
      //optional config options
      // 1 hour in milliseconds (same as idleTimer)
      sessionTimeout: 1000 * 60 * 60
    }); // check into adding user properties
  } catch (error) {
    console.error(error);
  }
};

const logEvent = (event = '', properties = {}) => {
  try {
    if (!REACT_APP_AMPLITUDE_API_KEY || !event) {
      return;
    }
    AMPLITUDE.getInstance(HOST_NAME).logEvent(event, { environment: HOST_NAME, org_id: orgVar(), org_name: orgNameVar(), ...properties });
  } catch (error) {
    console.error(error);
  }
};

const initialUserProperties = (userProperties) => {
  try {
    if (!REACT_APP_AMPLITUDE_API_KEY) {
      return;
    }

    AMPLITUDE.getInstance(HOST_NAME).setUserProperties(userProperties);
  } catch (error) {
    console.error(error);
  }
};

export * from './events';
export { initialiseAmplitude, logEvent, initialUserProperties };
