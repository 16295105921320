import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { ChipEnhanced, TooltipEnhanced, TypographyEnhanced } from '../../../../../global';

const HeldInfoChips = ({ options, value = [], size = 'small' }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TypographyEnhanced id={'Information held with this application'} />
      </Grid>

      {options?.map((option) => {
        const { id, name } = option || {};
        if (!value?.includes(id)) {
          return null;
        }
        return (
          <Grid item key={option.id}>
            <TooltipEnhanced title={name || ''}>
              <ChipEnhanced id={name} size={size} />
            </TooltipEnhanced>
          </Grid>
        );
      })}
    </Grid>
  );
};

HeldInfoChips.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  size: PropTypes.string
};

export default HeldInfoChips;
