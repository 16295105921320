import { getCategoriesFlat } from '@anirudhm9/base-lib/lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAssessmentData } from '../../hooks';

const AssessmentContext = createContext();
/**
 *
 * @returns {{frameworkId: *, assessment: (JSON), updateAssessment: VoidFunction, categoriesFlat: {[CategoryID]: category}, refetch: (variables?: Partial<{org: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError}}
 */
export const useAssessmentContext = () => {
  return useContext(AssessmentContext);
};

const AssessmentProvider = ({ children }) => {
  const { frameworkId, assessment: assessmentData, loading, error, refetch } = useAssessmentData();

  const [assessment, setAssessment] = useState({}); // holds assessment category/questions structure
  const [assessmentAnswers, setAssessmentAnswers] = useState({}); // hold current orgs answers.. not sure if should be in this context
  const [categoriesFlat, setCategoriesFlat] = useState();

  const updateAssessment = (data) => {
    if (!data || _.isEmpty(data)) {
      return;
    }
    setAssessment((prev) => {
      return { ...prev, ...data };
    });
  };

  const updateAnswers = (data) => {
    if (!data || _.isEmpty(data)) {
      return;
    }

    setAssessmentAnswers((prev) => {
      return { ...prev, ...data };
    });
  };

  useEffect(() => {
    if (error || loading || !assessmentData) {
      return;
    }
    const categoriesFlat = getCategoriesFlat(assessmentData?.category, 'id');
    setCategoriesFlat(categoriesFlat);
    updateAssessment(assessmentData);
  }, [assessmentData, error, loading]);

  const value = {
    frameworkId,
    assessment,
    updateAssessment,
    assessmentAnswers,
    updateAnswers,
    loading,
    refetch,
    error,
    categoriesFlat
  };

  return <AssessmentContext.Provider value={value}>{children}</AssessmentContext.Provider>;
};

AssessmentProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired
};

export default AssessmentProvider;
