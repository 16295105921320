import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useApplicationsFilterContext } from '../../../../../contexts';
import { APPLICATIONS as APPLICATIONS_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/applications';
import Filter from '../../../../ui/filter';
import FilterBody from '../../../../ui/filter/body';
import { FILTERS, SORT_OPTIONS } from './constants';
import { formatFilters } from './utils';

const ApplicationsFilter = () => {
  const {
    setWhere,
    setStart,
    sort,
    setSort,
    filterQuery,
    setFilterQuery,
    searchTerm,
    setSearchTerm,
    searchQuery,
    setSearchQuery,
    selectedFilters,
    setSelectedFilters,
    totalFilters
  } = useApplicationsFilterContext();

  const onSearch = (term = '') => {
    setSearchTerm(term);
    setSearchQuery({
      _or: [{ name_contains: term }, { customName_contains: term }, { 'thirdparty.name_contains': term }]
    });
  };

  const handleFilter = useCallback(() => {
    const query = formatFilters(selectedFilters);
    setFilterQuery(query);
  }, [selectedFilters, setFilterQuery]);

  const onFilterReset = () => {
    setSelectedFilters({});
    setFilterQuery();
  };

  useEffect(() => {
    const _and = [];
    if (searchQuery) {
      _and.push(searchQuery);
    }

    if (filterQuery) {
      _and.push(filterQuery);
    }

    setWhere({ _and });
  }, [filterQuery, searchQuery, setStart, setWhere]);

  return (
    <Filter
      onFilter={handleFilter}
      onFilterReset={onFilterReset}
      defaultSearch={searchTerm}
      onSearch={onSearch}
      onSearchReset={() => setSearchQuery()}
      totalFilters={totalFilters}
      handleSort={setSort}
      sortOptions={SORT_OPTIONS}
      sortValue={sort}
      eventPath={APPLICATIONS_EVENTS}
    >
      <Grid container item spacing={2}>
        <FilterBody filters={FILTERS} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
      </Grid>
    </Filter>
  );
};

ApplicationsFilter.propTypes = {
  searchKeys: PropTypes.arrayOf(PropTypes.string)
};

export default ApplicationsFilter;
