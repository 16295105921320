import { formatUserName } from '@anirudhm9/base-lib/lib/utils';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ChipEnhanced } from '../../../../../global';
import { LetterAvatar } from '../../../../../ui';
import { AutoCompleteEnhanced } from '../../../../../ui/form';

const EditUsersSelect = ({ options: users, onChange, label, value, hasError, errorText, loading, enable, ...props }) => {
  const options = useMemo(
    () => (users || []).map((user) => ({ id: user.id, name: formatUserName(user), disabled: !enable && !!user?.profiles?.length, ...(user || {}) })),
    [enable, users]
  );

  return (
    <AutoCompleteEnhanced
      id='userSelect'
      label={label}
      multiple={true}
      fullWidth
      options={options}
      value={value}
      onChange={onChange}
      hasError={hasError}
      errorText={errorText}
      loading={loading}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((user, index) => {
          const { id, name, disabled } = user || {};
          return <ChipEnhanced {...getTagProps({ index })} key={id || name} id={name} avatar={<LetterAvatar user={user} />} disabled={disabled} />;
        })
      }
      {...props}
    />
  );
};

EditUsersSelect.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  multiple: PropTypes.bool,
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  loading: PropTypes.bool,
  enable: PropTypes.bool
};

export default EditUsersSelect;
