import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EVENTS, logEvent } from '../../library';

const PageViewContext = createContext();
/**
 *
 * @returns {{devices: Object, loading: boolean, error: ApolloError, refetch: function}}
 */
export const usePageViewContext = () => {
  return useContext(PageViewContext);
};

const PageViewProvider = ({ children }) => {
  const { pathname = '' } = useLocation();

  const [currentPathname, setCurrentPathname] = useState();
  const [pageViewLogged, setPageViewLogged] = useState(false);

  useEffect(() => {
    if (!pathname.length) {
      return;
    }
    if (pathname === currentPathname) {
      setPageViewLogged(true);
      return;
    }
    setPageViewLogged(false);
    setCurrentPathname(pathname);
    logEvent(EVENTS.COMMON.PAGE_VIEW[pathname], { pathname: pathname });
  }, [currentPathname, pathname]);

  const value = {
    pageViewLogged: pageViewLogged,
    setPageViewLogged: setPageViewLogged
  };

  return <PageViewContext.Provider value={value}>{children}</PageViewContext.Provider>;
};

PageViewProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.node])
};

export default PageViewProvider;
