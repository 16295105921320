/* eslint-disable quotes */

// components.application.dashboard.widgets.news

export default {
  title: 'Tailored News',
  description: `Cybersecurity news you need to care about based on your organization and the things you use.`,
  buttons: {
    viewAll: 'VIEW ALL'
  },
  disconnected: {
    description:
      'Information required to provide insights here relies on one or more connections to other systems. Either you have not connected the relevant applications or there is an issue with the connections at the moment.',
    button: 'LINK COMPANY ACCOUNTS'
  }
};
