import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { useOrgData } from '../../hooks';

const OrgContext = createContext();

/**
 *
 * @returns {{org: (JSON), refetch: (variables?: Partial<{org: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError, orgId: *, updateOrg: VoidFunction}}
 */
export const useOrgContext = () => {
  return useContext(OrgContext);
};

const OrgProvider = ({ children }) => {
  const { orgId, org: orgData, loading, error, refetch } = useOrgData();

  const [org, setOrg] = useState();

  const updateOrg = (data) => {
    if (!data || _.isEmpty(data)) {
      return;
    }
    setOrg((prev) => {
      return { ...(prev || {}), ...data };
    });
  };

  useEffect(() => {
    if (error || loading) {
      return;
    }
    setOrg(orgData);
  }, [error, loading, orgData]);

  const value = {
    orgId,
    org,
    updateOrg,
    loading,
    refetch,
    error
  };

  return <OrgContext.Provider value={value}>{children}</OrgContext.Provider>;
};

OrgProvider.propTypes = {
  children: PropTypes.element
};

export default OrgProvider;
