import { INTEGRATION_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { GET_INTEGRATIONS, GET_INTEGRATION_DATA, wsContext } from '../../api';
import { toast } from '../../components/global';
import { defaultMessages } from '../../constants';
import { useOrgContext } from '../orgContext';

const IntegrationContext = createContext();

/**
 *
 * @returns {{integrations: (Array), refetch: (variables?: Partial<{org: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError, integrationsByType: JSON, updateIntegrations: VoidFunction, GSUITE: Array}}
 */
export const useIntegrationContext = () => {
  return useContext(IntegrationContext);
};

const IntegrationProvider = ({ children }) => {
  const userSocket = wsContext?.userSocket;
  const { orgId, error: orgError, loading: orgLoading } = useOrgContext();

  const {
    data: integrationData,
    loading: integrationLoading,
    error: integrationError,
    refetch
  } = useQuery(GET_INTEGRATIONS, {
    variables: { where: { org: orgId, _or: [{ archived: false }, { archived_null: true }] } },
    skip: !orgId || orgLoading || orgError
  });

  const [integrations, setIntegrations] = useState([]);
  const [integrationsByType, setIntegrationsByType] = useState({});
  const [gSuiteIntegration, setGSuiteIntegration] = useState();

  const { data: gSuiteData, loading: gSuiteLoading } = useQuery(GET_INTEGRATION_DATA, {
    variables: { id: gSuiteIntegration?.id },
    skip: !gSuiteIntegration?.id
  });

  useEffect(() => {
    if (userSocket) {
      userSocket?.on('refetchIntegrations', async (data) => {
        const { type = 'error', message = defaultMessages.defaultError } = data || {};
        toast[type](message);
        await refetch();
      });
      return () => userSocket?.off('refetchIntegrations');
    }
  }, [refetch, userSocket]);

  const updateIntegrations = (data) => {
    if (!data) {
      return;
    }
    setIntegrations(data);
  };

  useEffect(() => {
    if (integrationError || integrationLoading) {
      return;
    }
    const { integrations = [] } = integrationData || {};

    const integrationsByType = integrations.reduce((result, integration) => {
      const { type } = integration || {};
      result[type] = integration;
      return result;
    }, {});

    const gSuiteIntegration = integrationsByType[INTEGRATION_TYPES.GSUITE.value] || {};

    setGSuiteIntegration(gSuiteIntegration);
    updateIntegrations(integrations);
    setIntegrationsByType(integrationsByType);
  }, [integrationData, integrationError, integrationLoading]);

  const value = {
    integrations,
    integrationsByType,
    error: integrationError || orgError,
    loading: integrationLoading || gSuiteLoading || orgLoading,
    GSUITE: gSuiteData?.getIntegrationData,
    refetch
  };

  return <IntegrationContext.Provider value={value}>{children}</IntegrationContext.Provider>;
};

IntegrationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired
};

export default IntegrationProvider;
