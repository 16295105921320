import { ANSWER_OPTION_SUB_TYPES, ANSWER_OPTION_TYPES, IMAGES } from '@anirudhm9/base-lib/lib/constants';
import { formatErrorMessage } from '@anirudhm9/base-lib/lib/utils';
import { useMutation, useQuery } from '@apollo/client';
import { DeleteRounded, EditOffRounded, EditRounded, HelpOutlineRounded, OpenInNewRounded } from '@mui/icons-material';
import { Container, Divider, Grid, Link, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_ANSWER_OPTIONS, GET_APPLICATION, UPDATE_APPLICATION } from '../../../../../api';
import { CyberNews, HeldInfo, Kbs, ScopesChip } from '../../../../../components/application/manage';
import AppLogo from '../../../../../components/application/manage/applications/appLogo';
import GradingAvatar from '../../../../../components/application/manage/applications/gradingAvatar';
import ViewEditUsers from '../../../../../components/application/manage/applications/viewEditUsers';

import {
  ButtonEnhanced,
  CardWithLoader,
  ChipEnhanced,
  DotLoader,
  IconButtonEnhanced,
  toast,
  TooltipEnhanced,
  TypographyEnhanced
} from '../../../../../components/global';
import { Banner, Heading, Image } from '../../../../../components/ui';
import { ViewEditText } from '../../../../../components/ui/form';
import TextFieldView from '../../../../../components/ui/form/viewEditText/textFieldView';
import ModalEnhanced from '../../../../../components/ui/modal';
import { defaultMessages } from '../../../../../constants';
import { useApplicationsFilterContext, useOrgContext } from '../../../../../contexts';
import { useUsersContext } from '../../../../../contexts/usersContext';
import { logEvent } from '../../../../../library';
import { SINGLE_APPLICATION as SINGLE_APPLICATION_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/singleApplication';
import { THIRD_PARTY_TAWK_DOC, TRANSLATION_PATH } from './constants';
import { logOpenAppHomepage, logOpenAppLogin, logOpenNewsArticle, logUpdateAppDetails, logViewUser } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  bold: {
    fontWeight: 'bold'
  },
  hoverHand: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const ViewApplication = () => {
  const { orgId } = useOrgContext();
  const { users: usersOptions, loading: usersLoading } = useUsersContext();
  const { refetch: refetchApplications } = useApplicationsFilterContext();

  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  const [secScoreModalOpen, setSecScoreModalOpen] = useState(false);
  const [scopesModalOpen, setScopesModalOpen] = useState(false);

  const [updateApplication] = useMutation(UPDATE_APPLICATION);

  //APPLICATION
  const {
    data: applicationData,
    loading: applicationLoading,
    error: applicationError,
    refetch: refetchApplication
  } = useQuery(GET_APPLICATION, { variables: { id: id }, skip: !id });

  //INFORMATION HELD
  const {
    data: informationHeldData,
    loading: informationHeldLoading,
    error: informationHeldError
  } = useQuery(GET_ANSWER_OPTIONS, {
    variables: {
      where: {
        value_ne: null,
        type: ANSWER_OPTION_TYPES.THIRD_PARTY,
        subType: ANSWER_OPTION_SUB_TYPES.INFORMATION
      }
    }
  });

  const application = useMemo(() => {
    if (applicationLoading || applicationError) {
      return;
    }
    return applicationData?.application || {};
  }, [applicationData?.application, applicationError, applicationLoading]);

  const informationHeldOptions = useMemo(() => {
    if (informationHeldLoading || informationHeldError) {
      return [];
    }
    return informationHeldData?.answeroptions || [];
  }, [informationHeldData?.answeroptions, informationHeldError, informationHeldLoading]);

  const thirdparty = useMemo(() => {
    if (!application) {
      return;
    }
    return application?.thirdparty;
  }, [application]);

  // edit mode
  const [editMode, setEditMode] = useState(false);
  const [localData, setLocalData] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialiseLocalData = useCallback(() => {
    const { informationHeld = [], users = [], inactiveUsers = [], website = {}, thirdparty = {} } = application || {};

    const mapId = (array) => {
      return (array || []).map((item) => item.id);
    };

    setLocalData({
      ...application,
      informationHeld: mapId(informationHeld),
      users: mapId(users),
      inactiveUsers: mapId(inactiveUsers),
      website: website?.link || thirdparty?.website?.link || ''
    });
  }, [application]);

  useEffect(() => {
    if (applicationLoading) {
      return;
    }

    if (applicationError) {
      navigate('/app/manage/applications');
    }
    // if integration exists => application was imported
    setShowAlert(!!application?.integration?.id);
    initialiseLocalData();
  }, [navigate, initialiseLocalData, application, applicationError, applicationLoading]);

  const updateLocalData = (key, value) => {
    setLocalData((local) => {
      return { ...local, [key]: value };
    });
  };

  const handleEdit = () => {
    setEditMode((mode) => {
      // reset to default data if edit mode is cancelled
      if (mode) {
        logEvent(SINGLE_APPLICATION_EVENTS.CANCEL_EDIT, { application_id: id });
      }
      return !mode;
    });
  };

  const handleSave = async (archive) => {
    try {
      setLoading(true);
      const { customName, inactiveUsers, note, kbs, informationHeld, users, website } = localData || {};
      let data = {};

      // archive application if archive is true
      if (archive) {
        data = { archived: true };
      } else {
        data = { customName, inactiveUsers, note, kbs, informationHeld, users, website, org: orgId };
      }

      await updateApplication({
        variables: {
          id: application?.id,
          data
        }
      });
      logUpdateAppDetails(id, application, localData);
      await refetchApplication();
      await refetchApplications();
      handleEdit();
      toast.success(defaultMessages.defaultSuccess);
      // navigate back to previous page if user has archived an app
      if (archive) {
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (applicationLoading || informationHeldLoading) {
    return <DotLoader />;
  }

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={application?.name || ''} />
      {showAlert && (
        <Banner onClose={() => setShowAlert(false)}>
          This Application was automatically added by{' '}
          <TooltipEnhanced title={application?.integration?.name || ''} sx={{ display: 'inline-block' }}>
            <Image src={IMAGES.ICONS[application?.integration?.type]} style={{ height: 15, width: 15 }} />
          </TooltipEnhanced>
          {', '}
          and cannot be deleted.
        </Banner>
      )}
      <CardWithLoader
        cardHeaderProps={{
          title: (
            <Grid container spacing={2}>
              <Grid item>
                <AppLogo url={thirdparty?.logoUrl || ''} alt={thirdparty?.name || ''} style={{ height: 100, width: 100 }} />
              </Grid>
              <Grid item xs>
                <Stack>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                      <TypographyEnhanced id={thirdparty?.name || ''} variant='h6' />
                    </Grid>
                    <Grid item>
                      <ViewEditText
                        edit={editMode}
                        value={localData?.customName || ''}
                        onChange={(value) => updateLocalData('customName', value)}
                        size='small'
                        placeholder='Add a custom name'
                      />
                    </Grid>
                  </Grid>
                  <TypographyEnhanced id={thirdparty?.description || ''} variant='caption' />
                  <Grid container spacing={1}>
                    {(thirdparty?.categories || []).map((category) => {
                      return (
                        <Grid item key={category.id}>
                          <ChipEnhanced id={category.name || ''} variant='outlined' />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Stack>
              </Grid>
              <Grid item justifySelf='flex-end'>
                <Grid container spacing={1}>
                  {!application?.integration?.id && (
                    <Grid item>
                      <TooltipEnhanced title={'Delete'}>
                        <IconButtonEnhanced onClick={() => handleSave(true)} disabled={application?.integration?.id}>
                          <DeleteRounded />
                        </IconButtonEnhanced>
                      </TooltipEnhanced>
                    </Grid>
                  )}
                  <Grid item>
                    <IconButtonEnhanced onClick={handleEdit}>
                      {<TooltipEnhanced title={editMode ? 'Cancel Edit' : 'Edit'}>{editMode ? <EditOffRounded /> : <EditRounded />}</TooltipEnhanced>}
                    </IconButtonEnhanced>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        }}
        loading={applicationLoading || informationHeldLoading || !application}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TypographyEnhanced id={'Details'} variant='overline' className={classes.bold} />
          </Grid>
          <Grid container item spacing={2} alignItems='center'>
            <Grid item>
              <Kbs edit={editMode} value={localData?.kbs} onChange={(data) => updateLocalData('kbs', data)} size='large' />
            </Grid>
            {!editMode && (
              <Grid item>
                <GradingAvatar
                  grade={thirdparty?.grade}
                  score={thirdparty?.securityScore}
                  onClick={() => setSecScoreModalOpen(true)}
                  className={classes.hoverHand}
                  chip
                />
                <ModalEnhanced modalOpen={secScoreModalOpen} setModalOpen={() => setSecScoreModalOpen(!secScoreModalOpen)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TypographyEnhanced id={TRANSLATION_PATH('fields.securityScore.modal.description')} />
                      <Link href={THIRD_PARTY_TAWK_DOC} target='_blank' rel='noopener,noreferrer'>
                        <TypographyEnhanced id={TRANSLATION_PATH('fields.securityScore.modal.link.title')} />
                      </Link>
                    </Grid>
                  </Grid>
                </ModalEnhanced>
              </Grid>
            )}
          </Grid>

          {/* hide scopes if none present */}
          {!application?.scopes ? null : (
            <Grid item xs={12} container spacing={1}>
              <Grid item container alignItems='center'>
                <Grid item>
                  <TypographyEnhanced id={TRANSLATION_PATH('fields.scopes.label')} />
                </Grid>
                <Grid item>
                  <IconButtonEnhanced color='primary' size='small' onClick={() => setScopesModalOpen(true)}>
                    <HelpOutlineRounded fontSize='small' color='primary' />
                  </IconButtonEnhanced>
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={1}>
                {(application?.scopes || []).map((scope = '') => (
                  <ScopesChip scopeItem={scope} key={scope} className={classes.hoverHand} />
                ))}
              </Grid>
              <ModalEnhanced modalOpen={scopesModalOpen} setModalOpen={() => setScopesModalOpen(!scopesModalOpen)}>
                <TypographyEnhanced id={TRANSLATION_PATH('fields.scopes.modal.description')} />
              </ModalEnhanced>
            </Grid>
          )}

          {/* hide homepage if none present */}
          {!editMode && (
            <Grid item xs={12} md>
              <TextFieldView
                label='Homepage'
                value={
                  <Grid container alignItems='center'>
                    <Grid item>
                      <TypographyEnhanced id={thirdparty?.homepage || TRANSLATION_PATH('empty')} />
                    </Grid>
                    {thirdparty?.homepage && (
                      <Grid item>
                        <IconButtonEnhanced onClick={() => logOpenAppHomepage(id, thirdparty)} size='small'>
                          <OpenInNewRounded fontSize='small' />
                        </IconButtonEnhanced>
                      </Grid>
                    )}
                  </Grid>
                }
              />
            </Grid>
          )}

          {/* hide informationHeld if none present */}
          <Grid container item md alignItems='center'>
            <Grid item>
              <ViewEditText
                edit={editMode}
                label='App/Login URL'
                onChange={(value) => {
                  updateLocalData('website', value?.toLowerCase());
                }}
                value={localData?.website || ''}
              />
            </Grid>
            <Grid item>
              {(localData?.website?.link || thirdparty?.website?.link) && (
                <IconButtonEnhanced onClick={() => logOpenAppLogin(id, thirdparty, localData)} size='small'>
                  <OpenInNewRounded />
                </IconButtonEnhanced>
              )}
            </Grid>
          </Grid>

          {/* hide informationHeld if none present */}
          {!editMode && !localData?.informationHeld?.length ? null : (
            <Grid item xs={12} container>
              <HeldInfo
                edit={editMode}
                value={localData?.informationHeld || []}
                // defaultValue={application?.informationHeld || []}
                options={informationHeldOptions || []}
                multiple
                onChange={(values) => updateLocalData('informationHeld', values)}
              />
            </Grid>
          )}

          {/* hide active users if none present */}
          {!editMode && !localData?.users?.length ? null : (
            <Grid item xs={12} container>
              <ViewEditUsers
                edit={editMode}
                options={(usersOptions || []).filter((user) => !user.archived && !user.blocked)}
                label='Users using this application'
                value={localData?.users || []}
                onChange={(values) => updateLocalData('users', values)}
                loading={usersLoading}
                enable
                onClickEvent={(user) => logViewUser(user, navigate)}
              />
            </Grid>
          )}

          {/* hide inactive users if none present */}
          {!editMode && !localData?.inactiveUsers?.length ? null : (
            <Grid item xs={12} container>
              <ViewEditUsers
                edit={editMode}
                options={(usersOptions || []).filter((user) => user.archived || user.blocked)}
                label='Archived Users'
                value={localData?.inactiveUsers || []}
                onChange={(values) => updateLocalData('inactiveUsers', values)}
                loading={usersLoading}
                enable
                onClickEvent={(user) => logViewUser(user, navigate)}
              />
            </Grid>
          )}

          <Grid item xs={12} container>
            <ViewEditText
              edit={editMode}
              label='Notes'
              value={localData?.note || ''}
              onChange={(value) => updateLocalData('note', value)}
              multiline
              type='HTML'
            />
          </Grid>

          {editMode && (
            <Grid item xs={12} container justifyContent='flex-end' spacing={2}>
              <Grid item>
                <ButtonEnhanced
                  onClick={() => {
                    handleEdit();
                    initialiseLocalData();
                  }}
                  variant='outlined'
                  loading={loading || applicationLoading}
                >
                  <TypographyEnhanced id='CANCEL' />
                </ButtonEnhanced>
              </Grid>
              <Grid item>
                <ButtonEnhanced onClick={() => handleSave()} loading={loading || applicationLoading}>
                  <TypographyEnhanced id='SAVE' />
                </ButtonEnhanced>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {application?.thirdparty?.newsItems?.length ? (
            <Grid item xs={12}>
              <CyberNews application={application} onClick={logOpenNewsArticle} />
            </Grid>
          ) : null}
        </Grid>
      </CardWithLoader>
    </Container>
  );
};

ViewApplication.propTypes = {};

export default ViewApplication;
