/* eslint-disable quotes */
// views.user.register

export const REGISTER = {
  title: 'Sign Up',
  tcs: {
    1: "By clicking this checkbox, I accept Avertro's ",
    2: 'Terms & Conditions ',
    3: 'and ',
    4: 'Privacy Policy'
  },
  registerDisclaimer: {
    1: 'Avertro strongly recommends using Single Sign On to register your account.',
    2: 'Manual account creation does not allow for automatic integration with your Single Sign On security data.'
  },
  buttons: {
    google: 'Sign up with your Google Workspace admin account',
    signUp: 'Sign Up',
    login: 'Already signed up? Login'
  }
};
