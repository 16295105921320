// views.app.manage.issues.default

export default {
  title: 'Issues',
  description:
    'These are the list of issues that have been identified in your environments. You should either resolve them by fixing the problems identified, or accept the risk posed by leaving them unresolved.',
  cardTitle: 'List of all Issues',
  cardSubtitle: 'All detected and manually added issues.',
  buttons: {
    add: 'Add issue'
  }
};
