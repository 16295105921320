import { gql } from '@apollo/client';

export const COUNT_ISSUES = gql`
  query countIssues($where: JSON!) {
    countIssues(where: $where)
  }
`;

export const GET_ISSUES_BREAKDOWN = gql`
  query getIssuesBreakdown($where: JSON!) {
    getIssuesBreakdown(where: $where)
  }
`;

export const GET_ISSUES = gql`
  query issues($where: JSON!, $start: Int, $limit: Int, $sort: String) {
    issues(where: $where, start: $start, limit: $limit, sort: $sort) {
      id
      name
      description
      status {
        id
        name
        value
      }
      criticality {
        id
        name
        value
      }
      assignees {
        id
        firstName
        lastName
        email
      }
      integration {
        id
        type
      }
      type
      relation
    }
  }
`;

export const GET_ISSUE_BY_ID = gql`
  query issue($id: ID!) {
    issue(id: $id) {
      id
      name
      custom
      description
      assignees {
        id
        firstName
        lastName
        email
      }
      status {
        id
        name
        value
      }
      criticality {
        id
        name
        value
      }
      actions(sort: "created_at:ASC") {
        id
        name
        completed
      }
      createdBy {
        id
        firstName
        lastName
        imageUrl
      }
      integration {
        id
        name
        type
      }
      relation
      question {
        id
        name
      }
      device {
        id
      }
      application {
        id
        name
      }
      user {
        id
      }
      module {
        id
        name
      }
      vulnerabilities {
        id
        name
      }
    }
  }
`;

export const CREATE_OR_UPDATE_ISSUE = gql`
  mutation createOrUpdateIssue($id: ID, $org: ID!, $data: JSON!) {
    createOrUpdateIssue(id: $id, org: $org, data: $data) {
      id
    }
  }
`;
