import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../../contexts';
import ApplicationsFilterProvider from '../../../../contexts/applicationsContext';
import UsersProvider from '../../../../contexts/usersContext';
import { RoleProtectedRoute } from '../../../../helpers/routing';
import AddApplication from './addApplication';
import DefaultApplication from './default';
import ViewApplication from './viewApplication';

const Applications = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <UsersProvider>
        <ApplicationsFilterProvider>
          <Routes>
            <Route path='/' element={<RoleProtectedRoute roles={roles} path={pathname} element={DefaultApplication} />} />
            <Route path='/:id' element={<RoleProtectedRoute roles={roles} path={pathname} element={ViewApplication} />} />
            <Route path='/add' element={<RoleProtectedRoute roles={roles} path={pathname} element={AddApplication} />} />
            <Route path='*' element={<RoleProtectedRoute roles={roles} path={pathname} element={DefaultApplication} />} />
          </Routes>
        </ApplicationsFilterProvider>
      </UsersProvider>
    </div>
  );
};

Applications.propTypes = {};

export default Applications;
