import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { TypographyEnhanced } from '../../../../../global';

export const FILTERS = {
  STATUS: {
    id: 'STATUS',
    label: 'Status',
    filters: [
      {
        id: 'STATUS_CHECKBOX',
        label: '',
        type: 'checkbox',
        options: [
          { id: 'ANSWERED', name: 'Answered', value: 'ANSWERED' },
          { id: 'NOT_ANSWERED', name: 'Not Answered', value: 'NOT_ANSWERED' }
        ]
      }
    ]
  }
};

export const SORT_VALUES = {
  ALPHABETICAL_DESC: {
    id: 'ALPHABETICAL_DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Alphabetical
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'ALPHABETICAL_DESC',
    label: ''
  },
  ALPHABETICAL_ASC: {
    id: 'ALPHABETICAL_ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Alphabetical
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'ALPHABETICAL_ASC',
    label: ''
  },
  SECURITY_SCORE_DESC: {
    id: 'SECURITY_SCORE_DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Security Score
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'SECURITY_SCORE_DESC',
    label: ''
  },
  SECURITY_SCORE_ASC: {
    id: 'SECURITY_SCORE_ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Security Score
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'SECURITY_SCORE_ASC',
    label: ''
  },
  USER_COUNT_DESC: {
    id: 'USER_COUNT_DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          User count
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'USER_COUNT_DESC',
    label: ''
  },
  USER_COUNT_ASC: {
    id: 'USER_COUNT_ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          User count
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'USER_COUNT_ASC',
    label: ''
  }
};

export const SORT_OPTIONS = Object.values(SORT_VALUES);
