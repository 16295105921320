/* eslint-disable quotes */

// components.application.dashboard.summaries.posture

export const POSTURE = {
  title: 'Security Score',
  score: {
    description: 'Your security effectiveness score is {{score}}%'
  },
  benchmark: {
    description: 'Organizations similar to you have an effectiveness score of {{benchmark}}%'
  },
  na: 'N/A',
  dialog: {
    description:
      'This is based on an assessment of your current cybersecurity capability and posture. The responses provided in your Cyber Assessment (some of which is automated) determine the score. Addressing any security gaps and updating the assessment accordingly will increase your score.'
  }
};
