import { sortVersionArray } from '@anirudhm9/base-lib/lib/utils';
import { ExpandMoreRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import AccordionEnhanced from '../../../../../containers/accordion';
import { useIsDesktop } from '../../../../../hooks';
import Header from './header';

const useStyles = makeStyles((theme) => ({
  defaultBackground: {
    background: theme.palette.background.default
    // '& .Mui-expanded': {
    //   border: `1px solid ${theme.palette.primary.main}`
    // }
  },
  paperBackground: {
    background: theme.palette.background.paper
    // '& .Mui-expanded': {
    //   border: `1px solid ${theme.palette.primary.main}`,
    //   borderRadius: 25
    // }
  }
}));

const CategoryAccordion = ({ level, category, shadow, setSelectedCategory }) => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();

  const { children } = category || [];
  const sortedChildren = useMemo(() => sortVersionArray(children, 'index'), [children]);

  const { id: categoryId } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState({});

  useEffect(() => {
    if (!categoryId) {
      return;
    }

    setOpen((prev) => {
      return { [categoryId]: !prev[categoryId] };
    });
  }, [categoryId]);

  const goTo = (category) => {
    if (isDesktop) {
      setSelectedCategory(category);
      return;
    }

    const path = generatePath(`/app/assessment/category/${shadow ? 'shadow/' : ''}:id`, { id: category.id });
    navigate(path);
  };

  const toggleOpen = (id) => {
    setOpen((prev) => {
      return { [id]: !prev[id] };
    });
  };

  const backgroundClassName = useMemo(
    () => (level % 2 === 0 ? classes.paperBackground : classes.defaultBackground),
    [classes.defaultBackground, classes.paperBackground, level]
  );

  return (
    <Fragment>
      {(sortedChildren || []).map((child) => {
        const { id, questions } = child || {};
        if (questions?.length) {
          return (
            <AccordionEnhanced key={id} header={<Header item={child} />} onChange={() => goTo(child)} className={backgroundClassName}>
              <CategoryAccordion level={level + 1} category={child} shadow={shadow} setSelectedCategory={setSelectedCategory} />
            </AccordionEnhanced>
          );
        }
        return (
          <AccordionEnhanced
            key={id}
            header={<Header item={child} />}
            summaryProps={{ expandIcon: <ExpandMoreRounded /> }}
            onChange={() => toggleOpen(id)}
            expanded={open?.[id] || false}
            className={backgroundClassName}
          >
            <CategoryAccordion level={level + 1} category={child} shadow={shadow} setSelectedCategory={setSelectedCategory} />
          </AccordionEnhanced>
        );
      })}
    </Fragment>
  );
};

CategoryAccordion.propTypes = {
  level: PropTypes.number,
  category: PropTypes.object,
  shadow: PropTypes.bool,
  setSelectedCategory: PropTypes.func
};
export default CategoryAccordion;
