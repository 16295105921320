import { INTEGRATION_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { useQuery } from '@apollo/client';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import queryString from 'query-string';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GET_OAUTH_CALLBACK } from '../../../../../api';
import { DotLoader, toast } from '../../../../../components/global';
import { Heading } from '../../../../../components/ui';
import { defaultMessages } from '../../../../../constants';
import { useIntegrationContext } from '../../../../../contexts';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const GithubCallback = () => {
  // useLogEvent(LOG_EVENT_PATH('PAGE_VISITED'));
  const classes = useStyles();
  const { search } = useLocation();
  const navigate = useNavigate();

  const { integrationsByType, loading: integrationLoading } = useIntegrationContext();

  const code = useMemo(() => {
    const { code } = queryString.parse(search || '') || {};
    return code;
  }, [search]);

  const integration = useMemo(() => {
    return integrationsByType?.[INTEGRATION_TYPES.GITHUB.value] || {};
  }, [integrationsByType]);

  const { data, loading, error } = useQuery(GET_OAUTH_CALLBACK, { variables: { id: integration?.id, code }, skip: !integration?.id || !code });

  useEffect(() => {
    if (loading || integrationLoading) {
      return;
    }

    if (error) {
      navigate('/app/integrations');
    }

    const { settings } = data?.getOAuthCallback || {};
    const { ACCESS_TOKEN } = settings || {};

    if (ACCESS_TOKEN) {
      toast.success(defaultMessages.defaultSuccess);
      navigate('/app/integrations');
    }
  }, [data?.getOAuthCallback, error, integrationLoading, loading, navigate]);

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id='Github Authorization' />
      {/* <Heading id={TRANSLATION_PATH('title')} /> */}
      <Grid container>
        <Grid item xs={12}>
          <DotLoader showSentence sx={{ position: 'relative' }} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default GithubCallback;
