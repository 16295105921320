/* eslint-disable quotes */

// components.application.dashboard.widgets.mandatoryFrameworks

export const MANDATORY_FRAMEWORKS = {
  title: 'Mandatory Compliance Requirements',
  description: 'Based on your company profile, we have identified the below list of compliance requirements that could be applicable to your company:',
  disconnected: {
    description:
      'Information required to provide insights here relies on one or more connections to other systems. Either you have not connected the relevant applications or there is an issue with the connections at the moment.',
    button: 'UPDATE COMPANY INFO'
  },
  buttons: {
    linkAccount: 'UPDATE COMPANY INFO',
    viewAll: 'REVIEW'
  }
};
