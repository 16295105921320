import { FRAMEWORKS } from '@anirudhm9/base-lib/lib/constants';
import { formatDate, toTitleCase } from '@anirudhm9/base-lib/lib/utils';
import { useQuery } from '@apollo/client';
import { OpenInNewRounded } from '@mui/icons-material';
import { Avatar, Card, CardContent, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { GET_SCENARIOS } from '../../../../../../api';
import { useOrgContext } from '../../../../../../contexts';
import { openLink } from '../../../../../../helpers/routing';
import { useAssessmentData } from '../../../../../../hooks';
import ThreatModellingCategoryModal from '../../../../../../views/app/assessments/threatFx/categoryModal';
import ThreatModellingScenario from '../../../../../../views/app/assessments/threatFx/scenario';
import { ChipEnhanced, IconButtonEnhanced, TooltipEnhanced, TypographyEnhanced } from '../../../../../global';
import { ShowMoreText } from '../../../../../ui';
import { AUDIT_SUMMARY } from '../../constants';
import { getCVEURL, getCWEURL } from '../../utils';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.default
  }
}));

const VulnerabilityCard = ({ vulnerability }) => {
  const classes = useStyles();

  const { orgId, org } = useOrgContext();

  const [selectedCategoryId, setSelectedCategoryId] = useState();

  const mitreFramework = useMemo(() => {
    if (!org?.frameworks?.length) {
      return;
    }
    return org?.frameworks?.find((framework) => framework.value === FRAMEWORKS.MITRE_ATTACK.value);
  }, [org?.frameworks]);

  const { id, name, access, description, severityLevel, severity, url, vulnerableVersions, patchedVersions, recommendation, cves, cwe, created, updated } =
    vulnerability || {};

  // gets all the unarchived scenarios for the selected framework in the org
  const {
    data: scenarioData,
    loading: scenarioLoading,
    error: scenarioError
    // refetch: refetchScenarios
  } = useQuery(GET_SCENARIOS, {
    variables: {
      where: {
        org: orgId,
        framework: mitreFramework?.id,
        _or: [{ archived: false }, { archived_null: true }],
        vulnerability: id
      }
    },
    skip: !orgId || !mitreFramework?.id
  });

  // saves all scenarios in a memo
  const scenarios = useMemo(() => {
    if (scenarioLoading || scenarioError || !mitreFramework?.id) {
      return [];
    }

    return scenarioData?.scenarios || [];
  }, [scenarioData?.scenarios, scenarioError, scenarioLoading, mitreFramework?.id]);

  // fetches category data for selected framework
  const {
    assessment,
    loading: assessmentLoading
    // refetch: assessmentRefetch
  } = useAssessmentData(mitreFramework?.id, !mitreFramework?.id);

  const categories = useMemo(() => {
    if (!assessment?.category || !assessment?.category?.children?.length || assessmentLoading) {
      return [];
    }
    return _.sortBy(assessment?.category?.children || [], (child) => Number(child?.index));
  }, [assessment?.category, assessmentLoading]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item xs justifyContent='space-between' alignItems='center'>
            <Grid item>
              <TypographyEnhanced id={name || ''} display='inline' mr={1} variant='body2' />
              <ChipEnhanced id={access} variant='outlined' color='primary' size='small' />
              <IconButtonEnhanced onClick={() => openLink(url, '_blank')}>
                <OpenInNewRounded />
              </IconButtonEnhanced>
            </Grid>
            <Grid container item xs justifyContent='flex-end'>
              <TooltipEnhanced title={toTitleCase(severity || '')}>
                <ChipEnhanced
                  id={severityLevel}
                  icon={
                    <Avatar sx={{ color: 'black', bgcolor: (theme) => theme?.palette?.[AUDIT_SUMMARY?.[severity]?.color]?.main, width: 28, height: 28 }}>
                      <TypographyEnhanced id={(severity?.[0] || '')?.toUpperCase()} color='black' />
                    </Avatar>
                  }
                />
              </TooltipEnhanced>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item>
                <TypographyEnhanced id={`Reported on: ${formatDate(created)}`} variant='caption' />
              </Grid>
              <Grid item>
                <TypographyEnhanced id={`Updated on: ${formatDate(updated)}`} variant='caption' />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ShowMoreText>{description}</ShowMoreText>
          </Grid>
          <Grid item xs={12}>
            <TypographyEnhanced id={'CVES'} fontWeight={'bold'} />
            <Divider />
            <Grid container spacing={2}>
              {cves?.map((cve) => {
                return (
                  <Grid item key={cve}>
                    <TypographyEnhanced id={cve || ''} display='inline' />
                    <IconButtonEnhanced onClick={() => openLink(getCVEURL(cve), '_blank')} size='small'>
                      <OpenInNewRounded sx={{ height: 20, width: 20 }} />
                    </IconButtonEnhanced>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TypographyEnhanced id={'CWE'} fontWeight={'bold'} />
            <Divider />
            <Grid container spacing={2}>
              {cwe?.map((id) => {
                return (
                  <Grid item key={id}>
                    <TypographyEnhanced id={id || ''} display='inline' />
                    <IconButtonEnhanced onClick={() => openLink(getCWEURL(id), '_blank')} size='small'>
                      <OpenInNewRounded sx={{ height: 18, width: 18 }} />
                    </IconButtonEnhanced>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid container item>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TypographyEnhanced id={'Vulnerable Versions'} fontWeight='bold' />
                  </TableCell>
                  <TableCell>
                    <TypographyEnhanced id={'Patched Versions'} fontWeight='bold' />
                  </TableCell>
                  <TableCell>
                    <TypographyEnhanced id={'Recommendation'} fontWeight='bold' />
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell>
                    <TypographyEnhanced id={vulnerableVersions || ''} />
                  </TableCell>
                  <TableCell>
                    <TypographyEnhanced id={patchedVersions || ''} />
                  </TableCell>
                  <TableCell>
                    <TypographyEnhanced id={recommendation || ''} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid container item spacing={2}>
            {(scenarios || []).map((scenario) => {
              return (
                <Grid item xs={12} key={scenario.id}>
                  <ThreatModellingScenario
                    scenario={scenario}
                    techniques={categories}
                    cardProps={{ className: classes.card }}
                    onCategorySelect={setSelectedCategoryId}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {/* Modal for category */}
        <ThreatModellingCategoryModal id={selectedCategoryId} setId={setSelectedCategoryId} />
      </CardContent>
    </Card>
  );
};
VulnerabilityCard.propTypes = {
  vulnerability: PropTypes.object
};
export default VulnerabilityCard;
