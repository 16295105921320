import { logEvent } from '../../../../../library';
import { CLOUD_INFRASTRUCTURE as REPORTS_CLOUD_INFRASTRUCTURE_EVENTS } from '../../../../../library/amplitude/events/views/app/reports/cloudInfrastructure';

/**
 * Logs event when user clicks to hide all categories (under chart)
 *
 * @param {Boolean} open
 */
const logHideCategories = (open) => {
  if (!open) {
    logEvent(REPORTS_CLOUD_INFRASTRUCTURE_EVENTS.HIDE_CATEGORIES);
  }
};

/**
 * Logs event when user clicks on legend items to select/deselect
 *
 * @param {Object} event
 */
const logLegendChange = (event) => {
  const { selected } = event || {};
  const legendKeys = Object.keys(selected || {});

  const activeKeys = legendKeys.reduce((result, current) => {
    if (!selected?.[current]) {
      return result;
    }
    result.push(current);
    return result;
  }, []);

  logEvent(REPORTS_CLOUD_INFRASTRUCTURE_EVENTS.CLICK_LEGEND, { active_legends: activeKeys });
};

/**
 * Logs event when user changes chart type (bar/line)
 *
 * @param {Object} event
 */
const logMagicTypeChange = (event) => {
  const { currentType } = event || {};

  logEvent(REPORTS_CLOUD_INFRASTRUCTURE_EVENTS.CHANGE_VISUAL, { chart_type: currentType });
};

export { logLegendChange, logMagicTypeChange, logHideCategories };
