import { IMAGES } from '@anirudhm9/base-lib/lib/constants';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonEnhanced, TypographyEnhanced } from '../../../../global';
import { Image, WidgetContainer } from '../../../../ui';

const NoDataState = ({ path, navigateTo }) => {
  const navigate = useNavigate();

  const contructPath = (key) => {
    return `${path}.${key}`;
  };

  return (
    <WidgetContainer title={contructPath('title')}>
      <Grid item xs={12}>
        <TypographyEnhanced id={contructPath('description')} />
      </Grid>
      <Grid container justifyContent='center' item xs={12}>
        <Grid item>
          <Image src={IMAGES.EMPTY_STATE.CONNECTIVITY_CABLE} alt='broken-connection' />
        </Grid>
        <Grid container justifyContent='center' item xs={12}>
          <TypographyEnhanced id={contructPath('disconnected.description')} align='center' />
        </Grid>
      </Grid>
      {navigateTo && (
        <Grid container justifyContent='center' item>
          <ButtonEnhanced variant='contained' size='small' onClick={() => navigate(navigateTo)}>
            <TypographyEnhanced id={contructPath('disconnected.button')} />
          </ButtonEnhanced>
        </Grid>
      )}
    </WidgetContainer>
  );
};

NoDataState.propTypes = {
  path: PropTypes.string.isRequired,
  showButton: PropTypes.bool,
  navigateTo: PropTypes.string
};

export default NoDataState;
