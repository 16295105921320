import { useQuery } from '@apollo/client';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_QUESTION } from '../../../../../../api';
import SingleQuestion from '../../../../../../components/application/assessments/cyber/questionGroup/singleQuestion';
import { CardWithLoader } from '../../../../../../components/global';
import { Heading } from '../../../../../../components/ui';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const Question = ({ id }) => {
  const classes = useStyles();
  const { id: questionId } = useParams();

  const {
    data: questionData,
    loading: questionLoading,
    error: questionError,
    refetch: refetchQuestion
  } = useQuery(GET_QUESTION, { variables: { id: questionId || id }, skip: !questionId && !id });

  const question = useMemo(() => {
    if (questionLoading || questionError) {
      return {};
    }
    return questionData?.question || {};
  }, [questionData?.question, questionError, questionLoading]);

  const category = useMemo(() => {
    return question?.parent || {};
  }, [question?.parent]);

  const [customTimeout, setCustomTimeout] = useState();

  return (
    <Container component={'main'} className={classes.root} maxWidth={false}>
      <Heading id={category?.name || ''} description={category?.description || ''} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardWithLoader loading={questionLoading}>
            <SingleQuestion question={question} refetch={refetchQuestion} customTimeout={customTimeout} setCustomTimeout={setCustomTimeout} />
          </CardWithLoader>
        </Grid>
      </Grid>
    </Container>
  );
};

Question.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default Question;
