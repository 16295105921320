import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

const Title = ({ item, hideScore = false }) => {
  const { name, index } = item || {};
  return (
    <Fragment>
      <Typography variant='body1' display='inline' ml={hideScore ? 0 : 1}>
        {index || ''}
      </Typography>
      <Typography variant='body1' display='inline' ml={hideScore ? 0 : 1}>
        {name || ''}
      </Typography>
    </Fragment>
  );
};
Title.propTypes = {
  item: PropTypes.object,
  hideScore: PropTypes.bool
};

export default Title;
