import { REWARD_FAQ as faq } from './faq';
import { REWARD_LICENSE as license } from './license';
import { REWARD_OVERVIEW as overview } from './overview';
import { REWARD_SUPPORT as support } from './support';
import { REWARD_TERMS as terms } from './terms';

export const VIEW_REWARD = {
  faq,
  license,
  overview,
  support,
  terms
};
