import { Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const tabPanelIds = (tabId) => {
  return {
    id: `simple-tab-${tabId}`,
    'aria-controls': `simple-tabpanel-${tabId}`
  };
};

/**
 * @typedef {import('@mui/material').TabsProps} TabsProps Props for `Tabs` from '@mui/material` library
 *
 * @param {String} id
 * @param {[{ label:String, icon: JSX, iconPosition: String }] | [String]} options
 * @param {Function} onChange
 * @param {String | Number} value
 * @param {String} variant
 * @param {String} scrollButtons
 * @param {{ allowScrollButtonsMobile: Boolean } & TabsProps} props
 * @returns {React.FC<TabsProps>}
 */
const TabsEnhanced = ({ options, onChange, value, variant = 'scrollable', scrollButtons = 'auto', allowScrollButtonsMobile = true, ...props }) => {
  const { t } = useTranslation();

  // To get the current language for RTL languages
  // const { resolvedLanguage } = i18n || {};
  const text = (route, value) => t(route, value);

  return (
    <Tabs onChange={onChange} value={value} variant={variant} scrollButtons={scrollButtons} allowScrollButtonsMobile={allowScrollButtonsMobile} {...props}>
      {options.map((option, index) => {
        const key = index;
        if (option.constructor === ''.constructor) {
          return <Tab key={key} label={text(option)} {...tabPanelIds(index)} />;
        }
        const { label, icon, iconPosition, ...tabProps } = option || {};
        return <Tab key={key} label={text(label)} icon={icon} iconPosition={iconPosition} {...tabPanelIds(index)} {...tabProps} />;
      })}
    </Tabs>
  );
};

TabsEnhanced.propTypes = {
  id: PropTypes.string,
  tabId: PropTypes.number,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.element,
        iconPosition: PropTypes.oneOf(['start', 'top', 'end', 'bottom'])
      })
    )
  ]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  variant: PropTypes.string,
  scrollButtons: PropTypes.string,
  allowScrollButtonsMobile: PropTypes.bool,
  children: PropTypes.element
};

export default TabsEnhanced;
