import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ButtonEnhanced, TypographyEnhanced } from '../../global';

const ShowMoreText = ({ limit = 300, children, defaultShowMore, buttonProps, typographyProps }) => {
  const [open, setOpen] = useState(() => defaultShowMore);

  const toggle = () => {
    setOpen((open) => !open);
  };

  useEffect(() => {
    setOpen(defaultShowMore);
  }, [defaultShowMore]);

  const FormattedText = ({ children }) => {
    if (open) {
      return ReactHtmlParser(children);
    }
    return ReactHtmlParser(children.slice(0, limit));
  };
  FormattedText.propTypes = {
    children: PropTypes.string
  };

  if (!children || typeof children !== 'string') {
    return <Typography></Typography>;
  }

  return (
    <Fragment>
      <TypographyEnhanced id='' component='span' suppressContentEditableWarning {...typographyProps}>
        <FormattedText>{children}</FormattedText>
      </TypographyEnhanced>
      {children?.length > limit && (
        <ButtonEnhanced variant='outlined' size='small' onClick={toggle} {...buttonProps}>
          {open ? 'Show Less' : 'Show More'}
        </ButtonEnhanced>
      )}
    </Fragment>
  );
};

ShowMoreText.propTypes = {
  children: PropTypes.string,
  limit: PropTypes.number,
  defaultShowMore: PropTypes.bool,
  buttonProps: PropTypes.object,
  typographyProps: PropTypes.object
};

export default ShowMoreText;
