import { logEvent } from '../../../../library';
import { DASHBOARD as DASHBOARD_EVENTS } from '../../../../library/amplitude/events/views/app/dashboard';
import { DOTSTEPPER_BUTTONS } from '../../../application/dashboard/widgets/mandatoryFrameworks/constants';

/**
 * Logs event when user clicks Next/Back/Done
 *
 * @param {Object} event
 * @param {String | Number} activeStep
 */
const logDotStepNavigation = (event, activeStep) => {
  const buttonText = event?.target?.innerText.toUpperCase() || '';

  if (!DOTSTEPPER_BUTTONS[buttonText]) {
    return;
  }

  switch (buttonText) {
    case DOTSTEPPER_BUTTONS.NEXT:
      logEvent(DASHBOARD_EVENTS.MANDATORY_COMPLIANCE_NEXT, { step_from: activeStep, step_to: activeStep + 1 });
      break;
    case DOTSTEPPER_BUTTONS.DONE:
      logEvent(DASHBOARD_EVENTS.MANDATORY_COMPLIANCE_DONE);
      break;
    case DOTSTEPPER_BUTTONS.BACK:
      logEvent(DASHBOARD_EVENTS.MANDATORY_COMPLIANCE_BACK, { step_from: activeStep, step_to: activeStep - 1 });
      break;
  }
};

export { logDotStepNavigation };
