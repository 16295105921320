export const TRANSLATION_PATH = (route) => {
  return `components.application.dashboard.widgets.cloudSummary.${route}`;
};

export const DONUT_CONFIG = {
  type: 'pie',
  itemStyle: {
    borderRadius: 2
  },
  label: {
    show: true,
    position: 'center',
    formatter: '{a}'
  },
  labelLine: {
    show: false
  }
};

export const DEFAULT_CHART_OPTIONS = {
  legend: { show: true },
  tooltip: {
    trigger: 'item'
  },
  media: [
    {
      query: { minWidth: 700 },
      option: {
        legend: { top: '5%', right: 'center' },
        series: [
          { center: ['10%', '50%'], radius: ['30%', '40%'], labelLayout: { x: '10%', y: '75%' } },
          { center: ['30%', '50%'], radius: ['30%', '40%'], labelLayout: { x: '30%', y: '75%' } },
          { center: ['50%', '50%'], radius: ['30%', '40%'], labelLayout: { x: '50%', y: '75%' } },
          { center: ['70%', '50%'], radius: ['30%', '40%'], labelLayout: { x: '70%', y: '75%' } },
          { center: ['90%', '50%'], radius: ['30%', '40%'], labelLayout: { x: '90%', y: '75%' } }
        ]
      }
    },
    {
      // Mobile styling
      option: {
        legend: { top: 10, right: 'center' },
        series: [
          { center: ['25%', '25%'], radius: ['10%', '20%'], labelLayout: { x: '25%', y: '40%' } },
          { center: ['75%', '25%'], radius: ['10%', '20%'], labelLayout: { x: '75%', y: '40%' } },
          { center: ['25%', '60%'], radius: ['10%', '20%'], labelLayout: { x: '25%', y: '75%' } },
          { center: ['75%', '60%'], radius: ['10%', '20%'], labelLayout: { x: '75%', y: '75%' } },
          { center: ['50%', '80%'], radius: ['10%', '20%'], labelLayout: { x: '50%', y: '95%' } }
        ]
      }
    }
  ]
};
