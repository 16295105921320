import { calculatePercentageCompleted, isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';
import Xarrow, { Xwrapper } from 'react-xarrows';
import { TypographyEnhanced } from '../../../../../../components/global';
import { useThreatModellingContext } from '../../../../../../contexts';
import { getControlThreshold } from '../../../../../../contexts/threatModellingContext/utils';
import ThreatModellingLink from './link';

const ThreatModellingLinks = ({ techniques, scenario, onCategorySelect }) => {
  const { thresholds, categoriesFlat, selectedCategoryId } = useThreatModellingContext();

  const links = useMemo(() => {
    const scenarioLinks = _.cloneDeep(scenario?.links || []);

    return scenarioLinks?.map((link) => {
      const { source, target } = link || {};
      const { parent: sourceSubTechnique } = source || {};
      const { parent: targetSubTechnique } = target || {};

      const sourceCategory = categoriesFlat?.[sourceSubTechnique?.mappingNumber] || {};
      const targetCategory = categoriesFlat?.[targetSubTechnique?.mappingNumber] || {};

      const sourceCategoryTotals = calculatePercentageCompleted({ category: sourceCategory });
      const targetCategoryTotals = calculatePercentageCompleted({ category: targetCategory });

      const sourceColor = getControlThreshold(thresholds, sourceCategoryTotals?.value, sourceCategoryTotals?.total && !sourceCategoryTotals?.applicable);
      const targetColor = getControlThreshold(thresholds, targetCategoryTotals?.value, targetCategoryTotals?.total && !targetCategoryTotals?.applicable);

      link.source.color = sourceColor;
      link.target.color = targetColor;
      return link;
    });
  }, [categoriesFlat, scenario?.links, thresholds]);

  const techniqueMappingPairs = useMemo(() => {
    if (!links?.length) {
      return [];
    }
    const techniqueMappingNumbers = (links || []).reduce((result, link) => {
      const { source, target, sourceTactic, targetTactic } = link || {};
      const { parent: sourceSubTechnique } = source || {};
      const { parent: targetSubTechnique } = target || {};

      if (!result?.[sourceTactic?.id]) {
        result[sourceTactic?.id] = [];
      }

      if (!result?.[targetTactic?.id]) {
        result[targetTactic?.id] = [];
      }

      if (!result[sourceTactic?.id]?.find((question) => isIDEqual(question?.mappingNumber, sourceSubTechnique?.mappingNumber))) {
        result[sourceTactic?.id].push(sourceSubTechnique);
      }

      if (!result[targetTactic?.id]?.find((question) => isIDEqual(question?.mappingNumber, targetSubTechnique?.mappingNumber))) {
        result[targetTactic?.id].push(targetSubTechnique);
      }

      return result;
    }, {});

    return techniqueMappingNumbers;
  }, [links]);

  const Arrows = useCallback(() => {
    if (!links?.length) {
      return null;
    }

    return links.map((link, index) => {
      const { source, target } = link || {};
      const { parent: sourceSubTechnique, color: sourceColor } = source || {};
      const { parent: targetSubTechnique, color: targetColor } = target || {};

      // const sourceCategory = categoriesFlat?.[sourceSubTechnique?.mappingNumber] || {};
      // const targetCategory = categoriesFlat?.[targetSubTechnique?.mappingNumber] || {};

      // const sourceCategoryTotals = calculatePercentageCompleted({ category: sourceCategory });
      // const targetCategoryTotals = calculatePercentageCompleted({ category: targetCategory });

      // const sourceColor = getControlThreshold(thresholds, sourceCategoryTotals?.value, sourceCategoryTotals?.total && !sourceCategoryTotals?.applicable);
      // const targetColor = getControlThreshold(thresholds, targetCategoryTotals?.value, targetCategoryTotals?.total && !targetCategoryTotals?.applicable);

      const sourceId = `${scenario?.id}-${sourceSubTechnique?.mappingNumber}`;
      const targetId = `${scenario?.id}-${targetSubTechnique?.mappingNumber}`;

      if (!targetSubTechnique?.mappingNumber || !sourceSubTechnique?.mappingNumber) {
        return null;
      }

      return (
        <Xarrow
          key={`${link?.id}-${index}`}
          start={sourceId} //can be react ref
          end={targetId} //or an id
          startAnchor='bottom'
          endAnchor='top'
          lineColor={sourceColor?.name}
          headColor={targetColor?.name}
          // color={theme?.palette?.primary?.main}
        />
      );
    });
    // required for re-render of arrows
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links, scenario?.id, selectedCategoryId]);

  const Links = () => {
    if (!techniques?.length) {
      return null;
    }
    return techniques.map((technique) => {
      const subTechniques = techniqueMappingPairs?.[technique?.id] || [];
      if (!subTechniques?.length) {
        return null;
      }

      return (
        <Grid container key={technique?.id} spacing={4}>
          <Grid container item xs={12} justifyContent='center'>
            <TypographyEnhanced id={technique?.name} variant='h6' zIndex={10} />
          </Grid>
          <Grid container item spacing={2} justifyContent='space-evenly' sx={{ height: 250 }}>
            {subTechniques.map((subTechnique) => {
              const id = `${scenario?.id}-${subTechnique?.mappingNumber}`;
              const sourceLink = links?.find((link) => isIDEqual(link?.source?.parent?.id, subTechnique?.id));
              const targetLink = links?.find((link) => isIDEqual(link?.target?.parent?.id, subTechnique?.id));

              const color = sourceLink?.source?.color || targetLink?.target?.color || '';

              return (
                <Grid item xs={2} key={id}>
                  <ThreatModellingLink id={id} technique={technique} subTechnique={subTechnique} onCategorySelect={onCategorySelect} color={color} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Xwrapper>
      <Grid container>
        <Links />
        <Arrows />
      </Grid>
    </Xwrapper>
  );
};

ThreatModellingLinks.propTypes = {
  techniques: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      mappingNumber: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(PropTypes.object),
      questions: PropTypes.arrayOf(PropTypes.object)
    })
  ).isRequired,
  scenario: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string,
    framework: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string
    }),
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        source: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          mappingNumber: PropTypes.string,
          name: PropTypes.string
        }),
        target: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          mappingNumber: PropTypes.string,
          name: PropTypes.string
        }),
        sourceTactic: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          mappingNumber: PropTypes.string,
          name: PropTypes.string
        }),
        targetTactic: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          mappingNumber: PropTypes.string,
          name: PropTypes.string
        })
      })
    )
  }),
  onCategorySelect: PropTypes.func
};

// eslint-disable-next-line react/display-name
export default memo(ThreatModellingLinks, (prevProps, nextProps) => {
  const { scenario: previousScenario } = prevProps || {};
  const { scenario: nextScenario } = nextProps || {};

  // TODO: incomplete prop checking: only considers scenario
  return _.isEqual(previousScenario, nextScenario);
});
