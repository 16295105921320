import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../../contexts';
import { RoleProtectedRoute } from '../../../../helpers/routing';
import DefaultRedeem from './default';
import ViewReward from './viewReward';

const Redeem = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <Routes>
        <Route path='/' element={<RoleProtectedRoute roles={roles} path={pathname} element={DefaultRedeem} />} />
        <Route path='/:id' element={<RoleProtectedRoute roles={roles} path={pathname} element={ViewReward} />} />
        <Route path='*' element={<RoleProtectedRoute roles={roles} path={pathname} element={DefaultRedeem} />} />
      </Routes>
    </div>
  );
};

Redeem.propTypes = {};

export default Redeem;
