import { FRAMEWORKS } from '@anirudhm9/base-lib/lib/constants';
import { EVENTS } from '../../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.app.reports.cloudInfrastructure.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.APP.REPORTS.CLOUD_INFRASTRUCTURE[event];
};

export const CATEGORIES = ['Auditing', 'Encryption', 'Log Generation', 'Network Security Groups', 'Identity and Access Management'];

export const CLOUD_FRAMEWORKS = [FRAMEWORKS.AWS_SECURITY_HUB_FRAMEWORK.value];
