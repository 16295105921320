import { Close } from '@mui/icons-material';
import { Dialog, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { IconButtonEnhanced } from '../../global';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      background: theme.palette.background.paper
    }
  },
  card: {
    overflow: 'auto'
  },
  cardContent: {
    padding: theme.spacing(5),
    paddingTop: 0
  }
}));

const ModalEnhanced = ({ modalOpen, setModalOpen, children }) => {
  const classes = useStyles();

  return (
    <Dialog open={modalOpen} onClose={() => setModalOpen()} className={classes.dialog}>
      <Grid container>
        <Grid container item justifyContent='flex-end'>
          <Grid item m={1}>
            <IconButtonEnhanced size='medium' onClick={() => setModalOpen()}>
              <Close />
            </IconButtonEnhanced>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.cardContent}>
          {children}
        </Grid>
      </Grid>
    </Dialog>
  );
};

ModalEnhanced.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired
};

export default ModalEnhanced;
