import moment from 'moment';
import { ChipEnhanced, TooltipEnhanced, TypographyEnhanced } from '../../../../../../global';

export const TRANSLATION_PATH = (route) => {
  return `components.application.manage.viewUser.devicesTable.${route}`;
};

export const DEFAULT_COLUMNS = [
  'name',
  'email',
  'deviceModel',
  'type',
  'os',
  'deviceCompromisedStatus',
  'devicePasswordStatus',
  // 'deviceId',
  'encryptionStatus',
  'firstSync',
  'lastSync'
];

export const getLastSyncAge = (lastDate) => {
  return moment().diff(lastDate, 'days');
};

export const COLUMNS_CONFIG = {
  name: { flex: 1.5, renderCell: (params) => <TypographyEnhanced id={params.value || ''} /> },
  email: { flex: 1.5, renderCell: (params) => <TypographyEnhanced id={params.value || ''} /> },
  deviceModel: { flex: 1, renderCell: (params) => <TypographyEnhanced id={params.value || ''} /> },
  type: { flex: 1, renderCell: (params) => <TypographyEnhanced id={params.value || ''} /> },
  os: { flex: 1, renderCell: (params) => <TypographyEnhanced id={params.value || ''} /> },
  deviceCompromisedStatus: { flex: 1, hide: true, renderCell: (params) => <TypographyEnhanced id={params.value || ''} /> },
  devicePasswordStatus: { flex: 1, hide: true, renderCell: (params) => <TypographyEnhanced id={params.value || ''} /> },
  deviceId: { flex: 1, hide: true, renderCell: (params) => <TypographyEnhanced id={params.value || ''} /> },
  encryptionStatus: { flex: 1, hide: true, renderCell: (params) => <TypographyEnhanced id={params.value || ''} /> },
  firstSync: {
    flex: 1,
    sortComparator: (a, b) => {
      return moment(a).diff(moment(b));
    },
    renderCell: (params) => <TypographyEnhanced id={params.value || ''} />
  },
  lastSync: {
    flex: 1,
    renderCell: (params) => {
      const { id, value } = params;
      const daysSinceToday = getLastSyncAge(value);
      return (
        <TooltipEnhanced title={`Synced ${daysSinceToday} days ago`}>
          {daysSinceToday <= 90 ? <TypographyEnhanced id={value || ''} /> : <ChipEnhanced id={id} className='red' label={value} />}
        </TooltipEnhanced>
      );
    },
    sortComparator: (a, b) => {
      return moment(a).diff(moment(b));
    }
  }
};
