const STATUS_CODES = require('./statusCodes');
const ASSESSMENT_CONSTANTS = require('./assessment');
const COMPLIANCE_CONSTANTS = require('./compliance');
const MAPPING_CONSTANTS = require('./mapping');
const VERSION_CONSTANTS = require('./version');
const LOG_CONSTANTS = require('./log');

module.exports = {
  ...ASSESSMENT_CONSTANTS,
  ...COMPLIANCE_CONSTANTS,
  ...MAPPING_CONSTANTS,
  ...VERSION_CONSTANTS,
  ...LOG_CONSTANTS,
  STATUS_CODES
};
