import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatedNumber } from '../../components/animations';
import { ButtonEnhanced } from '../../components/global';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  textSize: {
    fontSize: 50
  }
}));

const Error = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Grid container direction='column' alignItems='center' justifyContent='center' style={{ minHeight: '90vh' }}>
        <Grid item>
          <AnimatedNumber from={0} to={404} config={{ duration: 1000 }} className={classes.textSize} />
        </Grid>
        <Grid item>
          <Typography variant='h5'>Page Not Found!</Typography>
        </Grid>
        <ButtonEnhanced onClick={() => navigate(-1)}>Go Back</ButtonEnhanced>
      </Grid>
    </Container>
  );
};
export default Error;
