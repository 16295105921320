import PropTypes from 'prop-types';
import { AutoCompleteEnhanced } from '../../../../../ui/form';

const HeldInfoSelect = ({ options, value, onChange, hasError, errorText, ...props }) => {
  return (
    <AutoCompleteEnhanced
      id='infoHeld'
      label='Information held within this application'
      multiple={true}
      fullWidth
      options={options}
      value={value}
      onChange={onChange}
      hasError={hasError}
      errorText={errorText}
      {...props}
    />
  );
};

HeldInfoSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  multiple: PropTypes.bool,
  hasError: PropTypes.bool,
  errorText: PropTypes.string
};

export default HeldInfoSelect;
