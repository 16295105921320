import { EVENTS, logEvent } from '../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.user.register.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.USER.REGISTER[event];
};
export const getOAuthDetails = (response) => {
  const { providerId = '', user = {}, _tokenResponse = {} } = response || {};

  const entity = {
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    confirmed: true,
    provider: '',
    imageUrl: '',
    idToken: ''
  };

  switch (providerId) {
    case 'microsoft.com':
      {
        const { email, fullName, idToken } = _tokenResponse || {};
        const { providerData = [] } = user || {};
        const [firstName = '', lastName = ''] = (fullName || '').split(' ');
        entity.email = email;
        entity.username = email;
        entity.firstName = firstName || '';
        entity.lastName = lastName || '';
        entity.provider = 'microsoft';
        entity.imageUrl = providerData?.[0]?.photoURL || '';
        entity.idToken = idToken;
      }
      break;
    case 'google.com':
      {
        const { email, firstName, lastName, photoUrl, idToken } = _tokenResponse || {};
        entity.email = email;
        entity.username = email;
        entity.firstName = firstName || '';
        entity.lastName = lastName || '';
        entity.provider = 'google';
        entity.imageUrl = photoUrl;
        entity.idToken = idToken;
      }
      break;
    default:
    // do nothing
  }
  return entity;
};

export const logEventForOAuth = (provider) => {
  let path;
  switch (provider) {
    case 'microsoft.com':
      path = 'MICROSOFT';
      break;
    default:
      path = 'GOOGLE';
  }

  logEvent(LOG_EVENT_PATH(path));
};

export const requiredFields = ['firstName', 'lastName', 'email'];

export const defaultData = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  acceptedTCs: false
};
