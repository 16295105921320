import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../../contexts';
import { RoleProtectedRoute } from '../../../../helpers/routing';

import GithubCallback from './github';
import SlackCallback from './slack';

const OAuthCallback = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <Routes>
        <Route path='/' element={<Navigate to='/app/integrations' />} />
        <Route path='github' element={<RoleProtectedRoute roles={roles} path={pathname} element={GithubCallback} />} />
        <Route path='slack' element={<RoleProtectedRoute roles={roles} path={pathname} element={SlackCallback} />} />
        <Route path='*' element={<Navigate to='/app/integrations' />} />
      </Routes>
    </div>
  );
};

OAuthCallback.propTypes = {};

export default OAuthCallback;
