import { RectangleRounded } from '@mui/icons-material';
import { Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment, useCallback } from 'react';
import { TypographyEnhanced } from '../../global';

const useStyles = makeStyles(() => ({
  pointer: {
    cursor: 'pointer'
  }
}));

/**
 * Legend element which looks similar to that of echarts
 *
 * @param {String} title heading to appear before the filters
 * @param {[{name: String, color: String, value: String | Number}]} items headings to render
 * @param {[String]} selected array of selected values
 * @param {VoidFunction} onClick function executed on click of the legend item
 * @returns {JSX}
 */
const Legend = ({ title, items, selected = [], onClick, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleClick = useCallback(
    (value) => {
      if (!onClick) {
        return;
      }

      onClick(value);
    },
    [onClick]
  );

  return (
    <Grid container spacing={1} {...props}>
      <Grid item>{title && <TypographyEnhanced id={title || ''} />}</Grid>
      {(items || []).map((item) => {
        const { name, color, value } = item || {};
        const enabled = !!(onClick && (selected || []).includes(value));

        return (
          <Fragment key={value}>
            <Grid item className={onClick && classes.pointer} onClick={() => handleClick(value)}>
              <RectangleRounded htmlColor={enabled ? theme?.palette?.[color]?.main : 'grey'} fontSize='small' />
            </Grid>
            <Grid item mr={1} className={onClick && classes.pointer} onClick={() => handleClick(value)}>
              <TypographyEnhanced id={name} display='inline' color={!enabled && 'grey'} />
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};

Legend.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      color: PropTypes.string
    })
  ),
  selected: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func
};
export default Legend;
