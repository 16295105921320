import { PriorityHighRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { ChipEnhanced } from '../../../../../global';

const HeaderChip = ({ header, onClick, ...props }) => {
  return (
    <Grid item key={header}>
      <ChipEnhanced key={header} id={header} icon={<PriorityHighRounded fontSize='small' />} onClick={onClick} {...props} />
    </Grid>
  );
};

HeaderChip.propTypes = {
  header: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func
};

export default HeaderChip;
