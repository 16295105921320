import { gql } from '@apollo/client';

export const COUNT_PROJECTS = gql`
  query countProjects($where: JSON!) {
    countProjects(where: $where)
  }
`;

export const GET_PROJECTS = gql`
  query projects($where: JSON!, $start: Int, $limit: Int, $sort: String) {
    projects(where: $where, start: $start, limit: $limit, sort: $sort) {
      id
      name
      description
      url
      avatar
      integration {
        id
        name
        type
      }
    }
  }
`;
