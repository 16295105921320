import { INTEGRATION_TYPES } from '@anirudhm9/base-lib/lib/constants';

export const TRANSLATION_PATH = (route) => {
  return `components.application.dashboard.widgets.userSummary.${route}`;
};

export const MANAGE_USERS_PATH = '/app/manage/users';

export const DUMMY_USERS = [
  { firstName: 'Eaton', lastName: 'Butler', logo: 'USER_PROFILE_A' },
  { firstName: 'Venus', lastName: 'Garrison', logo: 'USER_PROFILE_B' },
  { firstName: 'Oliver', lastName: 'Snyder', logo: 'USER_PROFILE_C' },
  { firstName: 'Randall', lastName: 'Pitts', logo: 'USER_PROFILE_D' },
  { firstName: 'Larissa', lastName: 'Bryan', logo: 'USER_PROFILE_E' }
];

export const PATH = 'components.application.dashboard.widgets.userSummary';

export const PROFILE_INTEGRATIONS = [INTEGRATION_TYPES.GSUITE.value, INTEGRATION_TYPES.SLACK.value];
