import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { CYBER_ASSESSMENT as CYBER_ASSESSMENT_EVENTS } from '../../../library/amplitude/events/views/app/assessments/cyber/index.js';
import { logEvent } from '../../../library/index.js';
import { DotLoader } from '../../global/index.js';
import PaginationEnhanced from '../pagination/index.js';
import CommentInput from './commentInput.js/index.js';
import SingleComment from './singleComment.js/index.js';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  bold: {
    fontWeight: 'bold'
  },
  background: {
    backgroundColor: theme.palette.background.default
  }
}));

const CommentActivity = ({ id, mappingNumber, model, logs, setStart, pageSize, setPageSize, total, onSave, loading, className }) => {
  const classes = useStyles();

  const handleExpanded = (_event, expanded) => {
    if (expanded) {
      logEvent(CYBER_ASSESSMENT_EVENTS.COMMENT_DROPDOWN, { question_id: id, question_mapping_numer: mappingNumber });
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Accordion className={className || classes.background} onChange={(event, expanded) => handleExpanded(event, expanded)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Grid container spacing={2} alignItems='center'>
              <Grid item>
                <Typography variant='body1'>Comments</Typography>
              </Grid>
              <Grid item>
                <Chip label={total || 0} color='secondary' />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <CommentInput id={id} model={model} onSave={onSave} />
              {loading ? (
                <DotLoader />
              ) : (
                <Fragment>
                  {!logs?.length ? null : (
                    <Grid container spacing={4} item xs={12}>
                      <Divider />
                      {(logs || []).map((log) => (
                        <SingleComment key={log?.id} log={log} onSave={onSave} />
                      ))}
                    </Grid>
                  )}
                </Fragment>
              )}

              <PaginationEnhanced total={total} onChange={setStart} pageSize={pageSize} setPageSize={setPageSize} />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

CommentActivity.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  mappingNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  model: PropTypes.string.isRequired,
  logs: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  setStart: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string
};
export default CommentActivity;
