import { formatDate } from '@anirudhm9/base-lib/lib/utils';
import { OpenInNewRounded } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsDesktop } from '../../../../../../hooks';
import { ButtonEnhanced, DataGridEnhanced, TypographyEnhanced } from '../../../../../global';
import { USER_MANAGEMENT_PATH } from '../constants';
import { COLUMNS_CONFIG, DEFAULT_COLUMNS, TRANSLATION_PATH } from './constants';

/**
 * @description Creates DataGrid table of devices
 * @param {columnKeys} array Provide array of strings to only show specific columns. Omit to show all ('name','email','deviceModel','type','os','deviceCompromisedStatus','devicePasswordStatus','deviceId','encryptionStatus','firstSync','lastSync')
 * @param {devices} array All devices data to show in table
 * @returns {Element} Data Grid of all devices
 * @link https://mui.com/x/react-data-grid/
 */

const DevicesTable = ({ columnKeys, devices }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!devices) {
      return;
    }

    const columnData = (columnKeys || DEFAULT_COLUMNS)?.map((col) => {
      const hidden = !isDesktop && !['name', 'lastSync'].includes(col);
      const defaultConfig = COLUMNS_CONFIG[col] || {};
      const hide = defaultConfig.hide || hidden;
      const config =
        col === 'name'
          ? {
              ...defaultConfig,
              renderCell: (params) => {
                const { value, row = {} } = params || {};
                const { user } = row || {};
                return (
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item>
                      <ButtonEnhanced onClick={() => navigate(`${USER_MANAGEMENT_PATH}${user.id}`)} size='small'>
                        <TypographyEnhanced id={value} />
                        <OpenInNewRounded fontSize='small' />
                      </ButtonEnhanced>
                    </Grid>
                  </Grid>
                );
              }
            }
          : defaultConfig;
      return {
        ...config,
        hide,
        field: col,
        headerName: t(TRANSLATION_PATH(`columnHeaders.${col}`)),
        renderHeader: () => <TypographyEnhanced id={TRANSLATION_PATH(`columnHeaders.${col}`)} />
      };
    });

    const rowData = (devices || [])?.map((device) => {
      return {
        user: device?.user || {},
        id: device.id,
        name: device.name,
        email: device.email,
        deviceModel: device.model,
        type: device.type,
        os: device.os,
        deviceCompromisedStatus: device.deviceCompromisedStatus,
        devicePasswordStatus: device.devicePasswordStatus,
        deviceId: device.deviceId,
        encryptionStatus: device.encryptionStatus,
        firstSync: formatDate(device?.firstSync),
        lastSync: formatDate(device?.lastSync)
      };
    });

    setColumns(columnData);
    setRows(rowData);
  }, [columnKeys, devices, isDesktop, navigate]);

  return (
    <Box sx={{ '& .red': { backgroundColor: theme.palette.red.light, color: '#000' } }}>
      <DataGridEnhanced
        columns={columns}
        rows={rows}
        disableDensitySelector
        hideFooterSelectedRowCount
        initialState={{
          sorting: {
            sortModel: [{ field: 'lastSync', sort: 'asc' }]
          }
        }}
      />
    </Box>
  );
};

DevicesTable.propTypes = {
  columnKeys: PropTypes.array,
  devices: PropTypes.array
};

export default DevicesTable;
