import { Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import CommentContent from './content';

const SingleComment = ({ log, onSave }) => {
  return (
    <Grid container spacing={2} item>
      <Grid item xs={12}>
        <CommentContent log={log} onSave={onSave} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

SingleComment.propTypes = {
  log: PropTypes.object,
  onSave: PropTypes.func.isRequired
};
export default SingleComment;
