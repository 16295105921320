import { formatDate } from '@anirudhm9/base-lib/lib/utils';
import { useQuery } from '@apollo/client';
import { Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_WEBSITES } from '../../../../../api';
import { useOrgContext } from '../../../../../contexts';
import { ButtonEnhanced, DotLoader, TypographyEnhanced } from '../../../../global';
import { WidgetContainer } from '../../../../ui';
import NoDataState from '../noDataState';
import CardPaginator from '../organisationSelector/paginator';
import GradeChart from './gradeChart';
import { BasicHeaderTable } from './table';
import { logViewAll } from './utils';

const PATH = 'components.application.dashboard.widgets.webSecurityHeaders';
const TRANSLATION_PATH = (route) => {
  return `${PATH}.${route}`;
};

const WebSecurityHeaders = ({ websites: paramsWebsites, onChange, loading, hideActions, hideTitle }) => {
  const navigate = useNavigate();
  const { org, loading: orgLoading } = useOrgContext();

  const [selectedWebsite, setSelectedWebsite] = useState('');
  const [scan, setScan] = useState();

  const orgWebsiteIds = useMemo(() => {
    if (orgLoading) {
      return;
    }
    const { websites = [] } = org || {};
    return (websites || []).map(({ id }) => id).filter((id) => id);
  }, [org, orgLoading]);

  const {
    data: websitesData,
    loading: websitesLoading,
    error: websitesError
  } = useQuery(GET_WEBSITES, { variables: { where: { id_in: orgWebsiteIds } }, skip: !orgWebsiteIds?.length || paramsWebsites?.length });

  const websites = useMemo(() => {
    if (paramsWebsites?.length) {
      return paramsWebsites || [];
    }

    if (websitesError || websitesLoading) {
      return [];
    }
    return websitesData?.websites || [];
  }, [paramsWebsites, websitesData?.websites, websitesError, websitesLoading]);

  useEffect(() => {
    if (!websites?.length) {
      return;
    }

    setSelectedWebsite(websites?.[0]);
    const { headerScans = [] } = websites?.[0] || {};
    setScan(headerScans?.[0] || {});
  }, [websites]);

  useEffect(() => {
    if (!selectedWebsite) {
      return;
    }
    onChange && onChange(selectedWebsite);
  }, [onChange, selectedWebsite]);

  if (!websites?.length && !orgLoading && !websitesLoading) {
    return <NoDataState path={PATH} />;
  }

  return (
    <WidgetContainer title={hideTitle ? '' : TRANSLATION_PATH('title')}>
      {!hideTitle && (
        <Grid item xs={12}>
          <TypographyEnhanced id={TRANSLATION_PATH('description') || ''} />
        </Grid>
      )}
      <CardPaginator items={websites || []} item={selectedWebsite || ''} onChange={setSelectedWebsite} />
      {orgLoading || websitesLoading || loading ? (
        <Grid item xs={12} m={2}>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Grid container item spacing={2} justifyContent='center'>
          <Grid item xs={12}>
            <TypographyEnhanced id={`Latest Scan Overview: ${formatDate(scan?.created_at)}`} variant='subtitle2' />
            <Divider />
          </Grid>
          <Grid item justifySelf='center'>
            <GradeChart scan={scan || {}} />
          </Grid>
          <Grid item xs={12} justifyContent='center'>
            <BasicHeaderTable scan={scan || {}} />
          </Grid>
          {hideActions ? null : (
            <Grid container item justifyContent='flex-end'>
              <ButtonEnhanced variant='outlined' size='small' onClick={() => logViewAll(navigate)}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.viewAll') || ''} />
              </ButtonEnhanced>
            </Grid>
          )}
        </Grid>
      )}
    </WidgetContainer>
  );
};

WebSecurityHeaders.propTypes = {
  websites: PropTypes.array,
  loading: PropTypes.bool,
  hideActions: PropTypes.bool,
  hideTitle: PropTypes.bool,
  onChange: PropTypes.func
};

export default WebSecurityHeaders;
