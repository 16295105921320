import { IMAGES, STATUS_CODES } from '@anirudhm9/base-lib/lib/constants';
import { CloseRounded } from '@mui/icons-material';
import { Box, Card, CardContent, Dialog, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { useLogEvent } from '../../../../../hooks';
import { logEvent } from '../../../../../library';
import { ButtonEnhanced, IconButtonEnhanced, toast, TypographyEnhanced } from '../../../../global';
import { Image } from '../../../../ui';
import { LOG_EVENT_PATH, TRANSLATION_PATH } from './constants/defaults';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(5)
  },
  buttonGroup: {
    marginTop: theme.spacing(2)
  }
}));

const RedemptionModal = ({ modalOpen = false, setModalOpen, data }) => {
  useLogEvent(LOG_EVENT_PATH('VIEWED_MODAL'));
  const classes = useStyles();

  const { label, description, pointValue } = data;

  const [isRedeemed, setIsRedeemed] = useState(false);

  // TODO get real data
  const orgDummy = {
    currentPoints: 190,
    pointsRedeemed: 10550,
    achievementsCompleted: 288,
    currentStreak: 159,
    nextBonus: 28
  };

  const closeModal = () => {
    logEvent(LOG_EVENT_PATH('CLOSED_MODAL'));
    setModalOpen();
  };

  const redeemReward = () => {
    logEvent(LOG_EVENT_PATH('REDEEMED_ITEM'));

    try {
      // update redemption status of reward
    } catch (error) {
      toast.error(STATUS_CODES.ERROR.REDEEM_FAILED.message);
    } finally {
      setIsRedeemed(true);
    }
  };

  const Success = () => {
    return (
      <Grid item xs={12} container spacing={3} alignItems='center' justifyContent='center'>
        <Grid item>
          <Box sx={{ height: '150px', width: '150px' }}>
            <Image src={IMAGES.REWARDS.REDEEM_CHECK} alt='success' />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TypographyEnhanced id={TRANSLATION_PATH('redeemed.success')} textAlign='center' variant='h6' style={{ fontWeight: 'bold' }} />
        </Grid>
        <Grid item xs={12}>
          <TypographyEnhanced id={TRANSLATION_PATH('redeemed.description')} textAlign='center' value={{ cost: pointValue }} variant='body1' />
        </Grid>
        <Grid item xs={12}>
          <TypographyEnhanced
            id={TRANSLATION_PATH('redeemed.newBalance')}
            textAlign='center'
            value={{ balance: orgDummy.currentPoints }}
            variant='body1'
            style={{ fontWeight: 'bold' }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog open={modalOpen}>
      <Card>
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <IconButtonEnhanced size='small' onClick={() => closeModal()}>
              <CloseRounded />
            </IconButtonEnhanced>
          </Grid>
        </Grid>
        <CardContent className={classes.card}>
          {isRedeemed ? (
            <Success />
          ) : (
            <Fragment>
              <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} container spacing={3} alignItems='center' justifyContent='center'>
                  <Grid item xs={4}>
                    <Image src={IMAGES.REWARDS.REDEEM_STAR} alt='star' referrerpolicy='no-referrer' />
                  </Grid>
                  <Grid item xs={2} sx={{ margin: 'auto' }}>
                    <Image src={IMAGES.REWARDS.REDEEM_ARROW_RIGHT} alt='arrow' referrerpolicy='no-referrer' />
                  </Grid>
                  <Grid item xs={4}>
                    <Image src={IMAGES.REWARDS.REDEEM_BLUE_GIFT} alt='gift' referrerpolicy='no-referrer' />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TypographyEnhanced
                    id={TRANSLATION_PATH('title')}
                    value={{ rewardName: label }}
                    variant='h6'
                    textAlign='center'
                    style={{ fontWeight: 'bold' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TypographyEnhanced id={description} variant='body1' textAlign='center' />
                </Grid>
                <Grid item xs={12}>
                  <TypographyEnhanced
                    id={TRANSLATION_PATH('balance')}
                    value={{ balance: orgDummy.currentPoints }}
                    variant='body1'
                    textAlign='center'
                    style={{ fontWeight: 'bold' }}
                  />
                  <TypographyEnhanced
                    id={TRANSLATION_PATH('cost')}
                    value={{ cost: pointValue }}
                    variant='body1'
                    textAlign='center'
                    style={{ fontWeight: 'bold' }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} justifyContent='center' className={classes.buttonGroup}>
                <Grid item>
                  <ButtonEnhanced color='secondary' onClick={() => redeemReward()}>
                    <TypographyEnhanced id={TRANSLATION_PATH('buttons.redeem')} value={{ cost: pointValue }} variant='body1' textAlign='center' />
                  </ButtonEnhanced>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </CardContent>
      </Card>
    </Dialog>
  );
};

RedemptionModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  data: PropTypes.any
};

export default RedemptionModal;
