import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultMessages } from '../../../../constants';
import { TypographyEnhanced } from '../../../global';

/**
 * @description
 * Basic text input field
 *
 *
 * @typedef {import('@mui/material').TextFieldProps & { loading: Boolean }} TextFieldProps Props for `TextField` from '@mui/material` library
 * @typedef {}
 * @type React.FC<TextFieldProps>
 *
 * @param {String | Number} id id/key of the field
 * @param {String | Number} value current text in input field
 * @param {Function} setValue onChange function
 * @param {*} label field descriptor. any element can be used for the label
 * @param {String} placeholder text to show in field when its empty. default: ''
 * @param {Boolean} required set required symbol on label if true
 * @param {Boolean} disabled disables the input field if true
 * @param {Boolean} hasError puts field into error state if true
 * @param {String} errorText show error text below field if hasError = true
 * @param {String} helperText show help text below field
 * @param {String} type change field type. default: text
 *
 * @returns React.FC<TextFieldProps>
 */

const TextFieldEnhanced = ({
  value,
  onChange,
  onBlur,
  label = '',
  placeholder = '',
  required = false,
  disabled = false,
  hasError = false,
  errorText = defaultMessages.requiredFields,
  // Requires a space to stay inline with a potential error text.
  helperText = '',
  ...props
}) => {
  const { t } = useTranslation();

  // To get the current language for RTL languages
  const placeholderText = t(placeholder, value) || placeholder;

  const handleChange = (event) => {
    try {
      if (onChange) {
        onChange(event.target.value);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBlur = (event) => {
    try {
      if (onBlur) {
        onBlur(event.target.value);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const Label = useCallback(() => {
    if (typeof label === 'string') {
      return (
        <Fragment>
          <TypographyEnhanced id={label} display='inline' />
          {required ? '*' : ''}
        </Fragment>
      );
    }

    return label;
  }, [label, required]);

  return (
    <Box>
      <Label />
      <TextField
        value={value || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholderText}
        required={required}
        disabled={disabled}
        error={hasError}
        helperText={hasError ? errorText : helperText}
        type='text'
        variant='outlined'
        fullWidth
        inputProps={{
          autoComplete: 'new-password',
          form: {
            autoComplete: 'new-password'
          }
        }}
        {...props}
      />
    </Box>
  );
};

TextFieldEnhanced.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string
};

export default TextFieldEnhanced;
