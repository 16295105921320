import { useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_FRAMEWORKS } from '../../../../../api';
import { useOrgContext } from '../../../../../contexts';
import { useAssessmentData } from '../../../../../hooks';
import { CLOUD_FRAMEWORKS } from '../../../../../views/app/reports/cloudInfrastructure/constants';
import { ButtonEnhanced, DotLoader, TypographyEnhanced } from '../../../../global';
import { WidgetContainer } from '../../../../ui';
import { SelectEnhanced } from '../../../../ui/form';
import { NoIntegrations } from '../../../integrations';
import Chart from './chart';
import { TRANSLATION_PATH } from './constants';
import { logChartClick, logLegendChange, logViewall } from './utils';

const CloudSummary = () => {
  const navigate = useNavigate();

  const { orgId } = useOrgContext();

  const {
    data: frameworksData,
    loading: frameworksLoading,
    error: frameworksError
  } = useQuery(GET_FRAMEWORKS, {
    variables: { where: { org: orgId, _or: [{ archived: false }, { archived_null: true }], integration_null: false, value_in: CLOUD_FRAMEWORKS } }
  });

  const [selectedFramework, setSelectedFramework] = useState();

  const frameworks = useMemo(() => {
    if (frameworksLoading || frameworksError) {
      return [];
    }
    setSelectedFramework(frameworksData?.frameworks?.[0]?.id);
    return frameworksData?.frameworks || [];
  }, [frameworksData?.frameworks, frameworksError, frameworksLoading]);

  const { assessment, loading: assessmentLoading, error: assessmentError } = useAssessmentData(selectedFramework, !selectedFramework);

  const categoryData = useMemo(() => {
    if (assessmentLoading || !assessment || assessmentError) {
      return {};
    }
    return assessment?.category || {};
  }, [assessment, assessmentError, assessmentLoading]);

  const title = useMemo(() => {
    return assessment?.name?.replace('Framework', '') || '';
  }, [assessment?.name]);

  return (
    <WidgetContainer title={TRANSLATION_PATH('title')} subTitle={title || ''}>
      <Grid item xs={12}>
        <TypographyEnhanced id={TRANSLATION_PATH('description')} />
      </Grid>

      {frameworksLoading || assessmentLoading ? (
        <Grid item xs={12} m={2}>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          {frameworks?.length ? (
            <Grid container item spacing={2}>
              {frameworks?.length > 1 ? (
                <Grid item xs={12}>
                  <SelectEnhanced options={frameworks} onChange={setSelectedFramework} value={selectedFramework} />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Box sx={{ height: '330px' }}>
                  <Chart
                    category={{ ...(categoryData || {}), name: title }}
                    onEvents={{
                      click: (event) => logChartClick(event, navigate),
                      legendSelectChanged: (event) => logLegendChange(event)
                    }}
                  />
                </Box>
              </Grid>
              <Grid container item justifyContent='flex-end'>
                <ButtonEnhanced variant='outlined' size='small' onClick={() => logViewall(navigate)}>
                  <TypographyEnhanced id={'VIEW ALL'} />
                </ButtonEnhanced>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <NoIntegrations link={'/app/integrations'} />
            </Grid>
          )}
        </Grid>
      )}
    </WidgetContainer>
  );
};

export default CloudSummary;
