// EVENTS.VIEWS.APP.REPORTS.CLOUD_INFRASTRUCTURE

export const CLOUD_INFRASTRUCTURE = {
  PAGE_VISITED: 'cloud_infrastructure_report-page_visited',
  CLICK_LEGEND: 'cloud_infrastructure-click_legend_visual',
  CHANGE_VISUAL: 'cloud_infrastructure-change_visual_graph',
  HIDE_CATEGORIES: 'cloud_infrastructure-hide_categories',
  TOGGLE_CONTROL_LIST: 'cloud_infrastructure-toggle_control_list',
  CHANGE_CONTROL_COLUMNS: 'cloud_infrastructure-change_control_columns',
  CHANGE_CONTROL_DENSITY: 'cloud_infrastructure-change_control_density',
  EXPORT_CONTROLS: 'cloud_infrastructure-export_controls',
  ARRANGE_TABLES: 'cloud_infrastructure-arrange_tables',
  ROWS_PER_PAGE: 'cloud_infrastructure-rows_per_page',
  NAVIGATION: 'cloud_infrastructure-navigation',
  REMEDIATION_ASSISTANCE: 'cloud_infrastructure-remediation_assistance'
};
