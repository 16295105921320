import { DEVICE_CATEGORY_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { formatUserName } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import AccordionEnhanced from '../../../../../containers/accordion';
import { useUsersContext } from '../../../../../contexts/usersContext';
import { DotLoader, TypographyEnhanced } from '../../../../global';
import DeviceIcon from '../deviceIcon';
import DevicesHeader from '../devicesHeader';
import { GROUP_BY_KEYS, GROUP_BY_VALUES } from '../filter/constants';
import SingleDeviceBar from '../singleDeviceBar';

const useStyles = makeStyles((theme) => ({
  category: {
    alignSelf: 'center !important'
  },
  categoryItem: {
    padding: '10px'
  },
  accordion: {
    background: theme.palette.background.default
  }
}));

const DeviceCategory = ({ groupedDevices, groupByValue, expanded }) => {
  const classes = useStyles();
  const { users, loading: usersLoading } = useUsersContext();

  const usersFlat = useMemo(() => {
    const usersCopy = _.cloneDeep(users || {});
    return usersCopy.reduce((flat, user) => {
      flat = { ...flat, [String(user.id)]: user };
      return flat;
    }, {});
  }, [users]);

  if (usersLoading) {
    return <DotLoader sx={{ position: 'relative' }} />;
  }

  return Object.keys(groupedDevices || []).map((groupedValue) => {
    const devices = groupedDevices[groupedValue] || [];
    const { name: categoryName, src: categorySrc = DEVICE_CATEGORY_TYPES.OTHER.src } = DEVICE_CATEGORY_TYPES[groupedValue] || {};
    const user = usersFlat[groupedValue];
    const name = groupByValue === GROUP_BY_VALUES.USER.value ? formatUserName(user || {}) : categoryName;
    const src = groupByValue === GROUP_BY_VALUES.USER.value ? '' : categorySrc;

    return (
      <Grid item xs={12} key={groupedValue} className={classes.category}>
        <AccordionEnhanced
          defaultValue=''
          header={
            <Grid item>
              <DevicesHeader name={name || ''} total={devices?.length} />
            </Grid>
          }
          icon={<DeviceIcon src={src ?? DEVICE_CATEGORY_TYPES.OTHER.src} user={user} sx={{ height: '50px', width: '50px' }} />}
          defaultExpanded={expanded}
          className={classes.accordion}
        >
          {!devices?.length ? (
            <TypographyEnhanced id={'No devices found.'} />
          ) : (
            <Fragment>
              {devices?.map((device, index) => (
                <Grid item key={index} className={classes.categoryItem}>
                  <SingleDeviceBar device={device}></SingleDeviceBar>
                </Grid>
              ))}
            </Fragment>
          )}
        </AccordionEnhanced>
      </Grid>
    );
  });
};

DeviceCategory.propTypes = {
  groupedDevices: PropTypes.object,
  groupByValue: PropTypes.oneOf(GROUP_BY_KEYS),
  expanded: PropTypes.bool
};

export default DeviceCategory;
