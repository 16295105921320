import { Avatar as MuiAvatar, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { ChipEnhanced, TooltipEnhanced, TypographyEnhanced } from '../../../../global';
import { GRADE_COLORS } from '../../../dashboard/widgets/webSecurityHeaders/constants';

/**
 * @typedef {import('@mui/material').AvatarProps} AvatarProps
 *
 * @param {AvatarProps} props
 * @param {String} props.grade letter for the score
 * @param {Number} props.score security score
 * @param {Boolean} props.tooltip to enable or disable tooltip
 * @returns {React.FC<AvatarProps>}
 */
const GradingAvatar = ({ grade, score, tooltip = true, chip = false, ...props }) => {
  const theme = useTheme();

  const value = useMemo(() => (grade === 'NOT_APPLICABLE' ? 'N/A' : grade), [grade]);

  const Avatar = useCallback(
    ({ sx, ...props }) => {
      const bgcolor = theme.palette[GRADE_COLORS[grade]]?.main;

      return (
        <MuiAvatar
          value={value}
          sx={{
            backgroundColor: bgcolor,
            ...(sx || {})
          }}
          {...props}
        >
          <TypographyEnhanced id={value || ''} color='black' />
        </MuiAvatar>
      );
    },
    [grade, theme.palette, value]
  );

  const GradeAvatar = useCallback(() => {
    if (chip) {
      return <ChipEnhanced id={`Security Score: ${score || 'N/A'}`} avatar={<Avatar />} {...props} />;
    }

    return <Avatar />;
  }, [chip, props, score]);

  if (tooltip) {
    return (
      <TooltipEnhanced title={`Security Score: ${score || 'N/A'}`}>
        <GradeAvatar />
      </TooltipEnhanced>
    );
  }

  return <GradeAvatar />;
};

GradingAvatar.propTypes = {
  grade: PropTypes.string,
  score: PropTypes.number,
  tooltip: PropTypes.bool,
  chip: PropTypes.bool
};
export default GradingAvatar;
