import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Score from './score';
import Title from './title';

const Header = ({ item }) => {
  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item>
        <Grid item>
          <Title item={item} />
        </Grid>
      </Grid>
      <Grid item>
        <Score item={item} />
      </Grid>
    </Grid>
  );
};
Header.propTypes = {
  item: PropTypes.object
};

export default Header;
