import { CorporateFareRounded, PersonRounded } from '@mui/icons-material';

const SETTINGS_SUBS = [
  {
    id: 'companySettings',
    icon: <CorporateFareRounded />,
    label: 'Company Profile',
    to: '/app/settings/company',
    viewEvent: 'company_profile-page_visited'
  },
  {
    id: 'userSettings',
    icon: <PersonRounded />,
    label: 'Account Settings',
    to: '/app/settings/user',
    viewEvent: 'account_settings-page_visited'
  }
];
export default SETTINGS_SUBS;
