import { gql } from '@apollo/client';

export const CREATE_WEBSITES = gql`
  mutation createWebsites($org: ID!, $sites: JSON!) {
    createWebsites(org: $org, sites: $sites)
  }
`;

export const UPDATE_WEBSITE = gql`
  mutation updateWebsite($id: ID!, $data: editWebsiteInput!) {
    updateWebsite(input: { where: { id: $id }, data: $data }) {
      website {
        id
      }
    }
  }
`;

export const GET_WEBSITES = gql`
  query websites($where: JSON) {
    websites(where: $where) {
      id
      link
      framework {
        id
      }
      headerScans(sort: "created_at:DESC", limit: 1) {
        id
        site
        grade
        ip
        headers
        missingHeaders
        validationErrors
        rawHeaders
        created_at
      }
    }
  }
`;

export const GET_SECURITY_HEADERS_DATA = gql`
  query getSecurityHeadersData($org: ID) {
    getSecurityHeadersData(org: $org)
  }
`;
