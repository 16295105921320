/**
 * To determine the grade of the framework score
 *
 * @param {Number} pointPct percentage of points acheived
 * @returns {'A'| 'B'| 'C'| 'D'| 'E'| 'F'}
 */
const getSecurityGrade = (pointPct) => {
  const grades = ['A', 'B', 'C', 'D', 'E', 'F'];

  const range = 100 / grades.length;
  return grades[grades.length - Math.ceil(pointPct / range)];
};

module.exports = {
  getSecurityGrade
};
