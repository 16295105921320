import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import { DRAWER_WIDTH_CLOSED } from '../../../containers/navigation/header/constants';
import { useIsDesktop } from '../../../hooks';
import { ChipEnhanced } from '../../global';

const BUTTON_WIDTH = 120;
const useStyles = makeStyles(() => ({
  button: {
    cursor: 'pointer',
    width: BUTTON_WIDTH
  }
}));

const ScrollTop = ({ threshold = 200, isDesktop, children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold
  });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role='presentation'
        sx={{
          position: 'fixed',
          zIndex: 9999,
          cursor: 'pointer',
          bottom: 16,
          right: `calc((100% - ${isDesktop ? DRAWER_WIDTH_CLOSED : 0}px - ${BUTTON_WIDTH}px)/2)`
        }}
      >
        {children}
      </Box>
    </Fade>
  );
};

ScrollTop.propTypes = {
  threshold: PropTypes.number,
  isDesktop: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node])
};

const BackToTop = (props) => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();

  return (
    <ScrollTop isDesktop={isDesktop} {...props}>
      <ChipEnhanced id='Back to top' icon={<KeyboardArrowUpIcon />} color='secondary' className={classes.button} size={isDesktop ? 'medium' : 'small'} />
    </ScrollTop>
  );
};

export default BackToTop;
