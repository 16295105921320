import { Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useState } from 'react';
import { ButtonEnhanced } from '../../../../global';
import { Watermark } from '../../../../ui';
import ModalEnhanced from '../../../../ui/modal';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  bold: {
    fontWeight: 'bold'
  }
}));

const GoPremium = () => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Fragment>
      <Watermark>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h5' className={classes.bold}>
                  Go Premium
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>
                  Have you got more sophisticated cybersecurity needs? Need executive reports, regulatory compliance assistance, and more integrations? Sign up
                  for a premium plan.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ButtonEnhanced color='secondary' onClick={() => setModalOpen(!modalOpen)}>
                  MORE INFO
                </ButtonEnhanced>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Watermark>
      <ModalEnhanced modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5' className={classes.bold}>
              Coming Soon!
            </Typography>
          </Grid>
        </Grid>
      </ModalEnhanced>
    </Fragment>
  );
};

export default GoPremium;
