import _ from 'lodash';
import PropTypes from 'prop-types';
import TextFieldEdit from './textFieldEdit';
import TextFieldView from './textFieldView';
import { TEXT_FIELD_VIEW_OMIT_PROPS } from './textFieldView/constants';

/**
 *
 * @param {Boolean} edit
 * @returns {JSX} view or edit component based on the props
 */
const ViewEditText = ({ edit, ...props }) => {
  if (edit) {
    return <TextFieldEdit {...props} />;
  } else {
    return <TextFieldView {..._.omit({ ...props }, TEXT_FIELD_VIEW_OMIT_PROPS)} />;
  }
};

ViewEditText.propTypes = {
  edit: PropTypes.bool
};

export default ViewEditText;
