import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    const root = document.getElementById('root');
    root.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);

  return children;
};

export default ScrollToTop;
