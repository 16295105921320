import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../translations';

const useStyles = makeStyles(() => ({
  nl2br: {
    whiteSpace: 'pre-wrap'
  }
}));

/**
 *
 * Customised typography component
 *
 * @typedef {import('@mui/material').TypographyProps} TypographyProps Props for `Typography` from '@mui/material` library
 * @typedef {{id: String}} TypographyEnhancedProps Props for `Typography` from '@mui/material` library
 *
 * @param {TypographyProps & TypographyEnhancedProps} props
 * @returns
 */
const TypographyEnhanced = ({ id, value, children, ...props }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  // To get the current language for RTL languages
  // const { resolvedLanguage } = i18n || {};
  const text = t(id, value);

  return (
    <Typography className={classes.nl2br} {...props}>
      {text || children}
    </Typography>
  );
};

TypographyEnhanced.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};
export default TypographyEnhanced;
