const DOMAIN = 'https://images.avertro.com';

const path = (file) => `${DOMAIN}/general/${file}`;

const GENERAL = {
  WELCOME: path('onboarding-welcome.jpg'),
  INSIGHTS: path('onboarding-insights.jpg'),
  ASSESSMENT: path('onboarding-assessment.jpg'),
  USER_PROFILE_A: path('thumb-lorem-face-1485_thumb.jpeg'),
  USER_PROFILE_B: path('thumb-lorem-face-4123_thumb.jpeg'),
  USER_PROFILE_C: path('thumb-lorem-face-3344_thumb.jpeg'),
  USER_PROFILE_D: path('thumb-lorem-face-3447_thumb.jpeg'),
  USER_PROFILE_E: path('thumb-lorem-face-6107_thumb.jpeg'),
  DEVICES: path('devices.svg')
};

module.exports = {
  GENERAL
};
