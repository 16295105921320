// components.application.manage.devices.manualAddDevice

export const MANUAL_ADD_DEVICE = {
  divider: 'Manually Add Device',
  fields: {
    model: 'Device Model',
    type: 'Device Type',
    os: 'Operating System',
    user: {
      id: '',
      firstName: '',
      lastName: '',
      email: ''
    }
  },
  buttons: {
    save: 'Save'
  }
};
