// components.application.integrations.deleteIntegrationModal

export const DELETE_INTEGRATION_MODAL = {
  title: 'Are you sure you want to delete {{name}}?',
  description: 'Once deleted, you will lose any information that originated from this integration.',
  buttons: {
    delete: 'I UNDERSTAND. DELETE THIS INTEGRATION',
    cancel: 'CANCEL'
  }
};
