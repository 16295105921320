import { IMAGES } from '@anirudhm9/base-lib/lib/constants';
/* eslint-disable quotes */
export const dummyAcheivements = [
  {
    id: '628475faff50f9b09dde7e1c',
    index: 0,
    label: 'Complete Self Assessment Domain 1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: 'Access the self-assessment and complete all the questions under the ‘Domain 1’ section.',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_LIGHTBULB,
    pointValue: 150,
    percentComplete: 100,
    type: 'other',
    category: 'REPORT',
    startedDate: '2022-03-08T05:32:24',
    completedDate: null,
    archived: false,
    claimed: false,
    locked: false,
    route: '/app/assessment/?category=1'
  },
  {
    id: '628475faff50f9b09dde7e2c',
    index: 1,
    label: 'Complete Self Assessment Domain 2',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: 'Access the self-assessment and complete all the questions under the ‘Domain 2’ section.',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_LIGHTBULB,
    pointValue: 150,
    percentComplete: 50,
    type: 'other',
    category: 'REPORT',
    startedDate: '2022-03-08T05:32:24',
    completedDate: null,
    archived: false,
    claimed: false,
    locked: false,
    route: '/app/assessment/?category=19'
  },
  {
    id: '628475faff50f9b09dde7e3c',
    index: 2,
    label: 'Complete Self Assessment Domain 3',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: 'Access the self-assessment and complete all the questions under the ‘Domain 3’ section.',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_LIGHTBULB,
    pointValue: 150,
    percentComplete: 25,
    type: 'other',
    category: 'REPORT',
    startedDate: '2022-03-08T05:32:24',
    completedDate: null,
    archived: false,
    claimed: false,
    locked: false,
    route: '/app/assessment/?category=38'
  },
  {
    id: '628475faff50f9b09dde7e4c',
    index: 3,
    label: 'Complete Self Assessment Domain 4',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: 'Access the self-assessment and complete all the questions under the ‘Domain 4’ section.',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_LIGHTBULB,
    pointValue: 150,
    percentComplete: 10,
    type: 'other',
    category: 'REPORT',
    startedDate: '2022-03-08T05:32:24',
    completedDate: null,
    archived: false,
    claimed: false,
    locked: false,
    route: '/app/assessment/?category=47'
  },
  {
    id: '628475faff50f9b09dde7e5c',
    index: 4,
    label: 'Complete Self Assessment Domain 5',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: 'Access the self-assessment and complete all the questions under the ‘Domain 5’ section.',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_LIGHTBULB,
    pointValue: 150,
    percentComplete: 0,
    type: 'other',
    category: 'REPORT',
    startedDate: '2022-03-08T05:32:24',
    completedDate: null,
    archived: false,
    claimed: false,
    locked: false,
    route: '/app/assessment/?category=62'
  },
  {
    id: '628475fa15d4b6ef7bd89526',
    index: 5,
    label: 'Connect to Google API',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: 'Connect your Googe Workspace Admin acoount.',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_LAPTOP_GMAIL,
    pointValue: 50,
    percentComplete: 100,
    type: 'other',
    category: 'REPORT',
    startedDate: '2021-03-28T07:48:49',
    completedDate: '2021-11-19T01:54:54',
    archived: false,
    claimed: true,
    locked: false,
    route: '/app/integrations'
  },
  {
    id: '628475fa31b325d2a69e4c1b',
    index: 6,
    label: 'Complete Company Profile',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: 'Share your success, by sharing your  Security Badge to Llinkedin.',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_BRAINSTORM,
    pointValue: 150,
    percentComplete: 100,
    type: 'selfAssessment',
    category: 'REPORT',
    startedDate: '2021-03-28T07:48:49',
    completedDate: null,
    archived: false,
    claimed: false,
    locked: true,
    route: '/app/'
  },
  {
    id: '628475fa6b09bf3d468a5167',
    index: 4,
    label: 'Complete User Profile',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: '',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_LAPTOP_TRACKPAD,
    pointValue: 1029,
    percentComplete: 0,
    type: 'other',
    category: 'DOCUMENTATION',
    startedDate: null,
    completedDate: null,
    archived: false,
    claimed: false,
    locked: false,
    route: '/app/'
  },
  {
    id: '628475fa1b13b2f646f47e13',
    index: 5,
    label: 'Email Security - Enable MFA',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: '',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_LAPTOP_TYPING,
    pointValue: 2274,
    percentComplete: 0,
    type: 'other',
    category: 'SCORE',
    startedDate: null,
    completedDate: null,
    archived: false,
    claimed: false,
    locked: true,
    route: '/app/'
  },
  {
    id: '628475faffdfe557628ffbe8',
    index: 6,
    label: 'Email Security - Enable Password Complexity',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: '',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_PADLOCK_COMBINATION,
    pointValue: 1780,
    percentComplete: 44,
    type: 'selfAssessment',
    category: 'DOCUMENTATION',
    startedDate: '2021-11-19T01:54:54',
    completedDate: null,
    archived: false,
    claimed: false,
    locked: false,
    route: '/app/'
  },
  {
    id: '628475fa4e3e135ab75c46db',
    index: 7,
    label: 'Email Security - Enable SSO',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: '',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_PADLOCK_KEYBOARD,
    pointValue: 4201,
    percentComplete: 50,
    type: 'other',
    category: 'SCORE',
    startedDate: '2021-09-02T03:48:14',
    completedDate: null,
    archived: false,
    claimed: false,
    locked: true,
    route: '/app/'
  },
  {
    id: '628475fa936935ab77150d6d',
    index: 8,
    label: 'Archived test',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    objective: '',
    benchmark: '20% of companies similar to you have completed this task.',
    image: IMAGES.REWARDS.IMAGE_HDD,
    pointValue: 3036,
    percentComplete: 0,
    type: 'selfAssessment',
    category: 'REPORT',
    startedDate: null,
    completedDate: null,
    archived: true,
    claimed: false,
    locked: false,
    route: '/app/'
  }
];
