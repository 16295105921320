import { Box } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * @typedef {import('@mui/material').BoxProps} BoxProps Props for `Box` from '@mui/material` library
 *
 * @param {String | Number} tabId
 * @param {String | Number} value
 * @param {JSX} children
 * @param {BoxProps} props
 * @returns {React.FC<TabsProps>}
 */
const TabPanelEnhanced = ({ tabId, value, children, ...props }) => {
  if (value !== tabId) {
    return null;
  }
  return (
    <Box mt={2} {...props}>
      {children}
    </Box>
  );
};

TabPanelEnhanced.propTypes = {
  tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.element
};

export default TabPanelEnhanced;
