import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CategoryBreadcrumbs, QuestionGroup, QuestionNavigator, SingleCategoryTitle } from '../../../../../components/application/assessments/cyber';
import { CardWithLoader } from '../../../../../components/global';
import { useInitialLoading, useSingleAssessmentCategory } from '../../../../../hooks';
import { logEvent } from '../../../../../library';
import { CYBER_ASSESSMENT as CYBER_ASSESSMENT_EVENTS } from '../../../../../library/amplitude/events/views/app/assessments/cyber';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  bold: {
    fontWeight: 'bold'
  },
  spacing: {
    marginTop: theme.spacing(2)
  }
}));

const SingleCategory = ({ id, fullscreen = true, refetch }) => {
  const initialLoading = useInitialLoading();
  const classes = useStyles();
  const navigate = useNavigate();
  const { id: paramsId } = useParams();
  const { pathname = '' } = useLocation();

  const shadow = useMemo(() => pathname?.includes('shadow'), [pathname]);

  const [categoryId, setCategoryId] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [customTimeout, setCustomTimeout] = useState();
  //prevent refetch logging page visit event when question is answered
  const [pageVisitLogged, setPageVisitLogged] = useState(false);

  useEffect(() => {
    if (!id && !paramsId) {
      return;
    }

    setCategoryId(id || paramsId);

    // clean up
    return () => {
      setCategoryId();
      setSelectedCategory();
    };
  }, [id, paramsId]);

  const { category, loading: categoryLoading, error: categoryError, refetch: refetchCategory } = useSingleAssessmentCategory(categoryId || id);

  useEffect(() => {
    if (categoryLoading) {
      return;
    }

    if (categoryError || ((categoryId || id) && !category)) {
      navigate('/app/assessment/cyber');
      return;
    }

    setSelectedCategory(category);

    if (pageVisitLogged) {
      return;
    }

    logEvent(CYBER_ASSESSMENT_EVENTS.VIEW_ASSESSMENT_CATEGORY, {
      category: `${category?.index} ${category?.name}`,
      category_id: category?.id
    });
    setPageVisitLogged(true);
  }, [categoryId, categoryError, categoryLoading, pageVisitLogged, navigate, category, id]);

  const executeTimeoutCallback = () => {
    if (!customTimeout) {
      return;
    }
    const { id, callback } = customTimeout || {};
    try {
      // clears the 3 second autosave timeout
      clearTimeout(id);
      // executes the function in timeout instantly
      callback();
      if (refetch) {
        refetch();
      }
      // clears the timeout so that it executes only once
      setCustomTimeout();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container component='main' className={fullscreen ? classes.root : ''} maxWidth={false}>
      {fullscreen && (
        <Grid container component='main' spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <CategoryBreadcrumbs current={selectedCategory} shadow={shadow} />
          </Grid>
          <Grid item xs={12}>
            <SingleCategoryTitle category={selectedCategory} />
          </Grid>
        </Grid>
      )}
      <Grid item>
        <CardWithLoader loading={initialLoading || categoryLoading}>
          <Grid container>
            <Grid item>
              <Typography variant='h6' className={classes.bold}>
                {selectedCategory?.name || ''}
              </Typography>
              <Typography variant='body1'>{selectedCategory?.description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <QuestionGroup
                questions={selectedCategory?.questions || []}
                refetch={() => {
                  refetchCategory();
                  refetch && refetch();
                }}
                customTimeout={customTimeout}
                setCustomTimeout={setCustomTimeout}
                shadow={shadow}
              />
            </Grid>
          </Grid>
        </CardWithLoader>
      </Grid>
      {fullscreen && (
        <QuestionNavigator
          current={selectedCategory}
          onBack={executeTimeoutCallback}
          onNext={executeTimeoutCallback}
          shadow={shadow}
          setPageVisitLogged={setPageVisitLogged}
        />
      )}
    </Container>
  );
};

SingleCategory.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullscreen: PropTypes.bool,
  refetch: PropTypes.func
};
export default SingleCategory;
