import { gql } from '@apollo/client';

export const GET_THIRDPARTIES = gql`
  query thirdparties($where: JSON) {
    thirdparties(where: $where, sort: "name:ASC") {
      id
      name
      description
      logoUrl
      securityScore
      kbs
      draft
      aliases
      homepage
      informationHeld {
        id
        name
        value
      }
      categories {
        id
        name
        value
      }
      website {
        id
        link
      }
    }
  }
`;

export const GET_THIRDPARTY = gql`
  query thirdparty($id: ID!) {
    thirdparty(id: $id) {
      id
      name
      description
      logoUrl
      securityScore
      kbs
      draft
      aliases
      homepage
      informationHeld {
        id
        name
        value
      }
      categories {
        id
        name
        value
      }
      website {
        id
        link
      }
    }
  }
`;
