import { Container, Grid, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DeviceCategory from '../../../../../components/application/manage/devices/deviceCategory';
import DeviceFilter from '../../../../../components/application/manage/devices/filter';
import { GROUP_BY_VALUES, SORT_VALUES } from '../../../../../components/application/manage/devices/filter/constants';
import { groupDevices, sortDevices } from '../../../../../components/application/manage/devices/filter/utils';
import { ButtonEnhanced, CardWithLoader, DotLoader, TypographyEnhanced } from '../../../../../components/global';
import { Heading } from '../../../../../components/ui';
import { useDevicesContext } from '../../../../../contexts';
import { TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  categoryItem: {
    padding: '10px'
  },
  noDevices: {
    textAlign: 'center'
  }
}));

const DefaultDevices = () => {
  const { devices, loading: devicesLoading } = useDevicesContext();

  const location = useLocation();

  const [filteredDevices, setFilteredDevices] = useState([]);
  const [groupByValue, setGroupByValue] = useState(GROUP_BY_VALUES.VIEW_ALL.value);

  //Used for 'group by' select menu
  const [groupedDevices, setGroupedDevices] = useState({});
  //Is category expanded by default or not
  const [defaultExpanded, setDefaultExpanded] = useState(false);
  const [defaultSearch, setDefaultSearch] = useState('');

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    const { state } = location || {};
    const { search: defaultSearch } = state || {};
    if (!defaultSearch) {
      return;
    }
    setDefaultSearch(defaultSearch || '');
  }, [location]);

  useEffect(() => {
    if (!(devices || []).length) {
      return;
    }
    const devicesCopy = _.cloneDeep(devices || []);

    const defaultDevices = sortDevices(devicesCopy, SORT_VALUES.USERNAME_ASC.value);
    setFilteredDevices(defaultDevices);
  }, [devices]);

  useEffect(() => {
    const grouped = groupDevices(filteredDevices, groupByValue);
    setGroupedDevices(grouped);
  }, [filteredDevices, groupByValue]);

  useEffect(() => {
    if (groupByValue === GROUP_BY_VALUES.VIEW_ALL.value) {
      setDefaultExpanded(true);
    } else {
      setDefaultExpanded(false);
    }
  }, [groupByValue]);

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title')} description={TRANSLATION_PATH('description')} />
      <DeviceFilter
        devices={devices || []}
        setDevices={setFilteredDevices}
        groupedDevices={groupedDevices}
        groupByValue={groupByValue}
        setGroupByValue={setGroupByValue}
        defaultSearch={defaultSearch}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardWithLoader
            cardHeaderProps={{
              title: (
                <Grid container spacing={2}>
                  <Grid item xs>
                    <Stack>
                      <TypographyEnhanced id={TRANSLATION_PATH('cardTitle')} variant='h6' />
                      <TypographyEnhanced id={TRANSLATION_PATH('cardSubtitle')} variant='caption' />
                    </Stack>
                  </Grid>
                  <Grid item justifySelf='flex-end'>
                    <ButtonEnhanced size='medium' onClick={() => navigate('add')}>
                      <TypographyEnhanced id={TRANSLATION_PATH('buttons.add')} />
                    </ButtonEnhanced>
                  </Grid>
                </Grid>
              )
            }}
            loading={devicesLoading}
          >
            {devicesLoading ? (
              <Grid container item justifyContent='center'>
                <DotLoader sx={{ position: 'relative' }} />
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {!devices.length ? (
                  <Grid item xs={12}>
                    <TypographyEnhanced id='No devices found.' variant='h6' className={classes.noDevices} />
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <DeviceCategory groupedDevices={groupedDevices} groupByValue={groupByValue} expanded={defaultExpanded} />
                  </Grid>
                )}
              </Grid>
            )}
          </CardWithLoader>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DefaultDevices;
