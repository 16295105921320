import { VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material';
import { Box, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { defaultMessages } from '../../../../constants';
import { IconButtonEnhanced } from '../../../global';
import TextFieldEnhanced from '../textField';

/**
 * @description
 * Basic password input field
 *
 * @param {String | Number} id id/key of the field
 * @param {*} label field descriptor. any element can be used for the label
 * @param {String | Number} value current text in input field
 * @param {String} placeholder text to show in field when its empty. default: ''
 * @param {Function} onChange onChange function
 * @param {Boolean} required set required symbol on label if true
 * @param {Boolean} disabled disables the input field if true
 * @param {Boolean} hasError puts field into error state if true
 * @param {String} errorText show error text below field if hasError = true
 * @param {String} helperText show help text below field. provide as ' ' to other fields if there are alignment issues
 */

const PasswordFieldEnhanced = ({
  id,
  label = '',
  value,
  placeholder = '',
  onChange,
  required = false,
  disabled = false,
  hasError = false,
  errorText = defaultMessages.requiredFields,
  // Requires a space to stay inline with a potential error text.
  helperText = '',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const StartAdornment = () => {
    return (
      <InputAdornment position='start'>
        <IconButtonEnhanced aria-label='toggle password visibility' size='small' onClick={() => setShowPassword(!showPassword)} edge='start'>
          {showPassword ? <VisibilityOffRounded /> : <VisibilityRounded />}
        </IconButtonEnhanced>
      </InputAdornment>
    );
  };

  return (
    <Box>
      <TextFieldEnhanced
        id={id}
        value={value || ''}
        label={label}
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        required={required}
        disabled={disabled}
        error={hasError}
        helperText={hasError ? errorText : helperText}
        // props
        InputProps={{
          startAdornment: <StartAdornment />
        }}
        autoComplete='new-password'
        {...props}
      />
    </Box>
  );
};

PasswordFieldEnhanced.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string
};

export default PasswordFieldEnhanced;
