const DEFAULT_OPTIONS = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  angleAxis: {
    min: 0,
    interval: 2,
    axisLabel: { show: true },
    axisLine: { show: true },
    axisTick: { show: false }
  },
  radiusAxis: {
    type: 'category',
    data: [],
    z: 10,
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    }
  },
  polar: {},
  series: [
    {
      type: 'bar',
      data: [],
      coordinateSystem: 'polar',
      name: '',
      stack: 'a',
      emphasis: {
        focus: 'series'
      },
      itemStyle: {
        color: '#A1D5B3'
      }
    },
    {
      type: 'bar',
      data: [],
      coordinateSystem: 'polar',
      name: '',
      stack: 'a',
      emphasis: {
        focus: 'series'
      },
      itemStyle: {
        color: '#E5828C'
      }
    }
  ],
  legend: {
    show: true,
    data: []
  }
};

export { DEFAULT_OPTIONS };
