import { useQuery } from '@apollo/client';
import { Card, CardContent, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useMemo, useState } from 'react';
import { GET_FRAMEWORKS } from '../../../../api';
import { NoIntegrations } from '../../../../components/application';
import { Category } from '../../../../components/application/reports/cloudInfrastructure';
import { DotLoader } from '../../../../components/global';
import { Heading } from '../../../../components/ui';
import { SelectEnhanced } from '../../../../components/ui/form';
import { useOrgContext } from '../../../../contexts';
import { useAssessmentData } from '../../../../hooks';
import { CLOUD_FRAMEWORKS, TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const CloudInfrastructure = () => {
  const classes = useStyles();

  const { orgId } = useOrgContext();
  const {
    data: frameworksData,
    loading: frameworksLoading,
    error: frameworksError
  } = useQuery(GET_FRAMEWORKS, {
    variables: { where: { org: orgId, _or: [{ archived: false }, { archived_null: true }], integration_null: false, value_in: CLOUD_FRAMEWORKS } }
  });

  const [selectedFramework, setSelectedFramework] = useState();

  const frameworks = useMemo(() => {
    if (frameworksLoading || frameworksError) {
      return [];
    }
    setSelectedFramework(frameworksData?.frameworks?.[0]?.id);
    return frameworksData?.frameworks || [];
  }, [frameworksData?.frameworks, frameworksError, frameworksLoading]);

  const { assessment, loading: assessmentLoading, error: assessmentError } = useAssessmentData(selectedFramework, !selectedFramework);

  const categoryData = useMemo(() => {
    if (assessmentLoading || !assessment || assessmentError) {
      return {};
    }
    return assessment?.category || {};
  }, [assessment, assessmentError, assessmentLoading]);

  const title = useMemo(() => {
    return assessment?.name?.replace('Framework', '') || '';
  }, [assessment?.name]);

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title')} />
      {frameworksLoading || assessmentLoading ? (
        <Grid container item justifyContent='center'>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Fragment>
          {frameworks?.length ? (
            <Grid container spacing={2}>
              {frameworks?.length > 1 ? (
                <Grid item xs={12}>
                  <SelectEnhanced options={frameworks} onChange={setSelectedFramework} value={selectedFramework} />
                </Grid>
              ) : null}

              <Grid item xs={12} key={assessment?.category?.name}>
                <Category category={{ ...(categoryData || {}), name: title }} expanded />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <NoIntegrations link={'/app/integrations'} />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Fragment>
      )}
    </Container>
  );
};

CloudInfrastructure.propTypes = {};

export default CloudInfrastructure;
