import { Box, Grid, Tooltip, tooltipClasses, Zoom } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useState } from 'react';
import { useIsDesktop } from '../../../hooks';
import ModalEnhanced from '../../ui/modal';
import TypographyEnhanced from '../typography';

/**
 * @description:
 * Tooltip with customisation
 *
 * @typedef {import('@mui/material').TooltipProps} TooltipProps Props for `Tooltip` from '@mui/material` library
 * @param {String | JSX} title Text displayed in the tooltip component. Can be a JSX or String
 * @param {String} placement Position of tooltip
 * @param {JSX} children Component which requires tooltip
 * @param {String | Number} maxWidth can either be 'none' or pixels
 * @param {{ arrow: Boolean } & TooltipProps} props for tooltip component
 * @returns {React.FC<TooltipProps>}
 */
const TooltipEnhanced = ({
  title = '',
  placement = 'top',
  children,
  maxWidth = 'none',
  arrow = true,
  boxProps,
  showInfoModal = true,
  modalFallback = true,
  ...props
}) => {
  const isDesktop = useIsDesktop();

  const [open, setOpen] = useState(false);

  const toggle = () => {
    if (isDesktop || !showInfoModal) {
      return;
    }
    setOpen((open) => !open);
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: { maxWidth }
    }
  });

  const Label = useCallback(() => {
    if (typeof title === 'string') {
      return <TypographyEnhanced id={title || ''} variant='caption' />;
    }

    return title;
  }, [title]);

  return (
    <Fragment>
      <CustomWidthTooltip title={<Label />} placement={placement} arrow={arrow} {...props} TransitionComponent={Zoom}>
        <Box {...(boxProps || {})} {...(!isDesktop && { onClick: toggle })}>
          {children}
        </Box>
      </CustomWidthTooltip>
      {!isDesktop && modalFallback && (
        <ModalEnhanced modalOpen={open} setModalOpen={toggle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Label />
            </Grid>
          </Grid>
        </ModalEnhanced>
      )}
    </Fragment>
  );
};

TooltipEnhanced.propTypes = {
  title: PropTypes.any.isRequired,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top'
  ]),
  boxProps: PropTypes.object,
  children: PropTypes.any,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  arrow: PropTypes.bool,
  showInfoModal: PropTypes.bool,
  modalFallback: PropTypes.bool
};
export default TooltipEnhanced;
