// EVENTS.VIEWS.APP.INTEGRATIONS

export const INTEGRATIONS = {
  PAGE_VISITED: 'integrations-page_visited',
  CLICK_CONNECT_ACCOUNT: 'integration-click_connect_account',
  INTEGRATION_CONNECT_MODAL: 'integration-connect_accounts_modal_opened',
  SUGGEST_INTEGRATION: 'integration-suggest_integration',
  ADD_INTEGRATION: 'integration-add_integration',
  ADD_INTEGRATION_ERROR: 'integration-add_integration_error',
  AUTHORIZE_INTEGRATION: 'integration-authorize_integration',
  INVITE_ADMIN: 'integration-invite_admin',
  VIEW_INTEGRATION_STATUS: 'integration-view_integration_status',
  EDIT_INTEGRATION: 'integration-edit_integration',
  ARCHIVE_INTEGRATION: 'integration-archive-integration'
};
