import { logEvent } from '../../../../../../../library';
import { CYBER_ASSESSMENT as CYBER_ASSESSMENT_EVENTS } from '../../../../../../../library/amplitude/events/views/app/assessments/cyber';

//Pathname is sent to differentiate between answer/comment activity from the Mandatory Compliance Requirements dash widget
//without firing multiple events for single action

//ANSWER EVENTS
/**
 * Logs event for user answering a previously unanswered question
 *
 * @param {Object} question
 * @param {String} type
 * @param {Object} eventPath
 */
const logAnswerQuestion = (question, type, eventPath) => {
  const { id, mappingNumber } = question || {};

  logEvent(eventPath?.MANDATORY_COMPLIANCE_ANSWER || CYBER_ASSESSMENT_EVENTS.ANSWER_QUESTION, {
    question_id: id,
    question_mapping_number: mappingNumber,
    question_type: type
  });
};

/**
 * Logs event for user updating the answer to a previously answered question
 *
 * @param {Object} question
 * @param {Array | String} answer
 * @param {Array | String} oldSelected
 * @param {Array | String} resultSelected
 * @param {Object} eventPath
 */
const logUpdateAnswerQuestion = (question, answer, oldSelected, resultSelected, eventPath) => {
  const { id, mappingNumber, datapoint } = question || {};
  const { answer: prevAnswer } = datapoint || {};

  logEvent(eventPath?.MANDATORY_COMPLIANCE_UPDATE_ANSWER || CYBER_ASSESSMENT_EVENTS.CHANGE_ANSWER_QUESTION, {
    question_id: id,
    question_mapping_number: mappingNumber,
    previous_answer: prevAnswer || oldSelected,
    updated_answer: answer || resultSelected
  });
};

//COMMENT EVENTS
/**
 * Logs event for user adding a new comment
 *
 * @param {Object} question
 * @param {Object} eventPath
 */
const logAddComment = (question, eventPath) => {
  const { id, mappingNumber } = question || {};

  logEvent(eventPath?.MANDATORY_COMPLIANCE_COMMENT_ADD || CYBER_ASSESSMENT_EVENTS.USER_COMMENT, { question_id: id, question_mapping_number: mappingNumber });
};

/**
 * Logs event for user editing an existing comment
 *
 * @param {Object} question
 * @param {String | Number} logId
 * @param {Object} eventPath
 */
const logEditComment = (question, logId, eventPath) => {
  const { id, mappingNumber } = question || {};

  logEvent(eventPath?.MANDATORY_COMPLIANCE_COMMENT_EDIT || CYBER_ASSESSMENT_EVENTS.USER_COMMENT_EDIT, {
    question_id: id,
    question_mapping_number: mappingNumber,
    comment_id: logId
  });
};

/**
 * Logs event for user adding an attachment to a new or existing comment
 *
 * @param {Object} question
 * @param {String | Number} logId
 * @param {Object} eventPath
 */
const logAddAttachment = (question, logId, eventPath) => {
  const { id, mappingNumber } = question || {};

  logEvent(eventPath?.MANDATORY_COMPLIANCE_COMMENT_ADD_ATTACHMENT || CYBER_ASSESSMENT_EVENTS.USER_ADD_ATTACHMENT, {
    question_id: id,
    question_mapping_number: mappingNumber,
    comment_id: logId
  });
};

/**
 * Logs event for user tagging 'eg @user' a user in a new or existing comment
 *
 * @param {Object} question
 * @param {String | Number} logId
 * @param {String | Number} userId
 * @param {Array} mentionedUserIds
 * @param {Object} eventPath
 */
const logTagUser = (question, logId, userId, mentionedUserIds, eventPath) => {
  const { id, mappingNumber } = question || {};

  logEvent(eventPath?.MANDATORY_COMPLIANCE_COMMENT_TAG_USER || CYBER_ASSESSMENT_EVENTS.USER_COMMENT_TAG, {
    question_id: id,
    question_mapping_number: mappingNumber,
    comment_id: logId,
    user_id: userId,
    assignees: mentionedUserIds
  });
};

export { logAnswerQuestion, logUpdateAnswerQuestion, logAddComment, logEditComment, logAddAttachment, logTagUser };
