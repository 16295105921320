import { Navigate, Route, Routes } from 'react-router-dom';
import UserLayout from '../../layout/userLayout';
import ForgotPassword from './forgotPassword';
import Home from './home';
import Login from './login';
import Register from './register';
import ResetPassword from './resetPassword';

const User = () => {
  return (
    <UserLayout>
      <Routes>
        <Route from='/' element={<Navigate to='/login' />} />
        <Route path='home' element={<Home />} />
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password' element={<ResetPassword />} />
      </Routes>
    </UserLayout>
  );
};

User.propTypes = {};

export default User;
