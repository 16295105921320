import { INTEGRATION_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { FEEDBACK_TYPES } from '@anirudhm9/base-lib/lib/constants/api/feedback';
import { formatErrorMessage } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { INITIALISE_INTEGRATION } from '../../../../api';
import { defaultMessages } from '../../../../constants';
import FeedbackModal from '../../../../containers/feedback';
import { useIntegrationContext, useOrgContext } from '../../../../contexts';
import { logEvent } from '../../../../library';
import { INTEGRATIONS as INTEGRATION_EVENTS } from '../../../../library/amplitude/events/views/app/integrations';
import { ButtonEnhanced, toast, TypographyEnhanced } from '../../../global';
import { Watermark } from '../../../ui';
import ModalEnhanced from '../../../ui/modal';
import AddApplication from './addApplication';
import AppTile from './appTile';
import { APPLICATIONS, TRANSLATION_PATH } from './constants';

const ConnectIntegrationModal = ({ modalOpen = false, setModalOpen }) => {
  const { orgId } = useOrgContext();
  const { integrationsByType = {}, refetch: refetchIntegrations } = useIntegrationContext();

  const [initialiseIntegration] = useMutation(INITIALISE_INTEGRATION);

  const [selectedApp, setSelectedApp] = useState();
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const applications = useMemo(() => {
    if (!integrationsByType) {
      return APPLICATIONS;
    }
    const typeKeys = Object.keys(integrationsByType || {});
    return APPLICATIONS.filter((application) => !typeKeys.includes(application.key));
  }, [integrationsByType]);

  const handleModalClose = () => {
    setSelectedApp();
    setModalOpen(false);
  };

  const handleClick = async (application) => {
    const { key } = application || {};
    try {
      let integration;
      switch (key) {
        case INTEGRATION_TYPES.GSUITE.value:
        case INTEGRATION_TYPES.GITHUB.value:
        case INTEGRATION_TYPES.SLACK.value:
          localStorage.setItem(`${(key || '').toLowerCase()}_auth`, '');
          {
            const { data: integrationData } = (await initialiseIntegration({ variables: { org: orgId, type: key } })) || {};
            integration = integrationData?.initialiseIntegration;
            toast.success(defaultMessages.defaultSuccess);
            logEvent(INTEGRATION_EVENTS.ADD_INTEGRATION, { integration: key });
            refetchIntegrations();
          }
          break;
        default:
        // do nothing
      }
      setSelectedApp(integration || application);
    } catch (error) {
      console.error(error);
      logEvent(INTEGRATION_EVENTS.ADD_INTEGRATION_ERROR, { integration: key });
      toast.error(formatErrorMessage(error));
    }
  };

  const toggleFeedbackModal = () => {
    setFeedbackOpen((open) => !open);
  };

  const ModalContent = () => {
    if (selectedApp) {
      return <AddApplication type={selectedApp?.key || selectedApp?.type} application={selectedApp} toggle={handleModalClose} />;
    }
    if (feedbackOpen) {
      return <FeedbackModal open={feedbackOpen} toggle={toggleFeedbackModal} type={FEEDBACK_TYPES.REQUEST.value} />;
    }
    return (
      <Grid container spacing={1}>
        <Grid item>
          <Grid item xs={12}>
            <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h6' textAlign='center' style={{ fontWeight: 'bold' }} />
          </Grid>
          <Grid item xs={12}>
            <TypographyEnhanced id={TRANSLATION_PATH('description')} paragraph textAlign='center' />
          </Grid>
          <Grid item xs={12} container spacing={2} justifyContent='center'>
            {applications?.map((application) => {
              const { enabled, key } = application || {};
              if (enabled) {
                return (
                  <Grid item key={key}>
                    <AppTile application={application} disabled={!enabled || !!integrationsByType[key]} onClick={() => handleClick(application)} />
                  </Grid>
                );
              }
              return (
                <Grid item key={key}>
                  <Watermark>
                    <AppTile application={application} disabled={!enabled || !!integrationsByType[key]} onClick={() => handleClick(application)} />
                  </Watermark>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <TypographyEnhanced id={TRANSLATION_PATH('help')} paragraph />
          </Grid>
          <Grid container item>
            <Grid item sx={{ marginLeft: 'auto' }}>
              <ButtonEnhanced onClick={toggleFeedbackModal}>Suggest Application</ButtonEnhanced>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <ModalEnhanced modalOpen={modalOpen || feedbackOpen} setModalOpen={handleModalClose}>
      <ModalContent />
    </ModalEnhanced>
  );
};

ConnectIntegrationModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired
};

export default ConnectIntegrationModal;
