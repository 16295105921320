import axios from 'axios';
import { toast } from '../../../components/global';

const SIMULTANEOUS_UPLOAD_FILE_LIMIT = 10;

const uploadFile = async (file, secure = true) => {
  const formData = new FormData();
  formData.append('files', file);
  if (secure) {
    formData.append('path', 'secure');
  }
  const response = await axios.post('/upload', formData, { headers: { 'content-type': 'multipart/form-data' } });
  return response.data[0];
};

const uploadFiles = async (files, secure = true) => {
  if (!files || !files.length) {
    return;
  }

  if (files.length > SIMULTANEOUS_UPLOAD_FILE_LIMIT) {
    toast.error(`Maximum upload limit ${SIMULTANEOUS_UPLOAD_FILE_LIMIT} files at a time.`);
    return;
  }

  const entity = [];
  const errors = [];
  try {
    await Promise.allSettled(Object.values(files).map((file) => uploadFile(file, secure))).then((results) => {
      results.forEach((result) => {
        const { status, value, reason } = result || {};
        if (status === 'fulfilled') {
          entity.push(value);
        } else {
          errors.push({
            value,
            reason
          });
        }
      });
    });
  } catch (error) {
    console.error(error);
  }

  return entity;
};

export { uploadFile, uploadFiles };
