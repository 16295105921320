import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Image } from '../../../ui';
import DotLoader from '../../dotLoader';

const useStyles = makeStyles(() => ({
  button: {
    height: '40px',
    width: '215px',
    borderRadius: '2px',
    cursor: 'pointer'
  },
  iconContainer: {
    height: '38px',
    width: '38px',
    backgroundColor: '#fff',
    borderRadius: '1px'
  },
  labelContainer: {
    height: '16px',
    padding: '12px'
  },
  label: {
    color: '#fff',
    lineHeight: '1'
  },
  light: {},
  dark: {}
}));

/**
 * @description:
 * Button for Google log in
 *
 * @typedef {import('@mui/material').ButtonProps} ButtonProps Props for `Button` from '@mui/material` library
 * @param {{ loading: Boolean } & ButtonProps} props loading state for Spinner
 * @returns {React.FC<ButtonProps>}
 */
const MicrosoftSignIn = ({ loading, onClick, disabled, ...props }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.button} onClick={onClick} disabled={disabled} {...props}>
        <Grid container>
          <Grid item container justifyContent='center'>
            <Image src='/assets/img/microsoft/signin_light.svg' alt='Microsoft signin' />
          </Grid>
        </Grid>
      </Box>
      {loading && <DotLoader />}
    </Fragment>
  );
};

MicrosoftSignIn.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default MicrosoftSignIn;
