import { COMMON, NOTIFICATIONS, USER_ACTIONS } from './common';
import { COMPONENTS } from './components';
import { VIEWS } from './views';

export const EVENTS = {
  VIEWS,
  COMMON,
  USER_ACTIONS,
  NOTIFICATIONS,
  COMPONENTS
};
