import { setupAxios, subscribeUserToWS, userVar, wsContext } from '../../../../api';
import { EVENTS, initialiseAmplitude, logEvent } from '../../../../library';

const initialiseUser = ({ id, firstName, lastName }, register) => {
  localStorage.setItem('user_id', id);
  if (id) {
    userVar(id);
  }
  setupAxios();
  if (wsContext?.userSocket) {
    wsContext.userSocket.disconnect();
  }

  initialiseAmplitude(`${id} ${firstName} ${lastName}`);
  logEvent(EVENTS.COMMON[register ? 'REGISTER' : 'LOGIN']);

  subscribeUserToWS();
};

export { initialiseUser };
