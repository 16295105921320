export const formatFilters = (filters = {}, applications) => {
  // Flatten out each filter group object into a single array

  const filtersByKey = Object.keys(filters).reduce((result, filterKey) => {
    Object.keys(filters[filterKey] || {}).forEach((key) => {
      result = { ...(result || {}), [key]: filters[filterKey][key] };
    });
    return result;
  }, {});

  const thirdparty_in = [];

  (applications || []).forEach((application) => {
    if ((filtersByKey.KEY_BUSINESS_SYSTEM || []).length) {
      if (filtersByKey.KEY_BUSINESS_SYSTEM.includes(application.kbs)) {
        thirdparty_in.push(application?.thirdparty?.id);
      }
    }

    if ((filtersByKey.CREATION_METHOD || []).length) {
      const all = filtersByKey.CREATION_METHOD.length === 2;
      const selected = filtersByKey.CREATION_METHOD[0];
      if (all || application[selected]) {
        thirdparty_in.push(application?.thirdparty?.id);
      }
    }
  });

  return {
    thirdparty_in
  };
};
