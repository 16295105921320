import { CRITICALITIES, LOG_SUB_TYPES, LOG_TYPES, PAGE_THRESHOLD, STATUSES } from '@anirudhm9/base-lib/lib/constants';
import { formatErrorMessage, getId } from '@anirudhm9/base-lib/lib/utils';
import { useMutation, useQuery } from '@apollo/client';
import { Grid, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COUNT_LOGS, CREATE_LOG, CREATE_OR_UPDATE_ISSUE, GET_ISSUE_BY_ID, GET_LOGS, GET_QUESTIONS, UPDATE_LOG } from '../../../../../api';
import { defaultMessages } from '../../../../../constants';
import { useCriticalityAndStatusContext, useDevicesContext, useOrgContext, useUserContext } from '../../../../../contexts';
import { useUsersContext } from '../../../../../contexts/usersContext';
import { useApplications } from '../../../../../hooks';
import { ButtonEnhanced, LabelWithInfo, TypographyEnhanced, toast } from '../../../../global';
import { CommentActivity } from '../../../../ui';
import { HTMLEditor, SelectEnhanced, SelectStatusCriticality, TextFieldEnhanced } from '../../../../ui/form';
import { IntegrationBanner } from '../../../integrations';
import EditUsersSelect from '../../applications/viewEditUsers/editUsersSelect';
import { SORT_VALUES as DEVICE_SORT_VALUES } from '../../devices/filter/constants';
import { sortDevices } from '../../devices/filter/utils';
import ActionList from '../actionList';
import { DEFAULT_ISSUE_DATA, ISSUE_RELATION_OPTIONS, TOOLTIPS, TRANSLATION_PATH_VIEW, VIEW_ISSUE_MODAL_WIDTH } from '../constants';
import DisclaimerModal from '../disclaimerModal';
import IssueRelationField from '../issueRelationField';
import IssueLogo from '../logo';

import { logEvent } from '../../../../../library';
import { ISSUES as MANAGE_ISSUES_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/issues';
import { stripRedundantRelations, validateFields } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  bold: {
    fontWeight: 'bold'
  },
  addIssueLogo: {
    fontSize: '75px'
  }
}));

/**
 *
 * Component to display key things for an issue
 *
 * @param {JSON} issue
 * @param {Boolean} loading
 * @param {*} error
 * @param {VoidFunction} refetch
 * @param {VoidFunction} onCancel
 * @returns {JSX}
 */

const IssueItem = ({ id: issueId = '', loading, error, refetch, onCancel, isModal = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { orgId } = useOrgContext();
  const { userId } = useUserContext();
  const { activeUsers } = useUsersContext();
  const { devices: devicesData, loading: devicesLoading, error: devicesError } = useDevicesContext();
  const { statuses, criticalities } = useCriticalityAndStatusContext();

  const [localLoading, setLocalLoading] = useState(false);
  const [localIssue, setLocalIssue] = useState(DEFAULT_ISSUE_DATA);
  const [actions, setActions] = useState([]);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const [errorFields, setErrorFields] = useState({});
  const [logs, setLogs] = useState([]);
  const [pageSize, setPageSize] = useState(PAGE_THRESHOLD);
  const [start, setStart] = useState(0);

  const [createOrUpdateIssue] = useMutation(CREATE_OR_UPDATE_ISSUE);
  const [createLog] = useMutation(CREATE_LOG);
  const [updateLog] = useMutation(UPDATE_LOG);

  useEffect(() => {
    if (!issueId) {
      localIssue.custom = true;
    }
  }, [issueId, localIssue]);

  const statusOptions = useMemo(() => {
    if (!statuses?.length) {
      return [];
    }
    const options = statuses.map((status) => {
      return {
        ...(status || {}),
        color: STATUSES[status.value].color
      };
    });
    if (!localIssue?.integration) {
      return options;
    }

    return options.map((status) => {
      // filter out resolved status for integration issues
      if (status.value === STATUSES.RESOLVED.value) {
        return { ...status, disabled: true };
      }
      return status;
    });
  }, [localIssue?.integration, statuses]);

  const criticalityOptions = useMemo(() => {
    if (!criticalities?.length) {
      return [];
    }
    const options = criticalities.map((criticality) => {
      return {
        ...(criticality || {}),
        color: CRITICALITIES[criticality.value].color
      };
    });
    return options;
  }, [criticalities]);

  //get questions
  const {
    data: questionsData,
    loading: questionsLoading,
    error: questionsError
  } = useQuery(GET_QUESTIONS, {
    variables: { where: { org: orgId } },
    skip: !orgId,
    fetchPolicy: 'no-cache'
  });

  //get applications
  const { applications } = useApplications();

  //DEVICES
  const devices = useMemo(() => {
    if (devicesLoading || devicesError) {
      return [];
    }
    return sortDevices(devicesData, DEVICE_SORT_VALUES.USERNAME_ASC.value);
  }, [devicesData, devicesError, devicesLoading]);

  //QUESTIONS
  const questions = useMemo(() => {
    if (questionsLoading || questionsError) {
      return [];
    }
    const { questions } = questionsData || [];
    return questions;
  }, [questionsData, questionsError, questionsLoading]);

  //RELATION
  const relation = useMemo(() => {
    if (!localIssue?.relation) {
      return '';
    }
    return localIssue?.relation;
  }, [localIssue?.relation]);

  const {
    data: issueData,
    loading: issueLoading,
    error: issueError,
    refetch: refetchIssue
  } = useQuery(GET_ISSUE_BY_ID, { variables: { id: issueId }, skip: !issueId });

  const issue = useMemo(() => {
    if (issueLoading || issueError) {
      return {};
    }

    const { assignees = [] } = issueData?.issue || {};
    const relations = ['criticality', 'status', 'device', 'application', 'user', 'question'];
    return {
      ...(issueData?.issue || {}),
      assignees: (assignees || []).map((assignee) => assignee.id),
      ...relations.reduce((result, relation) => {
        result = {
          ...(result || {}),
          [relation]: getId(issueData?.issue?.[relation])
        };
        return result;
      }, {})
    };
  }, [issueData?.issue, issueError, issueLoading]);

  useEffect(() => {
    if (loading || issueLoading) {
      return;
    }

    if (error || issueError) {
      navigate('/app/manage/issues/');
    }

    setLocalIssue(issue);
    setActions(issue?.actions || []);
  }, [error, issue, issueError, issueLoading, loading, navigate]);

  const {
    data: logCountData,
    loading: logCountLoading,
    error: logCountError,
    refetch: refetchLogCount
  } = useQuery(COUNT_LOGS, {
    variables: {
      where: { issue: issue?.id }
    },
    skip: !issue?.id
  });

  const {
    data: logData,
    loading: logLoading,
    error: logError,
    refetch: refetchLogs
  } = useQuery(GET_LOGS, {
    variables: {
      where: { issue: issue?.id, org: orgId },
      start,
      limit: pageSize
    },
    skip: !issue?.id
  });

  useEffect(() => {
    if (logLoading || logError) {
      return;
    }

    setLogs(logData?.logs || []);
  }, [logData?.logs, logError, logLoading]);

  const logsCount = useMemo(() => {
    if (logCountLoading || logCountError) {
      return 0;
    }
    return logCountData?.countLogs || 0;
  }, [logCountData?.countLogs, logCountError, logCountLoading]);

  const handleReset = () => {
    setLocalIssue(issue);
    logEvent(MANAGE_ISSUES_EVENTS.EDIT_CANCEL, { issue_id: issueId });
    if (onCancel) {
      onCancel();
    }
    if (!isModal) {
      navigate('/app/manage/issues');
    }
  };

  const toggleModal = () => {
    setShowDisclaimer((open) => !open);
  };

  const handleChange = (key, value) => {
    setLocalIssue((issue) => {
      return { ...(issue || {}), [key]: value };
    });
  };

  const handleClearLogEvent = (values) => {
    if (!values.length) {
      logEvent(MANAGE_ISSUES_EVENTS.CLEAR_ASSIGNEES, { issue_id: issueId });
    }
  };

  const handleSaveLogEvent = (oldIssue, newIssue) => {
    if (!issueId) {
      //logEvent for creating issue here
      return;
    }
    //loop through object properties and compare, if not equal then add to event log properties to be saved
    //format: property: {old: prevValue, new: updatedValue}
    const updatedProperties = { issue_id: issueId };

    for (const property in oldIssue) {
      if (!_.isEqual(oldIssue[property], newIssue[property])) {
        updatedProperties[property] = { old: oldIssue[property], new: newIssue[property] };
      }
    }

    logEvent(MANAGE_ISSUES_EVENTS.EDIT_SAVE, updatedProperties);
  };

  const handleSave = async () => {
    try {
      setLocalLoading(true);

      const localIssueCopy = stripRedundantRelations(localIssue);
      localIssueCopy.actions = actions;

      const dataToSave = localIssueCopy || {};

      await createOrUpdateIssue({ variables: { id: issue?.id, org: orgId, data: dataToSave } });

      handleSaveLogEvent(issue, dataToSave);
      toast.success(defaultMessages.defaultSuccess);
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setLocalLoading(false);
      if (refetch) {
        refetch();
      }
      if (!issueId) {
        navigate('/app/manage/issues');
      } else {
        refetchIssue();
        refetchLogs();
        refetchLogCount();
        onCancel();
      }
    }
  };

  const hasChanged = useMemo(() => {
    const issueResult = !_.isEqual(issue, localIssue);
    const actionResult = !_.isEqual(issue?.actions, actions);

    if (issueResult || actionResult) {
      return true;
    }
    return false;
  }, [issue, localIssue, actions]);

  const createOrUpdateComment = async (data, mentionedUserIds) => {
    let issueUpdated = false;
    try {
      setLocalLoading(true);
      const { id: logId, comment, attachments } = data || {};
      if (!logId) {
        await createLog({
          variables: {
            data: {
              comment,
              attachments,
              org: orgId,
              type: LOG_TYPES.ISSUE,
              subType: LOG_SUB_TYPES.COMMENT,
              issue: issue?.id
            }
          }
        });
        logEvent(MANAGE_ISSUES_EVENTS.ADD_COMMENT, { issue_id: issueId });
      } else {
        await updateLog({
          variables: {
            id: logId,
            data: {
              comment,
              attachments
            }
          }
        });
        logEvent(MANAGE_ISSUES_EVENTS.EDIT_COMMENT, { issue_id: issueId, log_id: logId });
      }

      if (attachments.length) {
        logEvent(MANAGE_ISSUES_EVENTS.ADD_ATTACHMENT, { issue_id: issueId, log_id: logId });
      }

      // add mentioned users as assignees to the issue
      if (mentionedUserIds?.length) {
        const { assignees: localAssignees = [] } = localIssue || {};
        const { assignees: prevAssignees = [] } = issue || {};

        const assignees = _.uniq([...(localAssignees || []), ...(mentionedUserIds || [])]);

        const localIssueCopy = stripRedundantRelations(localIssue);
        localIssueCopy.assignees = assignees;

        await createOrUpdateIssue({ variables: { id: issue?.id, org: orgId, data: localIssueCopy } });

        //log new user mentions
        if (!_.isEqual(prevAssignees, assignees)) {
          const newAssignees = mentionedUserIds.reduce((result, current) => {
            if (prevAssignees.includes(current)) {
              return result;
            }
            result.push(current);
            return result;
          }, []);

          logEvent(MANAGE_ISSUES_EVENTS.TAG_COMMENT, { issue_id: issueId, logId: logId, new_mentions: newAssignees });
        }
        issueUpdated = true;
      }
      toast.success(defaultMessages.defaultSuccess);
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      refetchLogs();
      refetchLogCount();
      // refetch issues if issue assignees were updated
      if (issueUpdated) {
        refetchIssue();
        if (refetch) {
          refetch();
        }
      }
      setLocalLoading(false);
    }
  };

  /**
   * Assigns user to dataToSave object if status has been changed to 'Risk Accepted' and there are no existing assignees
   * Updates localIssue state with same
   *
   * @param {Object} dataToSave - a copy of localIssue
   * @param {Object} selectedStatus
   * @returns {Object} dataToSave
   */
  const handleDefaultAssignee = (dataToSave, selectedStatus) => {
    const { assignees } = dataToSave || {};
    const { value: statusValue } = selectedStatus || {};

    const isRiskAccepted = statusValue === STATUSES.ACCEPTED.value;

    if (isRiskAccepted && !assignees?.length) {
      dataToSave.assignees = [userId];
    }

    setLocalIssue(dataToSave);
    return dataToSave;
  };

  const disclaimerConfirmation = () => {
    const localIssueCopy = _.cloneDeep(localIssue);
    const { status: newStatusId } = localIssueCopy || {};
    const selectedStatus = statuses.find((status) => status?.id === newStatusId);

    const dataToSave = handleDefaultAssignee(localIssueCopy, selectedStatus);

    if (!validateFields(dataToSave, setErrorFields)) {
      return;
    }

    //If saving status as OPEN, no disclaimer modal
    if (selectedStatus?.value === STATUSES.OPEN.value) {
      handleSave();
    } else {
      toggleModal();
    }
  };

  const disclaimerText = useMemo(() => {
    const selectedStatus = (statusOptions || []).find((status) => status.id === localIssue?.status) || '';
    return TRANSLATION_PATH_VIEW(`statusDisclaimer.${selectedStatus?.value}`);
  }, [localIssue?.status, statusOptions]);

  if (loading || issueLoading) {
    //set width as a constant and use for both
    return <Skeleton sx={{ width: VIEW_ISSUE_MODAL_WIDTH }} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} spacing={1} alignItems='flex-start'>
        {isModal ? (
          <IntegrationBanner model='Issue' integration={issue?.integration} />
        ) : (
          <Grid container item alignItems='center' spacing={2}>
            <Grid item>
              <IssueLogo relation={relation} className={classes.addIssueLogo} />
            </Grid>
            <Grid item xs>
              <IntegrationBanner model='Issue' integration={issue?.integration} />
            </Grid>
          </Grid>
        )}

        <Grid container item spacing={2} xs={12} md={isModal ? 12 : 6}>
          <Grid item xs={12}>
            <TextFieldEnhanced
              id='name'
              label='Name'
              value={localIssue?.name || ''}
              onChange={(value) => handleChange('name', value)}
              required
              errorText={errorFields['name']}
              hasError={!!errorFields['name']}
            />
          </Grid>

          <Grid item xs={12}>
            <HTMLEditor
              id='description'
              value={localIssue?.description || ''}
              label={'Description'}
              onChange={(value) => handleChange('description', value)}
              onCancel={() => handleChange('description', '')}
              placeholder=''
            />
          </Grid>

          {/* Actions */}
          <Grid item xs={12}>
            <ActionList
              id='actions'
              label={localIssue?.integration ? <LabelWithInfo label='Actions' tooltip={TOOLTIPS.DISABLED_ACTIONS} /> : 'Actions'}
              custom={localIssue?.custom || false}
              actions={actions}
              setActions={setActions}
              errorText={errorFields['actions']}
              hasError={!!errorFields['actions']}
              translationPath={TRANSLATION_PATH_VIEW}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12} md={isModal ? 12 : 6}>
          {/* Status */}
          <Grid item xs={12}>
            <SelectStatusCriticality
              id='status'
              label='Status'
              value={localIssue?.status}
              onChange={(value) => handleChange('status', value)}
              required
              options={statusOptions}
              errorText={errorFields['status']}
              hasError={!!errorFields['status']}
            />
          </Grid>

          {/* Criticality */}
          <Grid item xs={12}>
            <SelectStatusCriticality
              id='criticality'
              label='Criticality'
              value={localIssue?.criticality}
              onChange={(value) => handleChange('criticality', value)}
              required
              options={criticalityOptions}
              errorText={errorFields['criticality']}
              hasError={!!errorFields['criticality']}
            />
          </Grid>
          {/* Assignees */}
          <Grid item xs={12}>
            <EditUsersSelect
              id='assignees'
              label={<LabelWithInfo label='Assignees' tooltip={TOOLTIPS.ASSIGNEES} />}
              options={activeUsers || []}
              value={localIssue?.assignees || []}
              enable
              onChange={(values) => {
                handleClearLogEvent(values);
                handleChange('assignees', values);
              }}
              errorText={errorFields['assignees']}
              hasError={!!errorFields['assignees']}
            />
          </Grid>

          {/* Relation */}
          <Grid item xs={12}>
            <SelectEnhanced
              id='relation'
              label={localIssue?.integration ? <LabelWithInfo label='Relation' tooltip={TOOLTIPS.DISABLED_RELATIONS} /> : 'Relation'}
              value={ISSUE_RELATION_OPTIONS[localIssue?.relation]?.value || ISSUE_RELATION_OPTIONS.NONE.value}
              onChange={(value) => handleChange('relation', value)}
              options={Object.values(ISSUE_RELATION_OPTIONS)}
              disabled={localIssue?.integration ? true : false}
            />
          </Grid>
          {/* Specific Relation Select */}
          {relation && (
            <Grid item xs={12}>
              <IssueRelationField
                relation={relation}
                issue={localIssue}
                onChange={handleChange}
                devices={devices}
                questions={questions}
                applications={applications || []}
                users={activeUsers}
              />
            </Grid>
          )}
          {relation === ISSUE_RELATION_OPTIONS.MODULE.value && (
            <Grid container item>
              <Grid item xs={12}>
                <SelectEnhanced
                  id='module'
                  label={localIssue?.integration ? <LabelWithInfo label='Module' tooltip={TOOLTIPS.DISABLED_MODULES} /> : 'Module'}
                  value={localIssue?.module?.id || ''}
                  onChange={(value) => handleChange('module', value)}
                  options={[localIssue?.module || {}]}
                  disabled={!!localIssue?.integration}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectEnhanced
                  id='vulnerabilities'
                  label={localIssue?.integration ? <LabelWithInfo label='Vulnerabilities' tooltip={TOOLTIPS.DISABLED_VULNERABILITIES} /> : 'Vulnerabilities'}
                  value={(localIssue?.vulnerabilities || []).map((vulnerability) => vulnerability?.id)}
                  onChange={(value) => handleChange('vulnerabilities', value)}
                  options={localIssue?.vulnerabilities || []}
                  disabled={!!localIssue?.integration}
                  multiple
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <DisclaimerModal open={showDisclaimer} toggle={toggleModal} title={disclaimerText} handleSave={handleSave} />

      <Grid container item justifyContent='flex-end' spacing={2}>
        <Grid item>
          <ButtonEnhanced
            loading={loading || logCountLoading || logLoading || localLoading}
            variant='outlined'
            disabled={localLoading || (!onCancel && !hasChanged)}
            onClick={handleReset}
          >
            <TypographyEnhanced id='common.button.cancel' />
          </ButtonEnhanced>
        </Grid>
        <Grid item>
          <ButtonEnhanced loading={loading || logCountLoading || logLoading || localLoading} disabled={!hasChanged} onClick={disclaimerConfirmation}>
            <TypographyEnhanced id='common.button.save' />
          </ButtonEnhanced>
        </Grid>
      </Grid>

      {issueId && (
        <Grid item xs={12}>
          <CommentActivity
            id={issue?.id}
            model='issue'
            total={logsCount}
            logs={logs}
            onSave={createOrUpdateComment}
            setStart={setStart}
            loading={logCountLoading || logLoading}
            className={classes.accordionBackground}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </Grid>
      )}
    </Grid>
  );
};

IssueItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.any,
  refetch: PropTypes.func,
  onCancel: PropTypes.func,
  isModal: PropTypes.bool
};
export default IssueItem;
