import { ScienceRounded } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ButtonEnhanced, TypographyEnhanced } from '../../../global';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dialogContent: {
    paddingTop: '10px'
  },
  continueButton: {
    paddingTop: '10px'
  }
}));

const AlphaNoticeModal = ({ modalOpen, setModalOpen }) => {
  const classes = useStyles();

  const noticeText1 = 'You are using an early-release Alpha version of the product. Thank you for helping us iterate on and improve our capabilities.';
  const noticeText2 =
    'Please be aware that while we will endeavour to ensure the data we hold will carry forward into the subsequent versions, there is a small chance that we will be unable to do so due to changes in functionality.';

  return (
    <Dialog open={modalOpen} maxWidth='xs'>
      <DialogTitle>
        <Grid container item xs={12} className={classes.dialogTitle}>
          <ScienceRounded />
          <TypographyEnhanced id={'Alpha Notice'} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.dialogContent}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <TypographyEnhanced id={noticeText1} />
              <br />
              <TypographyEnhanced id={noticeText2} />
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent={'right'} className={classes.continueButton}>
            <ButtonEnhanced onClick={() => setModalOpen((prev) => !prev)}>Continue</ButtonEnhanced>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

AlphaNoticeModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired
};

export default AlphaNoticeModal;
