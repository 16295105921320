// EVENTS.VIEWS.USER.LOGIN

export const LOGIN = {
  PAGE_VISITED: 'login-page_visited',
  GOOGLE: 'google_login-actioned_button',
  MICROSOFT: 'microsoft_login-actioned_button',
  REMEBER_ME_CHECKED: 'checked-remember_login',
  REMEBER_ME_UNCHECKED: 'unchecked-remember_login',
  SIGN_UP_BUTTON: 'sign_up-actioned_button'
};
