import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../contexts';
import IntegrationProvider from '../../../contexts/integrationContext';
import { RoleProtectedRoute } from '../../../helpers/routing';
import OAuthCallback from './callback';
import DefaultIntegrations from './default';

const Integrations = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <IntegrationProvider>
        <Routes>
          <Route path='/' element={<RoleProtectedRoute roles={roles} path={pathname} element={DefaultIntegrations} />} />
          <Route path='callback/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={OAuthCallback} />} />
          <Route path='*' element={<RoleProtectedRoute roles={roles} path={pathname} element={DefaultIntegrations} />} />
        </Routes>
      </IntegrationProvider>
    </div>
  );
};

Integrations.propTypes = {};

export default Integrations;
