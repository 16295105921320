import { Slider } from '@mui/material';
import PropTypes from 'prop-types';
import { useIsDesktop } from '../../../hooks';

const SliderEnhanced = ({ value, onChangeCommitted, onChange, min = 0, max = 5, marks = [], step = 0.1, ...props }) => {
  const isDesktop = useIsDesktop();

  const handleSliderChangeComitted = (_event, newValue) => {
    try {
      if (onChangeCommitted) {
        onChangeCommitted(newValue);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSliderChange = (_event, newValue) => {
    try {
      if (onChange) {
        onChange(newValue);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Slider
      value={typeof value === 'number' ? value : 0}
      onChangeCommitted={handleSliderChangeComitted}
      onChange={handleSliderChange}
      aria-labelledby='input-slider'
      marks={marks}
      min={min}
      max={max}
      step={step}
      valueLabelDisplay={isDesktop ? 'auto' : 'on'}
      defaultValue={value}
      // valueLabelDisplay='on'
      sx={{
        '& .MuiSlider-valueLabel': (theme) => {
          return {
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: theme.palette.secondary.main,
            color: '#000',
            fontWeight: 'bold',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
              transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
            },
            '& > *': {
              transform: 'rotate(45deg)'
            }
          };
        }
      }}
      {...props}
    />
  );
};

SliderEnhanced.propTypes = {
  value: PropTypes.number.isRequired,
  onChangeCommitted: PropTypes.func,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  marks: PropTypes.array,
  step: PropTypes.number,
  sliderProps: PropTypes.object
};
export default SliderEnhanced;
