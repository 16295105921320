import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Image } from '../../../../ui';
import { AutoCompleteEnhanced } from '../../../../ui/form';

const UnCheckIcon = <CheckBoxOutlineBlankRounded fontSize='small' />;
const CheckedIcon = <CheckBoxRounded fontSize='small' />;

const CountrySelect = ({ id, label, options, value, multiple, checkbox, onChange, hasError, errorText, ...props }) => {
  const renderOption = useCallback(
    (props, option, { selected }) => {
      const { iso2, name } = option || {};
      return (
        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {checkbox && <Checkbox icon={UnCheckIcon} checkedIcon={CheckedIcon} style={{ marginRight: 8 }} checked={selected} />}
          <Image
            loading='lazy'
            width='20'
            src={`https://flagcdn.com/w20/${(iso2 || '').toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${(iso2 || '').toLowerCase()}.png 2x`}
            alt={''}
          />
          {name || ''}
        </Box>
      );
    },
    [checkbox]
  );

  return (
    <AutoCompleteEnhanced
      id={id}
      label={label}
      multiple={multiple}
      options={options || []}
      value={value}
      onChange={onChange}
      hasError={hasError}
      errorText={errorText}
      renderOption={renderOption}
      {...props}
    />
  );
};

CountrySelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  checkbox: PropTypes.bool,
  variant: PropTypes.bool,
  hasError: PropTypes.bool,
  errorText: PropTypes.string
};

export default CountrySelect;
