// components.application.rewards.achievementCard.achievementStatus

export const ACHIEVEMENT_STATUS = {
  common: {
    goToTask: 'GO TO COMPLETE TASK',
    close: 'CLOSE'
  },
  complete: {
    claim: 'CLAIM REWARD',
    claimed: 'Claimed'
  },
  inProgress: {
    inProgress: 'In Progress',
    progressBar: '{{percent}}% to complete'
  },
  locked: {
    locked: 'Locked',
    description: 'Increase your security posture to obtain your first badge to unlock this achievement.'
  },
  notStarted: {
    notStarted: 'Not started'
  }
};
