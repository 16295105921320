const OAUTH_PERMISSIONS = {
  GITHUB: [
    { label: 'Organizations', description: 'Provides a list of organization for the authenticated user' },
    { label: 'Repositories', description: 'Shows the repositores for the organization' },
    { label: 'Packages', description: 'Identifies the packages used by the repositories' }
  ],
  SLACK: [
    { label: 'Content and info', description: 'View people in your workspace to mention and tag' },
    { label: 'Action/Commands', description: 'Add shortcuts and/or slash commands that people can use' },
    { label: 'Webhook channel', description: 'A channel to post to as an application to keep users upto date' }
  ]
};

const OAUTH_STATES = {
  ATTEMPTED: 'ATTEMPTED',
  FAILED: 'FAILED'
};

const TRANSLATION_PATH = (route) => {
  return `components.application.integrations.connectIntegrationModal.addApplication.oAuth.${route}`;
};

export { OAUTH_PERMISSIONS, OAUTH_STATES, TRANSLATION_PATH };
