import { useQuery } from '@apollo/client';
import { Card, CardContent, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fragment, useMemo, useState } from 'react';
import { COUNT_PROJECTS, GET_PROJECTS } from '../../../../api';
import { NoIntegrations } from '../../../../components/application';
import { ProjectCard } from '../../../../components/application/reports/sbom';
import { DotLoader, TypographyEnhanced } from '../../../../components/global';
import { Banner, Heading, PaginationEnhanced } from '../../../../components/ui';
import { DEFAULT_PAGE_SIZE } from '../../../../components/ui/pagination/constants';
import { useOrgContext } from '../../../../contexts';
import { TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const SBOM = () => {
  const classes = useStyles();

  const { orgId } = useOrgContext();

  const {
    data: projectCountData,
    loading: projectCountLoading,
    error: projectCountError
    // refetch: refetchProjectCount
  } = useQuery(COUNT_PROJECTS, {
    variables: { where: { org: orgId } },
    skip: !orgId
  });

  const projectCount = useMemo(() => {
    if (projectCountLoading || projectCountError) {
      return 0;
    }
    return projectCountData?.countProjects || 0;
  }, [projectCountData?.countProjects, projectCountError, projectCountLoading]);

  const [start, setStart] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data: projectsData, loading: projectsLoading } = useQuery(GET_PROJECTS, {
    variables: { where: { org: orgId, _or: [{ archived: false }, { archived_null: true }] }, start, limit: pageSize, sort: 'created_at:DESC' },
    skip: !orgId
  });

  const projects = useMemo(() => {
    if (projectsLoading) {
      return [];
    }
    return projectsData?.projects || [];
  }, [projectsData?.projects, projectsLoading]);

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      {!projects?.length ? null : (
        <Banner>
          <TypographyEnhanced
            id={
              'As per your current subscription, access to full library modules is restricted to top-level only. Consider upgrading for comprehensive library access.'
            }
          />
        </Banner>
      )}
      <Heading id={TRANSLATION_PATH('title')} />
      {projectsLoading ? (
        <Grid container item justifyContent='center'>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Fragment>
          {projects?.length ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    {(projects || []).map((project) => {
                      const { id } = project || {};
                      return <ProjectCard key={id} project={project} />;
                    })}
                    <PaginationEnhanced total={projectCount} onChange={setStart} pageSize={pageSize} setPageSize={setPageSize} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <NoIntegrations link={'/app/integrations'} />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Fragment>
      )}
    </Container>
  );
};
export default SBOM;
