import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { TypographyEnhanced } from '../../../../../../../../components/global';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%'
  }
}));

const ThreatModellingColumnHeader = ({ title, subtitle, open, toggle }) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} onClick={toggle}>
      <Grid container>
        <Grid container item xs={12} alignItems='center' justifyContent='center'>
          <Grid item>
            <TypographyEnhanced id={title} display='inline-block' />
          </Grid>
          <Grid item>{open ? <ExpandLessRounded /> : <ExpandMoreRounded />}</Grid>
        </Grid>
        <Grid item xs={12}>
          <TypographyEnhanced id={subtitle} variant='caption' display='block' />
        </Grid>
      </Grid>
    </Button>
  );
};

ThreatModellingColumnHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  open: PropTypes.bool
};

export default ThreatModellingColumnHeader;
