import { useQuery } from '@apollo/client';
import { Button, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_FRAMEWORK_SCORE_TOTALS } from '../../../../../api';
import { useOrgContext } from '../../../../../contexts';
import { logEvent } from '../../../../../library';
import { DASHBOARD as DASHBOARD_EVENTS } from '../../../../../library/amplitude/events/views/app/dashboard';
import { DotLoader, TypographyEnhanced } from '../../../../global';
import { ProgressChart } from '../../../../ui';
import { DEfAULT_CHART_OPTIONS } from '../../summaries/progress/constants';

const useStyles = makeStyles(() => ({
  card: {
    height: '135px',
    textAlign: 'left'
  }
}));

const CyberAssessment = () => {
  const classes = useStyles();

  const { orgId, org } = useOrgContext();

  const {
    data: scoreData,
    loading: scoreLoading,
    error: scoreError
  } = useQuery(GET_FRAMEWORK_SCORE_TOTALS, {
    variables: { org: orgId, framework: org?.framework?.id },
    skip: !orgId || !org?.framework?.id,
    nextFetchPolicy: 'network-only'
  });

  const scoreTotals = useMemo(() => {
    if (scoreLoading || scoreError) {
      return null;
    }
    return scoreData?.frameworkScoreTotals || {};
  }, [scoreData?.frameworkScoreTotals, scoreError, scoreLoading]);

  const [options, setOptions] = useState(DEfAULT_CHART_OPTIONS);
  const navigate = useNavigate();

  const onChartClick = () => {
    logEvent(DASHBOARD_EVENTS.CYBER_ASSESSMENT_CLICKED);
    navigate('/app/assessment/cyber', { state: { filter: { STATUS: { STATUS_CHECKBOX: ['NOT_ANSWERED'] } } } });
  };

  useEffect(() => {
    if (!scoreTotals) {
      return;
    }

    setOptions((options) => {
      const optionsCopy = _.cloneDeep(options);
      optionsCopy.data[0].value = Math.round(scoreTotals?.answeredPercentage);
      return optionsCopy;
    });
  }, [scoreTotals]);

  const CardButton = (props) => {
    return <Button {...props} fullWidth />;
  };

  return (
    <Card className={classes.card} component={CardButton} onClick={() => onChartClick()}>
      <CardContent sx={{ height: '100%', width: '100%' }}>
        <Grid container>
          <Grid item xs={8}>
            <TypographyEnhanced id={'Cyber Assessment'} sx={{ fontWeight: 'bold' }} />
            <TypographyEnhanced id={'Build trust with your customers and regulators.'} variant='caption' />
          </Grid>
          <Grid container item xs={4} sx={{ height: '100px' }}>
            <Grid item xs={12} sx={{ height: '100px' }}>
              {scoreLoading ? (
                <DotLoader sx={{ position: 'relative', alignItems: 'center', height: 80 }} color='primary' />
              ) : (
                <ProgressChart
                  options={options}
                  onEvents={{
                    click: onChartClick
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CyberAssessment;
