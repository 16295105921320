import { gql } from '@apollo/client';

export const COUNT_NEWS_ITEMS = gql`
  query countNewsItems($where: JSON!) {
    countNewsItems(where: $where)
  }
`;

export const GET_NEWS_ITEMS = gql`
  query getNewsItems($where: JSON, $start: Int, $limit: Int, $sort: String) {
    newsitems(where: $where, start: $start, limit: $limit, sort: $sort) {
      id
      title
      description
      link
      displayLink
      imageUrl
      author
      createdAt
      thirdparty {
        id
        name
        logoUrl
      }
    }
  }
`;
