import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { animated, useSpring } from 'react-spring';

const CONFIG = {
  duration: 1500
};

/**
 *
 * @param {String} prefix: character(s) before the number
 * @param {String} suffix: character(s) after the number
 * @param {String} from: animation from
 * @param {String} to: animation to
 * @param {String} decimals: number of decimal places
 * @param {JSON} config: react-spring configuration
 * @param {String} reset: reset animation
 * @param {String} className: class-name
 * @param {String} variant: typography text variant
 * @returns
 */

const AnimatedNumber = ({
  prefix = '',
  suffix = '',
  from = 0,
  to = 0,
  decimals = 0,
  config = CONFIG,
  reset = false,
  className,
  variant = 'body1',
  ...props
}) => {
  const [flip, setFlip] = useState(false);
  const { number } = useSpring({
    reset,
    reverse: flip,
    from: { number: from },
    number: to,
    delay: 200,
    config,
    ...(reset && {
      onRest: () => setFlip(!flip)
    })
  });

  return (
    <Typography variant={variant} className={className} {...props}>
      {prefix}
      <animated.span>{number.to((n) => n.toFixed(decimals))}</animated.span>
      {suffix}
    </Typography>
  );
};

AnimatedNumber.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  from: PropTypes.number,
  to: PropTypes.number,
  decimals: PropTypes.number,
  config: PropTypes.object,
  reset: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string
};

export default AnimatedNumber;
