import { Card, CardContent, CssBaseline, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { BusinessInfo, ConnectGsuite } from '../../../components/application';
import { usePageViewContext } from '../../../contexts/logPageViewContext';
import { logEvent } from '../../../library';
import { ONBOARDING as ONBOARDING_EVENTS } from '../../../library/amplitude/events/views/app/onboarding';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  container: {
    maxWidth: '600px'
  }
}));

const Onboarding = () => {
  const classes = useStyles();

  const { pageViewLogged, setPageViewLogged } = usePageViewContext();

  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (pageViewLogged) {
      return;
    }
    logEvent(ONBOARDING_EVENTS.PAGE_VISITED);
    setPageViewLogged(true);
  }, [pageViewLogged, setPageViewLogged]);

  const steps = {
    1: <BusinessInfo setCurrentStep={setCurrentStep} />,
    2: <ConnectGsuite />
  };

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Card className={classes.container}>
        <CardContent>{steps[currentStep]}</CardContent>
      </Card>
    </Grid>
  );
};
export default Onboarding;
