import { AppBar, Box, Card, CardContent, Container, Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonEnhanced } from '../../../components/global';
import { Image } from '../../../components/ui';
import Logo from '../../../logogg.svg';

const useStyles = makeStyles(() => ({
  container: {
    height: '100vh',
    padding: '0'
  },
  rightMain: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px'
  },
  leftMain: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  leftSub: {
    marginTop: '10px',
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Container className={classes.container} maxWidth={false}>
      <AppBar color='transparent' elevation={0}>
        <Toolbar>
          <Image src={Logo} alt='gg' style={{ height: '30px', margin: '0 20px' }} />
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            CyberHQ Essentials V0.0.1
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container item xs={12} style={{ height: '100%' }}>
        <Grid container item xs={12} sm={7} style={{ height: '100%' }} direction='column' justifyContent='center' alignItems='center'>
          <Grid item className={classes.leftMain}>
            <Box>
              <Typography variant='h4'>Your platform to review cyber resilience, effortlessly.</Typography>
              <Typography variant='h5'>Whether it&apos;s your company or another</Typography>
            </Box>
          </Grid>
          <Grid container className={classes.leftSub}>
            <Grid item xs={12} sm={5} style={{ marginTop: '20px' }}>
              <Card>
                <CardContent>
                  <Typography variant='body1'>
                    Would you like to <b>create</b> a cyber assessment?
                  </Typography>
                  <ButtonEnhanced onClick={() => navigate('/user/register')}>Sign up</ButtonEnhanced>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={5} style={{ marginTop: '20px' }}>
              <Card>
                <CardContent>
                  <Typography variant='body1'>
                    Would you like to <b>view</b> a cyber assessment?
                  </Typography>
                  <ButtonEnhanced onClick={() => navigate('/user/login')}>Log in</ButtonEnhanced>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} className={classes.rightMain}>
          <Card style={{ width: '100%', height: '50%' }}>
            <CardContent>
              <iframe
                width='100%'
                height='315'
                src='https://www.youtube.com/embed/C8ZwEbhrco0?controls=0'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              ></iframe>
              <Typography variant='body1'>How it works</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
export default Home;
