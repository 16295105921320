import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { GET_USERS, GET_USERS_RESTRICTED, orgVar } from '../../api';

const UsersContext = createContext();

/**
 *
 * @returns {{
 * users: (JSON),
 * activeUsers: (JSON),
 * refetch: (variables?: Partial<{user: *}>) => Promise<ApolloQueryResult<any>>,
 * loading: boolean,
 * error: ApolloError,
 * }}
 */
export const useUsersContext = () => {
  return useContext(UsersContext);
};

const UsersProvider = ({ children }) => {
  const orgId = orgVar();

  //admin user can hit this api
  const {
    data: usersData,
    error,
    loading,
    refetch
  } = useQuery(GET_USERS, {
    variables: {
      where: {
        org: orgId
        // TODO: uncomment to discard archived users
        // _or: [{ archived_null: true }, { archived: false }]
      }
    }
  });

  //otherwise default user will hit this api
  //returns only id, firstName, lastName, email and imageUrl for users
  const {
    data: usersRestrictedData,
    error: usersRestrictedError,
    loading: usersRestrictedLoading,
    refetch: usersRestrictedRefetch
  } = useQuery(GET_USERS_RESTRICTED, {
    variables: {
      where: {
        org: orgId
        // TODO: uncomment to discard archived users
        // _or: [{ archived_null: true }, { archived: false }]
      }
    },
    skip: loading || !error || usersData?.users
  });

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!usersData?.users?.length && !usersRestrictedData?.usersRestricted?.length) {
      return;
    }
    const users = usersData?.users || usersRestrictedData?.usersRestricted;
    setUsers(users);
  }, [usersData?.users, usersRestrictedData?.usersRestricted]);

  const value = {
    users,
    activeUsers: (users || []).filter((user) => !user.archived && !user.blocked),
    error: usersRestrictedData?.usersRestricted?.length ? usersRestrictedError : error,
    loading: loading || usersRestrictedLoading,
    refetch: usersRestrictedData?.usersRestricted?.length ? usersRestrictedRefetch : refetch
  };

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

UsersProvider.propTypes = {
  children: PropTypes.element
};

export default UsersProvider;
