// EVENTS.VIEWS.APP.MANAGE.USERS

export const USERS = {
  PAGE_VISITED: 'manage_users-page_visited',
  VIEW_USER: 'manage_users-view_single_user',
  ADD_USER: 'manage_users-add_user',
  ADD_USER_MODAL: 'manage_users-add_user_modal_opened',
  SEARCH: 'manage_users-search',
  FILTER: 'manage_users-filter',
  BACK_BUTTON: 'manage_users-back_button',
  NEXT_BUTTON: 'manage_users-next_button',
  COOKIE_TRAIL: 'manage_users-cookie_trail'
};
