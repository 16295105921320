import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import ManualEditDevice from '../../../../../components/application/manage/devices/manualEditDevice';
import { CardWithLoader, DotLoader } from '../../../../../components/global';
import { Heading } from '../../../../../components/ui';
import { useDevicesContext } from '../../../../../contexts';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const EditDevice = () => {
  const classes = useStyles();

  const { id: deviceId } = useParams();
  const { devices, loading: devicesLoading } = useDevicesContext();
  const device = devices.find((device) => device.id === deviceId);
  const { model, type, version } = device || {};

  const deviceName = `${model} ${type} ${version}`;

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      {devicesLoading ? (
        <DotLoader />
      ) : (
        <Fragment>
          <Heading id={deviceName || ''} />
          <CardWithLoader>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ManualEditDevice />
              </Grid>
            </Grid>
          </CardWithLoader>
        </Fragment>
      )}
    </Container>
  );
};

export default EditDevice;
