import PropTypes from 'prop-types';
import { ISSUE_RELATION_OPTIONS } from '../constants';

const IssueLogo = ({ relation, ...props }) => {
  let Icon;
  if (!relation) {
    Icon = ISSUE_RELATION_OPTIONS.NONE.icon;
  } else {
    Icon = ISSUE_RELATION_OPTIONS[relation].icon;
  }
  return <Icon fontSize='medium' {...props} />;
};

IssueLogo.propTypes = {
  relation: PropTypes.string
};

export default IssueLogo;
