import { gql } from '@apollo/client';

export const GET_STATUSES = gql`
  query statuses {
    statuses {
      id
      name
      value
    }
  }
`;
