import { logEvent } from '../../../../../../library';
import { DASHBOARD as DASHBOARD_EVENTS } from '../../../../../../library/amplitude/events/views/app/dashboard';

/**
 *  Logs chart click event and navigates user
 *
 * @param {Object} event
 * @param {VoidFunction} navigate
 */
const logChartClick = (event, navigate) => {
  const { name, seriesName } = event || {};
  logEvent(DASHBOARD_EVENTS.CLOUD_INFRASTRUCTURE_CHART_CLICK, { category_name: name, category_status: seriesName });
  navigate('/app/reports/cloud-infrastructure');
  window.scrollTo(0, 0);
};

/**
 * Logs event for user clicking legend items to select/deselect
 *
 * @param {Object} event
 */
const logLegendChange = (event) => {
  const { selected } = event || {};

  const activeLegends = [];

  for (const key in selected) {
    if (!selected[key]) {
      continue;
    }
    activeLegends.push(key);
  }

  logEvent(DASHBOARD_EVENTS.CLOUD_INFRASTRUCTURE_CHART_LEGEND, { active_legends: activeLegends });
};

/**
 * Logs view all button click event and navigates user
 *
 * @param {VoidFunction} navigate
 */
const logViewall = (navigate) => {
  logEvent(DASHBOARD_EVENTS.CLOUD_INFRASTRUCTURE_VIEW_ALL);
  navigate('/app/reports/cloud-infrastructure');
};

export { logChartClick, logLegendChange, logViewall };
