import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_APPLICATIONS } from '../../api';
import useOrgData from '../useOrgData';

/**
 * Custom hook to encapsulate GET_APPLICATIONS
 * usage example:
 *
 * const { applications, loading, error, refetch } = useApplications()
 *
 * @param {JSON} where
 * @param {String} sort
 * @param {Number} start
 * @param {Number} limit
 * @param {Boolean} skip
 * @returns {{
 * applications: Array,
 * loading: Boolean,
 * Error: ApolloError,
 * refetch: (variables?: Partial<{application: *}>) => Promise<ApolloQueryResult<any>>
 * }}
 */
const useApplications = (where, sort = 'name:ASC', start, limit, skip, fetchPolicy) => {
  //Get orgId so we only pull applications from the one org
  const { orgId, loading: orgLoading, error: orgError } = useOrgData();

  //Fetch all applications from org
  const { data, loading, error, refetch } = useQuery(GET_APPLICATIONS, {
    variables: {
      where: {
        _or: [{ archived: false }, { archived_null: true }],
        org: orgId,
        ...(where || {})
      },
      start,
      limit,
      sort
    },
    skip: !orgId || skip,
    fetchPolicy
  });

  const applications = useMemo(() => {
    if (loading || error) {
      return [];
    }

    return data?.applications || [];
  }, [data?.applications, error, loading]);

  return {
    applications,
    loading: orgLoading || loading,
    error: orgError || error,
    refetch
  };
};

export default useApplications;
