import { useTheme } from '@emotion/react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import defaultOptions from './defaultOptions';

const ProgressChart = ({ options, barColor, textColor, onEvents = {}, style }) => {
  const theme = useTheme();
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (!options) {
      return;
    }
    const { palette } = theme || {};
    const { primary, text } = palette || {};

    const newOptions = _.cloneDeep(defaultOptions);

    const series = newOptions.series[0];
    series.data = options.data;
    series.axisLine = options.axisLine;
    series.itemStyle = {
      color: barColor ? barColor : primary?.main
    };
    series.detail = { ...series.detail, color: textColor ? textColor : text?.primary };

    setChartOptions(newOptions);
  }, [barColor, options, textColor, theme]);

  return <ReactEcharts option={chartOptions} style={{ height: 'inherit', pointerEvents: 'none', ...(style || {}) }} onEvents={onEvents} />;
};

ProgressChart.propTypes = {
  options: PropTypes.object,
  barColor: PropTypes.string,
  textColor: PropTypes.string,
  onEvents: PropTypes.object,
  style: PropTypes.object
};

export default ProgressChart;
