import { CRITICALITIES, STATUSES } from '@anirudhm9/base-lib/lib/constants';
import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_ISSUES_BREAKDOWN } from '../../../../../api';
import { useOrgContext } from '../../../../../contexts';
import { useCriticalities, useStatuses } from '../../../../../hooks';
import { ButtonEnhanced, TypographyEnhanced } from '../../../../global';
import { WidgetContainer } from '../../../../ui';
import { FILTERS } from '../../../manage/issues/filter/constants';
import AcceptedRisks from './acceptedRisks';
import CRITICALITIES_TO_EXCLUDE from './constants';
import UnaddressedRisks from './unaddressedRisks';

const IssueStatistics = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { orgId } = useOrgContext();
  const { statuses, loading: statusesLoading, error: statusesError } = useStatuses();
  const { criticalities, loading: criticalitiesLoading } = useCriticalities();

  const acceptedStatus = useMemo(() => {
    if (statusesLoading || statusesError) {
      return {};
    }
    return (statuses || []).find((status) => status.value === STATUSES.ACCEPTED.value);
  }, [statuses, statusesError, statusesLoading]);

  const openStatus = useMemo(() => {
    if (statusesLoading || statusesError) {
      return {};
    }
    return (statuses || []).find((status) => status.value === STATUSES.OPEN.value);
  }, [statuses, statusesError, statusesLoading]);

  const {
    data: acceptedIssuesBreakdownData,
    loading: acceptedIssuesBreakdownLoading,
    error: acceptedIssuesBreakdownError
  } = useQuery(GET_ISSUES_BREAKDOWN, {
    variables: { where: { org: orgId, _or: [{ archived: false }, { archived_null: true }], status: acceptedStatus?.id } },
    skip: !orgId || statusesLoading || !acceptedStatus?.id
  });

  const {
    data: openIssuesBreakdownData,
    loading: openIssuesBreakdownLoading,
    error: openIssuesBreakdownError
  } = useQuery(GET_ISSUES_BREAKDOWN, {
    variables: { where: { org: orgId, _or: [{ archived: false }, { archived_null: true }], status: openStatus?.id } },
    skip: !orgId || statusesLoading || !openStatus?.id
  });

  const getOptions = useCallback(
    (type) => {
      if (acceptedIssuesBreakdownLoading || acceptedIssuesBreakdownError || openIssuesBreakdownLoading || openIssuesBreakdownError) {
        return {};
      }

      const issuesBreakdown = type === STATUSES.OPEN.value ? openIssuesBreakdownData?.getIssuesBreakdown : acceptedIssuesBreakdownData?.getIssuesBreakdown;
      const { criticality = {} } = issuesBreakdown || {};
      const criticalityValuesAll = Object.keys(criticality || {}) || [];
      const criticalityValues = criticalityValuesAll.filter((allCriticalityValue) => !CRITICALITIES_TO_EXCLUDE.includes(allCriticalityValue));
      const options = {
        xAxis: {
          type: 'value'
        },
        yAxis: {
          axisLabel: {
            color: theme.palette?.text?.primary
          },
          type: 'category',
          data: criticalityValues.map((key) => CRITICALITIES?.[key]?.name || '')
        },
        data:
          criticalityValues.map((key) => {
            const { color } = CRITICALITIES?.[key] || {};

            const value = criticality?.[key] || 0;
            return {
              value,
              key,
              itemStyle: { color: theme.palette?.[color]?.main }
            };
          }) || []
      };

      return options || {};
    },
    [
      acceptedIssuesBreakdownData?.getIssuesBreakdown,
      acceptedIssuesBreakdownError,
      acceptedIssuesBreakdownLoading,
      openIssuesBreakdownData?.getIssuesBreakdown,
      openIssuesBreakdownError,
      openIssuesBreakdownLoading,
      theme.palette
    ]
  );

  const onChartClick = useCallback(
    (event, type) => {
      const { data = {} } = event || {};
      const { key } = data || {};
      const { id: criticalityId } = (criticalities || []).find((criticality) => criticality.value === key);

      const statusId = type === STATUSES.OPEN.value ? openStatus?.id : acceptedStatus?.id;

      if (!criticalityId || !statusId || !FILTERS?.CRITICALITY?.filters?.[0]?.id) {
        return;
      }

      const filter = {
        [FILTERS?.CRITICALITY.id]: {
          [FILTERS.CRITICALITY.filters[0].id]: [criticalityId]
        },
        [FILTERS?.STATUS.id]: {
          [FILTERS.STATUS.filters[0].id]: [statusId]
        }
      };
      //navigate and set default filter
      navigate('/app/manage/issues', { state: { filter } });
    },
    [acceptedStatus?.id, criticalities, navigate, openStatus?.id]
  );

  return (
    <WidgetContainer>
      <Grid container item>
        <Grid item xs={12} md={6}>
          <UnaddressedRisks
            options={getOptions(STATUSES.OPEN.value)}
            loading={statusesLoading || criticalitiesLoading}
            onClick={(event) => onChartClick(event, STATUSES.OPEN.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AcceptedRisks
            options={getOptions(STATUSES.ACCEPTED.value)}
            loading={statusesLoading || criticalitiesLoading}
            onClick={(event) => onChartClick(event, STATUSES.ACCEPTED.value)}
          />
        </Grid>
        <Grid container justifyContent='flex-end' item>
          <ButtonEnhanced variant='outlined' size='small' onClick={() => navigate('/app/manage/issues')}>
            <TypographyEnhanced id='VIEW ALL' />
          </ButtonEnhanced>
        </Grid>
      </Grid>
    </WidgetContainer>
  );
};

IssueStatistics.propTypes = {
  test: PropTypes.string
};

export default IssueStatistics;
