import { useEffect, useState } from 'react';
import { useUserContext } from '../../contexts';
import useWindowDimensions from '../useWindowDimensions';

/* sample confetti
        <Confetti
          width={width}
          height={height}
          confettiSource={{
            w: 10,
            h: 10,
            x: cursor.x,
            y: cursor.y
          }}
          gravity={0.2}
          initialVelocityX={13}
          initialVelocityY={13}
          numberOfPieces={pop ? 50 : 0}
          recycle={false}
          onConfettiComplete={(confetti) => {
            // setPop(false);
            confetti.reset();
          }}
        />
/> */

const useRajivSanity = () => {
  const [pop, setPop] = useState(false);
  const [cursor, setCursor] = useState({ x: 0, y: 0 });

  const { user } = useUserContext();
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if (user.email !== 'rajivfaleiro@avertro.com' || localStorage.getItem('imNotRajiv')) {
      return;
    }

    window.addEventListener('click', (event) => {
      const { clientX, clientY } = event || {};
      setCursor({
        x: clientX,
        y: clientY
      });
      setPop(true);
      return;
    });

    setTimeout(() => setPop(false), 500);
  }, [pop, user.email]);

  return { pop, width, height, cursor };
};
export default useRajivSanity;
