import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ChipEnhanced, TooltipEnhanced } from '../../../../global';

/**
 * @typedef {import('@mui/material').ChipProps} ChipProps
 * @param {{name: String, color: String}} item
 * @param {JSX} icon
 * @param {String} title
 * @param {Boolean} tooltip
 * @param {{sx: JSON} & ChipProps} props
 * @returns {React.FC<ChipProps>}
 */
const IssueChip = ({ item, icon, tooltip = false, title = '', sx, ...props }) => {
  const theme = useTheme();
  const [color, setColor] = useState('');
  const [tooltipTitle, setTooltipTitle] = useState('');

  useEffect(() => {
    const { color = '', name = '' } = item || {};
    setColor(theme.palette?.[color]?.main || '');
    setTooltipTitle(title || name);
  }, [item, theme.palette, title]);

  if (!tooltip) {
    return <ChipEnhanced id={item?.name || ''} variant='outlined' sx={{ color, ...(sx || {}) }} icon={icon} {...props} />;
  }
  return (
    <TooltipEnhanced title={tooltipTitle}>
      <ChipEnhanced id={item?.name || ''} variant='outlined' sx={{ color, ...(sx || {}) }} icon={icon} {...props} />
    </TooltipEnhanced>
  );
};

IssueChip.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    color: PropTypes.string
  }),
  icon: PropTypes.any,
  tooltip: PropTypes.bool,
  title: PropTypes.string,
  sx: PropTypes.object
};

export default IssueChip;
