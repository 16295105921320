/**
 * Sums the users and inactive users of app, returns total user count
 * @param {Object} app
 * @returns {Integer}
 */
const getApplicationUserCount = (app) => {
  if (!app || (!app.users && !app.inactiveUsers)) {
    return 0;
  }
  const { users, inactiveUsers } = app || {};
  const totalUsers = users?.length + inactiveUsers?.length;

  return totalUsers;
};

module.exports = {
  getApplicationUserCount
};
