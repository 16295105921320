const { EMPTY_STATE } = require('./emptyState');
const { REWARDS } = require('./rewards');
const { ICONS } = require('./icons');
const { GENERAL } = require('./general');

module.exports = {
  EMPTY_STATE,
  REWARDS,
  ICONS,
  GENERAL
};
