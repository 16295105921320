export const invalidEmail = 'Invalid Email address';

export const invalidDomain = 'Invalid Email domain';

export const invalidLogin = 'Invalid Email or Password';

export const requiredFields = 'Required fields cannot be empty';

export const passwordCompliance =
  'Password too simple. Password should have at least:\n• 12 characters\n• 1 uppercase letter\n• 1 lowercase letter\n• 1 digit\n• 1 special character.';

export const defaultSuccess = 'Saved successfully';

export const defaultError = 'There was an error saving your data';

export const passwordMismatch = 'Passwords do not match';

export const conditionsNotAccepted = 'You must accept the T&Cs and Privacy Policy to continue';

export const invalidInput = 'Invalid input.';

export const invalidFields = 'Some fields require attention.';

export const errorUpload = 'There was an error uploading your file';
