import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { TypographyEnhanced } from '../../../../../../../../../global';

const EmptyControls = ({ title = 'No contols found.' }) => {
  return (
    <Grid container item xs={12}>
      <TypographyEnhanced id={title} />
    </Grid>
  );
};
EmptyControls.propTypes = {
  title: PropTypes.string
};
export default EmptyControls;
