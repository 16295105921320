import { useTheme } from '@mui/styles';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DEFAULT_OPTIONS } from './constants';

const ColumnChart = ({ options, onEvents }) => {
  const theme = useTheme();

  const [chartOptions, setChartOptions] = useState();

  useEffect(() => {
    if (!options) {
      return;
    }

    const { palette } = theme || {};
    const { background, text } = palette || {};
    const defaultOptions = _.cloneDeep(DEFAULT_OPTIONS);

    const {
      legend: defaultLegend,
      tooltip: defaultTooltip,
      toolbox: defaultToolbox,
      xAxis: defaultXAxis = [],
      yAxis: defaultYAxis = []
    } = defaultOptions || {};

    const { legend, tooltip, toolbox, xAxis = [], yAxis = [], ...rest } = options || {};

    defaultOptions.tooltip = {
      ...defaultTooltip,
      backgroundColor: background?.default,
      textStyle: { color: text?.primary },
      ...(tooltip || {})
    };

    defaultOptions.legend = {
      ...defaultLegend,
      textStyle: { color: text?.primary },
      ...(legend || {})
    };

    defaultOptions.toolbox = {
      ...defaultToolbox,
      ...(toolbox || {})
    };

    defaultOptions.xAxis = (defaultXAxis || []).map((x, index) => {
      const newX = xAxis[index] || {};
      return {
        ...(x || {}),
        axisLabel: {
          color: text?.primary
        },
        ...newX
      };
    });

    defaultOptions.yAxis = (defaultYAxis || []).map((y, index) => {
      const newY = yAxis[index] || {};
      return {
        ...(y || {}),
        axisLabel: {
          color: text?.primary
        },
        ...newY
      };
    });

    setChartOptions({ ...(defaultOptions || {}), ...(rest || {}) });
  }, [options, theme]);

  if (!chartOptions) {
    return null;
  }

  return <ReactEcharts option={chartOptions} onEvents={onEvents} />;
};

ColumnChart.propTypes = {
  options: PropTypes.object,
  onEvents: PropTypes.object
};

export default ColumnChart;
