import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ButtonEnhanced, DrawerEnhanced, TypographyEnhanced } from '../../../../../components/global';
import { useThreatModellingContext } from '../../../../../contexts';
import UsersProvider from '../../../../../contexts/usersContext';
import SingleCategory from '../../cyber/singleCategory';

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    maxWidth: '60vw',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw'
    },
    height: '100%',
    padding: theme.spacing(2)
  }
}));

const ThreatModellingCategoryModal = ({ id, setId }) => {
  const classes = useStyles();
  const { selectedCategoryId, setSelectedCategoryId, refetchCategory } = useThreatModellingContext();

  const categoryId = useMemo(() => {
    return selectedCategoryId || id;
  }, [id, selectedCategoryId]);

  const toggle = () => {
    try {
      setSelectedCategoryId && setSelectedCategoryId();
      setId && setId();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <UsersProvider>
      <DrawerEnhanced
        open={!!categoryId}
        toggle={toggle}
        title={<TypographyEnhanced id={'Test'} variant='h5' />}
        header={false}
        containerProps={{
          className: classes.drawerContainer
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            {categoryId && <SingleCategory id={categoryId} refetch={refetchCategory} />}
          </Grid>
          <Grid container item xs={12} justifyContent='flex-end'>
            <ButtonEnhanced onClick={toggle} variant='outlined'>
              Cancel
            </ButtonEnhanced>
          </Grid>
        </Grid>
      </DrawerEnhanced>
    </UsersProvider>
  );
};

ThreatModellingCategoryModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setId: PropTypes.func
};

export default ThreatModellingCategoryModal;
