import { gql } from '@apollo/client';

export const GET_COMPANY_SIZES = gql`
  query companysizes {
    companysizes(sort: "employeeMin:ASC", where: { type_in: ["MICRO", "SMALL"] }) {
      id
      name
    }
  }
`;
