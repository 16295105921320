import { SwipeableDrawer as Drawer } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

const DRAWER_HEIGHT = 25;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default
  },
  puller: {
    width: 30,
    height: 6,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)'
  },
  styledBox: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
    position: 'absolute',
    top: -DRAWER_HEIGHT,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    visibility: 'visible',
    right: 0,
    left: 0
  }
}));

const SwipeableDrawer = ({ anchor = 'bottom', open = false, setOpen = () => {}, children, ...props }) => {
  const classes = useStyles();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box className={classes.root}>
      <Drawer
        PaperProps={{
          sx: {
            height: `calc(90% - ${DRAWER_HEIGHT}px)`,
            overflow: 'visible'
          }
        }}
        anchor={anchor}
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        swipeAreaWidth={DRAWER_HEIGHT}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true
        }}
        {...props}
      >
        {open && (
          <Box className={classes.styledBox}>
            <Box className={classes.puller} />
          </Box>
        )}
        <Box
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto'
          }}
        >
          {children}
        </Box>
      </Drawer>
    </Box>
  );
};

SwipeableDrawer.propTypes = {
  anchor: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.any
};

export default SwipeableDrawer;
