import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_CUSTOM_TEMPLATES, GET_DEFAULT_TEMPLATES } from '../../api';
import { useOrgContext } from '../../contexts';

const useScenarioTemplates = (selectedFramework, custom, { start, limit, sort } = {}, skip) => {
  const { orgId } = useOrgContext();

  const { data, loading, error, refetch } = useQuery(custom ? GET_CUSTOM_TEMPLATES : GET_DEFAULT_TEMPLATES, {
    variables: {
      frameworkValue: selectedFramework?.value,
      org: orgId,
      start,
      limit,
      sort
    },
    skip: !selectedFramework?.value || skip,
    fetchPolicy: 'network-only'
  });

  const templates = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return custom ? data?.getCustomTemplates : data?.getDefaultTemplates || [];
  }, [loading, error, custom, data?.getCustomTemplates, data?.getDefaultTemplates]);

  return {
    templates,
    loading,
    error,
    refetch
  };
};

export default useScenarioTemplates;
