import { Container, CssBaseline, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { AchievementCard } from '../../../../components/application';
import RewardFilter from '../../../../components/application/rewards/filter';
import { Heading, PointsSummary } from '../../../../components/ui';
import { filterOptions, TRANSLATION_PATH } from './constants';

// TODO get the real data
import { dummyAcheivements } from './dummyData';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  bold: {
    fontWeight: 'bold'
  }
}));

const Earn = () => {
  const classes = useStyles();

  const [achievements, setAchievements] = useState();
  const [filters, setFilters] = useState();

  useEffect(() => {
    const allAchievements = dummyAcheivements.filter((a) => !a.archived);
    const currentRewards = [];

    if (!_.isEmpty(filters)) {
      // TODO all of this needs to filter through the backened https://strapi.gitee.io/documentation/3.0.0-alpha.x/guides/filters.html#available-operators
      const filterKeys = Object.keys(filters);
      filterKeys.forEach((option) => {
        allAchievements.forEach((ach) => {
          let shouldReturn;
          switch (option) {
            case 'PROGRESSION':
              if (filters[option].includes('IN_PROGRESS') && ach.percentComplete > 0) {
                shouldReturn = true;
              }

              if (filters[option].includes('NOT_STARTED') && ach.percentComplete === 0) {
                shouldReturn = true;
              }
              if (filters[option].includes('COMPLETED') && ach.percentComplete === 100) {
                shouldReturn = true;
              }

              break;
            default:
              shouldReturn = true;
              break;
          }
          return shouldReturn && currentRewards.push(ach);
        });
      });
    }

    setAchievements(() => {
      return _.isEmpty(currentRewards) ? allAchievements : _.uniq(currentRewards);
    });
  }, [filters]);

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title')} description={TRANSLATION_PATH('description')} />
      <CssBaseline />
      <Grid container component='main' spacing={2}>
        <Grid item xs={12}>
          <PointsSummary />
        </Grid>
        <Grid item xs={12} container justifyContent='flex-end'>
          <Grid item>
            <RewardFilter filterTypes={filterOptions} updateFilters={setFilters} />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          {achievements?.map((item, i) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={i}>
                <AchievementCard data={item} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Container>
  );
};

Earn.propTypes = {};

export default Earn;
