import { Grid } from '@mui/material';
import React from 'react';
import { useLogEvent } from '../../../../../../hooks';
import { TypographyEnhanced } from '../../../../../global';
import AccordionEnhanced from '../../../../../ui/accordion';
import { LOG_EVENT_PATH } from './constants/defaults';

// TODO add real data in

const RewardFaq = () => {
  useLogEvent(LOG_EVENT_PATH('TAB_VIEWED'));

  /* eslint-disable quotes */
  const allFaqs = [
    { title: 'FAQ 1', question: "This is FAQ 1's question", answer: "The answer to FAQ 1's question" },
    { title: 'FAQ 2', question: "This is FAQ 2's question", answer: "The answer to FAQ 2's question" },
    { title: 'FAQ 3', question: "This is FAQ 3's question", answer: "The answer to FAQ 3's question" },
    { title: 'FAQ 4', question: "This is FAQ 4's question", answer: "The answer to FAQ 4's question" },
    { title: 'FAQ 5', question: "This is FAQ 5's question", answer: "The answer to FAQ 5's question" },
    { title: 'FAQ 6', question: "This is FAQ 6's question", answer: "The answer to FAQ 6's question" }
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {allFaqs?.map((faq, index) => (
          <AccordionEnhanced header={<TypographyEnhanced id={faq.question} variant='h6' />} key={index}>
            <TypographyEnhanced id={faq.answer} variant='body1' />
          </AccordionEnhanced>
        ))}
      </Grid>
    </Grid>
  );
};

export default RewardFaq;
