import { CloudCircleRounded, HubRounded, LanguageRounded, NewspaperRounded, TerminalRounded } from '@mui/icons-material';
import { experimentalMode } from '../../defaults';

const REPORTS = [
  experimentalMode && {
    id: 'reports_news',
    icon: <NewspaperRounded />,
    label: 'News',
    to: '/app/reports/news',
    viewEvent: 'news_board-page_visited'
  },
  experimentalMode && {
    id: 'reports_web_security_headers',
    icon: <LanguageRounded />,
    label: 'Web Security Headers',
    to: '/app/reports/web-security-headers',
    viewEvent: 'web_security_headers-page_visited'
  },
  {
    id: 'reports_cloud',
    icon: <CloudCircleRounded />,
    label: 'Cloud Infrastructure',
    to: '/app/reports/cloud-infrastructure',
    viewEvent: 'cloud_infrastructure-page_visited'
  },
  {
    id: 'reports_mapping',
    icon: <HubRounded />,
    label: 'Arc Reactor',
    to: '/app/reports/mapping',
    private: true,
    viewEvent: 'mapping-page_visited'
  },
  {
    id: 'reports_sbom',
    icon: <TerminalRounded />,
    label: 'SBOM',
    to: '/app/reports/sbom',
    private: false,
    viewEvent: 'sbom-page_visited'
  }
];
export default REPORTS;
