import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { getTotalFilters } from '../../../../../helpers/formatUtils';
import { DASHBOARD as DASHBOARD_EVENTS } from '../../../../../library/amplitude/events/views/app/dashboard';

import Filter from '../../../../ui/filter';
import FilterBody from '../../../../ui/filter/body';
import { FILTERS } from './constants';
import { filterAndSearchAssessment } from './utils';

const CyberAssessmentFilter = ({ category = {}, setCategory, defaultFilter = { STATUS: { STATUS_CHECKBOX: [] } } }) => {
  const [selectedFilters, setSelectedFilters] = useState(defaultFilter);

  const totalFilters = useMemo(() => getTotalFilters(selectedFilters || []), [selectedFilters]);

  const handleFilterAndSearch = useCallback(
    (searchTerm) => {
      const filteredCategory = filterAndSearchAssessment(category || {}, selectedFilters, searchTerm);
      setCategory(filteredCategory);
    },
    [category, selectedFilters, setCategory]
  );

  const onFilterReset = () => {
    setSelectedFilters({});
    setCategory(category);
  };

  return (
    <Filter
      onFilter={handleFilterAndSearch}
      onFilterReset={onFilterReset}
      onSearch={handleFilterAndSearch}
      onSearchReset={() => setCategory(category || {})}
      totalFilters={totalFilters}
      eventPath={DASHBOARD_EVENTS}
    >
      <Grid container item spacing={2}>
        <FilterBody filters={FILTERS} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
      </Grid>
    </Filter>
  );
};

CyberAssessmentFilter.propTypes = {
  category: PropTypes.object.isRequired,
  setCategory: PropTypes.func.isRequired,
  defaultFilter: PropTypes.object
};

export default CyberAssessmentFilter;
