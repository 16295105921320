import PropTypes from 'prop-types';
import { TextFieldEnhanced } from '../../../../../../../ui/form';

const TextField = ({ id, label, value, onChange, required, ...props }) => {
  return (
    <TextFieldEnhanced
      id={id}
      label={label}
      value={value || ''}
      onChange={(value) => onChange(value)}
      type='text'
      autoComplete='do-not-autofill'
      variant='outlined'
      fullWidth
      helperText=''
      required={required}
      {...props}
    />
  );
};

TextField.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.any,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool
};

export default TextField;
