// components.application.manage.viewUser.devicesTable

export const DEVICES_TABLE = {
  columnHeaders: {
    name: 'Name',
    email: 'Email',
    deviceModel: 'Device Model',
    type: 'Type',
    os: 'Operating System',
    deviceCompromisedStatus: 'Device Compromised Status',
    devicePasswordStatus: 'Device Password Status',
    deviceId: 'Device ID',
    encryptionStatus: 'Encryption Status',
    firstSync: 'First Synced',
    lastSync: 'Last Synced'
  }
};
