export const DEFAULT_ORG_DATA = {
  name: '',
  domain: '',
  industry: '',
  size: '',
  country: '',
  operations: [],
  websites: [{ link: 'https://' }]
};

export const REQUIRED_FIELDS = [
  'name',
  'industry',
  'size',
  'country',
  'operations'

  //TODO: uncomment
  // 'websites'
];

export const EMPLOYEE_OPTIONS = [
  { value: '1', label: '1-5' },
  { value: '2', label: '6-10' },
  { value: '3', label: '11-50' },
  { value: '4', label: '51-100' },
  { value: '5', label: '100+' }
];

export const COMPANY_SIZE_TYPES = [
  // For small businesses
  'MICRO',
  'SMALL'

  // big businesses
  // 'MEDIUM',
  // 'LARGE',
  // 'ENTERPRISE'
];

export const WEBSITE_PLACEHOLDER = 'https://dev.cyberhq.ai';
