import { logEvent } from '../../../../../../library';
import { APPLICATIONS as MANAGE_APPLICATIONS_EVENTS } from '../../../../../../library/amplitude/events/views/app/manage/applications';

/**
 * Logs event when a user clicks the 'Add Application' button to navigate to the AddApplication view
 *
 * @param {VoidFunction} navigate
 */
const logAddUserClick = (navigate) => {
  logEvent(MANAGE_APPLICATIONS_EVENTS.CLICK_ADD_APPLICATION);
  navigate('add');
};

/**
 * Logs event when user clicks on a SingleAppBar component (clicks on an individual app to view it's details in ViewApplication)
 *
 * @param {object} application
 * @param {VoidFunction} navigate
 */
const logAppBarClick = (application, navigate) => {
  logEvent(MANAGE_APPLICATIONS_EVENTS.VIEW_APPLICATION, { application_id: application?.id });
  navigate(`/app/manage/applications/${application?.id}`);
};

/**
 * Logs event when user changes page (navigates) via pagination
 *
 * @param {String | Number} value
 * @param {String | Number} page
 */
const logPageChange = (value, page) => {
  logEvent(MANAGE_APPLICATIONS_EVENTS.NAVIGATION, { page_prev: page, page_current: value });
};

/**
 * Logs event when user changes pagination row number
 *
 * @param {String | Number} value
 * @param {String | Number} prevValue
 */
const logRowChange = (value, prevValue) => {
  logEvent(MANAGE_APPLICATIONS_EVENTS.ROWS_PER_PAGE, { old: prevValue, new: value });
};

export { logAddUserClick, logAppBarClick, logPageChange, logRowChange };
