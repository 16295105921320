import { FRAMEWORKS, INTEGRATION_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { alpha, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { openLink } from '../../../../../helpers/routing';
import { useIsDesktop } from '../../../../../hooks';
import { logEvent } from '../../../../../library';
import { CLOUD_INFRASTRUCTURE as REPORTS_CLOUD_INFRASTRUCTURE_EVENTS } from '../../../../../library/amplitude/events/views/app/reports/cloudInfrastructure';
import { DataGridEnhanced } from '../../../../global';
import { Legend } from '../../../../ui';
import { DEFAULT_COLUMNS } from './constants';

const useStyles = makeStyles((theme) => ({
  enabled: {
    backgroundColor: alpha(theme.palette.success.main, 0.08)
  },
  needsAttention: {
    backgroundColor: alpha(theme.palette.error.main, 0.08)
  }
}));

const LEGEND = Object.values(INTEGRATION_TYPES.AWS_SECURITY_HUB.CRITICALITY_MAPPING);

const Remediation = ({ category }) => {
  const isDesktop = useIsDesktop();
  const classes = useStyles();

  const { questions } = category || {};

  const [columns] = useState(DEFAULT_COLUMNS);
  const [tableRows, setTableRows] = useState([]);
  const [selected, setSelected] = useState(LEGEND.map((item) => item.value));
  const [dataGridState, setDataGridState] = useState({});

  useEffect(() => {
    if (!questions) {
      return;
    }

    const rows = (questions || []).map((question) => {
      const { id, name, remediation, datapoint, criticality, issues = [] } = question || {};
      const { id: issueId } = issues?.[0] || {};
      const { selected = [] } = datapoint || {};
      const isAnsweredYes = (selected || []).find((option) => option.value === FRAMEWORKS.MASTER_CONTROL_FRAMEWORK.answers.YES.value);
      const { name: recommendationName, link } = remediation || {};
      return {
        id,
        control: name,
        remediation: { name: isAnsweredYes ? 'No action needed' : recommendationName, link: isAnsweredYes ? '' : link },
        requiresAttention: !isAnsweredYes,
        criticality,
        issue: {
          id: issueId
        }
      };
    });

    setTableRows(rows);
  }, [questions]);

  const handleClick = useCallback(
    (value) => {
      setSelected((legend) => {
        if (legend.includes(value)) {
          const filtered = legend.filter((item) => item !== value);
          logEvent(REPORTS_CLOUD_INFRASTRUCTURE_EVENTS.TOGGLE_CONTROL_LIST, { category_name: category?.name, risk_filters: filtered });
          return filtered;
        }
        logEvent(REPORTS_CLOUD_INFRASTRUCTURE_EVENTS.TOGGLE_CONTROL_LIST, { category_name: category?.name, active_risk_filters: [...(legend || []), value] });
        return [...(legend || []), value];
      });
    },
    [category?.name]
  );

  //external to handleDataGridChange to prevent logging multiple events per remediation component (one for each column)
  //eg sortModelChange will fire an event for every column within the component if done within handleDataGridChange
  const handleSortModelChange = (event) => {
    logEvent(REPORTS_CLOUD_INFRASTRUCTURE_EVENTS.ARRANGE_TABLES, { sort_by_field: event[0]?.field, sort_by_value: event[0]?.sort });
  };

  const logPageSizeChange = (event) => {
    logEvent(REPORTS_CLOUD_INFRASTRUCTURE_EVENTS.ROWS_PER_PAGE, { rows_per_page: event });
  };

  const handleDataGridChange = useCallback(
    (event) => {
      if (_.isEqual(dataGridState, event)) {
        return;
      }

      const { density: newDensity, columns: newColumns, pagination: newPagination } = event || {};
      const { density: oldDensity, columns: oldColumns, pagination: oldPagination } = dataGridState || {};

      //density change
      if (oldDensity && !_.isEqual(oldDensity, newDensity)) {
        logEvent(REPORTS_CLOUD_INFRASTRUCTURE_EVENTS.CHANGE_CONTROL_DENSITY, { prev_density: oldDensity?.value, selected_density: newDensity?.value });
      }

      //column change
      if (oldColumns && !_.isEqual(oldColumns, newColumns)) {
        const { columnVisibilityModel } = newColumns || {};
        const active = [];

        for (const column in columnVisibilityModel) {
          if (!columnVisibilityModel[column]) {
            continue;
          }
          active.push(column);
        }
        logEvent(REPORTS_CLOUD_INFRASTRUCTURE_EVENTS.CHANGE_CONTROL_COLUMNS, { category_name: category?.name, columns: active });
      }

      //page change
      const { page: newPage } = newPagination || {};
      const { page: oldPage } = oldPagination || {};
      if (oldPagination && !_.isEqual(oldPage, newPage)) {
        logEvent(REPORTS_CLOUD_INFRASTRUCTURE_EVENTS.NAVIGATION, { page_prev: oldPage, page_current: newPage, category_name: category?.name });
      }

      setDataGridState(event);
    },
    [category, dataGridState]
  );

  const filterModel = useMemo(() => {
    return {
      items: [{ columnField: 'criticality', operatorValue: 'isAnyOf', value: selected || [] }]
    };
  }, [selected]);

  const handleCellClick = ({ row, field }) => {
    if (field !== 'control') {
      return;
    }
    const { issue, requiresAttention } = row || {};
    if (!requiresAttention) {
      return;
    }
    openLink(`/app/manage/issues/${issue?.id}`);
  };

  return (
    <Grid container spacing={2}>
      <Grid container item justifyContent='center'>
        <Grid item>
          <Legend title='Risk: ' items={LEGEND} selected={selected} onClick={handleClick} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataGridEnhanced
          columns={columns}
          handleDataGridChange={handleDataGridChange}
          onSortModelChange={handleSortModelChange}
          logPageSizeChange={logPageSizeChange}
          rows={tableRows}
          filterModel={filterModel}
          disableColumnFilter
          initialState={{
            sorting: {
              sortModel: [{ field: 'remediation', sort: 'desc' }]
            },
            filter: filterModel
          }}
          getRowHeight={(params) => {
            const { model } = params || {};
            const { control, remediation } = model || {};
            const { name } = remediation || {};

            if (isDesktop) {
              if (control?.length > 45 || name?.length > 40) {
                return 100;
              }
            } else if (control?.length > 20 || name?.length > 20) {
              return 200;
            }

            return null;
          }}
          onCellClick={handleCellClick}
          selectable={false}
          isCellEditable={() => false}
          isRowSelectable={() => false}
          getRowClassName={(params) => {
            const { requiresAttention } = params.row || {};
            return requiresAttention ? classes.needsAttention : classes.enabled;
          }}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            '& .MuiDataGrid-row:hover': {
              background: 'none'
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

Remediation.propTypes = {
  category: PropTypes.object
};

export default Remediation;
