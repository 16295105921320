import { PAGE_THRESHOLD } from '@anirudhm9/base-lib/lib/constants';
import { Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { DrawerEnhanced, TypographyEnhanced } from '../../../../../../components/global';
import { useThreatModellingContext } from '../../../../../../contexts';
import { useScenarioTemplates } from '../../../../../../hooks';
import TemplateList from './list';

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    maxWidth: '60vw',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw'
    },
    height: '100%',
    padding: theme.spacing(2)
  }
}));

export const NoTemplateCard = () => {
  return (
    <Card>
      <CardContent>
        <TypographyEnhanced id='No templates found' />
      </CardContent>
    </Card>
  );
};

const ThreatModellingTemplates = ({ open, toggle, onSelect, selectedTemplate }) => {
  const classes = useStyles();
  const { selectedFramework } = useThreatModellingContext();

  const [defaultStart, setDefaultStart] = useState(PAGE_THRESHOLD);
  const [defaultPageSize, setDefaultPageSize] = useState(PAGE_THRESHOLD);
  const [customStart, setCustomStart] = useState(PAGE_THRESHOLD);
  const [customPageSize, setCustomPageSize] = useState(PAGE_THRESHOLD);

  const { templates: defaultTemplates, loading: defaultTemplateLoading } = useScenarioTemplates(
    selectedFramework,
    false,
    {
      start: defaultStart,
      limit: defaultPageSize
    },
    !open
  );
  const {
    templates: customTemplates,
    loading: customTemplateLoading,
    refetch: refetchCustomTemplates
  } = useScenarioTemplates(
    selectedFramework,
    true,
    {
      start: customStart,
      limit: customPageSize
    },
    !open
  );

  return (
    <DrawerEnhanced
      open={open}
      toggle={toggle}
      title={<TypographyEnhanced id={'Templates'} variant='h5' />}
      containerProps={{
        className: classes.drawerContainer
      }}
    >
      <TemplateList
        title={<TypographyEnhanced id='Default Templates' variant='h6' />}
        templates={defaultTemplates}
        loading={defaultTemplateLoading}
        selectedTemplate={selectedTemplate}
        onClick={onSelect}
        setStart={setDefaultStart}
        pageSize={defaultPageSize}
        setPageSize={setDefaultPageSize}
      />
      <TemplateList
        title={<TypographyEnhanced id='Custom Templates' variant='h6' />}
        templates={customTemplates}
        loading={customTemplateLoading}
        selectedTemplate={selectedTemplate}
        onClick={onSelect}
        custom
        refetch={refetchCustomTemplates}
        setStart={setCustomStart}
        pageSize={customPageSize}
        setPageSize={setCustomPageSize}
      />
    </DrawerEnhanced>
  );
};

ThreatModellingTemplates.propTypes = {
  toggle: PropTypes.func,
  open: PropTypes.bool,
  onSelect: PropTypes.func,
  selectedTemplate: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    prompt: PropTypes.string,
    mappings: PropTypes.object
  })
};

export default ThreatModellingTemplates;
