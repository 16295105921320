import { DEFAULT_SCALE } from '@anirudhm9/base-lib/lib/constants';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { GET_SUBJECTIVE_CONTROL_MAPPINGS_FOR_OBJECTIVE_FRAMEWORK } from '../../../../../../../../../../api';
import { useOrgContext } from '../../../../../../../../../../contexts';
import { DotLoader } from '../../../../../../../../../global';
import EmptyControls from '../empty';
import FrameworkControls from './frameworkControls';

const SubjectiveControls = ({ open, question, refetch }) => {
  const { orgId } = useOrgContext();

  // const [start, setStart] = useState(0);
  // const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  // const {
  //   data: mappingCountData,
  //   loading: mappingCountLoading,
  //   error: mappingCountError
  // } = useQuery(COUNT_CONTROL_MAPPINGS, {
  //   variables: {
  //     where: {
  //       sourceControl: question?.id,
  //       org: orgId
  //     }
  //   },
  //   skip: !orgId || !question?.id || !open
  // });

  // const mappingsCount = useMemo(() => {
  //   if (mappingCountLoading || mappingCountError) {
  //     return 0;
  //   }
  //   return mappingCountData?.countControlMappings || 0;
  // }, [mappingCountData?.countControlMappings, mappingCountError, mappingCountLoading]);

  const {
    data: controlMappingsData,
    loading: controlMappingsLoading,
    error: controlMappingsError,
    refetch: refetchControlMappings
  } = useQuery(GET_SUBJECTIVE_CONTROL_MAPPINGS_FOR_OBJECTIVE_FRAMEWORK, {
    variables: {
      where: {
        sourceControl: question?.id,
        org: orgId
      }
      // start,
      // limit: pageSize
    },
    skip: !orgId || !question?.id || !open
  });

  const mappings = useMemo(() => {
    if (controlMappingsLoading || controlMappingsError) {
      return [];
    }
    return controlMappingsData?.controlmappings || [];
  }, [controlMappingsData?.controlmappings, controlMappingsError, controlMappingsLoading]);

  const groupedMappings = useMemo(() => {
    if (!(mappings || []).length) {
      return {};
    }

    return _.groupBy(mappings, (mapping) => {
      const { destinationControl } = mapping || {};
      const { framework } = destinationControl || {};
      const { name } = framework || {};
      return name;
    });
  }, [mappings]);

  const satisfactory = useMemo(() => {
    if (!question) {
      return;
    }
    return question?.datapoint?.value === DEFAULT_SCALE.max;
  }, [question]);

  return (
    <Grid container spacing={1}>
      {controlMappingsLoading ? (
        <Grid container item justifyContent='center'>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Grid container item spacing={1}>
          {!(mappings || []).length ? (
            <EmptyControls />
          ) : (
            <Grid item xs={12}>
              {Object.keys(groupedMappings || {}).map((frameworkName) => {
                const mappings = groupedMappings[frameworkName] || [];
                return (
                  <FrameworkControls
                    key={frameworkName}
                    name={frameworkName}
                    mappings={mappings}
                    refetch={() => {
                      refetchControlMappings();
                      refetch();
                    }}
                    satisfactory={satisfactory}
                  />
                );
              })}
            </Grid>
          )}
          {/* <PaginationEnhanced total={mappingsCount} onChange={setStart} pageSize={pageSize} setPageSize={setPageSize} /> */}
        </Grid>
      )}
    </Grid>
  );
};

SubjectiveControls.propTypes = {
  open: PropTypes.bool,
  question: PropTypes.object,
  refetch: PropTypes.func
};

export default SubjectiveControls;
