import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { Collapse, Grid } from '@mui/material';
import { Container } from '@mui/system';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ButtonEnhanced, TypographyEnhanced } from '../../../global';
import Input from './input';

const DropdownFilterMenu = ({ id, label, options, selected }) => {
  const [collapsed, setCollapsed] = useState(selected);

  return (
    <Grid item xs={12} key={id}>
      <ButtonEnhanced variant='text' color='primary' onClick={() => setCollapsed((open) => !open)}>
        <TypographyEnhanced id={label} />
        {collapsed ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
      </ButtonEnhanced>
      <Collapse in={collapsed}>
        <Container sx={{ display: 'flex', flexDirection: 'column' }}>{options}</Container>
      </Collapse>
    </Grid>
  );
};

DropdownFilterMenu.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  options: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  selected: PropTypes.bool
};

/**
 *
 * @param {JSON} filters
 * @param {JSON} selectedFilters
 * @param {VoidFunction} updateSelectedFilters
 * @returns {JSX}
 */
const FilterBody = ({ filters, selectedFilters = {}, setSelectedFilters }) => {
  return Object.keys(filters || {}).map((key) => {
    const { id, label, filters: filterOptions } = filters[key] || {};

    const updateSelectedFilters = (key, value) => {
      setSelectedFilters((filters) => {
        return {
          ...(filters || {}),
          // category level (e.g: 'ALL_USERS')
          [id]: {
            ...(filters[id] || {}),
            // replace value with new value for child filter
            [key]: value
          }
        };
      });
    };

    const contentArray = filterOptions.map((option) => {
      const { id } = option || {};
      return <Input key={id} option={option} selectedFilters={selectedFilters[key] || {}} updateSelectedFilters={updateSelectedFilters} />;
    });

    const selected = _.some(Object.values(selectedFilters[key] || {}), (selected) => Array.isArray(selected) && selected?.length);
    return <DropdownFilterMenu key={id} options={contentArray} id={id} label={label} selected={selected} />;
  });
};

FilterBody.propTypes = {
  filters: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['checkbox', 'radio', 'text-field']),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired
        })
      )
    })
  ),
  selectedFilters: PropTypes.object,
  setSelectedFilters: PropTypes.func.isRequired
};
export default FilterBody;
