import { isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { CheckRounded, CloseRounded } from '@mui/icons-material';
import { Collapse, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { TypographyEnhanced } from '../../../../../../global';
import { TableEnhanced } from '../../../../../../ui';
import { GRADE_COLORS } from '../../constants';
import HeaderChip from '../../headerChip';
import { logWarningClick } from '../../utils';

const BasicHeaderTable = ({ scan }) => {
  const [selectedWarning, setSelectedWarning] = useState();
  const [rows, setRows] = useState([]);

  const toggleWarningDescription = (header, value) => {
    logWarningClick(header);
    setSelectedWarning((prev) => {
      if (isIDEqual(prev || '', value)) {
        return null;
      }
      return value;
    });
  };

  useEffect(() => {
    if (!scan) {
      return;
    }

    const { site, grade, ip, validationErrors, headers } = scan || {};

    const rows = [
      { label: 'URL:', value: site },
      { label: 'IP Address:', value: ip },
      { label: 'Grade:', value: grade, sx: { color: GRADE_COLORS[grade] } },
      {
        label: 'Headers:',
        value: (
          <Grid container spacing={1}>
            {Object.keys(headers || {}).map((header) => {
              const implemented = headers?.[header];
              const Icon = implemented ? <CheckRounded fontSize='small' /> : <CloseRounded fontSize='small' />;
              return <HeaderChip key={header} header={header} onClick={undefined} icon={Icon} color={implemented ? 'success' : 'error'} />;
            })}
          </Grid>
        )
      },
      {
        label: 'Warning(s):',
        value: (
          <Grid container spacing={1}>
            {Object.keys(validationErrors || {}).map((header) => (
              <HeaderChip key={header} header={header} onClick={() => toggleWarningDescription(header, validationErrors?.[header] || '')} color='warning' />
            ))}
            <Grid item xs={12}>
              <Collapse in={!!selectedWarning}>
                <TypographyEnhanced id={String(selectedWarning || '')} />
              </Collapse>
            </Grid>
          </Grid>
        )
      }
    ];

    setRows(rows);
  }, [scan, selectedWarning]);

  return <TableEnhanced rows={rows || []} />;
};

BasicHeaderTable.propTypes = {
  scan: PropTypes.object
};

export default BasicHeaderTable;
