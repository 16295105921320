import { gql } from '@apollo/client';

export const CREATE_FEEDBACK = gql`
  mutation createFeedback($data: FeedbackInput!) {
    createFeedback(input: { data: $data }) {
      feedback {
        id
      }
    }
  }
`;
