import { IMAGES } from '@anirudhm9/base-lib/lib/constants';
import { formatUserName } from '@anirudhm9/base-lib/lib/utils';
import { Avatar, Badge, Grid, styled, Tooltip, tooltipClasses } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { IconButtonEnhanced, TypographyEnhanced } from '../../../../../global';
import { LetterAvatar } from '../../../../../ui';
import { logAvatarClick } from '../utils';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    maxWidth: 150,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '3px',
    boxShadow: '1px 2px 10px 1px rgba(0,0,0,0.36)'
  }
}));

const UserAvatar = ({ user, applications = [] }) => {
  const { profiles = [] } = user || {};

  const navigate = useNavigate();

  const BadgeContent = () => {
    if (!(profiles || []).length) {
      return null;
    }

    return (
      <Grid container spacing={1}>
        {profiles.map((profile) => {
          const { id, type } = profile || {};
          return (
            <Grid item key={id}>
              <Avatar alt={type} src={IMAGES.ICONS[type] || ''} sx={{ height: 20, width: 20, bgcolor: (theme) => theme.palette.background.default }} />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Grid item>
      <HtmlTooltip
        title={
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TypographyEnhanced id={formatUserName(user)} />
            </Grid>
            <Grid item xs={12}>
              <TypographyEnhanced id={`Applications: ${applications?.length}`} />
            </Grid>
          </Grid>
        }
      >
        <IconButtonEnhanced onClick={() => logAvatarClick(navigate, user)}>
          <Badge overlap='circular' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={<BadgeContent />}>
            <LetterAvatar user={user} sx={{ width: 100, height: 100, margin: 'auto' }} />
          </Badge>
        </IconButtonEnhanced>
      </HtmlTooltip>
    </Grid>
  );
};

UserAvatar.propTypes = {
  user: PropTypes.object,
  applications: PropTypes.array,
  integration: PropTypes.object
};

export default UserAvatar;
