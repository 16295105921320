import PropTypes from 'prop-types';
import HeldInfoChips from './heldInfoChips';
import HeldInfoSelect from './heldInfoSelect';

/**
 *
 * @param {Boolean} edit
 * @returns {JSX} view or edit component based on the props
 */
const HeldInfo = ({ edit, ...props }) => {
  if (edit) {
    return <HeldInfoSelect {...props} />;
  } else {
    return <HeldInfoChips {...props} />;
  }
};

HeldInfo.propTypes = {
  edit: PropTypes.bool
};

export default HeldInfo;
