import { PAGE_THRESHOLD } from '@anirudhm9/base-lib/lib/constants';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_LOGS } from '../../api';

/**
 * Custom hook to encapsulate GET_LOGS
 * usage example:
 *
 * const { logs, loading, error, refetch } = useLogs()
 *
 * @returns {{logs: (Array), refetch: (variables?: Partial<{criticality: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError}}
 */
const useLogs = (variables = { where: {}, start: 0, limit: PAGE_THRESHOLD }, skip = false) => {
  const {
    data: logData,
    loading,
    error,
    refetch
  } = useQuery(GET_LOGS, {
    variables,
    skip,
    fetchPolicy: 'network-only'
  });

  const logs = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return logData?.logs || 0;
  }, [error, loading, logData?.logs]);

  return { logs, loading, error, refetch };
};

export default useLogs;
