import { formatUserName } from '@anirudhm9/base-lib/lib/utils';
import { logEvent } from '../../../../../../library';
import { DASHBOARD as DASHBOARD_EVENTS } from '../../../../../../library/amplitude/events/views/app/dashboard';
import { MANAGE_USERS_PATH } from '../constants';

/**
 * Logs view all button click event and navigates user
 *
 * @param {VoidFunction} navigate
 */
const logViewAll = (navigate) => {
  logEvent(DASHBOARD_EVENTS.USER_SUMMARY_VIEW_ALL);
  navigate(MANAGE_USERS_PATH, {
    state: {
      filter: {
        USER_GOOGLE: {
          USER_GOOGLE_CHECKBOX: ['GOOGLE_ADMIN', 'GOOGLE_DELEGATE_ADMIN']
        },
        USER_SLACK: {
          USER_SLACK_CHECKBOX: ['SLACK_ADMIN', 'SLACK_DELEGATE_ADMIN']
        }
      }
    }
  });
};

/**
 * Logs user avatar click event and navigates user
 *
 * @param {VoidFunction} navigate
 * @param {Object} user
 */
const logAvatarClick = (navigate, user) => {
  logEvent(DASHBOARD_EVENTS.USER_SUMMARY_AVATAR_CLICK, { user_id: user?.id, user_name: formatUserName(user) });
  navigate(`${MANAGE_USERS_PATH}/${user.id}?expand=true`);
};

export { logViewAll, logAvatarClick };
