import { DEFAULT_SCALE } from '@anirudhm9/base-lib/lib/constants';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { COUNT_CONTROL_MAPPINGS, GET_OBJECTIVE_CONTROL_MAPPINGS_FOR_SUBJECTIVE_FRAMEWORK } from '../../../../../../../../../../api';
import { useOrgContext } from '../../../../../../../../../../contexts';
import { DotLoader } from '../../../../../../../../../global';
import { PaginationEnhanced } from '../../../../../../../../../ui';
import { DEFAULT_PAGE_SIZE } from '../../../../../../../../../ui/pagination/constants';
import EmptyControls from '../empty';
import Mapping from '../mapping';

const ObjectiveControls = ({ open, question, refetch }) => {
  const { orgId } = useOrgContext();

  const [start, setStart] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const {
    data: mappingCountData,
    loading: mappingCountLoading,
    error: mappingCountError
  } = useQuery(COUNT_CONTROL_MAPPINGS, {
    variables: {
      where: {
        destinationControl: question?.id,
        org: orgId
      }
    },
    skip: !orgId || !question?.id || !open
  });

  const mappingsCount = useMemo(() => {
    if (mappingCountLoading || mappingCountError) {
      return 0;
    }
    return mappingCountData?.countControlMappings || 0;
  }, [mappingCountData?.countControlMappings, mappingCountError, mappingCountLoading]);

  const {
    data: controlMappingsData,
    loading: controlMappingsLoading,
    error: controlMappingsError,
    refetch: refetchControlMappings
  } = useQuery(GET_OBJECTIVE_CONTROL_MAPPINGS_FOR_SUBJECTIVE_FRAMEWORK, {
    variables: {
      where: {
        destinationControl: question?.id,
        org: orgId
      },
      start,
      limit: pageSize
    },
    skip: !orgId || !question?.id || !open
  });

  const mappings = useMemo(() => {
    if (controlMappingsLoading || controlMappingsError) {
      return [];
    }
    return controlMappingsData?.controlmappings || [];
  }, [controlMappingsData?.controlmappings, controlMappingsError, controlMappingsLoading]);

  return (
    <Grid container spacing={1}>
      {controlMappingsLoading ? (
        <Grid container item justifyContent='center'>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Grid container item spacing={1}>
          {!(mappings || []).length ? (
            <EmptyControls />
          ) : (
            <Grid container item xs={12} spacing={1}>
              {mappings.map((mapping) => {
                const { id, sourceControl, increase } = mapping || {};
                const { datapoint } = sourceControl || {};
                const satisfactory = datapoint?.value === DEFAULT_SCALE.max;

                return (
                  <Grid item key={id}>
                    <Mapping
                      control={sourceControl}
                      refetch={() => {
                        refetchControlMappings();
                        refetch();
                      }}
                      subjective
                      increase={increase}
                      satisfactory={satisfactory}
                      notApplicable={datapoint?.notApplicable}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <PaginationEnhanced total={mappingsCount} onChange={setStart} pageSize={pageSize} setPageSize={setPageSize} />
      </Grid>
    </Grid>
  );
};

ObjectiveControls.propTypes = {
  open: PropTypes.bool,
  question: PropTypes.object,
  refetch: PropTypes.func
};
export default ObjectiveControls;
