import { gql } from '@apollo/client';

export const COUNT_VULNERABILITIES = gql`
  query countVulnerabilities($where: JSON!) {
    countVulnerabilities(where: $where)
  }
`;

export const GET_VULNERABILITIES = gql`
  query vulnerabilities($where: JSON!, $start: Int, $limit: Int, $sort: String) {
    vulnerabilities(where: $where, start: $start, limit: $limit, sort: $sort) {
      id
      name
      description
      url
      recommendation
      access
      severity
      vulnerableVersions
      patchedVersions
      severityLevel
      cwe
      cves
      created
      updated
      module {
        id
        name
      }
      scenarios {
        id
      }
    }
  }
`;

export const GET_VULNERABILITY = gql`
  query vulnerability($id: ID!) {
    vulnerability(id: $id) {
      id
      name
      description
      url
      recommendation
      access
      severity
      vulnerableVersions
      patchedVersions
      severityLevel
      cwe
      cves
      created
      updated
      module {
        id
        name
      }
      scenarios {
        id
      }
    }
  }
`;
