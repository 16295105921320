/* eslint-disable quotes */
// views.user.resetPassword

export const RESET_PASSWORD = {
  title: 'Reset Password',
  newPassword: {
    label: 'New password',
    placeholder: 'Enter new password'
  },
  confirmPassword: {
    label: 'Confirm password',
    placeholder: 'Confirm your new password'
  },
  buttons: { reset: 'Confirm' },
  alerts: {
    resetSuccess: 'Password successfully reset',
    error: 'There was an error resetting your password',
    passwordMismatch: 'Passwords do not match'
  }
};
