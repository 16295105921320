import { gql } from '@apollo/client';

export const GET_CRITICALITIES = gql`
  query criticalities {
    criticalities(sort: "level:asc") {
      id
      name
      value
      level
    }
  }
`;
