export const APPLICATIONS = [
  { key: 'GSUITE', name: 'Google Workspace', enabled: true },
  { key: 'AWS_SECURITY_HUB', name: 'AWS Security Hub', enabled: true },
  { key: 'SLACK', name: 'Slack', enabled: true },
  { key: 'GITHUB', name: 'Github', enabled: true },
  { key: 'MICROSOFT', name: 'Office 365', enabled: false },
  { key: 'AZURE', name: 'Microsoft Azure', enabled: false },
  { key: 'JIRA', name: 'Jira', enabled: false }
  // { key: 'OTHER', name: 'More coming soon', enabled: false }
];

export const TRANSLATION_PATH = (route) => {
  return `components.application.integrations.connectIntegrationModal.${route}`;
};
