import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../contexts';
import IntegrationProvider from '../../../contexts/integrationContext';
import ShadowAssessmentProvider from '../../../contexts/shadowAssessmentContext';
import { RoleProtectedRoute } from '../../../helpers/routing';
import CloudInfrastructure from './cloudInfrastructure';
import Devices from './devices';
import Mappings from './mappings';
import News from './news';
import SBOM from './sbom';
import WebSecurityHeaders from './webSecurityHeaders';

const Reports = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <Routes>
        <Route path='/' element={<Navigate to='cloud-infrastructure' />} />
        <Route path='/mapping' element={<RoleProtectedRoute roles={roles} path={pathname} element={Mappings} />} />
        <Route path='/web-security-headers' element={<RoleProtectedRoute roles={roles} path={pathname} element={WebSecurityHeaders} />} />
        <Route path='/cloud-infrastructure' element={<RoleProtectedRoute roles={roles} path={pathname} element={CloudInfrastructure} />} />
        <Route path='/news' element={<RoleProtectedRoute roles={roles} path={pathname} element={News} />} />
        <Route
          path='/sbom'
          element={
            <ShadowAssessmentProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={SBOM} />
            </ShadowAssessmentProvider>
          }
        />
        <Route
          path='/devices'
          element={
            <IntegrationProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={Devices} />
            </IntegrationProvider>
          }
        />
        <Route path='*' element={<Navigate to='cloud-infrastructure' />} />
      </Routes>
    </div>
  );
};

Reports.propTypes = {};

export default Reports;
