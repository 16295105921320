import { sortVersionArray } from '@anirudhm9/base-lib/lib/utils';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { useMemo } from 'react';
import { GET_SINGLE_CYBER_ASSESSMENT_CATEGORY_WITH_CUSTOM_VALUES } from '../../api';

/**
 * Custom hook to encapsulate GET_SINGLE_CYBER_ASSESSMENT_CATEGORY
 * usage example:
 *
 * const { category, loading, error, refetch } = useSingleAssessmentCategory(categoryId)
 *
 * @param {String || Number} categoryId
 * @returns {{
 * category: Object,
 * loading: Boolean,
 * error: ApolloError,
 * refetch: (variables?: Partial<{category: *}>) => Promise<ApolloQueryResult<any>>
 * }}
 */
const useSingleAssessmentCategory = (categoryId) => {
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
    refetch: refetchCategory
  } = useQuery(GET_SINGLE_CYBER_ASSESSMENT_CATEGORY_WITH_CUSTOM_VALUES, { variables: { id: categoryId }, skip: !categoryId, fetchPolicy: 'network-only' });

  const category = useMemo(() => {
    if (categoryLoading || categoryError) {
      return {};
    }
    const categoryCopy = _.cloneDeep(categoryData?.getCustomCategory || {});
    const { questions } = categoryCopy || {};

    //sort questions in order of index eg 4.1, 4.2 instead of 4.1, 4.10, 4.11
    categoryCopy.questions = sortVersionArray(questions, 'index');

    return categoryCopy;
  }, [categoryData?.getCustomCategory, categoryError, categoryLoading]);

  return {
    category,
    loading: categoryLoading,
    error: categoryError,
    refetch: refetchCategory
  };
};

export default useSingleAssessmentCategory;
