const defaultOptions = {
  tooltip: {
    position: 'top'
  },
  animation: false,
  grid: {
    height: '50%',
    top: '10%'
  },
  xAxis: {
    type: 'category',
    data: ['1', '2', '3', '4', '5'],
    splitArea: {
      show: true
    }
  },
  yAxis: {
    type: 'category',
    data: ['1', '2', '3', '4', '5'],
    splitArea: {
      show: true
    }
  },
  visualMap: {
    min: 0,
    max: 5,
    calculable: true,
    orient: 'horizontal',
    left: 'center',
    bottom: '15%',
    inRange: {
      color: ['#99e394', '#A1D5B3', '#FFD233', '#CC0B45']
    }
  },
  series: [
    {
      name: 'Punch Card',
      type: 'heatmap',
      data: [
        [0, 0, Math.floor(Math.random() * 5) + 1],
        [0, 1, Math.floor(Math.random() * 5) + 1],
        [0, 2, Math.floor(Math.random() * 5) + 1],
        [0, 3, Math.floor(Math.random() * 5) + 1],
        [0, 4, Math.floor(Math.random() * 5) + 1],
        [1, 0, Math.floor(Math.random() * 5) + 1],
        [1, 1, Math.floor(Math.random() * 5) + 1],
        [1, 2, Math.floor(Math.random() * 5) + 1],
        [1, 3, Math.floor(Math.random() * 5) + 1],
        [1, 4, Math.floor(Math.random() * 5) + 1],
        [2, 0, Math.floor(Math.random() * 5) + 1],
        [2, 1, Math.floor(Math.random() * 5) + 1],
        [2, 2, Math.floor(Math.random() * 5) + 1],
        [2, 3, Math.floor(Math.random() * 5) + 1],
        [2, 4, Math.floor(Math.random() * 5) + 1],
        [3, 0, Math.floor(Math.random() * 5) + 1],
        [3, 1, Math.floor(Math.random() * 5) + 1],
        [3, 2, Math.floor(Math.random() * 5) + 1],
        [3, 3, Math.floor(Math.random() * 5) + 1],
        [3, 4, Math.floor(Math.random() * 5) + 1],
        [4, 0, Math.floor(Math.random() * 5) + 1],
        [4, 1, Math.floor(Math.random() * 5) + 1],
        [4, 2, Math.floor(Math.random() * 5) + 1],
        [4, 3, Math.floor(Math.random() * 5) + 1],
        [4, 4, Math.floor(Math.random() * 5) + 1]
      ]
    }
  ]
};

export default defaultOptions;
