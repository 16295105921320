// EVENTS.VIEWS.APP.MANAGE.ISSUES

export const ISSUES = {
  PAGE_VISITED: 'issues_manage-page_visited',
  VIEW_EDIT_ISSUE: 'manage_issues-view_issue-page_visited',
  VIEW_ADD_ISSUE: 'manage_issues-add_issue-page_visited',
  SEARCH: 'manage_issues-search',
  FILTER: 'manage_issues-filter',
  ROWS_PER_PAGE: 'manage_issues-rows_per_page',
  NAVIGATION: 'manage_issues-navigation',
  NEW_PAGE: 'manage_issues-open_new_page',
  CLOSE_MODAL: 'manage_issues-close_view_issue_modal',
  VIEW_SINGLE_ISSUE: 'manage_issues-view_single_issue',

  //view single issue
  INTEGRATION_ICON: 'edit_issue-integration_icon',
  EDIT_SAVE: 'edit_issue-save_changes',
  EDIT_CANCEL: 'edit_issue-cancel_changes',
  CLEAR_ASSIGNEES: 'edit_issue-clear_assignees',

  //comments
  ADD_COMMENT: 'edit_issue-add_comment',
  EDIT_COMMENT: 'edit_issue-edit_comment',
  TAG_COMMENT: 'edit_issue-user_tag_comment',
  ADD_ATTACHMENT: 'edit_issue-user_add_attachment'
};
