import { useMutation, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { LOG_OUT, themeVar, userVar } from '../api';
import { handleLogout } from '../helpers/routing';
import { logout as authLogout, logEvent } from '../library';
import { USER_ACTIONS as USER_ACTIONS_EVENTS } from '../library/amplitude/events/common';
import { DARK_MODE, LIGHT_MODE } from '../styles/theme';

const ProfileMenu = () => {
  const userId = useReactiveVar(userVar);

  const [logout] = useMutation(LOG_OUT);
  const localTheme = useReactiveVar(themeVar);
  const isDarkModeActive = useMemo(() => localTheme?.palette?.mode === 'dark', [localTheme]);

  const handleDarkMode = () => {
    const theme = isDarkModeActive ? LIGHT_MODE() : DARK_MODE();
    themeVar(theme);
    logEvent(USER_ACTIONS_EVENTS.CHANGE_THEME, { set_theme: theme?.palette?.mode });
    localStorage.setItem('theme', JSON.stringify(theme));
    window.location.reload();
  };

  const logViewProfile = () => {
    logEvent(USER_ACTIONS_EVENTS.VIEW_PROFILE);
  };

  const logViewAccountSettings = () => {
    logEvent(USER_ACTIONS_EVENTS.VIEW_ACCOUNT_SETTINGS);
  };

  return [
    {
      icon: 'person',
      label: 'Profile',
      component: Link,
      to: `/app/manage/users/${userId}`,
      onClick: logViewProfile
    },
    {
      icon: 'settings',
      label: 'Account Settings',
      component: Link,
      to: '/app/settings/user',
      onClick: logViewAccountSettings
    },
    {
      icon: isDarkModeActive ? 'brightness_7' : 'brightness_4',
      label: isDarkModeActive ? 'Light' : 'Dark',
      onClick: handleDarkMode
    },
    {
      icon: 'logout',
      label: 'Log out',
      onClick: async () => {
        try {
          await authLogout();
          await logout({ variables: { jwt: localStorage.getItem('user_token') } });
          logEvent(USER_ACTIONS_EVENTS.LOGOUT);
        } catch (error) {
          console.error(error);
        } finally {
          handleLogout();
        }
      }
    }
  ];
};
export default ProfileMenu;
