import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_ORG, orgVar } from '../../api';

/**
 * Custom hook to encapsulate GET_ORG
 * usage example:
 *
 * const { orgId, orgData, loading, error, refetch } = useOrgData()
 *
 * @returns {{org: (JSON), refetch: (variables?: Partial<{org: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError, orgId: *}}
 */
const useOrgData = (altOrgId) => {
  const orgId = useReactiveVar(orgVar);

  const {
    data: orgData,
    loading,
    error,
    refetch
  } = useQuery(GET_ORG, {
    variables: { id: altOrgId || orgId },
    skip: !altOrgId && !orgId
  });

  if (error) {
    console.error(error);
  }

  return { orgId: orgId || orgData?.org?.id, org: orgData?.org, loading, error, refetch };
};

export default useOrgData;
