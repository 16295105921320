import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import DotLoader from '../dotLoader';

/**
 * @typedef {import('@mui/material').IconButtonProps & { loading: Boolean }} IconButtonProps Props for `IconButton` from '@mui/material` library
 *
 * @type React.ForwardRefRenderFunction<React.FunctionComponent, IconButtonProps>
 *
 * @returns {React.FC<IconButtonProps>}
 */
const IconEnhanced = ({ children, loading = false, ...props }) => {
  return <Box {...props}>{loading ? <DotLoader sx={{ position: 'relative' }} /> : children}</Box>;
};

IconEnhanced.displayName = 'IconEnhanced';
IconEnhanced.propTypes = {
  children: PropTypes.element,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};
export default IconEnhanced;
