import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
/**
 * Custom hook to get the initial loading for specific interval
 * usage example:
 * @param {Number} interval in seconds
 *
 * const initialLoading = useInitialLoading();
 *
 * @returns {Boolean}
 */
const useInitialLoading = (interval = 2) => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setInitialLoading(false), interval * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [interval]);

  return initialLoading;
};

useInitialLoading.propTypes = {
  interval: PropTypes.number
};

export default useInitialLoading;
