import { useEffect } from 'react';

/**
 * Custom hook to useKeyPress
 * Example Code :
 *
 * useKeyPress('Enter', () => {
 *      perform something cool :)
 * });
 **/
const useKeyPress = (targetKey, callback) => {
  useEffect(() => {
    // If released key is our target key then call the function
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        return callback();
      }
    };

    // Add event listener
    window.addEventListener('keyup', upHandler);
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKey, callback]);
};

export default useKeyPress;
