import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { ButtonEnhanced, TypographyEnhanced } from '../../../../../../../global';
import { TRANSLATION_PATH } from '../constants/defaults';

const Complete = ({ claimed, onClaim, closeModal, type = 'basic' }) => {
  const claimReward = (event) => {
    event.stopPropagation();
    onClaim();
  };

  switch (type) {
    case 'basic':
      return !claimed ? (
        <ButtonEnhanced variant='contained' fullWidth size='small' color='secondary' onClick={(e) => claimReward(e)}>
          <TypographyEnhanced id={TRANSLATION_PATH('complete.claim')} />
        </ButtonEnhanced>
      ) : (
        <TypographyEnhanced id={TRANSLATION_PATH('complete.claimed')} variant='caption' paragraph align='center' style={{ margin: '0' }} />
      );
    default: //detailed
      return (
        <Grid item xs={12} container justifyContent='flex-end' spacing={2}>
          <Grid item xs={12}>
            <Typography variant='body1'>Completed</Typography>
          </Grid>
          {!claimed && (
            <Fragment>
              <Grid item justifySelf='flex-end'>
                <ButtonEnhanced variant='text' onClick={() => closeModal()}>
                  <TypographyEnhanced id={TRANSLATION_PATH('common.close')} />
                </ButtonEnhanced>
              </Grid>
              <Grid item justifySelf='flex-end'>
                <ButtonEnhanced variant='contained' color='secondary' onClick={(e) => claimReward(e)}>
                  <TypographyEnhanced id={TRANSLATION_PATH('complete.claim')} />
                </ButtonEnhanced>
              </Grid>
            </Fragment>
          )}
        </Grid>
      );
  }
};

Complete.propTypes = {
  claimed: PropTypes.bool,
  closeModal: PropTypes.func,
  onClaim: PropTypes.func,
  type: PropTypes.string
};

export default Complete;
