import { PAGE_THRESHOLD } from '@anirudhm9/base-lib/lib/constants';
import { useQuery } from '@apollo/client';
import { OpenInNewRounded } from '@mui/icons-material';
import { Container, Grid, Skeleton, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COUNT_ISSUES, GET_ISSUES } from '../../../../../api';
import { IssueItem, IssueLogo, IssuesFilter, SingleIssueBar } from '../../../../../components/application/manage';
import AppLogo from '../../../../../components/application/manage/applications/appLogo';
import { VIEW_ISSUE_MODAL_WIDTH } from '../../../../../components/application/manage/issues/constants';
import { SORT_VALUES } from '../../../../../components/application/manage/issues/filter/constants';

import { getIntegrationAvatarURL } from '@anirudhm9/base-lib/lib/utils';
import { ButtonEnhanced, CardWithLoader, DrawerEnhanced, IconButtonEnhanced, InsufficientData, TypographyEnhanced } from '../../../../../components/global';
import { Heading, PaginationEnhanced } from '../../../../../components/ui';
import { isDarkModeActive } from '../../../../../constants/defaults';
import { useOrgContext } from '../../../../../contexts';
import { TRANSLATION_PATH } from './constants';
import { logModalClose, logOpenNewTab, logPageChange, logRowChange, logViewIssue } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  drawer: {
    width: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box'
    },
    overflow: 'auto'
  },
  drawerContainer: {
    maxWidth: 550,
    [theme.breakpoints.down('sm')]: {
      width: '100vw'
    },
    height: '100%',
    padding: theme.spacing(2),
    marginTop: theme.spacing(8)
  }
}));

const Issues = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { orgId } = useOrgContext();

  const location = useLocation();

  const [selectedIssue, setSelectedIssue] = useState();
  const [pageSize, setPageSize] = useState(PAGE_THRESHOLD);
  const [start, setStart] = useState(0);
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [sort, setSort] = useState(SORT_VALUES.CREATED_AT_DESC.id);
  const [where, setWhere] = useState({});
  const [defaultFilter, setDefaultFilter] = useState();

  useEffect(() => {
    const { state } = location || {};
    const { filter: defaultFilter } = state || {};
    if (!defaultFilter) {
      return;
    }
    setDefaultFilter(defaultFilter);
  }, [location]);

  const {
    data: issuesCountData,
    loading: issuesCountLoading,
    error: issuesCountError
  } = useQuery(COUNT_ISSUES, {
    variables: { where: { org: orgId, _or: [{ archived: false }, { archived_null: true }], ...(where || {}) }, start, limit: PAGE_THRESHOLD },
    skip: !orgId
  });

  const issuesCount = useMemo(() => {
    if (issuesCountLoading || issuesCountError) {
      return 0;
    }
    return issuesCountData?.countIssues || 0;
  }, [issuesCountData?.countIssues, issuesCountError, issuesCountLoading]);

  const {
    data: issuesData,
    loading: issuesLoading,
    error: issuesError,
    refetch: refetchIssues
  } = useQuery(GET_ISSUES, {
    variables: { where: { org: orgId, _or: [{ archived: false }, { archived_null: true }], ...(where || {}) }, start, limit: pageSize, sort },
    fetchPolicy: 'network-only'
  });

  const issues = useMemo(() => {
    if (issuesLoading || issuesError) {
      return [];
    }
    setFilteredIssues(issuesData?.issues || []);
    return issuesData?.issues || [];
  }, [issuesData?.issues, issuesError, issuesLoading]);

  const handleClick = (issue) => {
    setSelectedIssue(issue);
  };

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title')} description={TRANSLATION_PATH('description')} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <IssuesFilter issues={issues} setIssues={setFilteredIssues} setWhere={setWhere} sort={sort} setSort={setSort} defaultFilter={defaultFilter} />
          {!issuesCount && !issuesCountLoading ? (
            <InsufficientData title='No Issues found' />
          ) : (
            <CardWithLoader
              cardHeaderProps={{
                title: (
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Stack>
                        <TypographyEnhanced id={TRANSLATION_PATH('cardTitle')} variant='h6' />
                        <TypographyEnhanced id={TRANSLATION_PATH('cardSubtitle')} variant='caption' />
                      </Stack>
                    </Grid>
                    <Grid item justifySelf='flex-end'>
                      <ButtonEnhanced size='medium' onClick={() => navigate('add')}>
                        <TypographyEnhanced id={TRANSLATION_PATH('buttons.add')} />
                      </ButtonEnhanced>
                    </Grid>
                  </Grid>
                )
              }}
            >
              <Grid container item xs={12} spacing={2}>
                {issuesLoading ? (
                  <Grid item xs={12}>
                    <Skeleton />
                  </Grid>
                ) : (
                  <Grid container item xs={12} spacing={2}>
                    {(filteredIssues || []).map((issue) => (
                      <Grid item xs={12} key={issue?.id}>
                        <SingleIssueBar
                          issue={issue}
                          onClick={() => {
                            logViewIssue(issue, handleClick);
                          }}
                          logo={<IssueLogo relation={selectedIssue?.relation} />}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <PaginationEnhanced
                    total={issuesCount || 0}
                    onChange={setStart}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    logPageChange={logPageChange}
                    logRowChange={logRowChange}
                  />
                </Grid>
              </Grid>
            </CardWithLoader>
          )}
        </Grid>
      </Grid>

      {selectedIssue && (
        <DrawerEnhanced
          open={!!selectedIssue}
          toggle={() => logModalClose(selectedIssue, handleClick)}
          title={
            <Grid container item alignItems='center' justifyContent='space-between'>
              <Grid container item xs alignItems='center'>
                <IssueLogo relation={selectedIssue?.relation} />
                <Grid item ml={1} mr={0.25}>
                  <TypographyEnhanced id={'views.app.manage.issues.single.title.view'} variant='h6' display='inline' />
                </Grid>
                <IconButtonEnhanced onClick={() => logOpenNewTab(selectedIssue, navigate)} size='small' color='primary'>
                  <OpenInNewRounded fontSize='small' />
                </IconButtonEnhanced>
              </Grid>
              {selectedIssue?.integration && (
                <Grid item>
                  <AppLogo url={getIntegrationAvatarURL(selectedIssue?.integration?.type, isDarkModeActive)} style={{ with: '30px', height: '30px' }} />
                </Grid>
              )}
            </Grid>
          }
          containerProps={{ style: { maxWidth: VIEW_ISSUE_MODAL_WIDTH } }}
        >
          <Grid item xs={12}>
            <IssueItem isModal id={selectedIssue?.id || ''} loading={issuesLoading} refetch={refetchIssues} onCancel={() => handleClick()} />
          </Grid>
        </DrawerEnhanced>
      )}
    </Container>
  );
};

Issues.propTypes = {};
export default Issues;
