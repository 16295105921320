import { useTheme } from '@emotion/react';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { menuItems as routeOptions } from '../../../constants';
import { betaMode, experimentalMode } from '../../../constants/defaults';
import { hasAccessToRoute } from '../../../helpers/routing';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  listItemRoot: {
    '&$listItemSelected, &$listItemSelected:focus, &$listItemSelected:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main
    }
  },
  listItemSelected: {}
}));

const SideMenu = ({ drawerOpen, setOpen: setDrawerOpen, roles, toggleBottomDrawer }) => {
  const classes = useStyles();
  const theme = useTheme();
  const color = theme.palette.primary.main;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState({});
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const filterRouteOptions = () => {
      const pages = _.cloneDeep(routeOptions);

      return pages.filter((option) => {
        if (option.subs) {
          option.subs = option.subs.filter((sub) => hasAccessToRoute(roles, sub.to)) || [];
          return hasAccessToRoute(roles, option.to) && option.subs.length;
        }
        return hasAccessToRoute(roles, option.to);
      });
    };

    setMenuItems(filterRouteOptions());
  }, [roles]);

  useEffect(() => {
    if (!menuItems || !menuItems.length) {
      return;
    }

    if (!drawerOpen) {
      setOpen({});
      return;
    }
    const pathtoCheck = String(window.location.pathname);
    menuItems.forEach((value, index) => {
      const menuItem = String(value.to);
      if (pathtoCheck === menuItem) {
        setSelectedIndex(`${index}`);
        return;
      } else if (value?.subs?.length) {
        value.subs.forEach((item, i) => {
          const subMenuItem = String(item.to);
          if (pathtoCheck === subMenuItem) {
            setSelectedIndex(`${index}.${i}`);
            setOpen((o) => {
              return { ...o, [index]: true };
            });
            return;
          }
        });
      }
    });
    return () => {
      if (setDrawerOpen) {
        setDrawerOpen(false);
      }
    };
  }, [drawerOpen, menuItems, setDrawerOpen]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (toggleBottomDrawer) {
      toggleBottomDrawer();
    }
  };

  const RenderMenuButton = ({ value, index }) => {
    const { to, icon, label, subs, comingSoon } = value || {};
    const renderSubs = subs?.filter((sub) => experimentalMode || !sub.private);
    const hideComingSoon = betaMode;

    const handleClick = (index) => {
      setOpen({ ...open, [index]: !open[index] });
    };

    return (
      <List key={`${label}-${index}`}>
        <ListItem
          classes={{
            root: classes.listItemRoot,
            selected: classes.listItemSelected
          }}
          button
          selected={selectedIndex === `${index}`}
          component={renderSubs?.length ? 'div' : Link}
          to={to}
          onClick={(e) => {
            renderSubs ? handleClick(`${index}`) : handleListItemClick(e, `${index}`);
          }}
        >
          <ListItemIcon>
            <i className='material-icons-round' style={{ ...(selectedIndex === `${index}` && { color }) }}>
              {icon}
            </i>
          </ListItemIcon>
          <ListItemText primary={label} secondary={comingSoon && !hideComingSoon && 'Coming Soon'} secondaryTypographyProps={{ variant: 'caption' }} />
          {!!renderSubs?.length && (open[index] ? <ExpandLessRounded /> : <ExpandMoreRounded />)}
        </ListItem>
        <Collapse in={open[index]} timeout='auto' unmountOnExit>
          <List component='div' disablePadding className={classes.nested}>
            {renderSubs?.map((subItem, i) => (
              <RenderMenuButton value={subItem} index={`${index}.${i}`} key={`${index}.${i}`} />
            ))}
          </List>
        </Collapse>
      </List>
    );
  };

  RenderMenuButton.propTypes = {
    value: PropTypes.object.isRequired,
    index: PropTypes.any
  };

  return (
    <List>
      {menuItems.map((value, i) => (
        <RenderMenuButton value={value} index={i} key={i} />
      ))}
    </List>
  );
};

SideMenu.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  roles: PropTypes.array,
  toggleBottomDrawer: PropTypes.func
};

export default SideMenu;
