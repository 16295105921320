import { STATUSES } from '@anirudhm9/base-lib/lib/constants';
import { isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useStatuses } from '../../../../../hooks';
import { DonutChart } from '../../../../ui';

const StatusDonut = ({ breakdown, dashboardView, onEvents }) => {
  const theme = useTheme();
  const { statuses, loading: statusesLoading } = useStatuses();

  const [options, setOptions] = useState();
  useEffect(() => {
    if (!breakdown || statusesLoading) {
      return;
    }

    const { palette } = theme || {};
    const { text } = palette || {};

    const labelOptions = dashboardView
      ? {
          show: true,
          color: text?.primary,
          formatter: ({ name, percent }) => `${name} (${Math.round(percent)}%)`,
          width: 30,
          overflow: 'truncate',
          ellipsis: '...'
        }
      : {
          show: true,
          color: text?.primary,
          formatter: ({ name, percent }) => `${name} (${Math.round(percent)}%)`
        };

    const seriesData = Object.keys(STATUSES || {}).map((value) => {
      const { name, color } = STATUSES[value] || {};
      const { id } = (statuses || []).find((status) => isIDEqual(status.value, value)) || {};

      return {
        id,
        value: breakdown[value],
        name,
        itemStyle: {
          color: theme?.palette?.[color]?.main
        }
      };
    });

    const options = {
      title: {
        show: true,
        text: 'Status Breakdown'
      },
      legend: {
        show: true,
        bottom: 0,
        left: 'center',
        type: dashboardView
      },
      series: [
        {
          top: 15,
          bottom: 15,
          name: 'Status',
          selectedMode: 'single',
          selectedOffset: 10,
          label: labelOptions,
          labelLine: {
            show: true
          },
          data: seriesData
        }
      ]
    };

    setOptions(options);
  }, [breakdown, dashboardView, statuses, statusesLoading, theme]);

  if (!options) {
    return null;
  }
  return <DonutChart options={options} onEvents={onEvents} />;
};

StatusDonut.propTypes = {
  breakdown: PropTypes.object,
  dashboardView: PropTypes.string,
  onEvents: PropTypes.object
};

export default StatusDonut;
