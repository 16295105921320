import { Box, Card, CardMedia, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrgContext } from '../../../../../contexts';
import { ButtonEnhanced, TypographyEnhanced } from '../../../../global';
import { Image, Watermark } from '../../../../ui';
import PointsChip from '../../pointsChip';
import DownloadModal from '../downloadModal';
import RedemptionModal from '../redemptionModal';
import { TRANSLATION_PATH } from './constants/defaults';

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    minWidth: '230px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cardContent: {
    padding: '16px'
  },
  grey: {
    filter: 'grayscale(100%)'
  }
}));

const RewardCard = ({ data }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { org } = useOrgContext();

  const [itemStatus, setItemStatus] = useState('unlocked');
  const [modalOpen, setModalOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);

  const { id, label, imageMain, backgroundImage, pointValue, creator } = data || {};

  const redeemModal = (event) => {
    event.stopPropagation();
    setModalOpen(true);
  };

  const downloadModal = (event) => {
    event.stopPropagation();
    setDownloadOpen(true);
  };

  useEffect(() => {
    const redeemedItems = ['6285e59f33568b70601a3846', '6285e59fa10ef8c48de61e86'];
    const points = 199;

    let status = 'unlocked';

    // if (org?.points < pointValue) {
    if (points < pointValue) {
      status = 'locked';
    }

    // if (org?.redeemedItems.includes(id)) {
    if (redeemedItems.includes(id)) {
      status = 'redeemed';
    }

    setItemStatus(status);
  }, [id, org?.points, pointValue]);

  const RenderPoints = () => {
    if (itemStatus === 'redeemed') {
      return (
        <Grid item xs={6} container>
          <PointsChip type='REDEEMED' pointValue={pointValue} />
        </Grid>
      );
    } else {
      return (
        <Grid item xs={6} container>
          <PointsChip type='DEFAULT' pointValue={pointValue} />
        </Grid>
      );
    }
  };

  const RenderButton = () => {
    switch (itemStatus) {
      case 'redeemed':
        return (
          <ButtonEnhanced color='primary' variant='outlined' onClick={(e) => downloadModal(e)}>
            <TypographyEnhanced id={TRANSLATION_PATH('buttons.download')} />
          </ButtonEnhanced>
        );
      case 'locked':
        return (
          <ButtonEnhanced color='primary' disabled>
            <TypographyEnhanced id={TRANSLATION_PATH('buttons.locked')} />
          </ButtonEnhanced>
        );

      default:
        return (
          <ButtonEnhanced color='secondary' onClick={(e) => redeemModal(e)}>
            <TypographyEnhanced id={TRANSLATION_PATH('buttons.redeem')} />
          </ButtonEnhanced>
        );
    }
  };

  return (
    <Fragment>
      <RedemptionModal modalOpen={modalOpen} setModalOpen={setModalOpen} data={data} />
      <DownloadModal modalOpen={downloadOpen} setModalOpen={setDownloadOpen} data={data} />
      <Card className={classes.card} onClick={() => navigate(`/app/rewards/redeem/${id}`)}>
        <Watermark>
          <CardMedia height='170' image={backgroundImage} alt='background-image' className={itemStatus === 'locked' ? classes.grey : ''}>
            <Image src={imageMain} alt='' />
          </CardMedia>
          <Grid container item direction='column' className={classes.cardContent}>
            <Grid item xs={12}>
              <Box sx={{ minHeight: '100px' }}>
                <RenderPoints />
                <TypographyEnhanced id={label} variant='body1' style={{ fontWeight: 'bold' }} />
                <TypographyEnhanced variant='caption' id={TRANSLATION_PATH('owner')} value={{ name: creator.name }} />
              </Box>
            </Grid>
            <Grid item>
              <RenderButton />
            </Grid>
          </Grid>
        </Watermark>
      </Card>
    </Fragment>
  );
};

RewardCard.propTypes = {
  data: PropTypes.any
};

export default RewardCard;
