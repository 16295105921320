import { Grid } from '@mui/material';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUsersContext } from '../../../../../contexts/usersContext';
import { useApplications } from '../../../../../hooks';
import { ButtonEnhanced, DotLoader, TypographyEnhanced } from '../../../../global';
import { WidgetContainer } from '../../../../ui';
import NoDataState from '../noDataState';
import UserAvatar from './avatar';
import { PATH, TRANSLATION_PATH } from './constants';
import { logViewAll } from './utils';

const UserSummary = () => {
  const { users, loading: usersLoading, error: usersError } = useUsersContext();
  const navigate = useNavigate();

  const usersWithAdminRole = useMemo(() => {
    if (usersError || usersLoading) {
      return [];
    }

    return (users || []).filter((user) => _.some(user.profiles || [], (profile) => profile.admin || profile.delegateAdmin));
  }, [users, usersError, usersLoading]);

  const userIds = useMemo(() => (usersWithAdminRole || [])?.map((user) => user.id), [usersWithAdminRole]);

  const { applications, loading: applicationsLoading } = useApplications({
    _and: [
      {
        _or: [{ archived: false }, { archived_null: true }]
      },
      {
        _or: [{ users_contains: userIds }, { inactiveUsers_contains: userIds }]
      }
    ]
  });

  const adminUsersWithApplications = useMemo(() => {
    const adminUsers = _.cloneDeep(usersWithAdminRole || []) || [];

    for (const user of adminUsers) {
      const userApplications = (applications || []).filter((application) => {
        const { users, inactiveUsers } = application || {};
        const allUsersIds = [...(users || []), ...(inactiveUsers || [])].map((user) => user.id) || [];
        return allUsersIds.includes(user.id);
      });
      user.applications = userApplications;
    }

    const sortedUsers = _.orderBy(adminUsers || [], (user) => user?.applications?.length || 0, 'desc');
    return (sortedUsers || []).slice(0, 5);
  }, [applications, usersWithAdminRole]);

  if (!(usersWithAdminRole || []).length && !applicationsLoading && !usersLoading) {
    return <NoDataState path={PATH} navigateTo='/app/integrations' />;
  }

  const TopUsers = () => {
    if (usersLoading || applicationsLoading) {
      return (
        <Grid item xs={12} m={2}>
          <DotLoader sx={{ position: 'relative', height: '100%' }} />
        </Grid>
      );
    }
    return (
      <Grid container item xs={12} spacing={4} justifyContent='center'>
        {(adminUsersWithApplications || []).map((user) => (
          <UserAvatar user={user} key={user.id} applications={user?.applications || []} navigate={navigate} />
        ))}
      </Grid>
    );
  };

  return (
    <WidgetContainer title={TRANSLATION_PATH('title')}>
      <Grid item xs={12}>
        <TypographyEnhanced id={TRANSLATION_PATH('description')} />
      </Grid>
      <TopUsers />
      <Grid container item justifyContent='flex-end'>
        <ButtonEnhanced variant='outlined' size='small' onClick={() => logViewAll(navigate)}>
          <TypographyEnhanced id={'VIEW ALL'} />
        </ButtonEnhanced>
      </Grid>
    </WidgetContainer>
  );
};

export default UserSummary;
