import { getId, isIDEqual, roundToTwo } from '@anirudhm9/base-lib/lib/utils';
import { useQuery } from '@apollo/client';
import { ExpandMoreRounded } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { GET_CONTROL_MAPPING_SCORE } from '../../../../../../../../api';
import { useOrgContext } from '../../../../../../../../contexts';
import { TypographyEnhanced } from '../../../../../../../global';
import { ObjectiveControls, SubjectiveControls } from './controls';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  bold: {
    fontWeight: 'bold'
  },
  background: {
    backgroundColor: theme.palette.background.default
  }
}));

const ControlMapping = ({ question = [], className }) => {
  const classes = useStyles();
  const { orgId, org, loading: orgLoading, error: orgError } = useOrgContext();

  const {
    data: controlMappingScoreData,
    loading: controlMappingLoading,
    error: controlMappingError,
    refetch: refetchControlMappingScore
  } = useQuery(GET_CONTROL_MAPPING_SCORE, {
    variables: {
      where: {
        destinationControl: question?.id,
        sourceControl_null: false,
        org: orgId
      }
    },
    skip: !orgId || !question?.id,
    fetchPolicy: 'network-only'
  });

  const stats = useMemo(() => {
    if (controlMappingLoading || controlMappingError) {
      return {};
    }
    return controlMappingScoreData?.getMappingScore || {};
  }, [controlMappingError, controlMappingLoading, controlMappingScoreData?.getMappingScore]);

  const isMasterControlFramework = useMemo(() => {
    if (orgLoading || orgError || !question) {
      return false;
    }
    const { masterControlFramework } = org || {};
    const { framework } = question || {};

    return isIDEqual(getId(framework), getId(masterControlFramework));
  }, [org, orgError, orgLoading, question]);

  const [open, setOpen] = useState(false);

  const toggleAccordion = (event) => {
    event.stopPropagation();
    setOpen((open) => !open);
  };

  return (
    <Accordion className={className || classes.background} expanded={open}>
      <AccordionSummary expandIcon={<ExpandMoreRounded onClick={toggleAccordion} />}>
        <Grid container spacing={2} alignItems='center' onClick={toggleAccordion}>
          <Grid item>
            <TypographyEnhanced id={'Control Mappings '} display='inline' />
            {!isMasterControlFramework && <TypographyEnhanced id={`(${roundToTwo(stats?.score || 0)})`} display='inline' />}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {isMasterControlFramework ? (
            <SubjectiveControls question={question} open={open} refetch={refetchControlMappingScore} />
          ) : (
            <ObjectiveControls question={question} open={open} refetch={refetchControlMappingScore} />
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

ControlMapping.propTypes = {
  question: PropTypes.object.isRequired,
  className: PropTypes.string
};
export default ControlMapping;
