import { INTEGRATION_TYPES } from '@anirudhm9/base-lib/lib/constants';

const INTEGRATION_CONFIGURATION = {
  [INTEGRATION_TYPES.AWS_SECURITY_HUB.value]: {
    name: 'AWS Security Hub',
    description: '',
    settings: {
      AWS_KEY_ID: '',
      AWS_KEY_SECRET: '',
      REGION: ''
    },
    placeholders: {
      AWS_KEY_ID: 'JXZAQKLAYNAFOQB',
      AWS_KEY_SECRET: 'VOZC+XC34kIy1gXYEK',
      REGION: 'ap-southeast-2'
    },
    help: {
      link: 'https://docs.aws.amazon.com/securityhub/latest/userguide/securityhub-settingup.html'
    }
  }
};

//values should match keys in amplitude library for INTEGRATIONS events
const INTEGRATION_SUBMISSION_TYPES = {
  AUTHORIZE_INTEGRATION: {
    id: 'AUTHORIZE',
    name: 'Authorize',
    value: 'AUTHORIZE_INTEGRATION'
  },
  INVITE_ADMIN: {
    id: 'INVITE_ADMIN',
    name: 'Invite Admin',
    value: 'INVITE_ADMIN'
  },
  EDIT_INTEGRATION: {
    id: 'EDIT',
    name: 'Edit',
    value: 'EDIT_INTEGRATION'
  },
  ARCHIVE_INTEGRATION: {
    id: 'ARCHIVE_INTEGRATION',
    name: 'Archive Integration',
    value: 'ARCHIVE_INTEGRATION'
  }
};

export { INTEGRATION_CONFIGURATION, INTEGRATION_SUBMISSION_TYPES };
