import { Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const CircularProgressEnhanced = ({ value, ...props }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant='determinate' color='primary' value={value} {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {value ?? <Typography variant='caption' component='div' color='text.secondary'>{`${Math.round(value)}%`}</Typography>}
      </Box>
    </Box>
  );
};

CircularProgressEnhanced.propTypes = {
  value: PropTypes.number
};

export default CircularProgressEnhanced;
