import { PAGE_THRESHOLD } from '@anirudhm9/base-lib/lib/constants';
import { useMutation, useQuery } from '@apollo/client';
import { DraftsRounded, MailRounded, NotificationsNoneRounded } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuList as Menu,
  MenuItem,
  Paper,
  Popper,
  Tab,
  Tabs
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useNavigate } from 'react-router-dom';
import { COUNT_NOTIFICATIONS, GET_USER_NOTIFICATIONS, MARK_ALL_AS_READ, MARK_AS_READ, userVar, wsContext } from '../../api';
import { DotLoader, TypographyEnhanced } from '../../components/global';
import { LetterAvatar, PaginationEnhanced } from '../../components/ui';
import { notificationParser } from '../../helpers/formatUtils';
import { triggerNotification } from '../../helpers/functions';
import { useIsDesktop } from '../../hooks';
import { BROWSER_NOTIFICATION_INPUT } from './constants';
import { logMarkAsRead, logNotificationCurrentTab, logPageChange, logRowChange, logViewNotifications } from './utils';

const color = 'primary';

const useStyles = makeStyles(() => ({
  root: {
    flexFlow: 1,
    borderRadius: '4px'
  },
  popperDesktop: {
    width: '20vw',
    borderRadius: '5px',
    maxHeight: '40vh',
    overflow: 'auto'
  },
  popperMobile: {
    width: '60vw',
    borderRadius: '5px',
    maxHeight: '50vh',
    overflow: 'auto'
  },
  spacing: {
    marginTop: 10
  },
  tab: {
    width: '50%'
  },
  tabs: {
    width: '100%'
  },
  notificationDate: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 1.5
  }
}));

const TabPanel = (props) => {
  const { children, value, index, style } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      style={{ ...style }}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  value: PropTypes.number,
  index: PropTypes.number.isRequired,
  style: PropTypes.object
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
};

const Notifications = ({ open, setOpen, anchorRef, setNotificationCounter, tabIndex, setTabIndex }) => {
  const navigate = useNavigate();

  const isDesktop = useIsDesktop('800px', '1100px');
  const classes = useStyles();

  const userSocket = wsContext.userSocket;
  const userId = userVar();
  const [notificationInfo, setNotificationInfo] = useState({});
  const [pageSize, setPageSize] = useState(PAGE_THRESHOLD);
  const [start, setStart] = useState(0);

  const [markAsRead] = useMutation(MARK_AS_READ);
  const [markAllAsRead] = useMutation(MARK_ALL_AS_READ);

  const {
    data: notificationCountData,
    loading: notificationCountLoading,
    error: notificationCountError,
    refetch: refetchNotificationCount
  } = useQuery(COUNT_NOTIFICATIONS, {
    variables: { where: { recipient: userId, _or: [{ actionable: false }, { actionable_null: true }] }, start, limit: pageSize },
    skip: !userId
  });

  const {
    data: notificationData,
    refetch: refetchNotifications,
    loading: notificationLoading
  } = useQuery(GET_USER_NOTIFICATIONS, {
    variables: { where: { recipient: userId }, start, limit: PAGE_THRESHOLD },
    skip: !userId
  });

  const notificationCount = useMemo(() => {
    if (notificationCountLoading || notificationCountError) {
      return {};
    }
    return notificationCountData?.countNotifications || {};
  }, [notificationCountData?.countNotifications, notificationCountError, notificationCountLoading]);

  useEffect(() => {
    if (!userSocket) {
      return;
    }
    userSocket.on('refetchNotifications', () => {
      refetchNotificationCount();
      refetchNotifications();
      triggerNotification(BROWSER_NOTIFICATION_INPUT, () => {
        parent.focus();
        setOpen(true);
      });
    });
  }, [userSocket, refetchNotificationCount, refetchNotifications, setOpen]);

  useEffect(() => {
    const notifications = notificationParser(notificationData?.notifications) || [];
    const readNotifications = _.filter(notifications, ['seen', true]);
    const unreadNotifications = _.difference(notifications, readNotifications);
    setNotificationInfo({
      unread: unreadNotifications,
      read: readNotifications
    });
  }, [notificationData]);

  useEffect(() => {
    setNotificationCounter(notificationCount?.all?.unread || 0);
  }, [notificationCount?.all?.unread, notificationCountData, setNotificationCounter]);

  const handleClose = (event) => {
    //prevents closing notifications when click set rows
    if (event?.target?.localName === 'body') {
      return;
    }
    if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleChange = (event, value) => {
    logNotificationCurrentTab(value);
    setTabIndex(value);
  };

  const markAsSeen = async (id) => {
    try {
      if (id) {
        await markAsRead({ variables: { id } });
      } else {
        await markAllAsRead({});
      }
      logMarkAsRead(id);
    } catch (error) {
      console.error(error);
    } finally {
      refetchNotificationCount();
      refetchNotifications();
      handleClose();
    }
  };

  const MenuList = ({ notifications = [], loading }) => {
    if (loading) {
      return <DotLoader />;
    }
    if (!notifications?.length) {
      return (
        <div>
          <MenuItem>
            <ListItemIcon>
              <NotificationsNoneRounded />
            </ListItemIcon>
            <ListItemText>{'No Notifications'}</ListItemText>
          </MenuItem>
        </div>
      );
    }

    return (
      <List>
        {notifications?.map((notification) => {
          const { id, created, sender, link, message, seen } = notification || {};
          const { primary } = message || {};
          return (
            <ListItem
              key={id}
              alignItems='flex-start'
              component={Button}
              onClick={() => {
                if (!seen) {
                  markAsSeen(id);
                }
                if (link) {
                  logViewNotifications(id, link, navigate, handleClose);
                }
              }}
            >
              <ListItemAvatar>
                <LetterAvatar user={sender} />
              </ListItemAvatar>
              <ListItemText
                primary={primary}
                secondary={
                  <TypographyEnhanced id='' variant='caption' className={classes.notificationDate}>
                    {created}
                  </TypographyEnhanced>
                }
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  MenuList.propTypes = {
    notifications: PropTypes.array.isRequired,
    loading: PropTypes.bool
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      className={isDesktop ? classes.popperDesktop : classes.popperMobile}
    >
      {({ TransitionProps = {}, placement }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <AppBar position='static' color='default' className={classes.root}>
                <Menu autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                  <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    indicatorColor={color}
                    textColor={color}
                    className={classes.tabs}
                    variant='scrollable'
                    scrollButtons
                  >
                    <Tab label='Unread' icon={<MailRounded />} className={classes.tab} {...a11yProps(0)} />
                    <Tab label='Read' icon={<DraftsRounded />} className={classes.tab} {...a11yProps(1)} />
                  </Tabs>
                  <TabPanel value={tabIndex} index={0} style={{ width: '100%' }}>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <MenuList notifications={notificationInfo.unread} loading={notificationLoading} />
                      <PaginationEnhanced
                        total={notificationCount?.all?.unread || 0}
                        onChange={(start) => setStart(start)}
                        logPageChange={logPageChange}
                        logRowChange={logRowChange}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        showLastButton={false}
                        showFirstButton={false}
                      />
                      <Button
                        variant='outlined'
                        color={color}
                        fullWidth
                        className={classes.spacing}
                        onClick={() => markAsSeen()}
                        disabled={!notificationInfo?.unread?.length}
                      >
                        Mark all as read
                      </Button>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <MenuList notifications={notificationInfo.read} loading={notificationLoading} />
                    <PaginationEnhanced
                      total={notificationCount?.all?.read || 0}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      onChange={setStart}
                      logPageChange={logPageChange}
                      logRowChange={logRowChange}
                      showLastButton={false}
                      showFirstButton={false}
                    />
                  </TabPanel>
                </Menu>
              </AppBar>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

Notifications.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  anchorRef: PropTypes.object.isRequired,
  setNotificationCounter: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired
};

export default Notifications;
