import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useId } from 'react';
import { useLoadingSentence } from '../../../hooks';
import TypographyEnhanced from '../typography';

const useStyles = makeStyles(() => ({
  center: {
    position: 'absolute',
    top: '60%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const DotLoader = ({ sx = {}, color = 'primary', showSentence }) => {
  const classes = useStyles();
  const id = useId();

  const Sentence = () => {
    const sentence = useLoadingSentence();
    if (!showSentence) {
      return;
    }

    return <TypographyEnhanced id={sentence || ''} ml={1} />;
  };

  return (
    <Box sx={{ display: 'flex', ...(sx || {}) }}>
      <CircularProgress id={id} size={20} color={color} className={showSentence ? '' : classes.center} />
      <Sentence className={classes.center} />
    </Box>
  );
};

DotLoader.propTypes = {
  sx: PropTypes.object,
  color: PropTypes.oneOf(['primary', 'secondary', 'warning', 'error', 'success']),
  showSentence: PropTypes.bool
};

export default DotLoader;
