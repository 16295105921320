// components.application.reports.applications.selectedApp

export const SELECTED_APP = {
  fields: {
    kbs: 'Is this a key business system?',
    notes: 'Notes'
  },
  buttons: {
    save: 'Add App'
  }
};
