import { preventEnter } from '@anirudhm9/base-lib/lib/utils';
import { AttachFileRounded } from '@mui/icons-material';
import { Badge, FormControl, FormLabel } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DotLoader, IconButtonEnhanced } from '../../global';

const Input = styled('input')({
  display: 'none'
});

const DEFAULT_TYPES = 'image/*, video/*, audio/*, .txt, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .odt, .rtf, .csv, .key';

const UploadIcon = ({ id = uuidv4(), icon, fileTypes = DEFAULT_TYPES, onUpload, multiple = false, attachments, loading, disabled }) => {
  const handleUpload = (event) => {
    const files = event.target.files;
    onUpload(files);
  };

  const Icon = () => {
    if (loading) {
      return <DotLoader />;
    }

    if (icon) {
      return icon;
    }

    if (!attachments || !attachments.length) {
      return <AttachFileRounded />;
    }
    return (
      <Badge badgeContent={attachments.length} color='primary'>
        <AttachFileRounded />
      </Badge>
    );
  };

  return (
    <Fragment>
      <FormControl onKeyDown={preventEnter}>
        <FormLabel id={id}>
          <Input id={id} accept={fileTypes} type='file' onChange={handleUpload} multiple={multiple} disabled={disabled} />
          <IconButtonEnhanced color='primary' aria-label='upload picture' component='span' disabled={disabled}>
            <Icon />
          </IconButtonEnhanced>
        </FormLabel>
      </FormControl>
    </Fragment>
  );
};

UploadIcon.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
  icon: PropTypes.element,
  fileTypes: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  attachments: PropTypes.array,
  setAttachments: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};
export default UploadIcon;
