import { ISSUE_RELATIONS } from '@anirudhm9/base-lib/lib/constants';
import { DEFAULT_REQUIRED_FIELDS } from '@anirudhm9/base-lib/lib/constants/api/issue';
import { isEmpty, isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import _ from 'lodash';
import { defaultMessages } from '../../../../../../constants';
import { toast } from '../../../../../global';

/**
 * Validates issueItem required fields on save
 *
 * @param {Object} localIssue
 * @param {VoidFunction} setErrorFields
 * @returns {Boolean}
 */
export const validateFields = (localIssue, setErrorFields) => {
  const localIssueCopy = _.cloneDeep(localIssue);

  const emptyFields = isEmpty(DEFAULT_REQUIRED_FIELDS, localIssueCopy);

  if (Object.keys(emptyFields || {}).length) {
    setErrorFields(emptyFields);
    toast.error(defaultMessages.requiredFields);
    return false;
  }
  return true;
};

/**
 * Can only be on relation associated with an issue
 * Removes all relation attributes that are not the actively selected relation on save
 *
 * @param {Object} localIssue
 * @returns {Object}
 */
export const stripRedundantRelations = (localIssue) => {
  const localIssueCopy = _.cloneDeep(localIssue);
  const { relation } = localIssueCopy || {};

  // Remove redundant relations - issue can only have one relation
  for (const issueRelation of Object.values(ISSUE_RELATIONS)) {
    const { id, value } = issueRelation || {};
    if (isIDEqual(relation, value)) {
      continue;
    }
    localIssueCopy[id] = null;
  }

  return localIssueCopy;
};
