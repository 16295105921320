// EVENTS.VIEWS.APP.MANAGE.DEVICES

export const DEVICES = {
  PAGE_VISITED: 'manage_devices-page_visited',
  VIEW_DEVICE: 'manage_devices-view_device-page_visited',
  ADD_DEVICE: 'manage_devices-add_device-page_visited',
  SEARCH: 'manage_devices-search',
  FILTER: 'manage_devices-filter',
  COOKIE_TRAIL: 'manage_devices-cookie_trail'
};
