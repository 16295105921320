import { ArrowCircleLeftRounded, ArrowCircleRightRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { IconButtonEnhanced } from '../../../../../global';

const CardPaginator = ({ items, item, onChange, children }) => {
  const index = items?.indexOf(item);
  const handleClick = (type) => () => {
    switch (type) {
      case 'NEXT':
        onChange(items[index + 1]);
        break;
      default:
        onChange(items[index - 1]);
    }
  };

  if (items?.length < 2) {
    return null;
  }

  return (
    <Grid container item spacing={2} justifyContent='flex-end'>
      <Grid item>
        <IconButtonEnhanced onClick={handleClick('BACK')} disabled={index === 0} color='primary'>
          <ArrowCircleLeftRounded fontSize='small' />
        </IconButtonEnhanced>
      </Grid>
      {children}
      <Grid item>
        <IconButtonEnhanced onClick={handleClick('NEXT')} disabled={index === items?.length - 1} color='primary'>
          <ArrowCircleRightRounded fontSize='small' />
        </IconButtonEnhanced>
      </Grid>
    </Grid>
  );
};

CardPaginator.propTypes = {
  items: PropTypes.array.isRequired,
  item: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.array])
};

export default CardPaginator;
