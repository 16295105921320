import { DEVICE_OS_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { ArrowDownwardRounded, ArrowUpwardRounded, DevicesOutlined, PersonOutlineRounded, SyncRounded, ViewHeadlineRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { TypographyEnhanced } from '../../../../../global';

export const FILTERS = {
  DEVICE_TYPE: {
    id: 'DEVICE_TYPE',
    label: 'Device Type',
    filters: [
      {
        id: 'DEVICE_TYPE_CHECKBOX',
        label: '',
        type: 'checkbox',
        options: Object.values(DEVICE_OS_TYPES)
      }
    ]
  },
  DEVICE_SYNC_STATUS: {
    id: 'DEVICE_SYNC_STATUS',
    label: 'Device Sync Status',
    filters: [
      {
        id: 'DEVICE_SYNC_STATUS_CHECKBOX',
        label: '',
        type: 'checkbox',
        options: [
          {
            id: 'SYNC_OVERDUE',
            name: 'Device sync overdue',
            value: 'SYNC_OVERDUE'
          },
          {
            id: 'SYNC_VALID',
            name: 'Device sync up to date',
            value: 'SYNC_VALID'
          }
        ]
      }
    ]
  }
};

export const SORT_VALUES = {
  SYNC_DATE_ASC: {
    id: 'SYNC_DATE_ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Sync Date
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'SYNC_DATE_ASC',
    label: ''
  },
  SYNC_DATE_DESC: {
    id: 'SYNC_DATE_DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Sync Date
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'SYNC_DATE_DESC',
    label: ''
  },
  USERNAME_ASC: {
    id: 'USERNAME_ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          User Name
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'USERNAME_ASC',
    label: ''
  },
  USERNAME_DESC: {
    id: 'USERNAME_DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          User Name
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'USERNAME_DESC',
    label: ''
  },
  DEVICE_NAME_ASC: {
    id: 'DEVICE_NAME_ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Device Name
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'DEVICE_NAME_ASC',
    label: ''
  },
  DEVICE_NAME_DESC: {
    id: 'DEVICE_NAME_DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Device Name
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'DEVICE_NAME_DESC',
    label: ''
  }
};

export const SORT_OPTIONS = Object.values(SORT_VALUES);

export const GROUP_BY_VALUES = {
  DEVICE_OS: {
    id: 'DEVICE_OS',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Device OS
        </TypographyEnhanced>
        <DevicesOutlined fontSize='small' />
      </Grid>
    ),
    value: 'DEVICE_OS',
    label: ''
  },
  USER: {
    id: 'USER',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          User
        </TypographyEnhanced>
        <PersonOutlineRounded fontSize='small' />
      </Grid>
    ),
    value: 'USER',
    label: ''
  },
  DEVICE_SYNC: {
    id: 'DEVICE_SYNC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Sync Status
        </TypographyEnhanced>
        <SyncRounded fontSize='small' />
      </Grid>
    ),
    value: 'DEVICE_SYNC',
    label: ''
  },
  VIEW_ALL: {
    id: 'VIEW_ALL',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          View All
        </TypographyEnhanced>
        <ViewHeadlineRounded fontSize='small' />
      </Grid>
    ),
    value: 'VIEW_ALL',
    label: ''
  }
};

export const GROUP_BY_OPTIONS = Object.values(GROUP_BY_VALUES);

export const GROUP_BY_KEYS = Object.keys(GROUP_BY_VALUES);

export const SEARCHABLE_KEYS = ['version', 'model', 'username', 'type', 'deviceType', 'deviceName'];
