import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold'
  }
}));

const SingleCategoryTitle = ({ category }) => {
  const classes = useStyles();
  const { index = '', name = '' } = category || {};
  const title = `${index} ${name}`;
  return (
    <Typography variant='h5' className={classes.bold}>
      {title}
    </Typography>
  );
};
SingleCategoryTitle.propTypes = {
  category: PropTypes.object
};
export default SingleCategoryTitle;
