import { CloseRounded } from '@mui/icons-material';
import { Box, Divider, Drawer, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import ButtonEnhanced from '../button';
import IconButtonEnhanced from '../iconButton';
import TypographyEnhanced from '../typography';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  drawer: {
    width: '100%',
    flexShrink: 0
    // whiteSpace: 'nowrap' //commented to fix TextfieldEnhanced labels not showing properly in side modal on manage issues
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100vw'
    },
    height: '100%',
    padding: theme.spacing(2)
  }
}));

/**
 *
 * @description:
 * Drawer with header and footer
 *
 * @typedef {import('@mui/material').DrawerProps} DrawerProps Props for `Drawer` from '@mui/material` library
 *
 * @param {Boolean} open drawer state
 * @param {VoidFunction} toggle function onclose for drawer
 * @param {'right'|'left'|'bottom'|'top'} anchor placement of drawer
 * @param {String | JSX} title heading in the footer
 * @param {JSX} children body of drawer
 * @param {Boolean} header to hide or display header
 * @param {Boolean} footer to hide or display footer
 * @param {JSON} drawerProps props for drawer component
 * @param {JSON} containerProps props for container component
 * @param {{drawerProps: JSON, containerProps: JSON} & DrawerProps} props
 *
 * @returns {React.FC<DrawerProps>}
 */
const DrawerEnhanced = ({ open, toggle, anchor = 'right', title = '', children, header = true, footer = false, drawerProps = {}, containerProps = {} }) => {
  const classes = useStyles();

  return (
    <Drawer open={open} onClose={toggle} anchor={anchor} className={classes.drawer} {...drawerProps}>
      <Box className={classes.container} {...containerProps}>
        <Grid container direction='column' justifyContent='space-between' spacing={2} height='100%'>
          <Grid container item spacing={2}>
            {/* TOP NAV OFFSET  */}
            <Grid container item xs={12} className={classes.appBarSpacer} />

            {header && (
              <Fragment>
                {/* TITLE AREA */}
                <Grid container item justifyContent='space-between' spacing={2}>
                  <Grid item xs>
                    {title}
                  </Grid>
                  <Grid item>
                    <IconButtonEnhanced size='small' onClick={toggle}>
                      <CloseRounded />
                    </IconButtonEnhanced>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Fragment>
            )}

            {/* BODY */}
            <Grid container item xs={12} spacing={2}>
              {children}
            </Grid>
          </Grid>

          {/* FOOTER */}
          {footer && (
            <Grid container item spacing={2} justifyContent='flex-end'>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item>
                <ButtonEnhanced variant='outlined'>
                  <TypographyEnhanced id='common.button.cancel' />
                </ButtonEnhanced>
              </Grid>
              <Grid item>
                <ButtonEnhanced>
                  <TypographyEnhanced id='common.button.save' />
                </ButtonEnhanced>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Drawer>
  );
};

DrawerEnhanced.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  header: PropTypes.bool,
  footer: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  drawerProps: PropTypes.object,
  containerProps: PropTypes.object
};
export default DrawerEnhanced;
