import { CrisisAlertRounded, NewspaperRounded } from '@mui/icons-material';

const ASSESSMENTS = [
  // original cyber assessment and shadow frameworks menu items
  // {
  //   id: 'assessments_cyber',
  //   icon: <NewspaperRounded />,
  //   label: 'Cyber Assessment',
  //   to: '/app/assessment/cyber',
  //   viewEvent: 'assessments_cyber-page_visited'
  // },
  // {
  //   id: 'shadow_assessments',
  //   icon: <HealthAndSafetyRounded />,
  //   label: 'Shadow Frameworks',
  //   to: '/app/assessment/shadow',
  //   mobile: true
  // },
  {
    id: 'assessments_cyber',
    icon: <NewspaperRounded />,
    label: 'Cyber Assessment',
    to: '/app/assessment/cyber',
    viewEvent: 'assessments_cyber-page_visited'
  },
  {
    id: 'assessments_threat_modelling',
    icon: <CrisisAlertRounded />,
    label: 'Threat Modeling',
    to: '/app/assessment/threat-modeling',
    viewEvent: 'assessments_threat_modeling-page_visited'
  }
];
export default ASSESSMENTS;
