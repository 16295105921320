import { CLOUD_INFRASTRUCTURE } from './cloudInfrastructure';
import { DEVICES } from './devices';
import { NEWS } from './news';
import { WEB_SECURITY_HEADERS } from './webSecurityHeaders';

export const REPORTS = {
  DEVICES,
  WEB_SECURITY_HEADERS,
  CLOUD_INFRASTRUCTURE,
  NEWS
};
