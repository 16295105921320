import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { UserAccountSettings } from '../../../../components/application/settings/user';
import { Heading } from '../../../../components/ui';
import { TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  title: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const UserSettings = () => {
  const classes = useStyles();

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title')} description={TRANSLATION_PATH('description')} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <UserAccountSettings />
        </Grid>
      </Grid>
    </Container>
  );
};
export default UserSettings;
