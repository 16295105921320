import { IMAGES } from '@anirudhm9/base-lib/lib/constants';
import { Close } from '@mui/icons-material';
import { Box, Card, CardContent, Dialog, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useWindowDimensions } from '../../../../../../hooks';
import { logEvent } from '../../../../../../library';
import { ButtonEnhanced, IconButtonEnhanced, TypographyEnhanced } from '../../../../../global';
import { Image } from '../../../../../ui';
import { LOG_EVENT_PATH, TRANSLATION_PATH } from './constants/defaults';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(5),
    paddingTop: 0
  },
  buttonGroup: {
    marginTop: theme.spacing(2)
  }
}));

const ClaimPointsModal = ({ data, open, toggleModal }) => {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  const [pop, setPop] = useState(true);
  const { label, pointValue } = data || {};

  //  reset confetti on unmount
  useEffect(() => {
    return () => {
      setPop(true);
    };
  }, []);

  const handleAction = () => {
    logEvent(LOG_EVENT_PATH('redeem'), { achievement: label });
    toggleModal();
  };

  return (
    <Dialog
      open={open}
      BackdropProps={{
        children: (
          <Confetti
            width={width}
            height={height}
            confettiSource={{
              w: 10,
              h: 10,
              x: width / 2,
              y: height / 3
            }}
            gravity={0.081}
            initialVelocityX={13}
            initialVelocityY={10}
            numberOfPieces={pop ? 300 : 0}
            recycle={false}
            onConfettiComplete={(confetti) => {
              confetti.reset();
            }}
          />
        )
      }}
    >
      <Card>
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <IconButtonEnhanced
              size='small'
              onClick={() => {
                setPop(true);
                toggleModal();
              }}
            >
              <Close />
            </IconButtonEnhanced>
          </Grid>
        </Grid>
        <CardContent className={classes.card}>
          <Grid item xs={12} container spacing={3} alignItems='center' justifyContent='center'>
            <Grid item>
              <Box sx={{ height: '150px', width: '150px' }}>
                <Image src={IMAGES.REWARDS.REDEEM_STAR} alt='success' />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TypographyEnhanced id={TRANSLATION_PATH('congrats')} variant='h4' sx={{ fontWeight: 'bold' }} textAlign='center' />
            </Grid>
            {/* summary */}
            <Grid item xs={12}>
              <TypographyEnhanced id={TRANSLATION_PATH('earned')} value={{ value: pointValue }} textAlign='center' />
              <TypographyEnhanced id={TRANSLATION_PATH('balance')} value={{ newBalance: pointValue }} textAlign='center' />
            </Grid>
          </Grid>
          {/* action */}
          <Grid container justifyContent='center' className={classes.buttonGroup}>
            <Grid item>
              <ButtonEnhanced onClick={handleAction}>
                <TypographyEnhanced id={TRANSLATION_PATH('shareButton')} />
              </ButtonEnhanced>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
};

ClaimPointsModal.propTypes = {
  data: PropTypes.object.isRequired,
  status: PropTypes.string,
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default ClaimPointsModal;
