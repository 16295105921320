/* eslint-disable quotes */

// views.app.assessments.cyberAssessment

export const CYBER_ASSESSMENT = {
  title: 'Cyber Assessment',
  titleShadow: 'Shadow Assessment',
  description:
    "The purpose of this self-assessment is to provide a reasonable understanding of {{org}}'s cyber resilience posture based on the effectiveness of key controls. The self-assessment framework is based on the Minimum Viable Secure Product which defines a minimum security baseline for enterprise-ready products and services. The contributors to this framework are Okta, Google, Salesforce, Slack, and Vanta."
};
