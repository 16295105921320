import { preventEnter } from '@anirudhm9/base-lib/lib/utils';
import { Download } from '@mui/icons-material';
import { Divider, FormControl, Grid, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { apiEndpoint } from '../../../constants/defaults';
import { SecureDownload } from '../../../containers/dialogs';
import downloadFile from '../../../helpers/functions/fileDownload';
import { ButtonEnhanced, Dropzone } from '../../global';

/**
 * @description: Upload box to render title, dropzone and save button
 * @typedef {import('../../global/dropzone').propTypes} DropzoneProps Props
 *
 * @param {{title: String, onClick: Function}} readMe: list of framework in org
 * @param {[{key: String, title: String | JSX, defaultFile: JSON, currentFile: JSON, upload: {name: String & DropzoneProps}}]} options: current selected framework type
 * @param {{title: String, onClick: Function, loading: Boolean, disabled: Boolean}} save: Save button config
 * @param {JSX} children: function to change selected framework
 *
 * @returns {JSX}
 */

const UploadBoxEnhanced = ({ readMe, options = [], save, children }) => {
  const [fileToDownload, setFileToDownload] = useState({});
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);

  const handleDownload = (config) => () => {
    setFileToDownload(config);
    setDownloadModalOpen(true);
  };

  const toggle = () => {
    setDownloadModalOpen((open) => !open);
  };

  return (
    <Grid container spacing={2} mt={0.5} mb={0.5}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {readMe && (
        <Grid item xs={12}>
          <Link onClick={() => readMe.onClick()}>{readMe.title}</Link>
        </Grid>
      )}
      {children}
      {options.map((option) => {
        const { key, title, defaultFile, currentFile, upload } = option || {};
        const { name } = upload || {};
        return (
          <Grid container item key={key} spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant='body1'>{title}</Typography>
              <Grid container justifyContent='space-between'>
                {defaultFile && (
                  <ButtonEnhanced variant='outlined' size='small' onClick={handleDownload(defaultFile.config)}>
                    <Download />
                    {defaultFile.title}
                  </ButtonEnhanced>
                )}
                {currentFile && (
                  <ButtonEnhanced variant='outlined' size='small' onClick={handleDownload(currentFile.config)}>
                    <Download />
                    {currentFile.title}
                  </ButtonEnhanced>
                )}
              </Grid>
            </Grid>
            {upload && (
              <Grid item xs={12} lg={6}>
                <FormControl variant='outlined' fullWidth onKeyDown={preventEnter}>
                  <Dropzone {...upload} />
                  {name && (
                    <Grid item>
                      <Typography variant='caption'>{name}</Typography>
                    </Grid>
                  )}
                </FormControl>
              </Grid>
            )}
          </Grid>
        );
      })}
      {save && (
        <Grid container item sm={12} justifyContent='flex-end'>
          <ButtonEnhanced onClick={save.onClick} loading={save.loading} disabled={save.disabled}>
            {save.title}
          </ButtonEnhanced>
        </Grid>
      )}
      <SecureDownload
        onClick={() => {
          downloadFile(`${apiEndpoint}/upload/securefile/${fileToDownload.hash}`);
          setDownloadModalOpen(false);
        }}
        open={downloadModalOpen}
        toggle={toggle}
        file={fileToDownload || {}}
        href={fileToDownload?.href}
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

UploadBoxEnhanced.propTypes = {
  readMe: PropTypes.shape({
    title: PropTypes.string,
    onClick: PropTypes.func
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      defaultFile: PropTypes.shape({
        title: PropTypes.string,
        config: PropTypes.shape({
          href: PropTypes.string,
          name: PropTypes.string,
          ext: PropTypes.string
        })
      }),
      currentFile: PropTypes.shape({
        title: PropTypes.string,
        config: PropTypes.shape({
          href: PropTypes.string,
          name: PropTypes.string,
          ext: PropTypes.string
        })
      }),
      upload: PropTypes.shape({
        name: PropTypes.string,
        ...Dropzone.propTypes
      })
    })
  ),
  save: PropTypes.shape({
    title: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
  }),
  children: PropTypes.any
};
export default UploadBoxEnhanced;
