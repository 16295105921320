import _ from 'lodash';

/**
 * @description:
 * Function to provide an array matrix to group options into categories
 * @param {Array} options
 * @returns {[Array]}
 */
export const groupedOptions = (options = []) => {
  const categories = options.filter((option) => option.category).map((option) => option.category);

  // If no category key is found
  if (!categories.length) {
    return [[...(options || [])]];
  }

  // Array of unique categories
  const uniqueCategories = _.uniq(categories);

  // Matrix of categoriesed arrays
  const categorisedArray = uniqueCategories.map((category) => [
    { type: 'category', name: category },
    ...options.filter((option) => option.category === category)
  ]);

  const result = categorisedArray.sort((a, b) => {
    const { name: aName } = a?.find((item) => item.type === 'category') || {};
    const { name: bName } = b?.find((item) => item.type === 'category') || {};

    return aName?.localeCompare(bName);
  });

  // If some options dont have category key, categorise them as 'Other'
  const uncatagorised = (options || []).filter((option) => !uniqueCategories.includes(option.category));
  if (uncatagorised.length) {
    return [...result, [{ type: 'category', name: 'Other' }, ...uncatagorised]];
  }

  return result;
};
