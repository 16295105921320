import { FEEDBACK_TYPES } from '@anirudhm9/base-lib/lib/constants/api/feedback';
import { logEvent } from '../../../library';
import { USER_ACTIONS as USER_ACTIONS_EVENTS } from '../../../library/amplitude/events/common';
import { INTEGRATIONS as INTEGRATIONS_EVENTS } from '../../../library/amplitude/events/views/app/integrations';

export const FEEDBACK_MODAL_CONTENT = {
  REQUEST: {
    header: 'Suggest an Application',
    name: 'Application name',
    description: 'Description'
  },
  BUG: {
    header: 'Report an issue?',
    name: 'Title',
    description: 'Description'
  }
};

export const FEEDBACK_REQUIRED_FIELDS = ['name'];

/**
 * Handles event log for different implementations of feedback modal
 *
 * @param {String} type
 * @param {Object} feedback
 * @returns
 */
export const handleFeedbackLogEvent = (type, feedback) => {
  const { name } = feedback || {};

  //feedback submissions must have a name (title)
  if (!FEEDBACK_TYPES[type] || !name) {
    return;
  }

  switch (type) {
    case FEEDBACK_TYPES.REQUEST.value:
      logEvent(INTEGRATIONS_EVENTS.SUGGEST_INTEGRATION, { suggested_integration: name });
      break;
    case FEEDBACK_TYPES.BUG.value:
      logEvent(USER_ACTIONS_EVENTS.REPORT_BUG, { title: name });
      break;
  }
};
