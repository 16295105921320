import { useMutation } from '@apollo/client';
import { CheckRounded, CloseRounded, RemoveRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { SAVE_ANSWER } from '../../../../../../../../../../api';
import { useOrgContext } from '../../../../../../../../../../contexts';
import { ChipEnhanced, TypographyEnhanced } from '../../../../../../../../../global';
import SelectInput from '../../../selectInput';

const useStyles = makeStyles((theme) => ({
  grey: {
    fill: 'grey'
  },
  green: {
    fill: theme.palette.green.main
  },
  red: {
    fill: theme.palette.red.main
  }
}));

const Mapping = ({ control, increase = 0, refetch, subjective = false, satisfactory, notApplicable }) => {
  const classes = useStyles();

  const { orgId } = useOrgContext();

  const { id, name, mappingNumber, options, datapoint = {} } = control || {};

  const [saveAnswer] = useMutation(SAVE_ANSWER);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!(datapoint?.selected || []).length) {
      return;
    }

    setSelectedOptions(datapoint?.selected?.map((select) => select?.id) || []);
  }, [datapoint?.selected]);

  const Label = () => {
    return (
      <Grid item xs={12}>
        <ChipEnhanced
          id={String(increase || 0)}
          icon={
            notApplicable ? (
              <RemoveRounded className={classes.grey} />
            ) : satisfactory ? (
              <CheckRounded className={classes.green} />
            ) : (
              <CloseRounded className={classes.red} />
            )
          }
          size='small'
        />
        <TypographyEnhanced id={mappingNumber} display='inline' fontWeight='bold' ml={0.5} />
        <TypographyEnhanced id={''} display='inline'>
          :
        </TypographyEnhanced>
        <TypographyEnhanced id={name} display='inline' ml={0.5} />
      </Grid>
    );
  };

  // Save datapoint
  const handleSave = async (data = {}) => {
    const { selected } = data || {};

    // if type of question is select/multi-select and no changes are made, do not save the answer
    // const selectedChanged = selectedOptionsChanged(resultSelected);
    // if (!selectedChanged) {
    //   return;
    // }

    // datapoint to update
    const datapoint = {
      selected
    };

    const saveData = async () => {
      try {
        setLoading(true);

        await saveAnswer({
          variables: {
            id,
            org: orgId,
            datapoint,
            options: []
          }
        });
        await refetch();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    await saveData();
    // setCallback({ id, execute: saveData });
  };

  return (
    <Grid container spacing={1}>
      <Label />
      {subjective && (
        <Grid container item justifyContent='flex-end'>
          <SelectInput
            id={id}
            label={''}
            options={options || []}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            row
            onSave={handleSave}
            disabled={loading}
          />
        </Grid>
      )}
    </Grid>
  );
};

Mapping.propTypes = {
  control: PropTypes.object,
  increase: PropTypes.number,
  refetch: PropTypes.func,
  subjective: PropTypes.bool,
  satisfactory: PropTypes.bool,
  notApplicable: PropTypes.bool
};

export default Mapping;
