import { logEvent } from '../../../../../../library';
import { USER as ACCOUNT_SETTINGS_EVENTS } from '../../../../../../library/amplitude/events/views/app/settings/user';

/**
 * Logs account settings update event when save button is clicked
 *
 * @param {Object} localData
 * @param {Object} user
 * @param {Boolean} isSSOUser
 */
const logAccountSettingsUpdate = (localData, user, isSSOUser) => {
  const localDataCopy = localData;

  //dont log pw changes
  if (!isSSOUser && localDataCopy.oldPassword) {
    delete localDataCopy.oldPassword;
    delete localDataCopy.password;
    delete localDataCopy.passwordConfirmation;
  }

  const localDataKeys = Object.keys(localDataCopy);

  const updatedFields = localDataKeys.reduce(
    (result, current) => {
      if (user[current] && user[current] !== localData[current]) {
        result[current] = { old: user[current], new: localData[current] };
        return result;
      }
      return result;
    },
    { user_id: user?.id }
  );

  logEvent(ACCOUNT_SETTINGS_EVENTS.UPDATE_DETAILS, updatedFields);
};

/**
 * Logs profile picture update event
 *
 * @param {String} oldImage
 * @param {String} newImage
 */
const logUpdateAvatar = (oldImage, newImage) => {
  if (oldImage === newImage || !newImage) {
    return;
  }

  logEvent(ACCOUNT_SETTINGS_EVENTS.UPDATE_AVATAR, { old: oldImage, new: newImage });
};

export { logAccountSettingsUpdate, logUpdateAvatar };
