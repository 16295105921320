import { Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import AnimatedNumber from '../../../../animations/number';
import { TypographyEnhanced } from '../../../../global';

const TRANSLATION_PATH = (route) => {
  return `components.application.dashboard.summaries.rewardStatus.${route}`;
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  card: {
    height: '135px'
  }
}));

const RewardStatus = () => {
  const classes = useStyles();

  //TODO get real points value
  const totalPoints = 550;

  return (
    <Grid item xs={12}>
      <Card className={classes.card}>
        <CardContent sx={{ height: '100%' }}>
          <Grid container justifyContent='space-between' alignItems='center' sx={{ height: '100%' }}>
            <Grid item xs={8}>
              <TypographyEnhanced id={TRANSLATION_PATH('title')} sx={{ fontWeight: 'bold' }} />
              <TypographyEnhanced id={TRANSLATION_PATH('description')} variant='caption' />
            </Grid>
            <Grid container item xs={4} justifyContent='center'>
              <AnimatedNumber to={totalPoints} variant='h6' />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default RewardStatus;
