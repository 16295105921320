import { createFilterOptions } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { TypographyEnhanced } from '../../../../../global';
import { AutoCompleteEnhanced } from '../../../../../ui/form';
import AppLogo from '../../appLogo';

const filter = createFilterOptions();

const renderOption = (props, option) => {
  const { inputValue, logoUrl, name } = option || {};

  return (
    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      <AppLogo loading='lazy' url={logoUrl || ''} alt={inputValue || name || ''} style={{ height: 20, width: 20, marginRight: 5 }} />
      <TypographyEnhanced id={name || ''} />
    </Box>
  );
};

const ApplicationAutoComplete = ({ options = [], onChange, value, ...props }) => {
  return (
    <AutoCompleteEnhanced
      id='applicationSelector'
      label='Search for an Application, or if you cannot find yours, add it in manually'
      options={options}
      onChange={onChange}
      renderOption={renderOption}
      value={value}
      errorText=''
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => {
          const { name = '', aliases = [] } = option || {};
          const lowerCaseName = (name || '').toLowerCase();

          const lowerCaseInputValue = (inputValue || '')?.toLowerCase();
          return lowerCaseInputValue === lowerCaseName || aliases?.map((alias) => alias.toLowerCase())?.includes(lowerCaseInputValue);
        });

        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`
          });
        }

        return filtered;
      }}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      {...props}
    />
  );
};

ApplicationAutoComplete.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default ApplicationAutoComplete;
