import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { TypographyEnhanced } from '../../../../../global';
import { BarChart } from '../../../../../ui';

const UnaddressedRisks = ({ options, onClick: click, loading }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TypographyEnhanced id='Key Unaddressed Risks' variant='body2' fontWeight='bold' />
      </Grid>
      <Grid item xs={12}>
        <TypographyEnhanced id='Do something about these as soon as you can as not addressing them significantly increases your risk' />
      </Grid>
      <Grid item xs={12}>
        <BarChart options={options} loading={loading} onEvents={{ click }} />
      </Grid>
    </Grid>
  );
};

UnaddressedRisks.propTypes = {
  options: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default UnaddressedRisks;
