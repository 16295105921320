import { FilterListRounded } from '@mui/icons-material';
import { Badge, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { logEvent } from '../../../library';
import { GROUP_BY_KEYS } from '../../application/manage/devices/filter/constants';
import { IconButtonEnhanced } from '../../global';
import { SelectEnhanced } from '../form';
import Search from '../search';
import { TRANSLATION_PATH } from './constants';
import FilterDrawer from './drawer';

const Filter = ({
  onFilter,
  onFilterReset,
  onSearch,
  onSearchReset,
  defaultSearch,
  onGroupBy,
  groupByOptions,
  groupByValue,
  handleGroupBy,
  sortOptions,
  handleSort,
  sortValue,
  searchLimit,
  totalFilters = 0,
  children,
  icon = <FilterListRounded />,
  eventPath
}) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((open) => !open);
  };

  const justifyContent = useMemo(() => {
    if (!onSearch) {
      return 'flex-end';
    }

    if (!onFilter) {
      return 'flex-start';
    }
    return 'space-between';
  }, [onFilter, onSearch]);

  const handleSearch = (value) => {
    logEvent(eventPath?.SEARCH, { search: value });
    onSearch(value);
  };

  const handleFilter = (value) => {
    const { selectedFilters } = children?.props?.children?.props || {};
    logEvent(eventPath?.FILTER, { filters: selectedFilters });
    onFilter(value);
  };

  if (!onSearch && !onFilter) {
    return null;
  }

  return (
    <Grid container item justifyContent={justifyContent} alignItems='flex-start' spacing={2}>
      {onSearch && (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Search id={TRANSLATION_PATH('search.label')} limit={searchLimit} onSearch={handleSearch} onReset={onSearchReset} defaultValue={defaultSearch} />
        </Grid>
      )}

      {onGroupBy && (
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <SelectEnhanced fullWidth={false} options={groupByOptions} value={groupByValue} onChange={handleGroupBy} size='small' />
        </Grid>
      )}

      {onFilter && (
        <Grid container item xs justifyContent='flex-end' spacing={2}>
          {/* SORT */}
          {handleSort && sortOptions?.length ? (
            <Grid item>
              <SelectEnhanced fullWidth={false} options={sortOptions || []} value={sortValue || ''} onChange={handleSort} size='small' />
            </Grid>
          ) : null}

          {/* FILTERS */}
          <Grid item>
            <Badge badgeContent={totalFilters} color='primary'>
              <IconButtonEnhanced onClick={toggle} size='small'>
                {icon}
              </IconButtonEnhanced>
            </Badge>
          </Grid>
          <FilterDrawer open={open} toggle={toggle} onFilter={handleFilter} onReset={onFilterReset}>
            {children}
          </FilterDrawer>
        </Grid>
      )}
    </Grid>
  );
};

Filter.propTypes = {
  onFilter: PropTypes.func,
  onFilterReset: PropTypes.func,
  onSearch: PropTypes.func,
  onSearchReset: PropTypes.func,
  defaultSearch: PropTypes.string,
  onGroupBy: PropTypes.bool,
  groupByOptions: PropTypes.array,
  groupByValue: PropTypes.oneOf(GROUP_BY_KEYS),
  handleGroupBy: PropTypes.func,
  sortOptions: PropTypes.array,
  handleSort: PropTypes.func,
  sortValue: PropTypes.string,
  sort: PropTypes.bool,
  toggleSort: PropTypes.func,
  searchLimit: PropTypes.number,
  totalFilters: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  icon: PropTypes.element,
  eventPath: PropTypes.object
};

export default Filter;
