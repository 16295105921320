import { useEffect } from 'react';
import { logEvent } from '../../library';

const useLogEvent = (event, properties) => {
  useEffect(() => {
    logEvent(event, { ...properties });
  }, [event, properties]);
};

export default useLogEvent;
