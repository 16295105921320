import { CRITICALITIES } from '@anirudhm9/base-lib/lib/constants';
import { RectangleRounded } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { TooltipEnhanced } from '../../../../global';

const CriticalityBox = ({ criticality = CRITICALITIES.LOW, tooltip = false, title = '', ...props }) => {
  const theme = useTheme();
  const [color, setColor] = useState('');
  const [tooltipTitle, setTooltipTitle] = useState('');

  useEffect(() => {
    const { value = '' } = criticality || {};
    const { color = '', name = '' } = CRITICALITIES[value] || {};
    setColor(theme.palette?.[color]?.main || '');
    setTooltipTitle(title || name);
  }, [criticality, theme.palette, title]);

  if (!tooltip) {
    return <RectangleRounded sx={{ color }} {...props} />;
  }

  return (
    <TooltipEnhanced title={tooltipTitle} boxProps={{ sx: { display: 'flex', alignItems: 'center' } }}>
      <RectangleRounded sx={{ color }} {...props} />
    </TooltipEnhanced>
  );
};

CriticalityBox.propTypes = {
  criticality: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    value: PropTypes.string
  }),
  tooltip: PropTypes.bool,
  title: PropTypes.string
};

export default CriticalityBox;
