import { ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const Container = ({ children, open, handleClose, anchorRef }) => {
  return (
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
      {({ TransitionProps = {}, placement }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <div>{children}</div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

Container.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorRef: PropTypes.object.isRequired
};

export default Container;
