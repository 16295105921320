import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import SingleQuestion from './singleQuestion';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const QuestionGroup = ({ questions, customTimeout, setCustomTimeout, refetch, shadow }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} item xs={12} elevation={6} className={classes.root}>
      {(questions || []).map((question) => (
        <SingleQuestion
          key={question?.id}
          question={question}
          customTimeout={customTimeout}
          setCustomTimeout={setCustomTimeout}
          refetch={refetch}
          shadow={shadow}
        />
      ))}
    </Grid>
  );
};

QuestionGroup.propTypes = {
  questions: PropTypes.array,
  customTimeout: PropTypes.object,
  setCustomTimeout: PropTypes.func,
  refetch: PropTypes.func,
  shadow: PropTypes.bool
};

export default QuestionGroup;
