import { useMediaQuery } from '@mui/material';

/**
 * Custom hook to useIsDesktop
 * Example Code :
 *
 * @param {String} width: pixels (optional)
 * @param {String} height: pixels (optional)
 * @returns {Boolean}
 **/
const useIsDesktop = (width = '600px', height = '600px') => {
  return useMediaQuery(`(min-width:${width}) and (min-height:${height})`);
};

export default useIsDesktop;
