import { Container, Grid, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AddUser, UserAvatar, UserFilter } from '../../../../../components/application/manage';
import { filterUsers } from '../../../../../components/application/manage/users/filter/utils';
import { ButtonEnhanced, CardWithLoader, TypographyEnhanced } from '../../../../../components/global';
import { Heading } from '../../../../../components/ui';
import ModalEnhanced from '../../../../../components/ui/modal';
import { useUsersContext } from '../../../../../contexts/usersContext';
import { logEvent } from '../../../../../library';
import { USERS as MANAGE_USERS_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/users';
import { DEFAULT_FILTER, TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  title: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const ManageUsers = () => {
  const classes = useStyles();
  //deconstruct state passed through by useNavigate
  const { state } = useLocation();
  const { filter: defaultFilter } = state || {};

  const { users, loading: usersLoading, error: usersError } = useUsersContext();

  const [modalOpen, setModalOpen] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  //? Should change AddUser/ViewUser functionality to behave as in manage/issues?
  useEffect(() => {
    if (!modalOpen) {
      return;
    }
    logEvent(MANAGE_USERS_EVENTS.ADD_USER_MODAL);
  }, [modalOpen]);

  useEffect(() => {
    if (usersLoading || usersError) {
      return;
    }
    const filteredUsers = filterUsers(users || [], defaultFilter || DEFAULT_FILTER);
    setFilteredUsers(filteredUsers);
  }, [defaultFilter, users, usersError, usersLoading]);

  return (
    <Fragment>
      <Container component='main' className={classes.root} maxWidth={false}>
        <Heading id={TRANSLATION_PATH('title')} description={TRANSLATION_PATH('description')} />
        <UserFilter users={users || []} setUsers={setFilteredUsers} defaultFilter={defaultFilter} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CardWithLoader
              cardHeaderProps={{
                title: (
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <Stack>
                        <Grid item>
                          <TypographyEnhanced id={TRANSLATION_PATH('cardTitle')} variant='h6' display='inline' />
                          <TypographyEnhanced id={`${filteredUsers?.length || 0}/${users?.length || 0}`} variant='h6' display='inline' ml={2} />
                        </Grid>
                        <TypographyEnhanced id={TRANSLATION_PATH('cardSubtitle')} variant='caption' />
                      </Stack>
                    </Grid>
                    <Grid item justifySelf='flex-end'>
                      <ButtonEnhanced size='medium' onClick={() => setModalOpen(true)}>
                        <TypographyEnhanced id={TRANSLATION_PATH('buttons.add')} />
                      </ButtonEnhanced>
                    </Grid>
                  </Grid>
                )
              }}
              loading={usersLoading}
            >
              <Grid container spacing={2}>
                {(filteredUsers || [])?.map((user) => (
                  <UserAvatar key={user.id} user={user} />
                ))}
              </Grid>
            </CardWithLoader>
          </Grid>
        </Grid>
      </Container>
      <ModalEnhanced modalOpen={modalOpen} setModalOpen={() => setModalOpen(!modalOpen)}>
        <AddUser />
      </ModalEnhanced>
    </Fragment>
  );
};

export default ManageUsers;
