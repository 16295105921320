/* eslint-disable quotes */
// views.user.register.userForm

export const LOGIN = {
  email: { label: 'Email', placeholder: 'Your email address' },
  password: { label: 'Password', placeholder: 'Choose password' },
  rememberMe: 'Remember me',
  forgotPassword: 'Forgot password?',
  buttons: { login: 'Log in', signUp: "Don't have an account yet? Sign up" }
};
