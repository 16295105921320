const GRADE_COLORS = {
  A: 'blue',
  B: 'green',
  C: 'yellow',
  D: 'orange',
  E: 'red',
  F: 'red',
  NOT_APPLICABLE: 'grey'
};

export { GRADE_COLORS };
