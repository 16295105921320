import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { ChipEnhanced } from '../../../../global';

const ScopesChip = ({ scopeItem }) => {
  // TODO maybe. Add tooltips that describe exactly what the scopes do?
  const scopeSplit = (scopeItem || '').split('/') || [];
  const name = scopeSplit?.[scopeSplit?.length - 1] || scopeItem;
  return (
    <Grid item key={scopeItem}>
      <ChipEnhanced id={name || ''} variant='outlined' />
    </Grid>
  );
};

ScopesChip.propTypes = {
  scopeItem: PropTypes.string
};

export default ScopesChip;
