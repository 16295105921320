import { Divider, Drawer, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ButtonEnhanced, TypographyEnhanced } from '../../../../global';
import CollapseEnhanced from '../../../../ui/collapse';
import { CheckboxEnhanced, TextFieldEnhanced } from '../../../../ui/form';
import { TRANSLATION_PATH } from '../constants/defaults';
import { FILTERS } from './constants/defaults';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  filtersContainer: {
    maxWidth: '300px',
    height: '100%',
    padding: theme.spacing(2)
  },
  footerContainer: {
    width: '300px',
    position: 'fixed',
    bottom: '0',
    padding: theme.spacing(2),
    zIndex: '99'
  }
}));

const FilterDrawer = ({ isOpen, toggleDrawer, updateFilters, filterTypes }) => {
  const classes = useStyles();

  const [filters, setFilters] = useState([]);
  const [selections, setSelections] = useState({});

  useEffect(() => {
    if (!filterTypes) {
      return;
    }

    setFilters(() => {
      return filterTypes?.map((type) => {
        return { ...FILTERS[type], key: type };
      });
    });
  }, [filterTypes]);

  const updateFilter = (label, selection) => {
    setSelections((prev) => {
      const prevCopy = prev;
      if (_.isEmpty(selection)) {
        delete prevCopy[label];
      } else {
        prevCopy[label] = selection;
      }
      return { ...prevCopy };
    });
  };

  const handleReset = () => {
    setSelections({});
    updateFilters({});
    toggleDrawer();
  };

  const handleFilter = () => {
    updateFilters(selections);
    toggleDrawer();
  };

  return (
    <Drawer open={isOpen} onClose={toggleDrawer} anchor='right'>
      <Box className={classes.filtersContainer}>
        <Grid container spacing={2} wrap='nowrap' direction='column' justifyContent='space-between' height='100%'>
          <Grid item container spacing={2}>
            {/* TOP NAV OFFSET */}
            <Grid item xs={12} className={classes.appBarSpacer} />

            {/* TITLE AREA */}
            <Grid item xs={12} container justifyContent='space-between'>
              <Grid item>
                <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h6' />
              </Grid>
            </Grid>

            {/* FILTERS */}
            {filters?.map((filter) => {
              const { key, type, label, options } = filter;
              switch (type) {
                case 'radio':
                  return (
                    <Grid item xs={12} key={key + label}>
                      <CollapseEnhanced label={label}>
                        <RadioGroup name='radio-buttons-group-categories' onChange={(e) => updateFilter(key, e.target.value)}>
                          {options.map((option) => {
                            const { label: optionLabel, value: optionValue } = option || {};
                            return (
                              <FormControlLabel
                                key={optionValue}
                                value={optionValue}
                                control={<Radio checked={selections?.[key]?.includes(optionValue) || false} color='primary' />}
                                label={optionLabel}
                              />
                            );
                          })}
                        </RadioGroup>
                      </CollapseEnhanced>
                    </Grid>
                  );
                case 'checkbox':
                  return (
                    <Grid item xs={12} key={key + label}>
                      <CollapseEnhanced label={label}>
                        <CheckboxEnhanced
                          value={selections?.[key] || []}
                          options={options?.map((option) => ({ id: option.value, name: option.label }))}
                          onChange={(e) => updateFilter(key, e)}
                        />
                      </CollapseEnhanced>
                    </Grid>
                  );
                default:
                  return (
                    <Grid item xs={12} key={key + label}>
                      <CollapseEnhanced label={label}>
                        <TextFieldEnhanced size='small' value={selections?.[key] || ''} onChange={(e) => updateFilter(key, e)} />
                      </CollapseEnhanced>
                    </Grid>
                  );
              }
            })}
          </Grid>

          {/* FOOTER */}
          <Grid item container spacing={2} justifyContent='flex-end'>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item>
              <ButtonEnhanced variant='outlined' onClick={() => handleReset()}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.clear')} />
              </ButtonEnhanced>
            </Grid>
            <Grid item>
              <ButtonEnhanced onClick={() => handleFilter()}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.filter')} />
              </ButtonEnhanced>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  updateFilters: PropTypes.func,
  filterTypes: PropTypes.array
};

export default FilterDrawer;
