import PropTypes from 'prop-types';
import { Image, LetterAvatar } from '../../../../ui';

const DeviceIcon = ({ user, src, ...props }) => {
  if (!user) {
    return <Image src={src} {...props} />;
  }
  return <LetterAvatar user={user} {...props} />;
};

DeviceIcon.propTypes = {
  user: PropTypes.object,
  src: PropTypes.string
};

export default DeviceIcon;
