import { preventEnter } from '@anirudhm9/base-lib/lib/utils';
import { Box, Checkbox, Chip, FormControl, FormHelperText, Grid, ListItemIcon, ListItemText, ListSubheader, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Fragment, useCallback } from 'react';
import { THEME } from '../../../../styles';
import { groupedOptions } from './utils';

const useStyles = makeStyles((theme) => ({
  listbox: {
    backgroundColor: theme.palette.mode === 'light' ? `${theme.palette.background.default} !important` : `${THEME.TOOLBAR_COLOR} !important`,
    backgroundImage: 'none'
  }
}));

/**
 * @description:
 * Material UI Select with customisation options
 * @typedef {import('@mui/material').SelectProps} SelectProps Props for `Select` from '@mui/material` library
 * @typedef {import('@mui/material').NativeSelectProps} NativeSelectProps Props for `Native` from '@mui/material` library
 *
 * @param {String} variant (optional) default: outlined
 * @param {String | Number} id for accessibility
 * @param {String}  label Label for the select dropdown
 * @param {String | Number} value unique value to identify items
 * @param {Function} onChange function triggered on change
 * @param {[{ id: Number, label: String, value: String | Number }]} options options to render the menu items
 * @param {Boolean} disabled to disable the textfield
 * @param {Boolean} multiple if multiple options can be selected
 * @param {Boolean} fullWidth width of select container
 * @param {String} placeholder text to display when nothing is selected
 * @param {String} itemType menu item type to display
 * @param {'chip' | 'number'} renderType selection type to display
 * @param {Boolean} hasError puts field into error state if true
 * @param {String} errorText show error text below field if hasError = true. defaultL empty space to allow consistent layout with other fields
 * @param {String} required for required fields
 * @param {{ selectAll: Boolean } & SelectProps & NativeSelectProps} props
 * @returns {React.FC<SelectProps>}
 */

const SelectEnhanced = ({
  variant = 'outlined',
  id,
  label,
  value,
  onChange,
  options,
  disabled,
  multiple = false,
  fullWidth = true,
  placeholder,
  itemType,
  renderType,
  selectAll,
  size = 'large',
  maxHeight = '50%',
  hasError = false,
  errorText = ' ',
  required,
  ...props
}) => {
  const classes = useStyles();
  const allSelected = multiple && options.length > 0 && value.length === options.length;

  const Item = (props) => {
    const { name, 'data-value': optionValue } = props;

    if (!multiple) {
      return <MenuItem {...props}>{name}</MenuItem>;
    }

    let result;
    switch (itemType) {
      case 'checkbox':
        result = (
          <Fragment>
            <Checkbox checked={value.indexOf(optionValue) > -1} />
            <ListItemText primary={name} />
          </Fragment>
        );
        break;
      default:
        result = name;
    }

    return <MenuItem {...props}>{result}</MenuItem>;
  };

  Item.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    'data-value': PropTypes.any
  };

  const renderValue = (selected) => {
    // Function to get a single option that matches the value from options
    const getOption = (value) => {
      return options.find((option) => option.id === value || option.value === value) || {};
    };

    if (!multiple) {
      const option = getOption(selected);
      return option.label || option.name;
    }

    let result = '';
    switch (renderType) {
      case 'chip':
        result = (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => {
              const option = getOption(value);
              return <Chip key={value} label={option.label || option.name} icon={option.icon} size={size} />;
            })}
          </Box>
        );
        break;
      case 'number':
        result = `${value.length || 0} selected`;
        break;
      default:
        result = selected
          .map((value) => {
            const option = getOption(value);
            return option.name;
          })
          .join(', ');
    }
    return result;
  };

  const handleChange = (currentValue) => {
    if (!multiple) {
      return onChange(currentValue);
    }

    if (currentValue[currentValue.length - 1] === 'all') {
      onChange(value.length === options.length ? [] : options.map((option) => option.id || option.value));
      return;
    }
    return onChange(currentValue);
  };

  const Label = useCallback(() => {
    if (typeof label === 'string') {
      return <Typography id={`input-select-${id}`}>{`${label || ''}${required ? '*' : ''}`}</Typography>;
    }
    return label;
  }, [id, label, required]);

  return (
    <Grid item>
      <FormControl variant={variant} fullWidth={fullWidth} size={size} error={hasError} onKeyDown={preventEnter}>
        <Label />
        <Select
          labelId={id}
          id={id}
          value={value || ''}
          onChange={(e) => handleChange(e.target.value)}
          disabled={disabled}
          multiple={multiple}
          placeholder={placeholder}
          renderValue={renderValue}
          MenuProps={{
            PaperProps: {
              className: classes.listbox,
              sx: {
                maxHeight
              }
            }
          }}
          {...props}
        >
          {selectAll && multiple && (
            <MenuItem value='all'>
              <ListItemIcon>
                <Checkbox checked={allSelected} indeterminate={!!value.length && value.length < options.length} />
              </ListItemIcon>
              <ListItemText primary='Select All' />
            </MenuItem>
          )}
          {(groupedOptions(options) || []).map((options) => {
            return (options || []).map((option) => {
              const { id: optionId, value: optionValue, name: optionName, label: optionLabel, type: optionType, disabled } = option || {};

              if (optionType === 'category') {
                return <ListSubheader>{optionName}</ListSubheader>;
              }
              return <Item key={optionId || optionValue} value={optionId || optionValue} name={optionLabel || optionName} disabled={disabled} />;
            });
          })}
        </Select>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </Grid>
  );
};

SelectEnhanced.propTypes = {
  variant: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,
  itemType: PropTypes.oneOf(['checkbox']),
  renderType: PropTypes.oneOf(['chip', 'number']),
  placeholder: PropTypes.string,
  selectAll: PropTypes.bool,
  size: PropTypes.string,
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool
};
export default SelectEnhanced;
