import _ from 'lodash';

/**
 *
 * @param {JSON} category - array of category objects
 * @param {JSON} filters - nested filters object
 * @param {String} searchTerm - string search term entered by user
 * @returns {JSON} - category
 */
export const filterAndSearchAssessment = (category, filters, searchTerm = '') => {
  const categoryCopy = _.cloneDeep(category || {});

  const searchText = (searchTerm || '').toLowerCase();
  const searchFields = ['name', 'mappingNumber'];

  // Flatten out each filter group object into a single array
  const filterGroupsFlat = Object.values(filters).map((filterGroup) => {
    const filterGroupFlat = Object.values(filterGroup).flat(1);
    return filterGroupFlat;
  });
  // Flatten all filter group arrays into one array of all selected filters
  const filtersFlat = filterGroupsFlat.flat(1);

  const filter = (question, key) => {
    const { datapoint } = question || {};
    switch (key) {
      case 'NOT_ANSWERED':
        return !datapoint;
      case 'ANSWERED':
        return !!datapoint;
      default:
        return false;
    }
  };

  const process = (category) => {
    const categoryFields =
      searchText && searchFields.some((fieldName) => category && category[fieldName] && category[fieldName].toLowerCase().includes(searchText));

    const { children = [], questions = [] } = category || {};
    if ((questions || []).length) {
      if (!categoryFields) {
        category.questions = questions.filter((question) => {
          if (filtersFlat.length && searchText) {
            return (
              filtersFlat.some((filterKey) => filter(question, filterKey)) &&
              searchFields.some((fieldName) => question && question[fieldName] && question[fieldName].toLowerCase().includes(searchText))
            );
          } else if (filtersFlat.length) {
            return filtersFlat.some((filterKey) => filter(question, filterKey));
          }
          return searchFields.some((fieldName) => question && question[fieldName] && question[fieldName].toLowerCase().includes(searchText));
        });
        category.shouldDelete = !category.questions.length;
      }
    }

    if ((children || []).length) {
      if (!categoryFields) {
        category.children = children.map((child) => process(child));
        category.children = category.children.filter((child) => !child.shouldDelete);
        category.shouldDelete = !category.children.length;
      }
    }

    return category;
  };

  return process(categoryCopy);
};
