// components.application.rewards.filter

export const FILTER = {
  title: 'Filter',
  headings: {
    keyword: 'Keyword',
    categories: 'Categories',
    status: 'Status'
  },
  buttons: {
    clear: 'Clear',
    filter: 'Filter items'
  }
};
