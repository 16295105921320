import { LOG_SUB_TYPES, LOG_TYPES, PAGE_THRESHOLD, QUESTION_TYPES, TEXT_INPUT_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { compareHtmlStrings, formatErrorMessage, symmetricDifference } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { Divider, Grid, Typography, styled } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { CREATE_LOG, SAVE_ANSWER, UPDATE_LOG, UPDATE_QUESTION_ASSIGNEES } from '../../../../../../api';
import { defaultMessages } from '../../../../../../constants';
import { BotDetectionModal } from '../../../../../../containers';
import { useOrgContext, useUserContext } from '../../../../../../contexts';
import { useLogs, useLogsCount } from '../../../../../../hooks';
import { uploadFiles } from '../../../../../../library';
import { TypographyEnhanced, toast } from '../../../../../global';
import { CommentActivity, SaveLoader } from '../../../../../ui';
import { HTMLEditor } from '../../../../../ui/form';
import InputChange from '../../../../../ui/form/inputChange';
import { ControlMapping, Creatable, MultiSelectInput, Scoring, SelectInput, TextField } from './components';

import Upload from './components/upload';
import { logAddAttachment, logAddComment, logAnswerQuestion, logEditComment, logTagUser, logUpdateAnswerQuestion } from './utils';

const StyledDivider = styled(Divider)(() => ({
  '&::before': {
    width: 15
  }
}));

const SingleQuestion = ({ question, customTimeout, setCustomTimeout, refetch, shadow, eventPath }) => {
  const botRef = useRef();
  const { orgId } = useOrgContext();
  const { userId } = useUserContext();
  const { pathname } = useLocation();

  const { id, index, name, type, description, options: defaultOptions = [], datapoint, mappingNumber, scale } = question || {};

  // sorts answer options with respect to their values
  const options = useMemo(() => {
    if (!defaultOptions || !defaultOptions.length) {
      return [];
    }
    const defaultOptionsCopy = _.cloneDeep(defaultOptions);

    return defaultOptionsCopy.sort((a, b) => {
      const aValueNumber = Number(a?.value?.replace?.('Answer', '') || 0);
      const bValueNumber = Number(b?.value?.replace?.('Answer', '') || 0);
      return aValueNumber - bValueNumber;
    });
  }, [defaultOptions]);

  // States for question logs
  const [pageSize, setPageSize] = useState(PAGE_THRESHOLD);
  const [start, setStart] = useState(0);
  // const [logs, setLogs] = useState([]);

  // States for function/saving
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [callback, setCallback] = useState();
  const errorOrSaving = useMemo(() => loading || error, [error, loading]);

  // States for answer/text field
  const [answer, setAnswer] = useState('');
  const [textAnswerChanged, setTextAnswerChanged] = useState(false);

  // State for select/multi field
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [fixedOptions, setFixedOptions] = useState([]);

  // States for other field
  const [otherTextChanged, setOtherTextChanged] = useState(false);
  const [other, setOther] = useState('');
  const [otherEnabled, setOtherEnabled] = useState(false);
  const otherOption = useMemo(() => options?.find((option) => option?.name === 'Other'), [options]);

  // States for attachments
  const [attachments, setAttachments] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [saveAnswer] = useMutation(SAVE_ANSWER);
  const [updateQuestionAssignees] = useMutation(UPDATE_QUESTION_ASSIGNEES);
  const [createLog] = useMutation(CREATE_LOG);
  const [updateLog] = useMutation(UPDATE_LOG);

  const { count, loading: logCountLoading, refetch: refetchLogsCount } = useLogsCount({ where: { question: id } }, !id);
  const {
    logs,
    loading: logsLoading,
    // error: logsError,
    refetch: refetchLogs
  } = useLogs(
    {
      where: {
        org: orgId,
        question: id
      },
      start,
      limit: pageSize
    },
    !orgId || !id
  );

  useEffect(() => {
    if (!datapoint) {
      return;
    }

    const { selected, answer, other, attachments } = datapoint || {};
    const selectedOptions = (selected || [])?.map((select) => select?.id) || [];

    const fixedOptions = (selected || []).reduce((result, option) => {
      const { disabled, id } = option || {};

      if (disabled) {
        result.push(id);
      }
      return result;
    }, []);

    setFixedOptions((prev) => [...prev, ...fixedOptions]);
    setSelectedOptions(selectedOptions);
    setAttachments(attachments);

    // Do not reset text fields to their default state when category is refetched, to preserve unsaved changes
    setOther((localOther) => {
      if (localOther && !compareHtmlStrings(localOther, other)) {
        return localOther;
      }
      return other || '';
    });

    // Do not reset text fields to their default state when category is refetched, to preserve unsaved changes
    setAnswer((localAnswer) => {
      if (localAnswer && !compareHtmlStrings(localAnswer, answer)) {
        return localAnswer;
      }
      return answer || '';
    });
  }, [datapoint]);

  // To check if other text field should be displayed
  useEffect(() => {
    if (!options || !options.length) {
      return;
    }
    const found = options.find((option) => {
      if (option.name === 'Other') {
        return selectedOptions.includes(option.id);
      }
      return null;
    });

    setOtherEnabled(found);
  }, [options, selectedOptions]);

  // To detect if answer has been updated
  useEffect(() => {
    if (!question) {
      return;
    }
    const { datapoint } = question || {};
    const { answer: defaultAnswer = '' } = datapoint || {};

    const textChanged = !compareHtmlStrings(defaultAnswer, answer);
    setTextAnswerChanged(textChanged);
  }, [answer, question]);

  // To detect if other text has been updated
  useEffect(() => {
    if (!question) {
      return;
    }
    const { datapoint } = question || {};
    const { other: defaultOther = '' } = datapoint || {};

    const otherTextChanged = defaultOther !== other;
    setOtherTextChanged(otherTextChanged);
  }, [question, other]);

  const selectedOptionsChanged = (values = []) => {
    // return true if type of question does not match select/multi-select
    if (![QUESTION_TYPES.MULTI_SELECT, QUESTION_TYPES.SELECT].includes(type)) {
      return true;
    }

    const { selected, other: oldOther = '' } = datapoint || {};

    const selectedOptions = (selected || [])?.map((select) => select?.id) || [];

    // Difference between new selected options and pre-selected options
    const difference = symmetricDifference(values, selectedOptions).length;

    // if there is no difference between the ids of the options selected and other option has not been updated
    if (!difference && oldOther === other) {
      return false;
    }

    return true;
  };

  // Save datapoint
  const handleSave = async (data = {}, creatable) => {
    botRef?.current?.increment();

    const { selected = selectedOptions } = data || {};

    let resultSelected = [...selected];
    const otherSelectedAndEmpty = (selected || []).includes(otherOption?.id) && !other;
    const otherNotSelected = !(selected || []).includes(otherOption?.id);

    // Exlude other if the value is not present for the other textfield
    if (otherSelectedAndEmpty) {
      resultSelected = selected.filter((option) => option !== otherOption?.id);
    }

    // if type of question is select/multi-select and no changes are made, do not save the answer
    const selectedChanged = selectedOptionsChanged(resultSelected);
    if (!selectedChanged) {
      return;
    }

    // datapoint to update
    const datapoint = {
      answer,
      selected: resultSelected,
      // set other field to empty if other option is de-selected
      other: otherNotSelected ? '' : other,
      attachments,
      // To update data via component onChange as state update takes time to reflect
      ...(data || {})
    };

    const saveData = async () => {
      try {
        setLoading(true);
        setError();
        await saveAnswer({
          variables: {
            id,
            org: orgId,
            datapoint,
            options: creatable
          }
        });
        // if updating a previously answered question
        if (question?.datapoint) {
          const oldSelected = (question?.datapoint?.selected || [])?.map((select) => select?.id) || [];
          logUpdateAnswerQuestion(question, answer, oldSelected, resultSelected, eventPath);
        } else {
          //if answering a previously unanswered question
          logAnswerQuestion(question, type, pathname, eventPath);
        }
        refetchLogsCount();
        refetchLogs();
        refetch();
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);

        // resets change state to avoid overlap of save indicator
        setTextAnswerChanged(false);
        setOtherTextChanged(false);
      }
    };

    await saveData();
    setCallback({ id, execute: saveData });
  };

  // Undo changes for text fields based on type
  const handleCancel = (type) => {
    const { datapoint } = question || {};
    const { answer: defaultAnswer = '', other: defaultOther = '' } = datapoint || {};

    switch (type) {
      case TEXT_INPUT_TYPES.OTHER:
        setOther(defaultOther || '');
        break;
      default:
        setAnswer(defaultAnswer || '');
    }
  };

  const handleUpload = async (files) => {
    setUploadLoading(true);
    const attachments = await uploadFiles(files);
    setAttachments(attachments);
    await handleSave({ attachments });
    setUploadLoading(false);
  };

  const createOrUpdateComment = async (data, mentionedUserIds) => {
    try {
      const { id: logId, comment, attachments } = data || {};
      if (!logId) {
        await createLog({
          variables: {
            data: {
              comment,
              attachments,
              org: orgId,
              type: LOG_TYPES.CYBER_ASSESSMENT,
              subType: LOG_SUB_TYPES.COMMENT,
              question: id
            }
          }
        });
        logAddComment(question, eventPath);
      } else {
        await updateLog({
          variables: {
            id: logId,
            data: {
              comment,
              attachments
            }
          }
        });
        logEditComment(question, logId, eventPath);
      }
      if (attachments?.length) {
        logAddAttachment(question, logId, eventPath);
      }
      // add mentioned users as assignees to the question
      if (mentionedUserIds?.length) {
        const { assignees: localAssignees = [] } = question || {};
        const localAssigneeIds = (localAssignees || []).map((assignee) => assignee.id) || [];
        const assignees = _.uniq([...(localAssigneeIds || []), ...(mentionedUserIds || [])]);
        await updateQuestionAssignees({ variables: { id, assignees } });
        logTagUser(question, logId, userId, mentionedUserIds, eventPath);
      }

      toast.success(defaultMessages.defaultSuccess);
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      refetchLogs();
    }
  };

  let component;
  switch (type) {
    case QUESTION_TYPES.TEXT_AREA:
      component = (
        <HTMLEditor
          label={name}
          defaultValue={datapoint?.answer || ''}
          onChange={setAnswer}
          onSave={() => handleSave()}
          onCancel={() => handleCancel(TEXT_INPUT_TYPES.ANSWER)}
          showInputChange={textAnswerChanged && !errorOrSaving}
          disabled={shadow}
          placeholder=''
        />
      );
      break;
    case QUESTION_TYPES.SELECT:
    case QUESTION_TYPES.SCALE_SELECT:
      component = (
        <SelectInput
          id={id}
          label={name}
          options={options || []}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          onSave={handleSave}
          other={other}
        />
      );
      break;
    case QUESTION_TYPES.SCALE:
      component = <Scoring id={id} label={`${mappingNumber}: ${name}`} options={options || []} datapoint={datapoint} scale={scale} onSave={handleSave} />;
      break;
    case QUESTION_TYPES.MULTI_SELECT:
      component = (
        <MultiSelectInput
          id={id}
          label={name}
          options={options || []}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          onSave={handleSave}
          other={other}
          customTimeout={customTimeout}
          setCustomTimeout={setCustomTimeout}
        />
      );
      break;
    case QUESTION_TYPES.CREATABLE:
      component = (
        <Creatable
          id={id}
          label={name}
          options={options || []}
          fixedOptions={fixedOptions}
          selectedOptions={selectedOptions}
          onSave={(data, creatable) => handleSave(data, creatable)}
        />
      );
      break;
    case QUESTION_TYPES.UPLOAD:
      component = (
        <Upload
          label={name}
          attachments={attachments}
          onUpload={handleUpload}
          loading={uploadLoading}
          setAttachments={setAttachments}
          onSave={handleSave}
          customTimeout={customTimeout}
          setCustomTimeout={setCustomTimeout}
        />
      );
      break;
    default:
      //get standard input fields.
      component = (
        <Fragment>
          <TextField label={name} value={answer} onChange={setAnswer} />
          {textAnswerChanged && !errorOrSaving && <InputChange onSave={() => handleSave()} onCancel={() => handleCancel(TEXT_INPUT_TYPES.ANSWER)} />}
        </Fragment>
      );
  }
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <StyledDivider textAlign='left'>
          <TypographyEnhanced id={`Q.${index}`} fontWeight='bold' />
        </StyledDivider>
      </Grid>
      {description && (
        <Grid item xs={12}>
          <Typography variant='caption'>{description}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {component}
      </Grid>

      {otherEnabled && (
        <Grid item xs={12}>
          <TextField id={`${id}-other`} label={'Other'} value={other} onChange={setOther} required={true} />
          {otherTextChanged && !errorOrSaving && (
            <InputChange onSave={() => handleSave()} onCancel={() => handleCancel(TEXT_INPUT_TYPES.OTHER)} disabled={!other} />
          )}
        </Grid>
      )}

      <Grid container item justifyContent='flex-end'>
        <Grid item>
          <SaveLoader loading={loading} error={error} callback={callback} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ControlMapping question={question} />
      </Grid>
      <Grid item xs={12}>
        <CommentActivity
          id={id}
          mappingNumber={mappingNumber}
          model='question'
          total={count}
          logs={logs}
          onSave={createOrUpdateComment}
          setStart={setStart}
          loading={logCountLoading || logsLoading}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Grid>
      <BotDetectionModal ref={botRef} />
    </Grid>
  );
};

SingleQuestion.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  question: PropTypes.object,
  refetch: PropTypes.func,
  customTimeout: PropTypes.object,
  setCustomTimeout: PropTypes.func,
  shadow: PropTypes.bool,
  eventPath: PropTypes.object
};

export default SingleQuestion;
