import { DOWNLOAD_MODAL as downloadModal } from './downloadModal';
import { REDEMPTION_MODAL as redemptionModal } from './redemptionModal';
import { REWARD_CARD as rewardCard } from './rewardCard';
import { VIEW_REWARD as viewReward } from './viewReward';

export const REDEEM = {
  rewardCard,
  redemptionModal,
  downloadModal,
  viewReward
};
