/* eslint-disable quotes */

// components.application.dashboard.widgets.passwordStrength

export const PASSWORD_STRENGTH = {
  title: 'Password Compliance - Google Suite',
  description:
    'Passwords provide the first line of defense against unauthorized access to your computer and personal information. The stronger your password, the more protected your systems will be from attacks.',
  disconnected: {
    description:
      'Information required to provide insights here relies on one or more connections to other systems. Either you have not connected the relevant applications or there is an issue with the connections at the moment.',
    button: 'LINK COMPANY ACCOUNTS'
  },
  buttons: {
    linkAccount: 'LINK COMPANY ACCOUNTS',
    viewAll: 'VIEW FOR ALL ACCOUNTS'
  }
};
