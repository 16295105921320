/* eslint-disable quotes */
// views.user.login

export const LOGIN = {
  title: 'Log In',
  email: { label: 'Email', placeholder: 'Your email address' },
  password: { label: 'Password', placeholder: 'Choose password' },
  rememberMe: 'Remember me',
  forgotPassword: 'Forgot password?',
  inactivity: 'You have been logged out due to inactivity',
  buttons: { login: 'Log in', google: 'Log in with Google', signUp: "Don't have an account yet? Sign up" }
};
