import { FEEDBACK_TYPES } from '@anirudhm9/base-lib/lib/constants/api/feedback';
import PropTypes from 'prop-types';
import ModalEnhanced from '../../components/ui/modal';
import FeedbackModalContent from './modalContent';

const FeedbackModal = ({ open, type, toggle }) => {
  return (
    <ModalEnhanced modalOpen={open} setModalOpen={toggle}>
      <FeedbackModalContent toggle={toggle} type={type} />
    </ModalEnhanced>
  );
};

export default FeedbackModal;

FeedbackModal.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(FEEDBACK_TYPES)),
  toggle: PropTypes.func
};
