/* eslint-disable quotes */

// components.application.dashboard.widgets.thirdPartyApplications

export const THIRD_PARTY_APPLICATIONS = {
  title: 'Top 5 Applications',
  description:
    "These third party sites and apps have access to your users' account data, including info that may be sensitive. Remove access for those that are no longer trusted or used.",
  disconnected: {
    description:
      'Information required to provide insights here relies on one or more connections to other systems. Either you have not connected the relevant applications or there is an issue with the connections at the moment.',
    button: 'LINK COMPANY ACCOUNTS'
  },
  buttons: {
    linkAccount: 'LINK COMPANY ACCOUNTS',
    viewAll: 'VIEW ALL'
  }
};
