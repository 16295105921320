import { EVENTS } from '../../../../../../library';

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.APP.SETTINGS.MANAGE_USERS[event];
};

export const TRANSLATION_PATH = (route) => {
  return `views.app.manage.manageUsers.${route}`;
};

export const DEFAULT_FILTER = { ALL_USERS: { ALL_USERS_CHECKBOX: ['ACTIVE_USERS'] } };
