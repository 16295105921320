import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { COUNT_NEWS_ITEMS, GET_NEWS_ITEMS } from '../../api';
import { useUserContext } from '../../contexts';
import useApplications from '../useApplications';

/**
 * Custom hook to encapsulate GET_NEWS_ITEMS
 * usage example:
 *
 * const { news, applications,  total, loading, error, refetch } = useGetNews()
 *
 * @returns {{news: (Array), applications: (Array), total: Number,  refetch: (variables?: Partial<{newsitem: *}>) => Promise<ApolloQueryResult<any>>, loading: Boolean, error: ApolloError}}
 */
const useGetNews = (_where = {}, start, limit, sort, skip) => {
  const { userId, admin, defaultUser, loading: userLoading } = useUserContext();

  const where = useMemo(() => {
    if (userLoading) {
      return;
    }

    if (defaultUser) {
      return {
        _and: [
          {
            _or: [{ archived: false }, { archived_null: true }]
          },
          {
            _or: [{ users_contains: [userId] }, { inactiveUsers_contains: [userId] }]
          }
        ]
      };
    }

    if (admin) {
      return {};
    }
  }, [admin, defaultUser, userId, userLoading]);

  const { applications, loading: applicationsLoading } = useApplications(where);

  const {
    data: newsCountData,
    loading: newsCountLoading,
    error: newsCountError
  } = useQuery(COUNT_NEWS_ITEMS, {
    variables: {
      where: {
        _and: [
          { thirdparty_in: applications.map((app) => app?.thirdparty?.id) },
          {
            ...(_where || {})
          }
        ]
      }
    },
    skip: !where || applicationsLoading || !applications?.length || skip
  });

  const {
    data: newsItemsData,
    loading,
    error,
    refetch
  } = useQuery(GET_NEWS_ITEMS, {
    variables: {
      where: {
        _and: [
          { thirdparty_in: applications.map((app) => app?.thirdparty?.id) },
          {
            ...(_where || {})
          }
        ]
      },
      sort,
      limit,
      start
    },
    skip: !where || applicationsLoading || !applications?.length || skip
  });

  const total = useMemo(() => {
    if (newsCountLoading || newsCountError) {
      return 0;
    }
    return newsCountData?.countNewsItems || 0;
  }, [newsCountData?.countNewsItems, newsCountError, newsCountLoading]);

  const news = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return newsItemsData?.newsitems || [];
  }, [newsItemsData?.newsitems, error, loading]);

  if (error) {
    console.error(error);
  }

  return {
    news,
    applications,
    total,
    loading: loading || userLoading || applicationsLoading,
    error,
    refetch
  };
};

export default useGetNews;
