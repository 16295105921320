import { InfoOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useIsDesktop } from '../../../hooks';
import ModalEnhanced from '../../ui/modal';
import TooltipEnhanced from '../tooltip';
import TypographyEnhanced from '../typography';

const LabelWithInfo = ({ label, tooltip }) => {
  const isDesktop = useIsDesktop();

  const [open, setOpen] = useState(false);

  const toggle = () => {
    if (isDesktop) {
      return;
    }
    setOpen((open) => !open);
  };

  const title = useMemo(() => {
    if (typeof label === 'string') {
      return <TypographyEnhanced id={label} display='inline' />;
    }
    return label;
  }, [label]);

  return (
    <Grid container item>
      {title}
      <TooltipEnhanced title={tooltip} ml={0.2} {...(!isDesktop && { boxProps: { onClick: toggle } })}>
        <InfoOutlined fontSize='small' color='primary' />
      </TooltipEnhanced>
      {!isDesktop && (
        <ModalEnhanced modalOpen={open} setModalOpen={toggle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyEnhanced id={tooltip || ''} />
            </Grid>
          </Grid>
        </ModalEnhanced>
      )}
    </Grid>
  );
};

LabelWithInfo.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tooltip: PropTypes.string
};

export default LabelWithInfo;
