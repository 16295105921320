import { Alert as MuiAlert, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { EVENTS, logEvent } from '../../../library';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant='filled' ref={ref} {...props} />;
});

let alert;

/**
 * @param {String} type (success, error, warning, info)
 * @param {String} message
 * @param {}
 * @description Alert Toast
 */
const Toast = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('success');
  const [anchorOrigin, setAnchorOrigin] = useState({
    vertical: 'top',
    horizontal: 'right'
  });

  const openToast = (
    type,
    message,
    anchorOrigin = {
      vertical: 'top',
      horizontal: 'right'
    }
  ) => {
    setOpen(true);
    setType(type);
    setMessage(message);
    setAnchorOrigin(anchorOrigin);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    alert = openToast;
    return () => handleClose();
  }, []);

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={anchorOrigin}>
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

/**
 * /**
 * @description shows an alert notification
 *
 * @returns {{
 * success: VoidFunction
 * warning: VoidFunction
 * error: VoidFunction
 * }}
 */
export const toast = {
  success: (message, anchorOrigin) => {
    alert('success', message, anchorOrigin);
  },
  warning: (message, anchorOrigin) => {
    alert('warning', message, anchorOrigin);
  },
  info: (message, anchorOrigin) => {
    alert('info', message, anchorOrigin);
  },
  error: (message, anchorOrigin) => {
    alert('error', message, anchorOrigin);
    logEvent(EVENTS.COMMON.TOAST.ERROR, { message });
  }
};

export default Toast;
