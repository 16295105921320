import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import { EN_US } from './en';
import ZH from './zh';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en_us',
    fallbackLng: 'en_us',
    interpolation: {
      format: (value, format) => {
        if (value instanceof Date) {
          return moment(value).format(format);
        }
        if (typeof value === 'number') {
          return new Intl.NumberFormat().format(value);
        }
        return value;
      }
    },
    resources: {
      en_us: {
        translation: EN_US
      },
      zh: {
        translation: ZH
      }
    }
  });

export const changeLanguage = (language) => i18n.changeLanguage(language);
