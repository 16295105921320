import { IMAGES } from '@anirudhm9/base-lib/lib/constants';
import { formatDateTime } from '@anirudhm9/base-lib/lib/utils';
import { Card, CardContent, CardMedia, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { openLink } from '../../../../../../helpers/routing';
import { useIsDesktop } from '../../../../../../hooks';
import { TooltipEnhanced, TypographyEnhanced } from '../../../../../global';
import { ShowMoreText } from '../../../../../ui';
import AppLogo from '../../appLogo';

const useStyles = makeStyles((theme) => ({
  cardButton: {
    cursor: 'pointer'
  },
  root: {
    textAlign: 'left',
    backgroundColor: theme.palette.background.default
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.main
  }
}));

const NewsItem = ({ applicationId, newsItem, src, alt, limit = 100, height = 120, className, onClick, ...props }) => {
  const { title, description, author, imageUrl, link, displayLink, createdAt, thirdparty } = newsItem || {};
  const classes = useStyles();

  const isDesktop = useIsDesktop();

  return (
    <Card onClick={() => onClick(applicationId, link, title) || openLink(link)} className={classes.cardButton} {...props}>
      <CardContent className={className || classes.root} sx={{ paddingBottom: '16px !important' }}>
        <Grid container spacing={1} alignContent='space-between'>
          <Grid container item spacing={1} alignItems='center'>
            {!isDesktop && (
              <Grid item>
                <AppLogo url={imageUrl || src || IMAGES.EMPTY_STATE.CONNECTIVITY_CABLE} alt={alt || title} style={{ width: '35px', height: '35px' }} />
              </Grid>
            )}
            <Grid item xs>
              <TypographyEnhanced id={title} variant='body1' />
            </Grid>
            <Grid item>
              <TooltipEnhanced title={thirdparty?.name || ''}>
                <AppLogo url={thirdparty?.logoUrl || ''} alt={thirdparty?.name || ''} style={{ width: 25, height: 25 }} />
              </TooltipEnhanced>
            </Grid>
            {isDesktop && (
              <CardMedia
                className={className}
                component='img'
                height={height}
                image={imageUrl || src || IMAGES.EMPTY_STATE.CONNECTIVITY_CABLE}
                alt={alt || title || ''}
              />
            )}
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={12}>
              <ShowMoreText limit={limit}>{description}</ShowMoreText>
            </Grid>
          </Grid>
          <Grid container item justifyContent='space-between' alignItems='center' spacing={2}>
            <Grid item>
              <TypographyEnhanced id='' variant='caption' className={classes.link}>
                {displayLink}
              </TypographyEnhanced>
            </Grid>
            <Grid item>
              <TypographyEnhanced id='' variant='caption'>
                {`${author || ''} (${formatDateTime(createdAt)})`}
              </TypographyEnhanced>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

NewsItem.propTypes = {
  applicationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newsItem: PropTypes.object,
  src: PropTypes.string,
  alt: PropTypes.string,
  limit: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func
};

export default NewsItem;
