// components.application.rewards.achievementCard.
import { ACHIEVEMENT_STATUS as achievementStatus } from './achievementStatus';
import { CLAIM_POINTS_MODAL as claimPointsModal } from './claimPointsModal';
import { MORE_INFO_MODAL as moreInfoModal } from './moreInfoModal';

export const ACHIEVEMENT_CARD = {
  claimPointsModal,
  moreInfoModal,
  achievementStatus
};
