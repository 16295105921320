import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../contexts';
import UsersProvider from '../../../contexts/usersContext';
import { RoleProtectedRoute } from '../../../helpers/routing';
import Applications from './applications';
import Devices from './devices';
import Issues from './issues';
import Users from './users';

const Manage = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <UsersProvider>
        <Routes>
          <Route path='/' element={<Navigate to='users' />} />
          <Route path='/users/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={Users} />} />
          <Route path='/applications/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={Applications} />} />
          <Route path='/issues/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={Issues} />} />
          <Route path='/devices/*' element={<RoleProtectedRoute roles={roles} path={pathname} element={Devices} />} />
          <Route path='*' element={<Navigate to='users' />} />
        </Routes>
      </UsersProvider>
    </div>
  );
};

Manage.propTypes = {};

export default Manage;
