import { DEVICE_OS_TYPES, DEVICE_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { formatErrorMessage, hasAdminRole, isEmpty } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { HelpOutlineRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UPDATE_DEVICE } from '../../../../../api';
import { defaultMessages } from '../../../../../constants';
import { useDevicesContext, useOrgContext, useUserContext } from '../../../../../contexts';
import { usePageViewContext } from '../../../../../contexts/logPageViewContext';
import { useUsersContext } from '../../../../../contexts/usersContext';
import { logEvent } from '../../../../../library';
import { DEVICES as MANAGE_DEVICES_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/devices';
import { ButtonEnhanced, TooltipEnhanced, TypographyEnhanced, toast } from '../../../../global';
import { SelectEnhanced, TextFieldEnhanced } from '../../../../ui/form';
import { IntegrationBanner } from '../../../integrations';
import DeviceIcon from '../deviceIcon';
import EditUserSelect from '../viewEditUser/editUserSelect';
import { REQUIRED_FIELDS, TRANSLATION_PATH } from './constants';
import { setDeviceDetails } from './utils';

const ManualEditDevice = () => {
  const { orgId } = useOrgContext();
  const { devices, loading: devicesLoading, error: devicesError, refetch: refetchDevices } = useDevicesContext();
  const { users: usersOptions, loading: usersLoading } = useUsersContext();
  const { user: currentUser } = useUserContext();
  const { pageViewLogged, setPageViewLogged } = usePageViewContext();
  const { id } = useParams();
  const hasAdmin = hasAdminRole(currentUser);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [localData, setLocalData] = useState();
  const [device, setDevice] = useState({});
  const [errorFields, setErrorFields] = useState({});

  const [updateDevice] = useMutation(UPDATE_DEVICE);

  useEffect(() => {
    if (pageViewLogged) {
      return;
    }
    logEvent(MANAGE_DEVICES_EVENTS.VIEW_DEVICE, { device_id: id });
    setPageViewLogged(true);
  }, [id, pageViewLogged, setPageViewLogged]);

  const src = useMemo(() => {
    if (!localData?.type) {
      return DEVICE_OS_TYPES.OTHER.src;
    }
    return DEVICE_OS_TYPES[localData?.type]?.src;
  }, [localData?.type]);

  const updateLocalData = (key, value) => {
    setLocalData((local) => {
      return { ...local, [key]: value };
    });
  };

  const hasChanged = useMemo(() => {
    if (!localData) {
      return false;
    }

    return !_.isEqual(localData, device);
  }, [device, localData]);

  //Set initial field values for the device
  useEffect(() => {
    if (devicesLoading || devicesError) {
      return;
    }
    const device = devices.find((device) => device?.id === id);
    setLocalData(device);
    setDevice(device);
  }, [devices, devicesError, devicesLoading, id]);

  const handleSaveDevice = async () => {
    if (!device?.custom) {
      toast.error('Cannot edit integration devices.');
      return;
    }
    const emptyFields = isEmpty(REQUIRED_FIELDS, localData);

    if (Object.keys(emptyFields || {}).length) {
      setErrorFields(emptyFields);
      toast.error(defaultMessages.requiredFields);
      return;
    }

    const localDataCopy = _.cloneDeep(localData);

    // UPDATE EXISTING DEVICE
    //Cleanup localData object to match DeviceInput model
    const data = _.omit(localDataCopy, ['__typename', 'id', 'firstSync', 'lastSync']);
    //Cannot omit org or strapi permissions get funky
    data.org = orgId;
    data.user = currentUser?.id;

    try {
      setLoading(true);
      await updateDevice({
        variables: {
          id: device?.id,
          data
        }
      });
      refetchDevices();
      toast.success('Device updated successfully.');
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };

  const handleCancel = () => {
    setDeviceDetails(updateLocalData, device);
    navigate(-1);
  };

  const DeviceLogo = useCallback(() => {
    return <DeviceIcon src={src} sx={{ height: 100, width: 100 }} />;
  }, [src]);

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <DeviceLogo />
        <Grid item xs>
          <IntegrationBanner model='Device' integration={device?.integration} />
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectEnhanced
          id='type'
          label={
            <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
              <TypographyEnhanced id='Operating System ' />
              <TooltipEnhanced title='Check your device settings if unsure.'>
                <HelpOutlineRounded fontSize='small' color='primary' />
              </TooltipEnhanced>
            </Grid>
          }
          value={localData?.type}
          onChange={(value) => updateLocalData('type', value)}
          options={Object.values(DEVICE_OS_TYPES)}
          errorText={errorFields['type']}
          hasError={!!errorFields['type']}
          disabled={!device?.custom}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldEnhanced
          id='version'
          label={
            <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
              <TypographyEnhanced id='Operating System Version ' />
              <TooltipEnhanced title='Check your device settings if unsure.'>
                <HelpOutlineRounded fontSize='small' color='primary' />
              </TooltipEnhanced>
            </Grid>
          }
          placeholder='eg: 16.1'
          value={localData?.version}
          onChange={(value) => updateLocalData('version', value)}
          errorText={errorFields['version']}
          hasError={!!errorFields['version']}
          disabled={!device?.custom}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectEnhanced
          id='deviceType'
          label='Device Type'
          value={localData?.deviceType}
          onChange={(value) => updateLocalData('deviceType', value)}
          options={Object.values(DEVICE_TYPES)}
          errorText={errorFields['deviceType']}
          hasError={!!errorFields['deviceType']}
          disabled={!device?.custom}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldEnhanced
          id='model'
          label='Device Model'
          placeholder='eg: iPhone 12 Pro'
          value={localData?.model}
          onChange={(value) => updateLocalData('model', value)}
          errorText={errorFields['model']}
          hasError={!!errorFields['model']}
          disabled={!device?.custom}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <EditUserSelect
          id='user'
          options={(usersOptions || []).filter((user) => {
            return hasAdmin ? !user.archived && !user.blocked : user?.id === currentUser?.id;
          })}
          label='User associated with this device'
          value={localData?.user?.id}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onChange={(value) =>
            updateLocalData(
              'user',
              usersOptions.find((user) => user.id === value)
            )
          }
          loading={usersLoading}
          enable
          required
          errorText={errorFields['user']}
          hasError={!!errorFields['user']}
          disabled={!device?.custom}
        />
      </Grid>

      {device?.custom && (
        <Grid container item xs={12} spacing={2} justifyContent='flex-end'>
          <Grid item>
            <ButtonEnhanced onClick={handleCancel} loading={loading || usersLoading} disabled={!hasChanged} variant='outlined'>
              <TypographyEnhanced id={'common.button.cancel'} />
            </ButtonEnhanced>
          </Grid>
          <Grid item>
            <ButtonEnhanced onClick={handleSaveDevice} loading={loading || usersLoading} disabled={!device?.custom || !hasChanged}>
              <TypographyEnhanced id={TRANSLATION_PATH('buttons.save')} />
            </ButtonEnhanced>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ManualEditDevice;
