import { isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { useTheme } from '@emotion/react';
import { Avatar, AvatarGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { GRADE_COLORS } from '../constants';

const useStyles = makeStyles(() => ({
  grade: { fontSize: '2.5rem', height: '70px', width: '70px' }
}));

const SCALE = Object.freeze(['A', 'B', 'C', 'D', 'E', 'F']);

const GradeChart = ({ scan }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <AvatarGroup max={6} sx={{ display: 'flex', alignItems: 'center' }}>
      {SCALE.map((letter) => {
        const isCurrentGrade = isIDEqual(letter, scan?.grade);

        const styles = {
          backgroundColor: theme.palette[GRADE_COLORS[letter]].main,
          color: '#000'
        };

        return (
          <Avatar sx={styles} className={isCurrentGrade ? classes.grade : ''} key={letter}>
            {letter}
          </Avatar>
        );
      })}
    </AvatarGroup>
  );
};

GradeChart.propTypes = {
  scan: PropTypes.object
};

export default GradeChart;
