import { Grid } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getTotalFilters } from '../../../../../helpers/formatUtils';
import { useCriticalities, useStatuses } from '../../../../../hooks';
import { ISSUES as ISSUES_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/issues';
import Filter from '../../../../ui/filter';
import FilterBody from '../../../../ui/filter/body';
import { FILTERS, SORT_OPTIONS } from './constants';
import { formatFilters } from './utils';

const IssuesFilter = ({ issues = [], setIssues, setWhere, sort, setSort, defaultFilter }) => {
  const [filters, setFilters] = useState(FILTERS);
  const [searchQuery, setSearchQuery] = useState();
  const [filterQuery, setFilterQuery] = useState();

  const { criticalities, loading: criticalitiesLoading } = useCriticalities();
  const { statuses, loading: statusesLoading } = useStatuses();

  const [selectedFilters, setSelectedFilters] = useState({});
  const totalFilters = useMemo(() => getTotalFilters(selectedFilters || []), [selectedFilters]);

  useEffect(() => {
    if (criticalitiesLoading || statusesLoading || !(criticalities || []).length || !(statuses || []).length) {
      return;
    }
    setFilters((filters) => {
      const filtersCopy = _.cloneDeep(filters || {});
      filtersCopy.CRITICALITY.filters[0].options = criticalities;
      filtersCopy.STATUS.filters[0].options = statuses;
      return filtersCopy;
    });
  }, [criticalities, criticalitiesLoading, statuses, statusesLoading]);

  useEffect(() => {
    if (!defaultFilter) {
      return;
    }
    setSelectedFilters(defaultFilter);
    const query = formatFilters(defaultFilter);
    setFilterQuery(query);
  }, [criticalitiesLoading, defaultFilter, statusesLoading]);

  const onSearch = (term = '') => {
    setSearchQuery({
      _or: [{ name_contains: term }, { description_contains: term }, { 'assignees.firstName_contains': term }, { 'assignees.lastName_contains': term }]
    });
  };

  const handleFilter = useCallback(() => {
    const query = formatFilters(selectedFilters);
    setFilterQuery(query);
  }, [selectedFilters]);

  const handleSort = useCallback((value) => setSort(value), [setSort]);

  const onFilterReset = () => {
    setSelectedFilters({});
    setFilterQuery();
  };

  const onSearchReset = () => {
    onSearch();
    setIssues(issues || []);
  };

  useEffect(() => {
    const _and = [];
    if (searchQuery) {
      _and.push(searchQuery);
    }

    if (filterQuery) {
      _and.push(filterQuery);
    }

    setWhere({ _and });
  }, [filterQuery, searchQuery, setWhere]);

  return (
    <Filter
      onFilter={handleFilter}
      onFilterReset={onFilterReset}
      onSearch={onSearch}
      onSearchReset={onSearchReset}
      totalFilters={totalFilters}
      handleSort={handleSort}
      sortOptions={SORT_OPTIONS}
      sortValue={sort}
      eventPath={ISSUES_EVENTS}
    >
      <Grid container item spacing={2}>
        <FilterBody filters={filters} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
      </Grid>
    </Filter>
  );
};

IssuesFilter.propTypes = {
  issues: PropTypes.array,
  setIssues: PropTypes.func,
  searchKeys: PropTypes.arrayOf(PropTypes.string),
  setWhere: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  defaultFilter: PropTypes.object
};
export default IssuesFilter;
