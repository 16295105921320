import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ChipEnhanced, TooltipEnhanced } from '../../../../../global';

import { KBS_TYPES } from './constants';

const KbsChip = ({ value, size = 'small', expand }) => {
  const kbs = useMemo(() => {
    if (!value) {
      return;
    }
    return KBS_TYPES[value];
  }, [value]);

  if (!kbs) {
    return null;
  }

  return (
    <TooltipEnhanced title={kbs.translation}>
      {expand ? (
        <ChipEnhanced id={kbs.translation || ''} color={kbs.color || 'default'} size={size} icon={kbs.icon} />
      ) : (
        <Grid container item>
          {kbs.icon}
        </Grid>
      )}
    </TooltipEnhanced>
  );
};

KbsChip.propTypes = {
  value: PropTypes.string,
  size: PropTypes.string,
  expand: PropTypes.bool
};

export default KbsChip;
