import { EVENTS } from '../../../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.app.manage.applications.defaultApplications.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.APP.MANAGE.APPLICATIONS[event];
};

export const DUMMY_DATA = [
  {
    id: '1',
    name: 'AWS',
    customName: '',
    description: 'Amazon Web Services Cloud Platform as a Service',
    kbs: 'NO',
    securityScore: null,
    type: 'Cloud PaaS',
    categories: ['Web Hosting & E-Commerce', 'Infrastructure'],
    infoHeld: ['5', '2', '3', '6', '8'],
    logoUrl:
      'https://dl.airtable.com/.attachments/ddfc1f669a9c0cc549ea9022b16e9109/870fa50e/aws.png?ts=1659596008&userId=usrLsbLDzrx6lJHcq&cs=f324973fa1f4bb51',
    loginUrl: 'https://signin.aws.amazon.com/',
    homepage: 'https://aws.amazon.com',
    autoAdded: true,
    notes: null,
    users: ['1']
  },
  {
    id: '2',
    name: 'Xero',
    customName: 'Our Accounting app',
    description: 'Xero Financial Software',
    kbs: 'YES',
    securityScore: 77,
    type: 'Cloud PaaS',
    categories: ['Accounting & Finance/ Money Management', 'Database'],
    infoHeld: ['7', '1'],
    logoUrl:
      'https://dl.airtable.com/.attachments/cd7877f25a5292ae37b36d8be4b6faf0/8508760c/39043396-5aee-4b6a-b445-fe6ceab8cee2.png?ts=1659596248&userId=usrLsbLDzrx6lJHcq&cs=f58a48e1c3413dbc',
    loginUrl: 'https://www.xero.com',
    homepage: 'https://www.xero.com',
    autoAdded: false,
    notes: 'Here some ntoes for this app',
    users: ['1']
  },
  {
    id: '3',
    name: 'Airtable',
    customName: '',
    description: 'Realtime spreadsheet-database hybrid.',
    kbs: 'NOT_IMPORTANT',
    securityScore: 10,
    type: 'Cloud PaaS',
    categories: ['Technology Infrastructure'],
    infoHeld: ['5', '3', '6'],
    logoUrl:
      'https://dl.airtable.com/.attachments/71534bfc374b30bef4f78782f86ef019/bca1a0f2/airtable-logo-216B9AF035-seeklogo_com.png?ts=1660001039&userId=usrLsbLDzrx6lJHcq&cs=ca845c83f3fd5cbc',
    loginUrl: 'https://airtable.com/login',
    homepage: 'https://www.airtable.com',
    autoAdded: false,
    notes: '',
    users: ['1']
  }
];
