import { formatDate } from '@anirudhm9/base-lib/lib/utils';
import { Box, Grid, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntegrationContext } from '../../../../../contexts';
import { DotLoader, TypographyEnhanced } from '../../../../global';
import { WidgetContainer } from '../../../../ui';
import StackedBarChart from '../../../../ui/charts/stackedBarChart';
import NoDataState from '../noDataState';
import OrganisationSelector from '../organisationSelector';
import { COMPLIANCE_OPTIONS } from './constants';

const PATH = 'components.application.dashboard.widgets.passwordStrength';
const TRANSLATION_PATH = (route) => {
  return `${PATH}.${route}`;
};

const PasswordStrength = () => {
  const theme = useTheme();

  const { GSUITE, error: integrationError, loading: integrationLoading } = useIntegrationContext();

  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const [reportDate, setReportDate] = useState();

  const [complianceOptions, setComplianceOptions] = useState();

  useEffect(() => {
    if (!selectedOrganisation || !GSUITE?.length) {
      return;
    }
    const { USERS, date } = GSUITE.find((org) => org.id === selectedOrganisation) || {};
    const { PASSWORD_STRENGTH_STRONG, PASSWORD_STRENGTH_WEAK, PASSWORD_LENGTH_COMPLIANT, PASSWORD_LENGTH_NON_COMPLIANT } = USERS || {};

    setReportDate(date);

    //set password compliance data
    const complianceOptions = COMPLIANCE_OPTIONS;

    const compliantOptions = {
      ...complianceOptions[0],
      data: [PASSWORD_LENGTH_COMPLIANT, PASSWORD_STRENGTH_STRONG],
      label: {
        show: false
      },
      itemStyle: {
        color: theme.palette.success.main
      }
    };
    const nonCompliantOptions = {
      ...complianceOptions[1],
      data: [Math.abs(PASSWORD_LENGTH_NON_COMPLIANT), Math.abs(PASSWORD_STRENGTH_WEAK)],
      label: {
        show: false
      },
      itemStyle: {
        color: theme.palette.error.main
      }
    };

    setComplianceOptions([compliantOptions, nonCompliantOptions]);
  }, [GSUITE, selectedOrganisation, theme.palette.success.light, theme.palette.error.light, theme.palette.success.main, theme.palette.error.main]);

  if (integrationError) {
    return <NoDataState path={PATH} navigateTo='/app/integrations' />;
  }

  return (
    <WidgetContainer title={TRANSLATION_PATH('title')} subTitle={`Report Date: ${formatDate(reportDate)}`}>
      <Grid item xs={12}>
        <TypographyEnhanced id={TRANSLATION_PATH('description')} />
      </Grid>
      <OrganisationSelector selectedOrganisation={selectedOrganisation || ''} setSelectedOrganisation={setSelectedOrganisation} />

      {integrationLoading ? (
        <Grid item xs={12} m={2}>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box sx={{ height: '250px', pointerEvents: 'none' }}>
            <StackedBarChart series={complianceOptions} yAxis={{ data: ['Strength', 'Length'] }} />
          </Box>
        </Grid>
      )}
    </WidgetContainer>
  );
};

export default PasswordStrength;
