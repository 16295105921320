import { IMAGES } from '@anirudhm9/base-lib/lib/constants';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Image, LetterAvatar } from '../../../../ui';
/**
 *
 * @param {String} url
 * @param {String} alt
 * @returns {JSX}
 */
const AppLogo = ({ url, alt = '', ...props }) => {
  const [src, setSrc] = useState(url || '');

  const handleError = () => {
    setSrc(IMAGES.EMPTY_STATE.CONNECTIVITY_CABLE);
  };

  return (
    <Box>
      {url ? (
        <Image src={src || url || ''} alt={alt} style={{ height: 50, width: 50 }} onError={handleError} {...props} />
      ) : (
        <LetterAvatar value={alt} variant='rounded' {...props} />
      )}
    </Box>
  );
};

AppLogo.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string
};

export default AppLogo;
