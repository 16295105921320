import { isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { NoTemplateCard } from '..';
import { DotLoader } from '../../../../../../../components/global';
import { PaginationEnhanced } from '../../../../../../../components/ui';
import TemplateCard from '../card';

const TemplateList = ({ title, templates, loading, selectedTemplate, onClick, custom, refetch, setStart, pageSize, setPageSize }) => {
  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        {title}
      </Grid>
      {loading ? (
        <DotLoader />
      ) : (
        <Grid container item spacing={2}>
          {templates?.length ? null : (
            <Grid item xs={12}>
              <NoTemplateCard />
            </Grid>
          )}
          {templates?.map((template) => {
            const { name } = template || {};
            const selected = isIDEqual(selectedTemplate?.name, name);

            return <TemplateCard key={name} template={template} selected={selected} onClick={() => onClick(template)} custom={custom} refetch={refetch} />;
          })}
          {/* TODO: add pagination for templates */}
          <PaginationEnhanced total={1} onChange={setStart} pageSize={pageSize} setPageSize={setPageSize} />
        </Grid>
      )}
    </Grid>
  );
};

TemplateList.propTypes = {
  title: PropTypes.string,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      description: PropTypes.string,
      prompt: PropTypes.string,
      mapping: PropTypes.object
    })
  ),
  loading: PropTypes.bool,
  selectedTemplate: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string,
    prompt: PropTypes.string,
    mapping: PropTypes.object
  }),
  onClick: PropTypes.func,
  custom: PropTypes.bool,
  refetch: PropTypes.func,
  setStart: PropTypes.func,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func
};
export default TemplateList;
