import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import nl2br from 'react-nl2br';

const Alert = ({ children, style = {} }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        backgroundColor: theme.palette.error.main,
        padding: '10px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        borderRadius: theme.shape.borderRadius,
        ...style
      }}
    >
      {nl2br(children)}
    </div>
  );
};

Alert.propTypes = {
  children: PropTypes.string,
  style: PropTypes.object
};

export default Alert;
