import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';

const UserLayout = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('background');
    return () => {
      document.body.classList.remove('background');
    };
  }, []);

  return (
    <Fragment>
      <div className='fixed-background' />
      <main>
        <div className='container'>{children}</div>
      </main>
    </Fragment>
  );
};

UserLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default UserLayout;
