import { ROLES } from '@anirudhm9/base-lib/lib/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { matchPath } from 'react-router-dom';
import { GraphQLClient, orgVar, setupAxios, userVar, wsContext } from '../../api';
import { ALLOWED_PAGES, LANDING_PAGES } from '../../constants';
import { EVENTS, logEvent } from '../../library';
import BlankPage from '../../views/app/blankPage';
import ScrollToTop from './scrollToTop';

const openLink = (link, target = '_blank') => {
  window.open(link, target, 'noopener,noreferrer');
};

const getInitialUrlForRole = (roles, onboarded, userId) => {
  if (!onboarded) {
    return 'onboarding';
  }
  const landingPages = LANDING_PAGES(userId) || {};
  const bestRouteKey = Object.keys(landingPages).find((role) => (roles || []).includes(role));
  if (bestRouteKey) {
    return landingPages[bestRouteKey];
  }

  return 'user/profile';
};

const hasAccessToRoute = (roles, route) => {
  if (!route) {
    return false;
  }

  if (!roles || !roles.length) {
    return ALLOWED_PAGES.DEFAULT.includes(route);
  }

  return roles.includes(ROLES.ADMIN.value) || roles.some((role) => ALLOWED_PAGES[role].filter((path) => matchPath({ path }, route)).length);
};

const RoleProtectedRoute = ({ roles = [], path, element: Element, ...props }) => {
  const Component = hasAccessToRoute(roles, path) ? Element : BlankPage;
  return <Component roles={roles} {...props} />;
};

RoleProtectedRoute.propTypes = {
  roles: PropTypes.array,
  path: PropTypes.string.isRequired,
  element: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
};

const handleLogout = (navigate) => {
  logEvent(EVENTS.COMMON.LOGOUT);
  localStorage.removeItem('user_id');
  localStorage.removeItem('user_token');
  localStorage.removeItem('org_id');
  localStorage.removeItem('org_name');
  localStorage.removeItem('gsuite_auth');
  localStorage.removeItem('token_expiry');

  setupAxios();
  GraphQLClient.cache.gc();

  userVar(null);
  orgVar(null);

  if (wsContext?.userSocket) {
    wsContext.userSocket.disconnect();
  }
  // commenting out to check if logout works fine without reloading
  // window.location.reload();
  navigate && navigate('/');
};

const clearCache = () => {
  localStorage.removeItem('user_id');
  localStorage.removeItem('user_token');
  localStorage.removeItem('org_id');
  localStorage.removeItem('org_name');
  localStorage.removeItem('timed_out');
  localStorage.removeItem('gsuite_auth');
  localStorage.removeItem('slack_auth');
  localStorage.removeItem('token_expiry');

  GraphQLClient.cache.gc();

  userVar(null);
  orgVar(null);

  if (wsContext?.userSocket) {
    wsContext.userSocket.disconnect();
  }
};

export { RoleProtectedRoute, ScrollToTop, clearCache, getInitialUrlForRole, handleLogout, hasAccessToRoute, openLink };
