import { LocalFireDepartment, Redeem, Star, Task } from '@mui/icons-material';
import { Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TypographyEnhanced } from '../../global';
import { TRANSLATION_PATH } from './constants/defaults';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    backgroundColor: theme.palette.blue.dark,
    padding: '24px'
  },
  cardContent: {
    // padding: '16px',
    color: theme.palette.common.white
  }
}));

const PointsSummary = () => {
  const classes = useStyles();
  // const { org } = useOrgContext();

  // TODO get these from org
  const rewards = {
    currentPoints: 190,
    pointsRedeemed: 10550,
    achievementsCompleted: 288,
    currentStreak: 159,
    nextBonus: 28
  };

  return (
    <Card className={classes.card}>
      <Grid container className={classes.cardContent} justifyContent='center'>
        <Grid item xs={6} md={3}>
          <Star fontSize='medium' />
          <TypographyEnhanced id={rewards.currentPoints.toString()} variant='h6' />
          <TypographyEnhanced id={TRANSLATION_PATH('earned')} />
        </Grid>
        <Grid item xs={6} md={3}>
          <Task fontSize='medium' />
          <TypographyEnhanced id={rewards.achievementsCompleted.toString()} variant='h6' />
          <TypographyEnhanced id={TRANSLATION_PATH('achievements')} />
        </Grid>
        <Grid item xs={6} md={3}>
          <Redeem fontSize='medium' />
          <TypographyEnhanced id={rewards.pointsRedeemed.toString()} variant='h6' />
          <TypographyEnhanced id={TRANSLATION_PATH('redeemed')} />
        </Grid>
        <Grid item xs={6} md={3}>
          <LocalFireDepartment fontSize='medium' />
          <TypographyEnhanced id={rewards.currentStreak.toString()} variant='h6' />
          <TypographyEnhanced id={TRANSLATION_PATH('streak')} />
          <TypographyEnhanced id={TRANSLATION_PATH('bonus')} value={{ days: rewards.nextBonus }} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default PointsSummary;
