import { Grid } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { getTotalFilters } from '../../../../../helpers/formatUtils';
import { USERS as USERS_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/users';
import Filter from '../../../../ui/filter';
import FilterBody from '../../../../ui/filter/body';
import { FILTERS, SORT_OPTIONS, SORT_VALUES } from './constants';
import { filterUsers, sortUsers } from './utils';

const UserFilter = ({
  users = [],
  setUsers,
  searchKeys = ['firstName', 'lastName', 'email'],
  defaultFilter = { ALL_USERS: { ALL_USERS_CHECKBOX: ['ACTIVE_USERS'] } }
}) => {
  const [selectedFilters, setSelectedFilters] = useState(defaultFilter);
  const [sortValue, setSortValue] = useState(SORT_VALUES.ALPHABETICAL_ASC.value);
  const usersCopy = useMemo(() => _.cloneDeep(users || []), [users]);
  const totalFilters = useMemo(() => getTotalFilters(selectedFilters || []), [selectedFilters]);

  const onSearch = (term = '') => {
    const filteredUsers = (users || []).filter((user) => {
      const lowerCaseTerm = (term || '').toLowerCase();

      return _.some(searchKeys, (key) => (user[key] || '').toLowerCase().includes(lowerCaseTerm));
    });
    setUsers(filteredUsers);
  };

  const handleFilter = useCallback(() => {
    const filteredUsers = filterUsers(usersCopy, selectedFilters);
    setUsers(filteredUsers);
  }, [selectedFilters, setUsers, usersCopy]);

  const handleSort = useCallback(
    (value) => {
      setUsers((users) => {
        setSortValue(value);
        return sortUsers(users, value);
      });
    },
    [setUsers]
  );

  const onFilterReset = () => {
    setSelectedFilters({});
    setUsers(users);
  };

  return (
    <Filter
      onFilter={handleFilter}
      onFilterReset={onFilterReset}
      onSearch={onSearch}
      onSearchReset={() => setUsers(users || [])}
      totalFilters={totalFilters}
      sortValue={sortValue}
      handleSort={handleSort}
      sortOptions={SORT_OPTIONS}
      eventPath={USERS_EVENTS}
    >
      <Grid container item spacing={2}>
        <FilterBody filters={FILTERS} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
      </Grid>
    </Filter>
  );
};

UserFilter.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string
    })
  ).isRequired,
  searchKeys: PropTypes.arrayOf(PropTypes.string),
  setUsers: PropTypes.func.isRequired,
  defaultFilter: PropTypes.object
};

export default UserFilter;
