import { NavigateNextRounded } from '@mui/icons-material';
import { Breadcrumbs, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useAssessmentContext, useShadowAssessmentContext } from '../../../../../contexts';
import { logEvent } from '../../../../../library';
import { CYBER_ASSESSMENT as CYBER_ASSESSMENT_EVENTS } from '../../../../../library/amplitude/events/views/app/assessments/cyber';
import { CYBER_ASSESSMENT_TITLE } from './constants';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold'
  }
}));

const CategoryBreadcrumbs = ({ current, shadow }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { categoriesFlat } = useAssessmentContext();
  const { categoriesFlat: shadowCategoriesFlat } = useShadowAssessmentContext();

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    if (!categoriesFlat || !current || (shadow && !shadowCategoriesFlat)) {
      return;
    }

    const breadcrumbs = [];
    const process = (category) => {
      const flat = shadow ? shadowCategoriesFlat : categoriesFlat;
      const breadcrumb = flat[category?.id];
      const { parent } = breadcrumb || {};

      if (parent) {
        process(parent);
      }

      breadcrumbs.push(
        breadcrumb || {
          id: category?.id,
          name: CYBER_ASSESSMENT_TITLE
        }
      );
    };
    process(current);
    setBreadcrumbs(breadcrumbs);
  }, [categoriesFlat, current, shadow, shadowCategoriesFlat]);

  return (
    <Breadcrumbs separator={<NavigateNextRounded fontSize='default' />}>
      {(breadcrumbs || []).map((breadcrumb, index) => {
        const { id, name, index: itemIndex } = breadcrumb || {};
        const label = `${itemIndex || ''} ${name || ''}`;
        if (index === breadcrumbs.length - 1) {
          return (
            <Typography key={id} variant='body1' className={classes.bold}>
              {itemIndex + ' ' + name}
            </Typography>
          );
        }
        return (
          <Button
            key={id}
            variant='text'
            size='small'
            onClick={() => {
              let path = `/app/assessment/cyber/${shadow ? 'shadow/' : ''}`;
              if (id) {
                path = generatePath(`${path}:id`, { id });
              }
              logEvent(CYBER_ASSESSMENT_EVENTS.COOKIE_TRAIL, { from: pathname, to: path });
              navigate(path);
            }}
          >
            {label}
          </Button>
        );
      })}
    </Breadcrumbs>
  );
};
CategoryBreadcrumbs.propTypes = {
  current: PropTypes.object,
  parent: PropTypes.object,
  shadow: PropTypes.bool
};

export default CategoryBreadcrumbs;
