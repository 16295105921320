// views.app.manage.applications.viewApplication

export const VIEW_APPLICATION = {
  details: 'Details',
  cyberNews: 'Cyber News',
  tooltipSecurityScore: 'Security Score',
  noDelete: 'This Application was automatically added and cannot be deleted.',
  buttons: {
    menu: {
      edit: 'Edit',
      cancelEdit: 'Cancel Edit',
      delete: 'Delete'
    }
  },
  empty: 'Third party under review',
  fields: {
    customName: {
      placeholder: 'Add a custom name'
    },
    homepage: 'Homepage',
    scopes: {
      label: 'Scopes',
      modal: {
        description: 'The following attributes have been provided to the application in question by your single sign-on provider (e.g. Google).'
      }
    },
    securityScore: {
      label: 'Security Score',
      modal: {
        description:
          // eslint-disable-next-line quotes
          "Avertro uses open-source intelligence tools to assess the security posture of third-party applications using reliable and consistent data. Our scoring algorithm produces a score out of 1000 that considers objective factors which can be externally verified. This score is then presented in an easy-to-understand letter grade format, ranging from A to F, providing a clear and concise evaluation of a third party's security hygiene.\n\nTo evaluate a third party's security posture, Avertro maps 41 risk vectors into specific categories that measure different aspects of its security hygiene. Each category is weighted based on the severity of the associated vectors, ensuring that the scoring system reflects the relative importance of different security controls. Our ratings include risk vectors like:\n\n• Checks against OWASP recommended headers.\n• Compliance with cybersecurity frameworks\n• Results of a SaaS MVSP\n• Exposure to known cyber incidents.\n\nBy leveraging these 41 risk vectors, Avertro provides comprehensive insights into a third party's security posture, enabling organizations to make informed decisions about their vendor risk management. More information about how Avertro assigns scores can be found here:",
        link: {
          title: 'Avertro Help Center | Understanding Avertro’s Third-Party Scoring Methods: How Ratings and Scores Are Assigned'
        }
      }
    },
    website: {
      label: 'App/Login URL',
      placeholder: 'URL to access the application'
    },
    infoHeld: {
      label: 'Information held within this application',
      placeholder: 'Types of data held in this application'
    }
  }
};
