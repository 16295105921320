const CLASSES = (theme) => ({
  // toolbar logo
  '.tool-bar-logo': {
    [theme.breakpoints.down('sm')]: {
      // hidden for mobile view
      // backgroundImage: `url(${window.location.origin}/assets/img/avertro-icon.png)`,
      // height: '60px',
      // width: '60px',
      // left: '35%'
    },
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${window.location.origin}/assets/img/logo-white.png)`,
      height: '50px',
      width: '180px',
      left: '0%'
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundPosition: 'left center',
    position: 'absolute',
    margin: '0 auto'
  },
  '.left-cover-image': {
    backgroundImage: `url(${window.location.origin}/assets/img/cover-image.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
});

export default CLASSES;
