import { Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { TypographyEnhanced } from '../../../../global';
import Body from '../body';

const useStyles = makeStyles((theme) => ({
  cardBackground: {
    background: theme.palette.background.paper
  }
}));

const Category = ({ category, expanded, level = 0 }) => {
  const classes = useStyles();

  const { name, description = '' } = category || {};

  return (
    <Card elevation={2} className={level % 2 === 1 ? classes.cardBackground : ''}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <TypographyEnhanced id={name} variant='h6' />
            <TypographyEnhanced id={description} variant='caption' />
          </Grid>
          <Grid item xs={12}>
            <Body category={category} level={level} open={open} expanded={expanded} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Category.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object),
    questions: PropTypes.arrayOf(PropTypes.object)
  }),
  expanded: PropTypes.bool,
  level: PropTypes.number
};

export default Category;
