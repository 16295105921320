import { useTheme } from '@mui/styles';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DEFAULT_OPTIONS } from './constants';

const StackedDonutChart = ({ options, onEvents }) => {
  const theme = useTheme();

  const [chartOptions, setChartOptions] = useState();

  useEffect(() => {
    if (!options) {
      return;
    }

    const { palette } = theme || {};
    const { background, text } = palette || {};
    const defaultOptions = _.cloneDeep(DEFAULT_OPTIONS);

    const {
      tooltip: defaultTooltip,
      angleAxis: defaultAngleAxis = {},
      radiusAxis: defaultRadiusAxis = {},
      legend: defaultLegend = {},
      series: defaultSeries = []
    } = defaultOptions || {};

    const { tooltip, angleAxis, radiusAxis, legend, series, ...rest } = options || {};

    defaultOptions.tooltip = {
      ...defaultTooltip,
      backgroundColor: background?.default,
      textStyle: { color: text?.primary },
      ...(tooltip || {})
    };

    defaultOptions.angleAxis = {
      ...defaultAngleAxis,
      axisLabel: { color: text?.primary },
      ...(angleAxis || {})
    };

    defaultOptions.radiusAxis = {
      ...defaultRadiusAxis,
      axisLabel: { color: text?.primary },
      ...(radiusAxis || {})
    };

    defaultOptions.legend = {
      ...defaultLegend,
      textStyle: { color: text?.primary },
      ...(legend || {})
    };

    defaultOptions.series = (defaultSeries || []).map((item, index) => {
      const seriesData = series[index] || {};
      return {
        ...(item || {}),
        axisLabel: {
          color: text?.primary
        },
        ...seriesData
      };
    });

    setChartOptions({ ...(defaultOptions || {}), ...(rest || {}) });
  }, [options, theme]);

  if (!chartOptions) {
    return null;
  }

  return <ReactEcharts option={chartOptions} onEvents={onEvents} />;
};

StackedDonutChart.propTypes = {
  options: PropTypes.object,
  onEvents: PropTypes.object
};

export default StackedDonutChart;
