import { Suspense, lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { DotLoader } from '../../../components/global';
import { useUserContext } from '../../../contexts';
import ThreatModellingContextProvider from '../../../contexts/threatModellingContext';
import UsersProvider from '../../../contexts/usersContext';
import { RoleProtectedRoute } from '../../../helpers/routing';
import CyberAssessment from './cyber';
import { Question } from './cyber/question';
import ShadowAssessment from './cyber/shadow';
import SingleCategory from './cyber/singleCategory';
import ThreatModellingBuilder from './threatFx/builder';

const ThreatFx = lazy(() => import('./threatFx'));

const Assessments = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <Routes>
        {/* <Route
          path='/'
          element={
            <UsersProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={CyberAssessment} />
            </UsersProvider>
          }
        />
        <Route
          path=':id'
          element={
            <UsersProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={CyberAssessment} />
            </UsersProvider>
          }
        /> */}
        <Route
          path='/'
          element={
            <UsersProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={ShadowAssessment} />
            </UsersProvider>
          }
        />
        <Route
          path=':id'
          element={
            <UsersProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={ShadowAssessment} />
            </UsersProvider>
          }
        />
        {/* <Route
          path='shadow/:id'
          element={
            <UsersProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={ShadowAssessment} />
            </UsersProvider>
          }
        /> */}
        <Route
          path='category/:id'
          element={
            <UsersProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={SingleCategory} />
            </UsersProvider>
          }
        />
        <Route
          path='category/:id/question/:id'
          element={
            <UsersProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={Question} />
            </UsersProvider>
          }
        />
        {/* <Route
          path='category/shadow/:id'
          element={
            <UsersProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={SingleCategory} />
            </UsersProvider>
          }
        />
        <Route
          path='category/shadow/:id/question/:id'
          element={
            <UsersProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={Question} />
            </UsersProvider>
          }
        /> */}
        {/* <Route
          path='shadow'
          element={
            <UsersProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={ShadowAssessment} />
            </UsersProvider>
          }
        /> */}
        <Route
          path='threat-modeling'
          element={
            <Suspense fallback={<DotLoader />}>
              <ThreatModellingContextProvider>
                <RoleProtectedRoute roles={roles} path={pathname} element={ThreatFx} />
              </ThreatModellingContextProvider>
            </Suspense>
          }
        />
        <Route
          path='threat-modeling/scenario'
          element={
            <Suspense fallback={<DotLoader />}>
              <ThreatModellingContextProvider>
                <RoleProtectedRoute roles={roles} path={pathname} element={ThreatModellingBuilder} />
              </ThreatModellingContextProvider>
            </Suspense>
          }
        />
        <Route
          path='threat-modeling/scenario/:id'
          element={
            <Suspense fallback={<DotLoader />}>
              <ThreatModellingContextProvider>
                <RoleProtectedRoute roles={roles} path={pathname} element={ThreatModellingBuilder} />
              </ThreatModellingContextProvider>
            </Suspense>
          }
        />
        <Route
          path='*'
          element={
            <UsersProvider>
              <RoleProtectedRoute roles={roles} path={pathname} element={CyberAssessment} />
            </UsersProvider>
          }
        />
      </Routes>
    </div>
  );
};

Assessments.propTypes = {};

export default Assessments;
