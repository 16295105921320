import { Container, CssBaseline, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { RewardCard } from '../../../../../components/application';
import RewardFilter from '../../../../../components/application/rewards/filter';
import { Heading, PointsSummary } from '../../../../../components/ui';
import { filterOptions, TRANSLATION_PATH } from './constants/defaults';

// TODO add real data in
import { dummyMarketplaceItems } from './dummyData';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  bold: {
    fontWeight: 'bold'
  }
}));

const DefaultRedeem = () => {
  const classes = useStyles();

  const [rewards, setRewards] = useState([]);
  const [filters, setFilters] = useState();

  useEffect(() => {
    const allRewards = dummyMarketplaceItems.filter((a) => !a.archived);
    const currentRewards = [];

    if (!_.isEmpty(filters)) {
      // TODO all of this needs to filter through the backened https://strapi.gitee.io/documentation/3.0.0-alpha.x/guides/filters.html#available-operators
      const filterKeys = Object.keys(filters);
      filterKeys.forEach((option) => {
        allRewards.forEach((reward) => {
          let shouldReturn;
          switch (option) {
            case 'KEYWORD':
              shouldReturn = reward.label.toLowerCase().includes(filters[option]);
              break;
            case 'CATEGORY':
              shouldReturn = filters[option].includes(reward.category);
              break;
            case 'STATUS':
              if (filters?.[option].includes('LOCKED') && !reward?.canRedeem) {
                shouldReturn = true;
              }
              if (filters?.[option].includes('REDEEMABLE') && reward?.canRedeem) {
                shouldReturn = true;
              }
              if (filters?.[option].includes('REDEEMED') && reward?.redeemed) {
                shouldReturn = true;
              }

              break;
            default:
              shouldReturn = true;
              break;
          }
          return shouldReturn && currentRewards.push(reward);
        });
      });
    }

    setRewards(() => {
      return _.isEmpty(currentRewards) ? allRewards : _.uniq(currentRewards);
    });
  }, [filters]);

  return (
    <Fragment>
      <Container component='main' className={classes.root} maxWidth={false}>
        <CssBaseline />
        <Heading id={TRANSLATION_PATH('title')} description={TRANSLATION_PATH('description')} />
        <Grid container component='main' spacing={2} className={classes.root}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <PointsSummary />
            </Grid>
            <Grid item xs={12} container justifyContent='flex-end'>
              <Grid item>
                <RewardFilter filterTypes={filterOptions} updateFilters={setFilters} />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              {rewards?.map((item, i) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                    <RewardCard data={item} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

DefaultRedeem.propTypes = {};

export default DefaultRedeem;
