import { gql } from '@apollo/client';

export const GET_COUNTRIES = gql`
  query countries {
    countries(sort: "name:ASC") {
      id
      name
      iso2
    }
  }
`;
