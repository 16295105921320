import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Collapse, Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import { Fragment, useState } from 'react';
import { useIsDesktop } from '../../../hooks';
import { IconButtonEnhanced, TypographyEnhanced } from '../../global';

const CollapseEnhanced = ({ label, children }) => {
  const isDesktop = useIsDesktop();

  const [isOpen, setIsOpen] = useState(isDesktop ? true : false);

  return (
    <Fragment>
      <Grid container alignItems='center'>
        <Grid item xs>
          <TypographyEnhanced id={label} />
        </Grid>
        {/* If on mobile, display as collapsible items */}
        {isDesktop ? null : (
          <Grid item>
            <IconButtonEnhanced onClick={() => setIsOpen(!isOpen)}>{isOpen ? <ExpandLessRounded /> : <ExpandMoreRounded />}</IconButtonEnhanced>
          </Grid>
        )}
      </Grid>
      <Collapse in={isOpen} unmountOnExit>
        {children}
      </Collapse>
    </Fragment>
  );
};

CollapseEnhanced.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
  summaryProps: PropTypes.object,
  detailProps: PropTypes.object
};
export default CollapseEnhanced;
