import { STATUS_CODES } from '@anirudhm9/base-lib/lib/constants';
import { formatErrorMessage, isEmpty, validateEmail, validateOrgDomain, validatePassword } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { Card, CardContent, Grid } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { CHANGE_PASSWORD } from '../../../../../api';
import { defaultMessages } from '../../../../../constants';
import { useUserContext } from '../../../../../contexts';
import { ButtonEnhanced, TypographyEnhanced, toast } from '../../../../global';
import { UserForm } from '../../../user';
import { TRANSLATION_PATH } from './constants';
import { logAccountSettingsUpdate, logUpdateAvatar } from './utils';

const UserAccountSettings = () => {
  const { userId, user, updateMeMutation, refetch: refetchUser } = useUserContext();
  const { domain } = user?.org || '';
  const [updatePassword] = useMutation(CHANGE_PASSWORD);

  const isSSOUser = useMemo(() => user?.provider !== 'local', [user]);

  const [loading, setLoading] = useState(false);
  const [localData, setLocalData] = useState({});
  const [errorFields, setErrorFields] = useState([]);

  useEffect(() => {
    if (!user) {
      return;
    }

    setLocalData({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email
    });
  }, [user]);

  const updateUser = async () => {
    let errors = {};

    const requiredFields = isSSOUser ? ['firstName', 'lastName'] : ['firstName', 'lastName', 'email'];

    // validate email string (string@string.com) and check domain is not on blacklist
    if (!validateEmail(localData.email, true)) {
      errors = {
        ...errors,
        email: defaultMessages.invalidEmail
      };
    }
    // validate email domain matches org domain
    if (!validateOrgDomain(localData.email, domain)) {
      if (!errors.email) {
        errors = {
          ...errors,
          email: defaultMessages.invalidDomain
        };
      }
    }
    // check that email/username not already in use done on backend

    // check if old password provided
    if (!isSSOUser && localData?.oldPassword) {
      requiredFields.push('password', 'oldPassword', 'passwordConfirmation');

      // new password compliant?
      if (!validatePassword(localData.password)) {
        errors = {
          ...errors,
          password: defaultMessages.passwordCompliance
        };
      }

      // confirmation password matches new password?
      if (localData.passwordConfirmation !== localData.password) {
        errors = {
          ...errors,
          passwordConfirmation: defaultMessages.passwordMismatch
        };
      }
    }

    const emptyFields = isEmpty(requiredFields, localData);

    if (emptyFields) {
      errors = {
        ...errors,
        ...emptyFields
      };
    }

    setErrorFields(errors);
    if (Object.keys(errors).length) {
      return;
    }

    try {
      await updateMeMutation({
        variables: {
          id: userId,
          data: {
            firstName: localData?.firstName,
            lastName: localData?.lastName,
            email: localData?.email
          }
        }
      });

      logAccountSettingsUpdate(localData, user, isSSOUser);

      if (localData?.oldPassword) {
        await updatePassword({
          variables: {
            oldPassword: localData?.oldPassword,
            password: localData?.password,
            passwordConfirmation: localData?.passwordConfirmation
          }
        });
      }
      await refetchUser();
      toast.success(STATUS_CODES.SUCCESS.DEFAULT.message);
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const updateProfilePicture = async (imageUrl) => {
    try {
      if (!imageUrl) {
        toast.error(defaultMessages.defaultError);
        return;
      }

      await updateMeMutation({
        variables: {
          id: userId,
          data: {
            imageUrl
          }
        }
      });
      logUpdateAvatar(user?.imageUrl, imageUrl);
      await refetchUser();
      toast.success(STATUS_CODES.SUCCESS.DEFAULT.message);
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const hasChanged = useMemo(() => {
    if (!localData) {
      return false;
    }

    const initialUser = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email
    };

    return !_.isEqual(localData, initialUser);
  }, [localData, user]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <UserForm
            showProfilePicture
            onProfileChange={updateProfilePicture}
            localData={localData}
            setLocalData={setLocalData}
            errorFields={errorFields}
            user={user}
          />
          <Grid item xs={12} container justifyContent='flex-end'>
            <ButtonEnhanced size='small' onClick={updateUser} loading={loading} disabled={!hasChanged}>
              <TypographyEnhanced id={TRANSLATION_PATH('buttons.save')} />
            </ButtonEnhanced>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default UserAccountSettings;
