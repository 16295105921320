import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_CRITICALITIES } from '../../api';

/**
 * Custom hook to encapsulate GET_CRITICALITIES
 * usage example:
 *
 * const { criticalities, loading, error, refetch } = useCriticalities()
 *
 * @returns {{criticalities: (Array), refetch: (variables?: Partial<{criticalities: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError}}
 */
const useCriticalities = () => {
  const { data, loading, error, refetch } = useQuery(GET_CRITICALITIES);

  const criticalities = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return data?.criticalities || [];
  }, [data?.criticalities, error, loading]);

  if (error) {
    console.error(error);
  }

  return { criticalities, loading, error, refetch };
};

export default useCriticalities;
