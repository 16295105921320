// views.app.manage.addUser

export const ADD_USER = {
  title: 'Add User',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
  buttons: {
    add: 'Add User'
  }
};
