import { OVERALL_TASKS } from '@anirudhm9/base-lib/lib/constants';
import { useQuery, useReactiveVar } from '@apollo/client';
import { CheckCircleOutlineRounded, CloseRounded, OpenInNewRounded } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_OVERALL_TASK_PROGRESS, orgVar } from '../../../../../api';
import { ChipEnhanced, DotLoader, IconButtonEnhanced, TypographyEnhanced } from '../../../../global';
import { ProgressChart } from '../../../../ui';
import ModalEnhanced from '../../../../ui/modal';
import { DEfAULT_CHART_OPTIONS } from './constants';
import { logOverallProgressClick, logRemediation } from './utils';

const TRANSLATION_PATH = (route) => {
  return `components.application.dashboard.summaries.progress.${route}`;
};

const useStyles = makeStyles(() => ({
  card: {
    height: '135px',
    textAlign: 'left'
  }
}));

const Progress = () => {
  const classes = useStyles();
  const orgId = useReactiveVar(orgVar);
  const navigate = useNavigate();

  const {
    data: progressData,
    loading: progressLoading,
    error: progressError
  } = useQuery(GET_OVERALL_TASK_PROGRESS, { variables: { id: orgId }, skip: !orgId, nextFetchPolicy: 'network-only' });

  const progressTotals = useMemo(() => {
    if (progressLoading || progressError) {
      return null;
    }
    return progressData?.getOverallTaskProgress || {};
  }, [progressData?.getOverallTaskProgress, progressError, progressLoading]);

  const [options, setOptions] = useState(DEfAULT_CHART_OPTIONS);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!progressTotals) {
      return;
    }

    setOptions((options) => {
      const optionsCopy = _.cloneDeep(options);
      optionsCopy.data[0].value = Math.round(progressTotals?.percentage || 0);
      return optionsCopy;
    });
  }, [progressTotals]);

  const handleClick = () => {
    logOverallProgressClick();
    setModalOpen((open) => !open);
  };

  const CardButton = (props) => {
    return <Button {...props} fullWidth onClick={handleClick} />;
  };

  return (
    <Box>
      <Card className={classes.card} component={CardButton}>
        <CardContent sx={{ height: '100%', width: '100%' }}>
          <Grid container>
            <Grid item xs={8}>
              <TypographyEnhanced id={TRANSLATION_PATH('title')} sx={{ fontWeight: 'bold' }} />
              <TypographyEnhanced id={TRANSLATION_PATH('description')} variant='caption' />
            </Grid>
            <Grid container item xs={4} sx={{ height: '100px' }}>
              <Grid item xs={12} sx={{ height: '100px' }}>
                {progressLoading ? (
                  <DotLoader sx={{ position: 'relative', alignItems: 'center', height: 80 }} color='primary' />
                ) : (
                  <ProgressChart options={options} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ModalEnhanced modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h5' />
          </Grid>
          <Grid item xs={12}>
            {progressLoading ? (
              <DotLoader sx={{ position: 'relative', alignItems: 'center', height: 80 }} color='primary' />
            ) : (
              <Table>
                <TableBody>
                  {Object.values(OVERALL_TASKS).map((task) => {
                    const { name, value, navigate: link } = task || {};
                    const { satisfactory } = progressTotals?.[value] || {};
                    return (
                      <TableRow key={value}>
                        <TableCell>
                          <TypographyEnhanced id={name || ''} />
                        </TableCell>
                        <TableCell>
                          <Grid container spacing={2} alignItems='center'>
                            <Grid item>
                              <ChipEnhanced
                                key={value}
                                id={satisfactory ? 'Yes' : 'No'}
                                color={satisfactory ? 'success' : 'error'}
                                icon={satisfactory ? <CheckCircleOutlineRounded /> : <CloseRounded />}
                              />
                            </Grid>
                            {!satisfactory && (
                              <Grid item>
                                <IconButtonEnhanced
                                  onClick={() => {
                                    logRemediation(value);
                                    navigate(link);
                                  }}
                                  color='primary'
                                >
                                  <OpenInNewRounded />
                                </IconButtonEnhanced>
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </Grid>
        </Grid>
      </ModalEnhanced>
    </Box>
  );
};

export default Progress;
