import { ISSUE_RELATIONS } from '@anirudhm9/base-lib/lib/constants';
import { DevicesRounded, HealthAndSafety, PeopleAltRounded, ViewModuleRounded, WarningAmber, WidgetsRounded } from '@mui/icons-material';

export const TRANSLATION_PATH_ADD = (route) => {
  return `views.app.manage.issues.addIssue.${route}`;
};

export const TRANSLATION_PATH_VIEW = (route) => {
  return `views.app.manage.issues.single.${route}`;
};

export const DEFAULT_ISSUE_DATA = {
  name: '',
  description: '',
  status: 'OPEN',
  criticality: '',
  org: '',
  question: '',
  application: '',
  device: '',
  user: '',
  assignees: '',
  relation: ISSUE_RELATIONS.NONE.value,
  type: 'MANUAL'
};

export const ISSUE_RELATION_OPTIONS = {
  QUESTION: {
    id: 'QUESTION',
    name: 'Question',
    value: 'QUESTION',
    icon: HealthAndSafety
  },
  DEVICE: {
    id: 'DEVICE',
    name: 'Device',
    value: 'DEVICE',
    icon: DevicesRounded
  },
  APPLICATION: {
    id: 'APPLICATION',
    name: 'Application',
    value: 'APPLICATION',
    icon: WidgetsRounded
  },
  USER: {
    id: 'USER',
    name: 'User',
    value: 'USER',
    icon: PeopleAltRounded
  },
  MODULE: {
    id: 'MODULE',
    name: 'Module',
    value: 'MODULE',
    icon: ViewModuleRounded
  },
  NONE: {
    id: 'NONE',
    name: 'Not applicable',
    value: 'NONE',
    icon: WarningAmber
  }
};

export const TOOLTIPS = {
  ACTIONS: 'Actions are derived automatically based on the specific Issue in question. They will be completed once they have been remediated at the source.',
  ASSIGNEES:
    'Assign the issue to be investigated and fixed by team members. You can also use the comments section to tag team members and get their attention if you need to have a conversation that can be tracked got audit purposes.',
  STATUS:
    'If you do not believe that this is a risk that needs to be addressed right now, change the status to "Risk Accepted". The status cannot be manually set to "Risk Resolved" as it will automatically change only when fixed at the source.',
  DISABLED_RELATIONS: 'Cannot add/edit relations for integration issues.',
  DISABLED_ACTIONS: 'Cannot add/edit/complete actions for integration issues.',
  DISABLED_MODULES: 'Cannot add/edit/complete modules for integration issues.',
  DISABLED_VULNERABILITIES: 'Cannot add/edit/complete vulnerabilities for integration issues.'
};

export const VIEW_ISSUE_MODAL_WIDTH = '700px';
