import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ButtonEnhanced, TypographyEnhanced } from '../../../global';
import { TRANSLATION_PATH } from './constants';

const NoIntegrations = ({ link }) => {
  const navigate = useNavigate();

  return (
    <Grid container direction='column' spacing={2} alignItems='center'>
      <Grid item>
        <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h6' textAlign='center' />
        <TypographyEnhanced id={TRANSLATION_PATH(link ? 'cloudDescription' : 'description')} variant='caption' textAlign='center' />
      </Grid>
      {link && (
        <Grid item>
          <ButtonEnhanced
            fullWidth
            size='large'
            variant='text'
            onClick={() => {
              navigate('/app/integrations');
            }}
          >
            <TypographyEnhanced id={TRANSLATION_PATH('connect')} />
          </ButtonEnhanced>
        </Grid>
      )}
    </Grid>
  );
};

NoIntegrations.propTypes = {
  link: PropTypes.string
};

NoIntegrations.propTypes = {};

export default NoIntegrations;
