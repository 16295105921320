const LOG_TYPES = {
  CYBER_ASSESSMENT: 'CYBER_ASSESSMENT',
  ISSUE: 'ISSUE'
};

const LOG_SUB_TYPES = {
  CREATED: 'CREATED',
  ARCHIVED: 'ARCHIVED',
  COMMENT: 'COMMENT',
  STATUS_CHANGE: 'STATUS_CHANGE',
  ANSWER_CHANGE: 'ANSWER_CHANGE',
  OPTION_ADDED: 'OPTION_ADDED'
};

const FORMAT_KEYS = {
  ARCHIVED: '<b>archived</b>',
  FROM: '<b>from</b>',
  FOR: '<b>for</b>',
  TO: '<b>to</b>',
  UPDATED: '<b>updated</b>',
  SET: '<b>set</b>',
  UPLOADED: '<b>uploaded</b>',
  CREATED: '<b>created</b>',
  CLEARED: '<b>cleared</b>'
};

module.exports = {
  LOG_TYPES,
  LOG_SUB_TYPES,
  FORMAT_KEYS
};
