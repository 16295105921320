import { CLOUD_SUMMARY as cloudSummary } from './cloudSummary';
import { DEVICES_IN_USE as devicesInUse } from './devicesInUse';
import { ISSUES_SUMMARY as issuesSummary } from './issuesSummary';
import { MANDATORY_FRAMEWORKS as mandatoryFrameworks } from './mandatoryFrameworks';
import { MULTI_FACTOR_AUTH as multiFactorAuth } from './multiFactorAuth';
import news from './news';
import { PASSWORD_STRENGTH as passwordStrength } from './passwordStrength';
import { THIRD_PARTY_APPLICATIONS as thirdPartyApplications } from './thirdPartyApplications';
import { USER_SUMMARY as userSummary } from './userSummary';
import { WEB_SECURITY_HEADERS as webSecurityHeaders } from './webSecurityHeaders';

export const WIDGETS = {
  cloudSummary,
  devicesInUse,
  mandatoryFrameworks,
  passwordStrength,
  thirdPartyApplications,
  userSummary,
  webSecurityHeaders,
  multiFactorAuth,
  issuesSummary,
  news
};
