import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { TypographyEnhanced } from '../../../../../global';

export const FILTERS = {
  KEY_BUSINESS_SYSTEM: {
    id: 'KEY_BUSINESS_SYSTEM',
    label: 'Key Business System',
    filters: [
      {
        id: 'KEY_BUSINESS_SYSTEM_CHECKBOX',
        label: '',
        type: 'checkbox',
        options: [
          { id: 'MAJOR', name: 'Yes', value: 'MAJOR' },
          { id: 'MINOR', name: 'Not Important', value: 'MINOR' },
          { id: 'NONE', name: 'No', value: 'NONE' }
        ]
      }
    ]
  },
  CREATION_METHOD: {
    id: 'CREATION_METHOD',
    label: 'Creation Method',
    filters: [
      {
        id: 'CREATION_METHOD_CHECKBOX',
        label: '',
        type: 'checkbox',
        options: [
          { id: 'CREATION_METHOD_MANUAL', name: 'Manually created', value: 'CREATION_METHOD_MANUAL' },
          { id: 'CREATION_METHOD_AUTOMATIC', name: 'Integration created', value: 'CREATION_METHOD_AUTOMATIC' }
        ]
      }
    ]
  }
};

export const SORT_VALUES = {
  ALPHABETICAL_DESC: {
    id: 'name:DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Alphabetical
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'name:DESC',
    label: ''
  },
  ALPHABETICAL_ASC: {
    id: 'name:ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Alphabetical
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'name:ASC',
    label: ''
  },
  SECURITY_SCORE_DESC: {
    id: 'thirdparty.securityScore:DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Security Score
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'thirdparty.securityScore:DESC',
    label: ''
  },
  SECURITY_SCORE_ASC: {
    id: 'thirdparty.securityScore:ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Security Score
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'thirdparty.securityScore:ASC',
    label: ''
  }
  //commented out until know how to put through as sort argument
  // USER_COUNT_DESC: {
  //   id: 'users_count:DESC',
  //   name: (
  //     <Grid container alignItems='center' justifyContent='space-between'>
  //       <TypographyEnhanced id='' mr={1}>
  //         User count
  //       </TypographyEnhanced>
  //       <ArrowDownwardRounded fontSize='small' />
  //     </Grid>
  //   ),
  //   value: 'users_count:DESC',
  //   label: ''
  // },
  // USER_COUNT_ASC: {
  //   id: 'users_count:ASC',
  //   name: (
  //     <Grid container alignItems='center' justifyContent='space-between'>
  //       <TypographyEnhanced id='' mr={1}>
  //         User count
  //       </TypographyEnhanced>
  //       <ArrowUpwardRounded fontSize='small' />
  //     </Grid>
  //   ),
  //   value: 'users_count:ASC',
  //   label: ''
  // }
};

export const SORT_OPTIONS = Object.values(SORT_VALUES);
