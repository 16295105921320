import { ASSESSMENTS as assessments } from './assessments';
import { DASHBOARD as dashboard } from './dashboard';
import { INTEGRATIONS as integrations } from './integrations';
import { MANAGE as manage } from './manage';
import { ONBOARDING as onboarding } from './onboarding';
import { REPORTS as reports } from './reports';
import { REWARDS as rewards } from './rewards';
import { SETTINGS as settings } from './settings';

export const APP = {
  assessments,
  dashboard,
  integrations,
  manage,
  reports,
  rewards,
  settings,
  onboarding
};
