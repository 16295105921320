import { ASSESSMENTS } from './assessments';
import { DASHBOARD } from './dashboard';
import { INTEGRATIONS } from './integrations';
import { MANAGE } from './manage';
import { ONBOARDING } from './onboarding';
import { REPORTS } from './reports';
import { REWARDS } from './rewards';
import { SETTINGS } from './settings';

export const APP = { ASSESSMENTS, DASHBOARD, INTEGRATIONS, MANAGE, ONBOARDING, REPORTS, REWARDS, SETTINGS };
