import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const MasonryLayout = ({ columns = 2, children, itemSizes = { xs: 12, md: 6, lg: 6, xl: 6 } }) => {
  const { xs, md, lg, xl } = itemSizes;

  const columnWrapper = { column0: [], column1: [] };
  const result = [];

  // divide children into columns
  for (let i = 0; i < children.length; i++) {
    const columnIndex = i % columns;
    if (!columnWrapper[`column${columnIndex}`]) {
      columnWrapper[`column${columnIndex}`] = [];
    }

    columnWrapper[`column${columnIndex}`].push(
      <div style={{ marginBottom: '20px' }} key={i}>
        {children[i]}
      </div>
    );
  }

  // wrap children in each column with a div
  for (let i = 0; i < columns; i++) {
    result.push(
      <Grid item xs={xs} md={md} lg={lg} xl={xl} key={i} className='wrapps'>
        {columnWrapper[`column${i}`]}
      </Grid>
    );
  }

  return <Fragment>{result}</Fragment>;
};

MasonryLayout.propTypes = {
  columns: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.element),
  itemSizes: PropTypes.object
};

export default MasonryLayout;
