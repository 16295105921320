const defaultOptions = {
  tooltip: {
    trigger: 'item'
  },
  xAxis: {
    type: 'category',
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      label: {
        show: false,
        position: 'center'
      },
      data: [],
      type: 'bar'
    }
  ]
};

export default defaultOptions;
