// EVENTS.VIEWS.USER.REGISTER

export const REGISTER = {
  PAGE_VISITED: 'sign_up-page_visited',
  SIGN_UP_BUTTON: 'sign_up-actioned_button',
  GOOGLE: 'google_sign_up-actioned_button',
  MICROSOFT: 'microsoft_sign_up-actioned_button',
  BACK: 'back_to_login-actioned_button',
  TC_DOC: 't&cs-doc_viewed',
  PP_DOC: 'privacy_policy-doc_viewed'
};
