import moment from 'moment';
import { openLink } from '../../../../../../helpers/routing';
import { logEvent } from '../../../../../../library';
import { DASHBOARD as DASHBOARD_EVENTS } from '../../../../../../library/amplitude/events/views/app/dashboard';

//? this doesn't seem to be used anywhere?
const getExpiryColor = (date) => {
  const difference = moment(date).diff(moment(), 'days');
  if (difference < 15) {
    return 'red';
  } else if (difference < 30) {
    return 'yellow';
  }
  return 'green';
};

/**
 * Logs event when user clicks the view all button
 *
 * @param {VoidFunction} navigate
 */
const logViewAll = (navigate) => {
  logEvent(DASHBOARD_EVENTS.WEB_SECURITY_HEADERS_VIEW_ALL);
  navigate('/app/reports/web-security-headers');
};

/**
 * Logs event when the user clicks a HeaderChip in the warnings section
 *
 * @param {Object} header
 */
const logWarningClick = (header) => {
  const { value } = header || {};
  logEvent(DASHBOARD_EVENTS.WEB_SECURITY_HEADERS_WARNING_CLICK, { header: value });
};

/**
 * Logs event when user clicks Open In New icon to view remediation info for a particular header
 *
 * @param {String} headerKey
 * @param {Object} remediation
 */
const logViewRemediation = (headerKey, remediation) => {
  const { link } = remediation || {};
  logEvent(DASHBOARD_EVENTS.WEB_SECURITY_HEADERS_OPEN_REMEDIATION, { header: headerKey });
  openLink(link);
};

export { getExpiryColor, logViewAll, logWarningClick, logViewRemediation };
