import { AddCircleOutlineRounded, CheckRounded, CloseRounded, DeleteRounded, EditRounded } from '@mui/icons-material';
import { Checkbox, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { TextFieldEnhanced } from '../../ui/form';
import IconButtonEnhanced from '../iconButton';
import TooltipEnhanced from '../tooltip';
import TypographyEnhanced from '../typography';

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius
  },
  disclaimer: {
    display: 'flex',
    justifyContent: 'center !important'
  }
}));

/**
 * @param {String} label Label for TextFieldEnhanced
 * @param {[{id: String | Number, name: String, completed: Boolean}]} items Collection of item objects
 * @param {Function} setItems setState func
 * @param {VoidFunction} onAdd setState func
 * @param {VoidFunction} onComplete Checkbox state handler
 *
 * @returns
 */

const TodoList = ({
  label,
  items,
  field = 'name',
  setItems,
  onAdd,
  onComplete,
  hasError,
  errorText,
  isAddDisabled,
  isDeleteDisabled,
  isCompleteDisabled,
  isEditDisabled,
  tooltipText,
  disabledTooltipText
}) => {
  const classes = useStyles();

  const [currentItem, setCurrentItem] = useState();

  const handleAdd = useCallback(() => {
    if (!currentItem) {
      return;
    }

    onAdd(currentItem);
    setCurrentItem('');
  }, [currentItem, onAdd]);

  const Item = ({ item, index }) => {
    const { id, name, completed } = item || {};

    const [editOpen, setEditOpen] = useState(false);
    const [editItem, setEditItem] = useState(name || '');
    const [checked, setChecked] = useState(completed);

    const handleDelete = useCallback(() => {
      setItems((prev) => {
        const itemsCopy = _.cloneDeep(prev || []);
        itemsCopy.splice(index, 1);
        return itemsCopy;
      });
    }, [index]);

    const handleEdit = useCallback(
      (value) => {
        setItems((prev) => {
          const itemsCopy = _.cloneDeep(prev || []);
          itemsCopy[index][field] = value;
          return itemsCopy;
        });
      },
      [index]
    );

    const handleComplete = useCallback(
      (event) => {
        setChecked((prev) => {
          onComplete(items[index], index, !prev);
          return !prev;
        });
        event.preventDefault();
      },
      [index]
    );

    const toggleEdit = () => {
      setEditOpen((open) => {
        if (open) {
          handleEdit(editItem);
        }
        return !open;
      });
    };

    const handleCancelEdit = () => {
      setEditOpen((open) => !open);
      setEditItem(name || '');
    };

    return (
      <Grid container className={classes.item} alignItems='center'>
        <Grid container item ml={1} alignItems='center' xs={9.5}>
          <Grid item xs={1}>
            {onComplete && (
              <TooltipEnhanced title={isCompleteDisabled ? disabledTooltipText : tooltipText} placement={'top'}>
                <Checkbox display='inline' disabled={isCompleteDisabled} value={name} checked={checked} onChange={handleComplete} />
              </TooltipEnhanced>
            )}
          </Grid>

          <Grid item xs={11}>
            {editOpen ? (
              <TextFieldEnhanced id={id} value={editItem || ''} onChange={setEditItem} multiline maxRows={4} />
            ) : (
              <TypographyEnhanced id={name} display='inline' />
            )}
          </Grid>
        </Grid>
        <Grid container item justifyContent='flex-end' xs={2} spacing={0.5}>
          <Grid item>
            <IconButtonEnhanced disabled={isEditDisabled} onClick={toggleEdit}>
              {editOpen ? <CheckRounded color='success' /> : <EditRounded />}
            </IconButtonEnhanced>
          </Grid>
          <Grid item>
            {editOpen ? (
              <IconButtonEnhanced onClick={handleCancelEdit}>
                <CloseRounded color='error' />
              </IconButtonEnhanced>
            ) : (
              <IconButtonEnhanced onClick={handleDelete} disabled={isDeleteDisabled}>
                <DeleteRounded />
              </IconButtonEnhanced>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  Item.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number
  };

  const Items = useCallback(() => {
    if (!items?.length) {
      return null;
    }

    return (
      <Grid container spacing={1}>
        {items.map((item, index) => {
          return (
            <Grid item key={index} xs={12}>
              <Item item={item} index={index} />
            </Grid>
          );
        })}
      </Grid>
    );
  }, [items]);

  Items.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      archived: PropTypes.bool
    })
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextFieldEnhanced
          id={label}
          label={label}
          value={currentItem || ''}
          onChange={(value) => setCurrentItem(value)}
          required
          display={'inline-block'}
          InputProps={{
            endAdornment: (
              <IconButtonEnhanced onClick={handleAdd} disabled={!currentItem || isAddDisabled}>
                <AddCircleOutlineRounded />
              </IconButtonEnhanced>
            )
          }}
          disabled={isAddDisabled}
          error={hasError}
          helperText={(hasError && errorText) || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <Items />
      </Grid>
    </Grid>
  );
};

TodoList.propTypes = {
  label: PropTypes.string,
  setItems: PropTypes.func,
  field: PropTypes.string,
  onAdd: PropTypes.func,
  onComplete: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired,
      completed: PropTypes.bool
    })
  ),
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  isAddDisabled: PropTypes.bool,
  isDeleteDisabled: PropTypes.bool,
  isCompleteDisabled: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
  tooltipText: PropTypes.string,
  disabledTooltipText: PropTypes.string
};

export default TodoList;
