import { IMAGES } from '@anirudhm9/base-lib/lib/constants';
import { Box, Button, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { TypographyEnhanced } from '../../../../global';
import { Image } from '../../../../ui';

const AppTile = ({ application, onClick, disabled }) => {
  const theme = useTheme();
  const { key, name } = application || {};

  const isDarkModeActive = useMemo(() => theme?.palette?.mode === 'dark', [theme]);
  const src = useMemo(() => {
    return IMAGES.ICONS[isDarkModeActive ? `${key}_DARK` : `${key}_LIGHT`] || IMAGES.ICONS[key];
  }, [isDarkModeActive, key]);

  const styles = {
    height: '130px',
    width: '115px',
    padding: '20px',
    border: '1px solid grey',
    borderRadius: '15px'
  };

  const imageStyle = {
    height: '40px',
    width: '40px'
  };

  return (
    <Box sx={styles} onClick={onClick} component={Button} disabled={disabled}>
      <Grid container spacing={1} direction='column' justifyContent='center' alignItems='center'>
        {src && (
          <Grid item>
            <Box sx={imageStyle}>
              <Image src={src || ''} alt={name} />
            </Box>
          </Grid>
        )}
        <Grid item>
          <TypographyEnhanced id={name} textAlign='center' />
        </Grid>
      </Grid>
    </Box>
  );
};

AppTile.propTypes = {
  application: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default AppTile;
