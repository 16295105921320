// EVENTS.VIEWS.APP.REPORTS.NEWS

export const NEWS = {
  PAGE_VISITED: 'news_board-page_visited',
  SEARCH: 'news_board-search',
  FILTER: 'news_board-filter',
  CLICK_ARTICLE: 'news_board-click_article',
  CHANGE_ROWS: 'news_board-rows_per_page',
  NAVIGATION: 'news_board-navigation',
  // BACK_BUTTON: 'news_board-back_button',
  // NEXT_BUTTON: 'news_board-next_button',
  COOKIE_TRAIL: 'news_board-cookie_trail'
};
