import { Add } from '@mui/icons-material';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fragment, useState } from 'react';
import { ConnectedIntegration, ConnectIntegrationModal, NoIntegrations } from '../../../../components/application/';
import { ButtonEnhanced, CardWithLoader, TypographyEnhanced } from '../../../../components/global';
import { Heading } from '../../../../components/ui';
import { useIntegrationContext } from '../../../../contexts';
import { logEvent } from '../../../../library';
import { INTEGRATIONS as INTEGRATIONS_EVENTS } from '../../../../library/amplitude/events/views/app/integrations';
import { TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  bold: {
    fontWeight: 'bold'
  }
}));

const Integrations = () => {
  const classes = useStyles();

  const { integrations, loading: integrationLoading } = useIntegrationContext();

  const [connectModalOpen, setConnectModalOpen] = useState(false);

  const handleClick = () => {
    logEvent(INTEGRATIONS_EVENTS.CLICK_CONNECT_ACCOUNT);
    setConnectModalOpen(true);
  };

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title')} />
      <ConnectIntegrationModal modalOpen={connectModalOpen} setModalOpen={setConnectModalOpen} />
      <Grid container component='main' spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <CardWithLoader
            cardHeaderProps={{
              title: <TypographyEnhanced id={TRANSLATION_PATH('cardTitle')} variant='h6' />,
              subheader: <TypographyEnhanced id={TRANSLATION_PATH('cardSubtitle')} variant='caption' />
            }}
            loading={integrationLoading}
          >
            <Grid container spacing={2}>
              {!integrations?.length ? (
                <NoIntegrations />
              ) : (
                <Fragment>
                  {integrations?.map((integration) => (
                    <Grid item xs={12} key={integration?.id}>
                      <ConnectedIntegration integration={integration} />
                    </Grid>
                  ))}
                </Fragment>
              )}
              <Grid container item justifyContent={!integrations?.length ? 'center' : 'flex-start'}>
                <ButtonEnhanced variant='outlined' onClick={handleClick}>
                  <Add />
                  <TypographyEnhanced id={TRANSLATION_PATH('connect')} />
                </ButtonEnhanced>
              </Grid>
            </Grid>
          </CardWithLoader>
        </Grid>
      </Grid>
    </Container>
  );
};

Integrations.propTypes = {};

export default Integrations;
