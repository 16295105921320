import { INTEGRATION_TYPES, ROLE_OPTIONS } from '@anirudhm9/base-lib/lib/constants';
import _ from 'lodash';
import { SORT_VALUES } from '../constants';

export const filterUsers = (users, filters) => {
  // Flatten out each filter group object into a single array
  const filterGroupsFlat = Object.values(filters).map((filterGroup) => {
    const filterGroupFlat = Object.values(filterGroup).flat(1);
    return filterGroupFlat;
  });
  // Flatten all filter group arrays into one array of all selected filters
  const filtersFlat = filterGroupsFlat.flat(1);
  if (!filtersFlat.length) {
    return users;
  }

  // If user passes filter evaluation, user object will be pushed to this array
  const userArray = [];

  for (const user of users) {
    /** FILTER PROPERTIES **/
    /** ALL_USERS filter properties **/
    const { archived, confirmed, orgRoles = [] } = user || {};
    const assignedUserRoles = (orgRoles || []).map((role) => role.value);
    const roleOptionValues = ROLE_OPTIONS.map((role) => role.value);
    /** USER_GOOGLE filter properties **/
    const { profiles = [] } = user || {};
    const googleProfile = (profiles || []).find((profile) => profile.type === INTEGRATION_TYPES.GSUITE.value) || {};
    const slackProfile = (profiles || []).find((profile) => profile.type === INTEGRATION_TYPES.SLACK.value) || {};

    const {
      admin: googleAdmin,
      delegateAdmin: googleDelegateAdmin,
      archived: googleArchived,
      blocked: googleBlocked,
      mfaEnabled: googleMfaEnabled,
      mfaEnforced: googleMfaEnforced
    } = googleProfile || {};

    const { admin: slackAdmin, delegateAdmin: slackDelegateAdmin, archived: slackArchived, blocked: slackBlocked } = slackProfile || {};

    /** FILTERS **/
    /** ALL_USERS filter evaluations **/
    if (filtersFlat.includes('ACTIVE_USERS') && !archived) {
      userArray.push(user);
      continue;
    }
    if (filtersFlat.includes('ARCHIVED') && archived) {
      userArray.push(user);
      continue;
    }
    if (filtersFlat.includes('REGISTERED') && confirmed) {
      userArray.push(user);
      continue;
    }
    if (filtersFlat.some((role) => roleOptionValues.includes(role))) {
      if (assignedUserRoles.some((role) => filtersFlat.includes(role))) {
        userArray.push(user);
        continue;
      }
    }
    /** USER_GOOGLE filter evaluations **/
    if (filtersFlat.includes('GOOGLE_ADMIN') && googleAdmin) {
      userArray.push(user);
      continue;
    }
    if (filtersFlat.includes('GOOGLE_DELEGATE_ADMIN') && googleDelegateAdmin) {
      userArray.push(user);
      continue;
    }
    if (filtersFlat.includes('GOOGLE_ARCHIVED') && googleArchived) {
      userArray.push(user);
      continue;
    }
    if (filtersFlat.includes('GOOGLE_SUSPENDED') && googleBlocked) {
      userArray.push(user);
      continue;
    }
    if (filtersFlat.includes('GOOGLE_MFA_ENABLED') && googleMfaEnabled) {
      userArray.push(user);
      continue;
    }
    if (filtersFlat.includes('GOOGLE_MFA_ENFORCED') && googleMfaEnforced) {
      userArray.push(user);
      continue;
    }
    // technically users that were deleted from google will not show as MFA enabled and they dont matter
    if (filtersFlat.includes('GOOGLE_MFA_NOT_ENABLED') && !_.isEmpty(googleProfile) && !googleMfaEnabled && !googleArchived && !googleBlocked) {
      userArray.push(user);
      continue;
    }

    /** USER_SLACK filter evaluations **/
    if (filtersFlat.includes('SLACK_ADMIN') && slackAdmin) {
      userArray.push(user);
      continue;
    }
    if (filtersFlat.includes('SLACK_DELEGATE_ADMIN') && slackDelegateAdmin) {
      userArray.push(user);
      continue;
    }
    if (filtersFlat.includes('SLACK_ARCHIVED') && slackArchived) {
      userArray.push(user);
      continue;
    }
    if (filtersFlat.includes('SLACK_SUSPENDED') && slackBlocked) {
      userArray.push(user);
      continue;
    }
  }

  // Return array of users
  return userArray;
};

export const sortUsers = (users, sortValue) => {
  let sortedUsers = _.cloneDeep(users || []);
  switch (sortValue) {
    case SORT_VALUES.ALPHABETICAL_ASC.value:
      sortedUsers = _.orderBy(sortedUsers || [], 'firstName', ['asc']);
      break;
    case SORT_VALUES.ALPHABETICAL_DESC.value:
      sortedUsers = _.orderBy(sortedUsers || [], 'firstName', ['desc']);
      break;
    default:
    // do nothing
  }
  return sortedUsers;
};
