import { logEvent } from '../../../../../../library';
import { DASHBOARD as DASHBOARD_EVENTS } from '../../../../../../library/amplitude/events/views/app/dashboard';

/**
 * Logs chart click event and navigates user
 *
 * @param {Object} event
 * @param {VoidFunction} navigate
 */
const logChartClick = (event, navigate) => {
  const { id: appId, name: appName } = event?.data || {};

  if (!appId) {
    navigate('/app/manage/applications');
  }
  logEvent(DASHBOARD_EVENTS.APPLICATIONS_CHART_CLICK, { application_id: appId, application_name: appName });
  navigate(`/app/manage/applications/${appId}`);
};

/**
 * Logs view all button click event and navigates user
 *
 * @param {VoidFunction} navigate
 */
const logViewAll = (navigate) => {
  logEvent(DASHBOARD_EVENTS.APPLICATIONS_VIEW_ALL);
  navigate('/app/manage/applications');
};

export { logChartClick, logViewAll };
