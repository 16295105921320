import { DialogActions, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { TransitionDialog } from '../../../../../containers';
import { ButtonEnhanced, TypographyEnhanced } from '../../../../global';

const DisclaimerModal = ({ open, toggle, title, handleSave }) => {
  return (
    <TransitionDialog
      open={open}
      toggle={toggle}
      title={'Confirmation'}
      actions={
        <DialogActions>
          <ButtonEnhanced onClick={toggle} variant={'outlined'}>
            Cancel
          </ButtonEnhanced>

          <ButtonEnhanced
            onClick={() => {
              toggle();
              handleSave();
            }}
          >
            Save
          </ButtonEnhanced>
        </DialogActions>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <TypographyEnhanced id={title} />
        </Grid>
      </Grid>
    </TransitionDialog>
  );
};

DisclaimerModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  handleSave: PropTypes.func
};

export default DisclaimerModal;
