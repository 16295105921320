import PropTypes from 'prop-types';
import EditUsersSelect from './editUsersSelect';
import ViewUsersChips from './viewUsersChips';

const ViewEditUsers = ({ edit, ...props }) => {
  if (edit) {
    return <EditUsersSelect {...props} />;
  } else {
    return <ViewUsersChips {...props} />;
  }
};

ViewEditUsers.propTypes = {
  edit: PropTypes.bool
};

export default ViewEditUsers;
