import { AssessmentRounded, AutoGraph, BlurOnRounded, HealthAndSafety, Settings, ShoppingBasket } from '@mui/icons-material';
import ASSESSMENTS from './assessments';
import MANAGE_SUBS from './manage';
import REPORTS from './reports';
import SETTINGS_SUBS from './settings';

const sideMenu = [
  {
    id: 'home',
    icon: <AutoGraph />,
    label: 'Dashboard',
    to: '/app/dashboard',
    viewEvent: 'dashboard-page_visited'
  },
  {
    id: 'assessments',
    icon: <HealthAndSafety />,
    label: 'Assessments',
    to: '/app/assessment',
    mobile: true,
    viewEvent: 'cyber_assessment-page_visited',
    subs: ASSESSMENTS
  },
  {
    id: 'reports',
    icon: <AssessmentRounded />,
    label: 'Reports',
    to: '/app/reports',
    subs: REPORTS,
    mobile: true
  },
  // {
  //   id: 'rewards',
  //   icon: <Circle />,
  //   label: 'Rewards',
  //   to: '/app/rewards',
  //   subs: REWARD_SUBS,
  //   mobile: true,
  //   comingSoon: true
  // },
  {
    id: 'integrations',
    icon: <ShoppingBasket />,
    label: 'Integrations',
    to: '/app/integrations',
    viewEvent: 'integration-page_visited'
  },
  {
    id: 'manage',
    icon: <BlurOnRounded />,
    label: 'Manage',
    to: '/app/manage',
    subs: MANAGE_SUBS
  },
  {
    id: 'settings',
    icon: <Settings />,
    label: 'Settings',
    to: '/app/settings',
    subs: SETTINGS_SUBS,
    mobile: true
  }
];

/**
 * @description Recursively loops through sideMenu items and sub items to get all page view events.
 *
 * @param {[{id: String, icon: Element, label: String, to: String, subs: Array, mobile: Boolean, private: Boolean}]} collection
 * @param {Object} result
 * @returns {to: String, viewEvent: String}
 */
const getAllViewEvents = (collection, result = {}) => {
  for (const item of collection) {
    const { subs = [], to, viewEvent } = item || {};
    //Base case:
    result[to] = viewEvent;
    //Recursive case:
    if (subs?.length) {
      getAllViewEvents(subs, result);
    }
  }
  return result;
};

export const SIDEMENU_VIEW_EVENTS = getAllViewEvents(sideMenu);

export default sideMenu;
