import { useQuery } from '@apollo/client';
import { CollectionsBookmarkRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded, OpenInNewRounded } from '@mui/icons-material';
import { Avatar, Card, CardContent, Collapse, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { COUNT_MODULES, GET_MODULES } from '../../../../../../api';
import { useOrgContext } from '../../../../../../contexts';
import { openLink } from '../../../../../../helpers/routing';
import { CardWithLoader, ChipEnhanced, IconButtonEnhanced, TypographyEnhanced } from '../../../../../global';
import { PaginationEnhanced } from '../../../../../ui';
import { DEFAULT_PAGE_SIZE } from '../../../../../ui/pagination/constants';
import { DEVELOPMENT_FRAMEWORK_SOURCE } from '../../constants';
import { ModuleCard } from '../../module';
import AuditSummary from '../auditSummary';

const LibraryCard = ({ library }) => {
  const { orgId } = useOrgContext();

  const { id, name, url, private: notPublic, developmentFramework } = library || {};

  const [open, setOpen] = useState(false);
  const [start, setStart] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const {
    data: moduleCountData,
    loading: moduleCountLoading,
    error: moduleCountError
    // refetch: refetchProjectCount
  } = useQuery(COUNT_MODULES, {
    variables: { where: { org: orgId, library: id } },
    skip: !orgId || !id || !open
  });

  const sbomCount = useMemo(() => {
    if (moduleCountLoading || moduleCountError) {
      return 0;
    }
    return moduleCountData?.countModules || 0;
  }, [moduleCountData?.countModules, moduleCountError, moduleCountLoading]);

  const { data: moduleData, loading: moduleLoading } = useQuery(GET_MODULES, {
    variables: { where: { org: orgId, library: id }, start, limit: pageSize },
    skip: !open || !id
  });

  const modules = useMemo(() => {
    if (moduleLoading) {
      return [];
    }
    return moduleData?.modules || [];
  }, [moduleData?.modules, moduleLoading]);

  const toggle = () => {
    setOpen((open) => !open);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item spacing={2} justifyContent='space-between'>
            <Grid container item xs alignItems='center'>
              <CollectionsBookmarkRounded color='primary' />
              <TypographyEnhanced id={name} display='inline' fontWeight='bold' ml={1} mr={1} />
              <ChipEnhanced id={notPublic ? 'Private' : 'Public'} size='small' variant='outlined' color='primary' />
              <IconButtonEnhanced onClick={() => openLink(url, '_blank')}>
                <OpenInNewRounded />
              </IconButtonEnhanced>
            </Grid>
            <Grid container item xs alignItems='center' justifyContent='flex-end' spacing={1}>
              <Grid item mr={1}>
                <AuditSummary auditSummary={library?.auditSummary || {}} />
              </Grid>
              <Grid item>
                <ChipEnhanced
                  id={developmentFramework}
                  avatar={<Avatar src={DEVELOPMENT_FRAMEWORK_SOURCE?.[developmentFramework] || '#'} alt={developmentFramework} />}
                />
              </Grid>
              <Grid item>
                <IconButtonEnhanced onClick={toggle}>{open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}</IconButtonEnhanced>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={open}>
              <Grid container spacing={2}>
                {moduleLoading ? (
                  <Grid container item spacing={2}>
                    {Array.from(Array(pageSize)).map((_, index) => (
                      <Grid key={`sbom-loading-card-${index}`} item xs={12}>
                        <CardWithLoader loading={moduleLoading} />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid container item spacing={2}>
                    {modules.map((module) => {
                      const { id } = module || {};
                      return (
                        <Grid item xs={12} key={id}>
                          <ModuleCard module={module} />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <PaginationEnhanced total={sbomCount} onChange={setStart} pageSize={pageSize} setPageSize={setPageSize} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

LibraryCard.propTypes = {
  library: PropTypes.object
};
export default LibraryCard;
