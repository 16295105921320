import { useTheme } from '@emotion/react';
import { MarkEmailReadRounded, SendRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { IconButtonEnhanced, TooltipEnhanced } from '../../../../global';
import { Image } from '../../../../ui';

/**
 *
 * @param {Boolean} confirmed
 * @param {Boolean} invited
 * @param {VoidFunction} onClick
 * @param {Boolean} loading
 * @returns
 */
const StatusIcon = ({ confirmed, invited, onClick, loading }) => {
  const theme = useTheme();
  const darkMode = useMemo(() => theme.palette.mode === 'dark', [theme]);

  if (!confirmed) {
    if (invited) {
      return (
        <Grid item mr={1} mt={0.5}>
          <TooltipEnhanced title={'Essentials invitation sent'}>
            <MarkEmailReadRounded color='primary' />
          </TooltipEnhanced>
        </Grid>
      );
    }
    return (
      <Grid item>
        <TooltipEnhanced title={'Invite user to Essentials'} showInfoModal={false}>
          <IconButtonEnhanced onClick={onClick} loading={loading}>
            <SendRounded color='primary' />
          </IconButtonEnhanced>
        </TooltipEnhanced>
      </Grid>
    );
  }

  return (
    <Grid item>
      <TooltipEnhanced title='Registered Essentials user'>
        <Image src={`/assets/img/${darkMode ? 'logo-white-icon' : 'avertro-icon'}.png`} sx={{ height: darkMode ? 25 : 35, width: darkMode ? 25 : 35 }} />
      </TooltipEnhanced>
    </Grid>
  );
};

StatusIcon.propTypes = {
  confirmed: PropTypes.bool,
  invited: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func
};
export default StatusIcon;
