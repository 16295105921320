import { convertEnumeration } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { TextFieldEnhanced } from '../../../../../../ui/form';

const inputType = (key) => {
  return key.match(/secret|password/gi) ? 'password' : 'text';
};

const IntegrationSettings = ({ localSettings, setLocalSettings, errorFields, configuration }) => {
  const { settings = {}, placeholders } = configuration || {};

  const updateLocalData = (key, value) => {
    setLocalSettings((prev) => {
      return { ...prev, [key]: value };
    });
  };

  return Object.keys(settings || {}).map((key) => (
    <Grid item key={key} xs={12} md={6}>
      <TextFieldEnhanced
        id={key}
        label={convertEnumeration(key, true)}
        value={localSettings?.[key] || ''}
        onChange={(value) => updateLocalData(key, value)}
        type={inputType(key)}
        hasError={!!errorFields?.[key]}
        errorText={errorFields?.[key]}
        placeholder={placeholders?.[key]}
        required
      />
    </Grid>
  ));
};

IntegrationSettings.propTypes = {
  localSettings: PropTypes.object,
  setLocalSettings: PropTypes.func,
  errorFields: PropTypes.object,
  configuration: PropTypes.object
};
export default IntegrationSettings;
