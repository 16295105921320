export const DEfAULT_CHART_OPTIONS = {
  data: [
    {
      value: 0,
      name: 'Overall Progress',
      detail: {
        valueAnimation: true,
        offsetCenter: ['0%', '0%']
      }
    }
  ],
  axisLine: {
    lineStyle: {
      width: 10,
      opacity: 0.2
    }
  }
};
