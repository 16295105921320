// views.app.manage.issues.single

export default {
  title: {
    view: 'Issue',
    add: 'Add Issue'
  },
  description:
    'Manage this issue by collaborating with other team members. Issues should not remain open over an extended period of time. They either need to be accepted or resolved.',
  statusDisclaimer: {
    RESOLVED: 'By setting status to risk resolved, after issue creation all actions will be set to complete.',
    ACCEPTED: 'By accepting this risk you are agreeing that the risk profile is acceptable to your organisation.'
  },
  actionCheckboxTooltip: {
    customAction: 'Mark this action as complete.',
    integrationAction: 'Only custom issues can be marked "complete" manually.'
  }
};
