import { sortVersionArray } from '@anirudhm9/base-lib/lib/utils';
import { useQuery } from '@apollo/client';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { GET_FRAMEWORKS, GET_STRUCTURED_MAPPINGS, GET_VERSIONS } from '../../../../api';
import { Heading, TreeChart } from '../../../../components/ui';
import { SelectEnhanced } from '../../../../components/ui/form';
import { useOrgContext } from '../../../../contexts';
import { FRAMEWORK_MAPPING_TYPES } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const Mappings = () => {
  const classes = useStyles();
  const { orgId } = useOrgContext();

  const { data: versionData, loading: versionLoading, error: versionError } = useQuery(GET_VERSIONS, { variables: { where: {} } });
  const [selectedVersion, setSelectedVersion] = useState('');
  const [sourceFramework, setSourceFramework] = useState();
  const [destinationFramework, setDestinationFramework] = useState();

  const {
    data: frameworksData,
    loading: frameworksLoading,
    error: frameworksError
  } = useQuery(GET_FRAMEWORKS, {
    variables: {
      where: { version: selectedVersion, org: orgId, mapping_null: false }
    },
    skip: !selectedVersion || !orgId
  });

  const {
    data: mappingData,
    loading: mappingLoading,
    error: mappingError
  } = useQuery(GET_STRUCTURED_MAPPINGS, {
    variables: {
      version: selectedVersion,
      sourceFramework,
      destinationFramework
    },
    skip: !selectedVersion || !sourceFramework
  });

  const [seriesData, setSeriesData] = useState();

  const versions = useMemo(() => {
    if (versionLoading || versionError) {
      return [];
    }
    const versions = versionData?.versions || [];
    const latestVersion = sortVersionArray([...versions], 'number')[0] || {};

    setSelectedVersion(latestVersion.id || '');
    return versions.map((version) => {
      return { ...version, name: `${version.name} - ${version.number}` };
    });
  }, [versionData?.versions, versionError, versionLoading]);

  const frameworks = useMemo(() => {
    if (frameworksLoading || frameworksError) {
      return [];
    }
    return frameworksData?.frameworks;
  }, [frameworksData?.frameworks, frameworksError, frameworksLoading]);

  useEffect(() => {
    if (mappingLoading || mappingError) {
      return;
    }
    const { getStructuredMappings } = mappingData || {};
    setSeriesData(getStructuredMappings);
  }, [mappingData, mappingError, mappingLoading]);

  const handleFramworkChange = (type, value) => {
    const framework = frameworks?.find((framework) => framework.id === value);
    switch (type) {
      case FRAMEWORK_MAPPING_TYPES.NON_DOMINANT:
        setDestinationFramework(framework);
        break;
      default:
        setSourceFramework(framework);
    }
  };

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={'Arc Reactor'} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SelectEnhanced label='Select version' value={selectedVersion || ''} options={versions || []} onChange={setSelectedVersion} />
        </Grid>
        <Grid item xs={6}>
          <SelectEnhanced
            label='Dominant'
            value={frameworks?.find((framework) => framework.value === sourceFramework?.value)?.id || ''}
            options={frameworks || []}
            onChange={(value) => handleFramworkChange(FRAMEWORK_MAPPING_TYPES.DOMINANT, value)}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectEnhanced
            label='Non-dominant (Expand)'
            value={frameworks?.find((framework) => framework.value === destinationFramework?.value)?.id || ''}
            options={frameworks || []}
            onChange={(value) => handleFramworkChange(FRAMEWORK_MAPPING_TYPES.NON_DOMINANT, value)}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ height: '80vh' }}>
          <TreeChart seriesData={seriesData} />
        </Grid>
      </Grid>
    </Container>
  );
};

Mappings.propTypes = {};

export default Mappings;
