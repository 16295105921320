import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ManualAddDevice from '../../../../../components/application/manage/devices/manualAddDevice';
import { CardWithLoader } from '../../../../../components/global';
import { Heading } from '../../../../../components/ui';
import { TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const AddDevice = () => {
  const classes = useStyles();

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title') || ''} />
      <CardWithLoader>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ManualAddDevice />
          </Grid>
        </Grid>
      </CardWithLoader>
    </Container>
  );
};

export default AddDevice;
