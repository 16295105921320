import { useTheme } from '@mui/styles';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DEFAULT_OPTIONS } from '../basic/constants';

const StackedColumnChart = ({ options, onEvents }) => {
  const theme = useTheme();

  const [chartOptions, setChartOptions] = useState();

  useEffect(() => {
    if (!options) {
      return;
    }

    const { palette } = theme || {};
    const { background, text } = palette || {};
    const defaultOptions = _.cloneDeep(DEFAULT_OPTIONS);

    const { tooltip: defaultTooltip, xAxis: defaultXAxis = {}, yAxis: defaultYAxis = {} } = defaultOptions || {};

    const { tooltip, xAxis = {}, yAxis = [], ...rest } = options || {};

    defaultOptions.tooltip = {
      ...defaultTooltip,
      backgroundColor: background?.default,
      textStyle: { color: text?.primary },
      ...(tooltip || {})
    };

    defaultOptions.legend = {
      data: options?.series?.map((data) => data.name) || [],
      textStyle: { color: text?.primary }
    };

    defaultOptions.xAxis = {
      ...(defaultXAxis || {}),
      axisLabel: {
        color: text?.primary
      },
      ...(xAxis || {})
    };

    defaultOptions.yAxis = {
      ...(defaultYAxis || {}),
      axisLabel: {
        color: text?.primary
      },
      ...(yAxis || {})
    };

    setChartOptions({ ...(defaultOptions || {}), ...(rest || {}) });
  }, [options, theme]);

  if (!chartOptions) {
    return null;
  }

  return <ReactEcharts option={chartOptions} onEvents={onEvents} />;
};

StackedColumnChart.propTypes = {
  options: PropTypes.object,
  onEvents: PropTypes.object
};

export default StackedColumnChart;
