import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const TableEnhanced = ({ columns, rows }) => {
  const [rowData, setRowData] = useState();
  const [columnData, setColumnData] = useState();

  useEffect(() => {
    if (!columns && !rows) return;

    const colItems = (
      <TableRow>
        {columns?.map((col, i) => {
          return (
            <TableCell align='left' key={i}>
              <Typography variant='body1'>{col}</Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );

    const rowItems = rows.map((row, i) => {
      const { className, sx = {} } = row || {};
      const cols = Object.keys(row);
      return (
        <TableRow key={i}>
          {cols.map((col, i) => {
            if (col === 'className' || col === 'sx') {
              return null;
            }

            return (
              <TableCell align='left' key={i}>
                {typeof row[col] === 'string' ? (
                  <Typography variant='body1' className={className} sx={sx}>
                    {row[col]}
                  </Typography>
                ) : (
                  row[col]
                )}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });

    setColumnData(colItems);
    setRowData(rowItems);
  }, [columns, rows]);

  return (
    <TableContainer>
      <Table size='small' aria-label='a dense table'>
        {columns && <TableHead>{columnData}</TableHead>}
        <TableBody>{rowData}</TableBody>
      </Table>
    </TableContainer>
  );
};

TableEnhanced.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array
};

export default TableEnhanced;
