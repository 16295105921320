import { DEVICE_CATEGORY_TYPES, DEVICE_OS_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { formatDate, formatUserName, hasDateExpired } from '@anirudhm9/base-lib/lib/utils';
import { Button, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsDesktop } from '../../../../../hooks';
import { ChipEnhanced, TooltipEnhanced, TypographyEnhanced } from '../../../../global';
import { LetterAvatar } from '../../../../ui';
import DeviceIcon from '../deviceIcon';
import { formatDeviceName } from '../utils';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    textAlign: 'left'
  },
  syncStatusChip: {
    '& .MuiChip-avatar': {
      width: '30px',
      height: '30px'
    }
  }
}));

const SingleDeviceBar = ({ device }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  //Destructure device object
  const { id: deviceId, user = {}, lastSync } = device || {};

  const deviceName = useMemo(() => {
    if (!device) {
      return '';
    }
    return formatDeviceName(device);
  }, [device]);

  //Get user data from user id
  const { id: userId } = user || {};

  // Check if it has been > 90 days since device was last synced
  const lastSyncOverdue = useMemo(() => {
    return hasDateExpired(lastSync, 90);
  }, [lastSync]);

  const CardButton = (props) => {
    return (
      <Button
        // disabled={!device?.custom}
        {...props}
        fullWidth
        onClick={() => {
          navigate(`/app/manage/devices/${deviceId}`);
        }}
      />
    );
  };

  return (
    <Card elevation={2}>
      <CardContent component={CardButton} className={classes.card}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item>
            <DeviceIcon src={DEVICE_OS_TYPES[device?.type].src || DEVICE_OS_TYPES.OTHER.src} sx={{ width: '30px', height: '30px' }} />
          </Grid>
          <Grid container item spacing={1} xs={8} sm direction='column' alignItems='flex-start'>
            <Grid item>
              <TypographyEnhanced id={deviceName} />
            </Grid>
            <Grid container item spacing={1}>
              <Grid item xs justifyContent='flex-start'>
                <ChipEnhanced id={formatUserName(user)} onClick={() => navigate(`/app/manage/users/${userId}`)} avatar={<LetterAvatar user={user} />} />
              </Grid>

              <Grid item xs sx={{ textAlign: isDesktop ? 'right !important' : '' }}>
                <TooltipEnhanced title={`Device${lastSyncOverdue ? ' not' : ''} synced within the last 90 days.`}>
                  <ChipEnhanced
                    className={classes.syncStatusChip}
                    id={formatDate(lastSync)}
                    avatar={
                      <DeviceIcon src={lastSyncOverdue ? DEVICE_CATEGORY_TYPES.SYNC_STATUS_OVERDUE.src : DEVICE_CATEGORY_TYPES.SYNC_STATUS_CURRENT.src} />
                    }
                  />
                </TooltipEnhanced>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SingleDeviceBar.propTypes = {
  device: PropTypes.object
};

export default SingleDeviceBar;
