import { useTheme } from '@emotion/react';
import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButtonEnhanced } from '../../../components/global';
import { menuItems as routeOptions } from '../../../constants';
import { hasAccessToRoute } from '../../../helpers/routing';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '64px', // app bar offset
    backgroundColor: theme.palette.background.paper
  },
  boxContainer: {
    height: '120px',
    width: '100%',
    '&$boxContainer:hover': {
      cursor: 'pointer'
    }
  },
  itemSelected: {
    borderLeft: `5px solid ${theme.palette.primary.main}`
  }
}));

const SideMenu = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const color = theme.palette.primary.main;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const { roles } = props || {};

    const filterRouteOptions = () => {
      const pages = _.cloneDeep(routeOptions);

      return pages.filter((option) => {
        if (option.subs) {
          option.subs = option.subs.filter((sub) => hasAccessToRoute(roles, sub.to)) || [];
          return hasAccessToRoute(roles, option.to) && option.subs.length;
        }
        return hasAccessToRoute(roles, option.to);
      });
    };

    setMenuItems(filterRouteOptions());
  }, [props]);

  useEffect(() => {
    const currentLocation = String(window.location.pathname);

    menuItems.forEach((item, index) => {
      const menuItem = String(item.to);

      if (menuItem === currentLocation) {
        setSelectedIndex(index);
      }
    });
  }, [menuItems]);

  const handleListItemClick = (event, index, goTo) => {
    setSelectedIndex(index);
    navigate(goTo);
  };

  return (
    <Grid container direction='column' className={classes.root}>
      {menuItems.map((item, index) => {
        const isSelected = selectedIndex === index;
        return (
          <Fragment key={index}>
            <Grid
              item
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              className={classes.boxContainer}
              classes={{
                root: isSelected && classes.itemSelected
              }}
              onClick={(e) => {
                handleListItemClick(e, index, item.to);
              }}
            >
              <Grid item>
                <IconButtonEnhanced className='material-icons-round' style={{ ...(isSelected && { color }) }}>
                  {item.icon}
                </IconButtonEnhanced>
              </Grid>
              <Grid item>
                <Typography variant='caption' style={{ ...(isSelected && { color }) }}>
                  {item.label}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </Fragment>
        );
      })}
    </Grid>
  );
};

export default SideMenu;
