// EVENTS.VIEWS.APP.MANAGE.APPLICATIONS

export const APPLICATIONS = {
  PAGE_VISITED: 'manage_applications-page_visited',
  VIEW_APPLICATION: 'manage_applications-view_application',
  CLICK_ADD_APPLICATION: 'manage_applications-click_add_application',
  SEARCH: 'manage_applications-search',
  FILTER: 'manage_applications-filter',
  BACK_BUTTON: 'manage_applications-back_button',
  NEXT_BUTTON: 'manage_applications-next_button',
  NAVIGATION: 'manage_applications-navigation',
  ROWS_PER_PAGE: 'manage_applications-rows_per_page',
  COOKIE_TRAIL: 'manage_applications-cookie_trail'
};
