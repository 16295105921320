import { CloseRounded } from '@mui/icons-material';
import { Box, Divider, Drawer, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ButtonEnhanced, IconButtonEnhanced, TypographyEnhanced } from '../../../global';
import { TRANSLATION_PATH } from '../constants';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  drawer: {
    width: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  container: {
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: '100vw'
    },
    height: '100%',
    padding: theme.spacing(2)
  }
}));

const FilterDrawer = ({ open, toggle, children, onReset, onFilter }) => {
  const classes = useStyles();

  const handleReset = () => {
    try {
      onReset();
    } catch (error) {
      console.error(error);
    } finally {
      toggle();
    }
  };

  const handleFilter = () => {
    try {
      onFilter();
    } catch (error) {
      console.error(error);
    } finally {
      toggle();
    }
  };

  return (
    <Drawer open={open} onClose={toggle} anchor='right' className={classes.drawer}>
      <Box className={classes.container}>
        <Grid container direction='column' justifyContent='space-between' spacing={2} height='100%'>
          <Grid container item spacing={2}>
            {/* TOP NAV OFFSET  */}
            <Grid container item xs={12} className={classes.appBarSpacer} />

            {/* TITLE AREA */}
            <Grid container item justifyContent='space-between' spacing={2}>
              <Grid item>
                <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h6' />
              </Grid>
              <Grid item>
                <IconButtonEnhanced size='small' onClick={toggle}>
                  <CloseRounded />
                </IconButtonEnhanced>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* FILTERS */}
            <Grid container item xs={12} spacing={2}>
              {children}
            </Grid>
          </Grid>

          {/* FOOTER */}
          <Grid container item spacing={2} justifyContent='flex-end'>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item>
              <ButtonEnhanced variant='outlined' onClick={handleReset}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.clear')} />
              </ButtonEnhanced>
            </Grid>
            <Grid item>
              <ButtonEnhanced onClick={handleFilter}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.filter')} />
              </ButtonEnhanced>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  toggle: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
};

export default FilterDrawer;
