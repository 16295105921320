import { VisibilityRounded } from '@mui/icons-material';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { useXarrow } from 'react-xarrows';
import { IconButtonEnhanced, TypographyEnhanced } from '../../../../../../../components/global';
import { ShowMoreText } from '../../../../../../../components/ui';
import { useThreatModellingContext } from '../../../../../../../contexts';
import { useIsDesktop } from '../../../../../../../hooks';

const useStyles = makeStyles(() => ({
  cell: {
    cursor: 'pointer'
  }
}));

const ThreatModellingLink = ({ id, subTechnique, color, defaultShowMore, onCategorySelect }) => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();

  const { setSelectedCategoryId } = useThreatModellingContext();

  const updateXarrow = useXarrow();

  const handleClick = (event) => {
    event?.stopPropagation();
    try {
      onCategorySelect && onCategorySelect(subTechnique?.id);
      setSelectedCategoryId && setSelectedCategoryId(subTechnique?.id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Draggable onDrag={updateXarrow} onStop={updateXarrow}>
      <Box id={id} className={classes.cell}>
        <Card elevation={4}>
          <CardContent sx={{ backgroundColor: color?.hex }}>
            <Grid container alignItems='center' justifyContent='space-between'>
              <Grid item>
                <TypographyEnhanced id={''} fontWeight='bold' flexWrap='nowrap'>
                  {subTechnique?.mappingNumber}
                </TypographyEnhanced>
              </Grid>
              <Grid item>
                <IconButtonEnhanced onClick={handleClick} color='primary'>
                  <VisibilityRounded />
                </IconButtonEnhanced>
              </Grid>
            </Grid>
            {isDesktop && (
              <ShowMoreText limit={50} defaultShowMore={defaultShowMore}>
                {subTechnique?.name}
              </ShowMoreText>
            )}
          </CardContent>
        </Card>
      </Box>
    </Draggable>
  );
};

ThreatModellingLink.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subTechnique: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    mappingNumber: PropTypes.string.isRequired
  }),
  color: PropTypes.shape({
    hex: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string
  }),
  defaultShowMore: PropTypes.bool,
  onCategorySelect: PropTypes.func
};
export default ThreatModellingLink;
