import { EVENTS } from '../../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.app.rewards.earn.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.APP.REWARDS.EARN[event];
};

// filters options to display
export const filterOptions = ['PROGRESSION'];
