import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_ISSUES_BREAKDOWN } from '../../../../../api';
import { useOrgContext } from '../../../../../contexts';
import { useCriticalities, useStatuses } from '../../../../../hooks';
import { ButtonEnhanced, DotLoader, TypographyEnhanced } from '../../../../global';
import { WidgetContainer } from '../../../../ui';
import { CriticalityDonut, StatusDonut } from '../../../manage';
import { FILTERS } from '../../../manage/issues/filter/constants';
import { logChartClick, logLegendClick, logViewAll } from './utils';

const PATH = 'components.application.dashboard.widgets.issuesSummary';

const TRANSLATION_PATH = (route) => {
  return `${PATH}.${route}`;
};

const useStyles = makeStyles(() => ({
  issuesChart: {
    marginTop: 0,
    marginBottom: 5,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const IssuesSummary = () => {
  const { orgId } = useOrgContext();
  const { criticalities, criticalitiesLoading, criticalitiesError } = useCriticalities();
  const { statuses, statusesLoading, statusError } = useStatuses();
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    data: issuesBreakdownData,
    loading: issuesBreakdownLoading,
    error: issuesBreakdownError
  } = useQuery(GET_ISSUES_BREAKDOWN, {
    variables: { where: { org: orgId, _or: [{ archived: false }, { archived_null: true }] } }
  });

  const issuesBreakdown = useMemo(() => {
    if (issuesBreakdownLoading || issuesBreakdownError) {
      return {};
    }

    return issuesBreakdownData?.getIssuesBreakdown || {};
  }, [issuesBreakdownLoading, issuesBreakdownData?.getIssuesBreakdown, issuesBreakdownError]);

  const statusOptions = useMemo(() => {
    if (statusesLoading || statusError) {
      return;
    }
    return statuses;
  }, [statusError, statuses, statusesLoading]);

  const criticalityOptions = useMemo(() => {
    if (criticalitiesLoading || criticalitiesError) {
      return;
    }
    return criticalities;
  }, [criticalities, criticalitiesError, criticalitiesLoading]);

  const onChartClick = useCallback(
    (event, type) => {
      logChartClick(event, type, statusOptions, criticalityOptions, navigate);
    },
    [criticalityOptions, navigate, statusOptions]
  );

  return (
    <WidgetContainer title={TRANSLATION_PATH('title')}>
      <Grid item xs={12}>
        <TypographyEnhanced id={TRANSLATION_PATH('description')} />
      </Grid>
      {issuesBreakdownLoading ? (
        <Grid item xs={12} m={2}>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Grid container item>
          <Grid item xs={12} md={6} className={classes.issuesChart}>
            <CriticalityDonut
              breakdown={issuesBreakdown?.criticality || {}}
              dashboardView={'scroll'}
              onEvents={{
                click: (event) => onChartClick(event, FILTERS.CRITICALITY.id),
                legendSelectChanged: (event) => logLegendClick(event)
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.issuesChart}>
            <StatusDonut
              breakdown={issuesBreakdown?.status || {}}
              dashboardView={'scroll'}
              onEvents={{
                click: (event) => onChartClick(event, FILTERS.STATUS.id),
                legendSelectChanged: (event) => logLegendClick(event)
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent='flex-end' item>
        <ButtonEnhanced variant='outlined' size='small' onClick={() => logViewAll(navigate)}>
          <TypographyEnhanced id={TRANSLATION_PATH('buttons.viewAll')} />
        </ButtonEnhanced>
      </Grid>
    </WidgetContainer>
  );
};

export default IssuesSummary;
