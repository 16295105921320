import { isIDEqual, preventEnter } from '@anirudhm9/base-lib/lib/utils';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';

const CheckboxEnhanced = ({ label, value, options, onChange, disabled }) => {
  const [selected, setSelected] = useState(() => value || []);

  useEffect(() => {
    if (!value) {
      return;
    }
    setSelected((value || []).map((item) => String(item)));
  }, [value]);

  const handleChange = (value) => {
    setSelected((selected) => {
      let selectedCopy = _.cloneDeep(selected || []);
      if (selected.includes(value)) {
        selectedCopy = (selectedCopy || []).filter((option) => !isIDEqual(option, value));
      } else {
        selectedCopy.push(String(value));
      }
      onChange(selectedCopy);
      return selectedCopy;
    });
  };

  return (
    <FormControl component='fieldset' onKeyDown={preventEnter}>
      <FormLabel component='legend'>{label}</FormLabel>
      <FormGroup>
        {(options || []).map((option) => {
          const { id, name = '' } = option || {};

          return (
            <Fragment key={id}>
              <FormControlLabel
                value={String(id)}
                control={<Checkbox checked={selected.includes(String(id))} color='primary' />}
                label={name}
                color='primary'
                onChange={(e) => handleChange(String(e.target.value))}
                disabled={disabled}
              />
            </Fragment>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

CheckboxEnhanced.propTypes = {
  label: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default CheckboxEnhanced;
