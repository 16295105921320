import { DEVICE_OS_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { formatAccountText } from '@anirudhm9/base-lib/lib/utils';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_DEVICES } from '../../../../../api';
import { useIntegrationContext, useOrgContext } from '../../../../../contexts';

import { ButtonEnhanced, DotLoader, TypographyEnhanced } from '../../../../global';
import { BarChart, TableEnhanced, WidgetContainer } from '../../../../ui';
import NoDataState from '../noDataState';
import { TABLE_HEADERS } from './constants';
import { logChartClick, logViewAll } from './utils';

const PATH = 'components.application.dashboard.widgets.devicesInUse';

const TRANSLATION_PATH = (route) => {
  return `${PATH}.${route}`;
};

const DevicesInUse = () => {
  const navigate = useNavigate();
  const { orgId, loading: orgLoading } = useOrgContext();
  const { loading: integrationLoading } = useIntegrationContext();

  const { data: devicesData, loading: devicesLoading, error: devicesError } = useQuery(GET_DEVICES, { variables: { org: orgId }, skip: !orgId });

  const devices = useMemo(() => {
    if (devicesLoading || devicesError) {
      return [];
    }
    return devicesData?.devices || [];
  }, [devicesData?.devices, devicesError, devicesLoading]);

  const [rows, setRows] = useState([]);
  const [options, setOptions] = useState();

  useEffect(() => {
    if (!devices?.length) {
      return;
    }
    const deviceTypes = _.groupBy(devices || [], 'type');
    const osVersions = (devices || []).reduce((result, device) => {
      const { type, version } = device || {};
      const { name: label = type } = DEVICE_OS_TYPES[type] || {};
      const os = `${label?.replace('Device', '')}${version}`;

      if (result[os]) {
        result[os] += 1;
      } else {
        result[os] = 1;
      }
      return result;
    }, {});

    const rows = Object.keys(deviceTypes || {}).map((type) => {
      const { name: label = type } = DEVICE_OS_TYPES[type] || {};
      return {
        label,
        value: formatAccountText(deviceTypes[type].length)
      };
    });
    const options = {
      name: 'Device OS',
      data: Object.keys(osVersions || {}).map((os) => {
        return {
          name: os,
          value: osVersions[os] || 0
        };
      }),
      xAxis: {
        type: 'category',
        data: Object.keys(osVersions || {}) || [],
        axisLabel: {
          inside: false,
          rotate: 50
        },
        z: 10,
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        }
      }
    };

    setOptions(options);
    setRows(rows);
  }, [devices]);

  if ((!devices?.length && !integrationLoading && !orgLoading && !devicesLoading) || devicesError) {
    return <NoDataState path={PATH} navigateTo='/app/integrations' />;
  }

  return (
    <WidgetContainer title={TRANSLATION_PATH('title')}>
      <Grid item xs={12}>
        <TypographyEnhanced id={TRANSLATION_PATH('description')} />
      </Grid>

      {devicesLoading || orgLoading || integrationLoading ? (
        <Grid item xs={12} m={2}>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TableEnhanced columns={TABLE_HEADERS} rows={rows} />
          </Grid>
          <Grid item xs={12}>
            <BarChart
              options={options}
              onEvents={{
                click: (event) => logChartClick(event, navigate)
              }}
            />
          </Grid>
        </Grid>
      )}

      <Grid container justifyContent='flex-end'>
        <ButtonEnhanced variant='outlined' size='small' onClick={() => logViewAll(navigate)}>
          <TypographyEnhanced id={TRANSLATION_PATH('buttons.viewAll')} />
        </ButtonEnhanced>
      </Grid>
    </WidgetContainer>
  );
};

export default DevicesInUse;
