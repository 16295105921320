import _ from 'lodash';

const searchTechnique = (category, term, searchFields = ['name', 'mappingNumber']) => {
  const searchTerm = (term || '')?.toLowerCase?.() || '';
  const categoryCopy = _.cloneDeep(category || {});

  const process = (category) => {
    // const { children = [], questions = [] } = category || {};

    const categoryFields = searchFields.some((fieldName) => category?.[fieldName]?.toLowerCase?.()?.includes?.(searchTerm));

    if (category?.questions?.length) {
      if (!categoryFields) {
        category.questions = category?.questions.filter((question) => {
          return searchFields.some((fieldName) => {
            return question?.[fieldName]?.toLowerCase?.()?.includes?.(searchTerm);
          });
        });
        category.shouldDelete = !category?.questions?.length;
      }
    }

    if (category?.children?.length) {
      if (!categoryFields) {
        category.children = category.children.map((child) => process(child));
        category.children = category.children.filter((child) => !child.shouldDelete);
        category.shouldDelete = !category.children.length;
      }
    }
    return category;
  };
  return process(categoryCopy);
};

export { searchTechnique };
