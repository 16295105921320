import { preventEnter } from '@anirudhm9/base-lib/lib/utils';
import { RectangleRounded } from '@mui/icons-material';
import { FormControl, Grid, MenuItem, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { TooltipEnhanced, TypographyEnhanced } from '../../../global';
import AutoCompleteEnhanced from '../autoComplete';

/**
 * @description:
 * Material UI Select with customisation options
 * @typedef {import('@mui/material').SelectProps} SelectProps Props for `Select` from '@mui/material` library
 * @typedef {import('@mui/material').NativeSelectProps} NativeSelectProps Props for `Native` from '@mui/material` library
 *
 * @param {String} variant (optional) default: outlined
 * @param {String | Number} id for accessibility
 * @param {String}  label Label for the select dropdown
 * @param {String | Number} value unique value to identify items
 * @param {Function} onChange function triggered on change
 * @param {[{ id: Number, label: String, value: String | Number }]} options options to render the menu items
 * @param {Boolean} disabled to disable the textfield
 * @param {Boolean} multiple if multiple options can be selected
 * @param {Boolean} fullWidth width of select container
 * @param {String} placeholder text to display when nothing is selected
 * @param {String} itemType menu item type to display
 * @param {'chip' | 'number'} renderType selection type to display
 * @param {Boolean} hasError puts field into error state if true
 * @param {String} errorText show error text below field if hasError = true. defaultL empty space to allow consistent layout with other fields
 * @param {String} required for required fields
 * @param {{ selectAll: Boolean } & SelectProps & NativeSelectProps} props
 * @returns {React.FC<SelectProps>}
 */

const SelectStatusCriticality = ({
  variant = 'outlined',
  id,
  label,
  value,
  onChange,
  options,
  disabled,
  fullWidth = true,
  placeholder,
  size = 'large',
  hasError = false,
  errorText = ' ',
  required,
  ...props
}) => {
  const theme = useTheme();

  const Item = (props, option) => {
    const { name, color: optionColor } = option;
    const color = theme.palette?.[optionColor]?.main;
    return (
      <MenuItem {...props}>
        <TooltipEnhanced title={name}>
          <RectangleRounded sx={{ color }} />
        </TooltipEnhanced>
        <TypographyEnhanced id={name} ml={0.5} />
      </MenuItem>
    );
  };

  Item.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    'data-value': PropTypes.any
  };

  const Label = useCallback(() => {
    if (typeof label === 'string') {
      return <Typography id={`input-select-${id}`}>{`${label || ''}${required ? '*' : ''}`}</Typography>;
    }
    return label;
  }, [id, label, required]);

  return (
    <Grid item>
      <FormControl variant={variant} fullWidth={fullWidth} size={size} onKeyDown={preventEnter}>
        <Label />
        <AutoCompleteEnhanced
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          options={options}
          multiple={false}
          placeholder={placeholder}
          renderOption={Item}
          hasError={hasError}
          errorText={errorText}
          {...props}
        />
      </FormControl>
    </Grid>
  );
};

SelectStatusCriticality.propTypes = {
  variant: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  itemType: PropTypes.oneOf(['checkbox']),
  renderType: PropTypes.oneOf(['chip', 'number']),
  placeholder: PropTypes.string,
  selectAll: PropTypes.bool,
  size: PropTypes.string,
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  required: PropTypes.bool,
  statusSelect: PropTypes.bool,
  criticalitySelect: PropTypes.bool
};
export default SelectStatusCriticality;
