const COLUMN_WIDTH = 300;

const DEFAULT_SCENARIO = {
  prompt: '',
  name: '',
  description: '',
  mappings: {}
};

const THREAT_MODELLING_TABS = {
  SANKEY: {
    name: 'View',
    value: 'SANKEY',
    tabValue: 1
  },
  // TABLE: {
  //   name: 'Manage',
  //   value: 'TABLE',
  //   tabValue: 2
  // },
  // BUILD: {
  //   name: 'Build',
  //   value: 'BUILDER',
  //   tabValue: 3
  // },
  SETTINGS: {
    name: 'Settings',
    value: 'SETTINGS',
    tabValue: 4
  }
};

const THREAT_MODELLING_MODAL_TYPES = {
  QUESTION: 'QUESTION',
  SCENARIO: 'SCENARIO'
};

export { COLUMN_WIDTH, DEFAULT_SCENARIO, THREAT_MODELLING_MODAL_TYPES, THREAT_MODELLING_TABS };
