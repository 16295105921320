import _ from 'lodash';

const THREAT_MODELLING_COLORS = Object.freeze({
  GREY: {
    value: 'GREY',
    threshold: -1,
    hex: '#EBEBEB',
    name: 'grey'
  },
  GREEN: {
    value: 'GREEN',
    threshold: 4,
    maximumThresholdPercentage: 100,
    minimumThresholdPercentage: 66,
    hex: '#377E22',
    name: 'green'
  },
  AMBER: {
    value: 'AMBER',
    threshold: 2,
    maximumThresholdPercentage: 65,
    minimumThresholdPercentage: 33,
    hex: '#E9A126',
    name: 'orange'
  },
  RED: {
    value: 'RED',
    threshold: 0,
    maximumThresholdPercentage: 32,
    minimumThresholdPercentage: 0,
    hex: '#EA3423',
    name: 'red'
  },
  BLUE: {
    value: 'BLUE',
    hex: '#19276F',
    name: 'blue'
  }
});

/**
 * @typedef {{hex: string, name: string, threshold: number, value: 'GREY'|'GREEN'|'AMBER'|'RED'|'BLUE'}} color
 *
 * @param {typeof THREAT_MODELLING_COLORS} thresholds current value (0-5)
 * @param {number} value current value (0-5)
 * @param {boolean} notApplicable if the control is marked notApplicable
 * @returns {color}
 */
const getControlThreshold = (thresholds, value, notApplicable) => {
  const valuePercentage = value * 20;

  if (notApplicable || value === thresholds.GREY.threshold) {
    return thresholds.GREY;
  }

  if (valuePercentage >= thresholds.GREEN.minimumThresholdPercentage) {
    return thresholds.GREEN;
  }

  if (valuePercentage >= thresholds.AMBER.minimumThresholdPercentage) {
    return thresholds.AMBER;
  }

  if (valuePercentage >= thresholds.RED.minimumThresholdPercentage && value !== null) {
    return thresholds.RED;
  }
  return thresholds.BLUE;
};

const getNodes = (scenarios) => {
  const nodes = [];
  for (const scenarioIndex in scenarios) {
    const scenario = scenarios[Number(scenarioIndex)];
    const scenarioQuestions = Object.values(scenario || {}).reduce((result, questionPairs) => {
      // check to prevent id from spreading
      if (typeof questionPairs === 'string') {
        return result;
      }
      result = [...(result || []), ...Object.values(questionPairs || {})];
      return result;
    }, []);

    nodes.push(
      ...(scenarioQuestions || []).map((question) => {
        const { mappingNumber, color } = question || {};

        return {
          scenarioIndex,
          question,
          name: mappingNumber,
          itemStyle: {
            color
          }
        };
      })
    );
  }

  return _.uniqBy(nodes, 'name');
};

const getLinksFlat = (links) => {
  if (!links || !links.length) {
    return {};
  }

  const result = {};

  const sortedLinks = _.orderBy(links, ['index'], 'desc');

  for (const linkIndex in sortedLinks) {
    const link = links[linkIndex] || {};
    const { source, target, index } = link || {};

    if (!result[index]) {
      result[index] = [];
    }
    if (!result[index + 1]) {
      result[index + 1] = [];
    }

    result[index].push(source.mappingNumber);
    result[index + 1].push(target.mappingNumber);
  }
  return result;
};

const getLinks = (linksFlat, questionsFlat) => {
  const links = [];

  // sorts indexes in ascending ordering as there could be missing or jumbled indexes
  const tacticIndexes = _.sortBy(Object.keys(linksFlat || {}).map((index) => Number(index)));

  tacticIndexes.forEach((tacticIndex, index) => {
    // current link for tactic index
    const { tacticId: sourceTacticId, mappingNumbers: sourceMappingNumbers } = linksFlat?.[tacticIndex] || {};
    // next link for the next tactinc index (tacticIndexes[index + 1])
    const { tacticId: targetTacticId, mappingNumbers: targetMappingNumbers } = linksFlat?.[tacticIndexes[index + 1]] || {};

    // skip if no target mapping numbers
    if (targetMappingNumbers?.length) {
      for (const targetMappingNumber of targetMappingNumbers) {
        const { id: targetQuestionId } = questionsFlat?.[targetMappingNumber] || {};
        for (const sourceMappingNumber of sourceMappingNumbers) {
          const { id: sourceQuestionId } = questionsFlat?.[sourceMappingNumber] || {};

          if (targetQuestionId && sourceQuestionId) {
            links.push({
              source: { id: sourceQuestionId, mappingNumber: sourceMappingNumber, tacticId: sourceTacticId },
              target: { id: targetQuestionId, mappingNumber: targetMappingNumber, tacticId: targetTacticId }
            });
          }
        }
      }
    }
  });

  return links;
};

export { THREAT_MODELLING_COLORS, getControlThreshold, getLinks, getLinksFlat, getNodes };
