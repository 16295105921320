import { Container, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { TypographyEnhanced } from '../../../../global';
import { CheckboxEnhanced, SelectEnhanced, TextFieldEnhanced } from '../../../form';
import { getPreselectedValues } from './utils';

const Input = ({ option, selectedFilters, updateSelectedFilters }) => {
  //destructuring dropdown select options from option object
  const { id, label, type, options: selectOptions } = option || {};

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (!selectedFilters) {
      return;
    }
    //get preselected values (ie currently active filters)
    const preselectedValues = getPreselectedValues(selectOptions, selectedFilters);

    setSelectedOptions(preselectedValues);
  }, [selectOptions, selectedFilters]);

  let content;
  switch (type) {
    case 'radio':
      content = (
        <RadioGroup name={`radio-${id}`} onChange={(e) => updateSelectedFilters(id, e.target.value)}>
          {selectOptions.map((option) => {
            const { name, value: optionValue } = option || {};
            return (
              <FormControlLabel
                key={optionValue}
                value={optionValue}
                control={<Radio checked={selectedFilters?.[id]?.includes(optionValue) || false} color='primary' />}
                label={name}
              />
            );
          })}
        </RadioGroup>
      );
      break;
    case 'checkbox':
      content = <CheckboxEnhanced value={selectedFilters?.[id] || []} options={selectOptions} onChange={(value) => updateSelectedFilters(id, value)} />;
      break;
    case 'select':
      content = (
        <SelectEnhanced
          multiple
          label={''}
          value={selectedOptions || []}
          options={selectOptions}
          onChange={(value) => {
            setSelectedOptions(value);
            updateSelectedFilters(id, value);
          }}
        />
      );
      break;
    default:
      content = (
        <Grid item xs={12}>
          <TextFieldEnhanced size='small' value={selectedFilters?.[id] || ''} onChange={(value) => updateSelectedFilters(id, value)} />
        </Grid>
      );
  }

  return (
    <Grid item xs={12} key={id}>
      {label ? (
        <Fragment>
          <TypographyEnhanced id={label} />
          <Container sx={{ display: 'flex', flexDirection: 'column' }}>{content}</Container>
        </Fragment>
      ) : (
        content
      )}
    </Grid>
  );
};

Input.propTypes = {
  option: PropTypes.object,
  selectedFilters: PropTypes.object,
  updateSelectedFilters: PropTypes.func
};

export default Input;
