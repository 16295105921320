import { EARN as earn } from './earn';
import { FILTER as filter } from './filter';
import { POINTS_CHIP as pointsChip } from './pointsChip';
import { REDEEM as redeem } from './redeem';

export const REWARDS = {
  earn,
  redeem,
  filter,
  pointsChip
};
