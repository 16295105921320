import { CancelRounded, SearchRounded } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { TextFieldEnhanced } from '../form';

const useStyles = makeStyles(() => ({
  button: {
    cursor: 'pointer'
  }
}));

const Search = ({ id, limit = 3, onSearch, onReset, defaultValue = '', ...props }) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');

  const handleSearch = () => {
    if (!searchTerm?.length || searchTerm?.length >= limit) {
      setError('');
      onSearch(searchTerm);
      return;
    }

    setError(`Minimum ${limit} characters to search`);
  };

  const handleReset = () => {
    try {
      onReset();
      setSearchTerm('');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!defaultValue) {
      return;
    }
    setSearchTerm(defaultValue);
    onSearch(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <TextFieldEnhanced
      id={id}
      value={searchTerm || ''}
      onChange={(value) => setSearchTerm(value)}
      onKeyDown={onKeyDown}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start' className={classes.button} onClick={handleSearch}>
            <SearchRounded />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end' className={classes.button} onClick={handleReset}>
            <CancelRounded />
          </InputAdornment>
        )
      }}
      variant='outlined'
      size='small'
      hasError={!!error}
      errorText={error}
      {...props}
    />
  );
};

Search.propTypes = {
  id: PropTypes.string,
  limit: PropTypes.number,
  defaultValue: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
};

export default Search;
