const moment = require('moment');
const _ = require('lodash');
const blacklistDomains = require('../../data/blacklist-domains.json');
const { ROLES, IMAGES, DEFAULT_SCALE } = require('../../constants');
const { v4: uuidV4 } = require('uuid');

/**
 * @param {Array} requiredFields
 * @param {Object} data
 * @returns {fieldErrors: Array}
 */

const hasErrors = (requiredFields, data) => {
  const fieldErrors = [];
  requiredFields.forEach((field) => {
    if (!data[field]) {
      fieldErrors.push({ field, error: 'emptyField' });
    }
    if (Array.isArray(data[field]) && !data[field].length) {
      fieldErrors.push({ field, error: 'emptyField' });
    }
  });
  return fieldErrors;
};

/**
 * @param {Array} requiredFields array of strings. eg. ['name', 'email']
 * @param {Object} data object where keys = field names. eg { name: '', email: 'eg@email.com'}
 * @returns Object eg. { [name]: 'Required field' }
 */
const isEmpty = (requiredFields, data, deepSearch) => {
  const ERROR_TEXT = 'Required fields cannot be empty';

  // To match the type of the values
  const DATA_TYPE_CONSTRUCTORS = {
    ARRAY: [].constructor,
    OBJECT: {}.constructor,
    STRING: 'string'.constructor
  };

  const fieldErrors = {};
  requiredFields.forEach((field) => {
    if (!data[field]) {
      fieldErrors[field] = ERROR_TEXT;
    }
    // Check if the type is array
    if (Array.isArray(data[field]) && data[field].constructor === DATA_TYPE_CONSTRUCTORS.ARRAY) {
      const array = data[field];
      // assigns key of the variable if no data
      if (!array.length) {
        fieldErrors[field] = ERROR_TEXT;
      } else if (deepSearch) {
        // assigns key of the variables if the value is empty
        array.forEach((arr, index) => {
          if (!arr) {
            if (!fieldErrors[field]) {
              fieldErrors[field] = {};
            }
            fieldErrors[field][index] = ERROR_TEXT;
          }
        });
      }
    }
    // Check if the type is object
    if (typeof data[field] === 'object' && data[field].constructor === DATA_TYPE_CONSTRUCTORS.OBJECT) {
      const objectKeys = Object.keys(data[field] || {});
      // assigns key of the variable if no data
      if (!objectKeys.length) {
        fieldErrors[field] = ERROR_TEXT;
      } else if (deepSearch) {
        // assigns key of the variables if the value is empty
        objectKeys.forEach((key) => {
          if (!data[field][key]) {
            if (!fieldErrors[field]) {
              fieldErrors[field] = {};
            }

            if (!fieldErrors[field][key]) {
              fieldErrors[field][key] = ERROR_TEXT;
            }
          }
        });
      }
    }
  });
  return fieldErrors;
};

/**
 * @description:
 * Function to create a Password compliant with the password  policy:
 * At least one lowecase letter
 * At least one Uppercase Letter
 * At least one Digit
 * At least one special symbol
 * Should be more than 8 characters
 * @returns {String}
 */
const createPassword = (length = 45) => {
  const alpha = 'abcdefghijklmnopqrstuvwxyz';
  const caps = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numeric = '0123456789';
  const special = '!^&*-=+_?';

  const options = [alpha, caps, numeric, special];

  let password = '';
  const passwordArray = Array(length);

  for (let i = 0; i < length; i++) {
    const currentOption = options[Math.floor(Math.random() * options.length)];
    const randomChar = currentOption.charAt(Math.floor(Math.random() * currentOption.length));
    password += randomChar;
    passwordArray.push(randomChar);
  }

  const checkPassword = () => {
    let missingValueArray = [];
    let containsAll = true;

    options.forEach((e, _i, a) => {
      let hasValue = false;
      passwordArray.forEach((e1) => {
        if (e.indexOf(e1) > -1) {
          hasValue = true;
        }
      });

      if (!hasValue) {
        missingValueArray = a;
        containsAll = false;
      }
    });

    if (!containsAll) {
      passwordArray[Math.floor(Math.random() * passwordArray.length)] = missingValueArray[Math.floor(Math.random() * missingValueArray.length)];
      password = '';
      passwordArray.forEach((e) => {
        password += e;
      });
      checkPassword();
    }
  };
  checkPassword();

  return password;
};

/**
 *
 * @param {String} email
 * @returns {String} domain
 */
const getDomain = (email) => {
  if (!email) {
    return '';
  }
  return email.toLowerCase().split('@').pop();
};

/**
 * checks is the domain is valid i.e not present in the blacklist
 *
 * @param {String} domain
 * @returns {Boolean} is valid
 */
const validateDomain = (email) => {
  if (!email) {
    return false;
  }

  const domain = getDomain(email);
  //check that domain is not on blacklist
  if (blacklistDomains.includes(domain)) {
    return false;
  }

  return true;
};

/**
 * @description: Checks that the submitted email domain matches the Orgs domain
 * @param {String} email
 * @param {String} orgDomain
 * @returns {Boolean}
 */
const validateOrgDomain = (email, orgDomain) => {
  if (!email) {
    return false;
  }

  const domain = getDomain(email);
  //check that domain matches Org domain
  if (domain !== orgDomain) {
    return false;
  }

  return true;
};

/**
 * @description: Function to validate email format
 * @param {String} email
 * @returns {Boolean}
 */
const validateEmail = (email, checkDomain = true) => {
  const emailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const validFormat = emailRegex.test(email);

  if (!validFormat) {
    return false;
  }

  if (checkDomain) {
    return validateDomain(email);
  }

  return validFormat;
};

/**
 * @description:
 * Function to validate compliance with password policy:
 * At least one lowecase letter
 * At least one Uppercase Letter
 * At least one Digit
 * At least one special symbol
 * Should be more than 8 characters
 * @param {String} password
 * @returns {Boolean}
 */
const validatePassword = (password) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{12,}$/.test(password);
};

/**
 * @description:
 * Prevents default action for Enter key press
 * @param {Event} e
 * @returns {Boolean}
 */
const preventEnter = (e) => {
  if (e.key === 'Enter') e.preventDefault();
};

/**
 * @description:
 * Converts string to title case
 * @param {String} str
 * @returns {String}
 */
const toTitleCase = (str) => {
  if (!str) {
    return '';
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

/**
 * @description:
 * Converts camel case string to title case sentence
 * @param {String} str
 * @returns {String}
 */
const camelToTitleCase = (str) => {
  if (!str) {
    return '';
  }
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 * @description:
 * Converts string to Enumeration and vice-versa
 * @param {String} value
 * @param {String} isEnum (optional): To covert enum into titled case string
 * @returns {String}
 */
const convertEnumeration = (value, isEnum) => {
  if (!value) {
    return '';
  }
  let result = '';

  if (isEnum) {
    const string = value.trim().replace(/_/g, ' ').toLowerCase();
    result = toTitleCase(string);
  } else {
    result = value.trim().replace(/\s/g, '_').toUpperCase();
  }
  return result;
};

/**
 * Checks if a link is a valid url
 * @param {String} url
 * @returns Boolean
 */
// eslint-disable-next-line
const validateURL = (url = '') => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(url);

/**
 * Executes a `callback` function on every item in the `data` array.
 * Splits execution in batches to avoid a server bottleneck.
 * @template T Data item like: `thirdparty`, `task`, `asset`, etc.
 * @param {T[]} data Set of data to iterate over
 * @param {(dataItem: T) => Promise<void>} callback Function that gets executed for every item in `data`
 * @param {Number} batchSize (optional) default: 20
 * @param {'ALL'|'SETTLED'} type (optional) default: SETTLED
 * @returns {{fulfilled: Array, rejected: Array}} array of failed and successful callbacks
 */
const batchExecute = async (data, callback, batchSize = 20, type) => {
  const batches = _.chunk(data, batchSize);

  const entity = {
    fulfilled: [],
    rejected: []
  };

  switch (type) {
    case 'ALL':
      for (const batch of batches) {
        await Promise.all(
          batch.map((item) => {
            return callback(item);
          })
        ).then((values) => {
          entity.fulfilled.push(...(values || []));
        });
      }
      break;
    default:
      for (const batch of batches) {
        await Promise.allSettled(
          batch.map((item) => {
            return callback(item);
          })
        ).then((results) => {
          results.forEach((result) => {
            const { status, value, reason } = result || {};
            entity[status].push(value || reason);
          });
        });
      }
  }
  return entity;
};

/**
 * Return org id for org object
 * @param {JSON | String | Number } org
 * @returns { String | Number } ID
 */
const getOrgId = (org) => {
  const id = org && org.id;
  return id || org;
};

/**
 *
 * @param {JSON} ctx
 * @param {JSON} entity
 * @returns {Error | null}
 */
const handleError = (ctx, entity) => {
  if (!ctx || !entity) {
    throw new Error('Bad Request');
  }
  const { statusCode, message } = entity || {};
  if (statusCode && statusCode !== 200) {
    return ctx.throw(statusCode, message, entity);
  }
};

/**
 * Formats root category to return all the questions in JSON format
 *
 * @param {JSON} category {children: [...{ questions: []}]}
 * @param {String} groupByField
 * @returns {{[groupByField]: JSON}}
 */
const getQuestionsFlat = (category, groupByField = 'mappingNumber') => {
  if (!category) {
    return {};
  }

  const questionsMap = {};

  const processCategory = (category) => {
    const { children, questions } = category || {};
    if ((questions || []).length) {
      questions.forEach((question) => {
        if (question) {
          questionsMap[question[groupByField]] = question;
        }
      });
    } else if ((children || []).length) {
      children.forEach((child) => processCategory(child));
    }
  };

  processCategory(category);
  return questionsMap;
};

/**
 * Formats root category to return all the sub categories or the first sub categories
 *
 * @param {JSON} category {children: [...{ questions: []}]}
 * @param {String} groupByField
 * @param {Boolean} onlyFirstCategories (optional)
 * @returns {{[groupByField]: JSON}}
 */
const getCategoriesFlat = function (category, groupByField = 'mappingNumber', onlyFirstCategories) {
  if (!category) {
    return {};
  }

  const categoryMap = {};

  const processCategory = (category) => {
    const { children } = category || {};
    if ((children || []).length) {
      children.forEach((child) => {
        const catCopy = _.cloneDeep(child);
        if (onlyFirstCategories) {
          delete catCopy.children;
          delete catCopy.questions;
        }
        if (child) {
          categoryMap[catCopy[groupByField]] = catCopy;
        }
        return processCategory(catCopy);
      });
    }
  };

  processCategory(category);
  return categoryMap;
};

/**
 * Formats number of accounts to text
 *
 * @param {Number} value
 * @returns
 */
const formatAccountText = (value) => {
  return `${value || 0} accts.`;
};

/**
 * Formats user full name
 *
 * @param {{firstName: String, lastName: String}} user
 * @returns {`${firstName} ${lastName}`}
 */
const formatUserName = (user) => {
  if (!user) {
    return '';
  }
  const { firstName = '', lastName = '' } = user || {};
  return `${firstName} ${lastName}`;
};

/**
 * Gets user initials
 *
 * @param {{firstName: String, lastName: String}} user
 * @returns {`${firstName} ${lastName}`}
 */
const getInitials = (user) => {
  if (!user) {
    return '';
  }

  const { firstName = '', lastName = '' } = user || {};
  return [firstName.trim(), lastName.trim()].map((n) => (n[0] || '').toUpperCase());
};

/**
 * Gets acronym of supplied string. Like above, but not limited to a user name
 *
 * @param {String} value
 * @returns {String}
 */
const getAcronym = (value) => {
  if (!value) {
    return '';
  }

  return value
    .trim()
    .split(' ')
    .map((n) => (n[0] || '').toUpperCase())
    .join('');
};

/**
 * To check if a link is a valid url
 *
 * @param {String} link
 * @returns Boolean
 */
const isValidUrl = (link) => {
  try {
    new URL(link);
  } catch (_) {
    return false;
  }

  return true;
};

/**
 * Strips the provided string off all HTML tags.
 * @param {String} string
 * @returns {String}
 */
const stripHtmlTags = (string) => {
  if (!string) {
    return '';
  }
  return string.replace(/<[^>]*>/gi, '');
};

/**
 * Compares two html strings and returns if they are same (true) or not (false)
 *
 * @param {String} string1
 * @param {String} string2
 * @returns {Boolean}
 */
const compareHtmlStrings = (string1 = '', string2 = '') => {
  return stripHtmlTags(string1 || '') === stripHtmlTags(string2 || '');
};

/**
 * Returns the difference between 2 arrays of string irrespective of the order
 *
 * @param {Array} array1
 * @param {Array} array2
 * @returns {Array}
 */
const symmetricDifference = (array1, array2) => {
  if (!array1 || !array2 || !Array.isArray(array1) || !Array.isArray(array2)) {
    return [];
  }
  return array1.filter((item1) => !array2.includes(item1)).concat(array2.filter((item2) => !array1.includes(item2)));
};

/**
 * To get a union of all the non duplicate values in multiple arrays
 *
 * @param {[Array]} arrays array of arrays
 * @returns {Array}
 */
const getUnion = (arrays = []) => {
  if (!arrays || !arrays.length) {
    return arrays;
  }
  return arrays.reduce((result, subArray) => (result = Array.from(new Set([...result, ...subArray]))), []);
};

/**
 * Formats date to readable date
 *
 * @param {Date} date
 * @returns {String}
 */
const formatDate = (date) => {
  if (!moment(date).isValid()) {
    return 'N/A';
  }
  return moment(date).format('DD MMM YYYY');
};

/**
 * Formats date to date-time
 *
 * @param {Date} date
 * @returns {String}
 */
const formatDateTime = (date) => {
  if (!moment(date).isValid()) {
    return 'N/A';
  }
  return moment(date).format('DD MMM YYYY hh:mm A');
};

const compareSemanticVersions = (a, b) => {
  // 1. Split the strings into their parts.
  const a1 = a.split('.');
  const b1 = b.split('.');
  // 2. Contingency in case there's a 4th or 5th version
  const len = Math.min(a1.length, b1.length);
  // 3. Look through each version number and compare.
  for (let i = 0; i < len; i++) {
    const a2 = +a1[i] || 0;
    const b2 = +b1[i] || 0;

    if (a2 !== b2) {
      return a2 > b2 ? 1 : -1;
    }
  }

  // 4. We hit this if the all checked versions so far are equal
  //
  return b1.length - a1.length;
};

/**
 * Sorts array by version/index number
 *
 * @param {Array} array to sort
 * @param {String} key (optional)
 * @returns
 */
const sortVersionArray = (array, key, reverse) => {
  if (!array || !array.length) {
    return [];
  }

  const arrayCopy = _.cloneDeep(array);
  const result = arrayCopy.sort((a, b) => compareSemanticVersions(a[key] || a, b[key] || b));

  if (reverse) {
    return result.reverse();
  }

  return result;
};

/**
 * Removes trailing spaces and double spaces
 *
 * @param {String} string
 * @returns {String}
 */
const removeExtraSpaces = (string) => {
  if (!string || typeof string !== 'string') {
    return '';
  }
  return string.trim().replace(/  +/g, ' ');
};

/**
 * Removes trailing spaces and double spaces for html text
 *
 * @param {String} string
 * @returns {String}
 */
const removeExtraSpacesFromHtml = (string) => {
  if (!string || typeof string !== 'string') {
    return '';
  }
  const htmlText = stripHtmlTags(string);
  return removeExtraSpaces(htmlText);
};

/**
 * delays execution by the provided seconds
 *
 * @param {Number} seconds
 */
const delay = (seconds) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

/**
 * Compares two IDs or values
 *
 * @param {String | Number} value1
 * @param {String | Number} value2
 * @param {Boolean} ignoreCase to ignore case
 * @returns {Boolean}
 */
const isIDEqual = (value1, value2, ignoreCase) => {
  if (ignoreCase) {
    return String(value1 || '').toLowerCase() === String(value2 || '').toLowerCase();
  }
  return String(value1) === String(value2);
};

/**
 * Returns model id for model object
 *
 * @param {JSON | String | Number } model
 * @returns { String | Number } ID
 */
const getId = (model) => {
  const id = model && model.id;
  return id || model;
};

/**
 * Sanitises non ASCII characters from string
 *
 * @param {String} string
 * @returns {String} sanitises string
 */
const removeNonASCII = (string = '') => {
  if (!string || typeof string !== 'string') {
    return '';
  }

  return string.replace(/[^\x20-\x7E]/g, '');
};

/**
 * @description Removes any instance of 'Error: ' from an error message string
 * @param {{message: {String}}} error
 * @returns {String}
 */
const formatErrorMessage = (error = {}) => {
  const { message = '' } = error || {};
  return (message || '').replace(/Error: /g, '') || 'There was an error saving your data';
};

/**
 * Checks if user has an admin role
 *
 * @param {{orgRoles: Array}} user
 * @returns {Boolean}
 */
const hasAdminRole = (user = {}) => {
  const { orgRoles = [] } = user || {};
  return !!(orgRoles || []).find((role) => role.value === ROLES.ADMIN.value);
};

/**
 * Checks if user has an default role
 *
 * @param {{orgRoles: Array}} user
 * @returns {Boolean}
 */
const isDefaultUser = (user = {}) => {
  const { orgRoles = [] } = user || {};

  return orgRoles.every((role) => role.value === ROLES.DEFAULT.value);
};

/**
 * Provides a random loading sentence
 * @returns {String}
 */
const randomLoadingSentence = () => {
  let string = 'loading...';
  try {
    const sentences = require('../../data/loading-sentences.json');
    string = sentences[Math.floor(Math.random() * sentences.length)];
  } catch (error) {
    console.error(error);
  }
  return string;
};

/**
 * Checks if a given date has expired
 * @param {Date} date
 * @param {Number} limit
 * @param {'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years'} type
 * @returns {Boolean}
 */
const hasDateExpired = (date, limit, type = 'days') => {
  if (!date || !moment(date).isValid()) {
    return true;
  }

  const expired = moment().diff(date, type) > limit;
  return expired;
};

/**
 *
 * @param {Array} collection
 * @param {String} groupByField
 * @returns {Object}
 */
const getCollectionFlat = (collection, groupByField = 'id') => {
  return (collection || []).reduce((result, item) => {
    result = {
      ...(result || {}),
      // stores each collection item as key value pair where key is the groupByField
      [item[groupByField]]: item
    };
    return result;
  }, {});
};

const skip = (num) => new Array(num);

const createUUID = () => {
  return uuidV4();
};

const getIntegrationAvatarURL = (type, darkMode) => {
  return IMAGES.ICONS[darkMode ? `${type}_DARK` : `${type}_LIGHT`] || IMAGES.ICONS[type];
};

/*
 * Converts value to a different custom scale and vice-versa
 *
 * @param {Number} value
 * @param {Boolean} isCustomValue
 * @param {{min:Number, max:Number}} defaultScale
 * @param {{min:Number, max:Number}} customScale
 * @returns {Number}
 */
const transformValueToDifferentScale = (value, isCustomValue, defaultScale, customScale) => {
  if (!customScale) {
    return value;
  }
  const { min: defaultMin = 0, max: defaultMax = 5 } = defaultScale || {};
  const { min: customMin = 0, max: customMax = 5 } = customScale || {};

  let fromMax, fromMin, toMax, toMin;
  //To convert the custom scale to default scale
  if (isCustomValue) {
    // custom values
    fromMin = customMin;
    fromMax = customMax;

    // default values
    toMin = defaultMin;
    toMax = defaultMax;
  }
  //To convert the default scale to custom scale
  else {
    // default values
    fromMin = defaultMin;
    fromMax = defaultMax;

    // custom values
    toMin = customMin;
    toMax = customMax;
  }
  /**
   * X = (value - fromMin)) / (fromMax - fromMin) defines the current scale; for X = 0; value = fromMin ; for X = 1; value = toMax;
   * ((toMax - toMin) * X (the multiplier)) + toMin  defines the scale for the new max and min
   */
  const result = ((toMax - toMin) * (value - fromMin)) / (fromMax - fromMin) + toMin;
  return parseFloat(result.toFixed(2));
};

/**
 * Calculates aggregate values for the category by going recursively to find question datapoints
 * @param category - category item
 * @param customScale - optional - if provided, custom scoring will be calculated
 * @param isCustomValue - optional - to calculate scores considering custom frameworks
 * @returns {{total: number, answered: number, answeredAndApplicable: number, points: number, pointsAvg: number, targetPointsAvg: number}}
 */
const calculatePercentageCompleted = ({ category, customScale, isCustomValue }) => {
  const { children = [], questions } = category || {};
  const rootArray = children.length ? children : questions;

  const zeroTotals = {
    total: 0,
    answered: 0,
    points: 0,
    answeredAndApplicable: 0,
    applicable: 0,
    originalPoints: 0
  };

  if (!rootArray) {
    return zeroTotals;
  }

  const reduceTotal = (array, subtotals) => {
    return array.reduce((current, next) => {
      const { children = [], questions = [], datapoint = {} } = next || {};

      if (children.length) {
        return reduceTotal(children || [], current);
      } else if ((questions || []).length) {
        return reduceTotal(questions, current);
      } else {
        const { value, notApplicable } = datapoint || {};

        const isAnswered = notApplicable || (value !== null && value !== undefined);
        const isAnsweredAndApplicable = value !== -1 && value !== null && value !== undefined && !notApplicable;
        const isApplicable = !notApplicable;

        let transformedScore = 0;
        if (isAnswered && isApplicable) {
          transformedScore = transformValueToDifferentScale(value || 0, isCustomValue, DEFAULT_SCALE, customScale);
        } else {
          // if the scale is in negative
          transformedScore = transformValueToDifferentScale(0, isCustomValue, DEFAULT_SCALE, customScale);
        }

        return {
          total: current.total + 1,
          answered: current.answered + (isAnswered ? 1 : 0),
          answeredAndApplicable: current.answeredAndApplicable + (isAnsweredAndApplicable ? 1 : 0),
          applicable: current.applicable + (isApplicable ? 1 : 0),
          points: current.points + transformedScore,
          originalPoints: current.originalPoints + (value || 0)
        };
      }
    }, subtotals);
  };

  const totals = reduceTotal(rootArray, zeroTotals);

  totals.answeredPercentage = totals.total ? Math.floor((totals.answered / totals.total) * 100) : 0;

  if (totals.total) {
    const value = totals.points / totals.applicable || 0;
    const originalValue = totals.originalPoints / totals.applicable || 0;

    totals.value = value;
    totals.originalValue = originalValue;
    const average = value / DEFAULT_SCALE.max;
    totals.average = average;
    totals.averagePercentage = average * 100;
  }

  return totals;
};

const roundToTwo = (num) => {
  return +(Math.round(num + 'e+2') + 'e-2');
};

module.exports = {
  hasErrors,
  isEmpty,
  createPassword,
  getDomain,
  validateDomain,
  validateOrgDomain,
  validateEmail,
  validatePassword,
  preventEnter,
  toTitleCase,
  camelToTitleCase,
  convertEnumeration,
  validateURL,
  batchExecute,
  getOrgId,
  handleError,
  getQuestionsFlat,
  getCategoriesFlat,
  formatAccountText,
  formatUserName,
  getInitials,
  getAcronym,
  isValidUrl,
  stripHtmlTags,
  compareHtmlStrings,
  symmetricDifference,
  getUnion,
  formatDate,
  formatDateTime,
  compareSemanticVersions,
  sortVersionArray,
  removeExtraSpaces,
  removeExtraSpacesFromHtml,
  delay,
  isIDEqual,
  getId,
  removeNonASCII,
  formatErrorMessage,
  hasAdminRole,
  isDefaultUser,
  randomLoadingSentence,
  hasDateExpired,
  getCollectionFlat,
  skip,
  createUUID,
  getIntegrationAvatarURL,
  transformValueToDifferentScale,
  calculatePercentageCompleted,
  roundToTwo
};
