import { gql } from '@apollo/client';

export const CREATE_LOG = gql`
  mutation createLog($data: LogInput!) {
    createLog(input: { data: $data }) {
      log {
        id
      }
    }
  }
`;

export const UPDATE_LOG = gql`
  mutation updateLog($id: ID!, $data: editLogInput!) {
    updateLog(input: { where: { id: $id }, data: $data }) {
      log {
        id
      }
    }
  }
`;

export const GET_LOGS = gql`
  query logs($where: JSON!, $start: Int, $limit: Int) {
    logs(where: $where, start: $start, limit: $limit, sort: "created_at:DESC") {
      id
      comment
      attachments
      oldData
      newData
      subType
      edited
      user {
        id
        firstName
        lastName
        imageUrl
      }
      org {
        id
      }
      created_at
    }
  }
`;

export const COUNT_LOGS = gql`
  query countLogs($where: JSON!) {
    countLogs(where: $where)
  }
`;
