const { CRITICALITIES } = require('../common');

const INTEGRATION_SUB_TYPES = {
  WORKSPACE: {
    name: 'Workspace',
    value: 'WORKSPACE'
  },
  CLOUD: {
    name: 'Cloud',
    value: 'CLOUD'
  },
  SBOM: {
    name: 'Software Bill of Materials',
    value: 'SBOM'
  },
  WEBHOOK: {
    name: 'Webhook',
    value: 'WEBHOOK'
  }
};

const INTEGRATION_TYPES = {
  GSUITE: {
    name: 'GSuite',
    value: 'GSUITE',
    subType: INTEGRATION_SUB_TYPES.WORKSPACE.value
  },
  AWS_SECURITY_HUB: {
    name: 'AWS Security Hub',
    value: 'AWS_SECURITY_HUB',
    subType: INTEGRATION_SUB_TYPES.CLOUD.value,
    CRITICALITIES: ['Low', 'Medium', 'High', 'Critical'],
    CRITICALITY_MAPPING: {
      [CRITICALITIES.LOW.value]: {
        name: 'Low',
        value: 'LOW',
        color: 'green'
      },
      [CRITICALITIES.LOW_MEDIUM.value]: {
        name: 'Medium',
        value: 'MEDIUM',
        color: 'yellow'
      },
      [CRITICALITIES.MEDIUM_HIGH.value]: {
        name: 'High',
        value: 'HIGH',
        color: 'orange'
      },
      [CRITICALITIES.HIGH.value]: {
        name: 'Critical',
        value: 'CRITICAL',
        color: 'red'
      }
    }
  },
  GITHUB: {
    name: 'Github',
    value: 'GITHUB',
    subType: INTEGRATION_SUB_TYPES.SBOM.value,
    CRITICALITIES: ['low', 'moderate', 'high', 'critical'],
    CRITICALITY_MAPPING: {
      [CRITICALITIES.LOW.value]: {
        name: 'Low',
        value: 'LOW',
        color: 'green'
      },
      [CRITICALITIES.LOW_MEDIUM.value]: {
        name: 'Moderate',
        value: 'MODERATE',
        color: 'yellow'
      },
      [CRITICALITIES.MEDIUM_HIGH.value]: {
        name: 'High',
        value: 'HIGH',
        color: 'orange'
      },
      [CRITICALITIES.HIGH.value]: {
        name: 'Critical',
        value: 'CRITICAL',
        color: 'red'
      }
    }
  },
  SLACK: {
    name: 'Slack',
    value: 'SLACK',
    subType: INTEGRATION_SUB_TYPES.WEBHOOK.value
  }
};

module.exports = {
  INTEGRATION_TYPES,
  INTEGRATION_SUB_TYPES
};
