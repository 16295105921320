const DEFAULT_OPTIONS = {
  title: {
    show: false,
    text: '',
    top: 0,
    left: 'center'
  },
  tooltip: {
    trigger: 'item',
    position: function (pos, params, dom, rect, size) {
      // tooltip will be fixed on the right if mouse hovering on the left,
      // and on the left if hovering on the right.
      var obj = { top: 60 };
      obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
      return obj;
    }
  },
  legend: {
    // top: '5%',
    show: false,
    left: 'center'
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['50%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        // borderRadius: 10,
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          // fontSize: '40',
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: []
    }
  ]
};

export { DEFAULT_OPTIONS };
