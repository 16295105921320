import { getScoreTotals } from '@anirudhm9/base-lib/lib/utils';
import { useTheme } from '@mui/styles';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useIsDesktop } from '../../../../../../hooks';
import { StackedColumnChart } from '../../../../../ui';
import { CLOUD_ANSWER_OPTIONS } from '../../../../reports/cloudInfrastructure/constants';
import { DEFAULT_CHART_OPTIONS, DONUT_CONFIG } from '../constants';

const Chart = ({ category, onEvents }) => {
  const { children = [] } = category || {};

  const isDesktop = useIsDesktop();
  const theme = useTheme();

  const Component = useCallback(() => {
    const { palette } = theme || {};
    const { background, success = {}, error = {}, text } = palette || {};

    const options = _.cloneDeep(DEFAULT_CHART_OPTIONS);
    const tooltip = options.tooltip;
    const legend = options.legend;

    tooltip.backgroundColor = background?.default;
    tooltip.textStyle = { color: text?.primary };
    legend.textStyle = { color: palette.text.primary };

    const optionsForColumn = {
      xAxis: {
        data: [],
        axisLabel: {
          color: text?.primary,
          formatter: (name) => {
            const truncatedText = name?.length > 10 ? `${name?.slice(0, 10)}...` : name;
            return truncatedText;
          }
        }
      },
      series: [
        {
          name: CLOUD_ANSWER_OPTIONS.YES.label,
          type: 'bar',
          stack: 'one',
          itemStyle: {
            color: success.main
          },
          data: []
        },
        {
          name: CLOUD_ANSWER_OPTIONS.NO.label,
          type: 'bar',
          stack: 'one',
          itemStyle: {
            color: error.main
          },
          data: []
        }
      ]
    };

    const seriesDataForDonut = [];

    for (const child of children) {
      const { name = '' } = child || {};
      const truncatedText = name?.length > 10 ? `${name?.slice(0, 10)}...` : name;
      const categoryWithScores = getScoreTotals(child || {});
      const { total, points } = categoryWithScores || {};

      optionsForColumn.xAxis.data.push(name);
      optionsForColumn.series[0].data.push(points || 0);
      optionsForColumn.series[1].data.push(total - points || 0);

      seriesDataForDonut.push({
        ...DONUT_CONFIG,
        name,
        data: [
          {
            name: CLOUD_ANSWER_OPTIONS.YES.label,
            value: points || 0,
            itemStyle: {
              color: success.main
            }
          },
          {
            name: CLOUD_ANSWER_OPTIONS.NO.label,
            value: total - points || 0,
            itemStyle: {
              color: error.main
            }
          }
        ],
        label: {
          ...DONUT_CONFIG.label,
          color: text?.primary,
          formatter: truncatedText || ''
        }
      });
    }

    if (isDesktop) {
      return <StackedColumnChart options={optionsForColumn} onEvents={onEvents} />;
    }

    return <ReactEcharts option={{ ...options, series: seriesDataForDonut }} onEvents={onEvents} />;
  }, [children, isDesktop, onEvents, theme]);

  return <Component />;
};

Chart.propTypes = {
  category: PropTypes.object,
  onEvents: PropTypes.object
};

export default Chart;
