// EVENTS.VIEWS.APP.DASHBOARD

export const DASHBOARD = {
  PAGE_VISITED: 'dashboard-page_visited',
  //OVERALL TASK PROGRESS SCORECARD
  OVERALL_TASK_PROGRESS_VIEW: 'dashboard_overall_task_progress-view',
  OVERALL_TASKS: {
    INITITAL_ONBOARDING: 'dashboard_overall_task_progress-company_profile',
    GSUITE_CONNECT: 'dashboard_overall_task_progress-connect_gsuite',
    AWS_CONNECT: 'dashboard_overall_task_progress-connect_aws',
    SLACK_CONNECT: 'dashboard_overall_task_progress-connect_slack',
    ASSESSMENT_COMPLETION: 'dashboard_overall_task_progress-complete_assessment',
    DEVICE_INVENTORY: 'dashboard_overall_task_progress-device_inventory',
    APPLICATION_INVENTORY: 'dashboard_overall_task_progress-application_inventory',
    USER_INVENTORY: 'dashboard_overall_task_progress-user_listing',
    CLOSED_ISSUES: 'dashboard_overall_task_progress-address_open_issues'
  },
  //SECURITY SCORECARD
  SECURITY_SCORE_VIEW: 'dashboard_security_score-view',
  //CYBER_ASSESSMENT SCORECARD
  CYBER_ASSESSMENT_CLICKED: 'dashboard_cyber_assessment-view',
  //NEWS WIDGET
  NEWS_VIEW_ALL: 'dashboard_news-view_all',
  //APPLICATIONS WIDGET
  APPLICATIONS_VIEW_ALL: 'dashboard_applications-view_all',
  APPLICATIONS_CHART_CLICK: 'dashboard_applications-chart_click',
  //ISSUES WIDGET
  ISSUES_SUMMARY_VIEW_ALL: 'dashboard_issues_summary-view_all',
  ISSUES_SUMMARY_CHART_CLICK: 'dashboard_issues_summary-chart_click',
  ISSUES_SUMMARY_CHART_LEGEND: 'dashboard_issues_summary-click_legend',
  //WEB_SECURITY_HEADERS WIDGET
  WEB_SECURITY_HEADERS_VIEW_ALL: 'web_security_headers-view_all',
  WEB_SECURITY_HEADERS_WARNING_CLICK: 'web_security_headers-click_warning',
  WEB_SECURITY_HEADERS_OPEN_REMEDIATION: 'web_security_headers-open_remediation',
  //USER_SUMMARY WIDGET
  USER_SUMMARY_VIEW_ALL: 'dashboard_user_summary-view_all',
  USER_SUMMARY_AVATAR_CLICK: 'dashboard_user_summary-avatar_click',
  //CLOUD INFRASTRUCTURE WIDGET
  CLOUD_INFRASTRUCTURE_VIEW_ALL: 'dashboard_cloud_infrastructure_summary-view_all',
  CLOUD_INFRASTRUCTURE_CHART_CLICK: 'dashboard_cloud_infrastructure_summary-chart_click',
  CLOUD_INFRASTRUCTURE_CHART_LEGEND: 'dashboard_cloud_infrastructure_summary-legend_click',
  //MFA STATUS WIDGET
  MFA_STATUS_VIEW_ALL: 'dashboard_MFA-view_accounts',
  MFA_STATUS_CHART_CLICK: 'dashboard_MFA-chart_click',
  //DEVICES WIDGET
  DEVICES_VIEW_ALL: 'dashboard_mobile_devices_in_use-view_all',
  DEVICES_CHART_CLICK: 'dashboard_mobile_devices_in_use-chart_click',
  //PASSWORD WIDGET
  PASSWORD_VIEW_ALL: 'dashboard_password_strength-view_all',
  //MANDATORY COMPLIANCE WIDGET
  MANDATORY_COMPLIANCE_REVIEW: 'dashboard_mandatory_compliance_requirements-review',
  MANDATORY_COMPLIANCE_INFO: 'dashboard_mandatory_compliance_requirements-framework_information_link',
  MANDATORY_COMPLIANCE_ACTIONS: {
    MANDATORY_COMPLIANCE_ANSWER: 'dashboard_mandatory_compliance_requirements-answer',
    MANDATORY_COMPLIANCE_UPDATE_ANSWER: 'dashboard_mandatory_compliance_requirements-update_answer',
    MANDATORY_COMPLIANCE_COMMENT_ADD: 'dashboard_mandatory_compliance_requirements-comment_add',
    MANDATORY_COMPLIANCE_COMMENT_EDIT: 'dashboard_mandatory_compliance_requirements-comment_edit',
    MANDATORY_COMPLIANCE_COMMENT_ADD_ATTACHMENT: 'dashboard_mandatory_compliance_requirements-comment_add_attachment',
    MANDATORY_COMPLIANCE_COMMENT_TAG_USER: 'dashboard_mandatory_compliance_requirements-comment_tag_user'
  },
  MANDATORY_COMPLIANCE_BACK: 'dashboard_mandatory_compliance_requirements-back',
  MANDATORY_COMPLIANCE_NEXT: 'dashboard_mandatory_compliance_requirements-next',
  MANDATORY_COMPLIANCE_DONE: 'dashboard_mandatory_compliance_requirements-done'
};
