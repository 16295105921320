const DEFAULT_OPTIONS = {
  tooltip: {
    trigger: 'item',
    triggerOn: 'mousemove'
  },
  series: [
    {
      label: {},
      type: 'tree',
      data: [],
      lineStyle: {},
      top: '18%',
      bottom: '14%',
      layout: 'radial',
      symbol: 'roundRect',
      symbolSize: 7,
      initialTreeDepth: 3,
      animationDurationUpdate: 750,
      emphasis: {
        focus: 'relative'
      },
      roam: true
    }
  ]
};

export { DEFAULT_OPTIONS };
