import { RewardFaq, RewardLicense, RewardOverview, RewardSupport, RewardTerms } from '../../../../../../components/application/rewards/redeem';
import { EVENTS } from '../../../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.app.rewards.redeem.viewReward.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.APP.REWARDS.REDEEM.VIEW_REWARD[event];
};

export const TABS = (data) => ({
  OVERVIEW: <RewardOverview data={data} />,
  LICENSE: <RewardLicense data={data} />,
  FAQ: <RewardFaq data={data} />,
  SUPPORT: <RewardSupport data={data} />,
  TERMS: <RewardTerms data={data} />
});
