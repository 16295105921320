import { DEVICE_OS_TYPES, DEVICE_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { formatErrorMessage, hasAdminRole, isEmpty } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { HelpOutlineRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { CREATE_DEVICE } from '../../../../../api';
import { defaultMessages } from '../../../../../constants';
import { useDevicesContext, useOrgContext, useUserContext } from '../../../../../contexts';
import { usePageViewContext } from '../../../../../contexts/logPageViewContext';
import { useUsersContext } from '../../../../../contexts/usersContext';
import { logEvent } from '../../../../../library';
import { DEVICES as MANAGE_DEVICES_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/devices';
import { ButtonEnhanced, TooltipEnhanced, TypographyEnhanced, toast } from '../../../../global';
import { SelectEnhanced, TextFieldEnhanced } from '../../../../ui/form';
import DeviceIcon from '../deviceIcon';
import EditUserSelect from '../viewEditUser/editUserSelect';
import { DEFAULT_DEVICE_DATA, REQUIRED_FIELDS, TRANSLATION_PATH } from './constants';
import { detectUserDevice } from './utils';

const ManualAddDevice = () => {
  const { orgId } = useOrgContext();
  const { refetch: refetchDevices } = useDevicesContext();
  const { users: usersOptions, loading: usersLoading } = useUsersContext();
  const { user: currentUser } = useUserContext();
  const { pageViewLogged, setPageViewLogged } = usePageViewContext();

  const hasAdmin = hasAdminRole(currentUser);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [localData, setLocalData] = useState(DEFAULT_DEVICE_DATA);
  const [errorFields, setErrorFields] = useState({});

  const [createDevice] = useMutation(CREATE_DEVICE);

  useEffect(() => {
    if (pageViewLogged) {
      return;
    }
    logEvent(MANAGE_DEVICES_EVENTS.ADD_DEVICE);
    setPageViewLogged(true);
  }, [pageViewLogged, setPageViewLogged]);

  const src = useMemo(() => {
    if (!localData?.type) {
      return DEVICE_OS_TYPES.OTHER.src;
    }
    return DEVICE_OS_TYPES[localData?.type]?.src;
  }, [localData?.type]);

  const updateLocalData = (key, value) => {
    setLocalData((local) => {
      return { ...local, [key]: value };
    });
  };

  const hasChanged = useMemo(() => {
    if (!localData) {
      return;
    }
    return !_.isEqual(localData, DEFAULT_DEVICE_DATA);
  }, [localData]);

  const handleSaveDevice = async () => {
    const emptyFields = isEmpty(REQUIRED_FIELDS, localData);

    if (Object.keys(emptyFields || {}).length) {
      setErrorFields(emptyFields);
      toast.error(defaultMessages.requiredFields);
      return;
    }

    const localDataCopy = _.cloneDeep(localData);

    //CREATE NEW DEVICE
    //If user has created a new device, there will be no deviceId
    if (!localDataCopy.deviceId) {
      //Set other properties before creation
      localDataCopy.firstSync = new Date().toISOString();
      localDataCopy.lastSync = new Date().toISOString();
      localDataCopy.org = orgId;
      //Create device id
      localDataCopy.deviceId = uuidv4();

      try {
        setLoading(true);
        await createDevice({
          variables: {
            data: localDataCopy
          }
        });
        refetchDevices();
        toast.success('Device added successfully.');
        navigate(-1);
      } catch (error) {
        console.error(error);
        toast.error(formatErrorMessage(error));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDetectDevice = () => {
    //Clear any previous user input
    setLocalData(DEFAULT_DEVICE_DATA);
    //Set local data fields
    detectUserDevice(updateLocalData, currentUser);
  };

  const handleCancel = () => {
    setLocalData(DEFAULT_DEVICE_DATA);
    navigate(-1);
  };

  const DeviceLogo = useCallback(() => {
    return <DeviceIcon src={src} sx={{ height: 100, width: 100 }} />;
  }, [src]);

  return (
    <Grid container item spacing={2}>
      <Grid container item spacing={2}>
        <Grid item>
          <DeviceLogo />
        </Grid>

        <Grid container item xs spacing={2}>
          <Grid container item justifyContent='flex-end'>
            <ButtonEnhanced onClick={handleDetectDevice} loading={loading || usersLoading}>
              <TypographyEnhanced id={'Detect My Device'} />
            </ButtonEnhanced>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectEnhanced
          id='type'
          label={
            <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
              <TypographyEnhanced id='Operating System ' />
              <TooltipEnhanced title='Check your device settings if unsure.'>
                <HelpOutlineRounded fontSize='small' color='primary' />
              </TooltipEnhanced>
            </Grid>
          }
          value={localData?.type}
          onChange={(value) => updateLocalData('type', value)}
          options={Object.values(DEVICE_OS_TYPES)}
          errorText={errorFields['type']}
          hasError={!!errorFields['type']}
          required
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldEnhanced
          id='version'
          label={
            <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
              <TypographyEnhanced id='Operating System Version ' />
              <TooltipEnhanced title='Check your device settings if unsure.'>
                <HelpOutlineRounded fontSize='small' color='primary' />
              </TooltipEnhanced>
            </Grid>
          }
          placeholder='eg: 16.1'
          value={localData?.version}
          onChange={(value) => updateLocalData('version', value)}
          errorText={errorFields['version']}
          hasError={!!errorFields['version']}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectEnhanced
          id='deviceType'
          label='Device Type'
          value={localData?.deviceType}
          onChange={(value) => updateLocalData('deviceType', value)}
          options={Object.values(DEVICE_TYPES)}
          errorText={errorFields['deviceType']}
          hasError={!!errorFields['deviceType']}
          required
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldEnhanced
          id='model'
          label='Device Model'
          placeholder='eg: iPhone 12 Pro'
          value={localData?.model}
          onChange={(value) => updateLocalData('model', value)}
          errorText={errorFields['model']}
          hasError={!!errorFields['model']}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <EditUserSelect
          id='user'
          options={(usersOptions || []).filter((user) => {
            return hasAdmin ? !user.archived && !user.blocked : user?.id === currentUser?.id;
          })}
          label='User associated with this device'
          value={localData?.user}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onChange={(value) => updateLocalData('user', value)}
          loading={usersLoading}
          enable
          required
          errorText={errorFields['user']}
          hasError={!!errorFields['user']}
        />
      </Grid>

      <Grid container item xs={12} spacing={2} justifyContent='flex-end'>
        <Grid item>
          <ButtonEnhanced onClick={handleCancel} loading={loading || usersLoading} disabled={!hasChanged} variant='outlined'>
            <TypographyEnhanced id={'common.button.cancel'} />
          </ButtonEnhanced>
        </Grid>
        <Grid item>
          <ButtonEnhanced onClick={handleSaveDevice} loading={loading || usersLoading} disabled={!hasChanged}>
            <TypographyEnhanced id={TRANSLATION_PATH('buttons.save')} />
          </ButtonEnhanced>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManualAddDevice;
