import { formatDate } from '@anirudhm9/base-lib/lib/utils';
import { useQuery } from '@apollo/client';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { GET_DEVICES } from '../../../../api';
import DevicesTable from '../../../../components/application/manage/users/viewUser/devicesTable';
import { CardWithLoader } from '../../../../components/global';
import { Heading } from '../../../../components/ui';
import { useOrgContext } from '../../../../contexts';
import { TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const Devices = () => {
  const { orgId } = useOrgContext();

  const classes = useStyles();

  const { data: devicesData, loading: devicesLoading, error: devicesError } = useQuery(GET_DEVICES, { variables: { org: orgId }, skip: !orgId });

  const devices = useMemo(() => {
    if (devicesLoading || devicesError) {
      return [];
    }
    return devicesData?.devices || [];
  }, [devicesData?.devices, devicesError, devicesLoading]);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (devicesError || devicesLoading) {
      return;
    }

    const deviceRows = (devices || []).map((device) => {
      const { user = {} } = device || {};
      const { firstName = '', lastName = '' } = user || {};
      return {
        ...(device || {}),
        name: `${firstName || ''} ${((lastName || '').split('') || [])[0] || ''}.`,
        email: user.email,
        firstSync: formatDate(device?.firstSync),
        lastSync: formatDate(device?.lastSync)
      };
    });

    setRows(deviceRows);
  }, [devices, devicesError, devicesLoading]);

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title')} description={TRANSLATION_PATH('description')} />
      <CardWithLoader loading={devicesLoading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DevicesTable devices={rows} />
          </Grid>
        </Grid>
      </CardWithLoader>
    </Container>
  );
};

Devices.propTypes = {};

export default Devices;
