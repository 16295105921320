import { validateURL } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import HtmlParser from 'react-html-parser';
import { TypographyEnhanced } from '../../../../global';

const TextFieldView = ({ label, value, type, ...props }) => {
  if (!value) {
    return;
  }

  let content;
  switch (type) {
    case 'HTML':
      content = HtmlParser(value || '');
      break;
    default: {
      const isURL = validateURL(value || '');
      content = isURL ? (
        <TypographyEnhanced id={''} {...props}>
          {value || ''}
        </TypographyEnhanced>
      ) : (
        <TypographyEnhanced id={value || ''} {...props} />
      );
    }
  }

  return (
    <Grid container spacing={1} alignItems='center'>
      {label && (
        <Grid item xs={12}>
          <TypographyEnhanced id={label || ''} />
        </Grid>
      )}
      <Grid item>{typeof value === 'string' ? content : value}</Grid>
    </Grid>
  );
};

TextFieldView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  type: PropTypes.oneOf(['HTML', 'TEXT', null])
};

export default TextFieldView;
