import { symmetricDifference } from '@anirudhm9/base-lib/lib/utils';
import { createFilterOptions, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { AutoCompleteEnhanced } from '../../../../../../../ui/form';
import InputChange from '../../../../../../../ui/form/inputChange';

const filter = createFilterOptions();

const optionsToDropdown = (options = []) => {
  return (options || []).map((option) => {
    // local options do not have id yet, so use value to compare
    return { ...(option || {}), value: option.id };
  });
};

const Creatable = ({ id, label, options, fixedOptions, selectedOptions, onSave, disabled }) => {
  const [localSelected, setLocalSelected] = useState([]);
  const [localOptions, setLocalOptions] = useState([]);
  const [newOptions, setNewOptions] = useState([]);

  const formattedOptions = useMemo(() => {
    if (!options || !options.length) {
      return [];
    }

    return optionsToDropdown(options || []);
  }, [options]);

  useEffect(() => {
    if (!formattedOptions || !formattedOptions.length) {
      return;
    }
    setLocalOptions(formattedOptions);
  }, [formattedOptions]);

  useEffect(() => {
    if (!selectedOptions || !selectedOptions.length) {
      return;
    }

    setLocalSelected(selectedOptions);
  }, [selectedOptions]);

  // save function to trigger save and create new options for the newly created fields
  const handleSave = () => {
    const selected = options.filter((option) => localSelected.includes(option.id)).map((option) => option.id) || [];
    onSave({ selected }, newOptions);
  };

  // Triggered on change of field
  const handleChange = (_event, selected = []) => {
    const values = (selected || []).map((item) => item?.value) || [];
    const optionsToAdd = [];

    (selected || []).forEach((item) => {
      const existsInDatabase = options.find((option) => option?.name === item?.name);

      if (!existsInDatabase) {
        const newOption = { ...(item || {}), name: item?.value || '' };
        optionsToAdd.push(newOption);
      }
    });

    setLocalOptions([...formattedOptions, ...optionsToAdd]);
    // do no add the options that were added but deselected
    setNewOptions(optionsToAdd);
    setLocalSelected(values);
  };

  // Filters data on input and shows option to add value
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = (localOptions || []).some((option) => String(inputValue || '').toLowerCase() === String(option?.name || '').toLowerCase());
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        id: inputValue,
        name: `Add "${inputValue}"`,
        value: inputValue
      });
    }
    return filtered;
  };

  const inputChanged = useMemo(() => {
    if (localOptions?.length !== options?.length || localSelected?.length !== selectedOptions?.length) {
      return true;
    }

    if (!symmetricDifference(localSelected, selectedOptions).length) {
      return false;
    }

    return true;
  }, [localOptions?.length, localSelected, options?.length, selectedOptions]);

  const onReset = () => {
    setLocalOptions(formattedOptions);
    setLocalSelected(selectedOptions || []);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <AutoCompleteEnhanced
          id={id}
          multiple={true}
          disableCloseOnSelect={true}
          getOptionLabel={(option) => option.name || option.label || ''}
          label={label}
          options={localOptions}
          value={localSelected}
          fixedOptions={fixedOptions}
          onChange={handleChange}
          filterOptions={filterOptions}
          disabled={disabled}
        />
      </Grid>
      {inputChanged && <InputChange onSave={handleSave} onCancel={onReset} disabled={disabled} />}
    </Grid>
  );
};

Creatable.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  fixedOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  selectedOptions: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default Creatable;
