import { formatUserName, getAcronym, getInitials } from '@anirudhm9/base-lib/lib/utils';
import { Avatar, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { StyledBadge } from '../../global';
import { stringToColor } from './utils';

/**
 * Displays Avatar with initials
 *
 * @param {Object} user must include keys firstName & lastName
 * @param {String} value pass this instead of user if avater from any string is needed
 * @param {Boolean} showBadge (optional) to show online styled badge
 * @param {String} variant default: 'circle'. or can be 'square' or 'rounded'
 * @returns {JSX}
 */
const LetterAvatar = ({ src, user, value, showBadge, variant = 'circle', ...props }) => {
  const StringAvatar = useCallback(() => {
    let userName = '';
    let letters = '';

    if (user) {
      userName = formatUserName(user);
      letters = getInitials(user);
    } else if (value) {
      letters = getAcronym(value).substring(0, 2);
    }

    if (user?.imageUrl || src) {
      return <Avatar imgProps={{ referrerPolicy: 'no-referrer' }} alt={userName} src={src || user?.imageUrl} {...props} />;
    }

    return (
      <Avatar
        {...props}
        sx={{
          '& .MuiChip-avatar': {
            color: 'black'
          },
          ...(props.sx || {}),
          bgcolor: stringToColor(userName || value)
        }}
        variant={variant}
      >
        {letters}
      </Avatar>
    );
  }, [user, value, src, props, variant]);

  return (
    <Stack direction='row' spacing={2}>
      {showBadge ? (
        <StyledBadge overlap='circular' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant='dot'>
          <StringAvatar />
        </StyledBadge>
      ) : (
        <StringAvatar />
      )}
    </Stack>
  );
};

LetterAvatar.propTypes = {
  src: PropTypes.string,
  user: PropTypes.object,
  value: PropTypes.string,
  showBadge: PropTypes.bool,
  variant: PropTypes.string,
  sx: PropTypes.object
};

export default LetterAvatar;
