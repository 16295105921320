export const TRANSLATION_PATH = (route) => {
  return `components.application.manage.devices.manualEditDevice.${route}`;
};

export const REQUIRED_FIELDS = ['user'];

export const DEFAULT_DEVICE_DATA = {
  deviceId: '',
  model: '',
  os: '',
  type: '',
  compromisedStatus: '',
  passwordStatus: '',
  encryptionStatus: '',
  firstSync: '',
  lastSync: '',
  org: '',
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: ''
  }
};
