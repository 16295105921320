import { gql } from '@apollo/client';

export const COUNT_SCENARIOS = gql`
  query countScenarios($where: JSON!) {
    countScenarios(where: $where)
  }
`;

export const GET_SCENARIOS = gql`
  query scenarios($where: JSON!, $start: Int, $limit: Int, $sort: String) {
    scenarios(where: $where, start: $start, limit: $limit, sort: $sort) {
      id
      prompt
      name
      description
      framework {
        id
        name
      }
    }
  }
`;

export const GET_SCENARIO = gql`
  query scenario($id: ID!) {
    scenario(id: $id) {
      id
      prompt
      name
      description
      links {
        id
        source {
          id
          name
          mappingNumber
          parent {
            id
            name
            mappingNumber
          }
        }
        sourceTactic {
          id
          name
          mappingNumber
        }
        target {
          id
          name
          mappingNumber
          parent {
            id
            name
            mappingNumber
          }
        }
        targetTactic {
          id
          name
          mappingNumber
        }
      }
      framework {
        id
        name
      }
    }
  }
`;

export const GET_SCENARIO_MAPPINGS = gql`
  query getScenarioMappings($id: ID!, $org: ID!) {
    getScenarioMappings(id: $id, org: $org)
  }
`;

export const GET_SCENARIO_FROM_PROMPT = gql`
  mutation getScenarioFromPrompt($prompt: String!, $org: ID!, $frameworkValue: String!) {
    getScenarioFromPrompt(prompt: $prompt, org: $org, frameworkValue: $frameworkValue)
  }
`;

export const CREATE_SCENARIO = gql`
  mutation createScenario($data: ScenarioInput!) {
    createScenario(input: { data: $data }) {
      scenario {
        id
      }
    }
  }
`;

export const UPDATE_SCENARIO = gql`
  mutation updateScenario($id: ID!, $data: editScenarioInput!) {
    updateScenario(input: { where: { id: $id }, data: $data }) {
      scenario {
        id
      }
    }
  }
`;

export const DELETE_SCENARIO = gql`
  mutation deleteScenario($id: ID!) {
    deleteScenario(input: { where: { id: $id } }) {
      scenario {
        id
      }
    }
  }
`;

export const CREATE_OR_UPDATE_SCENARIO = gql`
  mutation createOrUpdateScenario($id: ID, $org: ID!, $data: JSON!) {
    createOrUpdateScenario(id: $id, org: $org, data: $data)
  }
`;
