const FRAMEWORKS = {
  MASTER_CONTROL_FRAMEWORK: {
    name: 'Master Control Framework',
    value: 'MASTER_CONTROL_FRAMEWORK',
    answers: {
      YES: {
        value: 'Answer1',
        // select Answer1 and deselect Answer0
        mapping: 'Answer1;!Answer0'
      },
      NO: {
        value: 'Answer0',
        // select Answer0 and deselect Answer1
        mapping: '!Answer1;Answer0'
      }
    },
    // MCF framework csv name (needs to update with new MCF csv name)
    frameworkCSV: 'MASTER_CONTROL_FRAMEWORK_v0.0.1-Agra'
    // MCF framework mapping csv name (needs to update with new MCF mapping csv name)
    // mappingCSV: 'MASTER_CONTROL_FRAMEWORK-MVSP_FRAMEWORK_v0.0.1-Agra'
  },
  MVSP_FRAMEWORK: {
    name: 'MVSP',
    value: 'MVSP_FRAMEWORK',
    // MVSP framework csv name (needs to update with new MVSP csv name)
    frameworkCSV: 'MVSP_FRAMEWORK_v0.0.1-Agra',
    // MVSP framework mapping csv name (needs to update with new MVSP mapping csv name)
    // mappingCSV: 'MVSP_FRAMEWORK-INTEGRATION_FRAMEWORK_v0.0.1-Agra'
    mappingCSV: 'MASTER_CONTROL_FRAMEWORK-MVSP_FRAMEWORK_v0.0.1-Agra'
  },
  SECURITY_HEADERS_FRAMEWORK: {
    name: 'Security Headers',
    value: 'SECURITY_HEADERS_FRAMEWORK',
    answers: {
      YES: {
        value: 'Answer1'
      },
      NO: {
        value: 'Answer0'
      }
    },
    // Secuirty headers framework csv name (needs to update with new SH framework csv name)
    frameworkCSV: 'SECURITY_HEADERS_FRAMEWORK_v0.0.1-Agra'
  },
  GSUITE_FRAMEWORK: {
    name: 'GSuite Framework',
    value: 'GSUITE_FRAMEWORK',
    answers: {
      YES: {
        value: 'Answer1'
      },
      NO: {
        value: 'Answer0'
      }
    },
    // GSuite framework csv name (needs to update with new GSuite framework csv name)
    frameworkCSV: 'GSUITE_FRAMEWORK_v0.0.1-Agra'
  },
  AWS_SECURITY_HUB_FRAMEWORK: {
    name: 'AWS Security Hub Framework',
    value: 'AWS_SECURITY_HUB_FRAMEWORK',
    answers: {
      YES: {
        value: 'Answer1'
      },
      NO: {
        value: 'Answer0'
      }
    },
    // AWS framework csv name (needs to update with new AWS framework csv name)
    frameworkCSV: 'AWS_SECURITY_HUB_FRAMEWORK_v0.0.1-Agra',
    // Mapping file for AWS controls (eg 2.9 VPC flow) to relevant MVSP question (eg 4.1 VPC flow)
    integrationMappingCSV: 'AWS_SECURITY_HUB_FRAMEWORK_MAPPING_v0.0.1-Agra'
  },
  MITRE_ATTACK: {
    name: 'MITRE ATT&CK',
    value: 'MITRE_ATTACK',
    answers: {
      YES: {
        value: 'Answer1',
        // select Answer1 and deselect Answer0
        mapping: 'Answer1;!Answer0'
      },
      NO: {
        value: 'Answer0',
        // select Answer0 and deselect Answer1
        mapping: '!Answer1;Answer0'
      }
    },
    // MCF framework csv name (needs to update with new MCF csv name)
    frameworkCSV: 'MITRE_ATTACK_v0.0.1-Agra',
    // // MCF framework mapping csv name (needs to update with new MCF mapping csv name)
    mappingCSV: 'MITRE_ATTACK_MAPPING_v0.0.1-Agra'
  },
  MITRE_ATLAS: {
    name: 'MITRE ATLAS',
    value: 'MITRE_ATLAS',
    answers: {
      YES: {
        value: 'Answer1',
        // select Answer1 and deselect Answer0
        mapping: 'Answer1;!Answer0'
      },
      NO: {
        value: 'Answer0',
        // select Answer0 and deselect Answer1
        mapping: '!Answer1;Answer0'
      }
    },
    // MCF framework csv name (needs to update with new MCF csv name)
    frameworkCSV: 'MITRE_ATLAS_v0.0.1-Agra'
    // // MCF framework mapping csv name (needs to update with new MCF mapping csv name)
    // mappingCSV: 'MASTER_CONTROL_FRAMEWORK-MVSP_FRAMEWORK_v0.0.1-Agra'
  },
  NIST_CSF: {
    name: 'NIST CSF',
    value: 'NIST_CSF',
    answers: {
      Answer0: {
        value: 'Answer0',
        score: 0
      },
      Answer1: {
        value: 'Answer1',
        score: 1
      },
      Answer2: {
        value: 'Answer',
        score: 2
      },
      Answer3: {
        value: 'Answer3',
        score: 3
      },
      Answer4: {
        value: 'Answer4',
        score: 4
      },
      Answer5: {
        value: 'Answer5',
        score: 5
      }
    },
    // AWS framework csv name (needs to update with new AWS framework csv name)
    frameworkCSV: 'NIST_CSF_v0.0.1-Agra',
    mappingCSV: 'NIST_CSF_MAPPING_v0.0.1-Agra'
  },
  NIST_CSF_V2: {
    name: 'NIST CSF 2.0',
    value: 'NIST_CSF_V2',
    answers: {
      Answer0: {
        value: 'Answer0',
        score: 0
      },
      Answer1: {
        value: 'Answer1',
        score: 1
      },
      Answer2: {
        value: 'Answer',
        score: 2
      },
      Answer3: {
        value: 'Answer3',
        score: 3
      },
      Answer4: {
        value: 'Answer4',
        score: 4
      },
      Answer5: {
        value: 'Answer5',
        score: 5
      }
    },
    // AWS framework csv name (needs to update with new AWS framework csv name)
    frameworkCSV: 'NIST_CSF_2_v0.0.1-Agra',
    mappingCSV: 'NIST_CSF_2_MAPPING_v0.0.1-Agra'
  }
};

// For instant execution at the background for mappings
const FRAMEWORK_MAPPING_RELATION = {
  [FRAMEWORKS.MVSP_FRAMEWORK.value]: FRAMEWORKS.MASTER_CONTROL_FRAMEWORK.value,
  [FRAMEWORKS.GSUITE_FRAMEWORK.value]: FRAMEWORKS.MVSP_FRAMEWORK.value,
  [FRAMEWORKS.SECURITY_HEADERS_FRAMEWORK.value]: FRAMEWORKS.MVSP_FRAMEWORK.value,
  [FRAMEWORKS.AWS_SECURITY_HUB_FRAMEWORK.value]: FRAMEWORKS.MVSP_FRAMEWORK.value
};

const ANSWER_OPTION_TYPES = {
  FRAMEWORK: 'FRAMEWORK',
  THIRD_PARTY: 'THIRD_PARTY'
};

const ANSWER_OPTION_SUB_TYPES = {
  CATEGORY: 'CATEGORY',
  INFORMATION: 'INFORMATION',
  QUESTION: 'QUESTION'
};

const QUESTION_TYPES = {
  TEXT: 'TEXT',
  TEXT_AREA: 'TEXT_AREA',
  SELECT: 'SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  UPLOAD: 'UPLOAD',
  CREATABLE: 'CREATABLE',
  SCALE: 'SCALE',
  SCALE_SELECT: 'SCALE_SELECT'
};

const TEXT_INPUT_TYPES = {
  OTHER: 'OTHER',
  ANSWER: 'ANSWER'
};

const APPLICATION_INVENTORY_QUESTION = 'MVSP2.3.1';

module.exports = {
  FRAMEWORKS,
  FRAMEWORK_MAPPING_RELATION,
  ANSWER_OPTION_TYPES,
  ANSWER_OPTION_SUB_TYPES,
  QUESTION_TYPES,
  TEXT_INPUT_TYPES,
  APPLICATION_INVENTORY_QUESTION
};
