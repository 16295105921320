import { getIntegrationAvatarURL } from '@anirudhm9/base-lib/lib/utils';
import { useQuery } from '@apollo/client';
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { Card, CardContent, Collapse, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { COUNT_LIBRARIES, GET_LIBRARIES } from '../../../../../../api';
import { isDarkModeActive } from '../../../../../../constants/defaults';
import { useOrgContext } from '../../../../../../contexts';
import { CardWithLoader, IconButtonEnhanced, TooltipEnhanced, TypographyEnhanced } from '../../../../../global';
import { Image, PaginationEnhanced } from '../../../../../ui';
import { DEFAULT_PAGE_SIZE } from '../../../../../ui/pagination/constants';
import AppLogo from '../../../../manage/applications/appLogo';
import { LibraryCard } from '../../library';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  card: {
    textAlign: 'left',
    backgroundColor: theme.palette.background.default
  }
}));

const ProjectCard = ({ project }) => {
  const classes = useStyles();
  const { id, name, avatar } = project || {};

  const { orgId } = useOrgContext();

  const {
    data: libraryCountData,
    loading: libraryCountLoading,
    error: libraryCountError
    // refetch: refetchProjectCount
  } = useQuery(COUNT_LIBRARIES, {
    variables: { where: { org: orgId } },
    skip: !orgId
  });

  const libraryCount = useMemo(() => {
    if (libraryCountLoading || libraryCountError) {
      return 0;
    }
    return libraryCountData?.countLibraries || 0;
  }, [libraryCountData?.countLibraries, libraryCountError, libraryCountLoading]);

  const [open, setOpen] = useState(false);
  const [start, setStart] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data: librariesData, loading: librariesLoading } = useQuery(GET_LIBRARIES, {
    variables: { where: { org: orgId, project: id }, start, limit: pageSize },
    skip: !open
  });

  const libraries = useMemo(() => {
    if (librariesLoading) {
      return [];
    }
    return librariesData?.libraries || [];
  }, [librariesData?.libraries, librariesLoading]);

  const toggle = () => {
    setOpen((open) => !open);
  };

  const src = useMemo(() => {
    return getIntegrationAvatarURL(project?.integration?.type, isDarkModeActive);
  }, [project?.integration?.type]);

  return (
    <Card key={id} className={classes.card}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item spacing={2} justifyContent='space-between' alignItems='center'>
            <Grid container item xs spacing={2} alignItems='center'>
              <Grid item>
                <AppLogo url={avatar} style={{ width: 50, height: 50 }} />
              </Grid>
              <Grid item>
                <TypographyEnhanced id={name} />
              </Grid>
            </Grid>
            <Grid container item xs justifyContent='flex-end' spacing={1}>
              <Grid item sx={{ width: 40, height: 40 }}>
                <TooltipEnhanced title={project?.integration?.name || ''}>
                  <Image src={src} alt={name} />
                </TooltipEnhanced>
              </Grid>
              <Grid item>
                <IconButtonEnhanced onClick={toggle}>{open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}</IconButtonEnhanced>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={open}>
              <Grid container spacing={2}>
                {librariesLoading ? (
                  <Grid container item spacing={2}>
                    {Array.from(Array(pageSize)).map((_, index) => (
                      <Grid key={`library-loading-card-${index}`} item xs={12}>
                        <CardWithLoader loading={librariesLoading} />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid container item spacing={2}>
                    {libraries.map((library) => {
                      const { id } = library || {};
                      return (
                        <Grid item xs={12} key={id}>
                          <LibraryCard library={library} />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <PaginationEnhanced total={libraryCount} onChange={setStart} pageSize={pageSize} setPageSize={setPageSize} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.object
};
export default ProjectCard;
