import { formatUserName } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { getTotalFilters } from '../../../../../helpers/formatUtils';
import { DEVICES as DEVICES_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/devices';
import Filter from '../../../../ui/filter';
import FilterBody from '../../../../ui/filter/body';
import { FILTERS, GROUP_BY_KEYS, GROUP_BY_OPTIONS, SEARCHABLE_KEYS, SORT_OPTIONS, SORT_VALUES } from './constants';
import { filterDevices, sortDevices } from './utils';

const DeviceFilter = ({ devices = [], setDevices, groupedDevices, groupByValue, setGroupByValue, searchKeys = SEARCHABLE_KEYS, defaultSearch }) => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [sortValue, setSortValue] = useState(SORT_VALUES.USERNAME_DESC.value);

  const totalFilters = useMemo(() => getTotalFilters(selectedFilters || {}), [selectedFilters]);
  const devicesCopy = useMemo(() => _.cloneDeep(devices || []), [devices]);

  const onSearch = (term = '') => {
    const filteredDevices = (devicesCopy || []).filter((device) => {
      const lowerCaseTerm = (term || '').toLowerCase();
      const { user, type, version } = device || {};
      device.username = formatUserName(user || {});
      device.deviceName = `${type} ${version}`;
      return _.some(searchKeys, (key) => (device[key] || '').toLowerCase().includes(lowerCaseTerm));
    });

    setDevices(filteredDevices);
  };

  const handleFilter = useCallback(() => {
    const filteredDevices = filterDevices(devicesCopy, selectedFilters);
    setDevices(filteredDevices);
  }, [devicesCopy, selectedFilters, setDevices]);

  const handleSort = useCallback(
    (value) => {
      setSortValue(value);
      setDevices((devices) => {
        return sortDevices(devices, value);
      });
    },
    [setDevices]
  );

  const onFilterReset = () => {
    setSelectedFilters({});
    setDevices(devices);
  };

  const handleGroupBy = useCallback(
    (value) => {
      setGroupByValue(value);
    },
    [setGroupByValue]
  );

  return (
    <Filter
      onFilter={handleFilter}
      onFilterReset={onFilterReset}
      onSearch={onSearch}
      onSearchReset={() => setDevices(devices || [])}
      totalFilters={totalFilters}
      handleSort={groupedDevices?.length ? '' : handleSort}
      sortOptions={SORT_OPTIONS}
      sortValue={sortValue}
      onGroupBy
      handleGroupBy={handleGroupBy}
      groupByOptions={GROUP_BY_OPTIONS}
      groupByValue={groupByValue}
      eventPath={DEVICES_EVENTS}
      defaultSearch={defaultSearch}
    >
      <Grid container item spacing={2}>
        <FilterBody filters={FILTERS} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
      </Grid>
    </Filter>
  );
};

DeviceFilter.propTypes = {
  devices: PropTypes.arrayOf(Object),
  filteredDevices: PropTypes.arrayOf(Object),
  setDevices: PropTypes.func,
  groupedDevices: PropTypes.object,
  setGroupedDevices: PropTypes.func,
  groupByValue: PropTypes.oneOf(GROUP_BY_KEYS),
  setGroupByValue: PropTypes.func,
  searchKeys: PropTypes.arrayOf(String),
  defaultSearch: PropTypes.string
};

export default DeviceFilter;
