import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_USER, userVar } from '../../api';

/**
 * Custom hook to encapsulate GET_ME
 * usage example:
 *
 * const { userId, userData, loading, error, refetch } = userUserData()
 *
 * @returns {{user: (JSON), refetch: (variables?: Partial<{user: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError, userId: *}}
 */
const useUserData = (newId) => {
  const userId = useReactiveVar(userVar);
  const {
    data: userData,
    loading,
    error,
    refetch
  } = useQuery(GET_USER, {
    variables: { id: newId || userId },
    skip: !userId
  });

  if (error) {
    console.error(error);
  }

  return { userId, user: userData?.user, loading, error, refetch };
};

export default useUserData;
