import { INTEGRATION_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { useMutation } from '@apollo/client';
import { DeleteRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { ARCHIVE_INTEGRATION } from '../../../../../../api';
import { useIntegrationContext } from '../../../../../../contexts';
import { openLink } from '../../../../../../helpers/routing';
import { ButtonEnhanced, IconButtonEnhanced, TypographyEnhanced } from '../../../../../global';
import AccordionEnhanced from '../../../../../ui/accordion';
import { INTEGRATION_SUBMISSION_TYPES } from '../constants';
import { OAUTH_PERMISSIONS, OAUTH_STATES, TRANSLATION_PATH } from './constants';

const OAuthApplication = ({ type, integration, toggle, onSubmit }) => {
  const { settings } = integration || {};
  const { refetch: refetchIntegrations } = useIntegrationContext();

  const [archiveIntegration] = useMutation(ARCHIVE_INTEGRATION);

  const name = useMemo(() => INTEGRATION_TYPES?.[type]?.name || '', [type]);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    const { AUTH_LINK } = settings || {};

    onSubmit(type, INTEGRATION_SUBMISSION_TYPES.AUTHORIZE_INTEGRATION.value);
    localStorage.setItem(`${(type || '').toLowerCase()}_auth`, OAUTH_STATES.ATTEMPTED);
    window.open(AUTH_LINK, '_self', 'noopener,noreferrer');
  };

  const archive = async () => {
    try {
      setLoading(true);
      await archiveIntegration({ variables: { id: integration?.id } });
      await refetchIntegrations();
      onSubmit(type, INTEGRATION_SUBMISSION_TYPES.ARCHIVE_INTEGRATION.value);
      toggle();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item xs>
          <TypographyEnhanced id={TRANSLATION_PATH('title')} value={{ name }} variant='h6' textAlign='center' />
        </Grid>
        <Grid item>
          <TypographyEnhanced id={TRANSLATION_PATH('description')} value={{ name }} variant='body1' textAlign='center' />
        </Grid>
        <Grid item>
          <TypographyEnhanced id={TRANSLATION_PATH('scope_description')} value={{ name }} variant='body1' textAlign='center' />

          {(OAUTH_PERMISSIONS[type] || []).map((item, i) => {
            return (
              <AccordionEnhanced key={i} header={item.label}>
                {item.description}
              </AccordionEnhanced>
            );
          })}
        </Grid>
        <Grid container item xs={12} spacing={2} justifyContent='flex-end' alignItems='center'>
          {integration?.id && (
            <Grid item>
              <IconButtonEnhanced variant='contained' onClick={archive} loading={loading} color='error'>
                <DeleteRounded />
              </IconButtonEnhanced>
            </Grid>
          )}
          <Grid item>
            <ButtonEnhanced fullWidth variant='contained' onClick={handleClick} loading={loading}>
              <TypographyEnhanced id={TRANSLATION_PATH('buttons.authorise')} value={{ name }} />
            </ButtonEnhanced>
          </Grid>
        </Grid>
        <Grid container alignItems='center' justifyContent='center' item xs={12}>
          <Grid item>
            <TypographyEnhanced id={TRANSLATION_PATH('privacy_policy.1')} variant='body1' textAlign='inline' />
          </Grid>
          <Grid item>
            <TypographyEnhanced
              id={TRANSLATION_PATH('privacy_policy.2')}
              variant='body1'
              textAlign='inline'
              onClick={() => openLink('https://www.avertro.com/privacy-policy')}
              fontWeight='bold'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

OAuthApplication.propTypes = {
  type: PropTypes.oneOf(Object.keys(INTEGRATION_TYPES)),
  integration: PropTypes.object,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func
};
export default OAuthApplication;
