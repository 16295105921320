import { Article, PictureAsPdf } from '@mui/icons-material';
import { alpha, Card, CardContent, CardMedia, Container, CssBaseline, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RedemptionModal } from '../../../../../components/application/rewards/redeem';
import { ButtonEnhanced, toast, TypographyEnhanced } from '../../../../../components/global';
import { Heading, Watermark } from '../../../../../components/ui';
import TabPanelEnhanced from '../../../../../components/ui/tab/tabPanel';
import TabsEnhanced from '../../../../../components/ui/tab/tabs';
import { useOrgContext } from '../../../../../contexts';
import { logEvent } from '../../../../../library';
import { dummyMarketplaceItems } from '../default/dummyData';
import { LOG_EVENT_PATH, TABS, TRANSLATION_PATH } from './constants/defaults';
// TODO add real data in

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  bold: {
    fontWeight: 'bold'
  },
  headerBackground: {
    height: '250px',
    paddingLeft: theme.spacing(2),
    background: `linear-gradient(0deg, ${alpha(theme.palette.background.paper, 0.95)} 10%, ${alpha(theme.palette.background.paper, 0)} 100%)`
  }
}));

const ViewReward = () => {
  const classes = useStyles();
  const params = useParams();

  const { org } = useOrgContext();
  const allRewards = dummyMarketplaceItems;

  const [reward, setReward] = useState({});
  const [itemStatus, setItemStatus] = useState('unlocked');
  const [selectedTab, setSelectedTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!params || !allRewards) {
      return;
    }

    const currentReward = allRewards?.find((item) => item.id === params.id);

    setReward(currentReward);
  }, [allRewards, params]);

  useEffect(() => {
    // TODO get the orgs current rewards details
    const redeemedItems = ['6285e59f33568b70601a3846', '6285e59fa10ef8c48de61e86'];
    const points = 199;

    let status = 'unlocked';

    // if (org?.points < pointValue) {
    if (points < reward.pointValue) {
      status = 'locked';
    }

    // if (org?.redeemedItems.includes(id)) {
    if (redeemedItems.includes(reward.id)) {
      status = 'redeemed';
    }

    setItemStatus(status);
  }, [org?.points, reward]);

  const redeemModal = () => {
    setModalOpen(true);
  };

  const downloadFile = (type) => {
    logEvent(LOG_EVENT_PATH('DOWNLOAD_REWARD'), { type, rewardId: reward.id });
    toast.success(`${type} File Downloaded`);
  };

  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  const RenderButton = () => {
    switch (itemStatus) {
      case 'redeemed':
        return (
          <Fragment>
            <Grid item>
              <ButtonEnhanced color='secondary' startIcon={<Article />} variant='contained' onClick={() => downloadFile('word')}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.download.word')} />
              </ButtonEnhanced>
            </Grid>
            <Grid item>
              <ButtonEnhanced color='primary' variant='contained' startIcon={<PictureAsPdf />} onClick={() => downloadFile('pdf')}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.download.pdf')} />
              </ButtonEnhanced>
            </Grid>
          </Fragment>
        );
      case 'locked':
        return (
          <ButtonEnhanced color='primary' disabled onClick={() => redeemModal()}>
            <TypographyEnhanced id={TRANSLATION_PATH('buttons.locked')} />
          </ButtonEnhanced>
        );

      default:
        return (
          <ButtonEnhanced color='secondary' onClick={() => redeemModal()}>
            <TypographyEnhanced id={TRANSLATION_PATH('buttons.redeem')} value={{ cost: reward?.pointValue }} />
          </ButtonEnhanced>
        );
    }
  };

  const tabs = [
    TRANSLATION_PATH('tabs.overview'),
    TRANSLATION_PATH('tabs.license'),
    TRANSLATION_PATH('tabs.faq'),
    TRANSLATION_PATH('tabs.support'),
    TRANSLATION_PATH('tabs.terms')
  ];

  const tabContent = ['OVERVIEW', 'LICENSE', 'FAQ', 'SUPPORT', 'TERMS'];

  const data = 'test';

  if (_.isEmpty(reward)) {
    return null;
  }

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <CssBaseline />
      <RedemptionModal modalOpen={modalOpen} setModalOpen={setModalOpen} data={reward} />
      <Heading id={TRANSLATION_PATH('title')} value={{ rewardName: reward?.label }} hideDivider />
      <Card>
        <Watermark>
          <CardMedia image={reward.backgroundImage} alt='background-image'>
            <Grid container spacing={1} alignContent='flex-end' className={classes.headerBackground}>
              <Grid item xs={12}>
                <TypographyEnhanced
                  id={TRANSLATION_PATH('title')}
                  value={{ rewardName: reward?.label }}
                  style={{ fontWeight: 'bold' }}
                  display='inline'
                  variant='h4'
                />
              </Grid>
              <Grid item xs={12}>
                <TypographyEnhanced id={TRANSLATION_PATH('owner')} value={{ name: reward?.creator?.name }} variant='body1' />
              </Grid>
              <Grid item xs={12} container spacing={1} paddingBottom={2}>
                <RenderButton />
              </Grid>
            </Grid>
          </CardMedia>
          {/* </Heading> */}
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <TabsEnhanced options={tabs} value={selectedTab} onChange={handleChange} />
                <Divider />
              </Grid>
              <Grid item xs={12} lg={8}>
                {tabContent.map((item, index) => {
                  return (
                    <TabPanelEnhanced tabId={index} value={selectedTab} key={index}>
                      {TABS(data)[item]}
                    </TabPanelEnhanced>
                  );
                })}
              </Grid>
            </Grid>
          </CardContent>
        </Watermark>
      </Card>
    </Container>
  );
};

ViewReward.propTypes = {};

export default ViewReward;
