export const TRANSLATION_PATH = (route) => {
  return `components.application.manage.devices.manualAddDevice.${route}`;
};

export const REQUIRED_FIELDS = ['user', 'type', 'deviceType'];

export const DEFAULT_DEVICE_DATA = {
  deviceId: '',
  model: '',
  type: '',
  version: '',
  deviceType: '',
  compromisedStatus: '',
  passwordStatus: '',
  encryptionStatus: '',
  firstSync: '',
  lastSync: '',
  org: '',
  user: ''
};
