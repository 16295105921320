import { hasErrors, validateEmail } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { LockRounded } from '@mui/icons-material';
import { Avatar, Box, CssBaseline, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD } from '../../../api';
import { ButtonEnhanced, TypographyEnhanced, toast } from '../../../components/global';
import { TextFieldEnhanced } from '../../../components/ui/form';
import { defaultMessages } from '../../../constants';
import { usePageViewContext } from '../../../contexts/logPageViewContext';
import { clearCache } from '../../../helpers/routing';
import { useIsDesktop, useKeyPress } from '../../../hooks';
import { logEvent } from '../../../library';
import { FORGOT_PASSWORD as FORGOT_PASSWORD_PAGE_EVENTS } from '../../../library/amplitude/events/views/user/forgotPassword';
import { LOG_EVENT_PATH, TRANSLATION_PATH, defaultData, requiredFields } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(10, 0),
    justifyContent: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },

  link: {
    textDecoration: 'none'
  },
  attribute: {
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const { pageViewLogged, setPageViewLogged } = usePageViewContext();

  useEffect(() => {
    if (pageViewLogged) {
      return;
    }
    logEvent(FORGOT_PASSWORD_PAGE_EVENTS.PAGE_VISITED);
    setPageViewLogged(true);
  }, [pageViewLogged, setPageViewLogged]);

  const [localData, setLocalData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  const handleForgotPassword = async () => {
    logEvent(LOG_EVENT_PATH('RESET_BUTTON'));
    const errors = hasErrors(requiredFields, localData);

    if (errors?.length) {
      setError(defaultMessages.requiredFields);
      return;
    }
    if (!validateEmail(localData.email)) {
      setError(defaultMessages.invalidLogin);
      return;
    }

    try {
      setError('');
      setLoading(true);
      clearCache();
      const res = await forgotPassword({ variables: { email: localData.email.toLowerCase() } });
      const data = res?.data?.forgotPassword;
      if (data?.ok) {
        toast.success(t(TRANSLATION_PATH('alerts.emailSent')));
      } else {
        toast.error(t(TRANSLATION_PATH('alerts.error')));
      }
    } catch (error) {
      console.error(error);
      setError(defaultMessages.invalidLogin);
      toast.error(t(TRANSLATION_PATH('alerts.error')));
    } finally {
      setLoading(false);
    }
  };

  useKeyPress('Enter', () => {
    handleForgotPassword();
  });

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={6} component={isDesktop ? Paper : 'div'}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockRounded />
          </Avatar>
          <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h5' />
          <Box className={classes.form}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12}>
                <TextFieldEnhanced
                  id='email'
                  label={TRANSLATION_PATH('email.label')}
                  placeholder={TRANSLATION_PATH('email.placeholder')}
                  type='text'
                  required
                  value={localData?.email || ''}
                  onChange={(value) =>
                    setLocalData((prev) => {
                      return {
                        ...prev,
                        email: value.toLowerCase()
                      };
                    })
                  }
                  hasError={!!error}
                  errorText={error}
                  helperText=''
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonEnhanced fullWidth variant='contained' onClick={() => handleForgotPassword()} loading={loading}>
                  <TypographyEnhanced id={TRANSLATION_PATH('buttons.reset')} />
                </ButtonEnhanced>
              </Grid>
              <Grid item xs={12}>
                <ButtonEnhanced
                  fullWidth
                  variant='text'
                  onClick={() => {
                    logEvent(FORGOT_PASSWORD_PAGE_EVENTS.BACK_BUTTON);
                    navigate('/user/login');
                  }}
                >
                  <TypographyEnhanced id={TRANSLATION_PATH('buttons.back')} />
                </ButtonEnhanced>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
