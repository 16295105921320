import { CRITICALITIES, STATUSES } from '@anirudhm9/base-lib/lib/constants';
import { logEvent } from '../../../../../../library';
import { DASHBOARD as DASHBOARD_EVENTS } from '../../../../../../library/amplitude/events/views/app/dashboard';
import { FILTERS } from '../../../../manage/issues/filter/constants';

/**
 * Takes status or criticality id and returns value
 *
 * @param {String} id
 * @param {String | Number} type
 * @param {Array} statusOptions
 * @param {Array} criticalityOptions
 * @returns String
 */
const getFilterValue = (id, type, statusOptions, criticalityOptions) => {
  if (!type || !statusOptions || !criticalityOptions) {
    return;
  }
  //if user clicked on a criticality option
  if (type === FILTERS?.CRITICALITY?.id) {
    const result = Object.values(criticalityOptions).find((criticality) => criticality?.id === id);
    return result?.value;
  }
  //if user clicked on a status option
  const result = Object.values(statusOptions).find((status) => status?.id === id);
  return result?.value;
};

/**
 * Logs chart click event and navigates user
 *
 * @param {Object} event
 * @param {String | Number} type
 * @param {Array} statusOptions
 * @param {Array} criticalityOptions
 * @param {VoidFunction} navigate
 */
const logChartClick = (event, type, statusOptions, criticalityOptions, navigate) => {
  const { data = {} } = event || {};
  const { id } = data || {};

  if (!id || !FILTERS?.[type]?.filters?.[0]?.id) {
    return;
  }

  const filter = {
    [type]: {
      [FILTERS[type].filters[0].id]: [id]
    }
  };

  logEvent(DASHBOARD_EVENTS.ISSUES_SUMMARY_CHART_CLICK, {
    filter_type: type,
    filter_id: id,
    filter_value: getFilterValue(id, type, statusOptions, criticalityOptions)
  });
  //navigate and set default filter
  navigate('/app/manage/issues', { state: { filter } });
};

/**
 *
 * Takes CRITICALITIES enum and extracts name values into array
 *
 * @returns Array
 */
const CRITICALITY_NAMES = () => {
  const result = [];
  for (const key in CRITICALITIES) {
    result.push(CRITICALITIES[key]?.name);
  }
  return result;
};

/**
 *
 * Takes STATUSES enum and extracts name values into array
 *
 * @returns Array
 */
const STATUS_NAMES = () => {
  const result = [];
  for (const key in STATUSES) {
    result.push(STATUSES[key]?.name);
  }
  return result;
};

/**
 * Logs event for user clicking on legend item to select/deselect
 *
 * @param {Object} event
 */
const logLegendClick = (event) => {
  const { name, selected } = event || {};
  const criticalityNames = CRITICALITY_NAMES();
  const statusNames = STATUS_NAMES();

  const activeLegends = [];

  for (const legend in selected) {
    if (!selected[legend]) {
      continue;
    }
    activeLegends.push(legend);
  }

  if (criticalityNames.includes(name)) {
    logEvent(DASHBOARD_EVENTS.ISSUES_SUMMARY_CHART_LEGEND, { chart: 'Criticality Breakdown', active_legends: activeLegends });
  }
  if (statusNames.includes(name)) {
    logEvent(DASHBOARD_EVENTS.ISSUES_SUMMARY_CHART_LEGEND, { chart: 'Status Breakdown', active_legends: activeLegends });
  }
};

/**
 * Logs view all button click event and navigates user
 *
 * @param {VoidFunction} navigate
 */
const logViewAll = (navigate) => {
  logEvent(DASHBOARD_EVENTS.ISSUES_SUMMARY_VIEW_ALL);
  navigate('/app/manage/issues');
};

export { logViewAll, logChartClick, logLegendClick };
