import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useId } from 'react';
import HTMLEditor from '../../htmlEditor';
import TextFieldEnhanced from '../../textField';

const TextFieldEdit = ({ label, value, defaultValue, onChange, multiline, rows, placeholder, type, ...props }) => {
  const id = useId();

  let editor;
  switch (type) {
    case 'HTML':
      editor = <HTMLEditor label={label || ''} value={defaultValue || value || ''} onChange={onChange} />;
      break;
    default:
      editor = (
        <TextFieldEnhanced
          id={id}
          label={label || ''}
          value={value || ''}
          multiline={multiline}
          rows={rows || 5}
          onChange={onChange}
          placeholder={placeholder || ''}
          {...props}
        />
      );
  }
  return (
    <Grid item xs={12}>
      {editor}
    </Grid>
  );
};

TextFieldEdit.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['HTML', 'TEXT', null])
};

export default TextFieldEdit;
