import { IMAGES, STATUS_CODES } from '@anirudhm9/base-lib/lib/constants';
import { formatErrorMessage, isEmpty } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { DeleteRounded, OpenInNewRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { ARCHIVE_INTEGRATION, INITIALISE_INTEGRATION, UPDATE_INTEGRATION } from '../../../../../../api';
import { defaultMessages } from '../../../../../../constants';
import { useIntegrationContext, useOrgContext } from '../../../../../../contexts';
import { openLink } from '../../../../../../helpers/routing';
import { ButtonEnhanced, IconButtonEnhanced, toast, TooltipEnhanced, TypographyEnhanced } from '../../../../../global';
import { INTEGRATION_CONFIGURATION, INTEGRATION_SUBMISSION_TYPES } from '../constants';
import IntegrationSettings from './fields';

const IntegrationForm = ({ type, toggle, integration, onSubmit }) => {
  const { refetch: refetchIntegrations } = useIntegrationContext();

  const integrationConfiguration = useMemo(() => {
    return INTEGRATION_CONFIGURATION[type] || {};
  }, [type]);

  const integrationSettings = useMemo(() => integrationConfiguration?.settings || {}, [integrationConfiguration?.settings]);

  const { orgId } = useOrgContext();

  const [localSettings, setLocalSettings] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorFields, setErrorFields] = useState({});

  const [initialiseIntegration] = useMutation(INITIALISE_INTEGRATION);
  const [archiveIntegration] = useMutation(ARCHIVE_INTEGRATION);
  const [updateIntegration] = useMutation(UPDATE_INTEGRATION);

  const archive = async () => {
    try {
      setLoading(true);
      await archiveIntegration({ variables: { id: integration?.id } });
      await refetchIntegrations();
      onSubmit(type, INTEGRATION_SUBMISSION_TYPES.ARCHIVE_INTEGRATION.value);
      toggle();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const errors = isEmpty(Object.keys(integrationSettings), localSettings || {});
    setErrorFields(errors);
    if (!_.isEmpty(errors)) {
      toast.error(defaultMessages.requiredFields);
      return;
    }

    try {
      setLoading(true);
      let entity;
      if (!integration?.id) {
        entity = (await initialiseIntegration({ variables: { org: orgId, type, settings: localSettings } }))?.data?.initialiseIntegration;
      } else {
        entity = (await updateIntegration({ variables: { id: integration?.id, data: { settings: localSettings } } }))?.data?.updateIntegration?.integration;
        if (entity?.connected) {
          onSubmit(type, INTEGRATION_SUBMISSION_TYPES.EDIT_INTEGRATION.value);
        }
      }
      const { connected } = entity || {};
      await refetchIntegrations();
      if (!connected) {
        toast.error(STATUS_CODES.ERROR.INVALID_CREDENTIALS.message);
      } else {
        toast.success(defaultMessages.defaultSuccess);
      }
      toggle();
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!integration) {
      return;
    }
    setLocalSettings(integration?.settings);
  }, [integration]);

  return (
    <Grid container spacing={1}>
      <Grid
        container
        item
        xs={12}
        sx={{
          height: 150,
          backgroundImage: `url(${IMAGES.ICONS[type]})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <Grid container item xs={12} alignItems='flex-end' justifyContent='center'>
          <TypographyEnhanced id={integrationConfiguration?.name} variant='h6' />
          <TooltipEnhanced title={'Instructions'}>
            <IconButtonEnhanced onClick={() => openLink(integrationConfiguration?.help?.link)}>
              <OpenInNewRounded />
            </IconButtonEnhanced>
          </TooltipEnhanced>
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <IntegrationSettings
          localSettings={localSettings}
          setLocalSettings={setLocalSettings}
          errorFields={errorFields}
          configuration={integrationConfiguration}
        />
      </Grid>
      <Grid container item justifyContent='flex-end' alignItems='center' spacing={2}>
        {integration?.id && (
          <Grid item>
            <TooltipEnhanced title='Delete'>
              <IconButtonEnhanced size='large' onClick={archive} loading={loading} color='error'>
                <DeleteRounded />
              </IconButtonEnhanced>
            </TooltipEnhanced>
          </Grid>
        )}
        <Grid item>
          <ButtonEnhanced onClick={handleSubmit} loading={loading}>
            <TypographyEnhanced id={'Save'} />
          </ButtonEnhanced>
        </Grid>
      </Grid>
    </Grid>
  );
};

IntegrationForm.propTypes = {
  type: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  integration: PropTypes.object,
  onSubmit: PropTypes.func
};

export default IntegrationForm;
