import { gql } from '@apollo/client';

export const CREATE_ORG = gql`
  mutation createOrg($data: OrgInput!) {
    createOrg(input: { data: $data }) {
      org {
        id
      }
    }
  }
`;

export const GET_ORG = gql`
  query org($id: ID!) {
    org(id: $id) {
      id
      name
      domain
      onboarded
      size {
        id
        name
        type
      }
      industry {
        id
        name
      }
      country {
        id
        name
      }
      operations {
        id
        name
      }
      websites {
        id
        link
      }
      frameworks {
        id
        name
        value
      }
      masterControlFramework {
        id
        name
      }
      framework {
        id
      }
      compliances {
        id
        name
        fullName
        link
      }
    }
  }
`;

export const UPDATE_ORG = gql`
  mutation updateOrg($id: ID!, $data: editOrgInput!) {
    updateOrg(input: { where: { id: $id }, data: $data }) {
      org {
        id
      }
    }
  }
`;

export const UPDATE_ORG_COMPLIANCES = gql`
  mutation updateOrgCompliances($id: ID!) {
    updateOrgCompliances(id: $id)
  }
`;

export const GET_OVERALL_TASK_PROGRESS = gql`
  query getOverallTaskProgress($id: ID!) {
    getOverallTaskProgress(id: $id)
  }
`;
