import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import { CategoryAccordion } from '../../../../../components/application/assessments';
import { DotLoader, TypographyEnhanced } from '../../../../../components/global';
import { Banner, Heading } from '../../../../../components/ui';
import { SelectEnhanced } from '../../../../../components/ui/form';
import { useShadowAssessmentContext } from '../../../../../contexts';
import { useInitialLoading } from '../../../../../hooks';
import SingleCategory from '../singleCategory';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const ShadowAssessment = () => {
  const classes = useStyles();
  const initialLoading = useInitialLoading();

  const { assessment, loading: assessmentLoading, shadowFramework, setShadowFramework, frameworks, frameworksLoading } = useShadowAssessmentContext();

  const [selectedCategory, setSelectedCategory] = useState();

  const initialising = useMemo(() => {
    return frameworks?.find((framework) => !framework?.initialised);
  }, [frameworks]);

  // Reset selected category when framework changed by user
  useEffect(() => {
    setSelectedCategory();
  }, [shadowFramework]);

  const handleChange = (value) => {
    setShadowFramework(value);
  };

  if (assessmentLoading || initialLoading || frameworksLoading) {
    return <DotLoader />;
  }

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      {initialising && (
        <Banner>
          <TypographyEnhanced id={'One or more frameworks are currently undergoing initialization.'} />
        </Banner>
      )}

      {/* <Heading id={TRANSLATION_PATH('titleShadow')} /> */}
      <Heading id='Cyber Assessment' />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectEnhanced label='Select framework' value={shadowFramework || ''} options={frameworks} onChange={handleChange} />
        </Grid>
        {shadowFramework && (
          <Grid container item>
            <Grid item xs={selectedCategory ? 6 : 12}>
              <CategoryAccordion level={0} category={assessment?.category || {}} setSelectedCategory={setSelectedCategory} />
            </Grid>
            {selectedCategory && (
              <Grid item xs={6}>
                <SingleCategory id={selectedCategory?.id || ''} fullscreen={false} />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
export default ShadowAssessment;
