import { CRITICALITIES, STATUSES } from '@anirudhm9/base-lib/lib/constants';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { TypographyEnhanced } from '../../../../../global';

export const FILTERS = {
  CRITICALITY: {
    id: 'CRITICALITY',
    label: 'Criticality',
    filters: [
      {
        id: 'CRITICALITY_CHECKBOX',
        label: '',
        type: 'checkbox',
        options: Object.values(CRITICALITIES).map((criticality) => {
          return { ...criticality, id: criticality.value };
        })
      }
    ]
  },
  STATUS: {
    id: 'STATUS',
    label: 'Status',
    filters: [
      {
        id: 'STATUS_CHECKBOX',
        label: '',
        type: 'checkbox',
        options: Object.values(STATUSES).map((status) => {
          return { ...status, id: status.value };
        })
      }
    ]
  }
};

export const SORT_VALUES = {
  CREATED_AT_DESC: {
    id: 'created_at:DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Most Recent
        </TypographyEnhanced>
        {/* <ArrowDownwardRounded fontSize='small' /> */}
      </Grid>
    ),
    value: 'created_at:DESC',
    label: ''
  },
  // CREATED_AT_ASC: {
  //   id: 'created_at:ASC',
  //   name: (
  //     <Grid container alignItems='center' justifyContent='space-between'>
  //       <TypographyEnhanced id='' mr={1}>
  //         Created
  //       </TypographyEnhanced>
  //       <ArrowUpwardRounded fontSize='small' />
  //     </Grid>
  //   ),
  //   value: 'created_at:ASC',
  //   label: ''
  // },
  ALPHABETICAL_DESC: {
    id: 'name:DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Alphabetical
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'name:DESC',
    label: ''
  },
  ALPHABETICAL_ASC: {
    id: 'name:ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Alphabetical
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'name:ASC',
    label: ''
  },
  CRITICALITY_DESC: {
    id: 'criticality.level:DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Criticality
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'criticality.level:DESC',
    label: ''
  },
  CRITICALITY_ASC: {
    id: 'criticality.level:ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Criticality
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'criticality.level:ASC',
    label: ''
  }
};

export const SORT_OPTIONS = Object.values(SORT_VALUES);
