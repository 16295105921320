import { randomLoadingSentence } from '@anirudhm9/base-lib/lib/utils';
import { useEffect, useState } from 'react';
const SECOND_MS = 1000;

/**
 * Custom hook to useLoadingSentence
 * Example Code :
 *
 * useLoadingSentence();
 **/
const useLoadingSentence = (timer = 5 * SECOND_MS) => {
  const [sentence, setSentence] = useState(randomLoadingSentence());

  useEffect(() => {
    const interval = setInterval(() => {
      setSentence(randomLoadingSentence());
    }, timer);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [timer]);

  return sentence;
};

export default useLoadingSentence;
