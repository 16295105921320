import { PAGE_THRESHOLD } from '@anirudhm9/base-lib/lib/constants';
import { Grid, TablePagination } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { TypographyEnhanced } from '../../global';
import { PAGE_SIZES } from './constants';

/**
 *
 * Extends pagination component for async data fetching
 *
 * @typedef {import('@mui/material').PaginationProps} PaginationProps Props for `Pagination` from '@mui/material` library
 *
 * @param {{ total: number, size: number, onChange: VoidFunction, pageSize: number, setPageSize: VoidFunction } & PaginationProps} props
 * @returns {React.FC<PaginationProps>}
 */
const PaginationEnhanced = ({
  total,
  pageSize = PAGE_THRESHOLD,
  setPageSize,
  page: pageFromContext,
  onPageChange,
  onChange,
  logPageChange,
  logRowChange,
  ...props
}) => {
  const [page, setPage] = useState(0);

  const paginationRequired = useMemo(() => Math.ceil(total / PAGE_THRESHOLD) > 1, [total]);

  const handlePageChange = (_event, value) => {
    logPageChange && logPageChange(value, page);
    setPage(value);
    onPageChange && onPageChange(value);
  };

  const handlePageSizeChange = (event) => {
    if (!setPageSize) {
      return;
    }
    logRowChange && logRowChange(event.target.value, pageSize);
    setPageSize(event.target.value);
  };

  useEffect(() => {
    if (!total) {
      return;
    }

    onChange(page * pageSize);
  }, [onChange, page, pageSize, total]);

  // use pageFromContext if available
  useEffect(() => {
    // first page is 0, but 0 evaluates to false
    if (pageFromContext !== 0 && !pageFromContext) {
      return;
    }
    setPage(pageFromContext);
  }, [pageFromContext]);

  if (!total || !onChange || !paginationRequired) {
    return null;
  }

  return (
    <Grid container item xs={12} justifyContent='center'>
      <TablePagination
        component='div'
        count={total}
        page={pageFromContext || page}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handlePageSizeChange}
        rowsPerPageOptions={PAGE_SIZES}
        labelRowsPerPage={<TypographyEnhanced id='Rows per page:' component='span' variant='body1' />}
        labelDisplayedRows={({ from, to, count }) => <TypographyEnhanced id={`${from}-${to} of ${count}`} component='span' variant='body1' />}
        {...props}
      />
    </Grid>
  );
};

PaginationEnhanced.propTypes = {
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  setPageSize: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  logPageChange: PropTypes.func,
  logRowChange: PropTypes.func
};
export default PaginationEnhanced;
