import { isIDEqual, sortVersionArray } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAssessmentContext, useShadowAssessmentContext } from '../../../../../contexts';
import { logEvent } from '../../../../../library';
import { CYBER_ASSESSMENT as CYBER_ASSESSMENT_EVENTS } from '../../../../../library/amplitude/events/views/app/assessments/cyber';
import { ButtonEnhanced } from '../../../../global';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(2)
  }
}));

const QuestionNavigator = ({ current, onBack, onNext, shadow, setPageVisitLogged }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { categoriesFlat = {} } = useAssessmentContext();
  const { categoriesFlat: shadowCategoriesFlat } = useShadowAssessmentContext();

  const { id: currentCategoryId } = current || {};

  /**
   * Removes category objects that have no questions from return array
   *
   * @param {[Object]} categoriesArray
   * @returns {[Object]}
   */
  const removeParentCategories = (categoriesArray) => {
    if (!categoriesArray?.length) {
      return [];
    }
    const sortedCategories = sortVersionArray(categoriesArray, 'index');
    const result = (sortedCategories || []).filter((child) => child?.questions?.length > 0);
    return result;
  };

  const Navigator = useCallback(() => {
    const flat = shadow ? shadowCategoriesFlat : categoriesFlat;
    const allChildCategories = Object.values(flat || {}).filter((category) => category?.level > 1);
    const sortedChildCategories = removeParentCategories(allChildCategories);

    const currentIndex = _.findIndex(sortedChildCategories || [], (child) => {
      return isIDEqual(currentCategoryId, child?.id);
    });

    const showBack = currentIndex !== 0;
    const showDone = currentIndex === sortedChildCategories?.length - 1;

    const linkPrefix = `/app/assessment/cyber/category/${shadow ? 'shadow/' : ''}`;

    const sendLogEvent = (event) => {
      const { index: currentChildIndex, name: currentChildName } = sortedChildCategories[currentIndex];
      const { index: nextChildIndex, name: nextChildName } = sortedChildCategories[currentIndex + 1] || {};
      const { index: prevChildIndex, name: prevChildName } = sortedChildCategories[currentIndex - 1] || {};

      const from = `${currentChildIndex} ${currentChildName}`;

      //back button
      if (event?.target?.value === 'back') {
        logEvent(CYBER_ASSESSMENT_EVENTS.BACK_BUTTON, {
          from: from,
          to: `${prevChildIndex} ${prevChildName}`
        });
        return;
      }

      //next button
      if (event?.target?.value === 'next') {
        logEvent(CYBER_ASSESSMENT_EVENTS.NEXT_BUTTON, {
          from: from,
          to: showDone ? 'Done' : `${nextChildIndex} ${nextChildName}`
        });
      }
    };

    return (
      <Grid container className={classes.spacing} justifyContent='space-between'>
        <Grid item>
          {showBack && (
            <ButtonEnhanced
              variant='outlined'
              value='back'
              onClick={(event) => {
                sendLogEvent(event);
                setPageVisitLogged(false);
                navigate(`${linkPrefix}${sortedChildCategories?.[currentIndex - 1]?.id}`);
                onBack && onBack();
              }}
            >
              Previous Question
            </ButtonEnhanced>
          )}
        </Grid>
        <Grid item>
          <ButtonEnhanced
            variant='outlined'
            value='next'
            onClick={(event) => {
              sendLogEvent(event);
              setPageVisitLogged(false);
              if (showDone) {
                navigate('app/assessment/cyber');
                return;
              }
              navigate(`${linkPrefix}${sortedChildCategories?.[currentIndex + 1]?.id}`);
              onNext && onNext();
            }}
          >
            {showDone ? 'Done' : 'Next Question'}
          </ButtonEnhanced>
        </Grid>
      </Grid>
    );
  }, [categoriesFlat, classes.spacing, currentCategoryId, navigate, onBack, onNext, setPageVisitLogged, shadow, shadowCategoriesFlat]);

  return <Navigator />;
};

QuestionNavigator.propTypes = {
  current: PropTypes.object,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  shadow: PropTypes.bool,
  setPageVisitLogged: PropTypes.func
};

export default QuestionNavigator;
