/* eslint-disable quotes */
// views.app.rewards.redeem.viewReward

export const VIEW_REWARD = {
  title: '{{rewardName}}',
  owner: 'by {{name}}',
  points: '+{{points}} points',
  redeemed: 'Redeemed',
  tabs: {
    overview: 'OVERVIEW',
    license: 'LICENSE',
    faq: 'FAQ',
    support: 'SUPPORT',
    terms: 'TERMS & CONDITIONS'
  },
  buttons: {
    redeem: 'REDEEM WITH {{cost}} POINTS',
    locked: 'INSUFFICIENT POINTS',
    download: {
      word: 'DOWNLOAD WORD',
      pdf: 'DOWNLOAD PDF'
    }
  }
};
