import { useMutation } from '@apollo/client';
import React from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import { LOG_OUT } from '../../../api';
import { handleLogout } from '../../../helpers/routing';
import { logout as authLogout } from '../../../library';

const IdleTimerHandler = () => {
  const [logout] = useMutation(LOG_OUT);

  const handleOnIdle = async () => {
    const isLoggedIn = localStorage.getItem('user_token');
    if (!isLoggedIn) {
      return;
    }
    try {
      await authLogout();
      await logout({ variables: { jwt: localStorage.getItem('user_token') || '' } });
    } catch (error) {
      console.error(error);
    } finally {
      handleLogout();
      localStorage.setItem('timed_out', new Date().getTime());
    }
  };

  return (
    <IdleTimerProvider
      // time: 1 hour in milliseconds
      timeout={1000 * 60 * 60}
      onIdle={handleOnIdle}
      debounce={500}
    />
  );
};

IdleTimerHandler.propTypes = {};

export default IdleTimerHandler;
