import { CRITICALITIES } from '@anirudhm9/base-lib/lib/constants';
import { isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useCriticalities } from '../../../../../hooks';
import { DonutChart } from '../../../../ui';

const CriticalityDonut = ({ breakdown, dashboardView, onEvents }) => {
  const theme = useTheme();
  const { criticalities, loading: criticalitiesLoading } = useCriticalities();

  const [options, setOptions] = useState();
  useEffect(() => {
    if (!breakdown || criticalitiesLoading) {
      return;
    }

    const { palette } = theme || {};
    const { text } = palette || {};

    const labelOptions = dashboardView
      ? {
          show: true,
          color: text?.primary,
          formatter: ({ name, percent }) => `${name} (${Math.round(percent)}%)`,
          width: 30,
          overflow: 'truncate',
          ellipsis: '...'
        }
      : {
          show: true,
          color: text?.primary,
          formatter: ({ name, percent }) => `${name} (${Math.round(percent)}%)`
        };

    const seriesData =
      Object.keys(CRITICALITIES || {}).map((value) => {
        const { name, color } = CRITICALITIES[value] || {};
        const { id } = (criticalities || []).find((criticality) => isIDEqual(criticality.value, value)) || {};

        return {
          id,
          value: breakdown[value] || [],
          name,
          itemStyle: {
            color: palette?.[color]?.main
          }
        };
      }) || [];

    const options = {
      title: {
        show: true,
        text: 'Criticality Breakdown'
      },
      legend: {
        show: true,
        bottom: 0,
        left: 'center',
        type: dashboardView
      },
      series: [
        {
          top: 15,
          bottom: 20,
          name: 'Criticalities',
          selectedMode: 'single',
          selectedOffset: 10,
          label: labelOptions,
          labelLine: {
            show: true
          },
          data: seriesData
        }
      ]
    };

    setOptions(options);
  }, [breakdown, criticalities, criticalitiesLoading, dashboardView, theme]);

  if (!options) {
    return null;
  }
  return <DonutChart options={options} onEvents={onEvents} />;
};

CriticalityDonut.propTypes = {
  breakdown: PropTypes.object,
  dashboardView: PropTypes.string,
  onEvents: PropTypes.object
};

export default CriticalityDonut;
