import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import DotLoader from '../dotLoader';

/**
 * @typedef {import('@mui/material').IconButtonProps & { loading: Boolean }} IconButtonProps Props for `IconButton` from '@mui/material` library
 *
 * @type React.ForwardRefRenderFunction<React.FunctionComponent, IconButtonProps>
 *
 * @returns {React.FC<IconButtonProps>}
 */
const IconButtonEnhanced = forwardRef(({ children, color, onClick, disabled, loading = false, ...props }, ref) => {
  return (
    <IconButton ref={ref} color={color} onClick={onClick} disabled={loading || disabled} {...props}>
      {loading ? <DotLoader sx={{ position: 'relative' }} /> : children}
    </IconButton>
  );
});

IconButtonEnhanced.displayName = 'IconButtonEnhanced';
IconButtonEnhanced.propTypes = {
  children: PropTypes.element,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};
export default IconButtonEnhanced;
