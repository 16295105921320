import { logEvent } from '../../../../../../library';
import { DASHBOARD as DASHBOARD_EVENTS } from '../../../../../../library/amplitude/events/views/app/dashboard';

/**
 * Logs event for user clicking the Overall Task Progress card
 */
const logOverallProgressClick = () => {
  logEvent(DASHBOARD_EVENTS.OVERALL_TASK_PROGRESS_VIEW);
};

/**
 * Logs event for user clicking the 'Open in New' icon for a task in the Overall Task Progress card modal
 *
 * @param {String} value
 * @returns
 */
const logRemediation = (value) => {
  if (!value || !DASHBOARD_EVENTS.OVERALL_TASKS[value]) {
    return;
  }

  logEvent(DASHBOARD_EVENTS.OVERALL_TASKS[value]);
};

export { logOverallProgressClick, logRemediation };
