import { ROLES } from '@anirudhm9/base-lib/lib/constants';
import { PersonOffRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { TooltipEnhanced } from '../../../../global';
import { USER_ROLE_ICON } from './constants';

/**
 * Renders icons for user role values from userRoles array as defined in USER_ROLE_ICON
 * @param {Array} userRoles
 * @param {Boolean} deleted
 * @returns {React.FC<import('@mui/material').IconButtonProps}
 */
const UserRoleIcon = ({ userRoles, deleted }) => {
  if (deleted) {
    return (
      <TooltipEnhanced title='Deleted user'>
        <PersonOffRounded color='error' />
      </TooltipEnhanced>
    );
  }

  if (!userRoles.length) {
    return null;
  }

  const hasAdminRole = userRoles.includes(ROLES.ADMIN.value);
  if (hasAdminRole) {
    return USER_ROLE_ICON.ADMIN.icon;
  }
  return USER_ROLE_ICON.DEFAULT.icon;
};

UserRoleIcon.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string),
  deleted: PropTypes.bool
};

export default UserRoleIcon;
