import { Box, Typography, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useUserContext } from '../../../../contexts';
import { useIsDesktop } from '../../../../hooks';
import InputChange from '../inputChange';
import { COMMAND_OPTIONS, CONFIGURATION, FORMATS, PLACEHOLDER } from './constants';
import './index.css';
import { getMentionsFromEditor, mentionUserValues } from './utils';

const useStyles = makeStyles((theme) => ({
  editor: {
    color: theme.palette.text.primary,
    stroke: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.background.paper, 0.05)
  }
}));

/**
 * HTML editor.
 * @param {object} props
 * @param {String} props.label Field label
 * @param {String} props.placeholder helper text
 * @param {String} [props.defaultValue=''] Initial value displayed in the editor
 * @param {Array} props.users List of users to display and tag users
 * @param {(value: String) => void} props.onChange Function that updates the parent's state
 * @param {Boolean} props.showInputChange displays change checkbox and cancel icons
 * @param {() => void} props.onSave Function that updates the parent's state
 * @param {() => void} props.onCancel Function that updates the parent's state
 */
const HTMLEditor = forwardRef(
  (
    { label, placeholder = PLACEHOLDER, defaultValue = '', onChange, showInputChange, onSave, onCancel, users, showCommands = false, disabled, ...props },
    ref
  ) => {
    const classes = useStyles();
    const { userId } = useUserContext();
    const isDesktop = useIsDesktop();

    /**
     * Initialize the editor's value with the `defaultValue`.
     */
    const [value, setValue] = useState(() => defaultValue);
    const [commandType, setCommandType] = useState();
    const [mentionedUsersIds, setMentionedUsersIds] = useState([]);

    const handleChange = (value, _delta, _source, editor) => {
      try {
        const { users, commandType } = getMentionsFromEditor(editor);
        setMentionedUsersIds(users);
        setCommandType(commandType);

        // Update internal state.
        setValue(value);
        // Update parent state.
        onChange(value);
      } catch (error) {
        console.error(error);
      }
    };

    const handleCancel = useCallback(() => {
      try {
        setValue(defaultValue || '');
        onCancel();
      } catch (error) {
        console.error(error);
      }
    }, [defaultValue, onCancel]);

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          handleCancel();
        },
        getUserMentions: () => {
          return mentionedUsersIds;
        },
        getCommandType: () => {
          return commandType;
        }
      }),
      [commandType, handleCancel, mentionedUsersIds]
    );

    const userOptions = useMemo(() => {
      if (!users?.length || !userId) {
        return [];
      }
      return mentionUserValues(userId, users);
    }, [userId, users]);

    // Modules object for setting up the Quill editor
    const modules = useMemo(() => {
      const configuration = {
        // less icons for mobile view
        toolbar: isDesktop ? CONFIGURATION.DESKTOP.toolbar : CONFIGURATION.MOBILE.toolbar,
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false
        },
        mention: {}
      };

      if (!userOptions?.length) {
        return configuration;
      }

      configuration.mention = {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@', '/'],
        source: (searchTerm, renderList, mentionChar) => {
          let values;
          if (mentionChar === '@') {
            values = userOptions;
          } else if (mentionChar === '/' && showCommands) {
            values = COMMAND_OPTIONS;
          }
          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++) {
              if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                matches.push(values[i]);
              }
            }

            renderList(matches, searchTerm);
          }
        }
      };

      return configuration;
    }, [isDesktop, showCommands, userOptions]);

    return (
      <Box>
        <Typography>{label}</Typography>
        <ReactQuill
          className={classes.editor}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          modules={modules}
          formats={FORMATS}
          preserveWhitespace={true}
          readOnly={disabled}
          {...props}
        />
        {showInputChange && <InputChange onSave={onSave} onCancel={handleCancel} disabled={disabled} />}
      </Box>
    );
  }
);

HTMLEditor.displayName = 'HTMLEditor';
HTMLEditor.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  label: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  showCommands: PropTypes.bool,
  showInputChange: PropTypes.bool,
  disabled: PropTypes.bool
};

export default HTMLEditor;
