import { AccountCircleRounded, AdminPanelSettingsRounded } from '@mui/icons-material';
import { TooltipEnhanced } from '../../../../../global';

const USER_ROLE_ICON = {
  ADMIN: {
    value: 'ADMIN',
    label: 'Admin',
    icon: (
      <TooltipEnhanced title='Admin'>
        <AdminPanelSettingsRounded color='primary' />
      </TooltipEnhanced>
    )
  },
  DEFAULT: {
    value: 'DEFAULT',
    label: 'Read only user',
    icon: (
      <TooltipEnhanced title='Read only user'>
        <AccountCircleRounded color='primary' />
      </TooltipEnhanced>
    )
  }
};

export { USER_ROLE_ICON };
