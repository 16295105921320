import { useTheme } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DotLoader } from '../../../global';
import defaultOptions from './defaultOptions';

const BarChart = ({ options, onEvents, loading }) => {
  const theme = useTheme();

  const [chartOptions, setChartOptions] = useState(defaultOptions);

  useEffect(() => {
    if (!options) {
      return;
    }

    const { palette } = theme || {};
    const { background, text, mode, primary, secondary } = palette || {};
    const newOptions = _.cloneDeep(defaultOptions);

    const { series: defaultSeries, tooltip, xAxis: defaultXAxis = {}, yAxis: defaultYAxis = {} } = newOptions || {};
    const series = defaultSeries[0];

    const { name = '', data = [], xAxis = {}, yAxis = {} } = options || {};
    tooltip.backgroundColor = background?.default;
    tooltip.textStyle = { color: text?.primary };

    series.name = name;
    series.data = data;
    series.label.color = text?.primary;
    series.itemStyle = {
      color: (mode === 'light' ? primary : secondary).main
    };
    const { axisLabel = {} } = xAxis || {};
    axisLabel.color = text?.primary;
    newOptions.xAxis = { ...defaultXAxis, ...xAxis };
    newOptions.yAxis = { ...defaultYAxis, ...yAxis };

    setChartOptions(newOptions);
  }, [options, theme]);

  if (loading) {
    return <DotLoader sx={{ position: 'relative' }} />;
  }

  return <ReactEcharts option={chartOptions} onEvents={onEvents} />;
};

BarChart.propTypes = {
  options: PropTypes.object,
  onEvents: PropTypes.object,
  loading: PropTypes.bool
};

export default BarChart;
