import { logEvent } from '../../../../../library';
import { NEWS as REPORTS_NEWS_EVENTS } from '../../../../../library/amplitude/events/views/app/reports/news';

/**
 * Logs event when user clicks on a news article card
 *
 * @param {Object} article
 */
const logArticleClick = (article) => {
  const { id, title } = article || {};
  logEvent(REPORTS_NEWS_EVENTS.CLICK_ARTICLE, { article_id: id, article_title: title });
};

/**
 * Logs event when user changes page
 *
 * @param {String | Number} value
 * @param {String | Number} page
 */
const logPageChange = (value, page) => {
  logEvent(REPORTS_NEWS_EVENTS.NAVIGATION, { page_prev: page, page_current: value });
};

/**
 * Logs event when user changes number of rows per page
 *
 * @param {String | Number} value
 * @param {String | Number} size
 */
const logRowChange = (value, size) => {
  logEvent(REPORTS_NEWS_EVENTS.CHANGE_ROWS, { old: size, new: value });
};

export { logArticleClick, logPageChange, logRowChange };
