/* eslint-disable quotes */

// components.application.dashboard.widgets.devicesInUse

export const DEVICES_IN_USE = {
  title: 'Mobile Devices In Use',
  description:
    'Keeping track of all the mobile devices that have access to your company information via the email domain helps detect any rogue or unnecessary devices. Please review the owners, inactivity and OS versions to ensure these devices are legitimate.',
  disconnected: {
    description:
      'Information required to provide insights here relies on one or more connections to other systems. Either you have not connected the relevant applications or there is an issue with the connections at the moment.',
    button: 'LINK COMPANY ACCOUNTS'
  },
  buttons: {
    linkAccount: 'LINK COMPANY ACCOUNTS',
    viewAll: 'VIEW ALL'
  }
};
