import { logEvent } from '../../../../../../library';
import { ISSUES as MANAGE_ISSUES_EVENTS } from '../../../../../../library/amplitude/events/views/app/manage/issues';

/**
 * Logs event when user clicks on a single issue to view it's details in the side modal
 *
 * @param {Object} issue
 * @param {Function} handleClick
 */
const logViewIssue = (issue, handleClick) => {
  logEvent(MANAGE_ISSUES_EVENTS.VIEW_SINGLE_ISSUE, { issue_id: issue?.id });
  handleClick(issue);
};

/**
 * Logs event when user clicks the Open In New icon to open the View Issue page in another tab
 *
 * @param {Object} selectedIssue
 * @param {VoidFunction} navigate
 */
const logOpenNewTab = (selectedIssue, navigate) => {
  logEvent(MANAGE_ISSUES_EVENTS.NEW_PAGE, { issue_id: selectedIssue?.id });
  navigate(`/app/manage/issues/${selectedIssue?.id}`); //TODO: convert back to open in new tab and try localStorage for context for event log
};

/**
 * Logs event when user changes the page via pagination
 *
 * @param {String | Number} value
 * @param {String | Number} page
 */
const logPageChange = (value, page) => {
  logEvent(MANAGE_ISSUES_EVENTS.NAVIGATION, { page_prev: page, page_current: value });
};

/**
 * Logs event when user changes the number of rows via pagination
 *
 * @param {String | Number} value
 * @param {String | Number} prevValue
 */
const logRowChange = (value, prevValue) => {
  logEvent(MANAGE_ISSUES_EVENTS.ROWS_PER_PAGE, { rows_prev: prevValue, rows_current: value });
};

/**
 * Logs event when user closes the side modal that shows a selected issues details
 *
 * @param {Object} selectedIssue
 * @param {Function} handleClick
 */
const logModalClose = (selectedIssue, handleClick) => {
  logEvent(MANAGE_ISSUES_EVENTS.CLOSE_MODAL, { issue_id: selectedIssue?.id });
  handleClick();
};

export { logViewIssue, logOpenNewTab, logPageChange, logRowChange, logModalClose };
