// views.app.manage.devices.default

export const DEFAULT_DEVICES = {
  title: 'Devices',
  description:
    'These are the devices that you should be managing. They may have been manually created, or synchronized via one of the enabled integrations (e.g. Google Workspace). Ensure you can account for each device listed, including device owners.',
  cardTitle: 'List of Devices',
  cardSubtitle: 'All detected and manually added Devices.',
  buttons: {
    add: 'Add device'
  }
};
