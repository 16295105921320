import { MoreVertRounded } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
import { useRef, useState } from 'react';
import { IconButtonEnhanced, MenuList } from '../../components/global';

const useStyles = makeStyles((theme) => ({
  menuList: {
    zIndex: 1
  },
  header: {
    '& .Mui-expanded': {
      border: `1px solid ${theme.palette.primary.main}`
    }
  }
}));

/**
 * Custom Accordion component
 *
 * @typedef {import('@mui/material').CardProps} CardProps Props for `Card` from '@mui/material` library
 * @typedef {import('@mui/material').CardHeaderProps} CardHeaderProps Props for `CardHeader` from '@mui/material` library
 * @typedef {import('@mui/material').MenuProps} MenuProps Props for `Menu` from '@mui/material` library
 *
 * @param {{ menuItems: [{ id: String | Number, label: String | JSX, onClick: Function, component: String, icon: Icon }], headerProps: CardHeaderProps, menuProps: MenuProps, loading: Boolean } & CardProps} props loading state for Spinner
 * @returns {React.FC<CardProps>}
 */

const AccordionEnhanced = ({ header, icon, menuItems, children, summaryProps, detailProps, ...props }) => {
  const classes = useStyles();

  const menuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = (event) => {
    try {
      event.stopPropagation();
      setMenuOpen((open) => !open);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Accordion elevation={2} {...props}>
      <AccordionSummary aria-controls='accordian-content' id='accordian-header' {...summaryProps}>
        {icon && (
          <Grid item xs={6} md={1}>
            {icon}
          </Grid>
        )}
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={12}>
            {header}
          </Grid>
          {menuItems && (
            <Grid container alignItems='flex-start' justifyContent='flex-end' item xs={1}>
              <IconButtonEnhanced aria-label='more-options' aria-haspopup='true' onClick={toggleMenu} ref={menuRef} size='large'>
                <MoreVertRounded />
              </IconButtonEnhanced>
              <MenuList id={'menu-popper'} open={menuOpen} setOpen={setMenuOpen} anchorRef={menuRef} items={menuItems} className={classes.menuList} />
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails {...detailProps}>{children}</AccordionDetails>
    </Accordion>
  );
};

AccordionEnhanced.propTypes = {
  header: PropTypes.node,
  icon: PropTypes.node,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ),
  children: PropTypes.node,
  summaryProps: PropTypes.object,
  detailProps: PropTypes.object
};
export default AccordionEnhanced;
