import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useUserContext } from '../../../../contexts';
import CriticalityAndStatusProvider from '../../../../contexts/criticalityAndStatusContext';
import DevicesProvider from '../../../../contexts/devicesContext';

import { RoleProtectedRoute } from '../../../../helpers/routing';
import DefaultIssues from './default';
import Issue from './single';

const Issues = () => {
  const { pathname } = useLocation();
  const { roles } = useUserContext();

  return (
    <div className='dashboard-wrapper'>
      <CriticalityAndStatusProvider>
        <DevicesProvider>
          <Routes>
            <Route path='/' element={<RoleProtectedRoute roles={roles} path={pathname} element={DefaultIssues} />} />
            <Route path='/:id' element={<RoleProtectedRoute roles={roles} path={pathname} element={Issue} />} />
            <Route path='/add' element={<RoleProtectedRoute roles={roles} path={pathname} element={Issue} />} />
            <Route path='*' element={<RoleProtectedRoute roles={roles} path={pathname} element={DefaultIssues} />} />
          </Routes>
        </DevicesProvider>
      </CriticalityAndStatusProvider>
    </div>
  );
};

Issues.propTypes = {};

export default Issues;
