import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { Box, Collapse, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import MasonryLayout from '../../../../../views/app/dashboard/masonryLayout';
import { IconButtonEnhanced } from '../../../../global';
import Category from '../category';
import Chart from '../chart';
import Remediation from '../remediation';
import { logHideCategories } from '../utils';

const Body = ({ category, level, expanded }) => {
  const [open, setOpen] = useState(!!expanded);

  const toggleOpen = useCallback(() => {
    logHideCategories(!open);
    setOpen((open) => !open);
  }, [open]);

  const Component = useCallback(() => {
    const { children, questions } = category || {};

    if ((children || []).length) {
      return (
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} justifyContent='center'>
            <Box sx={{ height: '250px', width: '100%' }}>
              <Chart category={category} />
            </Box>
          </Grid>
          <Grid container item xs={12} justifyContent='center'>
            <IconButtonEnhanced onClick={toggleOpen}>{open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}</IconButtonEnhanced>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={open}>
              <Grid container item spacing={2}>
                <MasonryLayout columns={2} xs={12} md={6}>
                  {children.map((child) => {
                    return <Category category={child} level={level + 1} key={child.id} />;
                  })}
                </MasonryLayout>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      );
    }

    if ((questions || []).length) {
      return (
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Remediation category={category} />
          </Grid>
        </Grid>
      );
    }
    return null;
  }, [category, level, open, toggleOpen]);

  return (
    <Grid container item spacing={2}>
      <Component />
    </Grid>
  );
};

Body.propTypes = {
  expanded: PropTypes.bool,
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object),
    questions: PropTypes.arrayOf(PropTypes.object)
  }),
  level: PropTypes.number
};

export default Body;
