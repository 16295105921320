import { KBS_TYPES } from '../../kbs/kbsChip/constants';

export const TRANSLATION_PATH = (route) => {
  return `components.application.reports.applications.manualAddApp.${route}`;
};

export const REQUIRED_FIELDS = ['name', 'customName', 'kbs', 'website', 'org'];

export const DEFAULT_APPLICATION_DATA = {
  name: '',
  customName: '',
  inactiveUsers: [],
  note: '',
  kbs: KBS_TYPES.NONE.value,
  informationHeld: [],
  users: [],
  website: '',
  org: ''
};

export const MANUAL_OPTION = {
  id: '0',
  value: 'MANUAL_OPTION',
  name: ''
};
