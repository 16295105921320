import { ListItemIcon, ListItemText, MenuList as Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { MenuContainer } from '../../ui';

/**
 * @typedef {import('@mui/material').Icon} Icon
 * @typedef {HTMLStyleElement} StyleProps
 *
 * @param {Boolean} open
 * @param {Function} setOpen
 * @param {import('react').AnchorHTMLAttributes} anchorRef
 * @param {[{ id: String | Number, label: String | JSX, onClick: Function, component: String, icon: Icon }]} items
 * @param {StyleProps} style
 * @returns {JSX}
 */
const MenuList = ({ open, setOpen, anchorRef, items = [], style }) => {
  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };
  const handleClose = (event, onClick) => {
    onClick && onClick();

    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <MenuContainer anchorRef={anchorRef} open={open} setOpen={setOpen} handleClose={handleClose} style={{ ...style }}>
      <Menu autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown} style={{ ...style }}>
        {items?.map((item, index) => (
          <MenuItem
            onClick={(event) => {
              handleClose(event, item?.onClick);
            }}
            key={index}
            {...(item?.onClick && {
              onClick: item?.onClick
            })}
            component={item?.component || 'div'}
            to={item?.to}
          >
            <ListItemIcon>
              <i className='material-icons-round'>{item?.icon}</i>
            </ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </MenuContainer>
  );
};

MenuList.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  anchorRef: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  style: PropTypes.object
};

export default MenuList;
