import { useTheme } from '@mui/styles';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { betaMode } from '../../../constants/defaults';

const Watermark = ({ children, text = 'ComingSoon' }) => {
  const hideComingSoonWatermark = betaMode;
  const theme = useTheme();
  const { palette, shape } = theme;

  if (hideComingSoonWatermark) {
    return children;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          zIndex: '99',
          position: 'absolute',
          top: '0',
          left: '0',
          opacity: '0.4',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <svg viewBox='0 0 100 100' width='100%' height='100%' style={{ background: 'rgba(0, 0, 0, 0.25)', borderRadius: shape.borderRadius }}>
          <text x='50%' y='50%' dominantBaseline='middle' fill={palette.text.primary} textAnchor='middle' transform='translate(-20, 45) rotate(-45)'>
            {text}
          </text>
        </svg>
      </Box>
      {children}
    </Box>
  );
};

Watermark.propTypes = {
  children: PropTypes.any,
  text: PropTypes.string
};

export default Watermark;
