/* eslint-disable quotes */
// components.application.rewards.redeem.redemptionModal

export const REDEMPTION_MODAL = {
  title: 'Redeem {{rewardName}}?',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..',
  balance: 'Your current balance: {{balance}}',
  cost: 'Cost of template: {{cost}}',
  buttons: {
    redeem: 'USE {{cost}} POINTS',
    cancel: 'CLOSE'
  },
  redeemed: {
    success: 'Success!',
    description: '{{cost}} points has been successfully deducted from your total points balance. The template is now available for download.',
    newBalance: 'Updated Points Balance: {{balance}}'
  }
};
