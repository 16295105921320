import { CheckCircleRounded, LockRounded, Star } from '@mui/icons-material';

export const TRANSLATION_PATH = (route) => {
  return `components.application.rewards.pointsChip.${route}`;
};

export const STATES = {
  DEFAULT: { label: 'cost', icon: <Star fontSize='small' color='secondary' /> },
  LOCKED: { label: 'locked', icon: <LockRounded fontSize='small' color='grey' /> },
  CLAIMED: { label: 'claimed', icon: <CheckCircleRounded fontSize='small' color='success' /> },
  REDEEMED: { label: 'redeem', icon: <CheckCircleRounded fontSize='small' color='success' /> }
};
