import { gql } from '@apollo/client';

export const GET_FULL_FRAMEWORK_ASSESSMENT = gql`
  query framework($id: ID!) {
    framework(id: $id) {
      id
      name
      description
      category {
        id
        name
        level
        root
        parent {
          id
        }
        children {
          id
          mappingNumber
          index
          level
          name
          description
          parent {
            id
          }
          questions {
            id
            mappingNumber
            index
            name
            description
            type
            other
            archived
            options {
              id
              name
              type
              mapping
            }
            parent {
              id
            }
            issues {
              id
            }
          }
          children {
            id
            mappingNumber
            index
            level
            name
            description
            parent {
              id
            }
            questions {
              id
              mappingNumber
              index
              name
              description
              type
              other
              archived
              options {
                id
                name
                type
                mapping
              }
              parent {
                id
              }
              issues {
                id
              }
            }
            children {
              id
              mappingNumber
              index
              level
              name
              description
              parent {
                id
              }
              questions {
                id
                mappingNumber
                index
                name
                description
                type
                other
                archived
                options {
                  id
                  name
                  type
                  mapping
                }
                parent {
                  id
                }
                issues {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FRAMEWORK_ASSESSMENT = gql`
  query framework($id: ID!) {
    framework(id: $id) {
      id
      name
      description
      category {
        id
        mappingNumber
        name
        level
        root
        parent {
          id
        }
        children(sort: "index:ASC", where: { _or: [{ archived: false }, { archived_null: true }] }) {
          id
          mappingNumber
          index
          level
          name
          description
          parent {
            id
          }
          questions(where: { _or: [{ archived: false }, { archived_null: true }] }) {
            id
            name
            mappingNumber
            datapoint {
              id
              value
              selected {
                value
                disabled
              }
            }
            criticality {
              id
              name
              value
            }
            remediation
            issues {
              id
            }
          }
          children(sort: "index:ASC", where: { _or: [{ archived: false }, { archived_null: true }] }) {
            id
            mappingNumber
            index
            level
            name
            description
            parent {
              id
            }
            questions(where: { _or: [{ archived: false }, { archived_null: true }] }) {
              id
              name
              mappingNumber
              datapoint {
                id
                value
                selected {
                  value
                }
              }
              criticality {
                id
                name
                value
              }
              remediation
              issues {
                id
              }
            }
            children(sort: "index:ASC", where: { _or: [{ archived: false }, { archived_null: true }] }) {
              id
              mappingNumber
              index
              level
              name
              description
              parent {
                id
              }
              questions(where: { _or: [{ archived: false }, { archived_null: true }] }) {
                id
                name
                mappingNumber
                datapoint {
                  id
                  value
                  selected {
                    value
                  }
                }
                remediation
                issues {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FRAMEWORKS = gql`
  query frameworks($where: JSON) {
    frameworks(where: $where) {
      id
      name
      value
      initialised
    }
  }
`;

export const GET_FRAMEWORK_SCORE_TOTALS = gql`
  query frameworkScoreTotals($org: ID!, $framework: ID) {
    frameworkScoreTotals(org: $org, framework: $framework)
  }
`;

export const GET_FRAMEWORK_BENCHMARK_TOTALS = gql`
  query frameworkBenchmarkTotals($framework: ID) {
    frameworkBenchmarkTotals(framework: $framework)
  }
`;
