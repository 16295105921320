/* eslint-disable quotes */
// components.application.integrations.connectIntegrationModal.addApplication.oAuth

export default {
  title: 'Add {{name}} permissions',
  description:
    'To get the most out of CyberHQ Essentials, we recommend connecting to your {{name}} Admin account so that we can conduct a quick scan and identify security gaps (if any).',
  scope_description: "Here is a quick summary of the permissions we'll be asking for from the Admin account.",
  invite_admin: "Provide your {{name}} Admin's details and we'll invite them to authorize the connection.",
  unauthorised: 'Looks like you do not have the required permissions to authorize this connection. Please try inviting an Admin.',
  privacy_policy: { 1: 'To understand how we protect your data, click ', 2: 'here' },
  buttons: {
    authorise: 'Authorize permissions with {{name}}',
    invite: 'Send invite & continue'
  }
};
