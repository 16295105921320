import { Close } from '@mui/icons-material';
import { alpha, Card, CardContent, CardMedia, Dialog, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { IconButtonEnhanced, TypographyEnhanced } from '../../../../../global';
import PointsChip from '../../../pointsChip';
import AchievementStatus from '../achievementStatus';
import { TRANSLATION_PATH } from './constants/defaults';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '280px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  image: {
    height: '64px',
    margin: 'auto'
  },
  label: {
    fontWeight: 'bold'
  },
  headerBackground: {
    height: '150px',
    background: `linear-gradient(0deg, ${alpha(theme.palette.background.paper, 0.99)} 15%, ${alpha(theme.palette.background.paper, 0)} 100%)`
  },
  headerPadding: {
    paddingLeft: theme.spacing(2)
  },
  button: {
    margin: '5px',
    backgroundColor: theme.palette.grey[300]
  }
}));

const MoreInfoModal = ({ data, open, toggleModal, toggleRedeem }) => {
  const classes = useStyles();

  const { label, claimed, description, image, pointValue } = data || {};

  const pointsChipType = () => {
    if (claimed) {
      return 'CLAIMED';
    }

    return 'DEFAULT';
  };

  return (
    <Dialog open={open} onClose={toggleModal}>
      <Card sx={{ maxWidth: '600px' }}>
        <CardMedia image={image} alt='background-image'>
          <Grid container alignContent='space-between' className={classes.headerBackground}>
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <IconButtonEnhanced size='small' className={classes.button} onClick={() => toggleModal()}>
                  <Close size='small' />
                </IconButtonEnhanced>
              </Grid>
            </Grid>
            <Grid container className={classes.headerPadding}>
              <PointsChip type={pointsChipType()} pointValue={pointValue} />
              <Grid item xs={12}>
                <TypographyEnhanced id={label} variant='h5' className={classes.label} />
              </Grid>
            </Grid>
          </Grid>
        </CardMedia>
        <CardContent sx={{ paddingTop: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyEnhanced id={description} />
            </Grid>
            {/* Objectives */}
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <TypographyEnhanced id={TRANSLATION_PATH('titles.objectives')} className={classes.label} />
              </Grid>
              <Grid item xs={12}>
                <TypographyEnhanced id={description} />
              </Grid>
            </Grid>
            {/* Beanchmark */}
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <TypographyEnhanced id={TRANSLATION_PATH('titles.benchmark')} className={classes.label} />
              </Grid>
              <Grid item xs={12}>
                <TypographyEnhanced id={description} />
              </Grid>
            </Grid>
            {/* Progress */}
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <TypographyEnhanced id={TRANSLATION_PATH('titles.progress')} className={classes.label} />
              </Grid>
              <AchievementStatus data={data} type='detailed' onClaim={toggleRedeem} closeModal={toggleModal} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
};

MoreInfoModal.propTypes = {
  data: PropTypes.object.isRequired,
  status: PropTypes.string,
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  toggleRedeem: PropTypes.func.isRequired
};

export default MoreInfoModal;
