import { gql } from '@apollo/client';

export const LOG_IN = gql`
  mutation login($identifier: String!, $password: String!, $data: JSON!) {
    deviceLogin(input: { identifier: $identifier, password: $password }, data: $data)
  }
`;

export const LOG_OUT = gql`
  mutation logout($id: ID, $jwt: String) {
    logout(id: $id, jwt: $jwt)
  }
`;

export const REGISTER = gql`
  mutation registerWithName(
    $username: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String
    $data: JSON!
    $confirmed: Boolean
    $provider: String!
    $imageUrl: String
  ) {
    registerWithName(
      username: $username
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      data: $data
      confirmed: $confirmed
      provider: $provider
      imageUrl: $imageUrl
    )
  }
`;

export const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      imageUrl
      email
      firstName
      lastName
      tutorial
      provider
      invited
      confirmed
      blocked
      archived
      devices {
        id
        deviceId
        type
        model
        deviceType
        compromisedStatus
        passwordStatus
        encryptionStatus
        firstSync
        lastSync
      }
      profiles {
        id
        userId
        imageUrl
        tz
        type
        admin
        delegateAdmin
        archived
        blocked
        mfaEnabled
        mfaEnforced
        aliases
        createdAt
        lastLogin
      }
      org {
        id
        domain
      }
      orgRoles {
        id
        value
      }
    }
  }
`;

export const GET_USERS = gql`
  query users($where: JSON!) {
    users(where: $where, sort: "firstName") {
      id
      imageUrl
      firstName
      lastName
      email
      invited
      confirmed
      blocked
      archived
      provider
      orgRoles {
        id
        org {
          id
          name
        }
        value
      }
      profiles {
        id
        type
        admin
        delegateAdmin
        archived
        blocked
        mfaEnabled
        mfaEnforced
      }
    }
  }
`;

export const GET_USERS_RESTRICTED = gql`
  query usersRestricted($where: JSON!) {
    usersRestricted(where: $where) {
      id
      firstName
      lastName
      email
      imageUrl
    }
  }
`;

export const GET_ME = gql`
  query getMe {
    getMe {
      id
      imageUrl
      email
      firstName
      lastName
      tutorial
      provider
      invited
      confirmed
      blocked
      archived
      devices {
        id
        deviceId
        type
        model
        deviceType
        compromisedStatus
        passwordStatus
        encryptionStatus
        firstSync
        lastSync
      }
      profiles {
        id
        type
        admin
        delegateAdmin
        archived
        blocked
        mfaEnabled
        mfaEnforced
        aliases
        createdAt
        lastLogin
      }
      org {
        id
        domain
      }
      orgRoles {
        id
        value
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePasswordUsingOldPassword($password: String!, $passwordConfirmation: String!, $oldPassword: String!) {
    changePasswordUsingOldPassword(input: { password: $password, passwordConfirmation: $passwordConfirmation, oldPassword: $oldPassword })
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
    resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
      user {
        id
        email
      }
    }
  }
`;

export const GET_SESSIONS = gql`
  {
    getSessions
  }
`;

export const CREATE_USER = gql`
  mutation createUser($data: UserInput!) {
    createUser(input: { data: $data }) {
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const INVITE_ADMIN = gql`
  mutation inviteAdmin($data: UserInput!, $org: ID!) {
    inviteAdmin(input: $data, org: $org)
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $data: editUserInput!) {
    updateUser(input: { where: { id: $id }, data: $data }) {
      user {
        id
      }
    }
  }
`;

export const UPDATE_ME = gql`
  mutation updateMe($id: ID!, $data: editUserInput!) {
    updateMe(id: $id, data: $data)
  }
`;

export const INVITE_USER = gql`
  mutation inviteUser($user: ID!, $org: ID!) {
    inviteUser(user: $user, org: $org)
  }
`;
