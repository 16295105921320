import { getApplicationUserCount } from '@anirudhm9/base-lib/lib/utils/application';
import { Grid, alpha, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useIntegrationContext } from '../../../../../contexts';
import { useApplications, useIsDesktop } from '../../../../../hooks';
import { ButtonEnhanced, DotLoader, TypographyEnhanced } from '../../../../global';
import { DonutChart, WidgetContainer } from '../../../../ui';
import NoDataState from '../noDataState';
import { logChartClick, logViewAll } from './utils';

const PATH = 'components.application.dashboard.widgets.thirdPartyApplications';
const TRANSLATION_PATH = (route) => {
  return `${PATH}.${route}`;
};

const useStyles = makeStyles(() => ({
  donutChart: {
    marginLeft: '-15px !important'
  }
}));

const ThirdPartyApplications = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const { loading: integrationLoading } = useIntegrationContext();

  const { applications, loading: applicationsLoading, error: applicationsError } = useApplications();
  const [chartOptions, setChartOptions] = useState();

  const sortedApplications = useMemo(() => {
    if (applicationsLoading || applicationsError) {
      return [];
    }

    return _.orderBy(
      applications || [],
      [
        function (app) {
          return getApplicationUserCount(app);
        }
      ],
      ['desc']
    );
  }, [applications, applicationsError, applicationsLoading]);

  useEffect(() => {
    if (applicationsLoading || applicationsError) {
      return;
    }

    const { palette } = theme || {};
    const { text } = palette || {};

    const top5Apps = sortedApplications.slice(0, 5);

    const donutData = top5Apps?.map((app, index) => {
      const totalUsers = app?.users?.length + app?.inactiveUsers?.length;
      return {
        id: app?.id,
        name: app?.thirdparty?.name,
        value: totalUsers || 0,
        itemStyle: {
          color: alpha(palette[palette.mode === 'light' ? 'primary' : 'secondary'].main, (5 - index) * 0.2)
        },
        label: {
          color: palette.text.primary
        }
      };
    });
    //TODO: Have commented out the mobile view bar chart stuff in favour of downscaling the donut chart for consistency
    //TODO: Let me know what you prefer and will adjust/push
    // let options;
    // isDesktop ?
    //donut options (for desktop views)
    const options = {
      series: [
        {
          name: t(TRANSLATION_PATH('title')),
          data: donutData,
          roseType: 'area',
          top: '20',
          bottom: '0',
          radius: isDesktop ? ['5%', '80%'] : ['5%', '50%'],
          labelLine: {
            show: true
          },
          label: {
            show: true,
            position: 'outside',
            overflow: 'break',
            width: 100,
            color: text?.primary
          }
        }
      ]
    };
    // : //bar chart options for mobile views
    //   (options = {
    //     legend: {
    //       show: false
    //     },
    //     yAxis: {
    //       type: 'category',
    //       show: true,
    //       inverse: true,
    //       zlevel: 2
    //     },
    //     series: top5Apps.map((app, i) => {
    //       const color: alpha(palette[palette.mode === 'light' ? 'primary' : 'secondary'].main, (5 - i) * 0.2)
    //       const totalUsers = app?.users?.length + app?.inactiveUsers?.length;
    //       return {
    //         name: app?.thirdparty?.name,
    //         type: 'bar',
    //         data: [{ id: app?.id, name: app?.thirdparty?.name, value: totalUsers, itemStyle: { color: palette[color].main } }],
    //         label: {
    //           show: true,
    //           position: 'insideLeft',
    //           distance: 5,
    //           formatter: '{a}',
    //           fontSize: '15'
    //         },
    //         showBackground: true,
    //         backgroundStyle: {
    //           color: '#Dde1e6'
    //         }
    //       };
    //     })
    //   });
    setChartOptions(options);
  }, [isDesktop, applicationsError, applicationsLoading, sortedApplications, t, theme]);

  if (applicationsError) {
    return <NoDataState path={PATH} navigateTo='/app/integrations' />;
  }

  return (
    <WidgetContainer title={TRANSLATION_PATH('title')}>
      <Grid item xs={12}>
        <TypographyEnhanced id={TRANSLATION_PATH('description')} />
      </Grid>

      {applicationsLoading || integrationLoading ? (
        <Grid item xs={12} m={2}>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.donutChart}>
          {chartOptions && <DonutChart options={chartOptions} onEvents={{ click: (event) => logChartClick(event, navigate) }} />}
        </Grid>
      )}

      <Grid container justifyContent='flex-end' item>
        <ButtonEnhanced variant='outlined' size='small' onClick={() => logViewAll(navigate)}>
          <TypographyEnhanced id={TRANSLATION_PATH('buttons.viewAll')} />
        </ButtonEnhanced>
      </Grid>
    </WidgetContainer>
  );
};

export default ThirdPartyApplications;
