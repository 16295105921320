import { DevicesRounded, PeopleAltRounded, WarningAmber, WidgetsRounded } from '@mui/icons-material';

const MANAGE_SUBS = [
  {
    id: 'manage_users',
    icon: <PeopleAltRounded />,
    label: 'Users',
    to: '/app/manage/users',
    viewEvent: 'manage_users-page_visited'
  },
  {
    id: 'manage_applications',
    icon: <WidgetsRounded />,
    label: 'Applications',
    to: '/app/manage/applications',
    viewEvent: 'manage_applications-page_visited'
  },
  {
    id: 'manage_issues',
    icon: <WarningAmber />,
    label: 'Issues',
    to: '/app/manage/issues',
    viewEvent: 'manage_issues-page_visited'
  },
  {
    id: 'manage_devices',
    icon: <DevicesRounded />,
    label: 'Devices',
    to: '/app/manage/devices',
    viewEvent: 'manage_devices-page_visited'
  }
];
export default MANAGE_SUBS;
