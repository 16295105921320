import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ButtonEnhanced, DrawerEnhanced, TypographyEnhanced } from '../../../../../components/global';
import { useThreatModellingContext } from '../../../../../contexts';
import UsersProvider from '../../../../../contexts/usersContext';
import { Question } from '../../cyber/question';

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    maxWidth: '60vw',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw'
    },
    height: '100%',
    padding: theme.spacing(2)
  }
}));

const ThreatModellingQuestionModal = () => {
  const classes = useStyles();

  const { selectedQuestionId, setSelectedQuestionId } = useThreatModellingContext();

  const toggle = () => {
    setSelectedQuestionId();
  };

  return (
    <UsersProvider>
      <DrawerEnhanced
        open={!!selectedQuestionId}
        toggle={toggle}
        title={<TypographyEnhanced id={name} variant='h5' />}
        header={false}
        containerProps={{
          className: classes.drawerContainer
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            {selectedQuestionId && (
              <Question
                id={selectedQuestionId}
                // searchTerm={searchTerm}
              />
            )}
          </Grid>
          <Grid container item xs={12} justifyContent='flex-end'>
            <ButtonEnhanced onClick={toggle} variant='outlined'>
              Cancel
            </ButtonEnhanced>
          </Grid>
        </Grid>
      </DrawerEnhanced>
    </UsersProvider>
  );
};

ThreatModellingQuestionModal.propTypes = {};

export default ThreatModellingQuestionModal;
