const DEFAULT_OPTIONS = {
  tooltip: {},
  radar: {
    // shape: 'circle',
    indicator: []
  },
  series: [
    {
      name: '',
      type: 'radar',
      data: []
    }
  ]
};

export default DEFAULT_OPTIONS;
