export const TABLE_HEADERS = ['MFA Status', 'No. of Accounts'];

export const COLORS = {
  CHART: {
    ENABLED: '#A1D5B3',
    NOT_ENABLED: '#EBA1A9'
  },
  TEXT: {
    ENABLED: '#009688',
    NOT_ENABLED: '#D32F3F'
  }
};

export const PATH = 'components.application.dashboard.widgets.multiFactorAuth';
export const TRANSLATION_PATH = (route) => {
  return `${PATH}.${route}`;
};
