import { gql } from '@apollo/client';

export const GET_QUESTION_WITH_CUSTOM_VALUES = gql`
  query getCustomQuestion($id: ID!) {
    getCustomQuestion(id: $id)
  }
`;

export const GET_QUESTION = gql`
  query question($id: ID!) {
    question(id: $id) {
      id
      index
      mappingNumber
      name
      description
      type
      scale
      datapoint {
        id
        answer
        other
        attachments
        selected {
          id
          name
          disabled
        }
      }
      options(sort: "score:ASC") {
        id
        name
        type
        value
      }
      parent {
        id
        name
        description
      }
    }
  }
`;

export const GET_QUESTIONS = gql`
  query questions($where: JSON) {
    questions(where: $where) {
      id
      index
      mappingNumber
      name
      description
      type
      scale
      datapoint {
        id
        answer
        other
        attachments
        notApplicable
        selected {
          id
          name
          disabled
        }
      }
      options(sort: "score:ASC") {
        id
        name
        type
        value
        score
        customScore
      }
      parent {
        id
      }
    }
  }
`;

export const SAVE_ANSWER = gql`
  mutation saveAnswer($id: ID!, $org: ID!, $datapoint: DatapointInput!, $options: JSON) {
    saveAnswer(id: $id, org: $org, datapoint: $datapoint, options: $options)
  }
`;

export const CREATE_ANSWER_OPTIONS_FOR_QUESTIONS = gql`
  mutation createAnswerOptions($id: ID!, $org: ID!, $type: String, $options: JSON) {
    createAnswerOptions(input: { id: $id, org: $org, type: $type, options: $options })
  }
`;

export const UPDATE_QUESTION_ASSIGNEES = gql`
  mutation updateAssignees($id: ID!, $assignees: [ID]!) {
    updateAssignees(id: $id, assignees: $assignees)
  }
`;
