const MDN = 'https://developer.mozilla.org/en-US/docs';
const MDN_WEB_HEADERS = `${MDN}/Web/HTTP/Headers`;

const HEADERS = {
  'HTTP/2': {
    name: 'HTTP/2',
    remediation: {
      name: '',
      link: `${MDN}/Glossary/HTTP_2`
    }
  },
  'x-nf-request-id': {
    name: 'HTTP/2',
    remediation: {
      name: '',
      link: 'https://http.dev/x-request-id'
    }
  },
  'x-frame-options': {
    name: 'X-Frame-Options',
    remediation: {
      name: '',
      link: `${MDN_WEB_HEADERS}/X-Frame-Options`
    }
  },
  'content-security-policy': {
    name: 'Content-Security-Policy',
    remediation: {
      name: '',
      link: `${MDN_WEB_HEADERS}/Content-Security-Policy`
    }
  },
  'x-content-type-options': {
    name: 'X-Content-Type-Options',
    remediation: {
      name: '',
      link: `${MDN_WEB_HEADERS}/X-Content-Type-Options`
    }
  },
  'referrer-policy': {
    name: 'Referrer-Policy',
    remediation: {
      name: '',
      link: `${MDN_WEB_HEADERS}/Referrer-Policy`
    }
  },
  'permissions-policy': {
    name: 'Permissions-Policy',
    remediation: {
      name: '',
      link: `${MDN_WEB_HEADERS}/Permissions-Policy`
    }
  }
};

module.exports = { MDN_WEB_HEADERS, HEADERS };
