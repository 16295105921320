import { DevicesRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { ChipEnhanced, TypographyEnhanced } from '../../../../global';

const DevicesHeader = ({ name, total }) => {
  return (
    <Grid container justifyContent={'space-between'} alignItems='center'>
      <Grid item>
        <TypographyEnhanced id={name} />
      </Grid>

      <Grid item>
        <ChipEnhanced id={String(total)} icon={<DevicesRounded />} size='small' />
      </Grid>
    </Grid>
  );
};
DevicesHeader.propTypes = {
  name: PropTypes.string,
  total: PropTypes.number
};

export default DevicesHeader;
