import { STATUS_CODES } from '@anirudhm9/base-lib/lib/constants';
import { Article, PictureAsPdf } from '@mui/icons-material';
import { Card, CardContent, Dialog, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useLogEvent } from '../../../../../hooks';
import { logEvent } from '../../../../../library';
import { ButtonEnhanced, toast, TypographyEnhanced } from '../../../../global';
import { LOG_EVENT_PATH, TRANSLATION_PATH } from './constants/defaults';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(5)
  },
  buttonGroup: {
    marginTop: theme.spacing(2)
  }
}));

const DownloadModal = ({ modalOpen = false, setModalOpen, data }) => {
  useLogEvent(LOG_EVENT_PATH('VIEWED_MODAL'));
  const classes = useStyles();

  const { id, label } = data;

  const closeModal = () => {
    logEvent(LOG_EVENT_PATH('CLOSED_MODAL'));
    setModalOpen();
  };

  const downloadFile = (type) => {
    logEvent(LOG_EVENT_PATH('DOWNLOAD_REWARD'), { type, rewardId: id });

    try {
      // download the file
    } catch (error) {
      toast.error(STATUS_CODES.ERROR.REDEEM_FAILED.message);
    }
  };

  return (
    <Dialog open={modalOpen}>
      <Card className={classes.card}>
        <CardContent>
          <Grid item xs={12} container spacing={3} alignItems='center' justifyContent='center'>
            <Grid item xs={12}>
              <TypographyEnhanced id={TRANSLATION_PATH('title')} value={{ name: label }} textAlign='center' variant='h6' />
            </Grid>
            <Grid item xs={12}>
              <TypographyEnhanced id={TRANSLATION_PATH('description')} textAlign='center' />
            </Grid>
          </Grid>
          <Grid container justifyContent='center' spacing={2} className={classes.buttonGroup}>
            <Grid item>
              <ButtonEnhanced color='secondary' startIcon={<Article />} onClick={() => downloadFile('word')}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.word')} variant='body1' />
              </ButtonEnhanced>
            </Grid>
            <Grid item>
              <ButtonEnhanced color='primary' startIcon={<PictureAsPdf />} onClick={() => downloadFile('pdf')}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.pdf')} variant='body1' />
              </ButtonEnhanced>
            </Grid>
          </Grid>
          <Grid container justifyContent='center' spacing={2} className={classes.buttonGroup}>
            <Grid item>
              <ButtonEnhanced variant='outlined' onClick={() => closeModal()}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.close')} variant='body1' />
              </ButtonEnhanced>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
};

DownloadModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  data: PropTypes.any
};

export default DownloadModal;
