export const logRocketId = process.env.REACT_APP_LOG_ROCKET_ID || '';
export const appName = process.env.REACT_APP_NAME || 'React-App';
export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
const localTheme = localStorage.getItem('theme') && JSON.parse(localStorage.getItem('theme'));
export const isDarkModeActive = localTheme?.palette?.mode === 'dark';
export const defaultDirection = 'ltr';
export const logoutUri = `${window.location.origin}/user/login`;
export const fileDownloadLink = (hash) => `${apiEndpoint}/upload/securefile/${hash}`;
export const experimentalMode = localStorage.getItem('experimental') === 'true';
export const betaMode = localStorage.getItem('beta');
