export const getPreselectedValues = (selectOptions, selectedFilters) => {
  //flatten out all filter options
  const selectedFiltersFlat = Object.values(selectedFilters).flat(1);
  //get option values from select option objects
  const optionValues = selectOptions.map((option) => {
    return option.value;
  });
  //check for intersection of values between filtersFlat and options
  const preselectedValues = optionValues.filter((item) => selectedFiltersFlat.includes(item));
  return preselectedValues;
};
