import { Handyman } from '@mui/icons-material';
import { Grid, LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { logEvent } from '../../../../../../../../library';
import { ButtonEnhanced, TypographyEnhanced } from '../../../../../../../global';
import { LOG_EVENT_PATH } from '../../../constants/defaults';
import { TRANSLATION_PATH } from '../constants/defaults';

const InProgress = ({ data, percentComplete, closeModal, type = 'basic' }) => {
  const navigate = useNavigate();

  const { label, route } = data || {};

  const handleAction = () => {
    logEvent(LOG_EVENT_PATH('ACTION_BUTTON'), { achievement: label });
    navigate(route || '');
  };

  switch (type) {
    case 'basic':
      return (
        <Grid item xs={12}>
          <LinearProgress variant='determinate' value={percentComplete} color='secondary' />
          <TypographyEnhanced id={TRANSLATION_PATH('inProgress.inProgress')} variant='caption' paragraph align='center' style={{ margin: '0' }} />
        </Grid>
      );
    default: //detailed
      return (
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12} container alignItems='center'>
            <Grid item>
              <Handyman fontSize='small' color='secondary' />
            </Grid>
            <Grid item>
              <TypographyEnhanced
                id={TRANSLATION_PATH('inProgress.progressBar')}
                value={{ percent: 100 - percentComplete }}
                variant='caption'
                paragraph
                align='center'
                style={{ margin: '0' }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress variant='determinate' value={percentComplete} color='secondary' />
            <TypographyEnhanced id={TRANSLATION_PATH('inProgress.inProgress')} variant='caption' paragraph />
          </Grid>
          <Grid item xs={12} container spacing={2} justifyContent='flex-end'>
            <Grid item>
              <ButtonEnhanced variant='text' onClick={() => closeModal()}>
                <TypographyEnhanced id={TRANSLATION_PATH('common.close')} />
              </ButtonEnhanced>
            </Grid>
            <Grid item>
              <ButtonEnhanced variant='contained' onClick={() => handleAction()}>
                <TypographyEnhanced id={TRANSLATION_PATH('common.goToTask')} />
              </ButtonEnhanced>
            </Grid>
          </Grid>
        </Grid>
      );
  }
};

InProgress.propTypes = {
  data: PropTypes.object,
  closeModal: PropTypes.func,
  percentComplete: PropTypes.number,
  type: PropTypes.string
};

export default InProgress;
