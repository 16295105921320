import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ButtonEnhanced } from '../../../components/global';

const SecureDownload = ({ open, onClick, toggle, file }) => {
  const handleDownload = () => {
    try {
      onClick();
      toggle();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>File Download</DialogTitle>
      <DialogContent>
        <DialogContentText>Do you want to download this file?</DialogContentText>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Name</Typography>
              </TableCell>
              <TableCell>
                <Typography>{file?.name || 'Unknown File Name'}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Extension</Typography>
              </TableCell>
              <TableCell>
                <Typography>{file?.ext || 'Unknown File Name'}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>From</Typography>
              </TableCell>
              <TableCell>
                <Typography>CyberHQ Essentials</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <ButtonEnhanced variant='text' onClick={toggle}>
          Close
        </ButtonEnhanced>
        <ButtonEnhanced onClick={handleDownload}>Download</ButtonEnhanced>
      </DialogActions>
    </Dialog>
  );
};

SecureDownload.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  file: PropTypes.shape({
    name: PropTypes.string,
    ext: PropTypes.string
  }),
  toggle: PropTypes.func
};

export default SecureDownload;
