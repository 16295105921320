import { Container, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useState } from 'react';
import { GET_DEVICES } from '../../../api';
import { Progress, WelcomeModal } from '../../../components/application/dashboard';
import { IssueStatistics } from '../../../components/application/dashboard/widgets';
import { Heading } from '../../../components/ui';
import { useIntegrationContext, useOrgContext, useUserContext } from '../../../contexts';
import { useUsersContext } from '../../../contexts/usersContext';
import { useApplications, useIsDesktop } from '../../../hooks';
import { TRANSLATION_PATH, WIDGETS } from './constants';
import MasonryLayout from './masonryLayout';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  bold: {
    fontWeight: 'bold'
  }
}));

const Dashboard = () => {
  const isDesktop = useIsDesktop();

  const classes = useStyles();
  const { userId, user, updateMeMutation, refetch: refetchUser } = useUserContext();
  const { orgId } = useOrgContext();
  const { refetch: refetchUsers } = useUsersContext();
  const { refetch: refetchIntegrations } = useIntegrationContext();

  //Fetch all applications from org
  const { refetch: refetchApplications } = useApplications({
    _or: [{ archived: false }, { archived_null: true }],
    org: orgId
  });

  const { tutorial } = user || {};

  const [modalOpen, setModalOpen] = useState(true);

  const toggleModal = async () => {
    try {
      await updateMeMutation({
        variables: {
          id: userId,
          data: { tutorial: { ...tutorial, WELCOME: true } }
        },
        refetchQueries: [{ query: GET_DEVICES, variables: { org: orgId } }]
      });
      refetchUser();
      refetchUsers();
      refetchIntegrations();
      refetchApplications();
    } catch (error) {
      console.error(error);
    }
    setModalOpen((open) => !open);
  };

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      {!tutorial?.WELCOME && <WelcomeModal modalOpen={modalOpen} setModalOpen={toggleModal} />}
      <Grid container spacing={2}>
        <Grid item container spacing={2} justifyContent='space-between'>
          <Grid item xs={12} md={4}>
            <Heading id={TRANSLATION_PATH('title')} value={{ firstName: user.firstName }} description={TRANSLATION_PATH('description')} hideDivider />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Posture />
          </Grid> */}
          <Grid item xs={12} sm={6} md={4}>
            <Progress />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <IssueStatistics />
          </Grid>
        </Grid>

        <Grid item container direction='row' spacing={2}>
          {isDesktop ? (
            <MasonryLayout columns={2} xs={12} md={6}>
              {WIDGETS.map((widget) => {
                const { id, component } = widget || {};
                return <Fragment key={id}>{component}</Fragment>;
              })}
            </MasonryLayout>
          ) : (
            <Grid container spacing={2} item xs={12}>
              {WIDGETS.map((widget) => {
                const { id, component } = widget || {};
                return (
                  <Grid item key={id}>
                    {component}
                  </Grid>
                );
              })}
            </Grid>
          )}

          {/* <Grid item xs={12}>
            <MandatoryFrameworks />
          </Grid> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
