import { Slider } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

/**
 * @typedef {import('@mui/material').SliderProps} SliderProps
 *
 * @param {SliderProps} props
 * @param {number[]} defaultValue
 * @param {{label: string, value: number}[]} marks
 * @param {boolean} disableAutoLabel
 * @param {number[]} value
 * @param {VoidFunction} onChange
 * @param {VoidFunction} onChange
 * @param {number} step
 * @param {boolean} disableSwap
 * @param {number} min
 * @param {number} max
 * @returns {React.FC<SliderProps>}
 */

const SliderEnhanced = ({
  defaultValue,
  marks = [],
  disableAutoLabel = false,
  value,
  onChange,
  onChangeCommitted,
  step = 1,
  disableSwap = true,
  min = 0,
  max = 5,
  ...props
}) => {
  const handleChange = (_event, newValue) => {
    onChange(newValue);
  };

  const localMarks = useMemo(() => {
    if (!marks?.length) {
      return [];
    }

    if (disableAutoLabel) {
      return marks || [];
    }

    return marks.map(({ label, value, ...rest }) => ({ label: label ?? String(value), value, ...rest }));
  }, [disableAutoLabel, marks]);

  const handleChangeCommitted = (_event, newValue) => {
    if (onChangeCommitted) {
      onChangeCommitted(newValue);
    }
  };

  return (
    <Slider
      color='primary'
      marks={localMarks}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      onChangeCommitted={handleChangeCommitted}
      step={step}
      disableSwap={disableSwap}
      valueLabelDisplay='auto'
      min={min}
      max={max}
      {...props}
    />
  );
};

SliderEnhanced.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      railColor: PropTypes.string,
      thumbColor: PropTypes.string
    })
  ),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  step: PropTypes.number,
  disableSwap: PropTypes.bool,
  disableAutoLabel: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number
};
export default SliderEnhanced;
