import { hasAdminRole, isDefaultUser } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { CREATE_USER, UPDATE_ME, UPDATE_USER } from '../../api';
import { useGetMe } from '../../hooks';

const UserContext = createContext();

/**
 *
 * @returns {{
 * user: (JSON),
 * admin: Boolean,
 * defaultUser: Boolean
 * roles: [String],
 * refetch: (variables?: Partial<{user: *}>) => Promise<ApolloQueryResult<any>>,
 * loading: Boolean,
 * error: ApolloError,
 * userId: *,
 * createUserMutation: (variables?: Partial<{user: *}>) => Promise<ApolloQueryResult<any>>,
 * updateUserMutation:(variables?: Partial<{user: *}>) => Promise<ApolloQueryResult<any>>
 * updateMeMutation:(variables?: Partial<{user: *}>) => Promise<ApolloQueryResult<any>>
 * }}
 */
export const useUserContext = () => {
  return useContext(UserContext);
};

const UserProvider = ({ children }) => {
  const { userId, user: userData, loading, error, refetch } = useGetMe();
  const [createUserMutation] = useMutation(CREATE_USER);
  const [updateUserMutation] = useMutation(UPDATE_USER);
  const [updateMeMutation] = useMutation(UPDATE_ME);

  const [user, setUser] = useState({});

  const admin = useMemo(() => hasAdminRole(user), [user]);
  const defaultUser = useMemo(() => isDefaultUser(user), [user]);

  const roles = useMemo(() => {
    if (!user || loading || error) {
      return [];
    }
    // user role values for org
    return user?.orgRoles?.map((role) => role.value) || [];
  }, [error, loading, user]);

  const updateUser = (data) => {
    if (!data || _.isEmpty(data)) {
      return;
    }
    setUser((user) => {
      return { ...user, ...data };
    });
  };

  useEffect(() => {
    if (error || loading) {
      return;
    }
    const user = userData || {};
    updateUser(user);
  }, [error, loading, userData]);

  const value = {
    userId,
    user,
    admin,
    defaultUser,
    roles,
    updateUser,
    loading,
    refetch,
    error,
    createUserMutation,
    updateUserMutation,
    updateMeMutation
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.element
};

export default UserProvider;
