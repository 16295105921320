import _ from 'lodash';
import { openLink } from '../../../../../../helpers/routing';
import { logEvent } from '../../../../../../library';
import { SINGLE_APPLICATION as SINGLE_APPLICATION_EVENTS } from '../../../../../../library/amplitude/events/views/app/manage/singleApplication';

//UTILS

/**
 * Takes property with array of objects and returns array of object ids
 * Allows for comparison between application and localData properties for changes
 *
 * @param {[{old: String | Array, new: String | Array}]} property
 * @returns [Array of IDs]
 */
export const getApplicationPropertyIDs = (property) => {
  const result = property.reduce((result, current) => {
    result.push(current?.id);
    return result;
  }, []);
  return result;
};

//EVENT HANDLERS

/**
 * Logs event when any of the modals is opened
 *
 * @param {string || number} id
 * @param {boolean} secScoreModalOpen
 * @param {boolean} scopesModalOpen
 * @param {boolean} editMode
 */
export const logModalOpen = (id, secScoreModalOpen, scopesModalOpen, editMode) => {
  if (secScoreModalOpen) {
    logEvent(SINGLE_APPLICATION_EVENTS.SECURITY_SCORE_DETAILS, { application_id: id });
  }
  if (scopesModalOpen) {
    logEvent(SINGLE_APPLICATION_EVENTS.SCOPE_TOOLTIP, { application_id: id });
  }
  if (editMode) {
    logEvent(SINGLE_APPLICATION_EVENTS.CLICK_EDIT_APPLICATION, { application_id: id });
  }
};

/**
 * Logs event when user clicks to open an apps homepage
 *
 * @param {string || number} id
 * @param {object} thirdparty
 */
export const logOpenAppHomepage = (id, thirdparty) => {
  logEvent(SINGLE_APPLICATION_EVENTS.VIEW_APPLICATION_HOMEPAGE, { application_id: id, homepage_url: thirdparty?.homepage });
  window.open(thirdparty?.homepage, '_blank');
};

/**
 * Logs event when user clicks to open an apps login page
 *
 * @param {string || number} id
 * @param {object} thirdparty
 * @param {object} localData
 */
export const logOpenAppLogin = (id, thirdparty, localData) => {
  logEvent(SINGLE_APPLICATION_EVENTS.VIEW_APPLICATION_LOGIN, { application_id: id });
  window.open(localData?.website?.link || thirdparty?.website?.link, '_blank');
};

/**
 * Logs event when user clicks on a user chip to view that users details
 *
 * @param {object} user
 * @param {VoidFunction} navigate
 */
export const logViewUser = (user, navigate) => {
  const { id, archived } = user || {};

  archived
    ? logEvent(SINGLE_APPLICATION_EVENTS.VIEW_ARCHIVED_USER, { application_id: id, user_id: id })
    : logEvent(SINGLE_APPLICATION_EVENTS.VIEW_ACTIVE_USER, { application_id: id, user_id: id });

  navigate(`/app/manage/users/${id}`);
};

/**
 * Logs event when user clicks on an article to view the article where it is hosted
 *
 * @param {string || number} id
 * @param {string} link
 * @param {string} title
 */
export const logOpenNewsArticle = (id, link, title) => {
  logEvent(SINGLE_APPLICATION_EVENTS.VIEW_CYBER_NEWS, { application_id: id, title: title });
  openLink(link);
};

/**
 * Logs a single amplitude event after Application update event in handleSave function
 * Logs event properties for old and new Application values that have been edited
 */
export const logUpdateAppDetails = (id, application, localData) => {
  const localDataKeys = Object.keys(localData);

  // property: { old: oldValue, new: newValue}
  // includes app id to ensure it gets logged
  const updatedProperties = { application_id: id };

  for (const key of localDataKeys) {
    //loginUrl prop doesn't exist in application, check first
    if (localData?.loginUrl && key === 'loginUrl') {
      updatedProperties[key] = { old: application?.website?.link, new: localData[key] };
      continue;
    }

    //if key doesn't exist in application otherwise, don't log it
    if (!application[key]) {
      continue;
    }

    //if application prop is array, convert array of objects to array of IDs for comparison
    if (Array.isArray(application[key]) && Array.isArray(localData[key]) && typeof application[key][0] !== typeof localData[key][0]) {
      const oldKeyArrayOfIDs = getApplicationPropertyIDs(application[key]);
      //check for equality, if not equal - log changes
      if (!_.isEqual(oldKeyArrayOfIDs, localData[key])) {
        updatedProperties[key] = { old: oldKeyArrayOfIDs, new: localData[key] };
      }
      continue;
    }

    //otherwise check for equality, if not equal - log changes
    if (!_.isEqual(application[key], localData[key])) {
      updatedProperties[key] = { old: application[key], new: localData[key] };
    }
  }

  logEvent(SINGLE_APPLICATION_EVENTS.UPDATE_APPLICATION, updatedProperties);
};
