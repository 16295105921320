import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_DEVICES } from '../../api';

/**
 * Custom hook to encapsulate GET_DEVICES
 * usage example:
 *
 * const { devices, loading, error, refetch } = useDevices()
 *
 * @returns {{devices: (Array), refetch: (variables?: Partial<{criticality: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError}}
 */
const useDevices = (skip) => {
  //Fetch devices from org
  const { data, loading, error, refetch } = useQuery(GET_DEVICES, {
    skip
  });

  const devices = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return data?.devices || [];
  }, [data?.devices, error, loading]);

  return { devices, error, loading, refetch };
};

export default useDevices;
