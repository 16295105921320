const FEEDBACK_TYPES = {
  REQUEST: {
    id: 'REQUEST',
    value: 'REQUEST',
    name: 'Request'
  },
  BUG: {
    id: 'BUG',
    value: 'BUG',
    name: 'Bug'
  }
};

module.exports = { FEEDBACK_TYPES };
