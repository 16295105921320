import PropTypes from 'prop-types';
import ApplicationsFilterProvider from './applicationsContext';
import AssessmentProvider from './assessmentContext';
import PageViewProvider from './logPageViewContext';
import OrgProvider from './orgContext';
import ThreatModellingContextProvider from './threatModellingContext';
import UserProvider from './userContext';

const ContextProvider = ({ children }) => {
  return (
    <UserProvider>
      <OrgProvider>
        <AssessmentProvider>
          <PageViewProvider>{children}</PageViewProvider>
        </AssessmentProvider>
      </OrgProvider>
    </UserProvider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired
};
export default ContextProvider;

export * from './applicationsContext';
export * from './assessmentContext';
export * from './criticalityAndStatusContext';
export * from './devicesContext';
export * from './integrationContext';
export * from './orgContext';
export * from './shadowAssessmentContext';
export * from './threatModellingContext';
export * from './userContext';

export { ApplicationsFilterProvider, ThreatModellingContextProvider };
