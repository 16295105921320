import { gql } from '@apollo/client';

export const CREATE_NOTIFICATION = gql`
  mutation createNotification($data: NotificationInput!) {
    createNotification(input: { data: $data }) {
      notification {
        id
      }
    }
  }
`;

export const GET_USER_NOTIFICATIONS = gql`
  query getNotifications($where: JSON, $start: Int, $limit: Int) {
    notifications(where: $where, start: $start, limit: $limit, sort: "created_at:DESC") {
      id
      type
      data
      seen
      completed
      sender {
        id
        firstName
        lastName
        imageUrl
      }
      created_at
      question {
        id
        parent {
          id
        }
      }
    }
  }
`;

export const COUNT_NOTIFICATIONS = gql`
  query countNotifications {
    countNotifications
  }
`;

export const MARK_AS_READ = gql`
  mutation markAsRead($id: ID!) {
    markAsRead(id: $id)
  }
`;

export const MARK_ALL_AS_READ = gql`
  mutation markAllAsRead {
    markAllAsRead
  }
`;
