import { MobileStepper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ButtonEnhanced } from '../../global';
import { logDotStepNavigation } from './utils';

const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: 'transparent'
  }
}));

const DotsStepper = ({ items = [], activeStep = 0, setActiveStep, onChange, backDisabled, nextDisabled, loading }) => {
  const classes = useStyles();
  const totalItems = useMemo(() => ((items || []).length || 1) - 1, [items]);

  const handleChange = () => {
    try {
      onChange();
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = (event) => {
    logDotStepNavigation(event, activeStep);
    setActiveStep((prevActiveStep) => {
      const newStep = prevActiveStep + 1;
      if (newStep > totalItems) {
        return prevActiveStep;
      }
      return newStep;
    });
    handleChange();
  };

  const handleBack = (event) => {
    logDotStepNavigation(event, activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    handleChange();
  };

  return (
    <MobileStepper
      className={classes.background}
      variant='dots'
      steps={items.length}
      position='static'
      activeStep={activeStep}
      nextButton={
        <ButtonEnhanced size='small' onClick={handleNext} disabled={nextDisabled} loading={loading}>
          {items.length - 1 === activeStep ? 'Done' : 'Next'}
        </ButtonEnhanced>
      }
      backButton={
        <ButtonEnhanced size='small' onClick={handleBack} disabled={backDisabled} loading={loading}>
          Back
        </ButtonEnhanced>
      }
    />
  );
};

DotsStepper.propTypes = {
  items: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  backDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  loading: PropTypes.bool
};

export default DotsStepper;
