import { EVENTS, logEvent } from '../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.user.login.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.USER.LOGIN[event];
};

export const logEventForOAuth = (provider) => {
  let path;
  switch (provider) {
    case 'microsoft.com':
      path = 'MICROSOFT';
      break;
    default:
      path = 'GOOGLE';
  }

  logEvent(LOG_EVENT_PATH(path));
};

export const requiredFields = ['email', 'password'];

export const defaultData = {
  email: '',
  password: ''
};
