import { STATUS_CODES } from '@anirudhm9/base-lib/lib/constants';
import { PhotoCameraRounded } from '@mui/icons-material';
import { Avatar, Badge } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { apiEndpoint } from '../../../../../constants/defaults';
import { useUserContext } from '../../../../../contexts';
import { uploadFile } from '../../../../../library';
import { DotLoader, toast } from '../../../../global';
import { LetterAvatar, UploadIcon } from '../../../../ui';

const AVATAR_CONFIG = { width: 70, height: 70 };

const AvatarEdit = ({ value, onChange, edit }) => {
  const { user, loading: userLoading, error: userError } = useUserContext();

  const [loading, setLoading] = useState();

  const handleUpload = async (files) => {
    if (!edit) {
      return;
    }
    setLoading(true);
    try {
      const attachment = await uploadFile(files?.[0], false);
      const { hash } = attachment || {};
      onChange(`${apiEndpoint}/upload/file/${hash}`);
    } catch (error) {
      console.error(error);
      toast.error(STATUS_CODES.ERROR.UPLOAD_FAILED.message);
    } finally {
      setLoading(false);
    }
  };

  if (!edit) {
    return <LetterAvatar user={user || {}} sx={AVATAR_CONFIG} />;
  }

  if (userError) {
    return null;
  }

  if (userLoading || loading) {
    return <DotLoader sx={{ position: 'relative' }} />;
  }

  return (
    <Badge
      overlap='circular'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <UploadIcon
          icon={
            <Avatar sx={{ width: 28, height: 28, bgcolor: (theme) => theme.palette.primary.main }}>
              <PhotoCameraRounded fontSize='small' />
            </Avatar>
          }
          onUpload={handleUpload}
          fileTypes='image/*'
        />
      }
    >
      {value ? (
        <Avatar imgProps={{ referrerPolicy: 'no-referrer' }} src={value || ''} sx={AVATAR_CONFIG}></Avatar>
      ) : (
        <LetterAvatar user={user} sx={AVATAR_CONFIG} />
      )}
    </Badge>
  );
};

AvatarEdit.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  edit: PropTypes.bool
};

export default AvatarEdit;
