import { toast } from '../../../components/global';

const showNotification = (
  {
    title = 'New notification(s) just arrived.',
    body = '',
    data = { date: new Date() },
    icon = 'https://c1.avertro.com/assets/img/logo.png',
    tag = 'ESSENTIALS_NOTIFICATION',
    vibrate = true,
    silent = false,
    ...props
  },
  onClick
) => {
  const notification = new Notification(title, {
    // message on notification
    body,
    // data in event.target.data
    data,
    // icon to display (link/import)
    icon,
    // if a notification is provided with the same tag, that notification will be replaced instead of creating a new notification
    tag,
    // vibrate on phone
    vibrate,
    // silent notification
    silent,
    ...props
  });
  notification.onclick = onClick;
  return notification;
};

const requestPermissions = (callback) => {
  Notification.requestPermission().then((permissionStatus) => {
    switch (permissionStatus) {
      case 'granted':
        callback();
        break;
      default:
        toast.info('Please allow notifications', {
          vertical: 'top',
          horizontal: 'left'
        });
    }
  });
};

/**
 *
 * Triggers a browser notification if permissions are provided
 *
 * @param {{ title: String,  body: String,  data: JSON, icon: String | JSX, tag: String, vibrate: Boolean, silent: Boolean }} data
 */
const triggerNotification = (data, onClick) => {
  requestPermissions(() => showNotification(data, onClick));
};

export { triggerNotification };
