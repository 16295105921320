import { formatErrorMessage } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ARCHIVE_INTEGRATION } from '../../../../api';
import { defaultMessages } from '../../../../constants';
import { useIntegrationContext } from '../../../../contexts';
import { ButtonEnhanced, toast, TypographyEnhanced } from '../../../global';
import ModalEnhanced from '../../../ui/modal';
import { TRANSLATION_PATH } from './constants';

const DeleteIntegrationModal = ({ modalOpen, setModalOpen, integration }) => {
  const { refetch: refetchIntegrations } = useIntegrationContext();

  const [loading, setLoading] = useState(false);

  const [archive] = useMutation(ARCHIVE_INTEGRATION);
  //TODO: test toast/errors behaving correctly when delete integrations function available
  const archiveIntegration = async () => {
    try {
      setLoading(true);
      await archive({ variables: { id: integration.id } });
      refetchIntegrations();
      toast.success(defaultMessages.defaultSuccess);
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
      setModalOpen(false);
    }
  };

  return (
    <ModalEnhanced modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <Grid container spacing={2} justifyContent='center' direction='column'>
        <Grid item>
          <TypographyEnhanced id={TRANSLATION_PATH('title')} value={{ name: integration?.name }} variant='h6' textAlign='center' />
        </Grid>
        <Grid item>
          <TypographyEnhanced id={TRANSLATION_PATH('description')} textAlign='center' />
        </Grid>
        <Grid item>
          <ButtonEnhanced fullWidth color='error' onClick={archiveIntegration} loading={loading}>
            <TypographyEnhanced id={TRANSLATION_PATH('buttons.delete')} />
          </ButtonEnhanced>
        </Grid>
        <Grid item>
          <ButtonEnhanced fullWidth onClick={() => setModalOpen(false)}>
            <TypographyEnhanced id={TRANSLATION_PATH('buttons.cancel')} />
          </ButtonEnhanced>
        </Grid>
      </Grid>
    </ModalEnhanced>
  );
};

DeleteIntegrationModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  integration: PropTypes.object.isRequired
};

export default DeleteIntegrationModal;
