import { DEVICE_CATEGORY_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { hasDateExpired } from '@anirudhm9/base-lib/lib/utils';
import _ from 'lodash';
import { formatDeviceName } from '../../utils';
import { GROUP_BY_VALUES, SORT_VALUES } from '../constants';
/**
 *
 * @param {Array} devices - array of device objects
 * @param {JSON} filters - nested filters object
 * @returns {Array} - array of device objects
 */
export const filterDevices = (devices, filters = {}) => {
  // Flatten out each filter group object into a single array
  const filterGroupsFlat = Object.values(filters || {}).map((filterGroup) => {
    const filterGroupFlat = Object.values(filterGroup).flat(1);
    return filterGroupFlat;
  });
  // Flatten all filter group arrays into one array of all selected filters
  const filtersFlat = filterGroupsFlat.flat(1);

  // If device passes filter evaluation, device object will be pushed to this array
  const deviceArray = [];
  if ((filtersFlat || []).length) {
    for (const singleDevice of devices) {
      /** FILTER PROPERTIES **/
      const { type, lastSync } = singleDevice || {};
      // Check if it has been > 90 days since device was last synced
      const lastSyncOverdue = hasDateExpired(lastSync, 90);

      /** FILTERS **/
      /** DEVICE_TYPE filter evaluations **/

      if (filtersFlat.includes(type)) {
        deviceArray.push(singleDevice);
        continue;
      }

      /** DEVICE_SYNC filter evaluations **/
      if (filtersFlat.includes('SYNC_OVERDUE') && lastSyncOverdue) {
        deviceArray.push(singleDevice);
        continue;
      }
      if (filtersFlat.includes('SYNC_VALID') && !lastSyncOverdue) {
        deviceArray.push(singleDevice);
        continue;
      }
    }
  }
  // Return array of applications
  return deviceArray;
};

/**
 *
 * @param {Array} devices - array of device objects
 * @param {String} sortValue - string value of enum
 * @returns {Array} - array of device objects
 */
export const sortDevices = (devices, sortValue) => {
  let sortedDevices = _.cloneDeep(devices || []);

  for (const device of sortedDevices) {
    device.name = formatDeviceName(device).toLowerCase();
  }

  switch (sortValue) {
    case SORT_VALUES.SYNC_DATE_ASC.value:
      sortedDevices = _.orderBy(sortedDevices || [], ['lastSync'], ['asc']);
      break;
    case SORT_VALUES.SYNC_DATE_DESC.value:
      sortedDevices = _.orderBy(sortedDevices || [], ['lastSync'], ['desc']);
      break;
    case SORT_VALUES.USERNAME_ASC.value:
      sortedDevices = _.orderBy(sortedDevices || [], ['user.firstName'], ['asc']);
      break;
    case SORT_VALUES.USERNAME_DESC.value:
      sortedDevices = _.orderBy(sortedDevices || [], ['user.firstName'], ['desc']);
      break;
    case SORT_VALUES.DEVICE_NAME_ASC.value:
      sortedDevices = _.orderBy(sortedDevices || [], ['name'], ['asc']);
      break;
    case SORT_VALUES.DEVICE_NAME_DESC.value:
      sortedDevices = _.orderBy(sortedDevices || [], ['name'], ['desc']);
      break;

    default:
    // do nothing
  }

  return sortedDevices;
};

/**
 *
 * @param {Array of Objects} devices
 * @param {String enum value} groupValue
 * @returns Array of objects by category: [ 0: {category: String, deviceArray: Array of of device Objects}, 1: {category: String, deviceArray: Array of of device Objects}]
 */
export const groupDevices = (devices, groupValue) => {
  const devicesCopy = _.cloneDeep(devices || []);
  let result = {};
  switch (groupValue) {
    case GROUP_BY_VALUES.DEVICE_OS.value:
      result = _.groupBy(devicesCopy, (device) => {
        return device?.type;
      });
      break;
    case GROUP_BY_VALUES.DEVICE_SYNC.value:
      result = _.groupBy(devicesCopy, (device) => {
        const { lastSync } = device || {};
        const lastSyncOverDue = hasDateExpired(lastSync, 90);
        if (!lastSyncOverDue) {
          return DEVICE_CATEGORY_TYPES.SYNC_STATUS_CURRENT.value;
        }
        return DEVICE_CATEGORY_TYPES.SYNC_STATUS_OVERDUE.value;
      });
      break;
    case GROUP_BY_VALUES.USER.value:
      result = _.groupBy(devicesCopy, (device) => {
        return device?.user?.id;
      });
      break;
    case GROUP_BY_VALUES.VIEW_ALL.value:
      result = { [groupValue]: devicesCopy };
      break;

    default:
    // do nothing
  }

  return result;
};
