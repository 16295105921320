import { IMAGES, INTEGRATION_TYPES, STATUS_CODES } from '@anirudhm9/base-lib/lib/constants';
import { formatErrorMessage, formatUserName, isEmpty, isIDEqual, skip } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { ArchiveRounded, EditOffRounded, EditRounded, InfoRounded, UnarchiveRounded } from '@mui/icons-material';
import { Avatar, Box, Container, Divider, Grid, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { INVITE_USER, SAVE_ORG_ROLES } from '../../../../../api';
import { SingleAppBar, StatusIcon } from '../../../../../components/application/manage';
import ProfileCard from '../../../../../components/application/manage/users/profileCard';
import UserRoleIcon from '../../../../../components/application/manage/users/userRoleIcon';
import DevicesTable from '../../../../../components/application/manage/users/viewUser/devicesTable';
import {
  ButtonEnhanced,
  CardWithLoader,
  ChipEnhanced,
  DotLoader,
  IconButtonEnhanced,
  toast,
  TooltipEnhanced,
  TypographyEnhanced
} from '../../../../../components/global';
import { Heading, LetterAvatar } from '../../../../../components/ui';
import { SelectEnhanced, ViewEditText } from '../../../../../components/ui/form';
import { defaultMessages } from '../../../../../constants';
import { useOrgContext, useUserContext } from '../../../../../contexts';
import { usePageViewContext } from '../../../../../contexts/logPageViewContext';
import { useApplications, useUserData } from '../../../../../hooks';
import { logEvent } from '../../../../../library';
import { SINGLE_USER as MANAGE_SINGLE_USER_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/singleUser';
import { USERS as MANAGE_USERS_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/users';
import { REQUIRED_FIELDS, ROLE_OPTIONS } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  title: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  deleteIcon: {
    '&.MuiChip-deleteIcon': {
      color: theme.palette.primary.main
    }
  },
  usernameLine: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px'
  },
  addAppButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const ViewUser = () => {
  const { orgId } = useOrgContext();
  const { userId, admin, user: currentUser, roles: currentUserRoles, loading: currentUserLoading, updateUserMutation } = useUserContext();
  const { pageViewLogged, setPageViewLogged } = usePageViewContext();

  const [saveOrgRoles] = useMutation(SAVE_ORG_ROLES);
  const [inviteUser] = useMutation(INVITE_USER);

  const classes = useStyles();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { user: userData, loading: userLoading, refetch: refetchUser, error: userError } = useUserData(id);

  const [userRoles, setUserRoles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState();
  const [loading, setLoading] = useState();
  const [errorFields, setErrorFields] = useState({});
  const [user, setUser] = useState();
  const [editMode, setEditMode] = useState(false);
  const [localData, setLocalData] = useState({});
  const [isImported, setIsImported] = useState(false);

  useEffect(() => {
    if (pageViewLogged) {
      return;
    }
    logEvent(MANAGE_USERS_EVENTS.VIEW_USER, { user_id: id });
    setPageViewLogged(true);
  }, [pageViewLogged, setPageViewLogged, id]);

  const { applications, loading: applicationsLoading } = useApplications(
    {
      _and: [
        {
          _or: [{ archived: false }, { archived_null: true }]
        },
        {
          _or: [{ users_contains: [id] }, { inactiveUsers_contains: [id] }]
        }
      ]
    },
    ...skip(4),
    'network-only'
  );

  const expand = useMemo(() => {
    const { expand } = queryString.parse(location.search) || {};

    if (expand) {
      setSelectedProfile(user?.profiles?.[0]);
    }

    return Boolean(expand);
  }, [location.search, user?.profiles]);

  useEffect(() => {
    if (!id || userLoading) {
      return;
    }

    if (!userData && !isIDEqual(userId, id)) {
      toast.error(formatErrorMessage(userError));
      navigate('/app/manage/users');
      return;
    }

    let isImported, user, roles;

    if (userData && !userError) {
      isImported = !!userData?.profiles?.length;
      user = userData;
      roles = userData?.orgRoles?.map((orgRole) => orgRole.value);
    } else {
      isImported = !!currentUser?.profiles?.length;
      user = currentUser;
      roles = currentUserRoles;
    }

    setIsImported(isImported);
    setUser(user);
    setUserRoles(roles);
    setLocalData(user);
  }, [currentUser, currentUserRoles, id, navigate, userData, userError, userId, userLoading]);

  const updateLocalData = (key, value) => {
    setLocalData((local) => {
      return { ...local, [key]: value };
    });
  };

  const handleEdit = () => {
    setEditMode((mode) => {
      if (mode) {
        setUserRoles(userData?.orgRoles?.map((orgRole) => orgRole.value));
        setLocalData(userData);
        setErrorFields({});
      }
      return !mode;
    });
  };

  const handleInvite = async () => {
    try {
      setLoading(true);
      await inviteUser({ variables: { user: user?.id, org: orgId } });
      refetchUser();
      toast.success('Invitation sent');
    } catch (error) {
      console.error(error);
      toast.error('There was an error inviting the user');
    } finally {
      setLoading(false);
    }
  };

  const handleArchiveLogEvent = () => {
    //Logs what the archive action will do to the status
    //Eg: If current user.archived = false, clicking archive will change this to true
    !user?.archived
      ? logEvent(MANAGE_SINGLE_USER_EVENTS.ARCHIVE_USER, { archived_user_id: user?.id, archived_by: userId })
      : logEvent(MANAGE_SINGLE_USER_EVENTS.UNARCHIVE_USER, { unarchived_user_id: user?.id, unarchived_by: userId });
  };

  const saveUser = async (type) => {
    const emptyFields = isEmpty(REQUIRED_FIELDS, localData);

    if (!userRoles?.length) {
      emptyFields.roles = 'Required fields cannot be empty';
    }

    if (Object.keys(emptyFields || {}).length) {
      setErrorFields(emptyFields);
      toast.error(defaultMessages.requiredFields);
      return;
    }

    try {
      setLoading(true);
      switch (type) {
        case 'ARCHIVE':
          await updateUserMutation({
            variables: {
              id: user?.id,
              data: {
                archived: !user?.archived
              }
            }
          });
          handleArchiveLogEvent();
          break;
        default:
          await updateUserMutation({
            variables: {
              id: user?.id,
              data: {
                firstName: localData.firstName,
                lastName: localData.lastName
              }
            }
          });
          logEvent(MANAGE_SINGLE_USER_EVENTS.UPDATE_USER, { updated_user_id: user?.id, updated_by: userId });
      }
      await saveOrgRoles({ variables: { user: user?.id, org: orgId, roles: userRoles } });
      refetchUser();
      setEditMode(false);
      if (type === 'ARCHIVE') {
        toast.success(user?.archived ? 'User unarchived successfully' : 'User archived successfully');
      } else {
        toast.success(STATUS_CODES.SUCCESS.DEFAULT.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleProfileClick = (profile) => {
    setSelectedProfile((selected) => {
      if (isIDEqual(selected?.id || '', profile?.id || '')) {
        return null;
      }
      logEvent(MANAGE_SINGLE_USER_EVENTS.VIEW_DETAILS, { type: profile?.type });
      return profile;
    });
  };

  const handleAppBarClick = (application) => {
    logEvent(MANAGE_SINGLE_USER_EVENTS.VIEW_APPLICATION, { application_id: application?.id });
    navigate(`/app/manage/applications/${application?.id}`);
  };

  if (userLoading || !user || currentUserLoading) {
    return <DotLoader />;
  }

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={formatUserName(user || {})} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardWithLoader loading={userLoading || currentUserLoading}>
            <Grid container spacing={2}>
              <Grid container item spacing={2} alignItems='center'>
                {!editMode && (
                  <Grid item justifySelf='center' alignSelf='center'>
                    <Box sx={{ height: 'auto', width: 50 }}>
                      <LetterAvatar user={localData} />
                    </Box>
                  </Grid>
                )}
                <Grid item xs>
                  <Stack>
                    <Grid container spacing={1}>
                      {!editMode && (
                        <Grid item className={classes.usernameLine}>
                          <UserRoleIcon userRoles={userRoles} />
                        </Grid>
                      )}
                      <Grid item>
                        <ViewEditText
                          edit={editMode}
                          value={localData?.firstName || ''}
                          onChange={(data) => updateLocalData('firstName', data)}
                          placeholder='First Name'
                          size='small'
                          required
                          {...(editMode && { label: 'First Name' })}
                          errorText={errorFields['firstName']}
                          hasError={!!errorFields['firstName']}
                        />
                      </Grid>
                      <Grid item>
                        <ViewEditText
                          edit={editMode}
                          value={localData?.lastName || ''}
                          onChange={(data) => updateLocalData('lastName', data)}
                          placeholder='Last Name'
                          size='small'
                          required
                          {...(editMode && { label: 'Last Name' })}
                          errorText={errorFields['lastName']}
                          hasError={!!errorFields['lastName']}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <ViewEditText
                        edit={isImported ? false : editMode}
                        value={localData?.email}
                        onChange={(data) => updateLocalData('email', data)}
                        placeholder='Email'
                        size='small'
                        {...(editMode && { label: 'Email' })}
                      />
                    </Grid>
                  </Stack>
                </Grid>
                {admin && (
                  <Grid container item xs={12} sm={4} md={3} spacing={1} alignItems='center' justifyContent='flex-end'>
                    {!editMode && <StatusIcon {...user} onClick={handleInvite} loading={loading || userLoading} />}

                    {!isIDEqual(userId, user?.id) && (
                      <Grid item>
                        <TooltipEnhanced title={user?.archived ? 'Unarchive User' : 'Archive User'}>
                          <IconButtonEnhanced onClick={() => saveUser('ARCHIVE')} loading={loading || userLoading}>
                            {user?.archived ? <UnarchiveRounded color='success' /> : <ArchiveRounded color='error' />}
                          </IconButtonEnhanced>
                        </TooltipEnhanced>
                      </Grid>
                    )}

                    <Grid item>
                      <TooltipEnhanced title={editMode ? 'Cancel Edit' : 'Edit'}>
                        <IconButtonEnhanced onClick={handleEdit} color='primary' loading={loading || userLoading}>
                          {editMode ? <EditOffRounded /> : <EditRounded />}
                        </IconButtonEnhanced>
                      </TooltipEnhanced>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {editMode ? (
                <Grid container item xs={12} justifyContent='flex-end' spacing={2}>
                  <Grid container item spacing={2}>
                    <Grid item xs={12} md={6}>
                      <SelectEnhanced
                        label='Roles'
                        value={userRoles || []}
                        options={ROLE_OPTIONS}
                        onChange={(roles) => setUserRoles(roles)}
                        multiple
                        renderType='chip'
                        size='small'
                        required
                        errorText={errorFields['roles']}
                        hasError={!!errorFields['roles']}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <ButtonEnhanced onClick={handleEdit} variant='outlined' loading={loading || userLoading}>
                      <TypographyEnhanced id='CANCEL' />
                    </ButtonEnhanced>
                  </Grid>
                  <Grid item>
                    <ButtonEnhanced onClick={() => saveUser()} loading={loading || userLoading}>
                      <TypographyEnhanced id='SAVE' />
                    </ButtonEnhanced>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ) : (
                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    <TypographyEnhanced id={'Details'} variant='overline' className={classes.bold} />
                  </Grid>
                  <Grid container item spacing={2} alignItems='center'>
                    {(user?.profiles || [])?.map((profile) => {
                      const { id, type } = profile || {};
                      return (
                        <Grid item key={id}>
                          <ChipEnhanced
                            classes={{ deleteIcon: classes.deleteIcon }}
                            avatar={<Avatar alt={type} src={IMAGES.ICONS[type]} />}
                            id={INTEGRATION_TYPES[type]?.name}
                            variant='outlined'
                            onClick={() => handleProfileClick(profile)}
                            onDelete={() => handleProfileClick(profile)}
                            deleteIcon={<InfoRounded />}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  {selectedProfile && (
                    <Grid item xs={12}>
                      <ProfileCard
                        profile={selectedProfile}
                        onClose={() => handleProfileClick()}
                        highlight={expand && ['admin', 'delegateAdmin']}
                        loading={userLoading || applicationsLoading}
                      />
                    </Grid>
                  )}
                </Grid>
              )}

              {/* DEVICES */}
              <Grid container item spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid>
                    <TypographyEnhanced id={'Devices'} variant='overline' className={classes.bold} />
                  </Grid>
                  <Grid sx={{ marginLeft: 'auto' }}>
                    <ButtonEnhanced size='medium' onClick={() => navigate('/app/manage/devices/add')}>
                      <TypographyEnhanced id={'Add Device'} />
                    </ButtonEnhanced>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DevicesTable columnKeys={['deviceModel', 'type', 'os', 'deviceId', 'firstSync', 'lastSync']} devices={user?.devices || []} />
                </Grid>
              </Grid>

              {/* Applications */}
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={6}>
                  <TypographyEnhanced id={'Applications'} variant='overline' className={classes.bold} />
                </Grid>

                <Grid item xs={6} className={classes.addAppButton}>
                  <ButtonEnhanced
                    size='medium'
                    onClick={() => {
                      logEvent(MANAGE_SINGLE_USER_EVENTS.CLICK_ADD_APPLICATION);
                      navigate('/app/manage/applications/add');
                    }}
                  >
                    <TypographyEnhanced id={'Add Application'} />
                  </ButtonEnhanced>
                </Grid>

                <Grid item xs={12} container spacing={2}>
                  {applications?.map((application) => {
                    return (
                      <Grid item xs={12} key={application.id}>
                        <SingleAppBar application={application} onClick={() => handleAppBarClick(application)} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </CardWithLoader>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewUser;
