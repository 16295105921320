import { CloseRounded } from '@mui/icons-material';
import { Avatar, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Fade } from '../../../../animations';
import { DotLoader, IconButtonEnhanced, TypographyEnhanced } from '../../../../global';
import { HEADERS } from './constants';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.background.default
  }
}));

const ProfileCard = ({ profile, highlight = [], onClose, loading }) => {
  const classes = useStyles();

  const [fade, setFade] = useState(0);

  if (!profile) {
    return null;
  }

  if (loading) {
    return <DotLoader sx={{ position: 'relative' }} />;
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item justifyContent='space-between'>
            <Grid container item spacing={2} xs>
              {profile?.imageUrl && (
                <Grid item>
                  <Avatar src={profile.imageUrl || ''} />
                </Grid>
              )}
              <Grid item>
                <TypographyEnhanced id='Profile' variant='h6' />
              </Grid>
            </Grid>
            <Grid item>
              <IconButtonEnhanced onClick={onClose}>
                <CloseRounded />
              </IconButtonEnhanced>
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            {Object.keys(HEADERS).map((header) => {
              const { name, render } = HEADERS[header] || {};
              const highlighted = (highlight || []).includes(header) || (highlight || []).includes(name);
              const value = profile[header];
              if (Array.isArray?.(value) && !value?.length) {
                return null;
              }
              return (
                <Grid item key={header}>
                  {highlighted ? (
                    <Fade
                      config={{
                        to: { opacity: 1 },
                        from: { opacity: 0 },
                        reset: true,
                        reverse: !!fade,
                        delay: 350,
                        onRest: () =>
                          setFade((mode) => {
                            if (mode <= 2) {
                              return mode + 1;
                            }
                          })
                      }}
                    >
                      {render(name, profile[header])}
                    </Fade>
                  ) : (
                    render(name, profile[header])
                  )}
                </Grid>
              );
            })}
            {/* <Grid item>
              <Table>
                <TableBody>
                  {Object.keys(HEADERS).map((header) => {
                    const { name, render } = HEADERS[header] || {};
                    const highlighted = (highlight || []).includes(header) || (highlight || []).includes(name);

                    return (
                      <TableRow key={header}>
                        <TableCell>
                          <TypographyEnhanced id={name || ''} />
                        </TableCell>
                        {highlighted ? (
                          <TableCell>
                            <Fade
                              config={{
                                to: { opacity: 1 },
                                from: { opacity: 0 },
                                reset: true,
                                reverse: !!fade,
                                delay: 350,
                                onRest: () =>
                                  setFade((mode) => {
                                    if (mode <= 2) {
                                      return mode + 1;
                                    }
                                  })
                              }}
                            >
                              {render(profile[header])}
                            </Fade>
                          </TableCell>
                        ) : (
                          <TableCell>{render(name, profile[header])}</TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid> */}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ProfileCard.propTypes = {
  profile: PropTypes.object,
  highlight: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  onClose: PropTypes.func,
  loading: PropTypes.bool
};

export default ProfileCard;
