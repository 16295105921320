const DOMAIN = 'https://images.avertro.com';

const path = (file) => `${DOMAIN}/icons/${file}`;

const ICONS = {
  // EXTERNAL APP LOGOS
  AWS_SECURITY_HUB: path('logo-aws.svg'),
  AZURE: path('logo-azure.svg'),
  GCLOUD: path('logo-google-cloud.svg'),
  GSUITE: path('logo-google.svg'),
  GITHUB_LIGHT: path('logo-github-dark.png'),
  GITHUB_DARK: path('logo-github-white.png'),
  JIRA: path('logo-jira.svg'),
  TWITTER: path('Twitter.svg'),
  MICROSOFT: path('logo-microsoft.svg'),
  ANDROID: path('logo-android.svg'),
  APPLE: path('logo-apple.svg'),
  LINUX: path('logo-linux.svg'),
  WINDOWS: path('logo-windows.svg'),
  CHROME: path('logo-chrome.svg'),
  SYNC_CURRENT: path('sync-green.svg'),
  SYNC_ERROR: path('sync-error.svg'),
  SLACK: path('logo-slack.png')
};

module.exports = {
  ICONS
};
