/* eslint-disable quotes */
// components.application.settings.user.userAccountSettings

export const USER_ACCOUNT_SETTINGS = {
  profilePic: 'Profile Picture',
  name: 'Full Name',
  email: 'Email',
  password: 'Password',
  mfa: 'Two-Factor',
  enable: 'Enabled',
  notEnabled: 'Not Enabled',
  buttons: {
    save: 'Save',
    edit: 'Edit',
    email: 'Add Email',
    password: 'Create password',
    enable: 'Enable',
    disable: 'Disable'
  }
};
