/* eslint-disable quotes */
// components.application.settings.user.emailSettings

export const EMAIL_SETTINGS = {
  title: 'Email notification settings',
  toggles: {
    productUpdates: 'Monthly product updates',
    surveys: 'Surveys and feedback requests',
    newFeatures: 'New feature announcements'
  }
};
