// views.app.reports
import { CLOUD_INFRASTRUCTURE as cloudInfrastructure } from './cloudInfrastructure';
import { DEVICES as devices } from './devices';
import news from './news';
import sbom from './sbom';
import { WEB_SECURITY_HEADERS as webSecurityHeaders } from './webSecurityHeaders';

export const REPORTS = {
  cloudInfrastructure,
  devices,
  webSecurityHeaders,
  news,
  sbom
};
