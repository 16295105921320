const HEADERS = [
  { id: 'index', label: '' },
  { id: 'framework', label: 'Framework' },
  { id: 'name', label: 'Name' },
  { id: 'link', label: '' }
];

const DOTSTEPPER_BUTTONS = {
  NEXT: 'NEXT',
  DONE: 'DONE',
  BACK: 'BACK'
};

export { HEADERS, DOTSTEPPER_BUTTONS };
