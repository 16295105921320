import { CloseRounded } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Grid, Zoom } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { IconButtonEnhanced } from '../../../components/global';

//needs to be defined outside TransitionDialog or will leave a div overlaying wepapp and prevent user interaction
const Transition = forwardRef((props, ref) => {
  return <Zoom ref={ref} {...props} />;
});

Transition.displayName = 'Transition-component';

const TransitionDialog = ({ open, toggle, title, children, actions }) => {
  return (
    <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={toggle} aria-describedby='transition-dialog'>
      <Grid container>
        <Grid item xs>
          <DialogTitle>{title}</DialogTitle>
        </Grid>
        <Grid item>
          <IconButtonEnhanced onClick={toggle}>
            <CloseRounded />
          </IconButtonEnhanced>
        </Grid>
      </Grid>
      <DialogContent>{children}</DialogContent>
      {actions && actions}
    </Dialog>
  );
};

TransitionDialog.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.any,
  actions: PropTypes.any
};

export default TransitionDialog;
