import moment from 'moment';
import { TypographyEnhanced } from '../../components/global';

export const compareTodaysDate = (date) => {
  return moment(date).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY');
};

export const formatDate = (date) => {
  if (!moment(date).isValid()) {
    return 'N/A';
  }
  return moment(date).format('DD MMM YYYY');
};

export const notificationParser = (notifications) => {
  if (!notifications || !notifications.length) {
    return [];
  }
  return notifications.map((notification) => {
    const { type, sender, created_at, data, question = {} } = notification || {};

    const { firstName = '' } = sender || {};
    const { id = '', model = 'category' } = data || {};
    const { parent: category = {} } = question || {};
    const { id: categoryId } = category || {};

    let link = '';
    switch (model) {
      case 'issue':
        link = `/app/manage/issues/${id}`;
        break;
      case 'question':
        link = `/app/assessment/cyber/category/${categoryId}/question/${id}`;
        break;
      default:
        link = `/app/assessment/cyber/category/${id}`;
    }

    const created = compareTodaysDate(created_at) ? moment(created_at).format('hh:mm a') : moment(created_at).format('LL');
    let entity;
    switch (type) {
      case 'MENTION':
        entity = {
          ...notification,
          created,
          message: {
            primary: <TypographyEnhanced id=''>{`${firstName}: Mentioned you in a comment`}</TypographyEnhanced>
            // secondary: <ShowMoreText limit={200}>{comment}</ShowMoreText>
          },
          link
        };
        break;
      case 'ASSIGNEE':
        entity = {
          ...notification,
          created,
          message: {
            primary: <TypographyEnhanced id=''>{`${firstName}: Assigned you an issue`}</TypographyEnhanced>
            // secondary: <ShowMoreText limit={200}>{comment}</ShowMoreText>
          },
          link
        };
        break;
      default:
        return null;
    }

    return entity;
  });
};

export const invertColor = (hex) => {
  if (!hex) {
    return '';
  }
  const padZero = (str, len) => {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  };

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
};

/**
 *
 * @param {Object} selectedFilters
 * @returns {total: Number}
 */
export const getTotalFilters = (selectedFilters) => {
  return Object.values(selectedFilters || {}).reduce((total, filter) => {
    total += Object.values(filter || {}).reduce((sum, item) => {
      sum += Array.isArray(item) ? (item || []).length : 1;
      return sum;
    }, 0);
    return total;
  }, 0);
};
