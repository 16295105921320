import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { ButtonEnhanced, SliderEnhanced, TypographyEnhanced } from '../../../../../../../global';
import { TextFieldEnhanced } from '../../../../../../../ui/form';

const Scoring = ({ label, options, datapoint, onSave, scale }) => {
  const max = useMemo(() => {
    if (!scale) {
      return 5;
    }
    return scale.max;
  }, [scale]);

  const [value, setValue] = useState(0);
  const [notApplicable, setNotApplicable] = useState(false);

  const selectedAnswer = useMemo(() => {
    if (!datapoint?.answer) {
      return 'No Controls';
    }
    return datapoint?.answer || '';
  }, [datapoint?.answer]);

  const marks = useMemo(() => {
    if (!(options || []).length) {
      return [];
    }

    return (options || [])
      .filter((option) => option.score > -1)
      .map((option) => {
        const { name, customScore } = option || {};
        return {
          label: (
            // <TooltipEnhanced title={name || ''} modalFallback={false}>
            <TypographyEnhanced id={String(customScore)} />
            // </TooltipEnhanced>
          ),
          value: customScore,
          name
        };
      });
  }, [options]);

  const notApplicableOption = useMemo(() => {
    if (!(options || []).length) {
      return false;
    }

    return (options || []).find((option) => option.score === -1);
  }, [options]);

  useEffect(() => {
    if (!datapoint) {
      return;
    }
    const { value: customValue, notApplicable } = datapoint || {};

    setValue(customValue);
    setNotApplicable(notApplicable);
  }, [datapoint, scale]);

  const handleInputChange = (inputValue) => {
    let newValue = Number(inputValue || 0);

    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > max) {
      newValue = max;
    }
    setValue(newValue);
  };

  const handleChange = (inputValue, notApplicable) => {
    const value = notApplicable ? -1 : Number(inputValue);
    onSave({ value, notApplicable });
  };

  const handleNotApplicable = () => {
    setNotApplicable((selected) => {
      handleChange(0, !selected);
      return !selected;
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid container item spacing={2} justifyContent='space-between' alignItems='center'>
        <Grid item xs>
          <TypographyEnhanced id={label} />
        </Grid>

        {!!notApplicableOption && (
          <Grid item>
            <ButtonEnhanced size='small' variant={notApplicable ? 'contained' : 'outlined'} onClick={handleNotApplicable}>
              N/A
            </ButtonEnhanced>
          </Grid>
        )}
      </Grid>

      <Grid container item spacing={2}>
        <Grid container item spacing={2} alignItems='center'>
          <Grid item xs={8} lg={10}>
            <SliderEnhanced value={value} onChangeCommitted={handleChange} onChange={handleInputChange} marks={marks} max={max} disabled={notApplicable} />
          </Grid>

          <Grid item xs={4} lg={2} sx={{ height: '100%' }}>
            <TextFieldEnhanced
              value={String(value || 0)}
              size='small'
              onChange={handleInputChange}
              onBlur={handleChange}
              type='number'
              inputProps={{ step: 0.1 }}
              disabled={notApplicable}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TypographyEnhanced id={selectedAnswer} variant='caption' />
        </Grid>
      </Grid>
    </Grid>
  );
};

Scoring.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  options: PropTypes.array,
  datapoint: PropTypes.object,
  onSave: PropTypes.func,
  scale: PropTypes.object
};

export default Scoring;
