import { Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  }
}));

const CardWithLoader = ({ children, loading, cardHeaderProps, ...props }) => {
  const classes = useStyles();

  return (
    <Card {...props} className={classes.root}>
      {cardHeaderProps && <CardHeader {...cardHeaderProps} />}
      <CardContent>{loading ? <Skeleton /> : children}</CardContent>
    </Card>
  );
};
CardWithLoader.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.any,
  cardHeaderProps: PropTypes.object
};
export default CardWithLoader;
