import PropTypes from 'prop-types';

/**
 * @description Function to check if 'None of the above' has been selected in a multiSelectInput
 * If it has been selected, removes all other answer values and only returns 'None of the above' answer value
 * If it was previously selected, but user has now selected a different answer, removes 'None of the above' value
 * Otherwise passes values on unchanged

 * @param {Array} values - Array of answer id values currently selected by the user
 * @param {[{id: String | Number, name: String, type: String, value: String | Number}]} options - Array of objects, each object is an available answer to a given question and its associated properties
 * @param {Array} selected - Array of answer id values representing previously saved answer values
 * @returns {Array} saveValues - Array of answer id values to be saved
 */
const filterNoneOfTheAbove = (values = [], options = [], selected = []) => {
  if (!(options || []).length) {
    return [];
  }

  // Get the 'None of the above' answer option
  const answerNoneOfTheAbove = options.find((option) => option?.name === 'None of the above');

  // Check if the 'None of the above' answer id is in values array
  // Will return boolean value
  const foundAnswer = !!values.find((answer) => answer === answerNoneOfTheAbove?.id);

  let saveValues;

  // If 'None of the above' is not being passed, return values without modification
  if (!foundAnswer) {
    saveValues = values;
    return saveValues;
  }

  /**
   * If previous answer was 'None of the above' but user has now selected a different answer, remove 'None of the above' id from answer values
   * and save new values
   **/
  if (selected.includes(answerNoneOfTheAbove?.id)) {
    saveValues = values.filter((value) => value !== answerNoneOfTheAbove?.id);
    return saveValues;
  } else {
    // If user has selected 'None of the above', remove other save values and only pass through the value for 'None of the above'
    saveValues = [answerNoneOfTheAbove?.id];
    return saveValues;
  }
};

filterNoneOfTheAbove.propTypes = {
  values: PropTypes.Array,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  selected: PropTypes.Array
};

export { filterNoneOfTheAbove };
