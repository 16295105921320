import { STATUS_CODES } from '@anirudhm9/base-lib/lib/constants';
import { createPassword, formatErrorMessage, getDomain, isEmpty, validateEmail } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { Checkbox, CssBaseline, Grid, Paper, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { signInWithRedirect } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { deviceDetect, isFirefox, isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { REGISTER } from '../../../api';
import { UserForm } from '../../../components/application/user';
import { ButtonEnhanced, DividerWithText, TypographyEnhanced, toast } from '../../../components/global';
import { GoogleSignIn } from '../../../components/global/authorisationProviders';
import { defaultMessages } from '../../../constants';
import { usePageViewContext } from '../../../contexts/logPageViewContext';
import { clearCache, openLink } from '../../../helpers/routing';
import { useIsDesktop, useKeyPress } from '../../../hooks';
import { FIREBASE_AUTH, GOOGLE_PROVIDER, getSSOResult, logEvent } from '../../../library';
import { USER_ACTIONS as USER_ACTIONS_EVENTS } from '../../../library/amplitude/events/common';
import { REGISTER as REGISTER_PAGE_EVENTS } from '../../../library/amplitude/events/views/user/register';
import { initialiseUser } from '../login/helpers';
import { TRANSLATION_PATH, defaultData, getOAuthDetails, logEventForOAuth, requiredFields } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(10, 0),
    justifyContent: 'center'
  },
  terms: {
    margin: theme.spacing(1),
    justifyContent: 'center'
  },
  disclaimer: {
    color: alpha(theme.palette.primary.main, 0.8)
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  topMargin: {
    marginTop: theme.spacing(0)
  },
  authLogin: {
    margin: theme.spacing(1, 0, 1)
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  options: {
    padding: theme.spacing(0, 1)
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  attribute: {
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  termsError: {
    margin: theme.spacing(1),
    justifyContent: 'center',
    color: 'red'
  }
}));

const Register = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const { pageViewLogged, setPageViewLogged } = usePageViewContext();

  useEffect(() => {
    if (pageViewLogged) {
      return;
    }
    logEvent(REGISTER_PAGE_EVENTS.PAGE_VISITED);
    setPageViewLogged(true);
  }, [pageViewLogged, setPageViewLogged]);

  const [loading, setLoading] = useState(false);
  const [localData, setLocalData] = useState(defaultData);
  const [errorFields, setErrorFields] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState();
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const [register] = useMutation(REGISTER);

  const updateLocalData = (data) => {
    setShowDisclaimer(true);
    setLocalData(data);
  };

  const ssoRegister = useCallback(
    async (isPopup, authProvider) => {
      setLoading(true);
      clearCache();
      try {
        const result = await getSSOResult(isPopup, authProvider);
        if (!result) {
          setLoading(false);
          return;
        }

        const { email, username, firstName, lastName, confirmed, provider, imageUrl, idToken } = getOAuthDetails(result || {});
        const res = await register({
          variables: {
            email,
            username,
            firstName,
            lastName,
            confirmed,
            provider,
            imageUrl,
            data: deviceInfo,
            password: createPassword()
          }
        });

        const { user, message } = res?.data?.registerWithName || {};

        if (!user) {
          const error = message?.[0]?.messages?.[0]?.message || defaultMessages.defaultError;
          console.error(error);
          toast.error(formatErrorMessage(error));
          return;
        }

        localStorage.setItem('user_token', idToken);
        initialiseUser(user, true);

        logEventForOAuth(result?.providerId);
        setLoading(false);
        toast.success(STATUS_CODES.SUCCESS.DEFAULT.message);
        setTimeout(() => navigate('/'), 0);
      } catch (error) {
        console.error(error);
        toast.error(formatErrorMessage(error));
      } finally {
        setLoading(false);
      }
    },
    [deviceInfo, navigate, register]
  );

  useEffect(() => {
    if (!deviceInfo) {
      return;
    }

    ssoRegister();
  }, [deviceInfo, ssoRegister]);

  const handleSSOButtonClick = async (provider) => {
    if (!localData.acceptedTCs) {
      setErrorFields({ acceptedTCs: defaultMessages.conditionsNotAccepted });
      toast.error(defaultMessages.conditionsNotAccepted);
      return;
    }

    if (isIOS || isFirefox) {
      await ssoRegister(true, provider);
      return;
    }

    logEvent(USER_ACTIONS_EVENTS.SIGNUP_SSO);
    signInWithRedirect(FIREBASE_AUTH, provider);
  };

  useEffect(() => {
    const detectInfo = deviceDetect();
    if (!detectInfo) return;
    setDeviceInfo(detectInfo);
    const { isMobile, os = '', osName = '', vendor = '', model = '', browserName = '', browserMajorVersion = '', osVersion = '' } = detectInfo || {};

    const deviceType = ((isMobile ? os : osName) || '').toUpperCase();
    const deviceName = isMobile ? `${vendor} ${model}` : `${browserName} ${browserMajorVersion}`;
    const deviceVersion = isMobile ? `${os} ${osVersion}` : `${osName} ${osVersion}`;

    setDeviceInfo({
      deviceType,
      deviceName,
      deviceVersion
    });
  }, []);

  const onRegister = async () => {
    let errors = {};

    if (!validateEmail(localData.email)) {
      errors = {
        ...errors,
        email: defaultMessages.invalidEmail
      };
    }

    if (!localData?.acceptedTCs) {
      errors = {
        ...errors,
        acceptedTCs: defaultMessages.conditionsNotAccepted
      };
    }

    const emptyFields = isEmpty(requiredFields, localData);

    if (emptyFields) {
      errors = {
        ...errors,
        ...emptyFields
      };
    }

    if (Object.keys(errors).length) {
      setErrorFields(errors);
      const errorMessage = Object.values(errors)?.[0] || '';
      toast.error(errorMessage || defaultMessages.requiredFields);
      return;
    }

    try {
      setLoading(true);
      clearCache();
      const dataToSave = { ...localData, data: deviceInfo, provider: 'local', password: createPassword() };

      const res = await register({ variables: dataToSave });

      const data = res?.data?.registerWithName;
      const { user, message } = data || {};

      if (!user) {
        const error = message?.[0]?.messages?.[0]?.message || defaultMessages.defaultError;
        console.error(error);
        toast.error(formatErrorMessage(error));
        return;
      }

      initialiseUser(user, true);
      setLoading(false);
      logEvent(USER_ACTIONS_EVENTS.SIGNUP_EMAIL, { domain: getDomain(localData?.email) });
      toast.success('Verification email sent');
      setTimeout(() => navigate('/user/login'));
    } catch (error) {
      toast.error(formatErrorMessage(error));
      console.error(error);
    } finally {
      setLoading(false);
      setErrorFields([]);
    }
  };

  useKeyPress('Enter', () => {
    onRegister();
  });

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid container item xs={12} sm={8} md={6} component={isDesktop ? Paper : 'div'}>
        <div className={classes.paper}>
          <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h5' />
          <form className={classes.form} noValidate>
            <Grid container spacing={2} justifyContent='center' className={classes.topMargin}>
              {showDisclaimer && (
                <Grid container item alignItems='center'>
                  <Grid item>
                    <TypographyEnhanced id={TRANSLATION_PATH('registerDisclaimer.1')} variant='caption' className={classes.disclaimer} />
                    <TypographyEnhanced id={TRANSLATION_PATH('registerDisclaimer.2')} variant='caption' ml={1} className={classes.disclaimer} />
                  </Grid>
                </Grid>
              )}
              <UserForm localData={localData} setLocalData={updateLocalData} errorFields={errorFields} />
              <Grid item xs={12}>
                <ButtonEnhanced fullWidth variant='contained' size='large' onClick={() => onRegister()} loading={loading}>
                  <TypographyEnhanced id={TRANSLATION_PATH('buttons.signUp')} />
                </ButtonEnhanced>
              </Grid>
              <Grid container item alignItems='center' spacing={1} className={errorFields?.acceptedTCs ? classes.termsError : classes.terms}>
                <Grid item>
                  <Checkbox
                    id='acceptedTCs'
                    size='small'
                    className={classes.checkbox}
                    checked={localData?.acceptedTCs}
                    onChange={() =>
                      setLocalData((prev) => {
                        return {
                          ...prev,
                          acceptedTCs: !prev.acceptedTCs
                        };
                      })
                    }
                    sx={{
                      color: (theme) => theme.palette[errorFields?.acceptedTCs ? 'error' : 'primary'].main
                    }}
                  />
                </Grid>
                <Grid item>
                  <TypographyEnhanced id={TRANSLATION_PATH('tcs.1')} variant='caption' />
                </Grid>
                <Grid item>
                  <TypographyEnhanced
                    id={TRANSLATION_PATH('tcs.2')}
                    variant='caption'
                    display='inline'
                    onClick={() => openLink('https://www.avertro.com/saas-terms-of-use')}
                    className={classes.link}
                  />
                </Grid>
                <Grid item>
                  <TypographyEnhanced id={TRANSLATION_PATH('tcs.3')} variant='caption' />{' '}
                </Grid>
                <Grid item>
                  <TypographyEnhanced
                    id={TRANSLATION_PATH('tcs.4')}
                    variant='caption'
                    display='inline'
                    onClick={() => openLink('https://www.avertro.com/privacy-policy')}
                    className={classes.link}
                  />
                </Grid>
              </Grid>
              <DividerWithText />
              <Grid item>
                <GoogleSignIn onClick={() => handleSSOButtonClick(GOOGLE_PROVIDER)} />
              </Grid>
              {/* <Grid item>
                <MicrosoftSignIn onClick={() => signInWithRedirect(FIREBASE_AUTH, MICROSOFT_PROVIDER)} />
              </Grid> */}
              {/* <Grid item xs={12}>
                <ButtonEnhanced fullWidth size='large' variant='outlined' loading={loading}>
                  Sign up with your Office365 admin account
                </ButtonEnhanced>
              </Grid> */}
            </Grid>
          </form>
        </div>
        <Grid item xs={12} justifyContent='center' mb={1}>
          <ButtonEnhanced
            fullWidth
            variant='text'
            onClick={() => {
              logEvent(REGISTER_PAGE_EVENTS.BACK);
              navigate('/user/login');
            }}
          >
            <TypographyEnhanced id={TRANSLATION_PATH('buttons.login')} />
          </ButtonEnhanced>
        </Grid>
      </Grid>
    </Grid>
  );
};

Register.propTypes = {};

export default Register;
