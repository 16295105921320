// import { EVENTS } from '../../../../../../library';

import { TRANSLATION_PATH } from '../../constants/defaults';

// export const LOG_EVENT_PATH = (event) => {
//   return EVENTS.COMPONENTS.APPLICATION.REWARDS.REDEEM.DOWNLOAD_MODAL[event];
// };

export const FILTERS = {
  KEYWORD: {
    label: TRANSLATION_PATH('headings.keyword'),
    type: 'text'
  },
  CATEGORY: {
    label: TRANSLATION_PATH('headings.categories'),
    type: 'checkbox',
    options: [
      { label: 'Policies', value: 'POLICY', type: 'reward' },
      { label: 'Procedures', value: 'PROCEDURE', type: 'reward' },
      { label: 'Playbooks', value: 'PLAYBOOK', type: 'reward' }
    ]
  },
  STATUS: {
    label: TRANSLATION_PATH('headings.status'),
    type: 'checkbox',
    options: [
      { label: 'Redeemed', value: 'REDEEMED', type: '' },
      { label: 'Can be redeemed', value: 'REDEEMABLE', type: '' },
      { label: 'Locked', value: 'LOCKED', type: '' }
    ]
  },
  PROGRESSION: {
    label: TRANSLATION_PATH('headings.status'),
    type: 'checkbox',
    options: [
      { label: 'In Progress', value: 'IN_PROGRESS', type: '' },
      { label: 'Completed', value: 'COMPLETED', type: '' },
      { label: 'Not Started', value: 'NOT_STARTED', type: '' }
    ]
  }
};
