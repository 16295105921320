const _ = require('lodash');

const fetchDataInBatches = async (controller, where = {}, populate = [], size = 20) => {
  const SERVICE = await strapi.services[controller];
  const count = await SERVICE.count(where);

  const queries = [];

  for (let i = 0; i <= count; i = i + size) {
    queries.push({ ...where, _start: i, _limit: size });
  }

  const entity = [];
  const batches = _.chunk(queries, size);

  for (const batch of batches) {
    await Promise.all(
      batch.map((item) => {
        return SERVICE.find(item, populate);
      })
    ).then((values) => {
      values.forEach((value) => {
        entity.push(...(value || []));
      });
    });
  }

  return entity;
};

module.exports = { fetchDataInBatches };
