import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import propTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    justifyContent: 'center'
  }
}));

const DividerWithText = ({ text = 'OR' }) => {
  const classes = useStyles();

  return (
    <Grid container item xs={12} justifyContent='space-evenly' alignItems='center' className={classes.root}>
      <Grid item xs={5}>
        <Divider />
      </Grid>
      <Grid container justifyContent='center' item xs>
        <Typography variant='body1'>{text}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Divider />
      </Grid>
    </Grid>
  );
};

DividerWithText.propTypes = {
  text: propTypes.string
};

export default DividerWithText;
