import { PersonRounded } from '@mui/icons-material';
import { Button, Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ChipEnhanced, TooltipEnhanced, TypographyEnhanced } from '../../../../global';
import AppLogo from '../appLogo';
import GradingAvatar from '../gradingAvatar';
import KbsChip from '../kbs/kbsChip';
import { KBS_TYPES } from '../kbs/kbsChip/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.background.default
  },
  cardContent: {
    width: '100%',
    textAlign: 'left'
  }
}));

const SingleAppBar = ({ application, onClick }) => {
  const classes = useStyles();

  const { customName, name, kbs = KBS_TYPES.NONE.value, thirdparty, users = [], inactiveUsers = [] } = application || {};
  const { name: thirdpartyName = '', logoUrl = '', description = '', securityScore = 0, grade } = thirdparty || {};

  const totalUsers = useMemo(() => (users?.length || 0) + (inactiveUsers?.length || 0), [inactiveUsers?.length, users?.length]);

  const CardButton = (props) => {
    return <Button {...props} fullWidth />;
  };

  return (
    <Card onClick={onClick} className={classes.card}>
      <CardContent component={CardButton} className={classes.cardContent}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item>
            <AppLogo url={logoUrl || ''} alt={thirdpartyName || ''} />
          </Grid>
          <Grid container item xs={12} sm direction='column' alignItems='flex-start'>
            <Grid item>
              <TypographyEnhanced id={customName ? `${name} - ${customName}` : name} />
            </Grid>
            <Grid container item xs justifyContent='flex-start'>
              <TypographyEnhanced id={(description || '').trim()} variant='caption' />
            </Grid>
          </Grid>

          <Grid item>
            <KbsChip value={kbs} />
          </Grid>
          <Grid item>
            <TooltipEnhanced title={`Active: ${users?.length || 0}, Inactive: ${inactiveUsers?.length || 0} `}>
              <ChipEnhanced id={String(totalUsers || 0)} icon={<PersonRounded />} size='small' />
            </TooltipEnhanced>
          </Grid>
          <Grid item alignSelf='center'>
            <GradingAvatar grade={grade} score={securityScore} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SingleAppBar.propTypes = {
  application: PropTypes.object,
  onClick: PropTypes.func
};

export default SingleAppBar;
