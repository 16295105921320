const DOMAIN = 'https://images.avertro.com';

const path = (file) => `${DOMAIN}/empty-state/${file}`;

const EMPTY_STATE = {
  // Connectivity
  CONNECTIVITY_CABLE: path('Connectivity+01.svg'),
  CONNECTIVITY_EARTH_PHONE: path('Connectivity+02.svg'),
  CONNECTIVITY_HAND_PHONE: path('Connectivity+03.svg'),
  CONNECTIVITY_SATURN_EARTH: path('Connectivity+04.svg'),
  CONNECTIVITY_PHONE_KEY: path('Connectivity+04.svg'),

  // E-commerce
  ECOMMERCE_EMPTY_CART: path('E-Commerce+01.svg'),
  ECOMMERCE_EMPTY_BOX_FRONT: path('E-Commerce+02.svg'),
  ECOMMERCE_BOX_CLOSED: path('E-Commerce+03.svg'),
  ECOMMERCE_EMPTY_BOX_SIDE: path('E-Commerce+04.svg'),
  ECOMMERCE_EMPTY_BASKET: path('E-Commerce+05.svg'),

  // Messages
  MESSAGES_MAILBOX_EMPTY: path('Messages+01.svg'),
  MESSAGES_ENVELOPE_OPEN: path('Messages+02.svg'),
  MESSAGES_CONVERSATION: path('Messages+03.svg'),
  MESSAGES_ENVELOPE_SAD: path('Messages+04.svg'),
  MESSAGES_CHAT: path('Messages+05.svg'),

  // Social
  SOCIAL_HANDSHAKE: path('Social+01.svg'),
  SOCIAL_MAGNIFYING_GLASS: path('Social+02.svg'),
  SOCIAL_GHOST: path('Social+03.svg'),
  SOCIAL_FRIENDS: path('Social+04.svg'),
  SOCIAL_PERSON: path('Social+05.svg'),

  // Wallet
  WALLET_CLOSED: path('Wallet+01.svg'),
  WALLET_CARD_ADD: path('Wallet+02.svg'),
  WALLET_CARD_BROKEN: path('Wallet+03.svg'),
  WALLET_CLOSED_EMPTY: path('Wallet+04.svg'),
  WALLET_CARDS: path('Wallet+05.svg'),

  // Icons
  UNKNOWN_DEVICE: path('unknown-device.svg')
};

module.exports = {
  EMPTY_STATE
};
