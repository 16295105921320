import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import TodoList from '../../../../global/todoList';
import { TRANSLATION_PATH_VIEW } from '../constants';

const ActionList = ({ label, custom, actions, setActions, hasError, errorText }) => {
  const handleAdd = useCallback(
    (name) => {
      const newAction = {
        name,
        completed: false
      };
      setActions((prev) => [...prev, newAction]);
    },
    [setActions]
  );

  const onComplete = useCallback(
    (_item, index, value) => {
      setActions((prev) => {
        const actionsCopy = _.cloneDeep(prev || []);
        const action = actionsCopy[index];
        if (action) {
          action.completed = value;
        }
        return actionsCopy;
      });
    },
    [setActions]
  );

  return (
    <TodoList
      items={actions || []}
      setItems={setActions}
      onAdd={handleAdd}
      onComplete={onComplete}
      label={label}
      hasError={hasError}
      errorText={errorText}
      isAddDisabled={!custom}
      isCompleteDisabled={!custom}
      isDeleteDisabled={!custom}
      isEditDisabled={!custom}
      tooltipText={TRANSLATION_PATH_VIEW('actionCheckboxTooltip.customAction')}
      disabledTooltipText={TRANSLATION_PATH_VIEW('actionCheckboxTooltip.integrationAction')}
    />
  );
};

ActionList.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  custom: PropTypes.bool,
  actions: PropTypes.array,
  setActions: PropTypes.func,
  hasError: PropTypes.bool,
  errorText: PropTypes.string
};

export default ActionList;
