import { EVENTS } from '../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.user.forgotPassword.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.USER.FORGOT_PASSWORD[event];
};

export const requiredFields = ['email'];

export const defaultData = {
  email: ''
};
