import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { ButtonEnhanced } from '../../../components/global';
import { EVENTS, logEvent } from '../../../library';
import botImage from './rock-n-roll-monkey-robot.jpg';

const BotDetectionModal = forwardRef((_props, ref) => {
  const [open, setOpen] = useState(false);
  const [limit, setLimit] = useState(0);

  const increment = useCallback(() => {
    setLimit((limit) => ++limit);
  }, []);

  const toggle = (type) => {
    if (type) {
      logEvent(EVENTS.COMMON.BOT_DETECTED[type]);
    }
    setOpen((open) => !open);
  };

  useImperativeHandle(
    ref,
    () => ({
      increment
    }),
    [increment]
  );

  useEffect(() => {
    if (limit < 10) {
      return;
    }
    toggle();
    setLimit(0);
  }, [limit]);

  return (
    <Dialog open={open}>
      <DialogTitle>Bot Detected</DialogTitle>
      <DialogContent>
        <DialogContentText>BEEP BOOP!</DialogContentText>
        <Grid container>
          <Grid item xs={12}>
            <Typography>Your actions seem like that of a robot, finally someone I can talk to!</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <img style={{ objectFit: 'fill', maxWidth: '100%' }} src={botImage} alt='bot-detected' />
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonEnhanced variant='text' onClick={() => toggle('CLOSED')}>
          Close
        </ButtonEnhanced>
        <ButtonEnhanced variant='contained' onClick={() => toggle('TAKE_OVER')}>
          Take over the world!
        </ButtonEnhanced>
      </DialogActions>
    </Dialog>
  );
});

BotDetectionModal.displayName = 'Bot-Modal';

BotDetectionModal.propTypes = {};

export default BotDetectionModal;
