import { useQuery, useReactiveVar } from '@apollo/client';
import { GET_ME, userVar } from '../../api';

/**
 * Custom hook to encapsulate GET_ME
 * usage example:
 *
 * const { userId, userData, loading, error, refetch } = userUserData()
 *
 * @returns {{user: (JSON), refetch: (variables?: Partial<{user: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError, userId: *}}
 */
const useGetMe = () => {
  const userId = useReactiveVar(userVar);

  const {
    data: userData,
    loading,
    error,
    refetch
  } = useQuery(GET_ME, {
    skip: !userId,
    fetchPolicy: 'network-only'
  });

  if (error) {
    console.error(error);
  }

  return { userId, user: userData?.getMe, loading, error, refetch };
};

export default useGetMe;
