import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../translations';

const ChipEnhanced = ({ id, value, color, icon, size, ...props }) => {
  const { t } = useTranslation();

  const text = t(id, value) || id;

  return <Chip label={text} color={color} size={size || 'medium'} icon={icon} {...props} />;
};

ChipEnhanced.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.object,
  color: PropTypes.string,
  icon: PropTypes.any,
  size: PropTypes.string
};

export default ChipEnhanced;
