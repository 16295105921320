import { IMAGES } from '@anirudhm9/base-lib/lib/constants';
import { Fragment } from 'react';
import { TypographyEnhanced } from '../../../../global';

export const TRANSLATION_PATH = (route) => {
  return `components.application.dashboard.welcome_modal.${route}`;
};

export const content = {
  1: {
    title: <TypographyEnhanced id={TRANSLATION_PATH('steps.one.title')} variant='h5' align='center' sx={{ fontWeight: 'bold' }} />,
    image: IMAGES.GENERAL.WELCOME,
    bodyText: (
      <Fragment>
        <TypographyEnhanced id={TRANSLATION_PATH('steps.one.line_one')} variant='body1' sx={{ textAlign: 'center' }} />
        <br />
        <TypographyEnhanced id={TRANSLATION_PATH('steps.one.line_two')} variant='body1' sx={{ textAlign: 'center' }} />
      </Fragment>
    )
  },
  2: {
    title: <TypographyEnhanced id={TRANSLATION_PATH('steps.two.title')} variant='h5' align='center' sx={{ fontWeight: 'bold' }} />,
    image: IMAGES.GENERAL.INSIGHTS,
    bodyText: (
      <Fragment>
        <TypographyEnhanced id={TRANSLATION_PATH('steps.two.line_one')} variant='body1' sx={{ textAlign: 'center' }} />
        <br />
        <TypographyEnhanced id={TRANSLATION_PATH('steps.two.line_two')} variant='body1' sx={{ textAlign: 'center' }} />
      </Fragment>
    )
  },
  3: {
    title: <TypographyEnhanced id={TRANSLATION_PATH('steps.three.title')} variant='h5' align='center' sx={{ fontWeight: 'bold' }} />,
    image: IMAGES.GENERAL.ASSESSMENT,
    bodyText: (
      <Fragment>
        <TypographyEnhanced id={TRANSLATION_PATH('steps.three.line_one')} variant='body1' sx={{ textAlign: 'center' }} />
        <br />
        <TypographyEnhanced id={TRANSLATION_PATH('steps.three.line_two')} variant='body1' sx={{ textAlign: 'center' }} />
      </Fragment>
    )
  }
};
