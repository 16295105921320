import { useTheme } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DEFAULT_OPTIONS } from './constants';

const TreeChart = ({ seriesData, options }) => {
  const theme = useTheme();

  const [chartOptions, setChartOptions] = useState(DEFAULT_OPTIONS);

  useEffect(() => {
    if (!seriesData) {
      return;
    }
    const { palette } = theme || {};
    const { background, text, primary, secondary } = palette || {};

    setChartOptions((chartOptions) => {
      const optionsCopy = _.cloneDeep(chartOptions || {});
      const { series: defaultSeries, tooltip } = optionsCopy || {};
      const series = defaultSeries[0];
      series.data = [seriesData];
      series.itemStyle = {
        color: primary.main
      };
      series.label.color = text?.primary;
      series.lineStyle.color = secondary?.main;

      tooltip.backgroundColor = background?.default;
      tooltip.textStyle = { color: text?.primary };

      return { ...optionsCopy, ...(options || {}) };
    });
  }, [options, seriesData, theme]);

  return <ReactEcharts option={chartOptions} style={{ height: '100%', width: '100%' }} />;
};
TreeChart.propTypes = {
  seriesData: PropTypes.object,
  options: PropTypes.object
};

export default TreeChart;
