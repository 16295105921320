import { useMutation } from '@apollo/client';
import { DeleteRounded, MoveDownRounded } from '@mui/icons-material';
import { Card, CardContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import HtmlParser from 'react-html-parser';
import { DELETE_SCENARIO_TEMPLATE } from '../../../../../../../api';
import { ClipboardCopy, IconButtonEnhanced, TooltipEnhanced, TypographyEnhanced, toast } from '../../../../../../../components/global';

const TemplateCard = ({ template, selected, onClick, custom, refetch }) => {
  const { name, description, prompt } = template || {};

  const [deleteScenariotemplate] = useMutation(DELETE_SCENARIO_TEMPLATE);

  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      await deleteScenariotemplate({ variables: { id: template?.id } });
      toast.success('Template deleted successfully');
      try {
        refetch();
      } catch (error) {
        //do nothing
      }
    } catch (error) {
      console.error(error);
      toast.success('There was an error deleting template');
    } finally {
      setLoading(false);
    }
  }, [deleteScenariotemplate, refetch, template?.id]);

  return (
    <Grid key={name} item xs={12}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid container item spacing={2} justifyContent='space-between' alignItems='center'>
              <Grid container item xs alignItems='center'>
                <TypographyEnhanced id={'Name'} display='inline' />
                <ClipboardCopy text={name} />
                <Grid item xs={12}>
                  <TypographyEnhanced id={name} />
                </Grid>
              </Grid>
              <Grid container item xs justifyContent='flex-end'>
                {custom && (
                  <Grid item>
                    <TooltipEnhanced title={'Delete'} showInfoModal={false}>
                      <IconButtonEnhanced color='error' onClick={handleDelete} loading={loading}>
                        <DeleteRounded />
                      </IconButtonEnhanced>
                    </TooltipEnhanced>
                  </Grid>
                )}
                <Grid item>
                  <TooltipEnhanced title={selected ? 'Template already selected' : 'Select template'} showInfoModal={false}>
                    <IconButtonEnhanced onClick={onClick} color='primary' disabled={selected} loading={loading}>
                      <MoveDownRounded />
                    </IconButtonEnhanced>
                  </TooltipEnhanced>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <TypographyEnhanced id={'Description'} display='inline' />
                {description && <ClipboardCopy text={description} />}
                {description && HtmlParser(description || '')}
              </Grid>
              <Grid item xs={12}>
                {prompt && <ClipboardCopy text={prompt} />}
                <TypographyEnhanced id={prompt} variant='caption' />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

TemplateCard.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string,
    prompt: PropTypes.string,
    mapping: PropTypes.object
  }),
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  custom: PropTypes.bool,
  refetch: PropTypes.func
};
export default TemplateCard;
