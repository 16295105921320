import { formatUserName, isIDEqual } from '@anirudhm9/base-lib/lib/utils';

const getMentionsFromEditor = (editor) => {
  const result = {
    users: [],
    commandType: ''
  };

  if (!editor) {
    return result;
  }

  try {
    const { ops } = editor.getContents() || {};

    const userMentions = [];
    let slashMention = '';

    for (const op of ops) {
      const { insert } = op || {};
      const { mention } = insert || {};
      if (!mention) {
        continue;
      }

      const { id, denotationChar } = mention || {};
      switch (denotationChar) {
        case '@':
          if (!userMentions.includes(id)) {
            userMentions.push(id);
          }
          break;
        case '/':
          slashMention = id;
          break;
        default:
        // do nothing
      }
    }

    result.users = userMentions;
    result.commandType = slashMention;
  } catch (error) {
    console.error(error);
  }
  return result;
};

const mentionUserValues = (userId, users) => {
  return (users || [])
    .map((user) => {
      if (isIDEqual(userId, user?.id)) {
        return null;
      }
      return {
        value: formatUserName(user),
        ...(user || {})
      };
    })
    .filter((user) => user);
};

export { getMentionsFromEditor, mentionUserValues };
