import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { TypographyEnhanced } from '../../../global';
import { STATES, TRANSLATION_PATH } from './constants/defaults';

const PointsChip = ({ type, pointValue }) => {
  const [status, setStatus] = useState('DEFAULT');

  useEffect(() => {
    const currentState = type || 'DEFAULT';

    setStatus(currentState);
  }, [type]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '5px 0' }}>
      {STATES?.[status]?.icon}
      <TypographyEnhanced id={TRANSLATION_PATH(STATES?.[status]?.label)} value={{ cost: pointValue }} sx={{ fontWeight: 'bold', paddingLeft: '3px' }} />
    </Box>
  );
};

PointsChip.propTypes = {
  type: PropTypes.string,
  pointValue: PropTypes.number
};

export default PointsChip;
