import ReactEcharts from 'echarts-for-react';
import React, { useEffect, useState } from 'react';
import DefaultOptions from './defaultOptions';

const LineChart = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      const timer = Math.floor(Math.random() * 2000);

      setTimeout(() => {
        setIsLoading(false);
      }, timer);
    }
  }, [isLoading]);

  return <ReactEcharts option={DefaultOptions} />;
};
export default LineChart;
