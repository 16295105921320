import { INTEGRATION_TYPES } from '@anirudhm9/base-lib/lib/constants';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useUserContext } from '../../../../../contexts';
import { logEvent } from '../../../../../library';
import { INTEGRATIONS } from '../../../../../library/amplitude/events/views/app/integrations';
import { ConnectGsuite } from '../../../onboarding';
import IntegrationForm from './form';
import OAuthApplication from './oAuth';

const AddApplication = ({ type, application, toggle }) => {
  const { userId } = useUserContext();
  const { id: integrationId } = application || {};

  const handleIntegrationUpdateLog = useCallback(
    (integrationType, eventType) => {
      if (!INTEGRATION_TYPES[integrationType]) {
        return;
      }
      logEvent(INTEGRATIONS[eventType], { integration: integrationType, integration_id: integrationId, user_id: userId });
    },
    [integrationId, userId]
  );

  const component = useMemo(() => {
    let result;
    switch (type) {
      case INTEGRATION_TYPES.GSUITE.value:
        result = <ConnectGsuite type={type} onSubmit={handleIntegrationUpdateLog} />;
        break;
      case INTEGRATION_TYPES.GITHUB.value:
        result = <OAuthApplication type={type} toggle={toggle} integration={application} onSubmit={handleIntegrationUpdateLog} />;
        break;
      case INTEGRATION_TYPES.SLACK.value:
        result = <OAuthApplication type={type} toggle={toggle} integration={application} onSubmit={handleIntegrationUpdateLog} />;
        break;
      case INTEGRATION_TYPES.AWS_SECURITY_HUB.value:
        result = <IntegrationForm type={type} toggle={toggle} integration={application} onSubmit={handleIntegrationUpdateLog} />;
        break;
      default:
        result = null;
    }
    return result;
  }, [type, handleIntegrationUpdateLog, toggle, application]);

  return component;
};

AddApplication.propTypes = {
  type: PropTypes.string.isRequired,
  application: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired
};

export default AddApplication;
