import { formatUserName } from '@anirudhm9/base-lib/lib/utils';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { TypographyEnhanced } from '../../../../global';
import { AutoCompleteEnhanced } from '../../../../ui/form';
import AppLogo from '../../applications/appLogo';
import EditUsersSelect from '../../applications/viewEditUsers/editUsersSelect';
import { ISSUE_RELATION_OPTIONS } from '../constants';

const IssueRelationField = ({ relation, issue, onChange, devices, questions, applications, users }) => {
  const renderOption = (props, application) => {
    const { logoUrl, name } = application || {};

    return (
      <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        <AppLogo loading='lazy' url={logoUrl || ''} alt={name || ''} style={{ height: 20, width: 20, marginRight: 5 }} />
        <TypographyEnhanced id={name || ''} />
      </Box>
    );
  };

  const localApplications = useMemo(() => {
    if (!applications?.length) {
      return [];
    }
    return applications.map((application) => {
      const { customName, thirdparty } = application || {};
      const { logoUrl, name } = thirdparty || {};
      return { ...(application || {}), name: customName || name || '', logoUrl };
    });
  }, [applications]);

  const component = useMemo(() => {
    let result;
    switch (relation) {
      case ISSUE_RELATION_OPTIONS.QUESTION.value:
        result = (
          <AutoCompleteEnhanced
            id='question'
            label='Related Question'
            value={issue?.question}
            onChange={(value) => {
              onChange('question', value);
            }}
            options={questions.map((question) => {
              const { id, index, name } = question || {};
              return {
                id: id,
                name: `${index}: ${name}`
              };
            })}
          />
        );
        break;

      case ISSUE_RELATION_OPTIONS.APPLICATION.value:
        result = (
          <AutoCompleteEnhanced
            id='application'
            label='Application'
            value={issue?.application}
            onChange={(value) => {
              onChange('application', value);
            }}
            options={localApplications || []}
            renderOption={renderOption}
          />
        );
        break;

      case ISSUE_RELATION_OPTIONS.DEVICE.value:
        result = (
          <AutoCompleteEnhanced
            id='device'
            label='Related Device'
            value={issue?.device}
            onChange={(value) => {
              onChange('device', value);
            }}
            options={(devices || []).map((device) => {
              const { id, type, user } = device || {};
              return {
                id: id,
                name: `${formatUserName(user)} ${type} Device ID: ${id}`
              };
            })}
          />
        );
        break;

      case ISSUE_RELATION_OPTIONS.USER.value:
        result = (
          <EditUsersSelect
            options={(users || []).filter((user) => !user.archived && !user.blocked)}
            id='user'
            label='User'
            value={issue?.user}
            multiple={false}
            onChange={(value) => {
              onChange('user', value);
            }}
            enable
          />
        );
        break;

      default:
        result = null;
    }
    return result;
  }, [devices, onChange, localApplications, issue?.application, issue?.device, issue?.question, issue?.user, questions, relation, users]);

  return component;
};

IssueRelationField.propTypes = {};

export default IssueRelationField;
