import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import { useLogEvent } from '../../../../../../hooks';
import { TypographyEnhanced } from '../../../../../global';
import { LOG_EVENT_PATH, TRANSLATION_PATH } from './constants/defaults';

// TODO add real data in

const RewardSupport = () => {
  useLogEvent(LOG_EVENT_PATH('TAB_VIEWED'));

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h6' />
            <TypographyEnhanced id={TRANSLATION_PATH('description')} variant='body1' />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RewardSupport;
