import { gql } from '@apollo/client';

export const GET_APPLICATIONS = gql`
  query applications($where: JSON, $sort: String, $start: Int, $limit: Int) {
    applications(where: $where, sort: $sort, start: $start, limit: $limit) {
      id
      name
      customName
      note
      kbs
      scopes
      org {
        id
      }
      informationHeld {
        id
        name
        value
      }
      users {
        id
        firstName
        lastName
        email
      }
      inactiveUsers {
        id
        firstName
        lastName
        email
      }
      integration {
        id
        name
        type
      }
      thirdparty {
        id
        name
        description
        logoUrl
        securityScore
        grade
        kbs
        draft
        aliases
        homepage
        informationHeld {
          id
          name
          value
        }
        categories {
          id
          name
          value
        }
        website {
          id
          link
        }
      }
    }
  }
`;

export const COUNT_APPLICATIONS = gql`
  query countApplications($where: JSON!) {
    countApplications(where: $where)
  }
`;

export const GET_APPLICATION = gql`
  query application($id: ID!) {
    application(id: $id) {
      id
      name
      customName
      note
      kbs
      scopes
      org {
        id
      }
      informationHeld {
        id
        name
        value
      }
      users {
        id
        firstName
        lastName
        email
      }
      inactiveUsers {
        id
        firstName
        lastName
        email
      }
      integration {
        id
        name
        type
      }
      website {
        id
        link
      }
      thirdparty {
        id
        name
        description
        logoUrl
        securityScore
        grade
        kbs
        draft
        aliases
        homepage
        informationHeld {
          id
          name
          value
        }
        categories {
          id
          name
          value
        }
        website {
          id
          link
        }
        newsItems(sort: "createdAt:DESC", limit: 10) {
          id
          title
          description
          link
          displayLink
          imageUrl
          author
          createdAt
        }
      }
    }
  }
`;

export const CREATE_APPLICATION = gql`
  mutation createApplication($data: ApplicationInput!) {
    createApplication(input: { data: $data }) {
      application {
        id
      }
    }
  }
`;

export const UPDATE_APPLICATION = gql`
  mutation updateApplication($id: ID!, $data: editApplicationInput!) {
    updateApplication(input: { where: { id: $id }, data: $data }) {
      application {
        id
      }
    }
  }
`;
