export const LIGHT_MODE = () => {
  return {
    palette: {
      mode: 'light',
      primary: { main: '#12173F' },
      secondary: { main: '#A1D5B3' },
      error: { main: '#E57373' },
      warning: { main: '#ffB74D' },
      info: { main: '#4fC3F7' },
      success: { main: '#81C784' },

      blue: { main: '#A6DFFE', light: '#A6DFFE', dark: '#01669D' },
      green: { main: '#6FFFF2', light: '#6FFFF2', dark: '#008377' },
      yellow: { main: '#FFEDAD', light: '#FFEDAD', dark: '#E5B300' },
      orange: { main: '#FED2AE', light: '#FED2AE', dark: '#B25101' },
      red: { main: '#F2C1C5', light: '#F2C1C5', dark: '#A0222E' },
      text: {
        primary: '#000000',
        inverted: '#E7E7E7'
      },
      background: {
        default: '#F8F8F8',
        secondary: '#4F9CE3' // light blue
      },
      tonalOffset: 0.3

      // error: {},
      // warning: {},
      // info: {},
      // success: {},
    },
    shape: {
      borderRadius: 15
    },
    typography: {
      h1: {
        fontSize: 56
      },
      h2: {
        fontSize: 46
      },
      h3: {
        fontSize: 40
      },
      h4: {
        fontSize: 32
      },
      h5: {
        fontSize: 26
      },
      h6: {
        fontSize: 22
      },
      body1: {
        fontSize: 14
      },
      body2: {
        fontSize: 16
      },
      subtitle1: {
        fontSize: 20
      },
      caption: {
        fontSize: 12
      },
      button: {
        fontSize: 15,
        textTransform: 'none'
      }
    }
  };
};

export const DARK_MODE = () => {
  return {
    palette: {
      mode: 'dark',
      text: {
        primary: '#E7E7E7',
        inverted: '#000000'
      },
      primary: { main: '#FFEDAD', dark: '#FFD233' },
      secondary: { main: '#A3D3FF' },
      error: { main: '#E57373' },
      warning: { main: '#ffB74D' },
      info: { main: '#4fC3F7' },
      success: { main: '#81C784' },

      blue: { main: '#A6DFFE', light: '#A6DFFE', dark: '#01669D' },
      green: { main: '#6FFFF2', light: '#6FFFF2', dark: '#008377' },
      yellow: { main: '#FFEDAD', light: '#FFEDAD', dark: '#E5B300' },
      orange: { main: '#FED2AE', light: '#FED2AE', dark: '#B25101' },
      red: { main: '#F2C1C5', light: '#F2C1C5', dark: '#A0222E' },

      background: {
        paper: '#080C21',
        default: '#040610',
        secondary: '#4F9CE3' // light blue
      },
      tonalOffset: 0.3

      // error: {},
      // warning: {},
      // info: {},
      // success: {},
    },
    shape: {
      borderRadius: 15
    },
    typography: {
      h1: {
        fontSize: 56
      },
      h2: {
        fontSize: 46
      },
      h3: {
        fontSize: 40
      },
      h4: {
        fontSize: 32
      },
      h5: {
        fontSize: 26
      },
      h6: {
        fontSize: 22
      },
      body1: {
        fontSize: 14
      },
      body2: {
        fontSize: 16
      },
      subtitle1: {
        fontSize: 20
      },
      caption: {
        fontSize: 12
      },
      button: {
        fontSize: 15,
        textTransform: 'none'
      }
    }
  };
};

export const TOOLBAR_COLOR = '#070C20'; // Colour code via colour picker from dark mode toolbar until figure out where it's being styled

export const SYSTEM_THEME = (prefersDarkMode) => {
  return prefersDarkMode ? DARK_MODE() : LIGHT_MODE();
};
