import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMemo, useState } from 'react';
import Headline from '../../../../components/application/manage/applications/cyberNews/headline';
import NewsItem from '../../../../components/application/manage/applications/cyberNews/newsItem';
import { NewsFilter } from '../../../../components/application/reports';
import { SORT_VALUES } from '../../../../components/application/reports/news/filter/constants';
import { DotLoader } from '../../../../components/global';
import { Heading, PaginationEnhanced } from '../../../../components/ui';
import { useGetNews, useIsDesktop } from '../../../../hooks';
import MasonryLayout from '../../dashboard/masonryLayout';
import { TRANSLATION_PATH } from './constants';
import { logArticleClick, logPageChange, logRowChange } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  card: {
    textAlign: 'left',
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer'
  }
}));

const News = () => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();

  const [where, setWhere] = useState({});
  const [start, setStart] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState(SORT_VALUES.CREATED_AT_DESC.value);

  const { news, applications, total, loading } = useGetNews(where, start, pageSize, sort);

  const sections = useMemo(() => {
    const entity = { top: [], bottom: [] };
    if (!news?.length) {
      return entity;
    }

    return { top: news?.slice?.(1, 5), bottom: news?.slice?.(5, news.length) || [] };
  }, [news]);

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Heading id={TRANSLATION_PATH('title')} description={TRANSLATION_PATH('description')} />
      <NewsFilter applications={applications} sort={sort} setSort={setSort} setWhere={setWhere} />
      <Grid container spacing={2}>
        {loading ? (
          <DotLoader />
        ) : (
          <Grid container item spacing={2}>
            {/* Main heading news item */}
            {isDesktop && news?.[0] && (
              <Grid container item md={6} spacing={2}>
                <Grid item>
                  <Headline {...(news?.[0] || {})} className={classes.card} onClick={() => logArticleClick(news?.[0] || {})} />
                </Grid>
              </Grid>
            )}

            {/* News items to the right of heading */}
            <Grid container item xs={12} md={6} spacing={2}>
              <MasonryLayout columns={2} xs={12} md={6}>
                {((isDesktop ? sections?.top : news) || []).map((newsItem) => {
                  return (
                    <Grid item key={newsItem?.id}>
                      <NewsItem newsItem={newsItem} className={classes.card} limit={10000} onClick={() => logArticleClick(newsItem)} />
                    </Grid>
                  );
                })}
              </MasonryLayout>
            </Grid>

            {/* Rest of the news items at the bottom */}
            {sections.bottom?.length ? (
              // <Grid container item spacing={2}>
              <MasonryLayout columns={3} itemSizes={{ xs: 12, md: 4 }}>
                {(sections.bottom || []).map((newsItem) => {
                  return (
                    <Grid item key={newsItem?.id}>
                      <NewsItem newsItem={newsItem} className={classes.card} limit={10000} onClick={() => logArticleClick(newsItem)} />
                    </Grid>
                  );
                })}
              </MasonryLayout>
            ) : // </Grid>
            null}
          </Grid>
        )}
      </Grid>
      <PaginationEnhanced
        total={total}
        onChange={setStart}
        pageSize={pageSize}
        setPageSize={setPageSize}
        logPageChange={logPageChange}
        logRowChange={logRowChange}
        showFirstButton
        showLastButton
      />
    </Container>
  );
};

export default News;
