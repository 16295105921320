import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GET_STATUSES } from '../../api';

/**
 * Custom hook to encapsulate GET_CRITICALITIES
 * usage example:
 *
 * const { statuses, loading, error, refetch } = useStatuses()
 *
 * @returns {{statuses: (Array), refetch: (variables?: Partial<{criticality: *}>) => Promise<ApolloQueryResult<any>>, loading: boolean, error: ApolloError}}
 */
const useStatuses = () => {
  const { data, loading, error, refetch } = useQuery(GET_STATUSES);

  const statuses = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return data?.statuses || [];
  }, [data?.statuses, error, loading]);

  if (error) {
    console.error(error);
  }

  return { statuses, loading, error, refetch };
};

export default useStatuses;
