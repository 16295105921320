import _ from 'lodash';
import { SORT_VALUES } from '../constants';

export const formatFilters = (filters = {}) => {
  // Flatten out each filter group object into a single array
  const filtersByKey = Object.keys(filters).reduce((result, filterKey) => {
    const filterGroupFlat = Object.values(filters[filterKey]).flat(1);
    result = { ...(result || {}), [filterKey]: filterGroupFlat };
    return result;
  }, {});

  const result = {};

  const and = [];
  if ((filtersByKey.CRITICALITY || []).length) {
    and.push({ criticality_in: filtersByKey.CRITICALITY || [] });
  }

  if ((filtersByKey.STATUS || []).length) {
    and.push({ status_in: filtersByKey.STATUS || [] });
  }

  result._and = and;

  return result;
};

export const sortIssues = (issues, sortValue) => {
  let sortedIssues = _.cloneDeep(issues || []);
  switch (sortValue) {
    case SORT_VALUES.ALPHABETICAL_DESC.value:
      sortedIssues = _.orderBy(sortedIssues || [], ['name'], ['asc']);
      break;
    case SORT_VALUES.ALPHABETICAL_ASC.value:
      sortedIssues = _.orderBy(sortedIssues || [], ['name'], ['desc']);
      break;
    case SORT_VALUES.CRITICALITY_DESC.value:
      sortedIssues = _.orderBy(sortedIssues || [], ['criticality.name'], ['desc']);
      break;
    case SORT_VALUES.CRITICALITY_ASC.value:
      sortedIssues = _.orderBy(sortedIssues || [], ['criticality.name'], ['asc']);
      break;

    default:
    // do nothing
  }
  return sortedIssues;
};
