import { IMAGES } from '@anirudhm9/base-lib/lib/constants';
import { formatDateTime } from '@anirudhm9/base-lib/lib/utils';
import { Card, CardContent, CardHeader, CardMedia, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { openLink } from '../../../../../../helpers/routing';
import { TypographyEnhanced } from '../../../../../global';
import { ShowMoreText } from '../../../../../ui';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.main
  }
}));
const Headline = ({ className, title, description, author, imageUrl, link, displayLink, createdAt, height = 600, onClick, ...props }) => {
  const classes = useStyles();

  const handleClick = () => {
    onClick && onClick();
    openLink(link);
  };

  return (
    <Card onClick={handleClick} {...props}>
      <CardHeader className={className} title={title || ''} subheader={`${author || ''} (${formatDateTime(createdAt)})`} />
      <CardMedia className={className} component='img' height={height} image={imageUrl || IMAGES.EMPTY_STATE.CONNECTIVITY_CABLE} alt={title || ''} />
      <CardContent className={className}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ShowMoreText limit={10000}>{description || ''}</ShowMoreText>
          </Grid>
          <Grid container item justifyContent='space-between' alignItems='center' spacing={2}>
            <Grid item>
              <TypographyEnhanced id='' variant='caption' className={classes.link}>
                {displayLink}
              </TypographyEnhanced>
            </Grid>
            <Grid item>
              <TypographyEnhanced id='' variant='caption'>
                {`${author || ''} (${formatDateTime(createdAt)})`}
              </TypographyEnhanced>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Headline.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  imageUrl: PropTypes.string,
  link: PropTypes.string,
  displayLink: PropTypes.string,
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func
};

export default Headline;
