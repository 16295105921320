import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntegrationContext } from '../../../../../contexts';
import { TypographyEnhanced } from '../../../../global';
import CardPaginator from './paginator';

/**
 * Displays multiple segment names with selector for GSUITE integration
 *
 * @param {String} selectedOrganisation
 * @param {Function} setSelectedOrganisation
 * @returns {JSX}
 */
const OrganisationSelector = ({ selectedOrganisation, setSelectedOrganisation }) => {
  const { GSUITE, error: integrationError, loading: integrationLoading } = useIntegrationContext();

  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    if (integrationError || integrationLoading || !GSUITE?.length) {
      return;
    }
    const orgs = GSUITE.map((org) => org.id) || [];
    setOrganisations(orgs);
    setSelectedOrganisation(orgs[0]);
  }, [GSUITE, integrationError, integrationLoading, setSelectedOrganisation]);

  if (organisations?.length < 2) {
    return null;
  }

  return (
    <Grid container item justifyContent='space-between' alignItems='center'>
      <Grid item xs={6}>
        <TypographyEnhanced id={`ID: ${selectedOrganisation}`}>{selectedOrganisation || ''}</TypographyEnhanced>
      </Grid>
      <Grid item xs={6}>
        <CardPaginator items={organisations} item={selectedOrganisation || ''} onChange={setSelectedOrganisation} />
      </Grid>
    </Grid>
  );
};

OrganisationSelector.propTypes = {
  selectedOrganisation: PropTypes.string.isRequired,
  setSelectedOrganisation: PropTypes.func.isRequired
};

export default OrganisationSelector;
