import PropTypes from 'prop-types';
import { Complete, InProgress, Locked, NotStarted } from './statuses';

const AchievementStatus = ({ data, onClaim, closeModal, type }) => {
  const { percentComplete, claimed, locked } = data || {};

  // Check if Locked
  if (locked) {
    return <Locked type={type} closeModal={closeModal} />;
  }

  // Check if complete
  if (percentComplete === 100) {
    return <Complete claimed={claimed} onClaim={onClaim} type={type} closeModal={closeModal} />;
  }

  // Check if In Progress
  if (percentComplete < 100 && percentComplete > 0) {
    return <InProgress percentComplete={percentComplete} data={data} type={type} closeModal={closeModal} />;
  }

  // Default return Not Started
  return <NotStarted percentComplete={percentComplete} data={data} type={type} closeModal={closeModal} />;
};

AchievementStatus.propTypes = {
  data: PropTypes.object.isRequired,
  closeModal: PropTypes.func,
  onClaim: PropTypes.func,
  type: PropTypes.string
};

export default AchievementStatus;
