import PropTypes from 'prop-types';
import { AutoCompleteEnhanced, RadioEnhanced } from '../../../../../../../ui/form';
import { OPTION_THRESHOLD } from '../../constants';

const SelectInput = ({ id, label, options, selectedOptions, setSelectedOptions, onSave, other, disabled, ...props }) => {
  const handleChange = (value) => {
    const otherOption = options?.find((option) => option?.name === 'Other' && value === option?.id);

    setSelectedOptions([value]);

    // Do not save automatically if other option is selected and other textField is empty.
    if (otherOption && !other) {
      return;
    }
    onSave({ selected: [value] });
  };

  // show dropdown if options > threshold
  if (options?.length > OPTION_THRESHOLD) {
    return (
      <AutoCompleteEnhanced
        id={id}
        label={label}
        options={options || []}
        value={selectedOptions?.[0] || ''}
        onChange={handleChange}
        disabled={disabled}
        {...props}
      />
    );
  }
  // show radio if options < threshold
  return <RadioEnhanced label={label} value={selectedOptions?.[0] || ''} onChange={handleChange} options={options} disabled={disabled} {...props} />;
};

SelectInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element, PropTypes.node, PropTypes.object]),
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  other: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default SelectInput;
