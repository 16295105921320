import { SIDEMENU_VIEW_EVENTS } from '../../../../constants/sideMenu';

export const COMMON = {
  LOGIN: 'login',
  REGISTER: 'register',
  LOGOUT: 'logout',
  PAGE_REFRESHED: 'page_refreshed',
  // THEME: 'changed_theme',
  TOAST: {
    SUCCESS: 'success-toast',
    WARNING: 'warning-toast',
    ERROR: 'error-toast'
  },
  BOT_DETECTED: {
    OPENED: 'bot_modal_opened',
    CLOSED: 'bot_modal_closed',
    TAKE_OVER: 'bot_take_over_the_world'
  },
  //All sidemenu item page_view events
  //Does NOT include dynamic routes (eg manage/issue/:id)
  //Does NOT include add/view routes (eg manage/issue/add or manage/issue/:id)
  //The above can be handled through the PageViewContext pageViewLogged / setPageViewLogged state
  PAGE_VIEW: SIDEMENU_VIEW_EVENTS
};

export const USER_ACTIONS = {
  REPORT_BUG: 'user_action-report_bug',
  CLICK_APPBAR_LOGO: 'user_action-click_appbar_logo',
  VIEW_PROFILE: 'user_action-view_profile',
  VIEW_ACCOUNT_SETTINGS: 'user_action-account_settings',
  CHANGE_THEME: 'user_action-change_theme',
  SIGNUP: 'user_action-click_signup_button',
  SIGNUP_SSO: 'user_action-signup_with_google',
  SIGNUP_EMAIL: 'user_action-signup_with_email',
  LOGIN_SSO: 'user_action-signin_with_google',
  LOGIN_EMAIL: 'user_action-signin_with_email',
  REMEMBER_ME: 'user_action-remember_me',
  FORGOT_PASSWORD: 'user_action-forgot_password',
  LOGOUT: 'user_action-logout'
};

export const NOTIFICATIONS = {
  VIEW_UNREAD: 'notifications-view_unread',
  VIEW_READ: 'notifications-view_read',
  MARK_AS_READ: 'notifications-mark_as_read',
  MARK_ALL_AS_READ: 'notifications-mark_all_as_read',
  VIEW_NOTIFICATION: 'notifications-view_notification',
  ROWS_PER_PAGE: 'notifications-rows_per_page',
  NAVIGATION: 'notifications-navigation'
};
