import { Avatar, Grid, Link, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../../helpers/formatUtils';
import { useGetNews } from '../../../../../hooks';
import { ButtonEnhanced, DotLoader, TooltipEnhanced, TypographyEnhanced } from '../../../../global';
import AppLogo from '../../../manage/applications/appLogo';
import NoDataState from '../noDataState';
import { logViewAll } from './utils';

const { WidgetContainer, ShowMoreText } = require('../../../../ui');
const { TRANSLATION_PATH, PATH } = require('./constants');

const News = () => {
  const { news, loading } = useGetNews({}, 0, 3, 'createdAt:DESC');
  const navigate = useNavigate();

  if (!loading && !news?.length) {
    return <NoDataState path={PATH} />;
  }

  return (
    <WidgetContainer title={TRANSLATION_PATH('title')}>
      <Grid item xs={12}>
        <TypographyEnhanced id={TRANSLATION_PATH('description')} />
      </Grid>

      {loading ? (
        <Grid item xs={12} m={2}>
          <DotLoader sx={{ position: 'relative' }} />
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <List>
              {(news || []).map((newsItem) => {
                // eslint-disable-next-line no-unused-vars
                const { id, title, description, author, imageUrl, link, displayLink, createdAt, thirdparty } = newsItem || {};

                return (
                  <ListItem alignItems='flex-start' key={id}>
                    <ListItemAvatar>
                      <Avatar alt={thirdparty?.name || title || ''} src={imageUrl || ''} />
                    </ListItemAvatar>
                    <ListItemText
                      color='primary'
                      primary={
                        <Grid container item justifyContent='space-between'>
                          <Grid item>
                            <TypographyEnhanced id={title} variant='h6' fontWeight='bold' />
                          </Grid>
                          <Grid item>
                            <TooltipEnhanced title={thirdparty?.name || ''}>
                              <AppLogo url={thirdparty?.logoUrl || ''} alt={thirdparty?.name || ''} style={{ width: 25, height: 25 }} />
                            </TooltipEnhanced>
                          </Grid>
                        </Grid>
                      }
                      secondary={
                        <Grid container>
                          <Grid container item justifyContent='space-between'>
                            <Grid item>
                              <Link href={link} target='_blank' rel='noopener,noreferrer'>
                                <TypographyEnhanced id={displayLink} color='primary' />
                              </Link>
                            </Grid>
                            <Grid item>
                              <TypographyEnhanced id={formatDate(createdAt)} variant='caption' />
                            </Grid>
                          </Grid>
                          <Grid item>
                            <ShowMoreText limit={250}>{description}</ShowMoreText>
                          </Grid>
                        </Grid>
                      }
                      secondaryTypographyProps={{ component: 'div', color: 'text.primary' }}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Grid container justifyContent='flex-end' item>
              <ButtonEnhanced variant='outlined' size='small' onClick={() => logViewAll(navigate)}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.viewAll')} />
              </ButtonEnhanced>
            </Grid>
          </Grid>
        </Grid>
      )}
    </WidgetContainer>
  );
};

export default News;
