import { FEEDBACK_TYPES } from '@anirudhm9/base-lib/lib/constants/api/feedback';
import { formatErrorMessage, isEmpty } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CREATE_FEEDBACK } from '../../../api/graphql/feedback';
import { ButtonEnhanced, toast, TypographyEnhanced } from '../../../components/global';
import { HTMLEditor, TextFieldEnhanced } from '../../../components/ui/form';
import { defaultMessages } from '../../../constants';
import { useOrgContext } from '../../../contexts';
import { FEEDBACK_MODAL_CONTENT, FEEDBACK_REQUIRED_FIELDS, handleFeedbackLogEvent } from '../constants';

const FeedbackModalContent = ({ toggle, type }) => {
  const { orgId } = useOrgContext();
  const [feedback, setFeedback] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorFields, setErrorFields] = useState({});

  const [createFeedback] = useMutation(CREATE_FEEDBACK);
  const location = useLocation();

  const handleSubmit = async () => {
    const errors = isEmpty(FEEDBACK_REQUIRED_FIELDS, feedback || {});
    setErrorFields(errors);

    if (!_.isEmpty(errors)) {
      toast.error(defaultMessages.requiredFields);
      return;
    }
    try {
      setLoading(true);
      const { name, description } = feedback || {};

      const path = location?.pathname;

      await createFeedback({
        variables: {
          data: {
            name,
            description,
            type,
            path,
            org: orgId
          }
        }
      });
      handleFeedbackLogEvent(type, feedback);
      toast.success('Submitted successfully!');
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setFeedback({});
      setLoading(false);
      toggle();
    }
  };

  const updateFeedback = (key, value) => {
    setFeedback((prev) => {
      return { ...prev, [key]: value };
    });
  };

  return (
    <Grid container item spacing={2}>
      <Grid item>
        <TypographyEnhanced id={FEEDBACK_MODAL_CONTENT[type].header} variant='h6' />
      </Grid>
      <Grid container item sx={{ flexDirection: 'column' }} spacing={2}>
        <Grid item>
          <TextFieldEnhanced
            id='name'
            key='feedbackTitle'
            label={FEEDBACK_MODAL_CONTENT[type].name}
            value={feedback?.name}
            onChange={(value) => updateFeedback('name', value)}
            hasError={!!errorFields?.['name']}
            errorText={errorFields?.['name']}
            required
          />
        </Grid>
        <Grid item>
          <HTMLEditor
            id='description'
            key='feedbackDescription'
            label={FEEDBACK_MODAL_CONTENT[type].description}
            value={feedback?.description}
            onChange={(value) => updateFeedback('description', value)}
            placeholder=''
          />
        </Grid>
      </Grid>
      <Grid item sx={{ marginLeft: 'auto' }}>
        <ButtonEnhanced loading={loading} onClick={handleSubmit}>
          Submit
        </ButtonEnhanced>
      </Grid>
    </Grid>
  );
};

export default FeedbackModalContent;

FeedbackModalContent.propTypes = {
  toggle: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(FEEDBACK_TYPES))
};
