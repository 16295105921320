// EVENTS.VIEWS.APP.MANAGE.SINGLE_APPLICATION

export const SINGLE_APPLICATION = {
  SECURITY_SCORE_DETAILS: 'single_application-security_score_details',
  SCOPE_TOOLTIP: 'single_application-scope_tooltip',
  VIEW_APPLICATION_HOMEPAGE: 'single_application-visit_homepage',
  VIEW_APPLICATION_LOGIN: 'single_application-visit_login_url',
  VIEW_ACTIVE_USER: 'single_application-view_active_user',
  VIEW_ARCHIVED_USER: 'single_application-view_archived_user',
  VIEW_CYBER_NEWS: 'single_application-view_cyber_news_article',
  CLICK_EDIT_APPLICATION: 'single_application-click_edit_details',
  UPDATE_APPLICATION: 'single_application-update_details',
  CANCEL_EDIT: 'single_application-update_details_cancel'
};
