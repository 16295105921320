import { Avatar, Badge, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { TooltipEnhanced } from '../../../../../global';
import { AUDIT_SUMMARY } from '../../constants';

const AuditSummary = ({ auditSummary }) => {
  return (
    <Grid container spacing={2}>
      {Object.values(AUDIT_SUMMARY).map((summary) => {
        const { name, value, color } = summary || {};
        return (
          <Grid item key={value}>
            <TooltipEnhanced title={name}>
              <Badge badgeContent={auditSummary?.[value] || 0} max={99} color='primary'>
                <Avatar sx={{ bgcolor: (theme) => theme?.palette?.[color]?.main, width: 28, height: 28 }}>{name?.[0] || ''}</Avatar>
              </Badge>
            </TooltipEnhanced>
          </Grid>
        );
      })}
    </Grid>
  );
};

AuditSummary.propTypes = {
  auditSummary: PropTypes.object
};
export default AuditSummary;
