import { compareSemanticVersions, convertEnumeration } from '@anirudhm9/base-lib/lib/utils';
import { useQuery } from '@apollo/client';
import { Inventory2Rounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { Badge, Card, CardContent, Collapse, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { COUNT_VULNERABILITIES, GET_VULNERABILITIES } from '../../../../../../api';
import { useOrgContext } from '../../../../../../contexts';
import { openLink } from '../../../../../../helpers/routing';
import { CardWithLoader, ChipEnhanced, IconButtonEnhanced, TooltipEnhanced, TypographyEnhanced } from '../../../../../global';
import { PaginationEnhanced } from '../../../../../ui';
import { DEFAULT_PAGE_SIZE } from '../../../../../ui/pagination/constants';
import { IssueLogo, SingleIssueBar } from '../../../../manage';
import AuditSummary from '../../library/auditSummary';
import { VulnerabilityCard } from '../../vulnerability';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  card: {
    textAlign: 'left',
    backgroundColor: theme.palette.background.default
  }
}));

const sanitiseVersion = (version) => {
  return (version || '')?.replace(/\^/g, '');
};

const ModuleCard = ({ module }) => {
  const classes = useStyles();

  const { orgId } = useOrgContext();

  const { name, type } = module || {};

  const {
    data: vulnerabilitiesCountData,
    loading: vulnerabilitiesCountLoading,
    error: vulnerabilitiesCountError
    // refetch: refetchProjectCount
  } = useQuery(COUNT_VULNERABILITIES, {
    variables: { where: { org: orgId, module: module?.id } },
    skip: !orgId || !module?.id
  });

  const vulnerabilitiesCount = useMemo(() => {
    if (vulnerabilitiesCountLoading || vulnerabilitiesCountError) {
      return 0;
    }
    return vulnerabilitiesCountData?.countVulnerabilities || 0;
  }, [vulnerabilitiesCountData?.countVulnerabilities, vulnerabilitiesCountError, vulnerabilitiesCountLoading]);

  const [open, setOpen] = useState(true);
  const [start, setStart] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { data: vulnerabilitiesData, loading: vulnerabilitiesLoading } = useQuery(GET_VULNERABILITIES, {
    variables: { where: { org: orgId, module: module?.id }, start, limit: pageSize, sort: 'severityLevel:DESC' },
    skip: !module?.id
  });

  const vulnerabilities = useMemo(() => {
    if (vulnerabilitiesLoading) {
      return [];
    }
    return vulnerabilitiesData?.vulnerabilities || [];
  }, [vulnerabilitiesData?.vulnerabilities, vulnerabilitiesLoading]);

  const auditSummary = useMemo(() => {
    if (!vulnerabilities?.length) {
      return {};
    }
    const groupedCriticality = _.groupBy(vulnerabilities, 'severity');
    return Object.keys(groupedCriticality || {}).reduce((result, severity) => {
      result[severity] = groupedCriticality?.[severity]?.length || 0;
      return result;
    }, groupedCriticality || {});
  }, [vulnerabilities]);

  const VersionChip = useCallback(
    ({ type }) => {
      const { version, wanted, latest } = module || {};

      const currentVersionString = sanitiseVersion(version || '');
      const wantedVersionString = sanitiseVersion(wanted || '');
      const latestVersionString = sanitiseVersion(latest || '');

      let color = 'secondary';
      let tooltip = '';

      const sanitisedVersion = sanitiseVersion(module?.[type] || '');
      if (!currentVersionString || !wantedVersionString || !latestVersionString) {
        return <ChipEnhanced id={module?.[type] ? `v${sanitisedVersion}` : 'N/A'} color={color} size='small' />;
      }

      switch (type) {
        case 'version':
          if (compareSemanticVersions(currentVersionString, wantedVersionString) < 0) {
            color = 'error';
            tooltip = 'Current version not up-to-date with requirements.';
          } else if (compareSemanticVersions(currentVersionString, latestVersionString) < 0) {
            color = 'warning';
            tooltip = 'Update available';
          }
          break;
        case 'wanted':
          if (compareSemanticVersions(wantedVersionString, latestVersionString) < 0) {
            color = 'warning';
            tooltip = 'Minimum required version but update available';
          } else {
            tooltip = 'Minimum required version';
          }
          break;
        case 'latest':
          tooltip = 'Latest version available';
          break;
        default:
          color = 'secondary';
      }

      if (!tooltip) {
        return <ChipEnhanced id={module?.[type] ? `v${sanitisedVersion}` : 'N/A'} color={color} size='small' />;
      }

      return (
        <TooltipEnhanced title={tooltip} placement='top' arrow={false}>
          <ChipEnhanced id={module?.[type] ? `v${sanitisedVersion}` : 'N/A'} color={color} size='small' />
        </TooltipEnhanced>
      );
    },
    [module]
  );

  const toggle = () => {
    setOpen((open) => !open);
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item spacing={2} justifyContent='space-between'>
            <Grid container item xs alignItems='center'>
              <Inventory2Rounded color='primary' />
              <TypographyEnhanced id={name} display='inline' fontWeight='bold' ml={1} mr={1} />
              <ChipEnhanced id={convertEnumeration(type, true)} variant='outlined' color='secondary' size='small' mr={1} />
              <AuditSummary auditSummary={auditSummary || {}} />
            </Grid>
            <Grid container item xs alignItems='center' justifyContent='flex-end' spacing={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Current</TableCell>
                    <TableCell>Wanted</TableCell>
                    <TableCell>Latest</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Grid item>Compatibility</Grid>
                    </TableCell>
                    <TableCell>
                      <Grid item>
                        <VersionChip type='version' />
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <VersionChip type='wanted' />
                    </TableCell>
                    <TableCell>
                      <VersionChip type='latest' />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {!vulnerabilitiesCount ? null : (
                <Grid container item spacing={2} justifyContent='space-between' alignItems='center'>
                  <Grid item>
                    <Badge badgeContent={module?.issues?.length || 0} showZero color='primary'>
                      <TypographyEnhanced id='Issues' display='inline' mr={1} color={!open ? 'primary' : 'default'} />
                    </Badge>
                    <IconButtonEnhanced onClick={toggle} color={!open ? 'primary' : 'default'}>
                      {!open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
                    </IconButtonEnhanced>
                  </Grid>
                  <Grid item>
                    <Badge badgeContent={vulnerabilitiesCount || 0} color='primary'>
                      <TypographyEnhanced id='Vulnerabilities' display='inline' mr={1} color={open ? 'primary' : 'default'} />
                    </Badge>
                    <IconButtonEnhanced onClick={toggle} color={open ? 'primary' : 'default'}>
                      {open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
                    </IconButtonEnhanced>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={open}>
              <Grid container spacing={2}>
                {vulnerabilitiesLoading ? (
                  <Grid item xs={12}>
                    <CardWithLoader loading={vulnerabilitiesLoading} />
                  </Grid>
                ) : (
                  <Grid container item spacing={2}>
                    {vulnerabilities.map((vulnerability) => {
                      const { id } = vulnerability || {};
                      return (
                        <Grid item xs={12} key={id}>
                          <VulnerabilityCard vulnerability={vulnerability} />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <PaginationEnhanced total={vulnerabilitiesCount} onChange={setStart} pageSize={pageSize} setPageSize={setPageSize} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={!open}>
              <Grid container spacing={2}>
                <Grid container item spacing={2}>
                  {module?.issues?.map((issue) => {
                    const { id } = issue || {};
                    return (
                      <Grid item xs={12} key={id}>
                        <SingleIssueBar
                          issue={issue}
                          onClick={() => {
                            openLink(`/app/manage/issues/${id}`);
                          }}
                          logo={<IssueLogo relation={issue?.relation} />}
                          cardProps={{ className: '' }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ModuleCard.propTypes = {
  module: PropTypes.object
};
export default ModuleCard;
