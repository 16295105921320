import { useTheme } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DEFAULT_OPTIONS } from './constants';

/**
 * Displays Donut Chart from eCharts
 * @see {@link  https://echarts.apache.org/en/option.html#series-pie} for eChart series options
 * @see {@link https://echarts.apache.org/examples/en/editor.html?c=pie-borderRadius} for playground
 *
 * @param {String} chartName
 * @param {Array.<{value: Number, name: String}>} seriesData
 * @param {String}  legend (optional) top or bottom. Default - legend hidden
 * @param {String}  roseType (optional) 'area' or 'radius'. Omit to show as regular donut chart
 * @returns {JSX}
 */

const DonutChart = ({ options, onEvents }) => {
  const theme = useTheme();

  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (!options) {
      return;
    }

    const { palette, typography } = theme || {};
    const { background, text } = palette || {};
    const defaultOptions = _.cloneDeep(DEFAULT_OPTIONS);

    const { title: defaultTitle, tooltip: defaultTooltip, legend: defaultLegend = {}, series: defaultSeries = [] } = defaultOptions || {};

    const { title, tooltip, legend, series, ...rest } = options || {};

    defaultOptions.title = {
      ...defaultTitle,
      textStyle: {
        color: text?.primary,
        fontWeight: 'normal',
        fontFamily: typography?.fontFamily
      },
      ...(title || {})
    };

    defaultOptions.tooltip = {
      ...defaultTooltip,
      backgroundColor: background?.default,
      textStyle: { color: text?.primary },
      ...(tooltip || {})
    };

    defaultOptions.legend = {
      ...defaultLegend,
      textStyle: { color: text?.primary },
      ...(legend || {})
    };

    defaultOptions.series = (defaultSeries || []).map((item, index) => {
      const seriesData = series[index] || {};
      return {
        ...(item || {}),
        ...(seriesData || {})
      };
    });

    setChartOptions({ ...(defaultOptions || {}), ...(rest || {}) });
  }, [options, theme]);

  return <ReactEcharts option={chartOptions} onEvents={onEvents} />;
};

DonutChart.propTypes = {
  options: PropTypes.object.isRequired,
  onEvents: PropTypes.object
};

export default DonutChart;
