import { SignalCellular0BarRounded, SignalCellular1BarRounded, SignalCellular3BarRounded, SignalCellular4BarRounded } from '@mui/icons-material';
import { EVENTS } from '../../../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.app.manage.applications.viewApplication.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.APP.MANAGE.APPLICATIONS[event];
};

export const securityLevels = {
  LOW: {
    id: 'riskRatings.low',
    name: 'Low',
    icon: <SignalCellular1BarRounded />,
    value: 'LOW'
  },
  MEDIUM: {
    id: 'riskRatings.medium',
    name: 'Medium',
    icon: <SignalCellular3BarRounded />,
    value: 'MEDIUM'
  },
  HIGH: {
    id: 'riskRatings.high',
    name: 'High',
    icon: <SignalCellular4BarRounded />,
    value: 'HIGH'
  },
  NOT_SET: {
    id: 'riskRatings.notSet',
    name: 'Not set',
    icon: <SignalCellular0BarRounded />,
    value: 'NOT_SET'
  }
};

export const getSecurityLevel = (score) => {
  let result = securityLevels.NOT_SET;

  if (score <= 33) {
    result = securityLevels.LOW;
  } else if (score <= 66) {
    result = securityLevels.MEDIUM;
  } else if (score <= 100) {
    result = securityLevels.HIGH;
  }

  return result;
};

export const THIRD_PARTY_TAWK_DOC = 'https://avertro.tawk.help/article/how-third-party-scores-are-assigned';
