const REQUIRED_FIELDS = ['firstName', 'lastName', 'email'];

const DEFAULT_USER_DATA = {
  firstName: '',
  lastName: '',
  email: ''
};

const PERMISSIONS = [
  { label: 'User Accounts', description: 'Provides information about changes to various user security settings' },
  { label: 'Devices', description: 'Shows what devices are being used to access a workspace account' },
  { label: 'OAuth Token Audit', description: 'Identifies third party applications that have access to the workspace account' },
  { label: 'Account Parameters', description: 'Identifies general information about all accounts' }
];

const GSUITE_STATES = {
  ATTEMPTED: 'ATTEMPTED',
  FAILED: 'FAILED'
};

export { REQUIRED_FIELDS, DEFAULT_USER_DATA, PERMISSIONS, GSUITE_STATES };
