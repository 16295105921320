/* eslint-disable quotes */

// components.application.dashboard.widgets.webSecurityHeaders

export const WEB_SECURITY_HEADERS = {
  title: 'Web Security Headers',
  description:
    'HTTP security headers are a fundamental part of website security. Implement them to protect your website against the types of attacks that your site is most likely to come across.',
  disconnected: {
    title: "Once you've provided your company's URL, results from scanning the URL will be displayed here.",
    description:
      'Information required to provide insights here relies on one or more connections to other systems. Either you have not connected the relevant applications or there is an issue with the connections at the moment.',
    button: 'UPDATE COMPANY INFO'
  },
  buttons: {
    linkAccount: 'LINK COMPANY ACCOUNTS',
    newScan: 'PERFORM NEW SCAN',
    viewAll: 'VIEW ALL'
  }
};
