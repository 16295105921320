import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { forwardRef, useCallback } from 'react';
import { IconButtonEnhanced, TooltipEnhanced } from '../../../../../../components/global';
import { COLUMN_WIDTH } from '../../constants';

const ScrollHeader = forwardRef((_props, ref) => {
  const handleScroll = useCallback(
    (type) => {
      if (!ref?.current) {
        return;
      }

      switch (type) {
        case 'LEFT':
          ref.current.scrollBy({ left: -COLUMN_WIDTH, behavior: 'smooth' });
          break;
        default:
          ref.current.scrollBy({ left: COLUMN_WIDTH, behavior: 'smooth' });
      }
    },
    [ref]
  );

  return (
    <Grid container item xs={12} justifyContent='space-between'>
      <Grid item>
        <TooltipEnhanced title='Scroll Left' showInfoModal={false}>
          <IconButtonEnhanced onClick={() => handleScroll('LEFT')}>
            <ArrowBackIosNewRounded />
          </IconButtonEnhanced>
        </TooltipEnhanced>
      </Grid>
      <Grid item>
        <TooltipEnhanced title='Scroll Right' showInfoModal={false}>
          <IconButtonEnhanced onClick={() => handleScroll('RIGHT')}>
            <ArrowForwardIosRounded />
          </IconButtonEnhanced>
        </TooltipEnhanced>
      </Grid>
    </Grid>
  );
});

ScrollHeader.displayName = 'ScrollHeader';
export default ScrollHeader;
