import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { COUNT_APPLICATIONS } from '../../api';
import { useOrgContext } from '../../contexts';

/**
 * Custom hook to encapsulate COUNT_APPLICATIONS
 * usage example:
 *
 * const { applicationsCount, loading, error, refetch } = useApplicationsCount()
 *
 * @param {JSON} where
 * @returns {{
 * applicationsCount: Number,
 * loading: Boolean,
 * Error: ApolloError,
 * refetch: () => Promise<ApolloQueryResult<any>>
 * }}
 */
const useApplicationsCount = (where) => {
  const { orgId } = useOrgContext();

  const {
    data: applicationsCountData,
    loading: applicationsCountLoading,
    error: applicationsCountError,
    refetch: applicationsCountRefetch
  } = useQuery(COUNT_APPLICATIONS, {
    variables: { where: { org: orgId, _or: [{ archived: false }, { archived_null: true }], ...(where || {}) } },
    skip: !orgId
  });

  const applicationsCount = useMemo(() => {
    if (applicationsCountLoading || applicationsCountError) {
      return 0;
    }
    return applicationsCountData?.countApplications || 0;
  }, [applicationsCountData, applicationsCountError, applicationsCountLoading]);

  return {
    applicationsCount,
    loading: applicationsCountLoading,
    error: applicationsCountError,
    refetch: applicationsCountRefetch
  };
};

export default useApplicationsCount;
