import { FilterListRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { IconButtonEnhanced } from '../../../global';
import FilterDrawer from './filterDrawer';

const RewardFilter = ({ filterTypes = [], updateFilters }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <FilterDrawer isOpen={isOpen} toggleDrawer={toggleDrawer} updateFilters={updateFilters} filterTypes={filterTypes} />
      <IconButtonEnhanced onClick={() => toggleDrawer()} size='small'>
        {/* <Badge badgeContent={4} color='secondary'> */}
        <FilterListRounded />
        {/* </Badge> */}
      </IconButtonEnhanced>
    </Fragment>
  );
};

RewardFilter.propTypes = {
  filterTypes: PropTypes.array,
  updateFilters: PropTypes.func
};

export default RewardFilter;
