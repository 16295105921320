import { hasErrors, validatePassword } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { LockRounded } from '@mui/icons-material';
import { Avatar, Box, CssBaseline, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { RESET_PASSWORD } from '../../../api';
import { ButtonEnhanced, toast, TypographyEnhanced } from '../../../components/global';
import { TextFieldEnhanced } from '../../../components/ui/form';
import { defaultMessages } from '../../../constants';
import { clearCache } from '../../../helpers/routing';
import { useIsDesktop, useKeyPress, useLogEvent } from '../../../hooks';
import { defaultData, LOG_EVENT_PATH, requiredFields, TRANSLATION_PATH } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(10, 0),
    justifyContent: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  link: {
    textDecoration: 'none'
  },
  attribute: {
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const ResetPassword = () => {
  useLogEvent(LOG_EVENT_PATH('PAGE_VISITED'));

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const [localData, setLocalData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [resetPassword] = useMutation(RESET_PASSWORD);

  const handleResetPassword = async () => {
    const errors = hasErrors(requiredFields, localData);
    if (errors?.length) {
      setError(defaultMessages.requiredFields);
      return;
    }

    try {
      setError('');
      setLoading(true);
      clearCache();
      const res = await resetPassword({ variables: localData });
      const data = res?.data?.resetPassword;
      if (data?.user?.id) {
        toast.success(t(TRANSLATION_PATH('alerts.resetSuccess')));
        navigate('/user/login');
      } else {
        toast.error(t(TRANSLATION_PATH('alerts.error')));
      }
    } catch (error) {
      console.error(error);
      setError(defaultMessages.defaultError);
      toast.error(t(TRANSLATION_PATH('alerts.error')));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { code } = queryString.parse(location.search) || {};
    setLocalData((data) => {
      return { ...data, code };
    });
  }, [location.search]);

  const checkPassword = () => {
    const errors = hasErrors(requiredFields, localData);

    if (errors?.length) {
      setError(defaultMessages.requiredFields);
      return;
    }
    let check = true;
    if (localData.password !== localData.passwordConfirmation) {
      check = false;
      setError(t(TRANSLATION_PATH('alerts.passwordMismatch')));
    } else {
      const isPasswordCompliant = validatePassword(localData.password);
      if (!isPasswordCompliant) {
        setError(defaultMessages.passwordCompliance);
        check = false;
      }
    }

    if (check) {
      setError();
      handleResetPassword();
    }
  };

  useKeyPress('Enter', () => {
    checkPassword();
  });

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={6} component={isDesktop ? Paper : 'div'}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockRounded />
          </Avatar>
          <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h5' />
          <Box className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextFieldEnhanced
                  id='password'
                  label={TRANSLATION_PATH('newPassword.label')}
                  placeholder={TRANSLATION_PATH('newPassword.placeholder')}
                  type='password'
                  required
                  autoComplete='do-not-autofill'
                  variant='outlined'
                  fullWidth
                  value={localData?.password || ''}
                  onChange={(value) =>
                    setLocalData((prev) => {
                      return { ...prev, password: value };
                    })
                  }
                  hasError={!!error}
                  errorText={error}
                  helperText=''
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldEnhanced
                  id='passwordConfirmation'
                  label={TRANSLATION_PATH('confirmPassword.label')}
                  placeholder={TRANSLATION_PATH('confirmPassword.placeholder')}
                  type='password'
                  required
                  autoComplete='do-not-autofill'
                  variant='outlined'
                  fullWidth
                  value={localData?.passwordConfirmation || ''}
                  onChange={(value) =>
                    setLocalData((prev) => {
                      return { ...prev, passwordConfirmation: value };
                    })
                  }
                  hasError={!!error}
                  errorText={error}
                  helperText=''
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonEnhanced fullWidth variant='contained' onClick={() => checkPassword()} loading={loading}>
                  <TypographyEnhanced id={TRANSLATION_PATH('buttons.reset')} />
                </ButtonEnhanced>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

ResetPassword.propTypes = {};

export default ResetPassword;
