import { useReactiveVar } from '@apollo/client';
import { createTheme, StyledEngineProvider, ThemeProvider, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { themeVar } from '../../api';
import { BackToTop } from '../../components/ui';
import { Header } from '../../containers/navigation';
import { DRAWER_WIDTH_CLOSED } from '../../containers/navigation/header/constants';
import { useIsDesktop } from '../../hooks';
import { SYSTEM_THEME } from '../../styles/theme';

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  header: {
    display: 'flex',
    flex: '0 0 auto'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
  },
  mobileContent: {
    '-webkit-overflow-scrolling': 'touch',
    flexGrow: 1,
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  iOSPadding: {
    height: iOS ? theme.spacing(2) : 0
  },
  drawer: {
    marginRight: DRAWER_WIDTH_CLOSED
  }
}));

const AppLayout = (props) => {
  const classes = useStyles();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const localTheme = useReactiveVar(themeVar);
  const theme = localTheme || SYSTEM_THEME(prefersDarkMode);
  const applicationTheme = createTheme(theme);
  const isDesktop = useIsDesktop();

  return (
    <StyledEngineProvider injectFirst>
      <Header {...props} />
      <ThemeProvider theme={applicationTheme}>
        <div className={classes.root}>
          {isDesktop && <div className={classes.drawer} />}
          <main className={isDesktop ? classes.content : classes.mobileContent}>
            <div className={classes.appBarSpacer} />
            {props.children}
            {/* <div className={isDesktop ? classes.appBarSpacer : null} /> */}
            <div className={classes.iOSPadding} />
          </main>
          <BackToTop />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

AppLayout.propTypes = {
  children: PropTypes.element
};

export default AppLayout;
