import { RadioButtonCheckedRounded } from '@mui/icons-material';
import { AppBar, Divider, Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import { wsContext } from '../../../api';
import { IconButtonEnhanced } from '../../../components/global';
import { useOrgContext } from '../../../contexts/orgContext';
import { useIsDesktop } from '../../../hooks';
import { refreshLogin } from '../../../library';
import SideMenu from '../sideMenuOG';
import SwipeableDrawer from '../swipeableDrawer';
import ToolBar from '../toolbar';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_OPEN } from './constants';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24,
    justifyContent: 'space-between'
  },
  appBarFixed: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH_OPEN,
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawer: {
    width: DRAWER_WIDTH_OPEN,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    backgroundColor: 'paper',
    width: DRAWER_WIDTH_OPEN,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
  },
  drawerClose: {
    backgroundColor: 'paper',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH_CLOSED
    }
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH_OPEN,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH_CLOSED
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}));

const Header = (props) => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const { org } = useOrgContext();
  const { onboarded } = org || {};
  const { userSocket } = wsContext || {};

  const [isDrawerFixed, setIsDrawerFixed] = useState(false);
  const [open, setOpen] = useState(false);
  const [bottomDrawerOpen, setBottomDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleBottomDrawer = () => {
    setBottomDrawerOpen((open) => !open);
  };

  const handleDrawerState = () => {
    setIsDrawerFixed(!isDrawerFixed);
  };

  useEffect(() => {
    if (!userSocket) {
      return;
    }
    userSocket.on('refetchAccessToken', () => {
      refreshLogin();
    });
  }, [userSocket]);

  return (
    <Fragment>
      <AppBar position={'fixed'} className={classes.appBarFixed} sx={{ paddingRight: '0 !important' }}>
        <ToolBar open={open} handleDrawerOpen={handleDrawerOpen} isDrawerFixed={isDrawerFixed} toggleBottomDrawer={toggleBottomDrawer} {...props} />
      </AppBar>

      {onboarded ? (
        <Fragment>
          {isDesktop ? (
            <Drawer
              elevation={1}
              variant={'permanent'}
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open
                })
              }}
              open={open}
              onClose={handleDrawerClose}
              onMouseEnter={handleDrawerOpen}
              onMouseLeave={handleDrawerClose}
            >
              <div className={classes.toolbarIcon}>
                <IconButtonEnhanced onClick={handleDrawerState}>
                  <RadioButtonCheckedRounded {...(isDrawerFixed && { color: 'primary' })} />
                </IconButtonEnhanced>
              </div>
              <Divider />
              <SideMenu {...props} drawerOpen={open || isDrawerFixed} {...(!isDrawerFixed && { setDrawerOpen: setOpen })} />
            </Drawer>
          ) : (
            <SwipeableDrawer anchor='bottom' open={bottomDrawerOpen} setOpen={setBottomDrawerOpen}>
              <SideMenu {...props} drawerOpen={bottomDrawerOpen} setDrawerOpen={setBottomDrawerOpen} toggleBottomDrawer={toggleBottomDrawer} />
            </SwipeableDrawer>
          )}
        </Fragment>
      ) : null}
    </Fragment>
  );
};
export default Header;
