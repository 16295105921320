import { HEADERS, MDN_WEB_HEADERS } from '@anirudhm9/base-lib/lib/constants';
import { isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { OpenInNewRounded } from '@mui/icons-material';
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { openLink } from '../../../../../../../helpers/routing';
import { CardWithLoader, IconButtonEnhanced, TypographyEnhanced } from '../../../../../../global';

const RawHeadersTable = ({ scan, loading }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const { headers, rawHeaders = [], missingHeaders, validationErrors } = scan || {};
    const rawHeadersCopy = _.cloneDeep(rawHeaders || []);

    const essentialsHeaderValues = Object.keys(headers || [])
      .filter((header) => headers[header])
      .map((header) => header.toLowerCase());

    const missingHeaderValues = Object.keys(headers || [])
      .filter((header) => !headers[header])
      .map((header) => header.toLowerCase());

    const validationErrorsValues = Object.keys(validationErrors || []).map((header) => header.toLowerCase());

    for (const missingHeader of Object.keys(missingHeaders || {})) {
      const included = rawHeadersCopy.find((header) => isIDEqual(header?.key || '', missingHeader || '', true));
      if (!included) {
        rawHeadersCopy.push({ key: (missingHeader || '').toLowerCase(), value: 'Not found' });
      }
    }

    const headerColor = (header) => {
      let color = '';
      const missing = (missingHeaderValues || []).includes(header);
      const warning = (validationErrorsValues || []).includes(header);
      const success = (essentialsHeaderValues || []).includes(header);

      if (missing) {
        color = 'error';
      } else if (warning) {
        color = 'warning';
      } else if (success) {
        color = 'success';
      }
      return color;
    };

    const rows = (rawHeadersCopy || []).reduce((result, header) => {
      const { key, value } = header || {};
      const color = headerColor(key);
      const { remediation } = HEADERS[key] || {};
      const { link = `${MDN_WEB_HEADERS}/${key}` } = remediation || {};

      result = [
        ...(result || []),
        {
          key,
          label: <TypographyEnhanced variant='body1' id={key} sx={{ color: (theme) => theme.palette?.[color]?.main }} />,
          value: (
            <Fragment>
              <TypographyEnhanced variant='body1' id={value} display='inline' />
              <IconButtonEnhanced onClick={() => openLink(link)} color='primary'>
                <OpenInNewRounded />
              </IconButtonEnhanced>
            </Fragment>
          )
        }
      ];
      return result;
    }, []);

    setRows(rows);
  }, [scan]);

  if (!scan) {
    return null;
  }

  return (
    <CardWithLoader loading={loading} elevation={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TypographyEnhanced id='Headers' variant='h6' />
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table size='small' aria-label='a dense table'>
              <TableBody>
                {rows.map((row) => {
                  const { key, label, value } = row || {};
                  return (
                    <TableRow key={key}>
                      <TableCell align='left'>{label}</TableCell>
                      <TableCell align='left'>{value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </CardWithLoader>
  );
};

RawHeadersTable.propTypes = {
  scan: PropTypes.object,
  loading: PropTypes.bool
};
export default RawHeadersTable;
