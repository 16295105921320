/* eslint-disable quotes */
// views.user.forgotPassword

export const FORGOT_PASSWORD = {
  title: 'Forgot Password',
  email: { label: 'Email', placeholder: 'Your email address' },
  inactivity: 'You have been logged out due to inactivity',
  buttons: { reset: 'Reset', back: 'Back to Log In' },
  alerts: {
    emailSent: 'Password reset email sent',
    error: 'There was an error resetting your email'
  }
};
