import {
  CloudSummary,
  DevicesInUse,
  MultiFactorAuth,
  // News,
  PasswordStrength,
  ThirdPartyApplications,
  UserSummary
} from '../../../../components/application';
import IssuesSummary from '../../../../components/application/dashboard/widgets/issuesSummary';
import { EVENTS } from '../../../../library';

export const TRANSLATION_PATH = (route) => {
  return `views.app.dashboard.${route}`;
};

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.APP.DASHBOARD[event];
};

export const { DASHBOARD: DASHBOARD_EVENTS } = EVENTS.VIEWS.APP || {};

export const WIDGETS = [
  // hidden for external user alpha testing
  // {
  //   id: 'NEWS',
  //   component: <News />
  // },
  {
    id: 'APPLICATIONS',
    component: <ThirdPartyApplications />,
    googleAuth: true // display this widget if org has linked gSuite
  },
  {
    id: 'ISSUES',
    component: <IssuesSummary />
  },
  // {
  //   id: 'WEB_SECURITY_HEADERS',
  //   component: <WebSecurityHeaders />
  // },
  {
    id: 'AWS_SUMMARY',
    component: <CloudSummary />
  },
  {
    id: 'USER_SUMMARY',
    component: <UserSummary />,
    googleAuth: true // display this widget if org has linked gSuite
  },
  {
    id: 'MFA',
    component: <MultiFactorAuth />
  },
  {
    id: 'DEVICES_IN_USE',
    component: <DevicesInUse />,
    googleAuth: true
  },
  {
    id: 'PASSWORD_STENGTH',
    component: <PasswordStrength />,
    googleAuth: true
  }
];
