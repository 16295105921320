import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { PasswordFieldEnhanced, TextFieldEnhanced } from '../../../../ui/form';
import AvatarEdit from '../avatarEdit';
import { TRANSLATION_PATH } from './constants';

const UserForm = ({ showProfilePicture = false, onProfileChange = () => {}, localData, setLocalData, errorFields, user }) => {
  const { provider = 'local' } = user || {};

  const isSSOUser = provider !== 'local';

  return (
    <Grid container item spacing={2} mb={2}>
      {showProfilePicture && (
        <Grid container item justifyContent='center' alignItems='center'>
          <AvatarEdit user={user || localData} value={localData?.imageUrl} onChange={onProfileChange} edit />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        {/* User name */}
        <TextFieldEnhanced
          id='firstName'
          label={TRANSLATION_PATH('firstName.label')}
          placeholder={TRANSLATION_PATH('firstName.placeholder')}
          required
          value={localData?.firstName || ''}
          onChange={(value) =>
            setLocalData((prev) => {
              return {
                ...prev,
                firstName: value
              };
            })
          }
          hasError={!!errorFields?.firstName}
          errorText={errorFields?.firstName}
          helperText=''
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldEnhanced
          id='lastName'
          label={TRANSLATION_PATH('lastName.label')}
          placeholder={TRANSLATION_PATH('lastName.placeholder')}
          required
          value={localData?.lastName || ''}
          onChange={(value) =>
            setLocalData((prev) => {
              return {
                ...prev,
                lastName: value
              };
            })
          }
          hasError={!!errorFields?.lastName}
          errorText={errorFields?.lastName}
          helperText=''
        />
      </Grid>

      {/* Email */}
      <Grid item xs={12}>
        <TextFieldEnhanced
          id='email'
          label={TRANSLATION_PATH('email.label')}
          placeholder={TRANSLATION_PATH('email.placeholder')}
          required
          value={localData?.email || ''}
          onChange={(value) =>
            setLocalData((prev) => {
              return {
                ...prev,
                email: value.toLowerCase(),
                username: value.toLowerCase()
              };
            })
          }
          disabled={!!isSSOUser}
          hasError={!!errorFields?.email}
          errorText={errorFields?.email}
          helperText=''
        />
      </Grid>

      {/* Hide password fields for SSO user */}
      {!user || (user && isSSOUser) ? null : (
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <PasswordFieldEnhanced
              id='oldPassword'
              label={TRANSLATION_PATH('oldPassword.label')}
              placeholder={TRANSLATION_PATH('oldPassword.placeholder')}
              value={localData?.oldPassword || ''}
              required
              onChange={(value) =>
                setLocalData((prev) => {
                  return { ...prev, oldPassword: value };
                })
              }
              errorText={errorFields?.oldPassword}
              hasError={!!errorFields?.oldPassword}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordFieldEnhanced
              id='password'
              label={TRANSLATION_PATH('newPassword.label')}
              placeholder={TRANSLATION_PATH(user ? 'newPassword.placeholder' : 'password.placeholder')}
              value={localData?.password || ''}
              required
              onChange={(value) =>
                setLocalData((prev) => {
                  return { ...prev, password: value };
                })
              }
              errorText={errorFields?.password}
              hasError={!!errorFields?.password}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordFieldEnhanced
              id='passwordConfirmation'
              label={TRANSLATION_PATH('confirmPassword.label')}
              placeholder={TRANSLATION_PATH('confirmPassword.placeholder')}
              value={localData?.passwordConfirmation || ''}
              required
              onChange={(value) =>
                setLocalData((prev) => {
                  return { ...prev, passwordConfirmation: value };
                })
              }
              errorText={errorFields?.passwordConfirmation}
              hasError={!!errorFields?.passwordConfirmation}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

UserForm.propTypes = {
  showProfilePicture: PropTypes.bool,
  onProfileChange: PropTypes.func,
  localData: PropTypes.object,
  setLocalData: PropTypes.func,
  errorFields: PropTypes.any,
  user: PropTypes.object,
  register: PropTypes.bool
};

export default UserForm;
