import { isIDEqual } from '@anirudhm9/base-lib/lib/utils';
import { useQuery } from '@apollo/client';
import { Container, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_SCENARIO, GET_SCENARIO_MAPPINGS } from '../../../../../api';
import { ButtonEnhanced, DotLoader } from '../../../../../components/global';
import { Heading } from '../../../../../components/ui';
import { HTMLEditor, SelectEnhanced, TextFieldEnhanced } from '../../../../../components/ui/form';
import { experimentalMode } from '../../../../../constants/defaults';
import { useOrgContext, useThreatModellingContext } from '../../../../../contexts';
import { DEFAULT_SCENARIO } from '../constants';
import ThreatModellingQuestionModal from '../questionModal';
import ThreatModellingColumns from './columns';
import ScenarioPrompt from './prompt';
import ThreatModellingTemplates from './templates';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}));

const ThreatModellingBuilder = () => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const editorRef = useRef();

  const { orgId, org } = useOrgContext();
  const {
    categories,
    loading,
    saveScenario,
    deleteScenario,
    selectedFramework,
    setSelectedFramework,
    isMitreFrameworkSelected,
    promptLoading,
    promptDescription,
    generateScenarioFromPrompt,
    saveTemplate,
    selectedTemplate,
    setSelectedTemplate
  } = useThreatModellingContext();

  const {
    data: scenarioData,
    loading: scenarioLoading,
    error: scenarioError,
    refetch: refetchScenario
  } = useQuery(GET_SCENARIO, {
    variables: {
      id: params?.id
    },
    skip: !params?.id,
    fetchPolicy: 'network-only'
  });

  const {
    data: scenarioMappingsData,
    loading: scenarioMappingsLoading,
    refetch: refetchScenarioMappings
  } = useQuery(GET_SCENARIO_MAPPINGS, {
    variables: {
      id: params?.id,
      org: orgId
    },
    skip: !params?.id,
    fetchPolicy: 'network-only'
  });

  const [localScenario, setLocalScenario] = useState(DEFAULT_SCENARIO);

  const scenario = useMemo(() => {
    if (scenarioLoading) {
      return;
    }
    if (params?.id && (scenarioError || !scenarioData?.scenario)) {
      navigate('/app/assessment/threat-modeling');
    }

    if (!isIDEqual(selectedFramework?.id, scenarioData?.scenario?.framework?.id)) {
      navigate('/app/assessment/threat-modeling/scenario');
      setLocalScenario(DEFAULT_SCENARIO);
    }

    return scenarioData?.scenario;
  }, [navigate, params?.id, scenarioData?.scenario, scenarioError, scenarioLoading, selectedFramework?.id]);

  const scenarioMappings = useMemo(() => {
    if (scenarioMappingsLoading || !scenarioMappingsData?.getScenarioMappings) {
      return;
    }

    return scenarioMappingsData?.getScenarioMappings || {};
  }, [scenarioMappingsData?.getScenarioMappings, scenarioMappingsLoading]);

  const [mappings, setMappings] = useState({});
  const [templateDrawerOpen, setTemplateDrawerOpen] = useState(false);

  useEffect(() => {
    if (!scenario) {
      return;
    }
    setLocalScenario(scenario);
  }, [scenario]);

  useEffect(() => {
    if (!scenarioMappings) {
      return;
    }
    setMappings(scenarioMappings);
  }, [scenarioMappings]);

  const onChange = useCallback((key, value) => {
    setLocalScenario((scenario) => {
      return {
        ...(scenario || {}),
        [key]: value
      };
    });
  }, []);

  const Editor = useCallback(
    ({ promptDescription }) => {
      return (
        <HTMLEditor
          label={'Description'}
          defaultValue={promptDescription || scenario?.description || ''}
          onChange={(value) => onChange('description', value)}
          placeholder=''
          ref={editorRef}
          disabled={promptLoading}
        />
      );
    },
    [onChange, promptLoading, scenario?.description]
  );

  const handleSave = useCallback(async () => {
    try {
      const scenario = await saveScenario(localScenario, mappings);
      if (!params?.id) {
        navigate(scenario?.id);
      } else {
        await refetchScenario();
        await refetchScenarioMappings();
      }
    } catch (error) {
      console.error(error);
    }
  }, [saveScenario, localScenario, mappings, params?.id, refetchScenario, refetchScenarioMappings, navigate]);

  const handleSaveTemplate = useCallback(async () => {
    try {
      const { name, description } = localScenario || {};
      await saveTemplate({ name, description }, mappings);
      setTemplateDrawerOpen(true);
    } catch (error) {
      console.error(error);
    }
  }, [localScenario, mappings, saveTemplate]);

  const handleDelete = useCallback(async () => {
    if (!localScenario?.id) {
      return;
    }
    await deleteScenario(localScenario?.id);
  }, [deleteScenario, localScenario?.id]);

  const toggleTemplateDrawer = () => {
    setTemplateDrawerOpen((open) => !open);
  };

  const onTemplateSelect = (template) => {
    setSelectedTemplate(template);
    setMappings(template?.mappings);
  };

  const onReset = () => {
    setLocalScenario(scenario || DEFAULT_SCENARIO);
    setMappings(scenarioMappings || {});
    editorRef?.current?.reset?.();
  };

  const handlePromptSave = async () => {
    if (!localScenario?.prompt) {
      return;
    }

    const scenario = await generateScenarioFromPrompt(localScenario?.prompt || '');
    setLocalScenario(scenario);
    setMappings(scenario?.mappings || {});
  };

  if (!categories?.length) {
    return <DotLoader />;
  }

  return (
    <Container component='main' className={classes.root} maxWidth={false}>
      <Grid container justifyContent='space-between' alignItems='center' mb={2}>
        <Grid item xs>
          <Heading id={params?.id ? 'Edit Scenario' : 'Build Scenario'} hideDivider containerProps={{ mb: 0 }} />
        </Grid>
        {!params?.id && (
          <Grid item>
            <ButtonEnhanced size='sm' onClick={toggleTemplateDrawer}>
              Templates
            </ButtonEnhanced>
            <ThreatModellingTemplates open={templateDrawerOpen} toggle={toggleTemplateDrawer} onSelect={onTemplateSelect} selectedTemplate={selectedTemplate} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {experimentalMode && (
        <Grid item xs={12}>
          <SelectEnhanced
            options={org?.frameworks || []}
            value={selectedFramework?.id}
            onChange={(value) => {
              const framework = org?.frameworks?.find((framework) => isIDEqual(framework?.id, value));
              setSelectedFramework(framework);
            }}
          />
        </Grid>
      )}
      <Grid container spacing={2}>
        {!params?.id && isMitreFrameworkSelected && (
          <ScenarioPrompt
            defaultValue={scenario?.prompt || ''}
            value={localScenario?.prompt || ''}
            onChange={(value) => onChange('prompt', value)}
            onSave={handlePromptSave}
          />
        )}

        <Grid item xs={12}>
          <TextFieldEnhanced
            type='text'
            label='Name'
            id='name'
            placeholder='Scenario'
            value={localScenario?.name || ''}
            onChange={(value) => {
              onChange('name', value);
            }}
            required
            disabled={promptLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor promptDescription={promptDescription || ''} />
        </Grid>
        <Grid container item spacing={2} justifyContent='flex-end'>
          <Grid item>
            <ButtonEnhanced variant='outlined' color='error' onClick={onReset} loading={promptLoading}>
              Reset
            </ButtonEnhanced>
          </Grid>
          {!localScenario?.id && (
            <Grid item>
              <ButtonEnhanced
                size='sm'
                onClick={handleSaveTemplate}
                color='primary'
                variant='outlined'
                loading={promptLoading || loading}
                disabled={!localScenario?.name || !Object.keys(mappings || {}).length}
              >
                Save as Template
              </ButtonEnhanced>
            </Grid>
          )}
          {localScenario?.id && (
            <Grid item>
              <ButtonEnhanced size='sm' onClick={handleDelete} color='error' loading={promptLoading || loading}>
                Delete
              </ButtonEnhanced>
            </Grid>
          )}
          <Grid item>
            <ButtonEnhanced
              size='sm'
              onClick={handleSave}
              disabled={!localScenario?.name || !Object.keys(mappings || {}).length}
              loading={loading || promptLoading}
            >
              {localScenario?.id ? 'Update' : 'Add'}
            </ButtonEnhanced>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ThreatModellingColumns loading={loading} categories={categories} mappings={mappings} setMappings={setMappings} />
        </Grid>
      </Grid>
      <ThreatModellingQuestionModal />
    </Container>
  );
};

export default ThreatModellingBuilder;
