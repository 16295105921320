import { preventEnter } from '@anirudhm9/base-lib/lib/utils';
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CheckboxEnhanced from '../../ui/form/checkbox';
import ButtonEnhanced from '../button';
import Dropzone from '../dropzone';

const DynamicInput = ({ input, localData, setLocalData }) => {
  const { label, value, type, required, disabled, hasError, helperText, errorText, color, placeholder, options, hasOther, defaultValue } = input || {};

  const saveOtherValue = (questionId, otherText) => {
    const dataToSave = localData?.other || {};
    dataToSave[questionId] = otherText;

    setLocalData({ ...localData, other: dataToSave });
  };

  let render;
  switch (type) {
    case 'select':
      render = (
        <Grid key={value} item xs={12}>
          <FormControl variant='outlined' fullWidth onKeyDown={preventEnter}>
            <Typography id={`input-label-${value}`}>{label}</Typography>
            <Select
              labelId={value}
              id={`select-${value}`}
              value={options.find((option) => option.value === localData?.[value])?.value ?? ''}
              onChange={(e) => setLocalData({ ...localData, [value]: e.target.value })}
              required={required}
              disabled={disabled}
              error={hasError}
            >
              {(options || []).map((option, index) => (
                <MenuItem key={index} value={option?.value} name={option?.label}>
                  {option?.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={hasError}>{hasError ? errorText : helperText}</FormHelperText>
            {hasOther && localData?.[value] === 'other' && (
              <TextField
                id={value + 'other'}
                value={localData?.other?.[value] || ''}
                onChange={(e) => saveOtherValue(value, e.target.value)}
                placeholder={'Other'}
                error={hasError}
                helperText={hasError ? errorText : helperText}
                type='text'
                autoComplete='off'
                color={color || 'primary'}
                variant='outlined'
                fullWidth
              />
            )}
          </FormControl>
        </Grid>
      );
      break;
    case 'multiselect':
      render = (
        <Grid key={value} item xs={12}>
          <FormControl variant='outlined' fullWidth onKeyDown={preventEnter}>
            <Typography id={`input-label-${value}`}>{label}</Typography>
            <Select
              multiple
              labelId={value}
              id={`multi-select-${value}`}
              value={localData?.[value] ?? []}
              onChange={(e) => {
                const selected = e.target.value;
                const newData = typeof selected === 'string' ? selected.split(',') : selected;
                setLocalData({
                  ...localData,
                  [value]: newData
                });
              }}
              required={required}
              disabled={disabled}
              error={hasError}
              renderValue={(selections) => {
                const selectedObjs = options?.filter((o) => _.includes(selections, o.value));
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedObjs?.map((item) => (
                      <Chip key={item.value} label={item.label} />
                    ))}
                  </Box>
                );
              }}
            >
              {(options || []).map((option, index) => (
                <MenuItem key={index} value={option?.value} name={option?.label}>
                  {option?.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={hasError}>{hasError ? errorText : helperText}</FormHelperText>
            {hasOther && localData?.[value]?.includes('other') && (
              <TextField
                id={value + 'other'}
                value={localData?.other?.[value] || ''}
                onChange={(e) => saveOtherValue(value, e.target.value)}
                placeholder={'Other'}
                error={hasError}
                helperText={hasError ? errorText : helperText}
                type='text'
                autoComplete='off'
                color={color || 'primary'}
                variant='outlined'
                fullWidth
              />
            )}
          </FormControl>
        </Grid>
      );
      break;
    case 'radio':
      render = (
        <Grid key={value} item xs={12}>
          <FormControl component='fieldset' onKeyDown={preventEnter}>
            <FormLabel component='legend'>{label}</FormLabel>
            <RadioGroup
              aria-label={label}
              name={`radio-buttons-group-${label}`}
              defaultValue={defaultValue}
              onChange={(e) => setLocalData({ ...localData, [value]: e.target.value })}
            >
              {options.map((option) => {
                const { label: optionLabel, value: optionValue } = option || {};
                return (
                  <FormControlLabel
                    key={optionValue}
                    value={optionValue}
                    control={<Radio checked={localData?.[value] === optionValue} color={color || 'primary'} />}
                    label={optionLabel}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Grid>
      );
      break;
    case 'checkbox':
      render = <CheckboxEnhanced label={label} value={value} color={color} options={options} localData={localData} setLocalData={setLocalData} />;

      break;
    case 'upload':
      render = (
        <Grid key={value} item xs={12}>
          <label htmlFor='contained-button-file'>
            <Grid container spacing={2}>
              <Grid item xs>
                <Dropzone />
              </Grid>
              <Grid item xs={12}>
                <ButtonEnhanced variant='contained' component='span'>
                  Upload
                </ButtonEnhanced>
              </Grid>
            </Grid>
          </label>
        </Grid>
      );
      break;
    case 'textarea':
      render = (
        <Grid key={value} item xs={12}>
          <Typography id={`input-label-${value}`}>{label}</Typography>
          <TextField
            id={value}
            multiline
            rows={4}
            value={localData?.[value] || ''}
            onChange={(e) => setLocalData({ ...localData, [value]: e.target.value })}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            error={hasError}
            helperText={hasError ? errorText : helperText}
            type='text'
            autoComplete='off'
            color={color || 'primary'}
            variant='outlined'
            fullWidth
          />
        </Grid>
      );
      break;
    default:
      render = (
        <Grid key={value} item xs={12}>
          <Typography id={`input-label-${value}`}>{label}</Typography>
          <TextField
            id={value}
            value={localData?.[value] || ''}
            onChange={(e) => setLocalData({ ...localData, [value]: e.target.value })}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            error={hasError}
            helperText={hasError ? errorText : helperText}
            type='text'
            autoComplete='off'
            color={color || 'primary'}
            variant='outlined'
            fullWidth
          />
        </Grid>
      );
  }
  return render;
};

DynamicInput.propTypes = {
  input: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.any,
    configuration: PropTypes.shape({
      fileTypes: PropTypes.string.isRequired,
      multiple: PropTypes.bool
    }),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string.isRequired,
        defaultChecked: PropTypes.bool
      })
    ),
    color: PropTypes.string
  }),
  localData: PropTypes.object,
  setLocalData: PropTypes.func
};
export default DynamicInput;
