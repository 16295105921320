import { Alert, Grid } from '@mui/material';
import PropTypes from 'prop-types';

/**
 *
 * @typedef {import('@mui/material').AlertProps} AlertProps
 *
 * @param {AlertProps} props
 * @param {String|Element|JSX} props.children
 *
 * @returns {React.FC<AlertProps>}
 */
const Banner = ({ onClose, children, ...props }) => {
  return (
    <Grid container alignItems='center' mb={1}>
      <Grid item xs={12}>
        <Alert severity='info' onClose={onClose} {...props}>
          {children}
        </Alert>
      </Grid>
    </Grid>
  );
};

Banner.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element])
};
export default Banner;
