// views.app.manage.issues.addIssue

export default {
  title: 'Add Issue',
  description: '',
  // cardTitle: 'List of all Issues',
  // cardSubtitle: 'All detected and manually added issues.',
  buttons: {
    save: 'Add issue'
  },
  statusDisclaimer: {
    RESOLVED: 'By setting status to risk resolved, after issue creation all actions will be set to complete.',
    ACCEPTED: 'By accepting this risk you are agreeing that the risk profile is acceptable to your organisation.'
  }
};
