import { useQuery } from '@apollo/client';
import { Box, Card, CardContent, Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { GET_FRAMEWORK_BENCHMARK_TOTALS, GET_FRAMEWORK_SCORE_TOTALS } from '../../../../../api';
import { useOrgContext } from '../../../../../contexts';
import { ButtonEnhanced, DotLoader, TypographyEnhanced } from '../../../../global';
import { ProgressChart } from '../../../../ui';
import ModalEnhanced from '../../../../ui/modal';
import { DEfAULT_CHART_OPTIONS } from './constants';
import { logPostureClick } from './utils';

const TRANSLATION_PATH = (route) => {
  return `components.application.dashboard.summaries.posture.${route}`;
};

const useStyles = makeStyles((theme) => ({
  card: {
    height: '135px',
    textAlign: 'left'
  },
  text: {
    color: theme.palette.text.inverted
  }
}));

const Posture = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { orgId, org } = useOrgContext();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    logPostureClick();
    setOpen((open) => !open);
  };

  const {
    data: scoreData,
    loading: scoreLoading,
    error: scoreError
  } = useQuery(GET_FRAMEWORK_SCORE_TOTALS, {
    variables: { org: orgId, framework: org?.masterControlFramework?.id },
    skip: !orgId || !org?.masterControlFramework?.id,
    nextFetchPolicy: 'network-only'
  });

  const {
    data: benchmarkData,
    loading: benchmarkLoading,
    error: benchmarkError
  } = useQuery(GET_FRAMEWORK_BENCHMARK_TOTALS, { variables: { framework: org?.masterControlFramework?.id }, skip: !orgId, nextFetchPolicy: 'network-only' });

  const [options, setOptions] = useState(DEfAULT_CHART_OPTIONS);

  const scorePercentage = useMemo(() => {
    if (scoreLoading || scoreError) {
      return 0;
    }

    return Math.round(scoreData?.frameworkScoreTotals?.pointsPercentage || 0);
  }, [scoreData?.frameworkScoreTotals?.pointsPercentage, scoreError, scoreLoading]);

  const benchmarkPercentage = useMemo(() => {
    if (benchmarkLoading || benchmarkError) {
      return 0;
    }

    return Math.round(benchmarkData?.frameworkBenchmarkTotals?.pointsPercentage || 0);
  }, [benchmarkData?.frameworkBenchmarkTotals?.pointsPercentage, benchmarkError, benchmarkLoading]);

  const scoreTotals = useMemo(() => {
    if (!scorePercentage || !benchmarkPercentage) {
      return {};
    }

    setOptions((options) => {
      const optionsCopy = _.cloneDeep(options);
      optionsCopy.data[0].value = scorePercentage;
      // Removed for external user alpha testing
      // optionsCopy.data = [
      //   // {
      //   //   value: benchmarkPercentage,
      //   //   itemStyle: {
      //   //     color: theme.palette.blue.dark
      //   //   },
      //   //   title: {
      //   //     show: false
      //   //   },
      //   //   detail: { show: false }
      //   // },
      //   {
      //     value: scorePercentage
      //     // name: 'Effectiveness'
      //     // detail: {
      //     //   valueAnimation: true,
      //     //   offsetCenter: ['0%', '0%']
      //     // }
      //   }
      // ];
      return optionsCopy;
    });

    return scoreData?.frameworkScoreTotals || {};
  }, [benchmarkPercentage, scoreData?.frameworkScoreTotals, scorePercentage]);

  const CardButton = (props) => {
    return <ButtonEnhanced {...props} fullWidth className={classes.card} onClick={handleClick} />;
  };

  return (
    <Box>
      <Card className={classes.card} component={CardButton}>
        <CardContent sx={{ height: '100%', width: '100%' }}>
          <Grid container>
            <Grid item xs={8}>
              <TypographyEnhanced id={TRANSLATION_PATH('title')} className={classes.text} sx={{ fontWeight: 'bold' }} />
              <TypographyEnhanced
                id={TRANSLATION_PATH('score.description')}
                value={{ score: scorePercentage || 0 }}
                className={classes.text}
                variant='caption'
              />
              {/*
                Removed for external user alpha testing
              <TypographyEnhanced
                id={TRANSLATION_PATH('benchmark.description')}
                value={{ benchmark: benchmarkPercentage || 0 }}
                className={classes.text}
                variant='caption'
              />
              */}
            </Grid>
            <Grid container item xs={4} sx={{ height: '100px' }}>
              <Grid item xs={12} sx={{ height: '100px' }}>
                {scoreLoading || benchmarkLoading ? (
                  <DotLoader sx={{ position: 'relative', alignItems: 'center', height: 80 }} color='secondary' />
                ) : !scoreTotals ? (
                  <TypographyEnhanced id={TRANSLATION_PATH('na')} variant='h6' className={classes.text} />
                ) : (
                  <ProgressChart options={options} barColor={theme.palette.secondary.main} textColor={theme.palette.text.inverted} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ModalEnhanced modalOpen={open} setModalOpen={setOpen}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h5' />
          </Grid>
          <Grid item xs={12}>
            <TypographyEnhanced id={TRANSLATION_PATH('dialog.description')} />
          </Grid>
        </Grid>
      </ModalEnhanced>
    </Box>
  );
};

export default Posture;
