import { useQuery } from '@apollo/client';
import { EditRounded, ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Card, CardContent, Collapse, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_SCENARIO } from '../../../../../api';
import { DotLoader, IconButtonEnhanced, TypographyEnhanced } from '../../../../../components/global';
import { ShowMoreText } from '../../../../../components/ui';
import ThreatModellingLinks from '../view/links';

const ThreatModellingScenario = ({ scenario, techniques, defaultExpanded, cardProps, onCategorySelect }) => {
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(defaultExpanded);

  const {
    data: scenarioData,
    error: scenarioError,
    loading: scenarioLoading
  } = useQuery(GET_SCENARIO, {
    variables: { id: scenario?.id },
    skip: !scenario?.id || !expanded
  });

  const scenarioWithLinks = useMemo(() => {
    if (scenarioError || scenarioLoading) {
      return;
    }

    return scenarioData?.scenario;
  }, [scenarioData?.scenario, scenarioError, scenarioLoading]);

  return (
    <Card {...(cardProps || {})}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid container item xs={12} alignItems='center'>
              <Grid container item alignItems='center' xs={12}>
                <TypographyEnhanced variant='h6' id='' display='inline'>
                  {scenario?.name || ''}
                </TypographyEnhanced>
                <IconButtonEnhanced onClick={() => setExpanded((expanded) => !expanded)}>
                  {expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                </IconButtonEnhanced>
                {expanded && (
                  <Grid container item xs alignItems='center' justifyContent='flex-end' spacing={2}>
                    {/* <Grid item>
                    <ButtonEnhanced size='small' onClick={toggleShowMore}>
                      {showMore ? 'Hide all' : 'Show all'}
                    </ButtonEnhanced>
                  </Grid> */}
                    {scenario?.id && (
                      <Grid item>
                        <IconButtonEnhanced onClick={() => navigate(`scenario/${scenario?.id}`)}>
                          <EditRounded />
                        </IconButtonEnhanced>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12}>
                <ShowMoreText>{scenario?.description}</ShowMoreText>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Collapse in={expanded}>
                {scenarioLoading || !scenarioWithLinks ? (
                  <DotLoader sx={{ position: 'relative' }} />
                ) : (
                  <ThreatModellingLinks scenario={scenarioWithLinks} techniques={techniques} onCategorySelect={onCategorySelect} />
                )}
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ThreatModellingScenario.propTypes = {
  techniques: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      mappingNumber: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(PropTypes.object),
      questions: PropTypes.arrayOf(PropTypes.object)
    })
  ).isRequired,
  scenario: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string,
    framework: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string
    })
  }),
  defaultExpanded: PropTypes.bool,
  cardProps: PropTypes.object,
  onCategorySelect: PropTypes.func
};

export default ThreatModellingScenario;
