import { createPassword, formatErrorMessage, getDomain, isEmpty, validateDomain, validateEmail } from '@anirudhm9/base-lib/lib/utils';
import { Grid } from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultMessages } from '../../../../../constants';
import { useOrgContext, useUserContext } from '../../../../../contexts';
import { useUsersContext } from '../../../../../contexts/usersContext';
import { logEvent } from '../../../../../library';
import { USERS as MANAGE_USERS_EVENTS } from '../../../../../library/amplitude/events/views/app/manage/users';
import { ButtonEnhanced, toast, TypographyEnhanced } from '../../../../global';
import TextFieldEdit from '../../../../ui/form/viewEditText/textFieldEdit';

import { TRANSLATION_PATH } from './constants';

const AddUser = () => {
  const navigate = useNavigate();
  const { refetch: refetchUsers } = useUsersContext();
  const { orgId, org } = useOrgContext();
  const { createUserMutation } = useUserContext();

  const [localData, setLocalData] = useState({});
  const [hasErrors, setHasErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const updateLocalData = (key, value) => {
    setLocalData((local) => {
      return { ...local, [key]: value };
    });
  };

  const saveUser = async () => {
    setHasErrors({});

    // VALIDATE DATA
    const requiredFields = ['firstName', 'lastName', 'email'];
    const emptyFields = isEmpty(requiredFields, localData);

    if (!_.isEmpty(emptyFields)) {
      setHasErrors((prev) => {
        return { ...prev, ...emptyFields };
      });
      return;
    }

    const invalidEmail = !validateEmail(localData.email, false);

    if (invalidEmail) {
      setHasErrors((prev) => {
        return { ...prev, email: defaultMessages.invalidEmail };
      });
      return;
    }

    const invalidDomain = !validateDomain(localData.email);
    if (invalidDomain || org.domain !== getDomain(localData.email)) {
      setHasErrors((prev) => {
        return { ...prev, email: defaultMessages.invalidDomain };
      });
      return;
    }

    try {
      setLoading(true);
      const res = await createUserMutation({
        variables: {
          data: {
            firstName: localData.firstName,
            lastName: localData.lastName,
            email: localData.email,
            username: localData.email,
            password: createPassword(),
            org: orgId
          }
        }
      });
      refetchUsers();
      toast.success(defaultMessages.defaultSuccess);
      const newUserId = res?.data?.createUser?.user?.id;
      logEvent(MANAGE_USERS_EVENTS.ADD_USER, { new_user_id: newUserId });
      navigate(`/app/manage/users/${newUserId}`);
    } catch (error) {
      console.error(error);
      toast.error(formatErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h6' />
      </Grid>
      {/* User Details */}

      <Grid item xs={12} lg={6}>
        <TextFieldEdit
          value={localData?.firstName}
          onChange={(data) => updateLocalData('firstName', data)}
          label='First Name'
          hasError={!!hasErrors.firstName}
          errorText={hasErrors.firstName}
          required
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextFieldEdit
          value={localData?.lastName}
          onChange={(data) => updateLocalData('lastName', data)}
          label='Last Name'
          hasError={!!hasErrors.lastName}
          errorText={hasErrors.lastName}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldEdit
          value={localData?.email}
          onChange={(data) => updateLocalData('email', data)}
          label='Email'
          hasError={!!hasErrors.email}
          errorText={hasErrors.email}
          required
        />
      </Grid>

      {/* ACTIONS */}
      <Grid container item xs={12} justifyContent='flex-end'>
        <ButtonEnhanced onClick={saveUser} loading={loading}>
          <TypographyEnhanced id='SAVE' />
        </ButtonEnhanced>
      </Grid>
    </Grid>
  );
};

export default AddUser;
