import { batchExecute, compareHtmlStrings, stripHtmlTags, symmetricDifference } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { CREATE_NOTIFICATION } from '../../../../api';
import { fileDownloadLink } from '../../../../constants/defaults';
import { SecureDownload } from '../../../../containers/dialogs';
import { useOrgContext, useUserContext } from '../../../../contexts';
import { useUsersContext } from '../../../../contexts/usersContext';
import { downloadFile, uploadFiles } from '../../../../library';
import { ButtonEnhanced, DotLoader, TooltipEnhanced } from '../../../global';
import AttachedFiles from '../../attachedFiles';
import { HTMLEditor } from '../../form';
import LetterAvatar from '../../letterAvatar';
import UploadIcon from '../../uploadIcon';
import { DEFAULT_INPUT_DATA } from './constants';

const CommentInput = ({ id, model = 'category', onSave, defaultAttachments = [], defaultValue = '', showAvatar = true, showCancel = false, onCancel }) => {
  const editorRef = useRef();
  const { userId } = useUserContext();
  const { orgId } = useOrgContext();

  const [createNotification] = useMutation(CREATE_NOTIFICATION);

  const [localData, setLocalData] = useState(DEFAULT_INPUT_DATA);
  const [loading, setLoading] = useState(false);

  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [fileToDownload, setFileToDownload] = useState({});

  const { user, loading: userLoading } = useUserContext();
  const { users, loading: usersLoading } = useUsersContext();

  const handleChange = (key, value) => {
    setLocalData((local) => {
      return { ...local, [key]: value };
    });
  };

  useEffect(() => {
    if (!defaultValue && !defaultAttachments?.length) {
      return;
    }

    handleChange('comment', defaultValue);
    handleChange('attachments', defaultAttachments || []);
  }, [defaultAttachments, defaultValue]);

  const toggle = () => {
    setDownloadModalOpen((open) => !open);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const recipientIds = editorRef?.current?.getUserMentions() || [];
      if (recipientIds?.length) {
        // send notification
        const { comment = '' } = localData || {};
        await batchExecute(recipientIds, (recipient) =>
          createNotification({ variables: { data: { type: 'MENTION', [model]: id, org: orgId, recipient, data: { id, comment, model }, sender: userId } } })
        );
      }

      await onSave(localData, recipientIds);
      editorRef?.current?.reset();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (files) => {
    setLoading(true);
    const attachments = await uploadFiles(files);
    setLocalData((local) => {
      return { ...local, attachments: [...local['attachments'], ...attachments] };
    });
    setLoading(false);
  };

  const handleDownload = (file = {}) => {
    setFileToDownload(file);
    toggle();
  };

  const inputChanged = useMemo(() => {
    const { attachments, comment } = localData || {};
    const commentText = stripHtmlTags(comment);

    if (!commentText && !attachments?.length) {
      return false;
    }

    const commentSame = !commentText || (defaultValue && commentText && compareHtmlStrings(defaultValue, commentText));
    const attachmentsSame =
      (!defaultAttachments && !attachments?.length) ||
      (defaultAttachments &&
        !symmetricDifference(
          defaultAttachments?.map((attachment) => attachment?.id),
          attachments?.map((attachment) => attachment?.id)
        )?.length);

    if (attachmentsSame && commentSame) {
      return false;
    }
    return true;
  }, [defaultAttachments, defaultValue, localData]);

  if (userLoading || usersLoading) {
    return <DotLoader />;
  }

  return (
    <Fragment>
      <Grid container spacing={2} item xs={12}>
        {showAvatar && (
          <Grid item>
            <LetterAvatar user={user} showBadge />
          </Grid>
        )}
        <Grid item xs>
          <HTMLEditor
            defaultValue={defaultValue || ''}
            ref={editorRef}
            label={''}
            onChange={(value) => handleChange('comment', value)}
            users={users}
            onCancel={() => setLocalData(DEFAULT_INPUT_DATA)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between' alignItems='center' spacing={2} item xs={12}>
        <Grid item xs={12} sm={6}>
          <AttachedFiles
            attachments={localData.attachments || []}
            setAttachments={(values) => handleChange('attachments', values)}
            onDownloadClick={handleDownload}
          />
          <SecureDownload
            onClick={() => {
              downloadFile(fileDownloadLink(fileToDownload?.hash));
            }}
            open={downloadModalOpen}
            toggle={toggle}
            file={fileToDownload || {}}
            href={fileToDownload?.href}
          />
        </Grid>
        <Grid container justifyContent='flex-end' item spacing={2} xs={12} sm={6}>
          <Grid item>
            <TooltipEnhanced title={'Upload attachment'}>
              <UploadIcon onUpload={handleUpload} attachments={localData.attachments || []} multiple loading={loading} />
            </TooltipEnhanced>
          </Grid>
          {showCancel && (
            <Grid item>
              <ButtonEnhanced variant='text' onClick={onCancel} loading={loading}>
                Cancel
              </ButtonEnhanced>
            </Grid>
          )}
          <Grid item>
            <ButtonEnhanced onClick={handleSave} loading={loading} disabled={!inputChanged}>
              Save
            </ButtonEnhanced>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

CommentInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  model: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  defaultAttachments: PropTypes.array,
  showAvatar: PropTypes.bool,
  showCancel: PropTypes.bool,
  onCancel: PropTypes.func
};
export default CommentInput;
