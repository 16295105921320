const DOMAIN = 'https://images.avertro.com';

const path = (file) => `${DOMAIN}/rewards/${file}`;

const REWARDS = {
  // Earn
  EARN_BOOK_STACK_TOP: path('books-4993.svg'),
  EARN_BOOK_STACK: path('books-13453.svg'),
  EARN_BOOK_STACK_FRONT: path('books-13502.svg'),
  EARN_BROKEN_LINK: path('broken-link-10497.svg'),
  EARN_GRADUATION_CAP: path('graduation-cap-5409.svg'),
  EARN_GREEN_ERASER: path('green-eraser-14531.svg'),
  EARN_PADLOCK: path('Padlock.svg'),
  EARN_PROCESSING: path('processing-6093.svg'),

  // Redeem
  REDEEM_ARROW_RIGHT: path('arrow-right.svg'),
  REDEEM_BLUE_GIFT: path('blue-gift-box.svg'),
  REDEEM_CHECK: path('checkmark-green.svg'),
  REDEEM_QUALITY: path('quality-3602+1.svg'),
  REDEEM_STAR: path('star.svg'),

  // General Imagery
  IMAGE_HDD: path('hard-drive-md.jpg'),
  IMAGE_PC: path('pc-internal.jpg'),
  IMAGE_LAPTOP_CODE: path('laptop-code-md.jpg'),
  IMAGE_PHONE_VPN: path('phone-vpn-md.jpg'),
  IMAGE_PHONE_LOCK: path('phone-locked-md.jpg'),
  IMAGE_PHONE_APPS: path('phone-apps-md.jpg'),
  IMAGE_PHONE_APPS_2: path('phone-apps-2-md.jpg'),
  IMAGE_LIGHTBULB: path('lightbulb.jpg'),
  IMAGE_LAPTOP_GMAIL: path('laptop-gmail-md.jpg'),
  IMAGE_LAPTOP_TYPING: path('laptop-typing.jpg'),
  IMAGE_LAPTOP_TRACKPAD: path('hand-on-trackpad.jpg'),
  IMAGE_BRAINSTORM: path('brainstorming-over-paper.jpg'),
  IMAGE_PADLOCK_COMBINATION: path('padlock-combination.jpg'),
  IMAGE_PADLOCK_KEYBOARD: path('padlock-keyboard.jpg')
};

module.exports = {
  REWARDS
};
