import { FORGOT_PASSWORD } from './forgotPassword';
import { LOGIN } from './login';
import { REGISTER } from './register';
import { RESET_PASSWORD } from './resetPassword';

export const USER = {
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD
};
