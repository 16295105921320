import { INTEGRATION_TYPES } from '@anirudhm9/base-lib/lib/constants';
import { DoneAllRounded, OpenInNewRounded, WarningAmberRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { logEvent } from '../../../../../../library';
import { CLOUD_INFRASTRUCTURE as REPORTS_CLOUD_INFRASTRUCTURE_EVENTS } from '../../../../../../library/amplitude/events/views/app/reports/cloudInfrastructure';
import { IconButtonEnhanced, TypographyEnhanced } from '../../../../../global';
import { CriticalityBox } from '../../../../manage';

const DEFAULT_COLUMNS = [
  {
    headerName: 'Control',
    field: 'control',
    flex: 1.5,
    renderCell: (params) => {
      const { row, value } = params || {};
      const { criticality, requiresAttention, issue } = row || {};
      const { id: issueId } = issue || {};
      const { value: criticalityValue } = criticality || {};
      const { color } = INTEGRATION_TYPES.AWS_SECURITY_HUB.CRITICALITY_MAPPING[criticalityValue] || {};

      return (
        <Grid container spacing={1} wrap='nowrap'>
          <Grid item>
            <CriticalityBox
              criticality={criticality}
              sx={{
                color: (theme) => {
                  const themeColor = theme?.palette?.[color]?.main || '';
                  return themeColor;
                }
              }}
            />
          </Grid>
          <Grid container item xs>
            <Grid item>
              <TypographyEnhanced id={''} display='inline'>
                {value}
              </TypographyEnhanced>
            </Grid>
            {requiresAttention && issueId && (
              <Grid item>
                <WarningAmberRounded fontSize='small' color='primary' />
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    }
  },
  {
    headerName: 'Criticality',
    field: 'criticality',
    flex: 1,
    hide: true,
    valueGetter: (params) => {
      const { row } = params || {};
      const { criticality } = row || {};
      const { value: criticalityValue } = criticality || {};
      const { name = '' } = INTEGRATION_TYPES.AWS_SECURITY_HUB.CRITICALITY_MAPPING[criticalityValue] || {};
      return name;
    }
  },
  {
    headerName: 'Remediation Action',
    field: 'remediation',
    flex: 1,
    valueGetter: (params) => {
      return !!params?.row?.requiresAttention;
    },
    type: 'boolean',
    renderCell: (params) => {
      const { remediation, issue } = params?.row || {};
      const { name, link } = remediation || {};
      return (
        <Grid container alignItems='center' justifyContent='flex-start'>
          <TypographyEnhanced id={''} display='inline-block'>
            {name}
            {link ? (
              <IconButtonEnhanced
                size='small'
                color='primary'
                sx={{ display: 'inline-block' }}
                onClick={() => {
                  logEvent(REPORTS_CLOUD_INFRASTRUCTURE_EVENTS.REMEDIATION_ASSISTANCE, { issue_id: issue?.id });
                  window.open(link, '_blank', 'noopener,noreferrer');
                }}
              >
                <OpenInNewRounded fontSize='small' />
              </IconButtonEnhanced>
            ) : (
              <DoneAllRounded fontSize='small' color='primary' sx={{ marginLeft: '5px' }} />
            )}
          </TypographyEnhanced>
        </Grid>
      );
    }
  }
];

export { DEFAULT_COLUMNS };
