const STATUS_CODES = {
  ERROR: {
    DEFAULT: {
      statusCode: 400,
      message: 'Error',
      type: 'DEFAULT'
    },
    USER_ALREADY_REGISTERED: {
      statusCode: 409,
      message: 'You are already registered with us',
      type: 'USER_ALREADY_REGISTERED'
    },
    ORG_ALREADY_REGISTERED: {
      statusCode: 409,
      message: 'You are already registered with us',
      type: 'ORG_ALREADY_REGISTERED'
    },
    INTEGRATION_ALREADY_REGISTERED: {
      statusCode: 409,
      message: 'You are already registered with us',
      type: 'INTEGRATION_ALREADY_REGISTERED'
    },
    PASSWORD_REQUIRED: {
      statusCode: 400,
      message: 'Password is required',
      type: 'PASSWORD_REQUIRED'
    },
    INVALID_COUNTRY_CODE: {
      statusCode: 400,
      message: 'Invalid Country Code, Should be in the format +61',
      type: 'INVALID_COUNTRY_CODE'
    },
    INVALID_PHONE_NO_FORMAT: {
      statusCode: 400,
      message: 'Phone no. cannot start with 0',
      type: 'INVALID_PHONE_NO_FORMAT'
    },
    IMPLEMENTATION_ERROR: {
      statusCode: 500,
      message: 'Implementation Error',
      type: 'IMP_ERROR'
    },
    DUPLICATE_ENTRY: {
      statusCode: 400,
      message: 'Record Already Exist',
      type: 'DUPLICATE_ENTRY'
    },
    UNIQUE_CODE_LIMIT_REACHED: {
      statusCode: 400,
      message: 'Cannot Generate Unique Code, All combinations are used',
      type: 'UNIQUE_CODE_LIMIT_REACHED'
    },
    PHONE_NO_EXIST: {
      statusCode: 400,
      message: 'Mobile No. Already Exist',
      type: 'PHONE_NO_EXIST'
    },
    EMAIL_NO_EXIST: {
      statusCode: 400,
      message: 'Email Does Not Exist',
      type: 'EMAIL_NO_EXIST'
    },
    USERNAME_EXIST: {
      statusCode: 400,
      message: 'User Already Exist',
      type: 'USERNAME_EXIST'
    },
    INVALID_TOKEN: {
      statusCode: 401,
      message: 'Invalid token provided',
      type: 'INVALID_TOKEN'
    },
    INCORRECT_ACCESSTOKEN: {
      statusCode: 403,
      message: 'Incorrect AccessToken',
      type: 'INCORRECT_ACCESSTOKEN'
    },
    INSUFFICIENT_REQUIREMENTS: {
      statusCode: 400,
      message: 'Insufficient Requirements',
      type: 'INSUFFICIENT_REQUIREMENTS'
    },
    INVALID_CODE: {
      statusCode: 400,
      message: 'Invalid Verification Code',
      type: 'INVALID_CODE'
    },
    INVALID_CREDENTIALS: {
      statusCode: 400,
      message: 'Invalid Credentials Provided',
      type: 'INVALID_CREDENTIALS'
    },
    INVALID_ID: {
      statusCode: 400,
      message: 'Invalid ID Provided',
      type: 'INVALID_ID'
    },
    USER_NOT_FOUND: {
      statusCode: 400,
      message: 'User Not Found',
      type: 'USER_NOT_FOUND'
    },
    ORG_NOT_FOUND: {
      statusCode: 400,
      message: 'Org Not Found',
      type: 'ORG_NOT_FOUND'
    },
    INCORRECT_PASSWORD: {
      statusCode: 400,
      message: 'Incorrect Password',
      type: 'INCORRECT_PASSWORD'
    },
    ACCOUNT_BLOCKED: {
      statusCode: 400,
      message: 'You account has been blocked by authorities. Please Contact them.',
      type: 'ACCOUNT_BLOCKED'
    },
    PRIVILEGE_MISMATCH: {
      statusCode: 400,
      message: 'Your account doesnt have these privileges',
      type: 'PRIVILEGE_MISMATCH'
    },
    GSUITE_ORG_MISMATCH: {
      statusCode: 400,
      message: 'GSuite domain does not match Organisation domain.',
      type: 'GSUITE_ORG_MISMATCH'
    },
    NOT_REGISTERED: {
      statusCode: 400,
      message: 'You are not registered with Us. Kindly register yourself to avail services!',
      type: 'NOT_REGISTERED'
    },
    FACEBOOK_ID_NOT_FOUND: {
      statusCode: 400,
      message: 'Facebook Id Not Found',
      type: 'FACEBOOK_ID_NOT_FOUND'
    },
    PHONE_VERIFICATION_COMPLETE: {
      statusCode: 400,
      message: 'Your mobile number verification is already completed.',
      type: 'PHONE_VERIFICATION_COMPLETE'
    },
    EMAIL_VERIFICATION_COMPLETE: {
      statusCode: 400,
      message: 'Your email address verification is already completed.',
      type: 'EMAIL_VERIFICATION_COMPLETE'
    },
    OTP_CODE_NOT_FOUND: {
      statusCode: 400,
      message: 'Otp statusCode not found for this user',
      type: 'OTP_CODE_NOT_FOUND'
    },
    NOT_FOUND: {
      statusCode: 400,
      message: 'User Not Found',
      type: 'NOT_FOUND'
    },
    WRONG_PASSWORD: {
      statusCode: 400,
      message: 'Invalid old password',
      type: 'WRONG_PASSWORD'
    },
    NOT_UPDATE: {
      statusCode: 409,
      message: 'New password must be different from old password',
      type: 'NOT_UPDATE'
    },
    PASSWORD_CHANGE_REQUEST_INVALID: {
      statusCode: 400,
      type: 'PASSWORD_CHANGE_REQUEST_INVALID',
      message: 'Invalid password change request.'
    },
    USER_NOT_REGISTERED: {
      statusCode: 401,
      message: 'User is not registered with us',
      type: 'USER_NOT_REGISTERED'
    },
    PHONE_VERIFICATION: {
      statusCode: 400,
      message: 'Your mobile number verification is incomplete.',
      type: ' PHONE_VERIFICATION'
    },
    INCORRECT_ID: {
      statusCode: 401,
      message: 'Incorrect Phone Number',
      type: 'INCORRECT_ID'
    },
    NOT_VERFIFIED: {
      statusCode: 401,
      message: 'User Not Verified',
      type: 'NOT_VERFIFIED'
    },
    PASSWORD_CHANGE_REQUEST_EXPIRE: {
      statusCode: 400,
      message: ' Password change request time expired',
      type: 'PASSWORD_CHANGE_REQUEST_EXPIRE'
    },
    INVALID_EMAIL_FORMAT: {
      statusCode: 400,
      message: 'Inavlid email format',
      type: 'INVALID_EMAIL_FORMAT'
    },
    INVALID_AMOUNT: {
      statusCode: 400,
      message: 'Inavlid Amount',
      type: 'INVALID_AMOUNT'
    },
    INVALID_COUPON: {
      statusCode: 400,
      message: 'Inavlid Coupon',
      type: 'INVALID_COUPON'
    },
    INVALID_COMPANY_ID: {
      statusCode: 400,
      message: 'Inavlid Company ID or Company not registered',
      type: 'INVALID_COMPANY_ID'
    },
    INVALID_COMPANY_DETAILS: {
      statusCode: 400,
      message: 'Company Details cannot be empty',
      type: 'INVALID_COMPANY_DETAILS'
    },
    INVALID_TEAM_ID: {
      statusCode: 400,
      message: 'Team does not exist',
      type: 'INVALID_TEAM_ID'
    },
    INVALID_TEAM_MEMBER: {
      statusCode: 400,
      message: 'Team member does not exist',
      type: 'INVALID_TEAM_MEMBER'
    },
    REDEEM_FAILED: {
      statusCode: 400,
      message: 'There was an error reedeming your item.',
      type: 'REDEEM_FAILED'
    },
    UPLOAD_FAILED: {
      statusCode: 400,
      message: 'There was an error uploading your file.',
      type: 'UPLOAD_FAILED'
    }
  },
  SUCCESS: {
    DEFAULT: {
      statusCode: 200,
      message: 'Success',
      type: 'DEFAULT'
    },
    CREATED: {
      statusCode: 201,
      message: 'Created Successfully',
      type: 'CREATED'
    },
    VERIFY_COMPLETE: {
      statusCode: 200,
      message: 'OTP verification is completed.',
      type: 'VERIFY_SENT'
    },
    VERIFY_SENT: {
      statusCode: 200,
      message: 'Your new OTP has been sent to your phone',
      type: 'VERIFY_SENT'
    },
    LOGOUT: {
      statusCode: 200,
      message: 'Logged Out Successfully',
      type: 'LOGOUT'
    },
    PASSWORD_RESET: {
      statusCode: 200,
      message: 'Password Reset Successfully',
      type: 'PASSWORD_RESET'
    }
  }
};

module.exports = STATUS_CODES;
