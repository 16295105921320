/* eslint-disable quotes */
// components.application.rewards.redeem.downloadModal

export const DOWNLOAD_MODAL = {
  title: 'Download {{name}}',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..',
  buttons: {
    word: 'DOWNLOAD WORD',
    pdf: 'DOWNLOAD PDF',
    close: 'CLOSE'
  }
};
