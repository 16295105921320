import { AdminPanelSettingsRounded, PersonRounded } from '@mui/icons-material';
import { EVENTS } from '../../../../../../library';

export const LOG_EVENT_PATH = (event) => {
  return EVENTS.VIEWS.APP.SETTINGS.MANAGE_USERS[event];
};

export const TRANSLATION_PATH = (route) => {
  return `views.app.manage.manageUsers.${route}`;
};

export const REQUIRED_FIELDS = ['firstName', 'lastName'];
export const ROLE_OPTIONS = [
  {
    id: 'ADMIN',
    name: 'Admin',
    value: 'ADMIN',
    icon: <AdminPanelSettingsRounded />
  },
  {
    id: 'DEFAULT',
    name: 'Member',
    value: 'DEFAULT',
    icon: <PersonRounded />
  }
];
