import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { TypographyEnhanced } from '../../../../../global';

export const FILTERS = {
  APPLICATIONS: {
    id: 'APPLICATIONS',
    label: 'Application',
    filters: [
      {
        id: 'KEY_BUSINESS_SYSTEM',
        label: 'Key Business System',
        type: 'checkbox',
        options: [
          { id: 'MAJOR', name: 'Yes', value: 'MAJOR' },
          { id: 'MINOR', name: 'Not Important', value: 'MINOR' },
          { id: 'NONE', name: 'No', value: 'NONE' }
        ]
      },
      {
        id: 'CREATION_METHOD',
        label: 'Creation Method',
        type: 'checkbox',
        options: [
          { id: 'custom', name: 'Manually created', value: 'custom' },
          { id: 'integration', name: 'Integration created', value: 'integration' }
        ]
      }
    ]
  }
};

export const SORT_VALUES = {
  CREATED_AT_DESC: {
    id: 'createdAt:DESC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Newest
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'createdAt:DESC',
    label: ''
  },
  CREATED_AT_ASC: {
    id: 'createdAt:ASC',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Oldest
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'createdAt:ASC',
    label: ''
  },
  ALPHABETICAL_ASC: {
    id: 'title:asc',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Alphabetical
        </TypographyEnhanced>
        <ArrowDownwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'title:asc',
    label: ''
  },
  ALPHABETICAL_DESC: {
    id: 'title:desc',
    name: (
      <Grid container alignItems='center' justifyContent='space-between'>
        <TypographyEnhanced id='' mr={1}>
          Alphabetical
        </TypographyEnhanced>
        <ArrowUpwardRounded fontSize='small' />
      </Grid>
    ),
    value: 'title:desc',
    label: ''
  }
};

export const SORT_OPTIONS = Object.values(SORT_VALUES);
