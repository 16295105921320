/* eslint-disable quotes */
// components.ui.pointsSummary

export const POINTS_SUMMARY = {
  earned: 'Points Earned',
  achievements: 'Achievements Completed',
  redeemed: 'Points Redeemed',
  streak: 'Streak Count',
  bonus: '{{days}} days until bonus gift'
};
