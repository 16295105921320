/* eslint-disable quotes */

// components.application.dashboard.widgets.issuesSummary

export const ISSUES_SUMMARY = {
  title: 'Issues Summary',
  description: `Open issues should be addressed. If an issue is open, it is likely due to a potential risk or gap that has been found. As a general rule, all issues should either be resolved or accepted.`,
  buttons: {
    viewAll: 'VIEW ALL'
  }
};
