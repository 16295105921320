import { ApolloClient, createHttpLink, InMemoryCache, makeVar } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import axios from 'axios';
import io from 'socket.io-client';
import { apiEndpoint } from '../constants/defaults';
import { handleLogout } from '../helpers/routing';

export const wsContext = {
  userSocket: null
};

export function subscribeUserToWS() {
  const token = localStorage.getItem('user_token');
  const userId = localStorage.getItem('user_id');

  if (!token) {
    return;
  }

  wsContext.userSocket = io.connect(apiEndpoint, {
    query: { type: 'user', id: userId, token },
    withCredentials: true,
    extraHeaders: {
      'X-Content-Type-Options': 'nosniff'
    }
  });
}

subscribeUserToWS();

const httpLink = createHttpLink({
  uri: `${apiEndpoint}/graphql`
});

const handleResponse = (extensions) => {
  const unauthorisedCodes = [401, 403];
  const { statusCode } = extensions?.exception?.output?.payload || {};
  const code = Number(statusCode);

  if (unauthorisedCodes.includes(code)) {
    try {
      // do nothing
      // await refreshLogin();
      localStorage.setItem('timed_out', new Date().getTime());
      handleLogout();
    } catch (error) {
      localStorage.setItem('timed_out', new Date().getTime());
      handleLogout();
    }
  }
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      handleResponse(extensions);
      return console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('user_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});
export const userVar = makeVar(localStorage.getItem('user_id'));
export const orgVar = makeVar(localStorage.getItem('org_id'));
export const orgNameVar = makeVar(localStorage.getItem('org_name'));
export const themeVar = makeVar(localStorage.getItem('theme'));

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        user: { read: userVar() },
        org: { read: orgVar() },
        theme: { read: themeVar() }
      }
    }
  }
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache,
  resolvers: {}
});

/**
 * should delete all cached items for type
 * @param type (e.g. Org, Question, Category
 */
export function deleteCacheForKey(type) {
  const pattern = new RegExp(`^${type}`);
  Object.keys(cache.data.data).forEach((key) => {
    if (key.match(pattern)) {
      cache.data.delete(key);
    }
  });
}

export function setupAxios() {
  const authToken = localStorage.getItem('user_token');
  if (authToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }

  axios.defaults.baseURL = apiEndpoint;
}

function initLocalData() {
  const localTheme = localStorage.getItem('theme') && JSON.parse(localStorage.getItem('theme'));
  userVar(localStorage.getItem('user_id'));
  orgVar(localStorage.getItem('org_id'));
  themeVar(localTheme);
}
initLocalData();
setupAxios();

export const setOrgId = (orgId, orgName) => {
  orgVar(orgId);
  orgNameVar(orgName);
  localStorage.setItem('org_id', orgId);
  localStorage.setItem('org_name', orgName);
};

export const GraphQLClient = client;
export * from './graphql';
