import { STATUS_CODES } from '@anirudhm9/base-lib/lib/constants';
import { isEmpty, validateDomain, validateEmail } from '@anirudhm9/base-lib/lib/utils';
import { useMutation } from '@apollo/client';
import { Checkbox, CssBaseline, FormControlLabel, FormGroup, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { signInWithRedirect } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import { deviceDetect, isFirefox, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GET_ME, GraphQLClient, LOG_IN } from '../../../api';
import { Alert, ButtonEnhanced, DividerWithText, TypographyEnhanced, toast } from '../../../components/global';
import GoogleSignIn from '../../../components/global/authorisationProviders/google';
import { PasswordFieldEnhanced, TextFieldEnhanced } from '../../../components/ui/form';
import { defaultMessages } from '../../../constants';
import { usePageViewContext } from '../../../contexts/logPageViewContext';
import { clearCache } from '../../../helpers/routing';
import { useIsDesktop, useKeyPress } from '../../../hooks';
import { FIREBASE_AUTH, GOOGLE_PROVIDER, getSSOResult, logEvent } from '../../../library';
import { USER_ACTIONS as USER_ACTIONS_EVENTS } from '../../../library/amplitude/events/common';
import { LOGIN as LOGIN_PAGE_EVENTS } from '../../../library/amplitude/events/views/user/login';
import { TRANSLATION_PATH, defaultData, requiredFields } from './constants';
import { initialiseUser } from './helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(10, 0),
    justifyContent: 'center'
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  topMargin: {
    marginTop: theme.spacing(0)
  },

  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  options: {
    padding: theme.spacing(0, 1)
  },
  link: {
    textDecoration: 'none'
  },
  attribute: {
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const Login = () => {
  const isDesktop = useIsDesktop();
  const classes = useStyles();
  const navigate = useNavigate();
  const timedOut = localStorage.getItem('timed_out');
  const { t } = useTranslation();
  const { pageViewLogged, setPageViewLogged } = usePageViewContext();

  useEffect(() => {
    if (pageViewLogged) {
      return;
    }
    logEvent(LOGIN_PAGE_EVENTS.PAGE_VISITED);
    setPageViewLogged(true);
  }, [pageViewLogged, setPageViewLogged]);

  const [localData, setLocalData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [error, setError] = useState('');
  const [deviceInfo, setDeviceInfo] = useState();
  const [rememberEmail, setRememberEmail] = useState(() => !!localStorage.getItem('user_email'));

  const [login] = useMutation(LOG_IN);

  const ssoLogin = useCallback(
    async (isPopup, provider) => {
      setLoading(true);
      clearCache();
      try {
        const result = await getSSOResult(isPopup, provider);
        if (!result) {
          setLoading(false);
          return;
        }
        const { idToken } = (result || {})._tokenResponse || {};
        localStorage.setItem('user_token', idToken);
        const user = (
          await GraphQLClient.query({
            query: GET_ME,
            fetchPolicy: 'network-only'
          })
        )?.data?.getMe;

        initialiseUser(user || {});
        setLoading(false);
        toast.success(STATUS_CODES.SUCCESS.DEFAULT.message);
        setTimeout(() => navigate('/'), 0);
      } catch (error) {
        console.error(error);
        toast.error(defaultMessages.invalidLogin);
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  );

  useEffect(() => {
    ssoLogin();
  }, [ssoLogin]);

  const handleSSOClick = async (provider) => {
    if (isIOS || isFirefox) {
      await ssoLogin(true, provider);
      return;
    }
    signInWithRedirect(FIREBASE_AUTH, provider);
  };

  const onLogin = async () => {
    let errors = {};
    const emptyFields = isEmpty(requiredFields, localData);

    const invalidEmail = !validateEmail(localData.email, false);
    if (invalidEmail) {
      errors = {
        ...errors,
        email: defaultMessages.invalidEmail
      };
    }

    const invalidDomain = !validateDomain(localData.email);
    if (invalidDomain) {
      errors = {
        ...errors,
        email: defaultMessages.invalidDomain
      };
    }

    if (emptyFields) {
      errors = {
        ...errors,
        ...emptyFields
      };
    }

    if (Object.keys(errors).length) {
      setErrorFields(errors);
      return;
    }
    try {
      setLoading(true);
      clearCache();
      if (rememberEmail) {
        localStorage.setItem('user_email', localData?.email || '');
      }

      const res = await login({ variables: { identifier: localData.email.toLocaleLowerCase(), password: localData.password, data: deviceInfo } });
      const data = res?.data?.deviceLogin;
      const { jwt, user } = data || {};
      localStorage.setItem('user_token', jwt);
      initialiseUser(user || {});
      setLoading(false);
      logEvent(USER_ACTIONS_EVENTS.LOGIN_EMAIL);
      setTimeout(() => navigate('/'), 0);
    } catch (error) {
      console.error(error);
      setError(defaultMessages.invalidLogin);
      toast.error(defaultMessages.invalidLogin);
    } finally {
      setLoading(false);
      setErrorFields([]);
    }
  };

  const TimedOutAlert = () => {
    if (new Date().getTime() - parseInt(timedOut) > 15000) {
      localStorage.removeItem('timed_out');
    }
    return (
      <Grid item xs={12}>
        <Alert>{t(TRANSLATION_PATH('inactivity'))}</Alert>
      </Grid>
    );
  };

  useKeyPress('Enter', () => {
    onLogin();
  });

  useEffect(() => {
    const detectInfo = deviceDetect();
    if (!detectInfo) return;
    setDeviceInfo(detectInfo);
    const { isMobile, os = '', osName = '', vendor = '', model = '', browserName = '', browserMajorVersion = '', osVersion = '' } = detectInfo || {};

    const deviceType = ((isMobile ? os : osName) || '').toUpperCase();
    const deviceName = isMobile ? `${vendor} ${model}` : `${browserName} ${browserMajorVersion}`;
    const deviceVersion = isMobile ? `${os} ${osVersion}` : `${osName} ${osVersion}`;

    setDeviceInfo({
      deviceType,
      deviceName,
      deviceVersion
    });

    const rememberedEmail = localStorage.getItem('user_email');
    if (rememberedEmail) {
      setLocalData((prev) => {
        return {
          ...prev,
          email: rememberedEmail
        };
      });
    }
  }, []);

  const toggleRemember = () => {
    setRememberEmail((checked) => {
      if (checked) {
        localStorage.removeItem('user_email');
      }
      logEvent(USER_ACTIONS_EVENTS.REMEMBER_ME, { remember_me: !checked ? 'yes' : 'no' });
      return !checked;
    });
  };

  const handleSSOLogin = () => {
    logEvent(USER_ACTIONS_EVENTS.LOGIN_SSO);
    handleSSOClick(GOOGLE_PROVIDER);
  };

  const handleForgotPasswordClick = () => {
    logEvent(USER_ACTIONS_EVENTS.FORGOT_PASSWORD);
    navigate('/user/forgot-password');
  };

  const handleSignupClick = () => {
    logEvent(USER_ACTIONS_EVENTS.SIGNUP);
    navigate('/user/register');
  };

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid container item xs={12} sm={8} md={6} component={isDesktop ? Paper : 'div'}>
        <div className={classes.paper}>
          <TypographyEnhanced id={TRANSLATION_PATH('title')} variant='h5' />
          <Grid container spacing={2} justifyContent='center' className={classes.topMargin}>
            <Grid item xs={12}>
              <TextFieldEnhanced
                id='email'
                label={TRANSLATION_PATH('email.label')}
                placeholder={TRANSLATION_PATH('email.placeholder')}
                type='text'
                required
                value={localData?.email || ''}
                onChange={(value) =>
                  setLocalData((prev) => {
                    return {
                      ...prev,
                      email: value.toLowerCase(),
                      username: value.toLowerCase()
                    };
                  })
                }
                hasError={!!errorFields?.email}
                errorText={errorFields?.email}
                helperText=''
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordFieldEnhanced
                id='password'
                value={localData?.password || ''}
                label={TRANSLATION_PATH('password.label')}
                placeholder={TRANSLATION_PATH('password.placeholder')}
                required
                onChange={(value) =>
                  setLocalData((prev) => {
                    return { ...prev, password: value };
                  })
                }
                hasError={!!errorFields?.password}
                errorText={errorFields?.password}
              />
            </Grid>
            <Grid container item alignItems='center'>
              <Grid item xs={6} className={classes.options}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={rememberEmail} size='small' onChange={toggleRemember} />}
                    label={t(TRANSLATION_PATH('rememberMe'))}
                  />
                </FormGroup>
              </Grid>
              <Grid container item xs={6} justifyContent='flex-end'>
                <ButtonEnhanced variant='text' size='small' onClick={handleForgotPasswordClick}>
                  <TypographyEnhanced id={TRANSLATION_PATH('forgotPassword')} />
                </ButtonEnhanced>
              </Grid>
            </Grid>
            {timedOut ? <TimedOutAlert /> : null}
            {error && (
              <Grid item xs={12}>
                <Alert>{error}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <ButtonEnhanced fullWidth size='large' variant='contained' onClick={onLogin} loading={loading}>
                <TypographyEnhanced id={TRANSLATION_PATH('buttons.login')} />
              </ButtonEnhanced>
            </Grid>
            <DividerWithText />
            <Grid item>
              <GoogleSignIn onClick={handleSSOLogin} />
            </Grid>
            {/* <Grid item>
                <MicrosoftSignIn onClick={() => signInWithRedirect(FIREBASE_AUTH, MICROSOFT_PROVIDER)} />
              </Grid> */}
            {/* <Grid item xs={12}>
                <ButtonEnhanced fullWidth size='large' variant='outlined' onClick={() => {}} loading={loading}>
                  Log in with Office365
                </ButtonEnhanced>
              </Grid> */}
          </Grid>
        </div>
        <Grid item xs={12} justifyContent='center' mt={1} mb={1}>
          <ButtonEnhanced fullWidth size='large' variant='text' onClick={handleSignupClick}>
            <TypographyEnhanced id={TRANSLATION_PATH('buttons.signUp')} />
          </ButtonEnhanced>
        </Grid>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {};

export default Login;
