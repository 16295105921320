import PropTypes from 'prop-types';
import KbsChip from './kbsChip';

import KbsSelect from './kbsSelect';

const Kbs = ({ edit, ...props }) => {
  if (edit) {
    return <KbsSelect {...props} />;
  } else {
    return <KbsChip expand {...props} />;
  }
};

Kbs.propTypes = {
  edit: PropTypes.bool
};

export default Kbs;
