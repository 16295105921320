// views.app.reports.devices

export const DEVICES = {
  title: 'Mobile Devices',
  description:
    'These are the devices that have been used at one point in the past or present to access your primary logins (e.g. Google). Look for any devices or employees in this list that you believe should not be here.',
  columnHeaders: {
    name: 'Name',
    email: 'Email',
    model: 'Device Model',
    type: 'Type',
    os: 'Operating System',
    compromisedStatus: 'Device Compromised Status',
    passwordStatus: 'Device Password Status',
    deviceId: 'Device ID',
    encryptionStatus: 'Encryption Status',
    firstSync: 'First Synced',
    lastSync: 'Last Synced'
  }
};
