import { getApplicationUserCount } from '@anirudhm9/base-lib/lib/utils/application';
import _ from 'lodash';
import { SORT_VALUES } from '../constants';

/**
 * Assesses filter selection and returns appropriate where clause for argument in query
 *
 * @param {Object} filters
 * @returns {Object}
 */
export const formatFilters = (filters = {}) => {
  // Flatten out each filter group object into a single array
  const filtersByKey = Object.keys(filters).reduce((result, filterKey) => {
    const filterGroupFlat = Object.values(filters[filterKey]).flat(1);
    result = { ...(result || {}), [filterKey]: filterGroupFlat };
    return result;
  }, {});

  const result = {};

  const and = [];

  if ((filtersByKey.CREATION_METHOD || []).length) {
    const _or = [];

    if (filtersByKey.CREATION_METHOD.includes('CREATION_METHOD_MANUAL')) {
      _or.push({ integration_null: true });
    }
    if (filtersByKey.CREATION_METHOD.includes('CREATION_METHOD_AUTOMATIC')) {
      _or.push({ integration_null: false });
    }
    and.push({ _or });
  }

  if ((filtersByKey.KEY_BUSINESS_SYSTEM || []).length) {
    and.push({ kbs_in: filtersByKey.KEY_BUSINESS_SYSTEM || [] });
  }

  result._and = and;

  return result;
};

/**
 *
 * @param {Array} applications - array of application objects
 * @param {String} sortValue - string value of enum
 * @returns {Array} - array of application objects
 */
export const sortApplications = (applications, sortValue) => {
  let sortedApplications = _.cloneDeep(applications || []);
  switch (sortValue) {
    case SORT_VALUES.ALPHABETICAL_DESC.value:
      sortedApplications = _.orderBy(sortedApplications || [], ['thirdparty.name'], ['desc']);
      break;
    case SORT_VALUES.ALPHABETICAL_ASC.value:
      sortedApplications = _.orderBy(sortedApplications || [], ['thirdparty.name'], ['asc']);
      break;
    case SORT_VALUES.SECURITY_SCORE_DESC.value:
      sortedApplications = _.orderBy(sortedApplications || [], ['thirdparty.securityScore'], ['desc']);
      break;
    case SORT_VALUES.SECURITY_SCORE_ASC.value:
      sortedApplications = _.orderBy(sortedApplications || [], ['thirdparty.securityScore'], ['asc']);
      break;
    case SORT_VALUES.USER_COUNT_DESC.value:
      sortedApplications = _.orderBy(
        sortedApplications || [],
        [
          function (app) {
            return getApplicationUserCount(app);
          }
        ],
        ['desc']
      );
      break;
    case SORT_VALUES.USER_COUNT_ASC.value:
      sortedApplications = _.orderBy(
        sortedApplications || [],
        [
          function (app) {
            return getApplicationUserCount(app);
          }
        ],
        ['asc']
      );
      break;

    default:
    // do nothing
  }

  return sortedApplications;
};
