import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper } from '@mui/material';
import { PropTypes } from 'prop-types';

/**
 * Custom Accordion component
 *
 * @typedef {import('@mui/material').CardProps} CardProps Props for `Card` from '@mui/material` library
 * @typedef {import('@mui/material').CardHeaderProps} CardHeaderProps Props for `CardHeader` from '@mui/material` library
 * @typedef {import('@mui/material').MenuProps} MenuProps Props for `Menu` from '@mui/material` library
 * @typedef {import('@mui/material').MenuItemProps} MenuItemProps Props for `MenuItem` from '@mui/material` library
 *
 * @param {{ menuItems: [{ id: String | Number, label: String | JSX, onClick: Function & MenuItemProps }], headerProps: CardHeaderProps, menuProps: MenuProps, loading: Boolean } & CardProps} props loading state for Spinner
 * @returns {React.FC<CardProps>}
 */

const AccordionEnhanced = ({ header, children, summaryProps, detailProps, ...props }) => {
  return (
    <Accordion elevation={2} component={Paper} {...props}>
      <AccordionSummary aria-controls='accordian-content' id='accordian-header' {...summaryProps}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={12}>
            {header}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails {...detailProps}>{children}</AccordionDetails>
    </Accordion>
  );
};

AccordionEnhanced.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  summaryProps: PropTypes.object,
  detailProps: PropTypes.object
};
export default AccordionEnhanced;
