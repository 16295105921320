import { HEADERS } from '@anirudhm9/base-lib/lib/constants';
import { stripHtmlTags } from '@anirudhm9/base-lib/lib/utils';
import { OpenInNewRounded, WarningAmberRounded } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { CardWithLoader, TypographyEnhanced } from '../../../../../../global';
import { TableEnhanced } from '../../../../../../ui';
import { logViewRemediation } from '../../utils';

const MissingHeadersTable = ({ scan, loading }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const { missingHeaders = [] } = scan || {};
    const rows = Object.values(missingHeaders || []).reduce((result, header) => {
      const { key, missing } = header || {};
      const { remediation } = HEADERS[(key || '').toLowerCase()] || {};

      result = [
        ...(result || []),
        {
          label: key,
          value: (
            <Grid container item alignItems='center'>
              <TypographyEnhanced id={stripHtmlTags(missing)} display='inline' />
              <IconButton onClick={() => logViewRemediation(key, remediation)} color='primary'>
                <OpenInNewRounded />
              </IconButton>
            </Grid>
          )
        }
      ];
      return result;
    }, []);

    setRows(rows);
  }, [scan]);

  return (
    <CardWithLoader loading={loading} elevation={2}>
      <Grid container spacing={2}>
        <Grid container item xs={12} alignItems='center' spacing={1}>
          <Grid item>
            <TypographyEnhanced id='Missing Headers' variant='h6' display='inline' />
          </Grid>
          <Grid item>
            <WarningAmberRounded color='error' fontSize='large' />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableEnhanced rows={rows || []} />
        </Grid>
      </Grid>
    </CardWithLoader>
  );
};

MissingHeadersTable.propTypes = {
  scan: PropTypes.object,
  loading: PropTypes.bool
};
export default MissingHeadersTable;
